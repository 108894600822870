import { useState, useEffect } from "react";

function useUserMedia(orderLabels, videoDevices) {
  const [mediaStream, setMediaStream] = useState(null);
  const [streamError, setStreamError] = useState("");
  const [currentVideoDevice, setCurrentVideoDevice] = useState(0);
  const [activeDeviceId, setActiveDeviceId] = useState("idle");

  const numberOfVideoDevices =
    videoDevices !== "idle" && videoDevices !== "stop" && videoDevices !== null
      ? videoDevices.length
      : 0;

  useEffect(() => {
    if (videoDevices !== "idle" && numberOfVideoDevices > 0) {
      getNextDeviceId();
    } else if (videoDevices === null) {
      setActiveDeviceId("");
    } else if (videoDevices === "stop") {
      cleanupMediaStream();
    }
  }, [videoDevices]);

  useEffect(() => {
    if (activeDeviceId !== "idle" && !mediaStream) {
      startStream();
    }
  }, [mediaStream, activeDeviceId]);

  /** Clean up the current media stream  */
  const cleanupMediaStream = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  /** Set the state of the active Device Id
   * if there is not more devices left, set the active device id to ""
   */
  const getNextDeviceId = () => {
    let videoDevice = false;
    if (currentVideoDevice < numberOfVideoDevices) {
      videoDevice = videoDevices[currentVideoDevice];
      setCurrentVideoDevice(currentVideoDevice + 1);
    }
    setActiveDeviceId(videoDevice !== false ? videoDevice.deviceId : "");
  };

  /** Start the media stream */
  const startStream = async () => {
    cleanupMediaStream();
    try {
      const constraints = {
        audio: false,
        video: {
          facingMode: "environment",
          deviceId: activeDeviceId,
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setMediaStream(stream);
    } catch (error) {
      /** If getUserMedia failed, first check to see if there are other available devices
       * if so try to make those work
       * else show an error
       */
      if (currentVideoDevice < numberOfVideoDevices) {
        //getUserMedia failed for this device, clean up the current media stream and get another device
        getNextDeviceId();
      } else {
        console.error(error);
        /** If an error is dectected, check and see if it is a non-Safari browser on iOS
         * If so show an error stating that camera access is not supported for this browser
         */
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (iOS && !iOSSafari) {
          setStreamError(orderLabels["camera-access-not-supported"]);
        } else {
          //Otherwise show the detected error message
          setStreamError(error.message);
        }
      }
    }
  };

  return { mediaStream, streamError };
}

export default useUserMedia;

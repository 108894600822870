import React from "react";
import { DashboardCardsSlider } from "../DashboardCardsSlider/DashboardCardsSlider";
import { useHideOrderModule } from "../../App/useHideOrderModule";

export const DashboardWhatsNew = ({ slidesData }) => {
  const { hideOrderModule } = useHideOrderModule();
  return (
    <section className="dashboard__module dashboard__module--horizontal-slider dashboard__new-order-items-module whatsNewSliderSection">
      <DashboardCardsSlider sliderType={"whatsNew"} slidesData={slidesData} disableImageLink={hideOrderModule} />
    </section>
  );
};

//Libraries
import React, { useContext } from "react";

//Hooks
import useWindowSize from "../_common/hooks/useWindowSize";

//Contexts
import AppLabelsContext from "../App/AppLabelsContext";

//UI Components
import { Geolocation } from "../OnlineOrdering/GoogleMaps/Geolocation/Geolocation";
import OrderAddressAutoComplete from "./OrderAddressAutoComplete";

export const OrderStoresSearchComponent = (props) => {
  const {
    searchboxInputRef,
    onSearchBoxPlacesChanged,
    getUserGeolocation,
    geoLocationButtonIsActive,
    orderType,
    autocompleteRef,
    isAddressValid,
  } = props;

  const deviceWidth = useWindowSize().width;

  const appLabels = useContext(AppLabelsContext);

  return (
    <>
      {orderType === "delivery" && deviceWidth > 660 && (
        <div className="delivery-address__header-container desktop-container">
          <h2 style={{ marginBottom: "10px" }}>{appLabels["order"]["delivery-addresses"]}</h2>
        </div>
      )}
      <div
        className={`order-stores__address-location-wrapper ${
          (orderType === "delivery" || window.location.href.includes("account/delivery")) &&
          deviceWidth < 660
            ? "order-stores__address-location-wrapper-border-bottom"
            : ""
        }`}
      >
        <div
          className={`order-stores__address-wrapper`}
          style={orderType === "delivery" && deviceWidth > 660 ? { paddingTop: "10px" } : {}}
        >
          <div className="order-stores__address-input-wrapper">
            <OrderAddressAutoComplete
              appLabels={appLabels}
              onSearchBoxPlacesChanged={onSearchBoxPlacesChanged}
              inputRef={searchboxInputRef}
              autocompleteRef={autocompleteRef}
              isAddressValid={isAddressValid}
            ></OrderAddressAutoComplete>
            <ul
              className="address-suggestion-list_wrapper visually-hidden"
              id="address-suggestion-list"
            ></ul>
          </div>
        </div>

        <div
          className={`order-stores__use-location-wrapper ${
            orderType === "delivery" ? "order-stores__use-location-wrapper_delivery" : ""
          }`}
        >
          <Geolocation
            getUserGeolocation={getUserGeolocation}
            isActive={geoLocationButtonIsActive}
          />
        </div>
      </div>
    </>
  );
};

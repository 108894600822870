import React from "react";
import { Route } from "react-router-dom";
import { PageComponentTemplate } from "../_common/components/PageComponentTemplate";
import { PayInStore } from "./PayInStore";
import { AddNewCard } from "./AddNewCard";

export const ScanInStoreRoutes = () => {
  return (
    <>
      <Route
        exact
        path={["/pay-in-store", "/pay-in-store/add-money", "/pay-in-store/transfer-funds"]}
        render={() => <PayInStore />}
      />
      <Route
        path="/pay-in-store/add-new-card"
        render={() => (
          <PageComponentTemplate
            mainClassOverride="main-content--with-FAB add-new-card-main-content"
            component={<AddNewCard />}
            isFooter={false}
          />
        )}
      />
    </>
  );
};

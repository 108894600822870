import React, { Component } from "react";
import Quagga from "quagga";

class Scanner extends Component {
  componentDidUpdate() {
    const props = this.props;
    Quagga.stop();
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: {
            width: 640,
            height: 480,
            facingMode: "environment", // or user
            deviceId: props.deviceId,
          },
          area: {
            // defines rectangle of the detection/localization area
            top: "0%", // top offset
            right: "0%", // right offset
            left: "0%", // left offset
            bottom: "0%", // bottom offset
          },
        },
        locator: {
          patchSize: "medium",
          halfSample: true,
        },
        numOfWorkers: 4,
        decoder: {
          readers: ["code_128_reader", "code_39_reader"],
        },
        locate: true,
      },
      function (err) {
        if (err) {
          if (props.currentVideoDevice < props.numberOfVideoDevices) {
            //getUserMedia failed for this device, clean up the current media stream and get another device
            props.getNextDeviceId();
          } else {
            /** If an error is dectected, check and see if it is a non-Safari browser on iOS
             * If so show an error stating that camera access is not supported for this browser
             */
            const ua = window.navigator.userAgent;
            const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
            const webkit = !!ua.match(/WebKit/i);
            const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
            if (iOS && !iOSSafari) {
              props.setStreamError(props.orderLabels["camera-access-not-supported"]);
            } else {
              //Otherwise show the detected error message
              props.etStreamError(err.message);
            }
          }
          console.log(err);
        } else {
          Quagga.start();
        }
      }
    );
    Quagga.onDetected(this._onDetected);
  }
  componentDidMount() {
    const props = this.props;
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: {
            width: 640,
            height: 480,
            facingMode: "environment", // or user
            deviceId: props.deviceId,
          },
          area: {
            // defines rectangle of the detection/localization area
            top: "0%", // top offset
            right: "0%", // right offset
            left: "0%", // left offset
            bottom: "0%", // bottom offset
          },
          singleChannel: false,
        },
        locator: {
          patchSize: "medium",
          halfSample: true,
        },
        numOfWorkers: 4,
        decoder: {
          readers: ["code_128_reader", "code_39_reader"],
          multiple: false,
        },
        locate: true,
      },
      function (err) {
        if (err) {
          if (props.currentVideoDevice < props.numberOfVideoDevices) {
            //getUserMedia failed for this device, clean up the current media stream and get another device
            props.getNextDeviceId();
          }
          else {
            /** If an error is dectected, check and see if it is a non-Safari browser on iOS
             * If so show an error stating that camera access is not supported for this browser
             */
            const ua = window.navigator.userAgent;
            const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
            const webkit = !!ua.match(/WebKit/i);
            const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
            if (iOS && !iOSSafari) {
              props.setStreamError(props.orderLabels["camera-access-not-supported"]);
            } else {
              //Otherwise show the detected error message
              props.setStreamError(err.message);
            }
          }
          console.log(err);
        } else {
          Quagga.start();
        }
      }
    );
    Quagga.onDetected(this._onDetected);
  }

  componentWillUnmount() {
    Quagga.offDetected(this._onDetected);
    Quagga.stop();
  }

  _getMedian = (arr) => {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1)
      // Odd length
      return arr[half];
    return (arr[half - 1] + arr[half]) / 2.0;
  };

  _onDetected = (result) => {
    const errors = result.codeResult.decodedCodes
      .filter((_) => _.error !== undefined)
      .map((_) => _.error);
    const median = this._getMedian(errors);
    if (median < 0.09) {
      // probably correct
      this.props.onDetected(result);
      this.props.setStreamError("idle");
      Quagga.stop();
    }
    //else probably a false positive
  };

  render() {
    return <div id="interactive" className="viewport" />;
  }
}

export default Scanner;

import { callAPI } from "../_common/Api";

export const getApiSUN = async (skin, loginToken, rewardsCard, language) => {
  const api944Data = await callAPI(skin, "dc_944", {
    params: [
      language,
      "944",
      "mqid",
      "mqpass",
      loginToken,
      "",
      rewardsCard.iso,
      rewardsCard.serial,
      "",
    ],
    id: "944",
  });

  if (api944Data.status === 249 || api944Data.status === 150) {
    return { status: "expiredLoginToken" };
  } else if (api944Data.result.I1 === "0") {
    return { SUN: api944Data.result.I2, preferredCodeFormat: api944Data.result.I6 };
  } else {
    console.error(api944Data);
  }
};

// Libraries
import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";
import { Link } from "react-router-dom";

// Hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Contexts
import AppLabelsContext from "../../App/AppLabelsContext";
import UserRoleContext from "../../App/UserRoleContext";
import LoyaltyContext from "../../App/LoyaltyContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import BrandingContext from "../../App/BrandingContext";

//Helpers
import { getAPICardList } from "../../OnlineOrdering/Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPICardList";

// UI Components
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { DashboardRewards } from "../DashboardRewards/DashboardRewards";

// Assets
import { ReactComponent as IconStar } from "../../_common/icons/IconStar.svg";
import { ReactComponent as IconArrowDown } from "../../_common/icons/IconArrowDown.svg";

// CSS
import "./UserGreetings.css";

export const UserGreetings = React.forwardRef((props, ref) => {
  const { skin, isLoggedIn, hideExtraInformation } = props;

  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const branding = useContext(BrandingContext);
  const deviceWidth = useWindowSize().width;

  const [greeting, setGreeting] = useState("");
  useEffect(() => {
    setGreeting(getPartOfDay(appLabels["general"]));
  }, []);

  const [userFirstName, setUserFirstName] = useState(false);
  useEffect(() => {
    if (isLoggedIn) {
      localforage.getItem(skin + "__userFirstName").then((storedFirstName) => {
        if (storedFirstName) setUserFirstName(storedFirstName);
      });
    }
  }, []);

  const [isHeaderReady, setIsHeaderReady] = useState(false);
  useEffect(() => {
    if ((greeting && userFirstName) || (greeting && !isLoggedIn)) setIsHeaderReady(true);
  }, [greeting, userFirstName]);

  const loyaltyContext = useContext(LoyaltyContext);
  const userRoleContext = useContext(UserRoleContext);
  const userStatus = userRoleContext.status;
  const loginToken = userRoleContext.loginToken;

  const [isShowingRewardDetails, setIsShowingRewardDetails] = useState(false);
  const handleShowingRewardDetails = () => {
    setIsShowingRewardDetails(!isShowingRewardDetails);
  };

  const [userGiftCards, setUserGiftCards] = useState(null);
  useEffect(() => {
    if (loginToken && isShowingRewardDetails) {
      getAPICardList(
        skin,
        loginToken,
        appSettings["currency-symbol"],
        appSettings["currency-symbol-side"],
        appLanguage,
        branding
      ).then((fetchedData) => {
        if (fetchedData) {
          if (fetchedData.length > 0) {
            setUserGiftCards(fetchedData);
          } else if (fetchedData.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          }
        }
      });
    } else {
      setUserGiftCards(null);
    }
  }, [loginToken, isShowingRewardDetails]);

  return (
    <header className="dashboard__header dashboard__header-container">
      <div>
        {isHeaderReady && (
          <>
            <div className="dashboard-header__content-wrapper">
              <div
                className={`dashboard__greeting-wrapper ${
                  isLoggedIn ? "is-loggedin" : "not-loggedin"
                }`}
              >
                {/*<IconUser aria-hidden="true" />*/}
                <h1 className="dashboard__greeting">
                  {greeting}{" "}
                  {userFirstName !== "" && (
                    <span className="dashboard__greeting-name">{userFirstName}</span>
                  )}
                </h1>

                {isLoggedIn ? (
                  <>
                    {!hideExtraInformation && loyaltyContext.isEnabled && deviceWidth < 600 && (
                      <div
                        className={`dashboard__greeting-loyalty-button ${
                          isShowingRewardDetails ? "expanded" : "collapsed"
                        }`}
                      >
                        <IconStar aria-hidden="true" />
                        {isShowingRewardDetails ? (
                          <IconArrowDown
                            aria-hidden="true"
                            style={{ transform: "rotate(180deg)" }}
                          />
                        ) : (
                          <IconArrowDown aria-hidden="true" />
                        )}
                        <button
                          type="button"
                          aria-label={appLabels["loyalty"]["my-rewards"]}
                          onClick={handleShowingRewardDetails}
                          className="dashboard-greeting__rewards-button"
                        ></button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Link
                      className="blue-link-button"
                      to="/login-register"
                      aria-label={appLabels["login-register"]["sign-in"]}
                    >
                      {appLabels["login-register"]["sign-in"]}
                    </Link>
                  </>
                )}
              </div>
            </div>
            {loyaltyContext.isEnabled && userStatus === "logged-in" && isShowingRewardDetails && (
              <div className="user-greetings__rewards-container">
                {" "}
                {userGiftCards ? (
                  <DashboardRewards userGiftCards={userGiftCards} />
                ) : (
                  <LoadingSpinner customClass={"user-greetings__rewards-container-loader"} />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </header>
  );
});

const getPartOfDay = (generalLabels) => {
  let partOfDay;

  const now = new Date();
  const todayHours = now.getHours();

  if (todayHours < 12) {
    partOfDay = generalLabels["good-morning"];
  } else if (todayHours < 18) {
    partOfDay = generalLabels["good-afternoon"];
  } else {
    partOfDay = generalLabels["good-evening"];
  }

  return partOfDay;
};

export const giftCardLabels = {
  gift: "Presente",
  "gift-cards": "Cartões presente",
  "order-history": "Histórico de pedidos",
  "see-all": "Ver tudo",
  amount: "Valor",
  "recipient-name": "Nome do Destinatário",
  "recipient-email": "E-mail do destinatário",
  "confirm-recipient-email": "Confirmar e-mail do destinatário",
  "delivery-date-time": "Data e hora de entrega",
  "greeting-message": "Mensagem de saudação",
  "sender-name": "Nome do remetente",
  "add-more-people": "Adicionar mais pessoas",
  "clear-form": "Limpar Formulário",
  save: "Salvar",
  "select-gift-card": "Selecione cartão presente",
  "exit-gift-card-dialog-message":
    "Sair desta página resultará na perda do pedido do seu cartão presente.",
  "sure-text": "Você tem certeza?",
  "gift-card-recent-order-signed-out-message":
    "Procure cartões presente para iniciar um pedido ou faça login para ver seu histórico de transações",
  "add-discount-coupon": "Adicionar desconto / cupom",
  minimum: "Mínimo",
  maximum: "Máximo",
  "invalid-delivery-date-time-gift-card-title-message": "Oops..! Data/Hora de entrega inválida",
  "invalid-delivery-date-time-gift-card-message":
    "Um dos seus cartões presente tem data ou hora de entrega inválida. A próxima data e hora de entrega disponível seria",
  "exceeded-gift-card-amount-message":
    "A quantidade total de cartões-presente no carrinho é maior que a quantidade permitida de",
  "exceeded-gift-card-amount-remove-message":
    "Remova um vale-presente ou altere o valor do vale-presente.",
  "atleast-one-recipient-button": "Por favor, adicione pelo menos 1 destinatário",
  "submit-recipient-error": "Um ou mais detalhes do destinatário estão faltando ou inválidos",
  "one-valid-recipient-missing-error":
    "Você precisa de pelo menos um destinatário válido para um cartão presente",
  "gift-card-image-unavailable": "Imagem do cartão presente indisponível",
  "view-gift-cards-button": "Ver cartões presente",
  "send-to-more-people": "Enviar para mais pessoas",
  "for-delivery-on": "para entrega em",
  to: "para",
  at: "no",
  continue: "Continuar",
  "confirmation-email-sent": "Enviamos-lhe um e-mail de confirmação.",
  "gift-card-delivery-on": "Cartão presente virtual para entrega em",
  one: "um",
  "go-to-gc-cart": "Vá para o carrinho do cartão presente",
  "no-gift-cards-available": "Nenhum cartão presente está disponível no momento",
  "gift-card-value-text": "Valor do vale-presente",
  "gift-card-nav-label": "Histórico de pedidos de vale-presente",
  "and-text": "e",
  "custom-amount": "Valor personalizado",
  "minimum-one-recipient": "É necessário no mínimo 1 destinatário",
  "promo-code": "Código da promoção",
  "promo-apply": "Aplicar",
  "send-a-gift-card": "Enviar um vale-presente",
  "denomination-error-heading": "Oops... Parece que algo deu errado",
  "denomination-error-text": "Nós estamos limpando o seu pedido, por favor, tente novamente.",
  "recipient": "Destinatário",
  "delivery": "Entrega",
  "message": "Mensagem",
  "invalid-promo-code": "Código de oferta promocional inválido",
  "remove-applied-promo-codes": "Remover códigos de promoção aplicados",
  "order-limit-reached": "Seu pedido excede o limite",
  "item-order-limit": "Limite de pedido de",
  "reached": "alcançado",
  "promo": "Promoção"
};

import { callAPI } from "../../../_common/Api";
import { sortByKey } from "../../../_common/helpers";

export const getOrderFromTable = async (appLanguage, skin, tableNumber, storeId) => {
  const data1322 = await callAPI(
    skin,
    "vxl_1322",
    {
      params: [appLanguage, "1322", "mqid", "mqpass", storeId, tableNumber],
      id: "1322",
    },
    storeId
  );

  if (data1322) {
    return data1322;
  }
};

export const checkBillRequestStatus = async (appLanguage, skin, hostTransactionID, storeId) => {
  const data1325 = await callAPI(
    skin,
    "vxl_1325",
    {
      params: [
        appLanguage,
        "1325",
        "mqid",
        "mqpass",
        storeId,
        hostTransactionID,
        "HOST_GEN_BILL_ITEMS",
      ],
      id: "1325",
    },
    storeId
  );

  if (data1325) {
    return data1325;
  }
};

export const generateBillsForItems = async (
  appLanguage,
  skin,
  vxlOrderId,
  orderLineList,
  onlineOrderId,
  loyaltyCard = "",
  storeId
) => {
  const data1323 = await callAPI(
    skin,
    "vxl_1323",
    {
      params: [
        appLanguage,
        "1323",
        "mqid",
        "mqpass",
        storeId,
        "", // Operator_id, should be sent as empty string
        vxlOrderId,
        orderLineList,
        onlineOrderId,
        loyaltyCard,
      ],
      id: "1323",
    },
    storeId
  );

  if (data1323) {
    return data1323;
  }
};

export const calculateBillItemsSubtotal = (selectedBillItems) => {
  let checkOutSubtotal = 0;

  selectedBillItems.forEach((billItem) => {
    checkOutSubtotal = checkOutSubtotal + parseFloat(billItem["price_for_display"]);

    const addonList = billItem["addon_list"];
    if (addonList) {
      addonList.forEach((addon) => {
        checkOutSubtotal += parseFloat(addon["price_for_display"] || 0);
      });
    }

    /** handling of combo items */

    const childItems = billItem["item_list"];
    if (childItems) {
      childItems.forEach((childItem) => {
        checkOutSubtotal = checkOutSubtotal + parseFloat(childItem["price_for_display"]);

        const childItemAddonList = childItem["addon_list"];
        if (childItemAddonList) {
          childItemAddonList.forEach((addon) => {
            checkOutSubtotal += parseFloat(addon["price_for_display"] || 0);
          });
        }
      });
    }
  });

  return checkOutSubtotal;
};

export const formatBillItemsForApi = (billItems) => {
  let formattedBillItems = [];

  billItems.forEach((item) => {
    const addonList = [];
    if (item["addon_list"] && item["addon_list"].length > 0) {
      //setup the addonList

      item["addon_list"].forEach((addon) => {
        const formattedNestedModifierGroups = [];

        if (addon["modifier_list"] && addon["modifier_list"].length > 0) {
          addon["modifier_list"].forEach((nestedModifier) => {
            const formattedNestedModifier = {
              item_id: nestedModifier["modif_value_id"],
              item_sku_code: "",
              modifier_list: [],
              modifier_group_id: "",
              discount_ref: "",
              discount_amount: "",
            };
            formattedNestedModifierGroups.push(formattedNestedModifier);
          });
        }

        const formattedAddon = {
          item_id: addon["item_id"],
          item_sku_code: "",
          modifier_list: formattedNestedModifierGroups,
          discount_ref: "",
          discount_amount: "",
          addon_group_id: "",
        };

        let addonQuantity = addon.quantity || 1;
        for (let i = 0; i < addonQuantity; i++) {
          addonList.push(formattedAddon);
        }
      });
    }

    const modifierList = [];
    if (item["modifier_list"] && item["modifier_list"].length > 0) {
      //setup the modifierList

      item["modifier_list"].forEach((modifier) => {
        const formattedModifier = {
          item_id: modifier["modif_value_id"],
          item_sku_code: "",
          modifier_list: [],
          discount_ref: "",
          discount_amount: "",
        };

        modifierList.push(formattedModifier);
      });
    }

    const comboChildItems = [];
    if (item.hasOwnProperty("item_list") && item["item_list"].length > 0) {
      //setup combo child items
      item["item_list"].forEach((childItem, index) => {
        const comboChildAddonList = [];
        /** Child Item addon list */
        if (childItem["addon_list"] && childItem["addon_list"].length > 0) {
          childItem["addon_list"].forEach((addon) => {
            const childItemFormattedNestedModifierGroups = [];
            if (addon["modifier_list"] && addon["modifier_list"].length > 0) {
              addon["modifier_list"].forEach((nestedModifier) => {
                const formattedNestedModifier = {
                  item_id: nestedModifier["modif_value_id"],
                  item_sku_code: "",
                  modifier_list: [],
                  modifier_group_id: "",
                  discount_ref: "",
                  discount_amount: "",
                };
                childItemFormattedNestedModifierGroups.push(formattedNestedModifier);
              });
            }

            const childItemFormattedAddon = {
              item_id: addon["item_id"],
              item_sku_code: "",
              modifier_list: childItemFormattedNestedModifierGroups,
              discount_ref: "",
              discount_amount: "",
              addon_group_id: "",
            };

            let comboChildAddonQuantity = addon.quantity || 1;
            for (let i = 0; i < comboChildAddonQuantity; i++) {
              comboChildAddonList.push(childItemFormattedAddon);
            }
          });
        }

        const comboChildModifierList = [];
        if (childItem["modifier_list"] && childItem["modifier_list"].length > 0) {
          childItem["modifier_list"].forEach((modifier) => {
            const childItemFormattedModifier = {
              item_id: modifier["modif_value_id"],
              item_sku_code: "",
              modifier_list: [],
              discount_ref: "",
              discount_amount: "",
            };

            comboChildModifierList.push(childItemFormattedModifier);
          });
        }

        const formattedComboChildItems = {
          item_id: childItem["item_id"],
          item_sku_code: "",
          addon_list: comboChildAddonList,
          modifier_list: comboChildModifierList,
          cooking_instruction: "",
          combo_child_items: [],
          discount_ref: "",
          discount_amount: "",
          item_components_group_id: childItem.group_id,
        };

        let comboChildItemQuantity = childItem.quantity || 1;
        for (let i = 0; i < comboChildItemQuantity; i++) {
          comboChildItems.push(formattedComboChildItems);
        }
      });
    }

    formattedBillItems.push({
      item_id: item["item_id"],
      item_sku_code: "",
      addon_list: addonList,
      modifier_list: modifierList,
      cooking_instruction: "",
      combo_child_items: comboChildItems,
      discount_ref: "",
      discount_amount: "",
    });
  });

  return formattedBillItems;
};

const getOrderOccurrence = (array, value, addons, comboitems, modifiers) => {
  return array.filter(
    (item) =>
      JSON.stringify(item.item_id) === value &&
      JSON.stringify(item.addon_list) === addons &&
      JSON.stringify(item.item_list) === comboitems &&
      JSON.stringify(item.modifier_list) === modifiers
  ).length;
};

const removeOrderDuplicates = (data, key) => {
  return [...new Map(data.map((item) => [key(item), item])).values()];
};

export const cleanUpOrderListBasedOnUnits = (orderList) => {
  //calculate units
  orderList.forEach((tempItem) => {
    /** sort the addon_list and modifier_list for items and combo subitems */
    if (!tempItem.item_list || tempItem.item_list.length === 0) {
      tempItem.addon_list = sortByKey(tempItem.addon_list, "item_id", false);
      tempItem.modifier_list = sortByKey(tempItem.modifier_list, "item_id", false);
    } else {
      /** handling sorting for combo item */
      tempItem.item_list.forEach((listItem) => {
        listItem.addon_list = sortByKey(listItem.addon_list, "item_id", false);
        listItem.modifier_list = sortByKey(listItem.modifier_list, "item_id", false);
      });
    }

    delete tempItem.units;
    delete tempItem.vxl_order_line_id;
    delete tempItem.total_price;
    delete tempItem.seat_num;

    if (tempItem.addon_list) {
      tempItem.addon_list.forEach((tempAddOn) => {
        delete tempAddOn.vxl_order_line_id;
      });
    }

    //check if item is a combo
    if (tempItem.item_list) {
      tempItem.item_list.forEach((comboList) => {
        delete comboList.units;
        delete comboList.vxl_order_line_id;
        delete comboList.total_price;
        delete comboList.seat_num;

        if (comboList.addon_list) {
          comboList.addon_list.forEach((comboAddOn) => {
            delete comboAddOn.vxl_order_line_id;
          });
        }
      });
    }
  });

  orderList.forEach((tempItem) => {
    tempItem.units = getOrderOccurrence(
      orderList,
      JSON.stringify(tempItem.item_id),
      JSON.stringify(tempItem.addon_list),
      JSON.stringify(tempItem.item_list),
      JSON.stringify(tempItem.modifier_list)
    );
  });

  //remove duplicates from orderList
  orderList = removeOrderDuplicates(orderList, (item_id) => JSON.stringify(item_id));

  return orderList;
};

export const accountLabels = {
  profile: "Perfil",
  "log-out": "Sair",
  "log-out-confirm-message": "Tem certeza que deseja sair?",
  "personal-info": "Informações Pessoais",
  "update-your-personal-info": "Atualizar suas informações pessoais",
  update: "Atualizar",
  updating: "Atualizando...",
  "change-password": "Alterar senha",
  "update-your-password": "Atualizar sua senha",
  "current-username": "Nome de usuário atual",
  "current-password": "Senha atual",
  "new-password": "Nova senha",
  "confirm-new-password": "Confirme a nova senha",
  "changing-password": "Alterando a senha...",
  "payment-methods": "Métodos do Pagamento",
  "add-credit-card": "Adicionar cartão de crédito",
  "save-card": "Salvar Cartão",
  "saving-card": "Salvando Cartão...",
  "add-new-card": "Adicionar um novo cartão",
  "add-new-card-paragraph":
    "Para adicionar um novo cartão, digite o número do cartão ou escaneie o código de barras",
  "scan-barcode": "Escanear Código de Barras",
  "scan-barcode-modal-heading": "Escaneie o código de barras do seu cartão",
  "add-card": "Adicionar um cartão",
  adding: "Adicionando...",
  added: "Adicionado",
  "transaction-history": "Histórico de Transação",
  "recent-orders": "Pedidos Recentes",
  "recent-online-orders": "Pedidos Recentes Online",
  "recent-orders-disclaimer":
    "O total do pedido anterior pode variar uma vez que os preços estão sujeitos a alterações",
  "recent-orders-item-doesnt-exist-message": "Um ou mais itens deste pedido não existem mais",
  "no-previous-orders": "Nenhum pedido anterior",
  "recent-loyalty-transactions": "Transações de Fidelidade Recentes",
  "recent-card-transactions": "Transações Recentes de Cartão",
  "pay-in-store": "Escanear na loja",
  "add-money": "Adicionar Dinheiro",
  "add-money-submit-button": "Adicionar",
  "refresh-code": "Atualizar Código",
  "my-wallet": "Minha Carteira",
  card: "Cartão",
  "method-of-payment": "Método de Pagamento",
  "reload-amount": "Valor da Recarga",
  "auto-reload": "Recarga Automática",
  "auto-reload-amount": "Quantidade de Recarga Automática",
  "when-balance-reaches": "Quando  o Saldo Chegar a",
  "choose-how-often-to-reload": "ou escolha a frequência de recarga",
  "set-auto-reload": "Configurar Recarga Automática",
  "button-remove-autoreload": "Remover recarga automática",
  "update-auto-reload": "Atualizar Recarga Automática",
  "update-account-details": "Atualizar Detalhes da Conta",
  "enter-password-to-confirm-changes": "Digite a senha para confirmar as alterações",
  "balance-transfer-to-card-number": "Para o seu cartão de fidelidade",
  "balance-transfer-success-message": "Saldo transferido com sucesso",
  "balance-transfer-new-points-balance": "Seu novo saldo de pontos é",
  "transfer-funds": "Transferir saldo",
  "funds-transferred": "Saldo transferido",
  "balance-transfer-disclaimer":
    "Depois que o saldo for transferido para o seu Cartão Presente de Fidelidade, o Cartão Presente ficará inativo.",
  "transfer-funds-blurb":
    "Você tem um cartão presente existente? Transfira o saldo para sua conta aqui.",
  "balance-transfer-to-gift-card": "Para o seu cartão presente",
  "open-barcode-scanner": "Abrir leitor de código de barras",
  "auto-reload-schedule-with-threshold":
    "[auto-reload] está configurado para adicionar [reload-amount] para este cartão quando o saldo atingir [threshold-amount] ou menos.",
  "auto-reload-schedule-weekly":
    "[auto-reload] está configurado para adicionar [reload-amount] para este cartão [frequency].",
  "auto-reload-schedule-monthly":
    "[auto-reload] está configurado para adicionar [reload-amount] para este cartão [frequency].",
  "order-status": "Status do pedido",
  "order-status-active-orders-blurb": "Você possui [number-of-orders] pedido(s) em andamento",
  "order-details": "Detalhes do Pedido",
  "order-status-make-changes-blurb":
    "Se você precisar fazer alguma alteração, entre em contato com a loja no [phone-number]",
  "order-placed": "Efetuado",
  "order-accepted": "Aceito",
  "order-cancelled": "Cancelado",
  "order-preparing": "Preparando",
  "order-ready-for-pickup": "Pronto para retirada",
  "order-on-its-way": "Está a caminho",
  "order-complete": "Finalizado",
  "order-in-progress": "Pedidos em Progresso",
  "past-orders": "Pedidos Anteriores",
  "ordered-items": "Itens Pedidos",
  "order-received": "Recebido",
  "order-void": "Vazia",
  "order-rejected": "Declinou",
  "order-prepared": "Preparado",
  "order-status-last-update-time": "Última atualização na [time-stamp]",
  "order-reviewing": "Revisando",
  "order-timestamp-at": "às",
  "my-orders": "Meus Pedidos",
  "request-account-deletion": "Solicitar remoção de conta",
  "account-deletion-email-subject": "Solicitação de remoção de conta - [customer_id]",
  "account-deletion-email-body":
    "INSTRUÇÕES:\nPor favor, envie este e-mail para solicitar a remoção da seguinte conta em [merchant_name]:\n\nID DO CONSUMIDOR:\n[customer_id]\n\nNome de usuário do CONSUMIDOR\n[customer_email]\n\nAo enviar, você reconhece que esta solicitação é irreversível e sua conta com [merchant_name] juntamente com seus dados associados serão removidos permanentemente. Você receberá uma confirmação final por e-mail assim que sua conta for removida com sucesso.",
  "delete-account": "Remover Conta",
  "delete-my-account": "Remover minha conta",
  deleting: "Removendo...",
  "enter-password": "Forneça a senha",
  "delete-account-confirmation-header": "Confirmação",
  "delete-account-sorry-to-see-you-go": "Lamento ver você partir!",
  "delete-account-permanently": "Você tem certeza que quer remover sua conta permanentemente?",
  "delete-account-confirm-deletion":
    "Para confirmar a remoção da sua conta, por favor, forneça a sua senha abaixo.",
  "delete-account-success-message": "Sua conta foi desativada com sucesso!",
  "delete-account-blurb1":
    "Após [number-of-days] dias, nós iremos remover todos os registros e dados pessoais associados a sua conta.",
  "delete-account-blurb2":
    "Nós enviamos um e-mail de confirmação para o endereço de e-mail associado a sua conta",
  "remove-address-confirmation": "Tem certeza de que deseja remover este endereço?",
  "add-delivery-address": "Adicionar um endereço de entrega",
  "confirm-delivery-address": "Confirmar endereço de entrega",
  "select-delivery-address": "Selecionar endereço de entrega"
};

import React from "react";
//Hooks
import useWindowSize from "../hooks/useWindowSize";

//CSS
import "./HeroSection.css";

export const HeroSection = ({ imageUrl }) => {
  const deviceWidth = useWindowSize().width;
  return (
    <div
      role="banner"
      className={`${deviceWidth >= 660 ? "hero-section" : "hero-section-mobile"} ${
        window.location.href.includes("dashboard") ? " dashboard-hero" : ""
      }`}
      style={{
        backgroundImage: `url(${imageUrl})`,
        top: `${
          !!document.getElementsByClassName("dashboard-header").length
            ? document.getElementsByClassName("dashboard-header")[0].clientHeight - 2
            : 50
        }px`,
      }}
    />
  );
};

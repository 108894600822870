// Libraries
import React, { useContext } from "react";
import localforage from "localforage";
import { useHistory } from "react-router-dom";

// Contexts
import UserRoleContext from "../../App/UserRoleContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";

// UI components
import { DialogModal } from "../DialogModal/DialogModal";
import { callAPI } from "../Api";
import AppLanguageContext from "../../App/AppLanguageContext";

export const LogoutModalDialog = ({ resetRemoveDialog }) => {
  const skin = useContext(MerchantConfigContext).skin;
  const userStatusContext = useContext(UserRoleContext);
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);

  const history = useHistory();

  const logout = () => {
    callAPI(skin, "dc_958", {
      params: [appLanguage, "958", "mqid", "mqpass", "", "", "logout"],
      id: "958",
    }).then((data958) => {
      if (data958.result) {
        sessionStorage.removeItem(skin + "__login");
        sessionStorage.removeItem(skin + "__activeLoyaltyCard");

        localforage.removeItem(skin + "__customerInfo");
        localforage.removeItem(skin + "__customerID");
        localforage.removeItem(skin + "__userFirstName");
        localforage.removeItem(skin + "__isSessionLogin");
        localforage.removeItem(skin + "__lastLoginTimeStampDisplayed");
        localforage.setItem(skin + "__isUserGreeted", true);
        localforage.removeItem(skin + "__storedRewards");
        localforage.removeItem(skin + "__orderHistory");

        userStatusContext.updateStatus("guest");
        userStatusContext.updateLoginToken();

        resetRemoveDialog();

        history.push({
          pathname: "/",
        });
      }
    });
  };

  return (
    <DialogModal
      message={appLabels["account"]["log-out-confirm-message"]}
      confirmAction={logout}
      isCancelConfirm={true}
      resetRemoveDialog={resetRemoveDialog}
    />
  );
};

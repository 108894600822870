import React, { useState, useEffect, useContext } from "react";

//Context
import BrandingContext from "../../App/BrandingContext";

//Helper functions
import { getContrastColor } from "../colorHelpers";

//CSS
import "./GiftCardCartIndicator.css";

import GiftCardIcon from "../icons/GiftCardIcon";
import GCCartContext from "../../GiftCard/GCCartContext";
import { IconCart } from "../icons";

function GiftCardCartIndicator({ isAnimated, quantity, isFloating, isCheckoutScreen = true, showShoppingBagIcon = false, appLabels = null }) {
  const [animatedClass, setAnimatedClass] = useState("");
  const cartContext = useContext(GCCartContext)
  useEffect(() => {
    if (isAnimated) setAnimatedClass(" cart-indicator--blink");
    else setAnimatedClass("");
  }, [isAnimated]);

  const branding = useContext(BrandingContext);
  const cartIconColor =
    window.location.href.includes("review-order") ||
      window.location.href.includes("checkout") ||
      window.location.href.includes("bill")
      ? "#2a2a2a"
      : getContrastColor(branding["online-order-settings-background-colour"]);

  return (

    <div
      className={`cart-indicator${animatedClass}${quantity === 0 ? " cart-indicator--empty" : ""} ${isFloating ? " cart-indicator-floating giftCardCartIndicator" : ""
        } ${isCheckoutScreen ? "giftCardCartIndicatorCheckout" : ""} ${showShoppingBagIcon ? "giftCardCartShoppingBagIndicator" : ""}`} >
      {showShoppingBagIcon ? <IconCart aria-label={appLabels["gift-card"]["go-to-gc-cart"]} /> : <GiftCardIcon fill={cartIconColor} />}
      {cartContext.quantity > 0 && (
        <span
          className="gift-indicator__counter"
          aria-live="polite"
          style={{ color: getContrastColor(branding["online-order-settings-active-colour"]) }}
        >
          {cartContext.quantity}
        </span>
      )}
    </div>
  );
}

export default GiftCardCartIndicator;

// Libraries
import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";
import { useHideOrderModule } from "../App/useHideOrderModule";

// API helper functions
import { jsonCopy } from "../_common/helpers";

// Marqui data helper functions
import { getMarquiWhatsNewSliderData } from "./DashboardWhatsNew/getMarquiWhatsNewSliderData";
import { getMarquiNewsFeedSliderData } from "./DashboardNewsFeed/getMarquiNewsFeedSliderData";
import { isValidImage } from "../_common/helpers/isValidImage";

// Contexts
import UserRoleContext from "../App/UserRoleContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import { MenuContext } from "../OnlineOrdering/Menu/MenuContext";
import AppLabelsContext from "../App/AppLabelsContext";
import BrandingContext from "../App/BrandingContext";
import AppSettingsContext from "../App/AppSettingsContext";
import OrderContext from "../App/OrderContext";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";
import StoreContext from "../OnlineOrdering/StoreContext";
import ManifestContext from "../App/ManifestContext";
import OrderTimeContext from "../OnlineOrdering/OrderTimeContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";

// UI components
import { UserGreetings } from "./DashboardHeader/UserGreetings";
import { DashboardFeatured } from "./DashboardFeatured/DashboardFeatured";
import { DashboardWhatsNew } from "./DashboardWhatsNew/DashboardWhatsNew";
import { HeroSection } from "../_common/DesktopLayout/HeroSection";
import { LastLoginDialog } from "./LastLoginDialog";
import { AdditionalDisclaimer } from "../_common/AdditionalDisclaimer";
import DashboardPayBill from "./DashboardPayBill/DashboardPayBill";
import { DialogModal } from "../_common/DialogModal/DialogModal";
import { AppHeader } from "../App/AppHeader/AppHeader";
import { AppFooter } from "../App/AppFooter/AppFooter";
import { AppDesktopFooter } from "../App/AppFooter/AppDesktopFooter";
import OrderSettingsComponent from "../OrderSettings/OrderSettingsComponent";
import { DashboardNewsFeed } from "./DashboardNewsFeed/DashboardNewsFeed";
import { AppDesktopHeader } from "../App/AppHeader/AppDesktopHeader";
import { DashboardActionButtons } from "./DashboardActionButtons";

//Assets
import { ReactComponent as IconUtensils } from "../_common/icons/IconUtensils.svg";
import { ReactComponent as IconArrowRight } from "../_common/icons/IconArrowRight.svg";
import { ReactComponent as IconBill } from "../_common/icons/IconBill.svg";
import { ReactComponent as IconArrowUp } from "../_common/icons/IconArrowUp.svg";

// CSS
import "./Dashboard.css";
import "./DashboardOrderButtons.css";

export const Dashboard = () => {
  const isLocalhost = process.env.NODE_ENV === "development";
  const merchantConfigContext = useContext(MerchantConfigContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);

  const skin = merchantConfigContext.skin;
  const isOrderingEnabled = cws5ModuleContext.isOnlineOrderingEnabled;

  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;

  const userRoleContext = useContext(UserRoleContext);
  const userStatus = userRoleContext.status;

  const branding = useContext(BrandingContext);
  //const allStores = useContext(LocationsContext);
  const foodMenu = useContext(MenuContext);
  const appLabels = useContext(AppLabelsContext);
  const manifestContext = useContext(ManifestContext);

  const appSettings = useContext(AppSettingsContext);
  const relativePath = appSettings["relative-path"];

  const orderContext = useContext(OrderContext);

  const storeContext = useContext(StoreContext);
  const activeOrderStore = storeContext.activeOrderStore;

  const orderTimeContext = useContext(OrderTimeContext);

  const { hideOrderModule } = useHideOrderModule();

  const isPayOnly = appSettings["is-pay-only"] === "yes" && activeOrderType === "dinein";
  const history = useHistory();

  const IS_GIVEX_HOST = !isLocalhost;
  const PATHNAME = window.location.pathname;
  const IS_DEFAULT_LANGUAGE = IS_GIVEX_HOST
    ? PATHNAME.split("/")[3] === ""
    : PATHNAME.split("/")[1] === "";

  useEffect(() => {
    sessionStorage.removeItem(skin + "_isComingFromRewards");
    const historyState = history.location.state;

    if (historyState && historyState.showOrderTimeModal) {
      orderContext.checkOrderSettings();
    }
  }, []);

  const deviceWidth = useWindowSize().width;

  const [showLastLogin, setShowLastLogin] = useState(null);
  const [lastLoginTimeStamp, setLastLoginTimestamp] = useState("");

  useEffect(() => {
    localforage.getItem(skin + "__lastLoginTimeStampDisplayed").then((displayed) => {
      if (displayed) {
        setShowLastLogin(false);
      } else {
        localforage.getItem(skin + "__lastLoginTimestamp").then((storedTimestamp) => {
          if (storedTimestamp) {
            const timestampSplit = storedTimestamp.split(" ");

            // .replace is used for Safari to understand the date format
            const date = timestampSplit[0].replace(/-/g, "/");

            const time = timestampSplit[1]; // keep the timezone, so that the time stamp gets converted to user's local time

            const timestamp = date + " " + time;

            setLastLoginTimestamp(timestamp);
            setShowLastLogin(true);
            localforage.setItem(skin + "__lastLoginTimeStampDisplayed", true);
          }
        });
      }
    });
  }, []);

  const [whatsNewSlidesData, setWhatsNewSlidesData] = useState(null);
  useEffect(() => {
    getMarquiWhatsNewSliderData(relativePath).then((fetchedSlidesData) => {
      if (fetchedSlidesData) {
        if (IS_DEFAULT_LANGUAGE || isLocalhost) {
          setWhatsNewSlidesData(fetchedSlidesData);
        } else {
          const tempImages = jsonCopy(fetchedSlidesData);
          tempImages.forEach((image) => {
            const imageCopy = jsonCopy(image);
            image["image-src"] = "../" + imageCopy["image-src"];
          });
          setWhatsNewSlidesData(fetchedSlidesData);
        }
      }
    });
  }, []);

  const [dashboardNewsFeed, setDashboardNewsFeed] = useState(null);
  useEffect(() => {
    getMarquiNewsFeedSliderData(relativePath).then((fetchedCards) => {
      if (fetchedCards) {
        if (IS_DEFAULT_LANGUAGE || isLocalhost) {
          setDashboardNewsFeed(fetchedCards);
        } else {
          const tempImages = jsonCopy(fetchedCards);
          tempImages.forEach((image) => {
            const imageCopy = jsonCopy(image);
            image["image-src"] = "../" + imageCopy["image-src"];
          });
          setDashboardNewsFeed(fetchedCards);
        }
      }
    });
  }, []);

  const dashboardMainRef = useRef();

  useEffect(() => {
    localforage.removeItem(skin + "__storeIdToBeFaved");
  }, []);

  /** This use effect will trigger the prompt for allowing notiifcations to be sent if XP is enabled */
  const [showDialogForXP, setShowDialogForXP] = useState(false);
  useEffect(() => {
    function showPrompt() {
      setShowDialogForXP(true);
    }
    if (window.xtremepush) {
      window.xtremepush("push", "auto_prompt", false);

      function WebpushPrompt() {
        window.xtremepush("ready", function () {
          //if our session storage item does not exist, create it
          if (sessionStorage.getItem(skin + "__sessionStoragePrompt") === null) {
            sessionStorage.setItem(skin + "__sessionStoragePrompt", 1);
            var permission = window.xtremepush("push", "permission");
            // This will exclude people who have already subscribed / denied push notifications
            // This will also exclude browsers with no push notifications support
            if (permission === "default") {
              showPrompt();
            }
          }
        });
      }
      WebpushPrompt();
    } /*else {
      //For debugging purposes
      showPrompt();
    }*/
  }, []);

  const [isShowStaticOrderButton, setIsShowStaticOrderButton] = useState(false);
  const [isShowStatisPayBillButton, setIsShowStaticPayBillButton] = useState(false);
  const [isShowScrollToTopButton, setIsShowScrollToTopButton] = useState(false);
  const [scrollToTopButtonVisibleClass, setScrollToTopButtonVisibleClass] = useState("not-visible");
  const [rewardContainerTopPosition, setRewardContainerTopPosition] = useState(0);

  //Set the initial position of the rewards container
  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("dashboard-rewards-button")) {
        setRewardContainerTopPosition(
          document.getElementById("dashboard-rewards-button").getBoundingClientRect().bottom + 10
        );
      }
    }, 500);
  }, [deviceWidth]);

  useEffect(() => {
    let upScrollActivated = false;

    if (deviceWidth < 660) {
      if (document.getElementById("main-content-dashboard")) {
        document.getElementById("main-content-dashboard").onscroll = (e) => {
          const dashBoardSections = document.getElementById("dashboard-sections-container");
          const headerContainer = document.getElementsByClassName("dashboard-header")[0];
          const dashOrderNowButton = document.getElementById("dashboard-order-now-button");
          const dashReadyToPayContainer = document.getElementById("dashboard-pay-bill");

          const contentHeight = document.getElementById(
            "dashboard-sections-container"
          ).clientHeight;
          const scrollAmount = document.getElementById("main-content-dashboard").scrollTop;
          const viewPortHeight = document.getElementById("main-content-dashboard").clientHeight;
          //Show the scroll to top button when the user scrolls down more than 20% of the screen
          //and if the content is larger than the view port height
          if (contentHeight > viewPortHeight && scrollAmount > viewPortHeight * 0.3) {
            setIsShowScrollToTopButton(true);
          } else {
            setIsShowScrollToTopButton(false);
          }

          //** Handling the scroll behaviour of the main content and the header */
          if (dashBoardSections && headerContainer) {
            const dashBoardSectionsTop = dashBoardSections.getBoundingClientRect().top;
            const headerContainerTop = headerContainer.getBoundingClientRect().top;
            const headerContainerBottom = headerContainer.getBoundingClientRect().bottom;
            const headerContainerHeight = headerContainer.getBoundingClientRect().height;

            if (dashBoardSectionsTop <= headerContainerBottom && !upScrollActivated) {
              upScrollActivated = true;
              headerContainer.style.cssText = `
            top: ${dashBoardSectionsTop - headerContainerHeight + "px"};
            width: 100%;
          `;
            } else if (dashBoardSectionsTop >= 0 && upScrollActivated) {
              if (headerContainerTop > 0) {
                headerContainer.style.cssText = `
              top: 0;
              width: 100%;
            `;
                upScrollActivated = false;
              } else {
                headerContainer.style.cssText = `
              top: ${dashBoardSectionsTop - headerContainerHeight + "px"};
              width: 100%;
            `;
              }
            }
          }
          //Handling the appearance of the static order button
          if (dashOrderNowButton && !isPayOnly) {
            const dashOrderNowButtonTop = dashOrderNowButton.getBoundingClientRect().top;
            if (dashOrderNowButtonTop <= 0) {
              setIsShowStaticOrderButton(true);
            } else {
              setIsShowStaticOrderButton(false);
            }
          }

          //Handle the appearance of the static ready to pay button
          if (dashReadyToPayContainer && isPayOnly) {
            const dashReadyToPayContainerTop = dashReadyToPayContainer.getBoundingClientRect().top;
            if (dashReadyToPayContainerTop <= 0) {
              setIsShowStaticPayBillButton(true);
            } else {
              setIsShowStaticPayBillButton(false);
            }
          }
        };
      }
    }

    if (deviceWidth >= 660) {
      if (document.getElementsByClassName("App")) {
        document.getElementsByClassName("App")[0].onscroll = () => {
          const dashOrderNowButton = document.getElementById("dashboard-order-now-button");
          //Handling the appearance of the static order button
          if (dashOrderNowButton && !isPayOnly) {
            const dashOrderNowButtonTop = dashOrderNowButton.getBoundingClientRect().top;
            if (dashOrderNowButtonTop <= 0) {
              setIsShowStaticOrderButton(true);
            } else {
              setIsShowStaticOrderButton(false);
            }
          }

          if (document.getElementById("dashboard-sections-container")) {
            const contentHeight = document.getElementById(
              "dashboard-sections-container"
            ).clientHeight;
            const scrollAmount = document.getElementsByClassName("App")[0].scrollTop;
            const viewPortHeight = document.getElementsByClassName("App")[0].clientHeight;
            //Show the scroll to top button when the user scrolls down more than 20% of the screen
            //and if the content is larger than the view port height
            if (contentHeight > viewPortHeight && scrollAmount > viewPortHeight * 0.3) {
              setIsShowScrollToTopButton(true);
            } else {
              setIsShowScrollToTopButton(false);
            }
          }

          //Update the position of the rewards container
          if (document.getElementById("dashboard-rewards-button")) {
            setRewardContainerTopPosition(
              document.getElementById("dashboard-rewards-button").getBoundingClientRect().bottom +
                10
            );
          }
        };
      }
    }
  }, [deviceWidth]);

  useEffect(() => {
    let timer;

    if (isShowScrollToTopButton) {
      clearTimeout(timer);
      setScrollToTopButtonVisibleClass("");
    } else {
      timer = setTimeout(() => {
        setScrollToTopButtonVisibleClass("not-visible");
      }, 600);
    }
  }, [isShowScrollToTopButton]);

  const scrollToTop = () => {
    const container =
      deviceWidth >= 660
        ? document.getElementsByClassName("App")[0]
        : document.getElementById("main-content-dashboard");
    if (container) {
      container.style.scrollBehavior = "smooth";
      container.scrollTop = 0;
    }
  };

  /** Check if order type is selected without any store
   * if so clear the order type as well, when onlineorder routes is unmounted
   */
  useEffect(() => {
    localforage.getItem(skin + "__activeOrderStore").then((orderStore) => {
      if (!orderStore) {
        if (orderTypeContext.value !== "delivery") {
          orderTypeContext.update("");
        }
      }
    });
  }, []);

  return (
    <>
      {deviceWidth >= 660 && <AppDesktopHeader />}
      <div
        ref={dashboardMainRef}
        id="main-content-dashboard"
        className="main-content dashboard-main-content dashboard--animate"
      >
        {deviceWidth < 660 && (
          <AppHeader
            isFooter={true}
            isLogo={true}
            isLanguageButton={true}
            isOrderSettingsHidden={false}
            customClass={"dashboard-header"}
          />
        )}

        {deviceWidth < 660 &&
          !!branding["dashboard-banner-mobile"] &&
          isValidImage(branding["dashboard-banner-mobile"]) && (
            <HeroSection imageUrl={branding["dashboard-banner-mobile"]} />
          )}

        {/* Only render the hero/banner if image exists and image is valid */}
        {deviceWidth >= 660 && !!branding["dashboard-banner"] && (
          <HeroSection imageUrl={branding["dashboard-banner"]} />
        )}

        <div
          className="dashboard-sections-container desktop-container"
          id="dashboard-sections-container"
          style={{
            paddingTop: deviceWidth < 660 && !branding["dashboard-banner-mobile"] ? "30px" : "20px",
          }}
        >
          {deviceWidth < 660 && (
            <UserGreetings skin={skin} isLoggedIn={userStatus === "logged-in"} />
          )}

          {deviceWidth >= 660 && (
            <UserGreetings skin={skin} isLoggedIn={userStatus === "logged-in"} />
          )}

          {activeOrderType === "dinein" &&
            deviceWidth < 660 &&
            activeOrderStore &&
            activeOrderStore["vexilorConfig"] &&
            activeOrderStore["vexilorConfig"]["get_order_from_table"] === "t" && (
              <DashboardPayBill />
            )}

          <DashboardActionButtons rewardContainerTopPosition={rewardContainerTopPosition} />

          {/** What's new Slides */}
          {whatsNewSlidesData && whatsNewSlidesData.length > 0 && (
            <DashboardWhatsNew slidesData={whatsNewSlidesData} />
          )}

          {/** Featured Items */}
          {isOrderingEnabled && foodMenu.apiData && !hideOrderModule && <DashboardFeatured />}

          {/** News Feed */}
          {dashboardNewsFeed && dashboardNewsFeed.length > 0 && (
            <DashboardNewsFeed slidesData={dashboardNewsFeed} />
          )}
        </div>
        <AdditionalDisclaimer
          disclaimer={appLabels["order"]["dashboard-disclaimer"]}
          styleObject={deviceWidth >= 660 ? { paddingLeft: 0 } : {}}
        />
      </div>

      {/* Static Order Now Button */}
      {isShowStaticOrderButton && (
        <button
          aria-label={appLabels["general"]["order-now"]}
          onClick={() => {
            orderContext.checkOrderSettings(orderTypeContext, storeContext, orderTimeContext);
          }}
          className="dashboard-order-now-button-static desktop-container"
        >
          <div className="dashboard-order-buttons__inner-row">
            <div className="dahsboard-order-buttons__order-button-icon-container">
              <IconUtensils aria-hidden={true} />
            </div>
            <div className="dashboard-order-buttons__column order-now">
              <span>
                {appLabels["general"]["order-now"]} <IconArrowRight aria-hidden={true} />
              </span>
              <span>{appLabels["order"]["view-full-menu"]}</span>
            </div>
          </div>
        </button>
      )}

      {/* Static Ready to Pay Button */}
      {isShowStatisPayBillButton && (
        <button
          aria-label={appLabels["order"]["view-bill"]}
          onClick={() => {
            if (storeContext.activeOrderStoreTable) {
              history.push("/online-ordering/bill");
            } else {
              history.push("/online-ordering/dinein");
            }
          }}
          className="dashboard-order-now-button-static"
        >
          <div className="dashboard-order-buttons__inner-row">
            <div className="dahsboard-order-buttons__order-button-icon-container">
              <IconBill aria-hidden={true} />
            </div>
            <div className="dashboard-order-buttons__column order-now">
              <span>
                {appLabels["order"]["ready-to-pay"]} <IconArrowRight aria-hidden={true} />
              </span>
              <span>{appLabels["order"]["ready-to-pay-blurb"]}</span>
            </div>
          </div>
        </button>
      )}

      <OrderSettingsComponent />

      {/** Scroll to top button */}

      <button
        type="button"
        aria-label={appLabels["general"]["scroll-to-top"]}
        className={`dashboard-scroll-up-button ${
          !isShowScrollToTopButton ? "hidden" : ""
        } ${scrollToTopButtonVisibleClass}`}
        onClick={scrollToTop}
      >
        <IconArrowUp aria-hidden={true} />
      </button>

      {/*deviceWidth < 660 ? <AppFooter /> : <AppDesktopFooter />*/}
      {showLastLogin && <LastLoginDialog lastLoginTimeStamp={lastLoginTimeStamp} />}

      {showDialogForXP && (
        <DialogModal
          message={
            <div className="xp-subscription-container">
              <h2>{appLabels["general"]["xp-subscribe-to-notifications-header"]}</h2>
              <p>
                {appLabels["general"]["xp-subscribe-to-notifications-message"].replace(
                  "[merchant-name]",
                  manifestContext.name
                )}
              </p>
            </div>
          }
          confirmAction={() => {
            window.xtremepush("push", "prompt");
            setShowDialogForXP(false);
          }}
          isCancelConfirm={true}
          resetRemoveDialog={() => {
            setShowDialogForXP(false);
          }}
          isModifyText={appLabels["order"]["not-now"]}
          isCancelConfirmText={appLabels["general"]["dialog-modal-ok"]}
          isHTMLContent={true}
        />
      )}

      {deviceWidth < 660 ? <AppFooter /> : <AppDesktopFooter />}
    </>
  );
};

import React from "react";
import "./Form.css";

export const FormCheckbox = (props) => {
  const {
    isDefaultChecked,
    id,
    name,
    onCheckboxChange,
    value,
    isDisabled,
    label,
    price,
    fieldData,
    fieldWrapperClass,
    handleChangeFromParent,
  } = props;

  return (
    <div
      className={`form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--checkbox${
        fieldWrapperClass || ""
      }`}
    >
      <input
        type="checkbox"
        defaultChecked={isDefaultChecked}
        className="form__field--checkbox-radio form__field--checkbox visually-hidden"
        id={id}
        name={name}
        onChange={(e) => {
          if (handleChangeFromParent) handleChangeFromParent(e);
          onCheckboxChange(e);
        }}
        value={value}
        disabled={isDisabled}
      />
      <label
        htmlFor={id}
        className="form__field-label--checkbox-radio form__field-label--checkbox form__field-label--regular-checkbox"
      >
        <span>
          {fieldData.isRequired && "* "}
          {label}
        </span>
        {price && <span className="menu-item__addon-price">{price}</span>}
      </label>
      {!fieldData.isValid && fieldData.errorMessage !== "" && (
        <div className="form__field-error">{fieldData.errorMessage}</div>
      )}
    </div>
  );
};

FormCheckbox.displayName = "FormCheckbox";

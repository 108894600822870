import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useWindowSize from "../../../_common/hooks/useWindowSize";

/** Contexts */
import { MenuContext } from "../../Menu/MenuContext";
import CartContext from "../CartContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import AppSettingsContext from "../../../App/AppSettingsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import BrandingContext from "../../../App/BrandingContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

/** Helpers */
import { toDollars } from "../../../_common/helpers";
import {
  addItemToCart,
  getAllMenuItems,
  groupItemsBasedOnSubcategory,
  removeCartItemsFromList,
  removeDuplicatesFromSuggestedItemsBasedOnId,
} from "./SuggestedItemsHelpers";
import {
  onAddSuggestedItemXtreme,
  onProductClickXtreme,
} from "../../../_common/helpers/xtremePushHelper";
/** CSS */
import "./SuggestedItems.css";

const SuggestedItems = (props) => {
  const {
    setShowSuggestedItems,
    suggestedItemsGotGeneratedRef,
    suggestedItems,
    setSuggestedItems,
  } = props;

  const deviceWidth = useWindowSize().width;

  const history = useHistory();
  const menuContext = useContext(MenuContext);
  const cartContext = useContext(CartContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);
  const { skin } = useContext(MerchantConfigContext);

  const cartItems = cartContext.value || [];
  const menuData = menuContext.apiData;
  const isCategoryBased = menuContext.menuLevel === "threeLevels";

  const DEFAULT_NUMBER_OF_SUGGESTED_ITEMS = 3;

  const generateSuggestedItems = (numberOfItemsToBeGenerated) => {
    let allMenuItems = getAllMenuItems(isCategoryBased, menuData);

    /** remove the cart items from allMenuItems */
    let filteredItems = removeCartItemsFromList(allMenuItems, cartItems);
    if (filteredItems.length === 0) {
      return [];
    }

    /** if there is already a list of suggested items, remove them from the filteredItems List */
    if (suggestedItems !== null && suggestedItems.length > 0) {
      let suggestedItemsIdList = [];
      suggestedItems.forEach((item) => {
        suggestedItemsIdList.push(item.id);
      });

      filteredItems = filteredItems.filter(
        (tempItem) => !suggestedItemsIdList.includes(tempItem.id)
      );
    }

    /** Organize the valid items based on their subcategory id */
    let filteredItemsGroupedBasedOnSubcategory = groupItemsBasedOnSubcategory(filteredItems);

    /** select a random groups form filterItemsGroupedBasedOnSubcategory based on numberOfItemsToBeGenerated*/
    /** Ideally each suggested item should belong to a different subcategory */
    let suggestedItemsList = [];
    const subcategoryItemsGroup = filteredItemsGroupedBasedOnSubcategory.slice(
      0,
      numberOfItemsToBeGenerated
    );

    subcategoryItemsGroup.forEach((itemGroup) => {
      let itemGroupCopy = [...itemGroup];
      /** shuffle the itemGroup and pick one item */
      suggestedItemsList.push(itemGroupCopy.sort(() => 0.5 - Math.random()).slice(0, 1)[0]);
    });

    suggestedItemsList = removeDuplicatesFromSuggestedItemsBasedOnId(suggestedItemsList);

    return suggestedItemsList;
  };

  const goToItemsPage = (item) => {
    let path = "";

    onProductClickXtreme(item, item.categoryId, item.subcategoryId, skin);
    const isComboItem = item.type === "comboItem";
    if (isCategoryBased) {
      path = `/online-ordering/menu/category/${item.categoryId}/subcategory/${item.subcategoryId}/${
        isComboItem ? "combo" : "item"
      }/${item.id}`;
    } else {
      path = `/online-ordering/menu/subcategory/${item.subcategoryId}/${
        isComboItem ? "combo" : "item"
      }/${item.id}`;
    }

    history.push({
      pathname: path,
      state: { from: "suggestedItems" },
    });
  };

  const handleSuggestedItemOnclick = (item, e) => {
    if (item.requiresCustomization) {
      goToItemsPage(item);
    } else {
      addItemToCart(item, cartContext, cartItems, e);
      onAddSuggestedItemXtreme(item, item.categoryId, item.subcategoryId, skin);
      suggestedItemsGotGeneratedRef.current = false;
    }
  };

  useEffect(() => {
    if (
      suggestedItemsGotGeneratedRef.current === false &&
      menuContext.apiData &&
      suggestedItems === null
    ) {
      const tempSuggestedItems = generateSuggestedItems(DEFAULT_NUMBER_OF_SUGGESTED_ITEMS);

      if (tempSuggestedItems.length === 0) {
        setShowSuggestedItems(false);
      } else {
        setShowSuggestedItems(true);
        setSuggestedItems(tempSuggestedItems);
      }
    }
  }, [suggestedItemsGotGeneratedRef, menuContext]);

  useEffect(() => {
    if (suggestedItemsGotGeneratedRef.current === false && suggestedItems !== null) {
      const oneSuggestedItem = generateSuggestedItems(1);

      if (oneSuggestedItem.length === 0) {
        setShowSuggestedItems(false);
      } else {
        /** remove the cart items from the list and add the new one */
        const tempSuggestedItems = [...suggestedItems];
        let filteredSuggestedItems = removeCartItemsFromList(tempSuggestedItems, cartItems);
        filteredSuggestedItems.push(oneSuggestedItem[0]);
        setSuggestedItems(filteredSuggestedItems.slice(0, DEFAULT_NUMBER_OF_SUGGESTED_ITEMS));
      }
    }
  }, [suggestedItemsGotGeneratedRef]);

  return (
    <>
      {suggestedItems && (
        <>
          {deviceWidth < 660 ? (
            <ul className="suggestedItems__list">
              {suggestedItems.map((suggestedItem, index) => (
                <React.Fragment key={`${suggestedItem.id}-${index}`}>
                  <SuggestedItemCard
                    suggestedItem={suggestedItem}
                    branding={branding}
                    appLabels={appLabels}
                    appSettings={appSettings}
                    appLanguage={appLanguage}
                    handleSuggestedItemOnclick={handleSuggestedItemOnclick}
                    itemIndex={index}
                  />
                </React.Fragment>
              ))}
            </ul>
          ) : (
            <>
              {/** Desktop */}
              <ul className="suggestedItems__list">
                {suggestedItems.map((suggestedItem, index) => (
                  <React.Fragment key={`${suggestedItem.id}-${index}`}>
                    {index % 2 === 0 && (
                      <SuggestedItemCard
                        suggestedItem={suggestedItem}
                        branding={branding}
                        appLabels={appLabels}
                        appSettings={appSettings}
                        appLanguage={appLanguage}
                        handleSuggestedItemOnclick={handleSuggestedItemOnclick}
                        itemIndex={index}
                      />
                    )}
                  </React.Fragment>
                ))}
              </ul>

              <ul className="suggestedItems__list">
                {suggestedItems.map((suggestedItem, index) => (
                  <React.Fragment key={`${suggestedItem.id}-${index}`}>
                    {index % 2 > 0 && (
                      <SuggestedItemCard
                        suggestedItem={suggestedItem}
                        branding={branding}
                        appLabels={appLabels}
                        appSettings={appSettings}
                        appLanguage={appLanguage}
                        handleSuggestedItemOnclick={handleSuggestedItemOnclick}
                        itemIndex={index}
                      />
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </>
  );
};

const SuggestedItemCard = (props) => {
  const {
    suggestedItem,
    appLabels,
    appSettings,
    appLanguage,
    handleSuggestedItemOnclick,
    branding,
    itemIndex,
  } = props;

  const noImagePlaceholder = branding["no-image-available-placeholder"];

  const [isItemDetailsCollapsed, setIsItemDetailsCollapsed] = useState(true);

  const toggleItemDetails = () => {
    const currentState = isItemDetailsCollapsed;
    setIsItemDetailsCollapsed(!currentState);

    const content = document.getElementById(`${suggestedItem.id}-${itemIndex}`);

    if (!currentState) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  };

  return (
    <li>
      <div className="suggestedItems__info_container">
        <div className="suggestedItems__image_wrapper">
          <img src={suggestedItem.imageUrl || noImagePlaceholder} alt={suggestedItem.name} />
        </div>
        <div className="suggestedItems__name_price_wrapper">
          <span className="cartItem__heading">{suggestedItem.name}</span>
          <span className="cartItem__heading">
            {!!suggestedItem.calorie_count &&
              suggestedItem.calorie_count + ` ${appLabels["order"]["calories"]} | `}

            {toDollars(
              appSettings["currency-symbol"],
              appSettings["currency-symbol-side"],
              suggestedItem["price"],
              appLanguage
            )}
          </span>

          <div className="suggestedItems__button_wrapper">
            <button
              type="button"
              className="suggested_items__view-details"
              onClick={toggleItemDetails}
              style={
                suggestedItem.description === ""
                  ? { visibility: "hidden" }
                  : { visibility: "visible" }
              }
            >
              {isItemDetailsCollapsed
                ? appLabels["order"]["view-details"]
                : appLabels["order"]["hide-details"]}{" "}
            </button>
            <button
              type="button"
              className={`${
                suggestedItem.requiresCustomization
                  ? "suggestedItems__customize button button--primary  button--pill-shaped button--primary-small"
                  : "suggestedItems__add_to_cart c-button-reset c-plus-to-check"
              }`}
              onClick={(e) => {
                handleSuggestedItemOnclick(suggestedItem, e);
              }}
              data-state={suggestedItem.requiresCustomization ? "" : "inactive"}
              aria-label={suggestedItem.requiresCustomization ? appLabels["order"]["customize"] + " " + suggestedItem.name : appLabels["order"]["favorite-add-to-order"] + " " + suggestedItem.name}
            >
              {suggestedItem.requiresCustomization ? appLabels["order"]["customize"] : <></>}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`suggested_items__item-description ${
          isItemDetailsCollapsed ? " suggested_items__item-description-hidden" : ""
        }`}
        id={`${suggestedItem.id}-${itemIndex}`}
      >
        <p>{suggestedItem.description}</p>
      </div>
    </li>
  );
};

export default SuggestedItems;

import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";

import {
  formatStoreHours,
  getTodayClosingTime,
  getTodayOpeningTime,
  getOpeningTime,
  checkPickupInterval,
  getClosingTime,
  getDayOfWeekFromIndex,
  formatStoreTimeOverrides,
  isTimeSlotBlocked,
} from "../../OnlineOrdering/Locations/helpers/isStoreOpenOrClosed";

import {
  roundToNearestQuarter,
  roundTime,
  newTime,
  getTimeAmPm,
  getMaxIteration,
  prettifyDate,
} from "./dateHelpers";

import MerchantConfigContext from "../../App/MerchantConfigContext";
import StoreContext from "../../OnlineOrdering/StoreContext";
import BrandingContext from "../../App/BrandingContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import OrderTimeContext from "../../OnlineOrdering/OrderTimeContext";
import AppLabelsContext from "../../App/AppLabelsContext";

import { ReactComponent as IconClock } from "../../_common/icons/IconClock.svg";
import { ReactComponent as IconCalendar } from "../../_common/icons/IconCalendar.svg";
import { DashboardSelect } from "./DashboardSelect";
import useOrderTypeMinInterval from "../../_common/hooks/useOrderTypeMinInterval";

const LoadingDot = () => {
  const brandingContext = useContext(BrandingContext);
  const primaryColor = brandingContext["primary-colour"];
  return <div className="dashboard__loading-dot" style={{ backgroundColor: primaryColor }} />;
};

const getMarquiMaxFutureOrderDates = async () => {
  try {
    const data = await fetch("settings.json");
    const json = await data.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

const checkHoursValidity = (hours, orderCutOffTimeMinutes, isToday) => {
  let areValidHours;

  const openingTime = hours.split(" - ")[0];
  const closingTime = hours.split(" - ")[1];

  const openingTimeHours = openingTime.split(":")[0];
  const openingTimeMinutes = openingTime.split(":")[1];

  const closingTimeHours = closingTime.split(":")[0];
  const closingTimeMinutes = closingTime.split(":")[1];

  let openingTimeDate = new Date();
  openingTimeDate.setHours(openingTimeHours);
  openingTimeDate.setMinutes(openingTimeMinutes);

  let closingTimeDate = new Date();
  closingTimeDate.setHours(closingTimeHours);
  closingTimeDate.setMinutes(closingTimeMinutes);

  const timeDifference = Math.abs(closingTimeDate - openingTimeDate); // in milliseconds
  const minutesDifference = Math.floor(timeDifference / 60000);

  const cutOffTime = new Date(closingTimeDate - orderCutOffTimeMinutes * 60000);
  cutOffTime.setSeconds(0, 0);

  const now = new Date();
  const nowAndClosingTimeDifference = Math.abs(now - closingTimeDate); // in milliseconds
  const nowAndClosingTimeMinutesDifference = Math.floor(nowAndClosingTimeDifference / 60000);

  if (
    minutesDifference <= orderCutOffTimeMinutes ||
    (isToday && nowAndClosingTimeMinutesDifference < orderCutOffTimeMinutes)
  ) {
    areValidHours = false;
  } else {
    areValidHours = true;
  }

  return areValidHours;
};

const HOSTNAME = window.location.hostname;
const IS_LOCALHOST =
  HOSTNAME === "localhost" ||
  (HOSTNAME.split(".").length === 4 && isNaN(Number(HOSTNAME.split("."))));

const DEFAULT_MAX_ORDER_DATES = 4;

export const OrderDateAndTimeDropdowns = ({ activeOrderType }) => {
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;

  const storeContext = useContext(StoreContext);
  const activeOrderStore = storeContext.activeOrderStore;

  const orderTimeContext = useContext(OrderTimeContext);

  const activeStoreVexilorConfig = activeOrderStore.vexilorConfig;
  const pickupIncrement = activeStoreVexilorConfig.pickup_inc_interval;

  const [orderDateOptions, setOrderDateOptions] = useState(null);
  const [activeOrderDateOption, setActiveOrderDateOption] = useState(null);

  const pickupMinInterval = useOrderTypeMinInterval(
    activeStoreVexilorConfig,
    activeOrderType,
    skin,
    activeOrderStore
  );

  const [futureOrderDatesAllowed, setFutureOrderDatesAllowed] = useState(true);

  const [maxFutureDates, setMaxFutureDates] = useState("idle");
  useEffect(() => {
    if (IS_LOCALHOST) {
      setMaxFutureDates(DEFAULT_MAX_ORDER_DATES); // app fallback
    } else {
      getMarquiMaxFutureOrderDates().then((marquiMaxDates) => {
        if (marquiMaxDates && marquiMaxDates["max-order-dates"]) {
          setMaxFutureDates(Number(marquiMaxDates["max-order-dates"]));
        } else {
          setMaxFutureDates(DEFAULT_MAX_ORDER_DATES); // app fallback
        }
      });
    }
  }, []);

  const appLanguage = useContext(AppLanguageContext);

  const appLabels = useContext(AppLabelsContext);

  /**If there is previously selected order time, but it is passed. remove it from storage. */
  useEffect(() => {
    localforage.getItem(skin + "__orderTime").then((storedOrderTime) => {
      if (storedOrderTime) {
        const prevStoredOrderTime = new Date(storedOrderTime.value);
        const currentTime = new Date();

        if (prevStoredOrderTime < currentTime) {
          localforage.removeItem(skin + "__orderTime");
          localforage.removeItem(skin + "__orderDate");
        }
      }
    });
  }, []);

  /*
    Each time an active order store changes:
    
    1. Generate order date options while checking if store is closing soon
    2. Set the active order date option
  */
  useEffect(() => {
    if (maxFutureDates !== "idle") {
      const isStoreClosingSoon = checkPickupInterval(
        pickupMinInterval,
        activeOrderStore.closingTime,
        activeOrderStore.hours
      );

      const storeHours = activeOrderStore.hours;

      const today = new Date().getDay();

      let startingDay = isStoreClosingSoon ? today + Math.ceil(pickupMinInterval / 1440) : today;

      if (maxFutureDates === 0 && isStoreClosingSoon) {
        setFutureOrderDatesAllowed(false);
      }

      const options = [];

      let orderCutOffTimeMinutes = 0;
      if (
        activeStoreVexilorConfig.ordering_cut_off_time &&
        activeStoreVexilorConfig.ordering_cut_off_time !== "None"
      ) {
        orderCutOffTimeMinutes = Number(activeStoreVexilorConfig.ordering_cut_off_time);
      }
      // Generate each order date option
      for (let day = startingDay; day < startingDay + maxFutureDates + 1; day++) {
        const dayIndex = day > 6 ? day % 7 : day; // converts all date numbers after 6 to a 0-6 day number
        const storeDayHours = storeHours[getDayOfWeekFromIndex(dayIndex)];
        const nowDayOfWeek = new Date().getDay();
        const isToday = nowDayOfWeek === day;
        if (
          storeDayHours !== "closed" &&
          checkHoursValidity(storeDayHours, orderCutOffTimeMinutes, isToday)
        ) {
          const now = new Date();
          const nowDateNumber = now.getDate();

          now.setDate(nowDateNumber + (day - nowDayOfWeek));

          const option = {
            value: now.getMonth() + "-" + now.getDate(), // the day of a date as a number (1-31)
            displayValue: isToday ? appLabels["order"]["today"] : prettifyDate(now, appLanguage), // stringified Date object
          };
          options.push(option);
        }
      }

      /**
       * Check the closing hours of the first date in the options list.
       * If the current time + min interval is passed the closing time for that date the date should be removed from the list
       */
      if (options.length > 0) {
        const month = options[0].value.split("-")[0];
        const date = options[0].value.split("-")[1];

        let activeOrderDate = new Date();
        activeOrderDate.setMonth(month, 1); // 1 is a dummy value to prevent JS from skipping months without the current date
        activeOrderDate.setDate(date);

        let selectedDay = new Date(activeOrderDate).getDay();
        let selectedDate = Number(date);

        const selectedDayApiOpeningTime = getOpeningTime(storeHours, selectedDay);
        const selectedDayApiClosingTime = getClosingTime(storeHours, selectedDay);

        const selectedDayStoreHours = formatStoreHours(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime
        );

        let selectedDayStoreOpeningTime = selectedDayStoreHours.formattedOpeningTime;
        let selectedDayStoreClosingTime = selectedDayStoreHours.formattedClosingTime;

        let d = new Date(selectedDayStoreOpeningTime);
        let d1 = new Date(selectedDayStoreClosingTime);

        let nextDay = 0;

        // Account for closing time falling into the next day
        if (selectedDayStoreClosingTime.getDay() > selectedDayStoreOpeningTime.getDay()) {
          nextDay = 1;
        }

        selectedDayStoreOpeningTime = new Date(d.setDate(selectedDate));
        selectedDayStoreClosingTime = new Date(d1.setDate(selectedDate + nextDay));

        const currentTime = new Date();
        const currentWithMinInterval = new Date(currentTime.getTime() + pickupMinInterval * 60000);

        const cutOffTime = new Date(
          new Date(selectedDayStoreClosingTime - orderCutOffTimeMinutes * 60000).setMonth(month)
        );
        if (currentWithMinInterval > cutOffTime) {
          options.splice(0, 1);
        }
      }

      // Set all available options
      setOrderDateOptions(options);

      // Set the first available option only if it wasn't already retrieved from storage
      localforage.getItem(skin + "__orderDate").then((storedOrderDate) => {
        //if there is a order date in storage, also check to see the stored date also exists in the new generated date options
        if (
          storedOrderDate &&
          options.filter((date) => JSON.stringify(date) === JSON.stringify(storedOrderDate))
            .length > 0
        ) {
          setActiveOrderDateOption(storedOrderDate);
        } else {
          setActiveOrderDateOption(options[0]);
          localforage.setItem(skin + "__orderDate", options[0]);
        }
      });
    }
  }, [activeOrderStore, maxFutureDates, pickupMinInterval]);

  const [orderTimeOptions, setOrderTimeOptions] = useState(null);
  const [activeOrderTimeOption, setActiveOrderTimeOption] = useState(null);

  /** this function returns the correct time increment interval that should be used based on user's current time and BE overrides */
  const getIntermittentIncrementInterval = (timeOverrides, timeSlot, incrementInterval) => {
    let tempIncrementInterval = incrementInterval;

    const timeSlotCopy = new Date(timeSlot.getTime() + incrementInterval * 60000);
    timeSlotCopy.setMilliseconds(0);

    const currentTime = new Date();
    currentTime.setDate(timeSlotCopy.getDate());
    currentTime.setMilliseconds(0);

    for (let i = 0; i < timeOverrides.length; i++) {
      const override = timeOverrides[i];
      if (
        !override.isBlocked &&
        timeSlotCopy.getTime() >= override.start.getTime() &&
        timeSlotCopy.getTime() <= override.end.getTime()
      ) {
        const currentPlusLead = new Date(currentTime.getTime() + override.leadTime * 60000);
        currentPlusLead.setSeconds(0);

        //return the difference between current time + lead time and the time slot
        if (currentPlusLead.getTime() < timeSlotCopy.getTime()) {
          tempIncrementInterval = incrementInterval;
        } else {
          tempIncrementInterval = Math.abs(
            parseInt((currentPlusLead.getTime() - timeSlot.getTime()) / 60000)
          );
        }
        break;
      }
    }

    return tempIncrementInterval <= 0 || tempIncrementInterval < incrementInterval
      ? incrementInterval
      : tempIncrementInterval;
  };

  /*
    Each time an active order date changes:
    
    1. Generate order time options for today and future days
    2. Set the active order time option
  */
  useEffect(() => {
    if (activeOrderDateOption && storeContext) {
      let orderCutOffTimeMinutes = 0;
      if (
        activeStoreVexilorConfig.ordering_cut_off_time &&
        activeStoreVexilorConfig.ordering_cut_off_time !== "None"
      ) {
        orderCutOffTimeMinutes = Number(activeStoreVexilorConfig.ordering_cut_off_time);
      }

      // convert date number back to date in ms
      const month = activeOrderDateOption.value.split("-")[0];
      const date = activeOrderDateOption.value.split("-")[1];

      let activeOrderDate = new Date();
      activeOrderDate.setMonth(month, 1); // 1 is a dummy value to prevent JS from skipping months without the current date
      activeOrderDate.setDate(date);

      const storeHours = activeOrderStore.hours;
      const todayApiOpeningTime = getTodayOpeningTime(storeHours);
      const todayApiClosingTime = getTodayClosingTime(storeHours);

      let now = new Date();
      let roundedMinutes = roundToNearestQuarter(now.getMinutes());
      let roundedTime = roundTime(now, roundedMinutes);

      const isStoreOpen =
        activeOrderStore.isOpen &&
        todayApiOpeningTime !== "closed" &&
        todayApiClosingTime !== "closed";

      const placingOrderForToday = now.getDate() === new Date(activeOrderDate).getDate();

      const options = [];

      const formattedStoreOverrides =
        activeOrderType === "dinein"
          ? []
          : formatStoreTimeOverrides(
              activeOrderType === "delivery"
                ? activeStoreVexilorConfig.delivery_min_interval_override
                : activeStoreVexilorConfig.pickup_min_interval_override,
              activeOrderDate
            );
      const blockedTimeIntervals =
        Object.keys(formattedStoreOverrides).length === 0
          ? []
          : formattedStoreOverrides[getDayOfWeekFromIndex(activeOrderDate.getDay())].filter(
              (interval) => interval.isBlocked
            );
      if (isStoreOpen && placingOrderForToday) {
        // Store is open and ordering for today

        const storeHours = formatStoreHours(todayApiOpeningTime, todayApiClosingTime);
        const storeClosingTime = storeHours.formattedClosingTime;
        const cutOffTime = new Date(storeClosingTime - orderCutOffTimeMinutes * 60000);

        // Get the total number of all possible order timeslots
        const maxIteration = getMaxIteration(
          now,
          storeClosingTime,
          pickupMinInterval,
          pickupIncrement
        );

        for (let t = 0; t < maxIteration; t++) {
          roundedMinutes = roundToNearestQuarter(now.getMinutes());
          roundedTime = roundTime(now, roundedMinutes, true);
          const increment = getIntermittentIncrementInterval(
            formattedStoreOverrides,
            roundedTime,
            pickupIncrement
          );
          const nextTime = newTime(roundedTime, t === 0 ? pickupMinInterval : increment); // "In {pickupMinInterval OR pickupIncrement} min"
          const nextTimeWithResetSeconds = new Date(nextTime);
          nextTimeWithResetSeconds.setSeconds(0, 0); // reset seconds to 0 to allow pre-select based on storage value

          if (nextTimeWithResetSeconds <= cutOffTime) {
            const option = {
              value: nextTimeWithResetSeconds,
              displayValue:
                t === 0
                  ? `${appLabels["order"]["asap"]} (~${pickupMinInterval} ${appLabels["order"]["minutes-short-hand"]})`
                  : getTimeAmPm(nextTimeWithResetSeconds),
            };

            if (!isTimeSlotBlocked(option.value, blockedTimeIntervals)) {
              options.push(option);
            }

            now = new Date(nextTimeWithResetSeconds);
          } else {
            break; // stop the loop when all valid timeslots are generated
          }
        }
      } else {
        // Store is closed and/or ordering for future date
        let selectedDay = new Date(activeOrderDate).getDay();
        let selectedDate = Number(date);

        const selectedDayApiOpeningTime = getOpeningTime(storeHours, selectedDay);
        const selectedDayApiClosingTime = getClosingTime(storeHours, selectedDay);

        const selectedDayStoreHours = formatStoreHours(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime
        );

        let selectedDayStoreOpeningTime = selectedDayStoreHours.formattedOpeningTime;
        let selectedDayStoreClosingTime = selectedDayStoreHours.formattedClosingTime;

        let d = new Date(selectedDayStoreOpeningTime);
        let d1 = new Date(selectedDayStoreClosingTime);

        let nextDay = 0;

        // Account for closing time falling into the next day
        if (selectedDayStoreClosingTime.getDay() > selectedDayStoreOpeningTime.getDay()) {
          nextDay = 1;
        }

        selectedDayStoreOpeningTime = new Date(d.setDate(selectedDate));
        selectedDayStoreClosingTime = new Date(d1.setDate(selectedDate + nextDay));

        selectedDayStoreOpeningTime.setMonth(month);
        selectedDayStoreClosingTime.setMonth(month);
        const maxIteration = getMaxIteration(
          selectedDayStoreOpeningTime,
          selectedDayStoreClosingTime,
          pickupMinInterval,
          pickupIncrement
        );

        const currentTime = new Date();
        for (let t = 0; t <= maxIteration; t++) {
          if (t === 0) {
            now = new Date(activeOrderDate);
            /** if the current time + pickup min interval is not passed the opening hours of the store
             * set the starting hour and minute to match the opening time of the location
             */
            if (
              new Date(currentTime.getTime() + pickupMinInterval * 60000) <
              selectedDayStoreOpeningTime
            ) {
              now.setHours(selectedDayStoreOpeningTime.getHours()); //adjust the current time's hour
              now.setMinutes(selectedDayStoreOpeningTime.getMinutes());
            } else {
              //else set the starting time to current time + min interval
              now = new Date(currentTime.getTime() + pickupMinInterval * 60000);
            }

            now.setSeconds(0);
            const defaultOption = {
              value: "Select Time",
              displayValue: appLabels["order"]["select-time"],
            };
            options.push(defaultOption);
          }

          roundedMinutes = t === 0 ? 0 : roundToNearestQuarter(now.getMinutes());
          roundedTime = roundTime(now, roundedMinutes);
          var nextTime = newTime(roundedTime, t === 0 ? 0 : pickupIncrement); // "In {pickupMinInterval OR pickupIncrement} min"

          const cutOffTime = new Date(
            new Date(selectedDayStoreClosingTime - orderCutOffTimeMinutes * 60000).setMonth(month)
          );
          cutOffTime.setDate(nextTime.getDate());
          nextTime.setSeconds(0, 0); // reset seconds and milliseconds for date comparison
          cutOffTime.setSeconds(0, 0); // reset seconds and milliseconds for date comparison

          if (nextTime < cutOffTime) {
            const option = {
              value: nextTime,
              displayValue: getTimeAmPm(nextTime.getTime()),
            };

            if (!isTimeSlotBlocked(option.value, blockedTimeIntervals)) {
              options.push(option);
            }

            now = nextTime;
          } else if (nextTime.getTime() === cutOffTime.getTime()) {
            const option = {
              value: nextTime,
              displayValue: getTimeAmPm(nextTime.getTime()),
            };
            if (!isTimeSlotBlocked(option.value, blockedTimeIntervals)) {
              options.push(option);
            }
            break;
          } else {
            break; // stop the loop when all valid timeslots are generated
          }
        }
      }

      // Set all available options
      setOrderTimeOptions(options);
      // Set the first available option only if it wasn't already retrieved from storage
      localforage.getItem(skin + "__orderTime").then((storedOrderTime) => {
        if (
          storedOrderTime &&
          options.filter((time) => JSON.stringify(time) === JSON.stringify(storedOrderTime))
            .length > 0
        ) {
          setActiveOrderTimeOption(storedOrderTime);
        } else {
          setActiveOrderTimeOption(options[0]);
          orderTimeContext.update(options[0]); // update order time in context
        }
      });
    }
  }, [activeOrderDateOption, storeContext]);

  // Updated currently active order date option on select change
  const onOrderDateChange = (newValue, newDisplayValue) => {
    localforage.removeItem(skin + "__orderTime"); // reset order time in storage

    const newActiveOption = { ...activeOrderDateOption };

    newActiveOption.value = newValue;
    newActiveOption.displayValue = newDisplayValue;

    setActiveOrderDateOption(newActiveOption);
    localforage.setItem(skin + "__orderDate", newActiveOption);
  };

  // Updated currently active order time option on select change
  const onOrderTimeChange = (newValue, newDisplayValue) => {
    const newActiveOption = { ...activeOrderTimeOption };

    newActiveOption.value = newValue === "Select Time" ? newValue : new Date(newValue);
    newActiveOption.displayValue = newDisplayValue;

    setActiveOrderTimeOption(newActiveOption);
    orderTimeContext.update(newActiveOption); // update order time in context
  };

  // Check if dropdowns are ready to be rendered
  const [areDropdownsReady, setAreDropdownsReady] = useState(false);
  useEffect(() => {
    if (activeOrderDateOption && activeOrderTimeOption) setAreDropdownsReady(true);
  }, [activeOrderDateOption, activeOrderTimeOption]);

  useEffect(() => {
    if (orderDateOptions && orderDateOptions.length === 0) {
      setActiveOrderTimeOption(null);
      setActiveOrderDateOption(null);
      localforage.removeItem(skin + "__orderTime");
      localforage.removeItem(skin + "__orderDate");
    }
  }, [orderDateOptions]);

  const NUMBER_OF_LOADING_DOTS = 4;

  return (
    <>
      {orderDateOptions && !!orderDateOptions.length && futureOrderDatesAllowed ? (
        <>
          {areDropdownsReady ? (
            <div className="dashboard__selects-wrapper">
              <DashboardSelect
                options={orderDateOptions}
                activeOption={activeOrderDateOption}
                handleChangeAttempt={onOrderDateChange}
                id="dashboard__select-order-date"
                label="Select an order date"
                name="order dates"
                icon={<IconCalendar />}
              />
              <DashboardSelect
                options={orderTimeOptions}
                activeOption={activeOrderTimeOption}
                handleChangeAttempt={onOrderTimeChange}
                id="dashboard__select-order-time"
                label="Select an order time"
                name="order timeslots"
                icon={<IconClock />}
              />
            </div>
          ) : (
            <div className="dashboard__loading-dots-container">
              <div className="dashboard__loading-dots">
                {Array.from(Array(NUMBER_OF_LOADING_DOTS)).map((dot, index) => (
                  <LoadingDot key={index} />
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <p style={{ margin: `1em 0 0`, fontSize: "0.8em", textAlign: "center", color: "#ff2020" }}>
          {appLabels["order"]["no-future-dates"]}
        </p>
      )}
      <p className="dashboard__order-disclaimer">
        <em>{appLabels["order"]["pickup-and-delivery-times-disclaimer"]}</em>
      </p>
    </>
  );
};

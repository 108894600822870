// Libraries
import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

// Helper functions
import {
  getDayOfWeekFromIndex,
  getMonthFromIndex,
} from "../../../Locations/helpers/isStoreOpenOrClosed";
import { getTimeAmPm, getTrueBusinessDate } from "../../../../Dashboard/DashboardOrder/dateHelpers";

// API helper functions
import { getAPICardList } from "./apiHelpers/getAPICardList";
import { getAPIFullCardNumber } from "./apiHelpers/getAPIFullCardNumber";

// Contexts
import AppLanguageContext from "../../../../App/AppLanguageContext";
import MerchantConfigContext from "../../../../App/MerchantConfigContext";
import BrandingContext from "../../../../App/BrandingContext";
import OrderTimeContext from "../../../OrderTimeContext";
// import LoyaltyContext from "../../../../App/LoyaltyContext";
import AppLabelsContext from "../../../../App/AppLabelsContext";
import UserRoleContext from "../../../../App/UserRoleContext";
import StoreContext from "../../../StoreContext";
import AppSettingsContext from "../../../../App/AppSettingsContext";

// UI components
import { DummyFormSelect } from "../../../../_common/Form/DummyFormSelect";
import { AddAnotherGiftCard } from "./AddAnotherGiftCard";
import { AppliedGiftCardsBreakdown } from "./AppliedGiftCardsBreakdown/AppliedGiftCardsBreakdown";
import { RemainderGiftCardPayment } from "./RemainderGiftCardPayment";
import { CompleteOrderWithGiftCard } from "./CompleteOrderWithGiftCard";
import { DialogModal } from "../../../../_common/DialogModal/DialogModal";

// Assets
import { ReactComponent as IconPlus } from "../../../../_common/icons/IconPlus.svg";

// CSS
import "./PaymentMethodGiftCard.css";

export const PaymentMethodGiftCard = (props) => {
  const { paymentMethodExpanded, creditCardTypes, orderTotal, rewards, promoCode, coupon, isGCCheckout } = props;

  const merchantConfig = useContext(MerchantConfigContext);
  const skin = merchantConfig.skin;
  const history = useHistory();
  const brandingContext = useContext(BrandingContext);
  const secondaryColor = brandingContext["secondary-colour"];

  const storeContext = useContext(StoreContext);
  const activeOrderStore = storeContext.activeOrderStore;

  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  const [giftCards, setGiftCards] = useState(null);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  // Scroll to the top of the expand/collapse toggle button after details panel is expanded
  useEffect(() => {
    const timer = setTimeout(() => paymentMethodExpanded(), 300);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (loginToken) {
      let timer1;

      getAPICardList(
        skin,
        loginToken,
        appSettings["currency-symbol"],
        appSettings["currency-symbols-side"],
        appLanguage,
        brandingContext
      ).then((apiCardList) => {
        if (apiCardList.status === "expiredLoginToken") {
          userRoleContext.handleLoginTokenExpiration();
        } else if (apiCardList) {
          setGiftCards(apiCardList);
          setSelectedGiftCard(apiCardList[0]);
        }
      });

      return () => clearTimeout(timer1);
    }
  }, [loginToken]);

  const [selectedGiftCard, setSelectedGiftCard] = useState();
  const updateSelectedGiftCard = (e) => {
    const select = e.target;
    const selectedSerial = select.value;

    giftCards.forEach((giftCard) => {
      if (giftCard.serial === selectedSerial) {
        setSelectedGiftCard(giftCard);
      }
    });
  };

  const getDeductedBalance = (giftCard) => {
    let deductedBalance;

    /** If there are any gift cards already applied, compare the the balance with amount due
     * Other wise compare with order total
     */
    if (appliedGiftCards && appliedGiftCards.length > 0) {
      if (giftCard.balance >= amountDue) {
        deductedBalance = amountDue;
      } else {
        deductedBalance = giftCard.balance;
      }
    } else {
      if (giftCard.balance >= orderTotal) {
        deductedBalance = orderTotal;
      } else {
        deductedBalance = giftCard.balance;
      }
    }

    return deductedBalance;
  };

  const getRemainingBalance = (giftCard, deductedBalance) => {
    return giftCard.balance - deductedBalance;
  };

  const [amountDue, setAmountDue] = useState(orderTotal);
  const updateAmountDue = () => {
    const tempAppliedGiftCards = [...appliedGiftCards];
    let totalDeducatedBalance = 0;

    tempAppliedGiftCards.forEach((tempAppliedGiftCard) => {
      totalDeducatedBalance += tempAppliedGiftCard.deductedBalance;
    });

    let newAmountDue;
    if (totalDeducatedBalance >= orderTotal) {
      newAmountDue = 0;
    } else {
      newAmountDue = orderTotal - totalDeducatedBalance;
    }

    setAmountDue(newAmountDue);
  };

  const [appliedGiftCards, setAppliedGiftCards] = useState();
  useEffect(() => {
    if (appliedGiftCards && appliedGiftCards.length > 0) {
      updateAmountDue();
    } else {
      setAmountDue(orderTotal);
    }
  }, [appliedGiftCards]);

  const [isAddAnotherCard, setIsAddAnotherCard] = useState(false);

  const [isInitialUnregisteredCardApplied, setIsInitialUnregisteredCardApplied] = useState(false);

  const applyGiftCard = (giftCard) => {
    if ((!loginToken && !appliedGiftCards) || (loginToken && !giftCards)) {
      setIsInitialUnregisteredCardApplied(true);
    }

    const tempAppliedGiftCard = { ...giftCard };

    tempAppliedGiftCard.deductedBalance = getDeductedBalance(giftCard);
    tempAppliedGiftCard.remainingBalance = getRemainingBalance(
      giftCard,
      tempAppliedGiftCard.deductedBalance
    );

    /*
      Retrieve full card number for registered card only.
      Unregistered cards already have their full card numbers from user input.
    */
    if (!tempAppliedGiftCard.fullCardNumber) {
      getAPIFullCardNumber(skin, loginToken, giftCard, appLanguage).then((apiFullCardNumber) => {
        if (apiFullCardNumber) {
          if (apiFullCardNumber.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
            return;
          } else {
            tempAppliedGiftCard.fullCardNumber = apiFullCardNumber;
          }
        } else {
          console.error("Error retrieving full card number: ", apiFullCardNumber);
        }
      });
    }

    const tempAppliedGiftCards = appliedGiftCards ? [...appliedGiftCards] : [];
    tempAppliedGiftCards.push(tempAppliedGiftCard);
    setAppliedGiftCards(tempAppliedGiftCards);

    if (isAddAnotherCard) setIsAddAnotherCard(false);
  };

  const removeAppliedCard = (giftCard) => {
    const tempAppliedGiftCards = [...appliedGiftCards];

    tempAppliedGiftCards.forEach((tempAppliedGiftCard, index) => {
      if (tempAppliedGiftCard.serial === giftCard.serial) {
        tempAppliedGiftCards.splice(index, 1); /* delete card from applied card list */
      }
    });

    setAppliedGiftCards(tempAppliedGiftCards);
  };

  const formatOrderTime = (orderTime) => {
    const date = new Date(orderTime);
    const month = date.getMonth();
    const calDate = date.getDate();
    const day = date.getDay();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;

    const nameOfDay = getDayOfWeekFromIndex(day);
    const timestamp = `${
      nameOfDay.substr(0, 3).charAt(0).toUpperCase() + nameOfDay.substr(1, 2)
    } ${getMonthFromIndex(month)} ${calDate} at ${strTime}`;

    return (
      <>
        <br /> <br /> <span style={{ fontWeight: 700 }}>{timestamp} </span>
        <br /> <br />{" "}
      </>
    );
  };

  const orderTimeContext = useContext(OrderTimeContext);

  const updateOrderTime = () => {
    orderTimeContext.update({
      value: nextAvailableOrderTime,
      displayValue: getTimeAmPm(nextAvailableOrderTime),
      trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
      timeAtOrdering: new Date()
    });
    setIsPassedOrderTime(false);
  };

  const [isLateOrderAttempt, setIsLateOrderAttempt] = useState(false);
  const [isPassedOrderTime, setIsPassedOrderTime] = useState(false);
  const [isOrderTimeBeforeOpening, setIsOrderTimeBeforeOpening] = useState(false);
  const [nextAvailableOrderTime, setNextAvailableOrderTime] = useState(null);
  const [orderTimeIsBlocked, setOrderTimeIsBlocked] = useState(false);
  const [isStoreClosed, setIsStoreClosed] = useState(false);

  const appLabels = useContext(AppLabelsContext);

  const [customerInfo, setCustomerInfo] = useState(null);
  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      if (customerInfo) {
        setCustomerInfo(customerInfo);
      } else {
        setCustomerInfo({});
      }
    });
  }, [merchantConfig]);

  const [isCustomerIdMissing, setIsCustomerIdMissing] = useState(false);
  useEffect(() => {
    if (isAddAnotherCard === false && customerInfo && loginToken) {
      if (!customerInfo.id) {
        setIsCustomerIdMissing(true);
      }
    }
  }, [isAddAnotherCard, customerInfo, loginToken]);

  return (
    <>
      {giftCards ? (
        <>
          {appliedGiftCards ? (
            <>
              <AppliedGiftCardsBreakdown
                orderTotal={orderTotal}
                appliedGiftCards={appliedGiftCards}
                amountDue={amountDue}
                removeAppliedCard={(giftCard) => removeAppliedCard(giftCard)}
              />
              {amountDue > 0 && (
                <RemainderGiftCardPayment
                  paymentMethodExpanded={paymentMethodExpanded}
                  creditCardTypes={creditCardTypes}
                  merchantConfig={merchantConfig}
                  appliedGiftCards={appliedGiftCards}
                  updateAppliedGiftCard={(giftCard) => applyGiftCard(giftCard)}
                  orderTotal={orderTotal}
                  rewards={rewards}
                  promoCode={promoCode}
                  coupon={coupon}
                />
              )}
            </>
          ) : (
            <>
              {isAddAnotherCard ? (
                <AddAnotherGiftCard
                  merchantConfig={merchantConfig}
                  appliedGiftCards={appliedGiftCards}
                  updateAppliedGiftCard={(giftCard) => applyGiftCard(giftCard)}
                  backToRegisteredCard={() => setIsAddAnotherCard(false)}
                />
              ) : (
                <div className="gift-card-container">
                  {selectedGiftCard && (
                    <div className="gift-card__image-text-wrapper">
                      <div className="gift-card__image-wrapper">
                        <img
                          src={selectedGiftCard.image}
                          alt={selectedGiftCard.name}
                          className="gift-card__image"
                        />
                      </div>
                      <div className="gift-card__text-wrapper">
                        <span className="gift-card__balance-label">
                          {appLabels["order"]["card-balance"]}
                        </span>
                        <span className="gift-card__balance">
                          {selectedGiftCard.displayBalance}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="gift-card__registered-label">
                    {appLabels["order"]["registered-gift-card"]}
                  </div>
                  {giftCards.length > 1 && (
                    <DummyFormSelect
                      handleChange={updateSelectedGiftCard}
                      id="gift-card__select"
                      customClass=" gift-card__select-wrapper"
                      label="Registered Gift Card"
                      name="gift-card__select"
                      options={giftCards}
                    />
                  )}
                  <div className="gift-card__apply-button-wrapper">
                    <button
                      onClick={() => applyGiftCard(selectedGiftCard)}
                      className="button button--intermediary-submit gift-card__apply-button"
                      type="button">
                      {appLabels["order"]["apply-gift-card-to-order-button"]}
                    </button>
                  </div>
                  <div className="expanded-payment-method__footer">
                    <button
                      onClick={() => setIsAddAnotherCard(true)}
                      className="button expanded-payment-method__footer-button"
                      style={{ color: secondaryColor }}
                      type="button">
                      <IconPlus aria-hidden="true" style={{ stroke: secondaryColor }} />
                      <span>{appLabels["order"]["use-another-gift-card"]}</span>
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {giftCards === null ? (
            <>
              {isInitialUnregisteredCardApplied && appliedGiftCards ? (
                <>
                  <AppliedGiftCardsBreakdown
                    orderTotal={orderTotal}
                    appliedGiftCards={appliedGiftCards}
                    amountDue={amountDue}
                    removeAppliedCard={(giftCard) => removeAppliedCard(giftCard)}
                  />
                  {amountDue > 0 && (
                    <RemainderGiftCardPayment
                      paymentMethodExpanded={paymentMethodExpanded}
                      creditCardTypes={creditCardTypes}
                      merchantConfig={merchantConfig}
                      appliedGiftCards={appliedGiftCards}
                      updateAppliedGiftCard={(giftCard) => applyGiftCard(giftCard)}
                      orderTotal={orderTotal}
                      rewards={rewards}
                      promoCode={promoCode}
                      coupon={coupon}
                    />
                  )}
                </>
              ) : (
                <AddAnotherGiftCard
                  merchantConfig={merchantConfig}
                  appliedGiftCards={appliedGiftCards}
                  updateAppliedGiftCard={(giftCard) => applyGiftCard(giftCard)}
                  backToRegisteredCard={() => setIsAddAnotherCard(false)}
                  isUnregistered={true}
                />
              )}
            </>
          ) : (
            <p className="payment-method__error-text">
              {appLabels["order"]["no-active-gift-cards-message"]}
            </p>
          )}
        </>
      )}
      <CompleteOrderWithGiftCard
        appliedGiftCards={appliedGiftCards}
        isDisabled={amountDue > 0}
        merchantConfig={merchantConfig}
        rewards={rewards}
        promoCode={promoCode}
        setIsLateOrderAttempt={setIsLateOrderAttempt}
        setIsPassedOrderTime={setIsPassedOrderTime}
        setNextAvailableOrderTime={setNextAvailableOrderTime}
        setIsOrderTimeBeforeOpening={setIsOrderTimeBeforeOpening}
        orderTimeValue={orderTimeContext.value.value}
        coupon={coupon}
        setOrderTimeIsBlocked={setOrderTimeIsBlocked}
        isGCCheckout={isGCCheckout}
      />
      {isStoreClosed && (
        <DialogModal
          hideCloseButton={true}
          closeModalAction={() => {
            setIsStoreClosed(false);
          }}
          isCancelConfirm={false}
          hideConfirmButton={false}
          isCustomConfirmText={appLabels["order"]["pick-new-time"]}
          resetRemoveDialog={() => {
            history.push({
              pathname: "/dashboard",
              state: {
                showTimePanel: true,
                timeAtOrdering: orderTimeContext.value.timeAtOrdering,
                originalOrderTimeContext: orderTimeContext
              },
            });
          }}
          message={
            <div>
              <h2>{appLabels["order"]["sorry-it-is-closing-time"]}</h2>
              <p>{appLabels["order"]["store-closed-message"]}</p>
            </div>
          }
        />
      )}
      {isLateOrderAttempt && (
        <DialogModal
          message={appLabels["order"]["order-too-close-to-closing-time-error"]}
          resetRemoveDialog={() => {
            setIsLateOrderAttempt(false);
            orderTimeContext.update({
              value: nextAvailableOrderTime,
              displayValue: getTimeAmPm(nextAvailableOrderTime),
              trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
            });
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}
      {isPassedOrderTime && (
        <DialogModal
          message={
            <div>
              {appLabels["order"]["select-order-time-passed-message"] + ": "}
              {formatOrderTime(nextAvailableOrderTime)}
              {appLabels["order"]["confirm-updated-order-time-message"]}
            </div>
          }
          resetRemoveDialog={() => setIsPassedOrderTime(false)}
          isCancelConfirm={true}
          confirmAction={updateOrderTime}
          isHTMLContent={true}
        />
      )}

      {isOrderTimeBeforeOpening && (
        <DialogModal
          message={appLabels["order"]["order-before-store-opening-time-error"]}
          resetRemoveDialog={() => {
            setIsOrderTimeBeforeOpening(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {orderTimeIsBlocked && (
        <DialogModal
          message={appLabels["order"]["order-time-is-blocked"]}
          resetRemoveDialog={() => {
            setOrderTimeIsBlocked(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {isCustomerIdMissing && (
        <DialogModal
          message={appLabels["order"]["missing-customer-id-error-gc"]}
          resetRemoveDialog={() => {
            sessionStorage.setItem(skin + "__lastVisitedLink", window.location.hash.split("#")[1]);
            setIsCustomerIdMissing(false);
            userRoleContext.updateStatus("guest");
            userRoleContext.updateLoginToken();

            history.push("/login-register");
          }}
        />
      )}
    </>
  );
};

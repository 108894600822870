import React from "react";
import { ReactComponent as IconClosedEye } from "../icons/IconClosedEye.svg";
import { ReactComponent as IconEye } from "../icons/IconEye.svg";

const buttonStyles = {
  position: "absolute",
  bottom: "0",
  right: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "42px",
  height: "42px",
};

const iconStyles = {
  color: "#757575",
};

export const PasswordVisibilityToggle = ({ type, toggleVisibility, customAriaLabel = null }) => {
  let ariaLabelText = customAriaLabel || "Password"
  return (
    <button
      onClick={() => toggleVisibility(type === "password" ? "text" : "password")}
      style={buttonStyles}
      type="button"
      aria-label={type === "password" ? "Show value for " + ariaLabelText : "Hide value for " + ariaLabelText  }
    >
      <span className="visually-hidden">{`${type === "password" ? "Show" : "Hide"} password`}</span>
      {type === "password" ? (
        <IconClosedEye aria-hidden="true" style={iconStyles} />
      ) : (
        <IconEye aria-hidden="true" style={iconStyles} />
      )}
    </button>
  );
};

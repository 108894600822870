import React from "react";
import PropTypes from "prop-types";

const styles = {
  marginTop: `1em`,
  marginBottom: `1em`,
  color: `#2a2a2a`,
  fontSize: `0.75rem`,
  padding: "0 24px",
};

export const CaloriesDisclaimer = ({ caloriesDisclaimer }) => {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <p className="calories-disclaimer" style={styles}>
        {caloriesDisclaimer}
      </p>
    </div>
  );
};

CaloriesDisclaimer.propTypes = {
  caloriesDisclaimer: PropTypes.string.isRequired,
};

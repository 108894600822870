import {
  getDayOfWeekFromIndex,
  getFormattedStoreHoursWithMidnight,
} from "../Locations/helpers/isStoreOpenOrClosed";

export const getTodayName = () => {
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long", // e.g. displays Friday instead of Fri
  })
    .format(new Date()) // converts the Date()-returned day number value to an actual day name
    .toLowerCase();
};

export const getYesterdayName = () => {
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  return new Intl.DateTimeFormat("en-US", {
    weekday: "long", // e.g. displays Friday instead of Fri
  })
    .format(yesterday) // converts the Date()-returned day number value to an actual day name
    .toLowerCase();
};

export const formatStoreHours = (tempOpeningTime, tempClosingTime) => {
  const openingTimeHours = tempOpeningTime.split(":")[0];
  const openingTimeMinutes = tempOpeningTime.split(":")[1];
  const closingTimeHours = tempClosingTime.split(":")[0];
  const closingTimeMinutes = tempClosingTime.split(":")[1];
  const formattedOpeningTime = new Date();
  formattedOpeningTime.setHours(openingTimeHours, openingTimeMinutes, 0);
  const formattedClosingTime = new Date();
  formattedClosingTime.setHours(closingTimeHours, closingTimeMinutes, 0);
  const currentTime = new Date();
  //If Closing time is earlier than Opening time - one of them must be a different Date
  if (formattedClosingTime < formattedOpeningTime) {
    if (currentTime > formattedOpeningTime) {
      formattedClosingTime.setDate(formattedClosingTime.getDate() + 1);
    } else {
      formattedOpeningTime.setDate(formattedOpeningTime.getDate() - 1);
    }
  }
  return { formattedOpeningTime, formattedClosingTime };
};

export const convertFrom24HourFormat = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)/) || [time];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const getClosingTime = (hours, store) => {
  let todayStoreHours = "";
  const today = getTodayName();

  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  if (store && store.openPassedMidnight[getDayOfWeekFromIndex(yesterday.getDay())] === true) {
    //see if current time is before closing time of yesterday
    let yesterdayHours = "";
    for (let day in hours) {
      if (getDayOfWeekFromIndex(yesterday.getDay()) === day.toLowerCase())
        yesterdayHours = hours[day];
    }

    if (yesterdayHours === "closed") {
      return "closed";
    } else {
      const formattedStoreHoursForPreviousDay = getFormattedStoreHoursWithMidnight(
        yesterdayHours.split(" - ")[0],
        yesterdayHours.split(" - ")[1],
        yesterday,
        store
      );
      if (
        now >= formattedStoreHoursForPreviousDay.formattedOpeningTime &&
        now < formattedStoreHoursForPreviousDay.formattedClosingTime
      ) {
        //current time is before yesterday's closing time
        return yesterdayHours.split(" - ")[1];
      } else {
        for (let day in hours) {
          if (today === day.toLowerCase()) todayStoreHours = hours[day];
        }

        return todayStoreHours === "closed"
          ? "closed"
          : todayStoreHours.split("-")[1].replace(/\s/g, "");
      }
    }
  } else {
    for (let day in hours) {
      if (today === day.toLowerCase()) todayStoreHours = hours[day];
    }

    const todayStoreClosingTime =
      todayStoreHours === "closed" ? "closed" : todayStoreHours.split("-")[1].replace(/\s/g, "");

    return todayStoreClosingTime;
  }
};

export const getTodayOpeningTime = (hours) => {
  const today = getTodayName();
  let todayStoreHours = "";
  for (let day in hours) {
    if (today === day.toLowerCase()) todayStoreHours = hours[day];
  }
  return todayStoreHours.split("-")[0].replace(/\s/g, "");
};

export const isStoreOpenOrClosed = (hours, store) => {
  let isOpen;

  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  const tempOpeningTime = getTodayOpeningTime(hours);
  const tempClosingTime = getClosingTime(hours);
  const formattedStoreHours = formatStoreHours(tempOpeningTime, tempClosingTime);
  const formattedOpeningTime = formattedStoreHours.formattedOpeningTime;
  const formattedClosingTime = formattedStoreHours.formattedClosingTime;

  const yesterdayHours = hours[getDayOfWeekFromIndex(yesterday.getDay())];
  const yesterdayOpeningTime = yesterdayHours.split(" - ")[0];
  const yesterdayClosingTime = yesterdayHours.split(" - ")[1];

  const formattedStoreHoursForPreviousDay = getFormattedStoreHoursWithMidnight(
    yesterdayOpeningTime,
    yesterdayClosingTime,
    yesterday,
    store
  );

  if (now >= formattedOpeningTime && now < formattedClosingTime) {
    isOpen = true;
  } else {
    if (store.openPassedMidnight[getDayOfWeekFromIndex(yesterday.getDay())] === true) {
      return (
        now >= formattedStoreHoursForPreviousDay.formattedOpeningTime &&
        now < formattedStoreHoursForPreviousDay.formattedClosingTime
      );
    } else {
      isOpen = false;
    }
  }

  return isOpen;
};

export const checkPickupInterval = (minPickupInterval, closingTime, hours) => {
  const now = new Date();
  const formattedStoreHours = formatStoreHours(getTodayOpeningTime(hours), closingTime);
  const formattedClosingTime = formattedStoreHours.formattedClosingTime;

  let formattedPickupMinInterval = "";
  formattedPickupMinInterval = new Date(now.getTime() + minPickupInterval * 60000); // add pickup interval to current time

  let storeClosesBeforeMinPickup;
  if (formattedClosingTime <= formattedPickupMinInterval) {
    storeClosesBeforeMinPickup = true;
  } else {
    storeClosesBeforeMinPickup = false;
  }
  return storeClosesBeforeMinPickup;

  // For Debugging
  /* const diff = Math.abs(formattedClosingTime - now) / 1000;
    const diffHours = Math.floor(diff / 3600) % 24;
    const diffMinutes = Math.floor(diff / 60) % 60;

    console.log("Now: ", now);
    console.log("Closing time: ", formattedClosingTime);
    console.log(
      "%cPickup Min Interval:",
      "color: orange",
      pickupMinInterval + " min"
    );
    console.log(
      "%cClosing in: ",
      "color: red",
      diffHours + " hours, " + diffMinutes + " min"
    ); */
  // END OF For Debugging
};

export const setIsOpenPassedMidnightProperties = (hours) => {
  let passedMidnightObject = {};

  Object.keys(hours).forEach((day) => {
    if (hours[day] === "Closed" || hours[day] === "" || hours[day] === "closed") {
      passedMidnightObject[day] = "closed";
    } else {
      const openingTime = hours[day].split(" - ")[0].replace(":", "");
      const closingTime = hours[day].split(" - ")[1].replace(":", "");
      if (closingTime < openingTime) {
        passedMidnightObject[day] = true;
      } else {
        passedMidnightObject[day] = false;
      }
    }
  });
  return passedMidnightObject;
};

export const setStoreHoursProperties = (store) => {
  store.openPassedMidnight = setIsOpenPassedMidnightProperties(store.hours);
  store.isOpen = isStoreOpenOrClosed(store.hours, store);
  store.closingTime = getClosingTime(store.hours, store);
  store.displayClosingTime = convertFrom24HourFormat(store.closingTime);
  return store;
};

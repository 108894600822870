export const loyaltyLabels = {
  rewards: "Rewards",
  "start-collecting-now": "Start collecting now!",
  "add-new-card-to-join":
    "Join our family and start collecting points. Keep track of your progress and redeem towards free drinks, food and more.",
  "still-not-convinced": "Still not convinced?",
  "check-out-how-rewards-work": "Check out how rewards work",
  "check-out-how-rewards-work-extra-text":
    "and see how you can benefit from being our loyal customer.",
  "how-rewards-work": "How Rewards Work",
  "add-a-new-card": "Add a new card",
  "register-now": "Register Now",
  "my-rewards": "My Rewards",
  "ends-with": "Ends with",
  "reward-options": "Rewards Options",
  "learn-how-it-works": "Learn how it works",
  "start-collecting-points-toward": "Start collecting points toward free drinks, food and more.",
  "expiry-date": "Expiry Date",
  "bonus-rewards": "Bonus Rewards",
  "redeem-rewards": "Redeem Rewards",
  "online-order": "Online",
  "when-ordering-online":
    "Add any applicable items to your order. When you are ready to checkout, click the “Use Rewards” button in your shopping cart and choose which reward to redeem.",
  "in-store": "In Location",
  "click-on-any-available-reward":
    "From your homepage, click the “Scan” button, scan the code, and let the cashier know which reward you would like to redeem.",
  redeem: "Redeem",
  "you-need-to-earn-more-points": "You need to earn more points",
  "collect-points": "Collect Points",
  "reward-redemption-confirmation":
    "Are you sure you want to redeem [reward-name] reward for [reward-cost]",
  "order-confirmation-check-my-rewards-link": "Check My Rewards",
  "register-today-and-start-collecting-points-message":
    "Register today and start collecting points toward free drinks, food and more.",
  "see-how-rewards-work-link": "See how rewards work",
  "you-could-have-earned-points-message":
    "You could have earned [points] loyalty points for this order!",
  "confirm-rewards-redemption-message": "You are about to redeem [reward-name] reward",
  "redeem-reward-associated-item-error":
    "Please check the associated item is added to your order before attempting to redeem this reward",
  "moving-applied-reward-on-card-switch-confirm-text":
    "Switching cards will move any previously applied reward(s). Would you like to continue?",
  "available-rewards-subheading": "Available Rewards",
  "add-associated-item-before-redeeming-reward-blurb":
    "Please add the associated item(s) to your order before redeeming your reward. You can apply more than one reward by adjusting the quantity.",
  "no-rewards-for-card": "No rewards available for this card",
  "cart-change-reapply-rewards-message":
    "One or more previously applied rewards became unavailable with the last cart modification. Please reapply any rewards you wish to redeem.",
  "order-confirmation-points-issued":
    "You have earned [points][icon] loyalty points for this order!",
  "redeem-reward-success-message": "Successfully Redeemed",
  "transaction-reference-number": "Transaction reference number",
  "where-to-redeem-this-reward": "Where would you like to redeem this reward",
  "was-added-to-card": "was added to [card-number]",
  "your-new-card-balance": "Your new balance is: [card-balance]",
  "show-reward-item": "Show [reward-name] reward",
  "not-enough-points-for-redeeming":
    "You do not have enough points to redeem [reward-quantity] of [reward-name]",
  "reward-balance": "Points Balance",
  "rewards-remaining": "[reward-balance] Rewards Remaining",
  "rewards-remaining-single": "[reward-balance] Reward Remaining",
  "view-rewards": "View Rewards",
  "see-all-rewards": "See All Rewards",
};

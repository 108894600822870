// Libraries
import React from "react";
import "react-tabs/style/react-tabs.css";

// Helper functions
import { getOrderStoreAddress } from "./orderHistoryHelpers";

// UI components
import { ReorderButton } from "./ReorderButton";
import { ViewDetailsButton } from "./ViewDetailsButton";

// CSS
import "./RecentOrders.css";

export const PastOrdersCard = (props) => {
  const {
    order,
    appLabels,
    formatOrderDateAndTime,
    foodMenu,
    cart,
    updateIsNotification,
    setIsAlertNotification,
    menuLevel,
  } = props;

  return (
    <div className="recent-order">
      <header className="recent-order__header">
        <span>{formatOrderDateAndTime(order.date)}</span>
        <span>#{order.id}</span>
      </header>
      <section className="recent-order__main">
        {getOrderStoreAddress(order, appLabels, true)}
        <br />
        <h4 className="recent-order__total-past-orders">{appLabels["general"]["total"]}:</h4>
        {` ${order.total}`}
      </section>
      <section className={order.isFromDisabledStore ? "recent-order__footer-past-orders show-only-order-details" : "recent-order__footer-past-orders"}>
        <ViewDetailsButton recentOrders={order} />
        {!order.isFromDisabledStore &&
          <ReorderButton
            order={order}
            foodMenu={foodMenu}
            cart={cart}
            updateIsNotification={updateIsNotification}
            setIsAlertNotification={setIsAlertNotification}
            menuLevel={menuLevel}
            orderStore={order.storeLocation}
          />
        }
      </section>
    </div>
  );
};

import React, { useState, useEffect, useContext } from "react";

import useWindowSize from "../../../_common/hooks/useWindowSize";

import BrandingContext from "../../../App/BrandingContext";
import AppLabelsContext from "../../../App/AppLabelsContext";

import { ReactComponent as IconCheck } from "../../../_common/icons/IconCheck.svg";
import { RewardQuantityButtons } from "./RewardQuantityButtons";

import { LoadingSpinner } from "../../../_common/LoadingSpinner";

export default function RewardButton(props) {
  const {
    reward,
    selectedCard,
    checkedRewards,
    storedRewards,
    storedRewardsDetails,
    redeemReward,
    maxRedeemableRewardQuantity,
    setStoredRewards,
    setStoredRewardsDetails,
  } = props;

  const [rewardIsApplied, setRewardIsApplied] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(null);
  const appLabels = useContext(AppLabelsContext);

  const brandingContext = useContext(BrandingContext);
  const imagePlaceholder = brandingContext["no-image-available-giftcard-placeholder"];

  const deviceWidth = useWindowSize().width;

  useEffect(() => {
    if (storedRewards && storedRewards.length > 0 && checkedRewards) {
      storedRewards.forEach((storedReward) => {
        if (reward.id === storedReward[1].toString()) {
          setRewardIsApplied(true);
        }
      });
    } else {
      setRewardIsApplied(false);
    }
  }, [storedRewards, checkedRewards]);

  const [currentRewardQuantityValue, setCurrentRewardQuantityValue] = useState(null);
  useEffect(() => {
    if (storedRewards && currentRewardQuantityValue === null) {
      setCurrentRewardQuantityValue(
        storedRewards &&
          storedRewards.length > 0 &&
          storedRewards.filter((storedReward) => storedReward[1].toString() === reward.id).length >
            0
          ? storedRewards.filter((storedReward) => storedReward[1].toString() === reward.id).length
          : 1 //default value
      );
    }
  }, [storedRewards]);

  const updateRewardQuantity = (idAdding) => {
    let currentRewardQuantity = currentRewardQuantityValue;
    if (idAdding) {
      currentRewardQuantity++;
      if (!!maxRedeemableRewardQuantity && currentRewardQuantity > maxRedeemableRewardQuantity) {
        currentRewardQuantity = maxRedeemableRewardQuantity;
      }
    } else {
      currentRewardQuantity--;
      if (currentRewardQuantity < 1) {
        currentRewardQuantity = 1;
      }
    }
    setCurrentRewardQuantityValue(currentRewardQuantity);
    if (rewardIsApplied) {
      removeItemReward(reward.id);
    }
  };

  const removeItemReward = (itemRewardId) => {
    let tempStoredRewards = [...storedRewards];
    let tempStoredRewardsDetails = [...storedRewardsDetails];

    //filter out the rewards byt keeping only the ones that do not have the same id as itemRewardId
    tempStoredRewards = tempStoredRewards.filter((reward) => reward[1].toString() !== itemRewardId);
    tempStoredRewardsDetails = tempStoredRewardsDetails.filter(
      (reward) => reward.id !== itemRewardId
    );

    setRewardIsApplied(false);
    setShowButtonLoader(true);
    setStoredRewards(tempStoredRewards);
    setStoredRewardsDetails(tempStoredRewardsDetails);

    setTimeout(() => {
      setShowButtonLoader(false);
    }, 2000);
  };

  const [isRewardDetailsCollapsed, setIsRewardDetailsCollapsed] = useState(true);

  const toggleItemDetails = (reward) => {
    const currentState = isRewardDetailsCollapsed;
    setIsRewardDetailsCollapsed(!currentState);

    const content = document.getElementById(`${reward.id}`);

    if (!currentState) {
      content.style.maxHeight = null;
    } else {
      //content.style.maxHeight = content.scrollHeight + "px";
    }
  };

  return (
    <>
      {selectedCard.points >= reward.price && (
        <>
          {deviceWidth < 660 ? (
            <div className="my-rewards__wrapper" id={reward.id}>
              <div className="my-rewards__option-container">
                <div className="my-rewards__option--icon-rewards">
                  <img src={reward.image || imagePlaceholder} alt={reward.name} />
                </div>
                <div className="my-rewards__option-container-rewards">
                  <div className="my-rewards__option--name-rewards">
                    <span className="my-rewards__option-name">{reward.name}</span>
                  </div>
                  {reward.rewardBalance && reward.rewardBalance > 0 && (
                    <div className="my-rewards__option--balance">
                      <span>{`${appLabels["loyalty"][
                        reward.rewardBalance === "1"
                          ? "rewards-remaining-single"
                          : "rewards-remaining"
                      ].replace("[reward-balance]", reward.rewardBalance)}`}</span>
                    </div>
                  )}

                  {reward.expiry && (
                    <div className="my-rewards__option--expiry-rewards">
                      <span>{`${appLabels["general"]["expires-on"]} : ${reward.expiry}`}</span>
                    </div>
                  )}
                  <div>
                    <button
                      type="button"
                      className="suggested_items__view-details"
                      onClick={() => {
                        toggleItemDetails(reward);
                      }}
                      style={
                        reward.description === ""
                          ? { visibility: "hidden" }
                          : { visibility: "visible" }
                      }
                    >
                      {isRewardDetailsCollapsed
                        ? appLabels["order"]["view-details"]
                        : appLabels["order"]["hide-details"]}{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`my-rewards__reward-description ${
                  isRewardDetailsCollapsed
                    ? " my-rewards__reward-description-hidden"
                    : " my-rewards__reward-description-visible"
                }`}
                id={`${reward.id}`}
              >
                <p>{reward.description}</p>
              </div>
              <div className="reward__quantity-wrapper">
                <label htmlFor={`reward-quantity-${reward.id}`}>
                  {appLabels["order"]["quantity"]}
                </label>
                {currentRewardQuantityValue && (
                  <RewardQuantityButtons
                    inputId={`reward-quantity-${reward.id}`}
                    isDisabled={rewardIsApplied}
                    quantityValue={currentRewardQuantityValue}
                    appLabels={appLabels}
                    maxQuantityValue={maxRedeemableRewardQuantity}
                    updateRewardQuantity={updateRewardQuantity}
                  />
                )}
              </div>
              <div className="my-rewards__option--button-container-rewards">
                {rewardIsApplied ? (
                  <div className="reward-button__applied-indicator-wrapper">
                    <IconCheck />
                    {appLabels["general"]["applied"]}
                  </div>
                ) : (
                  <>
                    {showButtonLoader ? (
                      <LoadingSpinner />
                    ) : (
                      <button
                        type="button"
                        className="button button--primary button--primary-small"
                        onClick={() => {
                          redeemReward(
                            reward,
                            document.getElementById(`reward-quantity-${reward.id}`).value
                          );
                        }}
                        disabled={selectedCard.points < reward.price}
                      >
                        {appLabels["loyalty"]["redeem"]}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="my-rewards__wrapper-desktop" id={reward.id}>
              <div className="my-rewards__option-container">
                <div className="my-rewards__option--icon-rewards">
                  <img src={reward.image || imagePlaceholder} alt={reward.name} />
                </div>

                <div className="my-rewards__option-container-rewards">
                  <div className="my-rewards__option--name-rewards">
                    <span className="my-rewards__option-name">{reward.name}</span>
                  </div>
                  {reward.rewardBalance && reward.rewardBalance > 0 && (
                    <div className="my-rewards__option--balance">
                      <span>{`${appLabels["loyalty"][
                        reward.rewardBalance === "1"
                          ? "rewards-remaining-single"
                          : "rewards-remaining"
                      ].replace("[reward-balance]", reward.rewardBalance)}`}</span>
                    </div>
                  )}
                  {reward.expiry && (
                    <div className="my-rewards__option--expiry-rewards">
                      <span>{`${appLabels["general"]["expires-on"]} : ${reward.expiry}`}</span>
                    </div>
                  )}
                  <div>
                    <button
                      type="button"
                      className="suggested_items__view-details"
                      onClick={() => {
                        toggleItemDetails(reward);
                      }}
                      style={
                        reward.description === ""
                          ? { visibility: "hidden" }
                          : { visibility: "visible" }
                      }
                    >
                      {isRewardDetailsCollapsed
                        ? appLabels["order"]["view-details"]
                        : appLabels["order"]["hide-details"]}{" "}
                    </button>
                  </div>
                  <div
                    className={`my-rewards__reward-description ${
                      isRewardDetailsCollapsed
                        ? " my-rewards__reward-description-hidden"
                        : " my-rewards__reward-description-visible"
                    }`}
                    id={`${reward.id}`}
                  >
                    <p>{reward.description}</p>
                  </div>
                </div>
                <div className="reward__quantity-wrapper">
                  <label htmlFor={`reward-quantity-${reward.id}`}>
                    {appLabels["order"]["quantity"]}
                  </label>
                  {currentRewardQuantityValue && (
                    <RewardQuantityButtons
                      inputId={`reward-quantity-${reward.id}`}
                      isDisabled={rewardIsApplied}
                      quantityValue={currentRewardQuantityValue}
                      appLabels={appLabels}
                      maxQuantityValue={maxRedeemableRewardQuantity}
                      updateRewardQuantity={updateRewardQuantity}
                    />
                  )}
                </div>
                <div className="my-rewards__option--button-container-rewards use-rewards">
                  {rewardIsApplied ? (
                    <div className="reward-button__applied-indicator-wrapper">
                      <IconCheck />
                      {appLabels["general"]["applied"]}
                    </div>
                  ) : (
                    <>
                      {showButtonLoader ? (
                        <LoadingSpinner />
                      ) : (
                        <button
                          type="button"
                          className="button button--primary button--primary-small"
                          onClick={() => {
                            redeemReward(
                              reward,
                              document.getElementById(`reward-quantity-${reward.id}`).value
                            );
                          }}
                          disabled={selectedCard.points < reward.price}
                        >
                          {appLabels["loyalty"]["redeem"]}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

import { removeAPICreditCard } from "../OnlineOrdering/Payment/PaymentMethod/PaymentMethodCreditCard/apiHelpers/removeAPICreditCard";
import { callMarquiPaymentAPI, callAPI } from "../_common/Api";
import { removeAllSpaces } from "../_common/Form/helpers/formValidation";

export const addAPICreditCard = async (
  skin,
  activeType,
  formValues,
  customerID,
  customerInfo,
  language
) => {
  const type = activeType.toUpperCase();
  const number = formValues["credit-card-number"].replace(/\s/g, "");
  const name = formValues["credit-card-name"];
  const expiry = formValues["credit-card-expiry"];
  const expiryMonth = expiry.split("/")[0];
  const expiryYear = "20" + expiry.split("/")[1];
  const cvv = formValues["credit-card-cvv"];

  const address1 = formValues["first-address"];
  const address2 = formValues["second-address"];
  const city = formValues["city"];
  const province = formValues["province"];
  const country = formValues["country"];
  const postal = formValues["postal"];
  const email = (customerInfo && customerInfo.email) || formValues["email"];
  const phone = (customerInfo && customerInfo.phone) || formValues["phone"];

  const addAPICreditCardData = await callMarquiPaymentAPI(skin, "add_credit_card", {
    params: [
      skin,
      "consumer",
      language,
      type,
      number,
      name,
      cvv,
      "",
      expiryMonth,
      expiryYear,
      "",
      "",
      address1,
      address2 || "",
      city,
      province,
      country,
      postal,
      email || "",
      removeAllSpaces(phone),
      "t", // cc_on_file
      customerID,
      "", //url_3ds_success
      "", //url_3ds_failure
      "", //unified_or_bill_payment
      "t", //save_cust_cc
    ],
    id: "add_credit_card",
  });

  if (addAPICreditCardData.result.I0) {
    /* call API CWS Add Stored Address - "1000" - "AD" to add the billing address of the CC to the account
     * This is required to make GC auto reloads work
     */

    const addBillingAddressData = await callAPI(skin, "dc_1000", {
      params: [
        language,
        "1000",
        "mqid",
        "mqpass",
        "customer",
        customerInfo.loginToken, //email
        "", //password
        "stored_billing", //type of address
        address1, //address 1
        address2, //address 2
        city, //city
        province, //province
        "", //county
        country, //country
        postal, //postal code
        removeAllSpaces(phone), //phone number
        "CC Billing Address", //address description
        "", //address default
        "CC Billing Address", // address name
        "", // cws skin
      ],
      id: "1000",
    });

    if (addBillingAddressData.status === 249 || addBillingAddressData.status === 150) {
      return { status: "expiredLoginToken" };
    } else if (addBillingAddressData.result.I0) {
      // Successfully added a credit card
      return { isAdded: true, creditCardId: addAPICreditCardData.result.I0 };
    } else {
      //if the address failed, remove the CC
      const removeCreditCardData = await removeAPICreditCard(
        skin,
        customerInfo.loginToken,
        language
      );
      if (removeCreditCardData && !removeCreditCardData.error) {
        // Failed to add a credit card
        return { isAdded: false, error: addBillingAddressData.result.message };
      }
    }
  } else {
    // Failed to add a credit card
    return { isAdded: false, error: addAPICreditCardData.result.message };
  }
};

import { formatOrderType, storeIsClosedAllWeek } from "../../_common/helpers";
import { callAPI } from "../../_common/Api";

export const get1300Stores = async (
  skin,
  orderType,
  appLanguage,
  lat = "",
  lng = "",
  postalCode = ""
) => {
  try {
    const api1300Data = await callAPI(skin, "info_vxl_1300", {
      params: [
        appLanguage,
        "1300",
        "mqid",
        "mqpass",
        formatOrderType(orderType),
        lat,
        lng,
        postalCode,
      ],
      id: "1300",
    });

    if (api1300Data.error) {
      // 1300 API (List of stores for Pickup) error
      console.error(
        `API 1300 | ${orderType} | Error code ${api1300Data.error.code} | ${api1300Data.error.message}`
      );
      return { error: api1300Data.error.message, statusCode: api1300Data.error.code };
      //
    } else {
      let validApiStores = [];

      api1300Data.result.I2.forEach((apiStore) => {
        if (!storeIsClosedAllWeek(apiStore)) {
          validApiStores.push({
            latitude: parseFloat(apiStore.store_latitude),
            longitude: parseFloat(apiStore.store_longitude),
            storeId: apiStore.outlet_id,
            name: apiStore.store_name,
            address: apiStore.store_address,
            country: apiStore.store_country,
            province: apiStore.store_province,
            city: apiStore.store_city,
            postal: apiStore.store_postal,
            phone: apiStore.store_phone,
            hours: {
              monday: apiStore.monday_hours || "closed",
              tuesday: apiStore.tuesday_hours || "closed",
              wednesday: apiStore.wednesday_hours || "closed",
              thursday: apiStore.thursday_hours || "closed",
              friday: apiStore.friday_hours || "closed",
              saturday: apiStore.saturday_hours || "closed",
              sunday: apiStore.sunday_hours || "closed",
            },
            hoursComment: apiStore.outlet_hours_comment,
            amenities: apiStore.store_amenities,
            distanceFromUser: apiStore.distance_from_user,
          });
        }
      });

      return validApiStores;

      /*return api1300Data.result.I2.map((apiStore) => ({
        latitude: parseFloat(apiStore.store_latitude),
        longitude: parseFloat(apiStore.store_longitude),
        storeId: apiStore.outlet_id,
        name: apiStore.store_name,
        address: apiStore.store_address,
        country: apiStore.store_country,
        province: apiStore.store_province,
        city: apiStore.store_city,
        postal: apiStore.store_postal,
        phone: apiStore.store_phone,
        hours: {
          monday: apiStore.monday_hours || "closed",
          tuesday: apiStore.tuesday_hours || "closed",
          wednesday: apiStore.wednesday_hours || "closed",
          thursday: apiStore.thursday_hours || "closed",
          friday: apiStore.friday_hours || "closed",
          saturday: apiStore.saturday_hours || "closed",
          sunday: apiStore.sunday_hours || "closed",
        },
        hoursComment: apiStore.outlet_hours_comment,
        amenities: apiStore.store_amenities,
      }));*/
    }
    //
  } catch (error) {
    // Generic fallback for the failed 1300 API call
    console.error(`API 1300 | ${orderType} | ${error.message}`);
    return { error: "Error" };
  }
};

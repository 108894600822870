import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";
import { useLocation } from "react-router-dom";

//Contexts
import OrderTimeContext from "../OnlineOrdering/OrderTimeContext";
import BrandingContext from "../App/BrandingContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import StoreContext from "../OnlineOrdering/StoreContext";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";
import AppLabelsContext from "../App/AppLabelsContext";
import AppLanguageContext from "../App/AppLanguageContext";

//Custom Hooks
import useOrderTypeMinInterval from "../_common/hooks/useOrderTypeMinInterval";
import useWindowSize from "../_common/hooks/useWindowSize";

//Helper functions
import { getContrastColor } from "../_common/colorHelpers";
import {
  checkPickupInterval,
  formatStoreHours,
  formatStoreTimeOverrides,
  getClosingTime,
  getDayOfWeekFromIndex,
  getFormattedStoreHoursWithMidnight,
  getIntermittentIncrementInterval,
  getOpeningTime,
  getTodayClosingTime,
  getTodayOpeningTime,
  isTimeSlotBlocked,
  isWithinBusinessHours,
} from "../OnlineOrdering/Locations/helpers/isStoreOpenOrClosed";
import {
  getMaxIteration,
  getTimeAmPm,
  isLastDayOfMonth,
  newTime,
  prettifyDate,
  roundTime,
  roundToNearestQuarter,
} from "../Dashboard/DashboardOrder/dateHelpers";

import { capitalizeWords } from "../_common/stringHelpers";
import {
  calculateNextAvailableOrderTime,
  isOrderTimeBeforeOpeningTime,
} from "../_common/PaymentHelpers";

//Assets Components
import { ReactComponent as IconArrowRight } from "../_common/icons/IconArrowRight.svg";
import { ReactComponent as IconClock } from "../_common/icons/IconClock.svg";

const getMarquiMaxFutureOrderDates = async () => {
  try {
    const data = await fetch("settings.json");
    const json = await data.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

const checkHoursValidity = (
  hours,
  orderCutOffTimeMinutes,
  isToday,
  orderStore,
  dayIndex,
  originalOrderDate = null
) => {
  let areValidHours;

  const openingTime = hours.split(" - ")[0];
  const closingTime = hours.split(" - ")[1];

  const openingTimeHours = openingTime.split(":")[0];
  const openingTimeMinutes = openingTime.split(":")[1];

  const closingTimeHours = closingTime.split(":")[0];
  const closingTimeMinutes = closingTime.split(":")[1];

  let openingTimeDate = new Date();
  openingTimeDate.setHours(openingTimeHours);
  openingTimeDate.setMinutes(openingTimeMinutes);

  let closingTimeDate = new Date();
  closingTimeDate.setHours(closingTimeHours);
  closingTimeDate.setMinutes(closingTimeMinutes);

  const cutOffTime = new Date(closingTimeDate - orderCutOffTimeMinutes * 60000);
  cutOffTime.setSeconds(0, 0);
  if (orderStore.openPassedMidnight[getDayOfWeekFromIndex(dayIndex)] === true) {
    cutOffTime.setDate(cutOffTime.getDate() + 1);
  }
  closingTimeDate.setDate(cutOffTime.getDate());

  const timeDifference = Math.abs(closingTimeDate - openingTimeDate); // in milliseconds
  const minutesDifference = Math.floor(timeDifference / 60000);

  const now = new Date();
  const nowAndClosingTimeDifference = Math.abs(now - closingTimeDate); // in milliseconds
  const nowAndClosingTimeMinutesDifference = Math.floor(nowAndClosingTimeDifference / 60000);

  if (
    minutesDifference <= orderCutOffTimeMinutes ||
    (isToday && nowAndClosingTimeMinutesDifference < orderCutOffTimeMinutes)
  ) {
    areValidHours = false;
  } else {
    areValidHours = true;
  }
  if (originalOrderDate && originalOrderDate.getDay() === dayIndex) {
    return true;
  } else {
    return areValidHours;
  }
};

export const OrderSettingsTime = (props) => {
  const {
    setOpenTimeSelectionPanel,
    openTimeSelectionPanel,
    setOrderDateOptions,
    setActiveOrderDateOption,
    setOrderTimeOptions,
    setActiveOrderTimeOption,
    activeOrderDateOption,
    generatedTimeOptions,
  } = props;
  const HOSTNAME = window.location.hostname;
  const IS_LOCALHOST =
    HOSTNAME === "localhost" ||
    (HOSTNAME.split(".").length === 4 && isNaN(Number(HOSTNAME.split("."))));

  const DEFAULT_MAX_ORDER_DATES = 4;

  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);

  const orderTimeContext = useContext(OrderTimeContext);
  const branding = useContext(BrandingContext);

  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;

  const textIconColor = getContrastColor(branding["online-order-settings-background-colour"]);

  const storeContext = useContext(StoreContext);
  const activeOrderStore = storeContext.activeOrderStore;
  const activeStoreVexilorConfig = activeOrderStore.vexilorConfig;
  const pickupIncrement = activeStoreVexilorConfig.pickup_inc_interval;

  const activeOrderType = useContext(OrderTypeContext).value;

  const deviceWidth = useWindowSize().width;

  const pickupMinInterval = useOrderTypeMinInterval(
    activeStoreVexilorConfig,
    activeOrderType,
    skin,
    activeOrderStore
  );

  const [futureOrderDatesAllowed, setFutureOrderDatesAllowed] = useState(true);
  const [maxFutureDates, setMaxFutureDates] = useState("idle");

  const [originalOrderDate, setOrginalOrderDate] = useState(null);
  const [originalOrderTimeContext, setOriginalOrderTimeContext] = useState(null);
  const routerLocation = useLocation();

  useEffect(() => {
    if (
      routerLocation.state &&
      routerLocation.state.showTimePanel &&
      routerLocation.state.timeAtOrdering
    ) {
      let orgDate = routerLocation.state.timeAtOrdering;
      setOrginalOrderDate(orgDate);
      setOriginalOrderTimeContext(routerLocation.state.originalOrderTimeContext);
    }
    if (IS_LOCALHOST) {
      setMaxFutureDates(DEFAULT_MAX_ORDER_DATES); // app fallback
    } else {
      getMarquiMaxFutureOrderDates().then((marquiMaxDates) => {
        if (marquiMaxDates && marquiMaxDates["max-order-dates"]) {
          setMaxFutureDates(Number(marquiMaxDates["max-order-dates"]));
        } else {
          setMaxFutureDates(DEFAULT_MAX_ORDER_DATES); // app fallback
        }
      });
    }
    /* if user loaded app for first time and does not even open the time panel, populate timeAtOrdering in orderTimeContext with time app was opened.
    If store is near closing time by the time user reaches cart checkout, this time at ordering value will be used to re-render order time panel from past time */
    if (!originalOrderDate && orderTimeContext && !orderTimeContext.value.timeAtOrdering) {
      orderTimeContext.value.timeAtOrdering = new Date();
    }
  }, []);

  useEffect(() => {
    if (maxFutureDates !== "idle" && generatedTimeOptions.current === false) {
      const isStoreClosingSoon = checkPickupInterval(
        pickupMinInterval,
        activeOrderStore.closingTime,
        activeOrderStore.hours
      );

      const storeHours = activeOrderStore.hours;

      const today = new Date().getDay();

      let nextValidDate = calculateNextAvailableOrderTime(activeOrderStore, activeOrderType, skin);
      let nextValidDay = nextValidDate.getDay();
      let startingDay = isStoreClosingSoon ? today + Math.ceil(pickupMinInterval / 1440) : today;

      if (orderTimeContext.value.trueBusinessDate) {
        if (today !== orderTimeContext.value.trueBusinessDate.getDay()) {
          startingDay = today;
        } else {
          startingDay = orderTimeContext.value.trueBusinessDate.getDay();
        }
      }
      if (!originalOrderDate && startingDay !== nextValidDay) {
        startingDay = nextValidDay;
      }
      if (originalOrderTimeContext) {
        startingDay = originalOrderTimeContext.value.trueBusinessDate.getDay();
      }
      if (maxFutureDates === 0 && isStoreClosingSoon) {
        setFutureOrderDatesAllowed(false);
      }

      const options = [];

      let orderCutOffTimeMinutes = 0;
      if (
        activeStoreVexilorConfig.ordering_cut_off_time &&
        activeStoreVexilorConfig.ordering_cut_off_time !== "None"
      ) {
        orderCutOffTimeMinutes = Number(activeStoreVexilorConfig.ordering_cut_off_time);
      }
      // Generate each order date option
      for (let day = startingDay; day < startingDay + maxFutureDates + 1; day++) {
        const dayIndex = day > 6 ? day % 7 : day; // converts all date numbers after 6 to a 0-6 day number
        const storeDayHours = storeHours[getDayOfWeekFromIndex(dayIndex)];
        const nowDayOfWeek = new Date().getDay();
        const isToday = nowDayOfWeek === day;
        if (
          storeDayHours !== "closed" &&
          checkHoursValidity(
            storeDayHours,
            orderCutOffTimeMinutes,
            isToday,
            activeOrderStore,
            dayIndex,
            originalOrderDate
          )
        ) {
          let now = new Date();
          if (startingDay === day && originalOrderDate) {
            now = originalOrderDate;
          }

          const nowDateNumber = now.getDate();
          if (!originalOrderDate) {
            now.setDate(nowDateNumber + (day - nowDayOfWeek));
          } else if (originalOrderDate) {
            if (startingDay === day) {
              now.setDate(nowDateNumber);
            } else {
              now.setDate(nowDateNumber + (day - nowDayOfWeek));
            }
          }

          const option = {
            value: now.getMonth() + "-" + now.getDate(), // the day of a date as a number (1-31)
            displayValue: isToday ? appLabels["order"]["today"] : prettifyDate(now, appLanguage), // stringified Date object
            dateObject: now,
          };
          if (startingDay === day && originalOrderDate) {
            option.dateObject = originalOrderTimeContext.value.trueBusinessDate;
          }
          options.push(option);
        }
      }

      /**
       * Check the closing hours of the first date in the options list.
       * If the current time + min interval is passed the closing time for that date the date should be removed from the list
       */
      if (options.length > 0) {
        const month = options[0].value.split("-")[0];
        const date = options[0].value.split("-")[1];

        let activeOrderDate = originalOrderDate ? originalOrderDate : new Date();
        activeOrderDate.setMonth(month, 1); // 1 is a dummy value to prevent JS from skipping months without the current date
        activeOrderDate.setDate(date);

        let selectedDay = new Date(activeOrderDate).getDay();
        let selectedDate = Number(date);

        const selectedDayApiOpeningTime = getOpeningTime(storeHours, selectedDay);
        const selectedDayApiClosingTime = getClosingTime(storeHours, selectedDay);

        const selectedDayStoreHours = formatStoreHours(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime
        );

        let selectedDayStoreOpeningTime = selectedDayStoreHours.formattedOpeningTime;
        let selectedDayStoreClosingTime = selectedDayStoreHours.formattedClosingTime;

        let d = new Date(selectedDayStoreOpeningTime);
        let d1 = new Date(selectedDayStoreClosingTime);

        let nextDay = 0;

        // Account for closing time falling into the next day
        if (selectedDayStoreClosingTime.getDay() > selectedDayStoreOpeningTime.getDay()) {
          nextDay = 1;
        }

        selectedDayStoreOpeningTime = new Date(d.setDate(selectedDate));
        selectedDayStoreClosingTime = new Date(d1.setDate(selectedDate + nextDay));

        const currentTime = new Date();
        const currentWithMinInterval = new Date(currentTime.getTime() + pickupMinInterval * 60000);

        const cutOffTime = new Date(
          new Date(selectedDayStoreClosingTime - orderCutOffTimeMinutes * 60000).setMonth(month)
        );
        if (
          isLastDayOfMonth(selectedDayStoreOpeningTime) &&
          activeOrderStore.openPassedMidnight[
            getDayOfWeekFromIndex(selectedDayStoreOpeningTime.getDay())
          ] === true
        ) {
          cutOffTime.setMonth(month + 1);
        }
        if (currentWithMinInterval > cutOffTime && !originalOrderDate) {
          options.splice(0, 1);
        }
      }

      // Set all available options
      setOrderDateOptions(options);

      // Set the first available option only if it wasn't already retrieved from storage
      localforage.getItem(skin + "__orderDate").then((storedOrderDate) => {
        //if there is a order date in storage, also check to see the stored date also exists in the new generated date options
        if (
          storedOrderDate &&
          options.filter((date) => date.value === storedOrderDate.value).length > 0 &&
          !originalOrderDate
        ) {
          setActiveOrderDateOption(
            options.filter((date) => date.value === storedOrderDate.value)[0]
          );
        } else {
          setActiveOrderDateOption(options[0]);
          //localforage.setItem(skin + "__orderDate", options[0]);
        }
      });
    }
  }, [activeOrderStore, maxFutureDates, pickupMinInterval, generatedTimeOptions]);

  /*
    Each time an active order date changes:
    
    1. Generate order time options for today and future days
    2. Set the active order time option
  */
  useEffect(() => {
    if (
      activeOrderDateOption &&
      storeContext &&
      openTimeSelectionPanel &&
      generatedTimeOptions.current === false
    ) {
      let orderCutOffTimeMinutes = 0;
      if (
        activeStoreVexilorConfig.ordering_cut_off_time &&
        activeStoreVexilorConfig.ordering_cut_off_time !== "None"
      ) {
        orderCutOffTimeMinutes = Number(activeStoreVexilorConfig.ordering_cut_off_time);
      }
      // convert date number back to date in ms
      const month = activeOrderDateOption.value.split("-")[0];
      const date = activeOrderDateOption.value.split("-")[1];

      let activeOrderDate = new Date();
      if (
        originalOrderDate &&
        originalOrderDate.getDate() === activeOrderDateOption.dateObject.getDate()
      ) {
        activeOrderDate = originalOrderDate;
      }

      activeOrderDate.setMinutes(activeOrderDate.getMinutes());
      activeOrderDate.setMonth(month, 1); // 1 is a dummy value to prevent JS from skipping months without the current date
      activeOrderDate.setDate(date);
      activeOrderDate.setFullYear(activeOrderDateOption.dateObject.getFullYear());

      const storeHours = activeOrderStore.hours;
      const todayApiOpeningTime = originalOrderDate
        ? getTodayOpeningTime(storeHours, originalOrderDate)
        : getTodayOpeningTime(storeHours);
      const todayApiClosingTime = originalOrderDate
        ? getTodayClosingTime(storeHours, originalOrderDate)
        : getTodayClosingTime(storeHours);

      let now = originalOrderDate ? originalOrderDate : new Date();
      let roundedMinutes = roundToNearestQuarter(now.getMinutes());
      let roundedTime = roundTime(now, roundedMinutes);

      let isStoreOpen =
        activeOrderStore.isOpen &&
        todayApiOpeningTime !== "closed" &&
        todayApiClosingTime !== "closed";

      if (
        originalOrderDate &&
        originalOrderDate.getDate() === activeOrderDateOption.dateObject.getDate()
      ) {
        isStoreOpen = true;
      }

      const placingOrderForToday =
        now.getDate() === new Date(activeOrderDate).getDate() &&
        now.getMonth() === new Date(activeOrderDate).getMonth();

      const options = [];

      const formattedStoreOverrides =
        activeOrderType === "dinein"
          ? []
          : formatStoreTimeOverrides(
              activeOrderType === "delivery"
                ? activeStoreVexilorConfig.delivery_min_interval_override
                : activeStoreVexilorConfig.pickup_min_interval_override,
              activeOrderDate
            );
      const blockedTimeIntervals =
        Object.keys(formattedStoreOverrides).length === 0
          ? []
          : formattedStoreOverrides[getDayOfWeekFromIndex(activeOrderDate.getDay())].filter(
              (interval) => interval.isBlocked
            );
      if (isStoreOpen && placingOrderForToday) {
        // Store is open and ordering for today
        //const storeHours = formatStoreHours(todayApiOpeningTime, todayApiClosingTime);
        const storeHours = getFormattedStoreHoursWithMidnight(
          todayApiOpeningTime,
          todayApiClosingTime,
          orderTimeContext.value.trueBusinessDate,
          activeOrderStore
        );
        const storeClosingTime = storeHours.formattedClosingTime;
        const cutOffTime = new Date(storeClosingTime - orderCutOffTimeMinutes * 60000);
        /** matche the cutOffTime Date to the closing time */
        cutOffTime.setDate(storeClosingTime.getDate());
        // Get the total number of all possible order timeslots
        const maxIteration = getMaxIteration(
          now,
          storeClosingTime,
          pickupMinInterval,
          pickupIncrement
        );

        for (let t = 0; t < maxIteration; t++) {
          roundedMinutes = roundToNearestQuarter(now.getMinutes());
          roundedTime = roundTime(now, roundedMinutes, true);
          let increment;
          if (
            originalOrderDate &&
            originalOrderDate.getDate() === activeOrderDateOption.dateObject.getDate()
          ) {
            increment = getIntermittentIncrementInterval(
              formattedStoreOverrides,
              roundedTime,
              pickupIncrement,
              originalOrderDate
            );
          } else {
            increment = getIntermittentIncrementInterval(
              formattedStoreOverrides,
              roundedTime,
              pickupIncrement
            );
          }
          let nextTime = newTime(roundedTime, t === 0 ? pickupMinInterval : increment); // "In {pickupMinInterval OR pickupIncrement} min"

          // code for fixing correct hours for a day
          let nextValidTime = calculateNextAvailableOrderTime(
            activeOrderStore,
            activeOrderType,
            skin,
            orderTimeContext
          );

          if (
            originalOrderDate &&
            originalOrderTimeContext.value.trueBusinessDate.getDate() ===
              activeOrderDateOption.dateObject.getDate()
          ) {
            console.log("");
          } else if (nextTime !== undefined && nextTime < nextValidTime) {
            nextTime = nextValidTime;
          } else {
            nextTime = newTime(roundedTime, t === 0 ? pickupMinInterval : increment);
          }
          const nextTimeWithResetSeconds = new Date(nextTime);
          nextTimeWithResetSeconds.setSeconds(0, 0); // reset seconds to 0 to allow pre-select based on storage value
          if (nextTimeWithResetSeconds < cutOffTime) {
            const option = {
              value: nextTimeWithResetSeconds,
              displayValue:
                t === 0
                  ? `${appLabels["order"]["asap"]} (~${pickupMinInterval} ${appLabels["order"]["minutes-short-hand"]})`
                  : getTimeAmPm(nextTimeWithResetSeconds),
            };

            if (
              !isTimeSlotBlocked(option.value, blockedTimeIntervals) &&
              isWithinBusinessHours(activeOrderStore, nextTimeWithResetSeconds) &&
              !isOrderTimeBeforeOpeningTime(activeOrderStore, {
                value: {
                  value: nextTimeWithResetSeconds,
                  trueBusinessDate: orderTimeContext.value.trueBusinessDate,
                },
              })
            ) {
              options.push(option);
            }

            now = new Date(nextTimeWithResetSeconds);
          } else {
            break; // stop the loop when all valid timeslots are generated
          }
        }
      } else {
        // Store is closed and/or ordering for future date
        let selectedDay = new Date(activeOrderDate).getDay();
        let selectedDate = Number(date);

        const selectedDayApiOpeningTime = getOpeningTime(storeHours, selectedDay);
        const selectedDayApiClosingTime = getClosingTime(storeHours, selectedDay);

        /*const selectedDayStoreHours = formatStoreHours(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime
        );*/

        const selectedDayStoreHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          activeOrderDateOption.dateObject,
          activeOrderStore
        );

        let selectedDayStoreOpeningTime = selectedDayStoreHours.formattedOpeningTime;
        let selectedDayStoreClosingTime = selectedDayStoreHours.formattedClosingTime;

        let d = new Date(selectedDayStoreOpeningTime);
        selectedDayStoreOpeningTime = new Date(d.setDate(selectedDate));
        selectedDayStoreOpeningTime.setMonth(month);

        /** If selectedDayStoreOpeningTime is the last day of the month and selectedDayStoreClosingTime falls to the next day
         *  adjust the month of the closing time and the cutoff time
         * */
        if (
          isLastDayOfMonth(selectedDayStoreOpeningTime) &&
          activeOrderStore.openPassedMidnight[
            getDayOfWeekFromIndex(selectedDayStoreOpeningTime.getDay())
          ] === true
        ) {
          selectedDayStoreClosingTime.setMonth(parseInt(month) + 1);
        } else {
          selectedDayStoreClosingTime.setMonth(parseInt(month));
        }

        let cutOffTime = new Date(
          new Date(selectedDayStoreClosingTime - orderCutOffTimeMinutes * 60000).setMonth(month)
        );
        if (
          isLastDayOfMonth(selectedDayStoreOpeningTime) &&
          activeOrderStore.openPassedMidnight[
            getDayOfWeekFromIndex(selectedDayStoreOpeningTime.getDay())
          ] === true
        ) {
          cutOffTime.setMonth(month + 1);
        }

        /** use default min interval values for future order dates */
        let defaultMinInterval =
          activeOrderType === "delivery"
            ? activeStoreVexilorConfig.delivery_min_interval
            : activeStoreVexilorConfig.pickup_min_interval;

        const maxIteration = getMaxIteration(
          selectedDayStoreOpeningTime,
          selectedDayStoreClosingTime,
          defaultMinInterval,
          pickupIncrement
        );

        //const currentTime = new Date();
        for (let t = 0; t <= maxIteration; t++) {
          if (t === 0) {
            now = new Date(activeOrderDate);
            /** if the current time + pickup min interval is not passed the opening hours of the store
             * set the starting hour and minute to match the opening time of the location
             */
            /*if (
              new Date(currentTime.getTime() + pickupMinInterval * 60000) <
              selectedDayStoreOpeningTime
            ) {
              now.setHours(selectedDayStoreOpeningTime.getHours()); //adjust the current time's hour
              now.setMinutes(selectedDayStoreOpeningTime.getMinutes());
            } else {
              //else set the starting time to current time + min interval
              now = new Date(currentTime.getTime() + pickupMinInterval * 60000);
            }*/

            if (
              originalOrderTimeContext &&
              originalOrderDate.getDate() === activeOrderDateOption.dateObject.getDate()
            ) {
              now.setTime(new Date(originalOrderDate).getTime() + defaultMinInterval * 60000);
              now.setSeconds(0);
              now.setFullYear(activeOrderDateOption.dateObject.getFullYear());
            } else {
              now.setTime(
                new Date(selectedDayStoreOpeningTime).getTime() + defaultMinInterval * 60000
              );
              now.setSeconds(0);
              now.setFullYear(activeOrderDateOption.dateObject.getFullYear());
            }
            /*const defaultOption = {
              value: "Select Time",
              displayValue: appLabels["order"]["select-time"],
            };
            options.push(defaultOption);*/
          }

          roundedMinutes = t === 0 ? 0 : roundToNearestQuarter(now.getMinutes());
          roundedTime = roundTime(now, roundedMinutes);
          var nextTime = newTime(roundedTime, t === 0 ? 0 : pickupIncrement); // "In {pickupMinInterval OR pickupIncrement} min"

          //cutOffTime.setDate(nextTime.getDate());
          nextTime.setSeconds(0, 0); // reset seconds and milliseconds for date comparison
          cutOffTime.setSeconds(0, 0); // reset seconds and milliseconds for date comparison
          cutOffTime.setFullYear(activeOrderDateOption.dateObject.getFullYear());

          /** CutOffTime should matche the closing time object's date */
          let selectedDayApiOpeningTime = getOpeningTime(
            activeOrderStore.hours,
            activeOrderDateOption.dateObject.getDay()
          );

          let selectedDayApiClosingTime = getClosingTime(
            activeOrderStore.hours,
            activeOrderDateOption.dateObject.getDay()
          );

          let actualStoreHours = getFormattedStoreHoursWithMidnight(
            selectedDayApiOpeningTime,
            selectedDayApiClosingTime,
            activeOrderDateOption.dateObject,
            activeOrderStore
          );

          const storeClosingTime = actualStoreHours.formattedClosingTime;
          let cutOffMinutes = activeStoreVexilorConfig.ordering_cut_off_time;
          const cutOffTimestamp = new Date(storeClosingTime - cutOffMinutes * 60000);

          let targetDate = selectedDayStoreHours.formattedClosingTime.getDate();
          cutOffTime.setDate(targetDate);
          if (nextTime < cutOffTimestamp) {
            const option = {
              value: nextTime,
              displayValue: getTimeAmPm(nextTime.getTime()),
            };

            if (!isTimeSlotBlocked(option.value, blockedTimeIntervals)) {
              options.push(option);
            }

            now = nextTime;
          } else if (nextTime.getTime() === cutOffTime.getTime()) {
            const option = {
              value: nextTime,
              displayValue: getTimeAmPm(nextTime.getTime()),
            };
            if (!isTimeSlotBlocked(option.value, blockedTimeIntervals)) {
              options.push(option);
            }
            break;
          } else {
            break; // stop the loop when all valid timeslots are generated
          }
        }
      }

      // Set all available options
      setOrderTimeOptions(options);
      // Set the first available option only if it wasn't already retrieved from storage
      localforage.getItem(skin + "__orderTime").then((storedOrderTime) => {
        if (
          !originalOrderDate &&
          storedOrderTime &&
          options.filter((time) => time.displayValue === storedOrderTime.displayValue).length > 0 &&
          storedOrderTime.value.getTime() > new Date().getTime()
        ) {
          setActiveOrderTimeOption(storedOrderTime);
        } else {
          setActiveOrderTimeOption(options[0]);
          //orderTimeContext.update(options[0]); // update order time in context
        }
      });

      generatedTimeOptions.current = true;
    }
  }, [activeOrderDateOption, storeContext, openTimeSelectionPanel]);

  const toggleTimeSelectionPanel = () => {
    setOpenTimeSelectionPanel(!openTimeSelectionPanel);
  };

  return (
    <>
      {orderTimeContext.value &&
        orderTimeContext.value.value &&
        futureOrderDatesAllowed &&
        orderTimeContext.value.displayValue && (
          <>
            {deviceWidth > 660 && (
              <IconClock
                style={{ color: textIconColor, marginBottom: deviceWidth > 1024 ? "3px" : "0px" }}
              />
            )}
            <div className="order-settings-component__store-wrapper order-settings-component__time-wrapper">
              <div
                className="order-settings-component__store"
                style={{ borderColor: textIconColor }}
              >
                <span
                  style={{
                    color: textIconColor,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    marginBottom: "4px",
                  }}
                >
                  {orderTimeContext.value.displayValue.includes(appLabels["order"]["asap"])
                    ? orderTimeContext.value.displayValue
                    : capitalizeWords(
                        getDayOfWeekFromIndex(
                          orderTimeContext.value.value.getDay(),
                          appLabels
                        ).substr(0, 3)
                      ) +
                      "-" +
                      orderTimeContext.value.displayValue}
                </span>
              </div>

              <button
                id="dateTimePanel"
                type="button"
                className="order-settings-component__store-button"
                onClick={toggleTimeSelectionPanel}
                aria-label={appLabels["order"]["select-pickup-time"]}
              >
                <IconArrowRight
                  aria-hidden="true"
                  style={{ fill: textIconColor }}
                  className={"order-settings__right-arrow"}
                />
              </button>
            </div>
          </>
        )}
    </>
  );
};

export const orderLabels = {
  "dashboard-section-heading": "Pedir Online",
  "select-store-for-menu-modal-message": "Favor de seleccionar una sucursal para ver el menú",
  "select-order-time-for-menu-modal-message":
    "Favor de seleccionar un horario valido para ver el menú",
  "closest-store": "Sucursales cerca de usted",
  "select-store": "Seleccione una sucursal",
  "find-store": "Buscar una sucursal",
  pickup: "Recoger",
  delivery: "Entrega a domicilio",
  "dine-in": "Comer aquí",
  "select-time": "Seleccione un horario",
  "no-future-dates": "No es posible colocar pedidos para esta sucursal",
  "pickup-and-delivery-times-disclaimer": "Los horarios de entrega y para recoger son aproximados",
  featured: "Destacadas",
  "select-order-time-for-featured-modal-message":
    "Favor de seleccionar un horario valido de pedido para ver los artículos destacados",
  "whats-new": "Novedades",
  "selected-store": "Sucursal seleccionada",
  today: "Hoy",
  "in-minutes": "En [número] minutos",
  "map-overlay-pickup-heading": "Vamos buscar una sucursal para recoger",
  "map-overlay-delivery-heading": "Cual es la dirección para la Entrega?",
  "use-your-location": "Utilizar su ubicación",
  "or-enter-an-address": "O Ingrese una dirección",
  "maps-searchbox-placeholder": "Ingrese ciudad, código postal",
  "previously-found-stores": "Anterior:",
  "order-here": "Ordene Aquí",
  "order-ahead": "Ordene a futuro",
  "list-view": "Visualizar Lista",
  "map-view": "Visualizar Mapa",
  "more-store-info-button": "Información",
  "close-more-store-info-button": "Regresar",
  menu: "Menú",
  favorites: "Favoritos",
  "no-favorites-message": "No tiene ningún favorito",
  "remove-favorite-confirmation": "Esta seguro desea eliminar este artículo de sus favoritos?",
  "view-favorite-item": "Visualizar artículo",
  calories: "Cal",
  "select-options": "Seleccionar Opciones",
  "modify-this-item": "Modificar este artículo",
  "add-extras": "Agregar extras",
  "special-instructions": "Instrucciones Especiales",
  "add-to-order": "Agregar [item-quantity] al Pedido",
  "add-another": "Agregar Otro",
  "menu-disclaimer": "",
  "menu-item-disclaimer": "",
  added: "Agregado",
  "save-changes": "Salvar Cambios",
  "button-save-changes-success": "Salvado",
  "recent-orders": "Pedidos Recientes",
  reorder: "Reordenar",
  "view-one-item": "Visualizar 1 artículo",
  "view-multiple-items": "Visualizar [número] artículos",
  "view-cart": "Visualizar Carro",
  "back-to-menu": "Regresar al Menú",
  "review-order": "Revisar Pedido",
  "your-order-details": "Detalles de su Pedido",
  "items-in-the-cart": "artículo(s) en su carro",
  "discount-and-coupon-button": "Descuento/Cupón",
  "discount-code": "Código de Descuento",
  "check-discount": "Verificar Descuento",
  "coupon-code": "Código de Cupón",
  "check-coupon": "Verificar Cupón",
  "discount-remove-confirmation": "Esta seguro que desea eliminar este código de descuento?",
  "use-rewards": "Usar Recompensas",
  discount: "Descuento",
  coupon: "Cupón",
  "apply-discount-coupon": "Aplicar",
  "invalid-discount-coupon-error": "Código de Descuento invalido",
  "only-one-discount-reward-error":
    "Solo se puede aplicar un descuento/recompensa a su pedido. Por favor elimine el descuento aplicado si desea agregar otro",
  tax: "Impuesto",
  "add-tip": "Agregar Propina",
  "no-tip": "Sin Propina",
  tip: "Propina",
  "custom-amount": "Cantidad personalizada",
  modify: "Modificar",
  "remove-all-items": "Eliminar todos los artículos",
  "add-more-items": "Agregar más artículos",
  "empty-cart-message": "Su carro de compras esta vacío",
  "begin-checkout": "Iniciar Checkout",
  "customer-info-form-heading": "Vamos a confirmar su contacto del pedido",
  "order-contact-confirm": "Siguiente",
  "order-contact-confirming": "Siguiente…",
  "pickup-information": "Información para recoger el pedido",
  "review-order-disclaimer": "",
  "pickup-information-subheading": "",
  payment: "Pago",
  "use-another-credit-card": "Usar otra tarjeta de crédito",
  "saved-credit-card-heading": "tarjeta de crédito guardada",
  "return-to-saved-credit-card": "Regresar a tarjeta de crédito guardada",
  "replacing-saved-credit-card-confirmation":
    "Usted ya tiene una tarjeta de crédito guardada. Esta seguro que desea eliminar [número de tarjeta]?",
  "card-balance": "Saldo de la tarjeta",
  "apply-gift-card-to-order-button": "Aplicar",
  "registered-gift-card": "Tarjeta de regalo registrada",
  "back-to-registered-gift-card": "Regresar a Tarjeta de regalo registrada",
  "apply-rewards-to-order-button": "Aplicar",
  "use-another-gift-card": "Usar otra tarjeta de regalo",
  "gift-card-number": "Numero de Tarjeta de Regalo",
  "pin-number": "Número de PIN",
  "no-active-gift-cards-message": "Uste no cuenta con ninguna tarjeta activa",
  "order-total": "Total de Pedido",
  "remaining-balance-on-gift-card": "Restante en su tarjeta",
  "pay-remainder-with-credit-card": "Pagar el restante con tarjeta de crédito",
  "amount-due": "Monto",
  "card-has-already-been-applied-message": "Esta tarjeta ya ha sido aplicada",
  "pay-on-pickup": "Pagar al recoger el pedido",
  "pay-on-delivery": "Pagar contra entrega",
  "submit-order-button": "Completar Pedido",
  "submit-order-button-processing": "Completando el Pedido…",
  "payment-disclaimer": "",
  "order-too-close-to-closing-time-error":
    "Lamentamos informar que no podemos aceptar su pedido en este momento. No se aceptan pedidos en línea muy próximos o previos a la hora de cierre.",
  "select-order-time-passed-message":
    "El horario seleccionado de su pedido ha pasado. El próximo horario disponible será",
  "confirm-updated-order-time-message": "Desea continuar con el horario actualizado de su pedido?",
  "order-confirmation": "Confirmación de Pedido",
  "order-thank-you-message": "Gracias por su pedido",
  "your-order-number-is": "Su numero de pedido es",
  "we-sent-you-confirmation-email": "Hemos enviado una confirmación a su correo electrónico",
  "order-confirmation-disclaimer": "",
  "item-for-pickup-at": "[quantity] artículo para [order-type] en",
  "items-for-pickup-at": "[quantity] artículos para [order-type] en",
  "item-for-delivery-or-dinein-from": "[quantity] artículo para [order-type] de",
  "items-for-delivery-or-dinein-from": "[quantity] artículos para [order-type] de",
  "item-for-dine-in-at": "[quantity] artículo para [order-type] en",
  "items-for-dine-in-at": "[quantity] artículos para [order-type] en",
  "order-type-change-confirmation": "Esta seguro que desea modificar su pedido a [order-type]?",
  "order-store-change-confirmation": "Esta seguro que desea modificar su sucursal a [order-store]?",
  "your-current-order-will-be-cleared": "Su pedido actual será eliminado",
  "cart-item-removal-confirmation": "Esta seguro que desea eliminar [item-name] de su carro?",
  "cart-item-removal-discount-also-removed-message":
    "Al eliminar esta artículo, también se eliminara el código de descuento asociado",
  "order-type-order-date-at-order-time": "[order-type] [order-date] en [order-time]",
  "delivery-shipping-form-submit-button": "Confirmar",
  "delivery-shipping-form-submit-button-processing": "Confirmando…",
  "no-pickup-stores-within-range-message":
    "No se encuentran sucursales recoger en [stores-range] km de usted",
  "distance-to-store": "Distancia [distance-from-user] km",
  "store-open-status": "Abierto",
  "store-closed-status": "Cerrado",
  "store-open-until-closing-time": "Cierra a las [closing-time]",
  "no-store-menu-message": "No se encontró menú para esta sucursal",
  "select-up-to-n-items": "Seleccione hasta [max-quantity] artículo(s) (opcional)",
  "select-n-items": "Seleccione [min-quantity] artículos",
  "select-n-item": "Seleccione [min-quantity] artículo",
  "select-between-n-and-n-items": "Seleccione entre [min-quantity] y [max-quantity] artículos",
  "calories-disclaimer":
    "Los Adolescentes y Adultos (13 años o más) necesitan un promedio de 2,000 calorías diarias, y los niños (de 4 a 12 años) necesitan un promedio de 1,500 calorías diarias. Sin embargo, las necesidades individuales pueden variar.",
  "button-add-to-combo-group-choice": "Agregar a elección [item-group-id]",
  "button-add-to-combo-group-choice-success": "Agregado",
  "button-save-combo-choices": "Guardar cambios",
  "button-save-combo-choices-success": "Guardado",
  "combo-choice": "Elección",
  "combo-summary": "Resumen",
  "combo-choose-item": "Elija un artículo de las siguientes opciones",
  "add-gift-card": "Agregar tarjeta de regalo",
  "add-another-gift-card": "Agregar otra tarjeta de regalo",
  "complete-order-pay-on-pickup-blurb":
    "Termine su pedido y pague cuando pase a recoger su pedido en la sucursal",
  "complete-order-pay-on-delivery-blurb":
    "Termine su pedido y pague al repartido cuando reciba su pedido",
  "bring-coupon-notice": "No olvide traer su [balance] cupón!",
  "complete-order-see-you-soon": "Hasta la próxima!",
  "recent-order-item-modifier-heading": "Modificado",
  "recent-order-item-addon-heading": "Agregado",
  "recent-order-unavailable-item-notification": "Uno o mas artículos no están disponibles",
  "recent-order-price-change-notification":
    "* Subtotal del pedido anterior puede variar ya que los precios están sujetos a cambios",
  "card-autoreload-success-message":
    "Ha configurado satisfactoriamente la auto recarga para la tarjeta [card-numbe] por un monto de [amount]",
  "card-autoreload-when-balance-reaches": "cuando el saldo llegue a",
  "card-autoreload-weekly-frequency": "semanalmente",
  "card-autoreload-monthly-frequency": "mensualmente",
  "add-money-add-credit-card": "Agregar tarjeta de crédito",
  "pay-in-store-card-expiry": "Expira en",
  "qr-code": "Código QR",
  "pay-in-store-how-to-heading": "Esta listo para configurar checkout express en sucursal?",
  "pay-in-store-how-to-blurb-1":
    "Agregue una tarjeta a su cuenta para aprovechar las ventajas del express checkout en la sucursal",
  "pay-in-store-how-to-blurb-2":
    "Puede agregar múltiples tarjetas a su cuenta, consultar su saldo y agregar fondos o configurar recargas automáticas",
  "pay-in-store-add-new-card": "Agregar una nueva tarjeta",
  "complete-order-pay-with-paypal":
    'Al hacer clic en "Completar Pedido" será redireccionado a PayPal para completar su pedido con seguridad',
  "cart-removing-items-with-out-of-stock-addons-message":
    "Artículos eliminados por complementos agotados",
  "cart-removing-item-with-out-of-stock-addons-message":
    "[item-name] eliminado por complementos agotados",
  "item-detail-addons-out-of-stock-error": "[number] complementos requeridos están agotados",
  "item-detail-addon-out-of-stock-error": "Complemento seleccionado esta agotado",
  "dashboard-primary-order-button": "Ordene ahora",
  "dashboard-disclaimer": "",
  "dine-in-qr-scanner-instructions": "O simplemente ingrese el numero de mesa",
  "complete-order-pay-later-dine-in-blurb": "Complete su pedido y pague al final de su consumo",
  "pay-later": "Pagar después",
  "show-menu-button": "Mostrar Menú",
  "show-menu-button-processing": "Obteniendo Menú...",
  "table-number-field-label": "Número de Mesa",
  "invalid-qr-code": "Código QR Invalido",
  "camera-access-not-supported":
    "Su navegador no soporta el acceso a la camara. Se recomienda utilizar Safari para esta funcion.",
  "invalid-coupon-number": "Numero de Cupon Invalido",
  "back-to-combo-item-name": "Regresa a [combo-item-name]",
  "confirm-modified-combo-changes-button": "Confirmar Cambios",
  "no-delivery-stores-within-range-message":
    "No hay tiendas para entrega dentro de [stores-range] km de su ubicacion",
  "order-before-store-opening-time-error":
    "Lamentamos informarle que no podemos aceptar su pedido en este momento. Estamos cerrados",
  "confirm-summary-details": "Confirme los detalles de su pedido",
  "coupon-remove-confirmation": "Esta seguro que quiere eliminar este cupon?",
  "applied-discount-code": "Descuento/Codigo de Promocion Aplicado",
  "applied-coupon-code": "Codigo de Cupon Aplicado",
  asap: "lo antes posible",
  "minimum-delivery-amount-message":
    "Se requiere un monto minimo de pedido por [delivery-minimum-amount] para entrega",
  quantity: "Cantidad",
  "check-balance": "Consultar saldo",
  "out-of-stock": "Sin existencias",
  "remove-item-from-favorites": "Eliminar [item-name] de favoritos",
  "add-item-to-favorites": "Agregar [item-name] de favoritos",
  "go-to-item-name": "Ir a detalles del articulo [item-name]",
  "go-to-whats-new-image-link": "Ir a [image-link]",
  "remove-item-from-cart": "Eliminar [item-name] del carro de compras",
  "modify-item-in-cart": "Modificar [item-name]",
  "decrease-quantity": "Disminuir cantidad",
  "increase-quantity": "Aumentar la cantidad",
  "remove-tip": "Eliminar propina",
  "show-category-subcategories": "Mostrar [category-name] subcategorias",
  "show-subcategory-items": "Mostrar [subcategory-name] articulos",
  "close-item-details": "Cerrar los detalles de [item-name]",
  "back-to-item": "Regresar a los detalles de [item-name]",
  "open-options-group-options": "Abrir las opciones de [option-group-name]",
  "select-payment-type": "Seleccionar [payment-type] como forma de pago",
  "remove-applied-giftcard": "Eliminar tarjeta de regalo aplicada",
  edit: "Editar",
  "delivering-to-address": "Entregando a",
  "edit-delivery-address": "Editar direccion de entrega",
  "order-time-is-blocked":
    "El horario solicitado ya no esta disponible. Por favor seleccione un nuevo horario para su pedido",
  "ordering-from": "Pidiendo de",
  "select-another-store-prompt": "Seleccione otra tienda para ver el menu",
  "select-a-store-prompt": "Seleccione una tienda para ver el menu",
  "discount-disclaimer": "",
  "coupon-disclaimer": "",
  "complete-your-order-below":
    "Termine su pedido y pague cuando pase a recoger su pedido en la sucursal",
  "invalid-reward": "Recompensa Invalida",
  "price-based-on-selection": "Precio en base a su seleccion",
  "total-combo-price-disclaimer": "El precio total del combo se calcula en su carro de compras",
  "order-type": "Tipo de Pedido",
  "number-of-required-choices": "[number-of-choices] Selección(es) requerida(s)",
  "minutes-short-hand": "min",
  "guest-attempt-favorite-item": "Por favor ingrese para agregar articulos a sus favoritos",
  "favorite-add-to-order": "Agregar al pedido",
  "read-more": "Leer Mas",
  "read-less": "Leer Menos",
  "ios-camera-disclaimer":
    'Para consultar el menú, ya sea:[line-break]Seleccione "Usar mi ubicación" a continuación, o[line-break]Escanee un código QR con la cámara de su dispositivo.',
  "lets-find-store-for-order-type": "Busquemos una tienda para [order-type]",
  "lets-find-store-blurb":
    "Ingrese su dirección, permita a la app utilizar su geolocalizacion o seleccione la tienda más cercana de la siguiente lista",
  "unit-can-be-added": "Se puede agregar al finalizar la compra",
  "use-my-location": "Utiliza mi ubicación",
  "select-store-all": "Todos",
  "select-store-nearby": "Cerca",
  "select-store-favorites": "Favoritos",
  "select-store-hours-info": "Horarios e información",
  "select-store-get-direction": "Cómo llegar",
  "select-store-select-store": "Seleccione Tienda",
  "guest-attempt-favorite-location": "Por favor ingrese para agregar ubicaciones a sus favoritos",
  "no-favorite-locations-blurb":
    "Toque/clic el icono de corazon para guardar la ubicacion de la tienda en sus favoritos para un acceso mas sencillo",
  "no-nearby-locations-blurb":
    "Para buscar tiendas cercanas, ingrese su direccion o toque 'Utiliza mi ubicación'",
  "time-selection-panel-header": "Seleccione fecha y hora para [order-type]",
  "no-delivery-stores-within-range": "No hay tiendas para entrega dentro del rango",
  "order-type-selection-blurb": "Como desea hacer su pedido",
  "type-in-address": "Calle y Ciudad",
  "location-not-in-delivery-range": "Esta ubicacion no esta dentro del rango de entregas",
  "no-nearby-locations-blurb-delivery":
    "Para buscar tiendas cercanas, ingrese su direccion o toque 'Utilizar mi ubicacion'",
  "price-change-error-message":
    "Tenga en cuenta que los precios de los artículos en su carrito se han actualizado para reflejar el menú disponible actualmente",
  "items-removed-from-menu-error-message":
    "Uno o mas articulos ya no estan disponibles para ordenar en la fecha/hora seleccionada",
  "dine-in-use-location-instructions":
    "Escanee el código QR o permita a la app utilizar su ubicación para continuar",
  "dine-in-not-in-location-error": "Ud debe estar en el restaurante para poder ordenar",
  "dine-in-use-location-greetings":
    "Bienvenido a [merchant-name]. Ingrese su numero de mesa para ver el menu",
  "dine-in-scan-qr-code": "Escane el codigo QR",
  "dinein-information-special-instructions": "Informacion para cenar",
  "invalid-item-quantity-error": "No queda stock de este artículo",
  "item-not-available": "Este articulo no esta disponible",
  "app-rating-header": "Cómo fue tu experiencia con la aplicación hoy?",
  "app-rating-thumbs-up": "Pulgares Arriba",
  "app-rating-thumbs-down": "Pulgares Abajo",
  "merchant-email-subject": "Comentarios de los clientes para [merchant-name] CWS5",
  "merchant-email-body-template-line-1":
    "Agradecemos su contacto, por favor háganos saber cómo podemos mejorar para la próxima vez. Si encontró un problema técnico, agregue los detalles relacionados con el problema y los pasos que tomó antes de encontrar el problema.",
  "merchant-email-do-not-remove": "***Please do not remove***",
  "merchant-email-username": "Usuario",
  "merchant-email-guest-user": "Tipo de cliente",
  "merchant-email-device-type": "Tipo de Dispositivo",
  "merchant-email-os-version": "Version SO:",
  "merchant-email-description-of-issue": "Descripcion del problem",
  "merchant-email-steps-taken": "Pasos realizaddos antes de encontrar el problema",
  "merchant-email-information-used-to-help":
    "Esta información se utiliza para ayudar a identificar y resolver problemas técnicos.",
  "merchant-email-enter-details": "<ingrese_los_detalles_aqui>",
  "cart-out-of-stock-item-error-message":
    "Desafortunadamente [item-name] no esta disponible. Este ha sido eliminado de su carro de compras",
  "pickup-information-special-instruction-placeholder": "",
  "submit-order": "Enviar Pedido",
  "order-and-pay": "Pedir y Pagar",
  checkout: "Pagar",
  "select-payment": "Seleccionar Pago",
  "ready-to-pay": "Listo para pagar?",
  "view-bill": "Ver Cuenta",
  "ready-to-pay-blurb": "Revisar, dividir y pagar su cuenta aqui",
  bill: "Cuenta",
  "confirmation-email": "Confirmacion de Email",
  "confirmation-email-blurb": "Desea le enviemos copia de su recibo por email?",
  table: "Mesa",
  "ordered-together": "Ordenados juntos",
  seat: "Asiento [seat-number]",
  "submitting-order": "Enviando Pedido...",
  "payment-confirmation": "Confirmacion de Pago",
  "thank-you-for-payment": "Gracias por su pago",
  "email-receipt-blurb": "Le hemos enviado un correo de confirmacion a [email]",
  "payment-confirmation-blurb": "Estás listo! Esperamos volver a verte pronto.",
  "submit-payment-button": "Complete el pago",
  "submit-payment-button-processing": "Completando el pago",
  "review-items": "Revisar Productos",
  "items-not-ready-for-billing": "¡Ya casi! Estamos preparando tu pedido y en breve podrás pagar.",
  "bill-page-no-order-placed-yet": "Aún no se ha enviado ningún pedido para esta mesa.",
  refresh: "Refrescar",
  "bill-api-error": "Ha ocurrido un error. Por favor contacte a su mesero",
  "back-to-home": "Regresar a Inicio",
  "view-details": "Ver Detalles",
  "hide-details": "Esconder Detalles",
  "our-picks-for-you": "Nuestras sugerencias para ti",
  customize: "Personalizar",
  "ordered-from": "Ordenado desde",
  "delivered-to": "Entregado a",
  "points-earned": "Puntos ganados",
  "points-redeemed": "Puntos canjeados",
  "charged-to": "Con cargo a",
  "active-orders": "Pedidos Activos",
  "past-orders": "Pedidos Anteriores",
  "no-orders-yet": "Aun no hay Pedidos",
  "view-menu": "Ver Menu",
  "browse-menu": "Consultar el menu e iniciar pedido",
  "confirm-reorder-details": "Para reordenar, ¡confirmemos los detalles de su pedido!",
  "active-orders-loading": "¡Espera! Estamos recuperando sus pedidos activos",
  "past-orders-loading": "¡Espera! Estamos recuperando sus pedidos anteriores",
  "addon-no-thank-you-option": "No gracias. Omitir estas opciones",
  "select-n-items-or-more": "Seleccione [n-items] o más",
  "date-and-time": "Fecha y hora",
  "combo-summary-header": "Resumen Combo",
  "edit-combo-item-arial": "Editar Artículo del Combo [item-name]",
  "setting-favorite-error":
    "Seleccione las opciones a continuación y luego agréguelas a sus favoritos",
  oops: "Ups!",
  "reorder-limited-stock-items":
    "Parece que los siguientes artículos no se pudieron agregar a su carrito debido al stock limitado",
  "reorder-items-no-longer-available":
    "Parece que los siguientes artículos no se pudieron agregar a su carrito porque ya no están disponibles en el menú",
  "reorder-items-out-of-stock-no-longer-available":
    "Parece que los siguientes artículos no se pudieron agregar a tu carrito debido al stock limitado o que ya no están disponibles",
  "add-new-item": "Agregar ítem nuevo",
  customization: "Personalización",
  "remove-all-items-confirmation-modal-message":
    "¿Estás seguro de que quieres eliminar todo [item-name] ?",
  "choice-n-of-total": "Seleccion [current-choice] de [total-choices]",
  "hang-tight-processing-payment": "¡Espera! Estamos procesando tu pago",
  "hang-tight-reviewing-order": "¡Espera! Estamos procesando su pedido",
  "contacting-bank-to-confirm-payment":
    "Nos estamos comunicando con su banco para confirmar su pago",
  "almost-done-passing-order-to-store": "¡Casi terminamos! Estamos enviando tu pedido a la tienda",
  "almost-done": "¡Casi terminamos!",
  "do-not-click-back-or-refresh":
    "No haga clic Atrás, refresque o abandone esta página, ya que esto podría interrumpir el proceso y/o duplicar sus cargos.",
  connecting: "Conectando...",
  "complete-order-pay-with-google-pay":
    "Después de hacer clic en el botón Pagar a continuación, será redirigido a Google Pay para completar su pedido de forma segura.",
  "complete-order-pay-with-apple-pay":
    "Al hacer clic en “Pagar con [apple-pay-icon]”, será redireccionado a Apple Pay para completar su pedido con seguridad.",
  "order-processed-using-digital-payment": "y se procesó con éxito utilizando",
  "google-pay": "Google Pay",
  "apple-pay": "Apple Pay",
  "preparing-bill-for-verification": "Preparar su factura para la verificación...",
  "retrieving-bill-details": "Recuperar su factura...",
  "processing-your-bill": "Procesando la factura...",
  "verifying-bill-details": "Verificar los datos de su factura...",
  "double-checking-your-bill": "Confirmando la factura...",
  "confirming-bill-details": "Confirmando los datos de la factura, ¡Procesando el pago!",
  "processing-your-payment": "Procesamiento del pago...",
  "all-done": "¡Listo!",
  "delivery-address": "Dirección de entrega",
  "delivery-addresses": "Direcciones de entrega",
  "confirm-address": "Confirmar dirección",
  "use-my-location-for-delivery": "Usar mi ubicación para la dirección de entrega",
  "use-my-location-for-pickup": "Usar mi ubicación para encontrar tiendas cercanas",
  "confirm-your-pickup-location": "Confirme su ubicación de recolecta",
  "modify-location": "Modificar ubicación",
  "find-address": "Encuentra la dirección",
  "primary-delivery-address-message":
    "¿Le gustaría guardar la dirección de entrega a continuación en su cuenta?",
  "set-as-primary-delivery-address": "Establecer como dirección de entrega principal",
  "recommended-address-changes": "Revise los cambios recomendados y elija la opción correcta.",
  "save-address": "Guardar dirección",
  "not-now": "Ahora no",
  "your-addresses": "Tus direcciones",
  "no-saved-delivery-address": "Actualmente aún no tienes una dirección de entrega guardada.",
  "you-entered": "Has entrado",
  recommended: "Recomendado:",
  "unable-to-verify-address": "¡No pudimos verificar su dirección!",
  "try-different-address": "Prueba con una dirección diferente",
  "store-filter-list": "Lista",
  "store-filter-map": "Mapa",
  "verification-issues": "Problemas de verificación",
  "street-number-not-found": "Número de calle no encontrado",
  "use-entered-address": "Usar ingresado",
  "make-changes-to-address": "Realizar cambios",
  "street-number-missing": "Le falta el número de la calle",
  "confirm-seating": "Confirmar Asiento",
  "edit-seating": "Editar Asiento",
  "order-pickup": "Recojer Orden",
  "stadium-service-not-available": "Este servicio no está disponible para su sección.",
  "in-seat-service": "Servicio en el asiento",
  "in-seat-service-time-title": "Seleccione el horario de servicio en el asiento",
  "stadium-pickup-time-title": "Seleccione la hora de recogida",
  "limit-per-order": "Limite [qty-limit] por orden",
  "limit-reached": "Límite máximo por pedido alcanzado",
  "limit-reached-past-order":
    "Estos artículos no se pueden agregar a su carrito. Uno o más artículos excederán su límite máximo por pedido",
  "meal-voucher": "Cupon de comida",
  "address-already-exists": "La dirección del cliente ya existe",
  "select-dates": "Seleccionar fechas",
  "select-date-range": "Seleccionar rango de fechas",
  "load-more": "Carga más",
  "select-one-bill": "Seleccionar todo (Pagar como una sola cuenta)",
  "open-calendar": "Calendario abierto",
  "select-pickup-location": "Seleccionar lugar de recogida",
  "select-pickup-time": "Seleccione la hora de recogida",
  "set-store-location-favorite": "Establecer la ubicación de esta tienda como favorita",
  "remove-store-location-favorite": "Eliminar la ubicación de esta tienda como favorita",
  "edit-order-time": "Editar detalles de tiempo del pedido",
  "edit-order-type": "Editar tipo de orden",
  "back-to-payment-method-select": "Volver a la selección del método de pago",
  "pay-with-google-pay": "Pagar con Google Pay",
  "pay-with-apple-pay": "Pagar con Apple Pay",
  "expand-section": "Ampliar sección para",
  "collapse-section": "Contraer sección para",
  "remove-discount": "Eliminar descuento/cupón",
  "item-quantity": "Cantidad de objetos",
  "add-to-apple-wallet": "Añadir a Apple Wallet",
  "add-to-google-pay": "Añadir a Google Pay",
  "expand-order-details": "Ampliar detalles del pedido",
  "collapse-order-details": "Contraer detalles del pedido",
  "remove-promo": "Eliminar promoción",
  "store-hours": "Horario de la tienda",
  "edit-ordering-locaiton": "Editar pedidos desde la ubicación",
  "missing-customer-id-error":
    "Para asegurar el uso de esta tarjeta de crédito guardada, valide su información de inicio de sesión",
  "missing-customer-id-error-gc":
    "Para asegurar el uso de esta tarjeta de regalo guardada, valide su información de inicio de sesión",
  "sorry-it-is-closing-time": "¡Lo sentimos, ya estamos cerrados!",
  "store-closed-message":
    "No podemos procesar tu pedido en este momento porque estamos apunto de cerrar o ya hemos cerrado.",
  "pick-new-time": "Selecciona un nuevo horario.",
  "view-full-menu": "Ver menú completo",
  "recent-order-guest-attempt": "Inicie sesión para ver sus pedidos recientes",
  "invalid-direct-url-error": "[order-type] no está disponible actualmente en esta ubicación.",
  "guest-attempt-favorite-item-dashboard": "Por favor inicia sesión para ver tus favoritas",
  "order-types": "Tipos de órdenes",
  "customer-info-dialog-message":
    "Por favor ingrese los datos del cliente antes de continuar a la página de pago.",
  "input-custom-tip-amount": "Ingrese el monto personalizado de propina en",
  "press-tab-to-add-tip": "Presione la tecla tab para aplicar el monto de la propina.",
  "currently-selected-tip-option": "La opción de propina actualmente seleccionada es",
  "currently-selected-category": "La categoría seleccionada actualmente es",
  "menu-categories-list": "Lista de categorías del menú.",
  "on-combo-summary-page": "Estás en la página de resumen del combo.",
  "on-step": "Estás en el paso",
  "select-item-to-continue": "Seleccione un artículo para continuar.",
  "go-to-combo-summary-page": "Ir a la página de resumen del combo.",
  "go-to-choice": "Ir a la opción",
  "please-select": "Seleccione",
  "please-select-payment-method": "Por favor seleccione un método de pago.",
  "select-date-time-current-selected-date":
    "Por favor seleccione la fecha y hora. La fecha seleccionada actualmente es",
  "currently-selected-date": "Seleccionado actualmente.",
  "select-time-option-for": "Seleccione una opción de horario para",
  "time-option-for": "opción de horario para",
  "your-total-is": "Tu total es",
  "on-menu-item-details-page-for": "En la página de detalles del artículo del menú para",
  "additional-addons-area-expanded-message":
    "Complementos adicionales expandidos. Presione tab para navegar y seleccionar complementos adicionales.",
  "you-have-n-items-in-cart": "Tienes [item-quantity] artículos en el carrito.",
  "quantity-in-cart-is": "La cantidad en el carrito es",
  "remove-from-order": "Eliminar [item-name] del pedido",
  "shopping-cart": "Carro de la compra",
  "decrease-quantity-by-one": "Disminuir la cantidad en 1",
  "increase-quantity-by-one": "Aumentar la cantidad en 1",
  "checkout-cart-summary-message":
    "Estás en la página de pago. Tienes [cart.quantity] artículos en el carrito.",
  "your-order-subtotal-is": "Tu subtotal es",
  "ordering-for-tomorrow-warning-message":
    "¡ADVERTENCIA: su pedido está programado para el próximo día hábil!",
  "ordering-for-tomorrow-info-message": "Esta sucursal ya no está aceptando más pedidos hoy.",
  "current-item-quantity-in-cart": "Cantidad actual [item-quantity] en el carrito",
  "tip-amount-is": "El monto de la propina es [tip-amount]",
  "order-total-is": "El total del pedido es [order-total]",
  "item-addons-modifications": "Complementos o modificadores del artículo",
  "confirm-order-date-time": "Confirme la fecha y hora del pedido",
  "current-quantity": "Cantidad actual [item-quantity]",
};

import React, { useContext } from "react";
import { callAPI } from "../../_common/Api";
import { arrayToObject, getArray, objectToArray, toDollars } from "../../_common/helpers";
import he from "he";
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";

export const updateCustomerFavorite = async (
  skin,
  languageCode,
  loginToken,
  customerId,
  action,
  itemsList,
  FavoriteTypeId = "MENUITEMS",
  outletIds = []
) => {
  /** action can ber 'get' , 'add', or 'update */
  const favData = await callAPI(skin, "vxl_1327", {
    params: [
      languageCode,
      "1327",
      "mqid",
      loginToken,
      customerId,
      action,
      itemsList,
      FavoriteTypeId,
      outletIds,
    ],
    id: "1327",
  });

  if (favData.status === 0) {
    if (action === "add") {
      return { favItemAdded: true, favItems: favData.result.I2 };
    } else {
      return favData.result.I2;
    }
  } else {
    return { favItemAdded: false, error: favData.result.message, errorCode: favData.status };
  }
};

/** returns a list of addonGroup. Filters the input parameter based on if at least there is one selected addon from the group */
export const filterOptionGroupBasedOnSelection = (optionGroups) => {
  let filteredOptionGroup = [];

  if (optionGroups.length > 0) {
    optionGroups.forEach((optionGroup) => {
      if (optionGroup.items) {
        const addons = objectToArray(optionGroup.items);
        if (addons.filter((option) => option.isSelected).length > 0) {
          filteredOptionGroup.push(optionGroup);
        }
      }
    });
  }
  return filteredOptionGroup;
};

/**
 *
 * @param {*} addonGroupsA  - from Favourite item
 * @param {*} addonGroupsB  - from menu item
 * @returns
 */
export const isTheSameAddonSelection = (addonGroupsA, addonGroupsB) => {
  let addonGroupsAIds = []; //for fav item
  let addonGroupsBIds = []; //for menu item
  let addonGroupASelectedItems = []; //for fav item
  let addonGroupBSelectedItems = []; //for menu item

  let addonModifierGroupsAIds = []; // for favItem
  let addonModifierGroupASelectedItems = []; //for fav Item

  let addonModifierGroupsBIds = []; //for menu item
  let addonModifierGroupBSelectedItems = []; //for menu item

  // get the addon groupIds and sort them in separate arrays
  addonGroupsA.forEach((addonGroup) => {
    addonGroupsAIds.push(
      addonGroup.addon_group_id !== "" ? addonGroup.addon_group_id : addonGroup.item_id
    );
    let addonQuantity = addonGroup.quantity || 1;
    for (let i = 0; i < addonQuantity; i++) {
      addonGroupASelectedItems.push(addonGroup.item_id);
    }

    //track the nested modifiers
    if (addonGroup.modifier_list.length > 0) {
      addonGroup.modifier_list.forEach((modifierGroup) => {
        addonModifierGroupsAIds.push(modifierGroup.modifier_group_id);
        addonModifierGroupASelectedItems.push(modifierGroup.item_id);
      });
    }
  });
  addonGroupsAIds.sort((a, b) => a > b);
  addonModifierGroupsAIds.sort((a, b) => a > b);
  //remove duplicate addon group id
  addonGroupsAIds = [...new Set(addonGroupsAIds)];
  addonModifierGroupsAIds = [...new Set(addonModifierGroupsAIds)];

  addonGroupsB.forEach((addonGroup) => {
    addonGroupsBIds.push(addonGroup.id);
    const addons = objectToArray(addonGroup.items);
    addons.forEach((addon) => {
      if (addon.isSelected) {
        let addonQuantity = addon.quantity || 1;
        for (let i = 0; i < addonQuantity; i++) {
          addonGroupBSelectedItems.push(addon.id);
        }

        //track the nested modifiers
        Object.keys(addon.modifierGroups).forEach((modifierGroupKey) => {
          const nestedModifierGroup = addon.modifierGroups[modifierGroupKey];
          const nestedModifierItems = nestedModifierGroup.items;
          Object.keys(nestedModifierItems).forEach((nestedModifierKey) => {
            const nestedModifier = nestedModifierItems[nestedModifierKey];
            if (nestedModifier.isSelected) {
              if (!addonModifierGroupsBIds.includes(nestedModifier.parentId)) {
                addonModifierGroupsBIds.push(nestedModifier.parentId);
              }
              addonModifierGroupBSelectedItems.push(nestedModifier.id);
            }
          });
        });
      }
    });
  });
  addonGroupsBIds.sort((a, b) => a > b);
  addonModifierGroupsBIds.sort((a, b) => a > b);

  //remove duplicate addon group id
  addonGroupsBIds = [...new Set(addonGroupsBIds)];

  if (
    JSON.stringify(addonGroupsAIds) !== JSON.stringify(addonGroupsBIds) ||
    JSON.stringify(addonGroupASelectedItems) !== JSON.stringify(addonGroupBSelectedItems) ||
    JSON.stringify(addonModifierGroupsAIds) !== JSON.stringify(addonModifierGroupsBIds) ||
    JSON.stringify(addonModifierGroupASelectedItems) !==
      JSON.stringify(addonModifierGroupBSelectedItems)
  ) {
    return false;
  } else {
    return true;
  }
};

/**
 *
 * @param {*} modifierGroupA  - from fav item
 * @param {*} modifierGroupB  - from menu item
 * @returns
 */
export const isTheSameModifierSelection = (modifierGroupA, modifierGroupB) => {
  let modifierGroupsAIds = [];
  let modifierGroupsBIds = [];
  let modifierGroupASelectedItems = [];
  let modifierGroupBSelectedItems = [];

  // get the addon groupIds and sort them in separate arrays
  modifierGroupA.forEach((modifierGroup) => {
    modifierGroupsAIds.push(modifierGroup.modifier_group_id);
    modifierGroupASelectedItems.push(modifierGroup.item_id);
  });
  modifierGroupsAIds.sort((a, b) => a > b);
  //remove duplicate addon group id
  modifierGroupsAIds = [...new Set(modifierGroupsAIds)];

  modifierGroupB.forEach((modifierGroup) => {
    modifierGroupsBIds.push(modifierGroup.id);
    const addons = objectToArray(modifierGroup.items);
    addons.forEach((addon) => {
      if (addon.isSelected) {
        modifierGroupBSelectedItems.push(addon.id);
      }
    });
  });
  modifierGroupsBIds.sort((a, b) => a > b);

  if (JSON.stringify(modifierGroupsAIds) !== JSON.stringify(modifierGroupsBIds)) {
    return false;
  }

  if (JSON.stringify(modifierGroupASelectedItems) !== JSON.stringify(modifierGroupBSelectedItems)) {
    return false;
  }

  return true;
};

//Formats the itemList for storing favorite items
export const formatCartForFavoritesApi = (cart, category, subcategory) => {
  let formattedCart = [];
  cart.forEach((item) => {
    //Format addons
    const addonList = [];
    const addonGroupsArray = getArray(item, "addonGroups");
    if (addonGroupsArray.length) {
      addonGroupsArray.forEach(([key, addonGroup]) => {
        const addonItemsArray = getArray(addonGroup, "items");
        if (addonItemsArray.length) {
          addonItemsArray.forEach(([key, addon]) => {
            if (addon.isSelected) {
              let formattedNestedModifierGroups = [];
              const nestedModifierGroups = getArray(addon, "modifierGroups");

              if (nestedModifierGroups.length) {
                nestedModifierGroups.forEach(([key, nestedModifierGroup]) => {
                  const nestedModifiers = getArray(nestedModifierGroup, "items");

                  nestedModifiers.forEach(([key, nestedModifier]) => {
                    if (nestedModifier.isSelected) {
                      const formattedNestedModifier = {
                        item_id: nestedModifier.id,
                        name: he.encode(nestedModifier.name),
                        item_sku_code: "",
                        modifier_list: [],
                        modifier_group_id: nestedModifier.parentId,
                        discount_ref: "",
                        discount_amount: "",
                      };
                      formattedNestedModifierGroups.push(formattedNestedModifier);
                    }
                  });
                });
              }

              const formattedAddon = {
                item_id: addon.id,
                item_sku_code: "",
                modifier_list: formattedNestedModifierGroups,
                discount_ref: "",
                discount_amount: "",
                addon_group_id: addonGroup.isStandalone ? "" : addonGroup.id,
                name: he.encode(addon.name),
                price: addon.price,
                quantity: addon.quantity || 1,
              };

              const addonModGroupsArray = getArray(formattedAddon, "modifierGroups");
              if (addonModGroupsArray.length) {
                addonModGroupsArray.forEach(([key, addonModGroup]) => {
                  const addonModItemsArray = getArray(addonModGroup, "items");
                  if (addonModItemsArray.length) {
                    addonModItemsArray.forEach(([key, addonModItem]) => {
                      if (addonModItem.isSelected) formattedAddon.modifier_list.push(addonModItem);
                    });
                  }
                });
              }
              addonList.push(formattedAddon);
            }
          });
        }
      });
    }
    //END OF Format addons
    //Format modifiers
    const modifierList = [];
    const modifierGroupsArray = getArray(item, "modifierGroups");
    if (modifierGroupsArray.length) {
      modifierGroupsArray.forEach(([key, modifierGroup]) => {
        const modifierItemsArray = getArray(modifierGroup, "items");
        if (modifierItemsArray.length) {
          modifierItemsArray.forEach(([key, modifier]) => {
            if (modifier.isSelected) {
              const formattedModifier = {
                item_id: modifier.id,
                item_sku_code: "",
                name: he.encode(modifier.name),
                modifier_list: [],
                modifier_group_id: modifier.parentId ? modifier.parentId : "",
                discount_ref: "",
                discount_amount: "",
              };
              modifierList.push(formattedModifier);
            }
          });
        }
      });
    }
    //END OF Format modifiers

    //Formate Combo Child Items
    const comboChildItems = [];
    if (item.hasOwnProperty("combo_child_items") && item["combo_child_items"].length > 0) {
      item.combo_child_items.forEach((comboChild) => {
        //Formatting Combo Child Items Addons
        const comboChildAddonList = [];
        const comboChildAddonGroupArray = getArray(comboChild, "addonGroups");
        if (comboChildAddonGroupArray.length) {
          comboChildAddonGroupArray.forEach(([key, addonGroup]) => {
            const comboChildAddonItemsArray = getArray(addonGroup, "items");
            if (comboChildAddonItemsArray.length) {
              comboChildAddonItemsArray.forEach(([key, addon]) => {
                if (addon.isSelected) {
                  let comboChildFormattedNestedModifierGroups = [];
                  const comboChildNestedModifierGroups = getArray(addon, "modifierGroups");
                  if (comboChildNestedModifierGroups.length) {
                    comboChildNestedModifierGroups.forEach(
                      ([key, comboChildNestedModifierGroup]) => {
                        const comboChildNestedModifiers = getArray(
                          comboChildNestedModifierGroup,
                          "items"
                        );

                        comboChildNestedModifiers.forEach(([key, comboChildNestedModifier]) => {
                          if (comboChildNestedModifier.isSelected) {
                            const comboChildFormattedNestedModifier = {
                              item_id: comboChildNestedModifier.id,
                              name: he.encode(comboChildNestedModifier.name),
                              modifier_group_id: comboChildNestedModifier.parentId,
                              item_sku_code: "",
                              modifier_list: [],
                              discount_ref: "",
                              discount_amount: "",
                            };
                            comboChildFormattedNestedModifierGroups.push(
                              comboChildFormattedNestedModifier
                            );
                          }
                        });
                      }
                    );
                  }

                  const comboChildFormattedAddon = {
                    item_id: addon.id,
                    name: he.encode(addon.name),
                    price: addon.price,
                    item_sku_code: "",
                    modifier_list: comboChildFormattedNestedModifierGroups,
                    discount_ref: "",
                    discount_amount: "",
                    addon_group_id: addonGroup.isStandalone ? "" : addonGroup.id,
                    quantity: addon.quantity || 1,
                  };

                  const comboChildAddonModGroupsArray = getArray(
                    comboChildFormattedAddon,
                    "modifierGroups"
                  );
                  if (comboChildAddonModGroupsArray.length) {
                    comboChildAddonModGroupsArray.forEach(([key, comboChildAddonModGroup]) => {
                      const comboChildAddonModItemsArray = getArray(
                        comboChildAddonModGroup,
                        "items"
                      );
                      if (comboChildAddonModItemsArray.length) {
                        comboChildAddonModItemsArray.forEach(([key, comboChildAddonModItem]) => {
                          if (comboChildAddonModItem.isSelected) {
                            comboChildFormattedAddon.modifier_list.push(comboChildAddonModItem);
                          }
                        });
                      }
                    });
                  }
                  comboChildAddonList.push(comboChildFormattedAddon);
                }
              });
            }
          });
        }
        //End of Combo Child Items Addons

        //Formatting Combo Child Items Modifiers
        const comboChildModifierList = [];
        const comboChildModifierGroupsArray = getArray(comboChild, "modifierGroups");
        if (comboChildModifierGroupsArray.length) {
          comboChildModifierGroupsArray.forEach(([key, comboChildModifierGroup]) => {
            const comboChildModifierItemsArray = getArray(comboChildModifierGroup, "items");
            if (comboChildModifierItemsArray.length) {
              comboChildModifierItemsArray.forEach(([key, comboChildModifier]) => {
                if (comboChildModifier.isSelected) {
                  const comboChildFormattedModifier = {
                    item_id: comboChildModifier.id,
                    modifier_group_id: comboChildModifier.parentId
                      ? comboChildModifier.parentId
                      : "",
                    item_sku_code: "",
                    modifier_list: [],
                    discount_ref: "",
                    discount_amount: "",
                    name: he.encode(comboChildModifier.name),
                  };
                  comboChildModifierList.push(comboChildFormattedModifier);
                }
              });
            }
          });
        }
        //End of Combo Child Items Modifiers

        const formattedComboChildItems = {
          item_id: comboChild.id,
          name: he.encode(comboChild.name),
          price: comboChild.price,
          item_sku_code: "",
          addon_list: comboChildAddonList,
          modifier_list: comboChildModifierList,
          cooking_instruction: "",
          combo_child_items: [],
          discount_ref: "",
          discount_amount: "",
          item_components_group_id: comboChild["group_id"],
          quantity: comboChild.quantity || 1,
          groupMinQty: comboChild.groupMinQty || false,
        };

        comboChildItems.push(formattedComboChildItems);
      });
    }
    //END OF Combo Child Items
    formattedCart.push({
      item_id: item.id,
      item_sku_code: "",
      addon_list: addonList,
      modifier_list: modifierList,
      cooking_instruction: item.specialInstructions || "",
      combo_child_items: comboChildItems,
      discount_ref: "",
      discount_amount: "",
      price: item.price,
      calorie_count: item.calorie_count,
      name: he.encode(item.name),
      imageUrl: item.imageUrl,
      category: category,
      subcategory: subcategory,
    });
  });

  return formattedCart;
};

export const listFavItemOptions = (groupArray, displayType, isAddonQuantityEnabled) => {
  const selectedOptions = groupArray;
  const isInline = displayType === "inline";
  const isColumn = displayType === "column";

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  return selectedOptions.map((option, i) => {
    const itemPrice =
      option.price > 0
        ? `${toDollars(
            appSettings["currency-symbol"],
            appSettings["currency-symbol-side"],
            option.price,
            appLanguage
          )}`
        : "";
    const comma = i + 1 < selectedOptions.length ? ", " : "";

    let output = "";

    if (isInline) {
      output = isAddonQuantityEnabled
        ? `${option.quantity}x ${he.decode(option.name)}${itemPrice}${comma}`
        : `${he.decode(option.name)}${itemPrice}${comma}`;
    } else if (isColumn) {
      output = (
        <div className="options-list__row" key={option.id}>
          {/*"- " + option.name + itemPrice*/}
          <div className="favorite-item__item-details-inner-wrapper">
            <div className="favorite-item__text-wrapper">
              <span className="favorite-item__name">
                {isAddonQuantityEnabled && option.quantity ? (
                  <>
                    {option.quantity}x {he.decode(option.name)}
                  </>
                ) : (
                  <>{he.decode(option.name)}</>
                )}
              </span>
            </div>
            <div className="favorite-item__price-container">
              <div className="favorite-item__price">{itemPrice}</div>
            </div>
          </div>

          {option.modifier_list && option.modifier_list.length > 0 && (
            <div className="favorite-item__nested-options-list">
              {option.modifier_list.map((nestedOption) => (
                <>
                  <span className="favorite-item__nested-options">
                    {he.decode(nestedOption.name)}
                  </span>
                </>
              ))}
            </div>
          )}
        </div>
      );
    }

    return output;
  });
};

// Format Food Menu items into objects
export const formatItems = (apiItems) => {
  const formattedItemsArray = [...apiItems];

  formattedItemsArray.map((apiItem) => {
    // Convert addons into objects
    if (!Array.isArray(apiItem.addonGroups)) {
      apiItem.addonGroups = Object.values(apiItem.addonGroups);
    }

    apiItem.addonGroups.map((addonGroup) => {
      if (!Array.isArray(addonGroup.items)) {
        addonGroup.items = Object.values(addonGroup.items);
      }
      addonGroup.items.map((addon) => {
        //convert addon modifiers to objects
        if (addon.modifierGroups && addon.modifierGroups.length > 0) {
          addon.modifierGroups.map((addonMod) => {
            addonMod.items = arrayToObject(addonMod.items, "id", "modifier");
            return addonMod;
          });
          addon.modifierGroups = arrayToObject(addon.modifierGroups, "id", "addonModifierGroup");
        }
        const isStandaloneGroup = !addonGroup.id;
        addon.addon_group_id = isStandaloneGroup ? "" : addon.parentId;
        return addon;
      });

      addonGroup.items = arrayToObject(addonGroup.items, "id", "addon");
      return addonGroup;
    });

    apiItem.addonGroups = arrayToObject(apiItem.addonGroups, "id", "addonGroup");

    if (!Array.isArray(apiItem.modifierGroups)) {
      apiItem.modifierGroups = Object.values(apiItem.modifierGroups);
    }
    // Convert modifiers into objects
    apiItem.modifierGroups.map((modifierGroup) => {
      if (!Array.isArray(modifierGroup.items)) {
        modifierGroup.items = Object.values(modifierGroup.items);
      }
      modifierGroup.items = arrayToObject(modifierGroup.items, "id", "modifier");
      return modifierGroup;
    });

    apiItem.modifierGroups = arrayToObject(apiItem.modifierGroups, "id", "modifierGroup");
    return apiItem;
  });

  return formattedItemsArray;
};
// END OF Format Food Menu items into objects

/**
 * Compares and item from the favourites and it's selection to an item from the menu, mostly used for quantity combos which have two instances of the same item with different customization
 * @param {Object} favItem , item from the favourites
 * @param {Object} menuItem , item from the menu
 * @param {String} type , type of option group: addon/modifier
 * @returns {Boolean} true or false
 */
export const hasTheSameOptionSelections = (favItem, menuItem, type) => {
  let hasSameSelection = true;
  if (type === "addon") {
    for (let i = 0; i < favItem.addon_list.length; i++) {
      let addonItem = favItem.addon_list[i];
      if (
        addonItem.addon_group_id &&
        menuItem.addonGroups[`addonGroup-${addonItem.addon_group_id}`]
      ) {
        if (
          menuItem.addonGroups[`addonGroup-${addonItem.addon_group_id}`] &&
          menuItem.addonGroups[`addonGroup-${addonItem.addon_group_id}`].items[
            `addon-${addonItem.item_id}`
          ] &&
          !menuItem.addonGroups[`addonGroup-${addonItem.addon_group_id}`].items[
            `addon-${addonItem.item_id}`
          ].isSelected
        ) {
          hasSameSelection = false;
          break;
        }
      } else if (addonItem.addon_group_id === "") {
        //single addon item
        if (
          menuItem.addonGroups[`addonGroup-${addonItem.item_id}`] &&
          menuItem.addonGroups[`addonGroup-${addonItem.item_id}`].items[
            `addon-${addonItem.item_id}`
          ] &&
          !menuItem.addonGroups[`addonGroup-${addonItem.item_id}`].items[
            `addon-${addonItem.item_id}`
          ].isSelected
        ) {
          hasSameSelection = false;
          break;
        }
      }
    }
    Object.keys(menuItem.addonGroups).forEach((addonGroupKey) => {
      const addonGroupItems = menuItem.addonGroups[addonGroupKey].items;
      const isStandalone = !menuItem.addonGroups[addonGroupKey].id;
      Object.keys(addonGroupItems).forEach((addonKey) => {
        if (addonGroupItems[addonKey].isSelected) {
          if (
            favItem.addon_list.filter(
              (addon) =>
                addon.item_id === addonGroupItems[addonKey].id &&
                ((addon.addon_group_id === addonGroupItems[addonKey].parentId && !isStandalone) ||
                  isStandalone)
            ).length === 0
          ) {
            hasSameSelection = false;
          }
        }
      });
    });
  } else if (type === "modifier") {
    for (let i = 0; i < favItem.modifier_list.length; i++) {
      let modifierItem = favItem.modifier_list[i];
      if (
        menuItem.modifierGroups[`modifierGroup-${modifierItem.modifier_group_id}`] &&
        !menuItem.modifierGroups[`modifierGroup-${modifierItem.modifier_group_id}`].items[
          `modifier-${modifierItem.item_id}`
        ].isSelected
      ) {
        hasSameSelection = false;
        break;
      }
    }

    Object.keys(menuItem.modifierGroups).forEach((modifierGroupKey) => {
      const modifierGroupItems = menuItem.modifierGroups[modifierGroupKey].items;
      Object.keys(modifierGroupItems).forEach((modifierKey) => {
        if (modifierGroupItems[modifierKey].isSelected) {
          if (
            favItem.modifier_list.filter(
              (modifier) =>
                modifier.item_id === modifierGroupItems[modifierKey].id &&
                modifier.modifier_group_id === modifierGroupItems[modifierKey].parentId
            ).length === 0
          ) {
            hasSameSelection = false;
          }
        }
      });
    });
  }

  return hasSameSelection;
};

/**
 *
 * @param {Array} allFavItems - list of all favorites items from orderMenu Context
 * @param {Object} item - item to check if it is includes in the allFavItems list
 * @returns {Boolean} true /false
 */
export const isFavouriteItem = (allFavItems, item) => {
  let itemIsAlreadyAFavorite = false;
  if (allFavItems.length === 0) {
    return false;
  }
  for (let i = 0; i < allFavItems.length; i++) {
    const favItem = allFavItems[i];
    const favItemAddonGroups = favItem.addon_list;
    const itemAddonGroups = filterOptionGroupBasedOnSelection(objectToArray(item.addonGroups));

    const favItemModifierGroups = favItem.modifier_list;
    const itemModifierGroups = filterOptionGroupBasedOnSelection(
      objectToArray(item.modifierGroups)
    );

    if (
      isTheSameAddonSelection(favItemAddonGroups, itemAddonGroups) &&
      isTheSameModifierSelection(favItemModifierGroups, itemModifierGroups)
    ) {
      if (!item.combo_child_items || item.combo_child_items.length === 0) {
        //if it's a regular item, break out of the loop
        itemIsAlreadyAFavorite = true;
        break;
      } else {
        //it's a combo item
        //check the combo child items
        let comboChildrenMatch = true;
        for (let i = 0; i < item.combo_child_items.length; i++) {
          const favChildItem = favItem.combo_child_items[i];
          const childItem = item.combo_child_items[i];

          if (favChildItem && childItem.id === favChildItem.item_id) {
            const favChildAddonGroups = favChildItem.addon_list;
            const childItemAddonGroups = filterOptionGroupBasedOnSelection(
              objectToArray(childItem.addonGroups)
            );

            const favChildModifierGroups = favChildItem.modifier_list;
            const childItemModifierGroups = filterOptionGroupBasedOnSelection(
              objectToArray(childItem.modifierGroups)
            );

            if (
              !isTheSameAddonSelection(favChildAddonGroups, childItemAddonGroups) ||
              !isTheSameModifierSelection(favChildModifierGroups, childItemModifierGroups)
            ) {
              comboChildrenMatch = false;
            }
          } else {
            //one child item odes not match
            comboChildrenMatch = false;
            break;
          }
        }

        if (comboChildrenMatch) {
          itemIsAlreadyAFavorite = true;
          break;
        } else {
          itemIsAlreadyAFavorite = false;
        }
      }
    }
  }

  return itemIsAlreadyAFavorite;
};

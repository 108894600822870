export const giftCardLabels = {
  gift: "Gift",
  "gift-cards": "Gift Cards",
  "order-history": "Order History",
  "see-all": "See All",
  amount: "Amount",
  "recipient-email": "Recipient Email",
  "recipient-name": "Recipient Name",
  "confirm-recipient-email": "Confirm Recipient Email",
  "delivery-date-time": "Delivery Date & Time",
  "greeting-message": "Greeting Message",
  "sender-name": "Sender Name",
  "add-more-people": "Add more people",
  "clear-form": "Clear Form",
  save: "Save",
  "select-gift-card": "Select gift card",
  "exit-gift-card-dialog-message":
    "Leaving this page will result in the loss of your gift card order.",
  "sure-text": "Are you sure?",
  "gift-card-recent-order-signed-out-message":
    "Browse gift cards to start an order or sign in to see your transaction history.",
  "add-discount-coupon": "Add Discount/Coupon",
  minimum: "Minimum",
  maximum: "Maximum",
  "invalid-delivery-date-time-gift-card-title-message": "Oops..! Invalid delivery date/time",
  "invalid-delivery-date-time-gift-card-message":
    "One or more of your gift cards has an invalid delivery date or time. Please confirm the next available delivery date/time to proceed.",
  "exceeded-gift-card-amount-message":
    "The total amount of gift card(s) in cart is greater than the allowed amount of",
  "exceeded-gift-card-amount-remove-message":
    "Please remove a gift card or change the gift card amount.",
  "atleast-one-recipient-button": "Please add details for at least 1 recipient",
  "submit-recipient-error": "One or more recipient details are invalid or missing",
  "one-valid-recipient-missing-error": "You need at least one valid recipient for a gift card",
  "gift-card-image-unavailable": "Gift Card Image Unavailable",
  "view-gift-cards-button": "View Gift Cards",
  "send-to-more-people": "Send To More People",
  "for-delivery-on": "for delivery on",
  to: "to",
  at: "at",
  continue: "continue",
  "confirmation-email-sent": "We have sent you a confirmation email",
  "gift-card-delivery-on": "E-Gift Card for delivery on",
  one: "1",
  "go-to-gc-cart": "Go to gift card cart",
  "no-gift-cards-available": "No Gift Cards are available at this Time",
  "gift-card-value-text": "Gift Card Value",
  "gift-card-nav-label": "Gift Card Order History",
  "and-text": "and",
  "custom-amount": "Custom Amount",
  "minimum-one-recipient": "Minimum 1 Recipient Required",
  "promo-code": "Promo Code",
  "promo-apply": "Apply",
  "send-a-gift-card": "Send A Gift Card",
  "denomination-error-heading": "Oops... Looks like something went wrong",
  "denomination-error-text": "We are clearing your order, please try again.",
  "recipient": "Recipient",
  "delivery": "Delivery",
  "message": "Message",
  "invalid-promo-code": "Invalid Promo Code",
  "remove-applied-promo-codes": "Remove Applied Promo Codes",
  "order-limit-reached": "Your Order Exceeds The Limit",
  "item-order-limit": "Order Limit Of",
  "reached": "Reached",
  "promo": "Promo"
};

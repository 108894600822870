import { createEl, getEl } from "./_common/helpers";
import localforage from "localforage";
export const generateAppUpdateModal = (registration) => {
  /** when there is a new app version, clear all the data in localForage */
  localforage
    .clear()
    .then(function () {
      // Run this code once the database has been entirely deleted.
      console.log("Database is now empty.");

      console.log("----Setting new service worker session storage----");
      sessionStorage.setItem("newServiceWorker", "true");

      if (!getEl(".new-update-modal")) {
        let appLanguage = "en";
        const acceptableLanguageCodes = ["fr", "pt", "es"];
        const isLocalhost = process.env.NODE_ENV === "development";

        /** check the language code from url */
        let languageCode = "";
        languageCode = window.location.href.split("/")[isLocalhost ? 3 : 5];
        if (languageCode !== "#" && acceptableLanguageCodes.includes(languageCode)) {
          appLanguage = languageCode;
        }

        let labelsPath = "";
        if (appLanguage && appLanguage !== "en") {
          labelsPath = appLanguage + "/";
        }

        const generalLabels =
          require(`./App/labels/${labelsPath}default-general-labels.js`).generalLabels;

        const container = createEl("div");
        container.className = "new-update-modal-container";

        const modal = createEl("div");
        modal.className = "new-update-modal";

        const message = createEl("p");
        message.textContent = generalLabels["new-version-message"];
        message.className = "new-update-modal__message";

        const buttonWrapper = createEl("div");
        buttonWrapper.className = "new-update-modal__buttons-wrapper";

        const confirmButton = createEl("button");
        confirmButton.type = "button";
        confirmButton.textContent = generalLabels["dialog-modal-confirm"];

        confirmButton.classList.add("button", "button--primary", "new-update-modal__submit-button");

        modal.appendChild(message);

        buttonWrapper.appendChild(confirmButton);
        modal.appendChild(buttonWrapper);

        container.appendChild(modal);
        document.body.appendChild(container);

        getEl(".new-update-modal__submit-button").addEventListener("click", () => {
          if (registration.waiting) {
            registration.waiting.postMessage("skipWaiting");

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            container.parentElement.removeChild(container);

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        });
      }
    })
    .catch(function (err) {
      // This code runs if there were any errors
      console.log(err);
    });
};

// used to convert Gift Cart Context value items and converts it into an object with format valid for API 1013 and dc_placeorder calls.

import { objectToArray } from "../_common/helpers";

export const castToApiObject = (gc, isDeliveryTimeEnabled = false) => {
  let ecertOrderInfo = {};
  let orderDetailInfo = {};
  let greetingMessage = {}

  greetingMessage.entryGreetingTo = gc.recipientName
  greetingMessage.entryGreetingFrom = gc.senderName
  greetingMessage.entryGreetingBody = gc.message
  greetingMessage = objectToArray(greetingMessage)

  let tempGreetingMessage = []
  tempGreetingMessage.push(greetingMessage)
  greetingMessage = tempGreetingMessage

  orderDetailInfo.entryOrderRef = ""
  orderDetailInfo.itemRef = gc.giftCard.reference
  orderDetailInfo.itemQuantity = 1
  orderDetailInfo.itemDenom = Number(gc.amount)
  orderDetailInfo.entryGreetingMessage = greetingMessage
  orderDetailInfo = objectToArray(orderDetailInfo)

  let tempOrderDetailInfo = []
  tempOrderDetailInfo.push(orderDetailInfo)
  orderDetailInfo = tempOrderDetailInfo

  ecertOrderInfo.shippingOrderRef = "E-commerce App Order"
  ecertOrderInfo.receivingName = gc.recipientName
  ecertOrderInfo.receivingEmail = gc.recipientEmail
  ecertOrderInfo.expiryDate = ""
  ecertOrderInfo.orderDetailInfo = orderDetailInfo
  ecertOrderInfo.ecertDeliveryDate = formatDateObject(gc.deliveryDateTime)
  ecertOrderInfo.ecertDeliveryMethod = "email"
  ecertOrderInfo.receivingMobile = ""
  let deliveryTime = isDeliveryTimeEnabled ? formatDateObject(gc.deliveryDateTime, true).time : ""
  if (deliveryTime === "00:00") {
    deliveryTime = "24:00"
  }
  ecertOrderInfo.ecertDeliveryTime = deliveryTime

  return objectToArray(ecertOrderInfo)
}

/*
Given a dateTime object it will return date or time (if getTime is true) in the format YYYY-MM-DD or YYYY-MM-DD HH:MM
*/
export const formatDateObject = (dateTime, getTime = false) => {
  let value = new Date(dateTime)

  let month = Number(value.getMonth() + 1).toString().padStart(2, '0')
  let date = value.getDate().toString().padStart(2, '0')

  let year = value.getFullYear()
  if (!getTime) {
    return year + "-" + month + "-" + date;
  }
  else {
    let hour = value.getHours().toString().padStart(2, '0')
    let minutes = value.getMinutes().toString().padStart(2, '0')

    return { date: year + "-" + month + "-" + date, time: hour + ":" + minutes }
  }
}

// returns time in format of HH AM, example 12 AM, 2 PM for confirmation page
export const formatTimeForConfirmationPage = (datetime) => {
  let formdattedDate = formatDateObject(datetime)
  let value = new Date(datetime)
  let hour = value.getHours()
  let ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12;
  if (hour === 0) {
    hour = 12
  }
  return { formdattedDate, time: hour + " " + ampm }

}

/*
Given a dateTime object it will return date and time  in the format YYYY-MM-DD or YYYY-MM-DD HH:MM 
for showing values in DateTimeInputCombo component.
*/
export const formatDateTimeObject = (dateTime, onlyDateDislay = false) => {
  let value = new Date(dateTime)
  let day = value.getDate().toString().padStart(2, '0')
  let month = Number(value.getMonth() + 1).toString().padStart(2, '0')
  let year = value.getFullYear()
  let minutes = value.getMinutes().toString().padStart(2, '0')
  let hour = value.getHours().toString().padStart(2, '0')
  if (onlyDateDislay) {
    return { "displayValue": month + "/" + day + "/" + year, timestamp: value }
  }
  else {
    return { "displayValue": month + "/" + day + "/" + year + " " + hour + ":" + minutes + " ", timestamp: value }
  }
}

/*
Checks delivery date and time for gift card cart items.
Returns invalidIndexes and true/false if any datetime value is less than current timestamp
*/
export const checkDeliveryDateTime = (gcCartItems, isDateOnly = false) => {
  let today = new Date()
  let pastDeliveryDateTime = false
  let invalidItemIndexes = []
  gcCartItems.forEach((item, index) => {
    let date = new Date(item.deliveryDateTime)
    if (date < today && !isDateOnly) {

      invalidItemIndexes.push(index)
      pastDeliveryDateTime = true
    } else if (isDateOnly) {
      today.setHours(23)
      today.setMinutes(59)
      today.setSeconds(59)

      date.setHours(23)
      date.setMinutes(59)
      date.setSeconds(59)

      date.setMilliseconds(0)
      today.setMilliseconds(0)

      if (date.getTime() < today.getTime()) {
        invalidItemIndexes.push(index)
        pastDeliveryDateTime = true
      } 
    }
  })

  return { invalidItemIndexes, pastDeliveryDateTime }
}

/*
Calculates next delivery date time for sending a gift card.
It is calculated to the nearest next hour.
Example: System time is 10:20, next available time would
be 11:00
*/
export const nextDeliveryDateTime = (isForDialogMessage = false, isTimePickerEnabled = true, atLabel) => {
  let currentTime = new Date()
  let newTime = new Date(currentTime.getTime() + 60 * 60000)
  newTime.setMinutes(0)

  let day = newTime.getDate().toString().padStart(2, '0')
  let month = Number(newTime.getMonth()) + 1
  month = month.toString().toString().padStart(2, '0')
  let year = newTime.getFullYear()
  let hour = newTime.getHours().toString().padStart(2, '0')

  let minutes = "00"
  if (isForDialogMessage) {
    if (isTimePickerEnabled) {
      return { "displayValue": year + "/" + month + "/" + day + " " + atLabel + " " + hour + ":" + minutes + " ", timestamp: newTime }
    }
    else {
      let newTime = new Date()
      let day = newTime.getDate().toString().padStart(2, '0')
      let month = Number(newTime.getMonth()) + 1
      month = month.toString().toString().padStart(2, '0')
      newTime.setHours(23)
      newTime.setMinutes(59)
      newTime.setSeconds(59)
      return { "displayValue": newTime.getFullYear() + "/" + month + "/" + day, timestamp: newTime }
    }
  } else {
    if (isTimePickerEnabled) {
      return { "displayValue": year + "-" + month + "-" + day + " " + hour + ":" + minutes + " ", timestamp: newTime }
    }
    else {
      let newTime = new Date()
      let day = newTime.getDate().toString().padStart(2, '0')
      let month = Number(newTime.getMonth()) + 1
      month = month.toString().toString().padStart(2, '0')
      newTime.setHours(23)
      newTime.setMinutes(59)
      newTime.setSeconds(59)
      return { "displayValue": newTime.getFullYear() + "/" + month + "/" + day, timestamp: newTime }
    }
  }
}

/*
Formatting date and time for recent gift card orders
Example return value: Tue Jul 23, 2024 at 10:38
*/
export const formatRecentOrderDateAndTime = (orderDateString, orderTimeString, appLanguage = "en") => {
  let dateTimeString = orderDateString + " " + orderTimeString
  let dateObject = new Date(dateTimeString)
  let year = dateObject.getFullYear()
  let day = dateObject.toLocaleDateString(appLanguage, { weekday: 'short' });
  let date = dateObject.getDate()
  let month = dateObject.toLocaleDateString(appLanguage, { month: 'short' });
  let hour = dateObject.getHours().toString().padStart(2, '0')
  let minutes = dateObject.getMinutes().toString().padStart(2, '0')
  return { date: day + " " + month + " " + date + ", " + year, time: hour + ":" + minutes }
};

/*
For given gift card cart items calculates the raw total i.e sum of all theri amount values
Should always return value of type Number
*/
export const getGiftCardCartSubTotal = (giftCartItems) => {
  let subTotal = 0
  giftCartItems.forEach((gc) => {
    subTotal = subTotal + Number(gc.amount)
  })
  return subTotal
}
import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";

import { getTimeAmPm } from "../../Dashboard/DashboardOrder/dateHelpers";
import { getStore1306Config } from "../../App/apiHelpers/getStore1306Config";

import AppLabelsContext from "../../App/AppLabelsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";
import StoreContext from "../../OnlineOrdering/StoreContext";
import LocationsContext from "../../OnlineOrdering/Locations/LocationsContext";
import OrderTimeContext from "../../OnlineOrdering/OrderTimeContext";
import { MenuContext } from "../../OnlineOrdering/Menu/MenuContext";
import CartContext from "../../OnlineOrdering/Cart/CartContext";
import AppLanguageContext from "../../App/AppLanguageContext";

import { useModal } from "../../_common/Modal/useModal";
import { Modal } from "../../_common/Modal/Modal";

import { DashboardSelect } from "./DashboardSelect";

import { ReactComponent as IconPaperBag } from "../../_common/icons/IconPaperBag.svg";
import { ReactComponent as IconMapPin } from "../../_common/icons/IconMapPin.svg";

const getOrderTypeInOptionFormat = (orderTypeValue, orderLabels) => {
  const option = {};

  switch (orderTypeValue) {
    case "pickup":
      option.value = "pickup";
      option.displayValue = orderLabels["pickup"];
      break;
    case "delivery":
      option.value = "delivery";
      option.displayValue = orderLabels["delivery"];
      break;
    case "dinein":
      option.value = "dinein";
      option.displayValue = orderLabels["dine-in"];
      break;
    default:
      return;
  }

  return option;
};

export const OrderTypeAndStoreDropdowns = () => {
  const { skin, vexilor } = useContext(MerchantConfigContext);

  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const orderType = useContext(OrderTypeContext);
  const orderStore = useContext(StoreContext);
  const allOrderStores = useContext(LocationsContext);
  const orderTime = useContext(OrderTimeContext);
  const orderMenu = useContext(MenuContext);
  const orderCart = useContext(CartContext);

  const [activeOrderTypeOption, setActiveOrderTypeOption] = useState(null);
  useEffect(() => {
    if (orderType.value) {
      setActiveOrderTypeOption(getOrderTypeInOptionFormat(orderType.value, appLabels["order"]));
    }
  }, [orderType.value]);

  // Generate options based on merchant-level order types
  const [orderTypeOptions, setOrderTypeOptions] = useState(null);
  useEffect(() => {
    const options = [];
    const merchantOrderTypes = vexilor.I2.active_order_types.split(",");

    merchantOrderTypes.forEach((merchantOrderType) => {
      const option = getOrderTypeInOptionFormat(merchantOrderType, appLabels["order"]);
      options.push(option);
    });

    setOrderTypeOptions(options);
  }, []);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const { toggleModal, closeAnimation, isModal } = useModal();

  const handleOrderTypeChangeAttempt = (newOrderType) => {
    const selectedOption = orderTypeOptions.find((option) => option.value === newOrderType);
    const isCart = orderCart && orderCart.quantity > 0;

    localforage.getItem(skin + "__activeOrderType").then((storedOrderType) => {
      const orderTypeChanged = storedOrderType && storedOrderType !== selectedOption.value;

      if (isCart && orderTypeChanged) {
        const confirmOrderTypeChangeMessage = appLabels["order"]["order-type-change-confirmation"];

        setConfirmationModal({
          content: (
            <div className="confirm-order-type__container">
              <p>
                {confirmOrderTypeChangeMessage.split("[order-type]")[0]}
                <strong>{selectedOption.displayValue.toUpperCase()}</strong>
                {confirmOrderTypeChangeMessage.split("[order-type]")[1]}
              </p>
              <p>{appLabels["order"]["your-current-order-will-be-cleared"]}</p>
            </div>
          ),
          onConfirm: () => updateOrderType(selectedOption, orderTypeChanged),
        });

        toggleModal();
        //
      } else {
        updateOrderType(selectedOption, orderTypeChanged);

        if (
          selectedOption.value === "dinein" &&
          orderStore.activeOrderStore &&
          !orderStore.activeOrderStore["__isUnavailable"]
        ) {
          const storeConfig = orderStore.activeOrderStore.vexilorConfig;
          const minInterval = Number(storeConfig.pickup_min_interval);

          const orderTimeDate = new Date();
          orderTimeDate.setTime(orderTimeDate.getTime() + minInterval * 60000);

          orderTime.update({
            value: new Date(orderTimeDate),
            displayValue: getTimeAmPm(orderTimeDate),
          });
        }
      }
    });
  };

  const updateOrderType = (selectedOption, orderTypeChanged) => {
    clearOrderStorage(orderTypeChanged);

    orderType.update(selectedOption.value);
    orderStore.updateActiveOrderStoreTable(null);

    resetMenu();
    orderCart.updateCart(null);
  };
  // end of: order type logic

  // Order Store
  useEffect(() => {
    if (allOrderStores && activeOrderTypeOption) generateOrderStoreOptions();
  }, [allOrderStores, activeOrderTypeOption]);

  const [orderStoreOptions, setOrderStoreOptions] = useState(false);
  const [activeOrderStoreOption, setActiveOrderStoreOption] = useState(false);

  /*
    1. Generate order store options
    2. Set the active order store option
  */
  const generateOrderStoreOptions = () => {
    let options = [];

    allOrderStores.forEach((store) => {
      options.push({
        value: store.storeId,
        displayValue: store.name,
      });
    });

    options = sortByKey(options, "displayValue");

    options.splice(0, 0, {
      value: "",
      displayValue: appLabels["order"]["select-store"],
      disabled: true,
    });

    setOrderStoreOptions(options);

    const activeStoreContext = orderStore.activeOrderStore;

    if (activeStoreContext) {
      const activeOption = options.find((option) => option.value === activeStoreContext.storeId);

      if (activeOption) {
        setActiveOrderStoreOption(activeOption);
        orderStore.updateActiveOrderStore(activeStoreContext);
      } else {
        let foundStore;

        if (!!options[0].value) {
          setActiveOrderStoreOption(options[0]);
          foundStore = allOrderStores.find((store) => store.storeId === options[0].value);
          orderStore.updateActiveOrderStore(foundStore);
        } else if (!!options[1] && !!options[1].value) {
          setActiveOrderStoreOption(options[1]);
          foundStore = allOrderStores.find((store) => store.storeId === options[1].value);
          orderStore.updateActiveOrderStore(foundStore);
        }
      }
    } else {
      //if there is only one location and "select location" in the drop down, select the one location
      if (options.length === 2) {
        setActiveOrderStoreOption(options[1]);
        const selectedStore = allOrderStores.find((store) => store.storeId === options[1].value);
        orderStore.updateActiveOrderStore(selectedStore);
      } else {
        setActiveOrderStoreOption(options[0]);
      }
    }
  };

  const handleOrderStoreChangeAttempt = (newOrderStore) => {
    /**
     * If the about to be selected store is unavailable for any reason (including API errors)
     * reset the currently active order time so the order settings modal can be triggered
     */
    for (let i = 0; i < allOrderStores.length; i++) {
      if (allOrderStores[i].storeId === newOrderStore) {
        orderTime.update({
          value: null,
          displayValue: "",
        });
        break;
      }
    }

    localforage.removeItem(skin + "__orderDate");
    localforage.removeItem(skin + "__orderTime");

    const selectedOption = orderStoreOptions.find((option) => option.value === newOrderStore);
    const isCart = orderCart && orderCart.quantity > 0;
    const selectedStoreId = selectedOption.value;

    localforage.getItem(skin + "__activeOrderStore").then((activeOrderStore) => {
      const storeGotChanged = activeOrderStore && activeOrderStore.storeId !== selectedStoreId;

      if (isCart && storeGotChanged) {
        setConfirmationModal({
          content: (
            <div className="confirm-order-type__container">
              <p>
                {appLabels["order"]["order-store-change-confirmation"].split("[order-store]")[0]}
                <strong style={{ textTransform: `capitalize` }}>
                  {selectedOption.displayValue.toLowerCase()}
                </strong>
                {appLabels["order"]["order-store-change-confirmation"].split("[order-store]")[1]}
              </p>
              <p>{appLabels["order"]["your-current-order-will-be-cleared"]}</p>
            </div>
          ),
          onConfirm: () => updateOrderStore(selectedOption, skin, storeGotChanged),
        });

        toggleModal();
        //
      } else {
        // if the store got changed and the active order type was set to dinein, update the order time based on the interval
        if (activeOrderTypeOption.value === "dinein") {
          (async () => {
            let storeConfig;
            if (
              orderStore.activeOrderStore &&
              !orderStore.activeOrderStore["__isUnavailable"] &&
              orderStore.activeOrderStore.vexilorConfig
            ) {
              storeConfig = orderStore.activeOrderStore.vexilorConfig;
            } else {
              const storeCopy = { ...orderStore.activeOrderStore };
              const store1306Data = await getStore1306Config(skin, appLanguage, storeCopy);
              if (store1306Data) {
                storeConfig = store1306Data.vexilorConfig;
              }
            }

            if (storeConfig) {
              const minInterval = Number(storeConfig.pickup_min_interval);

              const orderTimeDate = new Date();
              orderTimeDate.setTime(orderTimeDate.getTime() + minInterval * 60000);
              updateOrderStore(selectedOption, skin, storeGotChanged);
              setTimeout(() => {
                orderTime.update({
                  value: new Date(orderTimeDate),
                  displayValue: getTimeAmPm(orderTimeDate),
                });
              }, 500);
            }
          })();
        } else {
          updateOrderStore(selectedOption, skin, storeGotChanged);
        }
      }
    });
  };

  const updateOrderStore = async (selectedOption, skin, storeGotChanged) => {
    clearOrderStorage(storeGotChanged);
    setActiveOrderStoreOption(selectedOption);

    const selectedStore = allOrderStores.find((store) => store.storeId === selectedOption.value);
    orderStore.updateActiveOrderStore(selectedStore);

    resetMenu();
    orderCart.updateCart(null);
  };
  // end of: order store logic

  // On Order Type or Order Store change - reset order storage
  const clearOrderStorage = (storeOrOrderTypeGotChanged) => {
    localforage.removeItem(skin + "__activeOrderType");
    localforage.removeItem(skin + "__orderDate");
    localforage.removeItem(skin + "__orderTime");

    localforage.removeItem(skin + "__storedMenu");

    localforage.removeItem(skin + "__storedPromo");
    localforage.removeItem(skin + "__storedRewards");
    localforage.removeItem(skin + "__storedRewardsDetails");
    localforage.removeItem(skin + "__activeOrderStoreTable");

    localforage.removeItem(skin + "__storedTip");

    localforage.removeItem(skin + "__storedPromoCode");

    if (storeOrOrderTypeGotChanged) {
      localforage.removeItem(skin + "__userDeliveryAddress");
      localforage.removeItem(skin + "__cart");
    }
  };

  const resetMenu = () => {
    orderMenu.updateMenu({
      ...orderMenu,
      apiData: null,
      activeTopCategory: null,
      activeSubcategory: null,
      activeItem: null,
      menuLevel: null,
    });
  };
  // end of: order storage reset

  const sortByKey = (array, key) => {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  // Check if dropdowns are ready to be rendered
  const [areDropdownsReady, setAreDropdownsReady] = useState(false);
  useEffect(() => {
    if (activeOrderTypeOption && activeOrderStoreOption) setAreDropdownsReady(true);
  }, [activeOrderTypeOption, activeOrderStoreOption]);

  useEffect(() => {
    if (orderStore && orderStore.isGeolocationGranted) {
      setActiveOrderStoreOption({
        value: orderStore.activeOrderStore.storeId,
        displayValue: orderStore.activeOrderStore.name,
      });
    }
  }, [orderStore]);

  return (
    <>
      {areDropdownsReady && (
        <>
          <div className="dashboard__selects-wrapper">
            <DashboardSelect
              options={orderTypeOptions}
              activeOption={activeOrderTypeOption}
              handleChangeAttempt={handleOrderTypeChangeAttempt}
              id="dashboard__select-order-type"
              label="Select an order type"
              name="order types"
              icon={<IconPaperBag />}
            />
            <DashboardSelect
              options={orderStoreOptions}
              activeOption={activeOrderStoreOption}
              handleChangeAttempt={handleOrderStoreChangeAttempt}
              id="dashboard__select-store"
              label="Select an order store"
              name="stores"
              icon={<IconMapPin />}
            />
          </div>
          {orderStore.activeOrderStore && orderStore.activeOrderStore["__isUnavailable"] && (
            <p
              style={{
                margin: `1em 0 0`,
                fontSize: "0.8em",
                textAlign: "center",
                color: "#ff2020",
              }}
            >
              {appLabels["order"]["no-future-dates"]}
            </p>
          )}
        </>
      )}
      <Modal
        type="default"
        content={confirmationModal.content}
        onSubmit={confirmationModal.onConfirm}
        isSubmitButton={true}
        isCancelButton={true}
        fabButtonText={appLabels["general"]["dialog-modal-confirm"]}
        description="Confirm order type change"
        isModal={isModal}
        toggleModal={toggleModal}
        closeAnimation={closeAnimation}
        wrapperClass="modal-wrapper--select-order-store"
      />
    </>
  );
};

import React, { useState, useContext } from "react";

//Hooks
import { useHistory } from "react-router-dom";
import { useHideOrderModule } from "../App/useHideOrderModule";
import useWindowSize from "../_common/hooks/useWindowSize";

//Context
import AppLabelsContext from "../App/AppLabelsContext";
import OrderContext from "../App/OrderContext";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";
import StoreContext from "../OnlineOrdering/StoreContext";
import OrderTimeContext from "../OnlineOrdering/OrderTimeContext";
import UserRoleContext from "../App/UserRoleContext";
import BrandingContext from "../App/BrandingContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";

//UI Components
import { DashboardActionButton } from "./DashboardButtons/DashboardActionButton";
import { DashboardRewardButton } from "./DashboardButtons/DashboardRewardButton";
import { DialogModal } from "../_common/DialogModal/DialogModal";
import { LoadingSpinner } from "../_common/LoadingSpinner";
import { DashboardRewards } from "./DashboardRewards/DashboardRewards";

//Assets
import { ReactComponent as IconUtensils } from "../_common/icons/IconUtensils.svg";
import { ReactComponent as IconHeart } from "../_common/icons/IconHeart.svg";
import { ReactComponent as IconDocumentPen } from "../_common/icons/IconDocumentPen.svg";
import { ReactComponent as IconEGiftCard } from "../_common/icons/IconEGiftCard.svg";

//Helpers
import { getContrastColor } from "../_common/colorHelpers";

//CSS
import "./DashboardGiftCardButtons.css";

export const DashboardActionButtons = (props) => {
  const { rewardContainerTopPosition } = props;
  const { hideOrderModule } = useHideOrderModule();

  const history = useHistory();
  const appLabels = useContext(AppLabelsContext);
  const orderContext = useContext(OrderContext);
  const orderType = useContext(OrderTypeContext).value;
  const storeContext = useContext(StoreContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const userRoleContext = useContext(UserRoleContext);
  const branding = useContext(BrandingContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);

  const skin = merchantConfigContext.skin;
  const merchantVexilorConfig = merchantConfigContext.vexilor.I2;
  const activeOrderStore = storeContext.activeOrderStore;
  const isOrderingEnabled = cws5ModuleContext.isOnlineOrderingEnabled;
  const isLoyaltyEnabled = cws5ModuleContext.isLoyaltyEnabled;
  const isGiftCardEnabled = cws5ModuleContext.isGiftCardEnabled;

  const isLoggedIn = userRoleContext.status === "logged-in";

  const isOrderStatusEnabled = merchantVexilorConfig["order_status_access"] === "t";

  const [isGuestAttempt, setIsGuestAttempt] = useState(false);
  const [guestAttemptMessage, setGuestAttemptMessage] = useState("");
  const [isShowingRewardDetails, setIsShowingRewardDetails] = useState(false);
  const [userGiftCards, setUserGiftCards] = useState(null);

  const handleOnFavoriteClick = () => {
    if (isLoggedIn) {
      history.push("/online-ordering/menu/favorites");
    } else {
      sessionStorage.setItem(skin + "__lastVisitedLink", "/online-ordering/menu/favorites");
      setGuestAttemptMessage(appLabels["order"]["guest-attempt-favorite-item-dashboard"]);
      setIsGuestAttempt(true);
    }
  };

  const handleOnRecentClick = () => {
    if (isLoggedIn) {
      if (isOrderStatusEnabled) {
        history.push("/online-ordering/recent-orders/active-orders");
      } else {
        history.push("/online-ordering/recent-orders/past-orders");
      }
    } else {
      sessionStorage.setItem(
        skin + "__lastVisitedLink",
        "/online-ordering/recent-orders/past-orders"
      );
      setGuestAttemptMessage(appLabels["order"]["recent-order-guest-attempt"]);
      setIsGuestAttempt(true);
    }
  };

  const deviceWidth = useWindowSize().width;
  return (
    <>
      <section className="dashboard-action-buttons-container">
        {(isOrderingEnabled || isLoyaltyEnabled) && (
          <>
            {/** Desktop Dashboard Order buttons */}
            {deviceWidth >= 660 && (
              <>
                {isOrderingEnabled && !hideOrderModule && (
                  <>
                    <DashboardActionButton
                      isLeftAligned={true}
                      title={appLabels["general"]["order-now"]}
                      subtitle={appLabels["order"]["view-full-menu"]}
                      ariaLabel={appLabels["general"]["order-now"]}
                      icon={
                        <IconUtensils
                          aria-hidden={true}
                          style={{ fill: getContrastColor(branding["primary-colour"]) }}
                        />
                      }
                      onClick={() => {
                        orderContext.checkOrderSettings(orderType, storeContext, orderTimeContext);
                      }}
                      id="dashboard-order-now-button"
                      iconBackGroundColour={branding["primary-colour"]}
                    />
                  </>
                )}

                {isLoyaltyEnabled && isLoggedIn && (
                  <DashboardRewardButton
                    rewardContainerTopPosition={rewardContainerTopPosition}
                    setIsShowingRewardDetails={setIsShowingRewardDetails}
                    isShowingRewardDetails={isShowingRewardDetails}
                    setUserGiftCards={setUserGiftCards}
                    userGiftCards={userGiftCards}
                    customClass={
                      !isOrderingEnabled && !isGiftCardEnabled && isLoyaltyEnabled
                        ? "loyalty-only"
                        : ""
                    }
                  />
                )}

                {isOrderingEnabled && !hideOrderModule && (
                  <>
                    {activeOrderStore && (
                      <DashboardActionButton
                        isLeftAligned={true}
                        title={appLabels["order"]["favorites"]}
                        ariaLabel={appLabels["order"]["favorites"]}
                        icon={<IconHeart aria-hidden={true} stroke="#FC8181" fill={"#FC8181"} />}
                        onClick={() => {
                          handleOnFavoriteClick();
                        }}
                        iconBackGroundColour={"#eeeeee"}
                        customClass={
                          activeOrderStore &&
                          isOrderingEnabled &&
                          (!isLoyaltyEnabled || (isLoyaltyEnabled && !isLoggedIn))
                            ? "right-aligend-fav-button"
                            : ""
                        }
                      />
                    )}

                    <DashboardActionButton
                      isLeftAligned={true}
                      title={appLabels["order"]["recent-orders"]}
                      aria-label={appLabels["order"]["recent-orders"]}
                      icon={
                        <IconDocumentPen
                          aria-hidden="true"
                          style={{ fill: getContrastColor("#eeeeee") }}
                        />
                      }
                      ariaLabel={appLabels["order"]["recent-orders"]}
                      onClick={() => {
                        handleOnRecentClick();
                      }}
                      iconBackGroundColour={"#eeeeee"}
                      customClass={
                        activeOrderStore &&
                        isOrderingEnabled &&
                        (!isLoyaltyEnabled || (isLoyaltyEnabled && !isLoggedIn))
                          ? "full-length-recent-order-button"
                          : ""
                      }
                    />
                  </>
                )}
              </>
            )}

            {deviceWidth < 660 && (
              <>
                {isOrderingEnabled && !hideOrderModule && (
                  <>
                    <div className="dashboard-order-buttons__row order-now">
                      <DashboardActionButton
                        isLeftAligned={true}
                        title={appLabels["general"]["order-now"]}
                        subtitle={appLabels["order"]["view-full-menu"]}
                        ariaLabel={appLabels["general"]["order-now"]}
                        icon={
                          <IconUtensils
                            aria-hidden={true}
                            style={{ fill: getContrastColor(branding["primary-colour"]) }}
                          />
                        }
                        onClick={() => {
                          orderContext.checkOrderSettings(
                            orderType,
                            storeContext,
                            orderTimeContext
                          );
                        }}
                        id="dashboard-order-now-button"
                        iconBackGroundColour={branding["primary-colour"]}
                      />
                    </div>

                    {activeOrderStore ? (
                      <div className="dashboard-order-buttons__row">
                        <DashboardActionButton
                          isLeftAligned={false}
                          isCenterAligned={true}
                          title={appLabels["order"]["favorites"]}
                          ariaLabel={appLabels["order"]["favorites"]}
                          icon={<IconHeart aria-hidden={true} stroke="#FC8181" fill={"#FC8181"} />}
                          onClick={() => {
                            handleOnFavoriteClick();
                          }}
                          customClass={"split-action-button"}
                        />

                        <DashboardActionButton
                          isLeftAligned={false}
                          isCenterAligned={true}
                          title={appLabels["order"]["recent-orders"]}
                          aria-label={appLabels["order"]["recent-orders"]}
                          icon={
                            <IconDocumentPen
                              aria-hidden="true"
                              style={{ fill: getContrastColor("#eeeeee") }}
                            />
                          }
                          onClick={() => {
                            handleOnRecentClick();
                          }}
                          ariaLabel={appLabels["order"]["recent-orders"]}
                          customClass={"split-action-button"}
                        />
                      </div>
                    ) : (
                      <>
                        {/** Recent Order button has a different style on mobile when there is not favorite button rendered */}
                        <DashboardActionButton
                          isLeftAligned={true}
                          title={appLabels["order"]["recent-orders"]}
                          aria-label={appLabels["order"]["recent-orders"]}
                          icon={
                            <IconDocumentPen
                              aria-hidden="true"
                              style={{ fill: getContrastColor("#eeeeee") }}
                            />
                          }
                          onClick={() => {
                            handleOnRecentClick();
                          }}
                          iconBackGroundColour={"#eeeeee"}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        {isGiftCardEnabled && !isOrderingEnabled && (
          <>
            <DashboardActionButton
              isLeftAligned={true}
              title={appLabels["gift-card"]["send-a-gift-card"]}
              ariaLabel={appLabels["gift-card"]["send-a-gift-card"]}
              icon={
                <IconEGiftCard
                  aria-hidden={true}
                  style={{
                    fill: getContrastColor(
                      !isOrderingEnabled ? branding["primary-colour"] : "#eeeeee"
                    ),
                  }}
                />
              }
              iconBackGroundColour={!isOrderingEnabled ? branding["primary-colour"] : "#eeeeee"}
              onClick={() => {
                history.push("/gift-card/purchase");
              }}
            />

            <DashboardActionButton
              isLeftAligned={true}
              title={appLabels["gift-card"]["gift-card-nav-label"]}
              ariaLabel={appLabels["gift-card"]["gift-card-nav-label"]}
              icon={<IconDocumentPen aria-hidden={true} />}
              iconBackGroundColour={"#eeeeee"}
              onClick={() => {
                history.push("/gift-card/recent");
              }}
            />
          </>
        )}
      </section>

      {isGuestAttempt && (
        <DialogModal
          isConfirmText={false}
          hideConfirmButton={true}
          description={guestAttemptMessage}
          message={
            <div className="menu-item__favourite-dialog-modal">
              <div>
                <p>{guestAttemptMessage}</p>
              </div>
              <div className="menu-item__favourite-dialog-modal-buttons-wrapper">
                <button
                  onClick={() => {
                    history.push("/login-register");
                  }}
                  className="button dialog-modal__button  button--primary button--primary-small"
                  type="button"
                >
                  {appLabels["login-register"]["sign-in"]}
                </button>

                <button
                  onClick={() => {
                    history.push("/register");
                  }}
                  className="button dialog-modal__button  button--primary button--primary-small"
                  type="button"
                >
                  {appLabels["login-register"]["register"]}
                </button>
              </div>
            </div>
          }
          resetRemoveDialog={() => {
            setIsGuestAttempt(false);
            sessionStorage.removeItem(skin + "__lastVisitedLink");
          }}
          isHTMLContent={true}
        />
      )}

      {isLoggedIn && isShowingRewardDetails && (
        <div
          className="user-greetings__rewards-container"
          style={{
            width: document.getElementById("dashboard-rewards-button")
              ? document.getElementById("dashboard-rewards-button").getBoundingClientRect().width +
                "px"
              : "unset",
            position: "fixed",
            left: document.getElementById("dashboard-rewards-button")
              ? document.getElementById("dashboard-rewards-button").getBoundingClientRect().left +
                "px"
              : "unset",
            zIndex: 2,
            marginTop: "-10px",
            top: rewardContainerTopPosition + "px",
          }}
        >
          {" "}
          {userGiftCards ? (
            <DashboardRewards userGiftCards={userGiftCards} />
          ) : (
            <LoadingSpinner customClass={"user-greetings__rewards-container-loader"} />
          )}
        </div>
      )}
    </>
  );
};

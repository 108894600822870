import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { toDollars } from "../../_common/helpers";

import { onRemoveProductFromGCCart } from "../../_common/helpers/dataLayerHelpers";
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import AppLabelsContext from "../../App/AppLabelsContext";

import { DialogModal } from "../../_common/DialogModal/DialogModal";


import { IconDelete, IconEdit } from "../../_common/icons";

import "./giftCardCheckout.css"
import "../../OnlineOrdering/Menu/DesktopMenu/DesktopMenu.css"
import MerchantConfigContext from "../../App/MerchantConfigContext";

function GCCartItem(props) {
  const {
    item,
    handleRemove,
  } = props;


  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const removeItem = () => {
    onRemoveProductFromGCCart(item);
    handleRemove(item.entryKey)
  };

  const [formattedDate, setFormattedDate] = useState()
  const [isShowingFullItemDescription, setIsShowingFullItemDescription] = useState(false)
  const history = useHistory()

  const merchantConfig = useContext(MerchantConfigContext)
  const isTimePickerEnabled = merchantConfig.merchant.I57 !== "" ? true : false

  useEffect(() => {
    if (item) {
      let date = new Date(item.deliveryDateTime)
      let orderDayMonth = date.getMonth() + 1
      let orderDayDate = date.getDate()
      let orderDayHours = date.getHours()
      let orderDayMinutes = date.getMinutes()
      let orderDayYear = date.getFullYear()
      if (orderDayDate >= 0 && orderDayDate <= 9) {
        orderDayDate = "0" + orderDayDate
      }
      if (orderDayMinutes >= 0 && orderDayMinutes <= 9) {
        orderDayMinutes = "0" + orderDayMinutes
      }
      if (orderDayMonth >= 1 && orderDayMonth <= 9) {
        orderDayMonth = "0" + orderDayMonth
      }
      if (orderDayHours >= 0 && orderDayHours <= 9) {
        orderDayHours = "0" + orderDayHours
      }

      let strTime = orderDayHours + ':' + orderDayMinutes;

      let dateString = isTimePickerEnabled ? orderDayMonth + "/" + orderDayDate + "/" + orderDayYear + ", " + strTime : orderDayMonth + "/" + orderDayDate + "/" + orderDayYear
      setFormattedDate(dateString)

    }
  }, []);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);

  return (
    <>
      {item && (
        <li key={item.id} className={`cartItem ${item.className ? item.className : ""}`}>
          <div className="cartItem__wrapper">
            <div>
              <div className="cartItem__infoWrapper">
                <div className="cart-item__name-price-wrapper">
                  <h3 className="cartItem__heading gcCartItemTitle">

                    {item.giftCard.title}
                  </h3>

                  <span className="cartItem__price">
                    {item.amount &&
                      toDollars(
                        appSettings["currency-symbol"],
                        appSettings["currency-symbol-side"],
                        item.amount,
                        appLanguage
                      )}
                  </span>

                </div>
                <ul className="cartItem__modifiers gittCardCartItemDetails">

                  <li className="giftCardInfo">
                    <p>{appLabels["gift-card"]["recipient"]}</p>
                    <p>{item.recipientEmail}</p>
                  </li>
                  <li className="giftCardInfo" >
                    <p>{appLabels["gift-card"]["delivery"]}</p>
                    <p>{formattedDate}</p>
                  </li>
                  {item.message !== "" &&
                    <li className="giftCardInfo" >
                      <p>{appLabels["gift-card"]["message"]}</p>

                      {item.message.length > 60 && !isShowingFullItemDescription ? (
                        <div className="collapsedGreetingContainer">
                          <p>{item.message.substring(0, 60)}...</p>
                          <button
                            type="button"
                            className="desktop-order-item__description--read-more"
                            onClick={() => setIsShowingFullItemDescription(true)}
                          >
                            {appLabels["order"]["read-more"]}
                          </button>
                        </div>
                      ) : (
                        <div className="expandedGreetingContainer">
                            <p>{item.message}</p> 
                          {item.message.length > 60 && isShowingFullItemDescription &&
                            <button
                              type="button"
                              className="desktop-order-item__description--read-more"
                              onClick={() => setIsShowingFullItemDescription(false)}
                            >
                              {appLabels["order"]["read-less"]}
                            </button>
                          }
                        </div>
                      )}

                    </li>
                  }
                </ul>

              </div>
            </div>

            <div className="cartItem__buttons-container">
              <button
                className="button cartItem__button--delete"
                onClick={() => setShowRemovePopup(true)}
                aria-label={appLabels["order"]["remove-item-from-cart"].replace(
                  "[item-name]",
                  item.giftCard.title
                )}
                type="button"
              >
                <IconDelete
                  className="cartItem__icon cartItem__icon--delete"
                  aria-hidden="true"
                />
                <span className="cartItem__button-text">{appLabels["general"]["remove"]}</span>
              </button>
              <button
                className="button cartItem__button--edit"
                onClick={() => history.push({ pathname: "/gift-card/purchase/item/" + item.giftCard.id, state: { isEditing: true, editingItemEntryKey: item.id, from: "gift-card-checkout-page" } })}
                aria-label={appLabels["order"]["modify-item-in-cart"].replace(
                  "[item-name]",
                  item.giftCard.title
                )}
              >
                <IconEdit
                  className="cartItem__icon cartItem__icon--edit"
                  aria-hidden="true"
                />
                <span className="cartItem__button-text">{appLabels["order"]["modify"]}</span>
              </button>
              {showRemovePopup && (
                <DialogModal
                  message={
                    appLabels["order"]["cart-item-removal-confirmation"].split("[item-name]")[0] +
                    "gift card" +
                    appLabels["order"]["cart-item-removal-confirmation"].split("[item-name]")[1]
                  }
                  confirmAction={removeItem}
                  isCancelConfirm={true}
                  resetRemoveDialog={() => setShowRemovePopup(false)}
                />
              )}
            </div>

          </div>
        </li>
      )}

    </>
  );
}

export default GCCartItem;

import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconArrowRight } from "../../_common/icons/IconArrowRight.svg";
import "./AppFooterSubnavItem.css";

export const AppFooterSubnavItem = (props) => {
  const {
    isLink,
    linkPathname,
    icon,
    marquiIconFilename,
    displayName,
    isExternalLink,
    action,
    listItemCustomClass = "",
  } = props.item;

  return (
    <li className={`footer__subnav-item ${listItemCustomClass}`}>
      {isLink ? (
        <>
          {isExternalLink ? (
            <a
              href={linkPathname}
              className="account-section__edit-item-link"
              target={isExternalLink ? "_blank" : "_self"}
              rel={isExternalLink ? "noopener noreferrer" : ""}
            >
              <LinkContent
                marquiIconFilename={marquiIconFilename}
                displayName={displayName}
                icon={icon}
              />
            </a>
          ) : (
            <Link
              to={linkPathname}
              className="account-section__edit-item-link"
              onClick={() => {
                if (document.getElementById("mobile-hamburger-menu-container")) {
                  document.getElementById("mobile-hamburger-menu-container").style.display = "none";
                }
              }}
            >
              <LinkContent
                marquiIconFilename={marquiIconFilename}
                displayName={displayName}
                icon={icon}
              />
            </Link>
          )}
        </>
      ) : (
        <button onClick={action} className="button footer__subnav-link" type="button">
          {icon}
          <span className="footer__subnav-link-text">
            {displayName}
            <IconArrowRight aria-hidden="true" />
          </span>
        </button>
      )}
    </li>
  );
};

const LinkContent = ({ marquiIconFilename, displayName, icon }) => (
  <>
    {marquiIconFilename ? (
      <img
        src={marquiIconFilename}
        alt={`${displayName} footer icon`}
        aria-hidden="true"
        loading="lazy"
      />
    ) : (
      icon
    )}
    <span>{displayName}</span>
    <IconArrowRight aria-hidden="true" />
  </>
);

import React, { useState, useContext, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import useWindowSize from "../../../_common/hooks/useWindowSize";
import localforage from "localforage";

/** Helpers */
import { isValidItemQuantity, validateOptions } from "../../../_common/MenuHelpers";
import { findPos, jsonCopy } from "../../../_common/helpers";
import { getContrastColor } from "../../../_common/colorHelpers";
import { sortObjectKeysAlphabetically } from "../helpers/orderingHelpers";
import { getItemQuantityFromCart } from "../../../_common/CartHelpers";
import { onAddProductToCart } from "../../../_common/helpers/dataLayerHelpers";
import {
  onAddProductToCartXtreme,
  onAddSuggestedItemXtreme,
} from "../../../_common/helpers/xtremePushHelper";

/** Context */
import AppLabelsContext from "../../../App/AppLabelsContext";
import OrderTypeContext from "../../OrderTypeContext";
import AppSettingsContext from "../../../App/AppSettingsContext";
import BrandingContext from "../../../App/BrandingContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

/** UI Components */
import { FAB } from "../../../_common/buttons/FAB/FAB";
import { OrderSettingsCart } from "../../../OrderSettings/OrderSettingsCart";

export const MenuAddToOrder = (props) => {
  const {
    cartContext,
    menuItem,
    specialInstructions,
    category,
    subcategory,
    isFAB,
    isOutOfStock,
    updateMenuItem,
    outOfStockError,
    totalPriceText,
    itemQuantity,
    numberOfRequiredChoices,
    addToOrderButtonText,
    isStepByStepOrderingEnabled,
    animationClass,
    isRelativePositioning,
    relativeContainerId,
    useAddToOrderButtonText,
    isAtLastStage,
  } = props;
  const cart = cartContext.value || [];
  const cartData = cartContext;
  const history = useHistory();
  const deviceWidth = useWindowSize().width;

  const [addedToOrder, setAddedToOrder] = useState(false);
  const [areOptionsValid, setAreOptionsValid] = useState(null);
  const [isQuantityValid, setIsQuantityValid] = useState(null);

  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;

  const { skin } = useContext(MerchantConfigContext);

  const timerRef = useRef(null);
  const { state } = useLocation();

  const appSettings = useContext(AppSettingsContext);
  const showPriceOnOrderButton =
    appSettings["show-price-for-order-button"] === "Yes" ||
    !appSettings["show-price-for-order-button"];

  const onlineQuantityLimit =
    !!menuItem["online_qty_limit"] && menuItem["online_qty_limit"] !== "None"
      ? menuItem["online_qty_limit"]
      : null;
  const [errorMessages, setErrorMessages] = useState()
  const addToOrder = () => {
    
    console.log('adding to order')
    const { errors, idOfGroupWithErrors, idOfParentOfGroupWithErrors, idOfMainAddon, errorDetails } =
      validateOptions(menuItem, appLabels["form"]);
    if (errors === 0) {
      if (!isValidItemQuantity(cart, menuItem)) {
        setIsQuantityValid(false);
        return;
      }
      const quantity = menuItem.quantity;
      const cartItem = JSON.parse(JSON.stringify(menuItem));
      cartItem.specialInstructions = specialInstructions;
      cartItem.category = category || "";
      cartItem.subcategory = subcategory || "";
      cartItem.quantity = quantity;

      const newEntryKeyValue = Date.now();
      cartItem.entryKey = newEntryKeyValue;
      delete cartItem.onlineQuantityLimit;

      /** Check if the same item with the same selection exist in the cart
       * by creating copies of the cart and the item being added
       * and removing unnecessary keys
       * and then comparing them as strings
       */

      const cartCopy = jsonCopy(cart);
      const cartItemCopy = jsonCopy(cartItem);

      delete cartItemCopy.entryKey;
      delete cartItemCopy.quantity;
      let matchFound = false;
      let matchIndex = 0;
      cartCopy.forEach((cartCopyItem, index) => {
        let storedQuantity = cartCopyItem.quantity;
        delete cartCopyItem.entryKey;
        delete cartCopyItem.quantity;
        delete cartCopyItem.total_addon_discount;
        if (
          JSON.stringify(sortObjectKeysAlphabetically(cartCopyItem)) ===
          JSON.stringify(sortObjectKeysAlphabetically(cartItemCopy))
        ) {
          matchFound = true;
          matchIndex = index;
          cartItem.quantity = quantity + storedQuantity;
          cartItem.entryKey = newEntryKeyValue;
        }
      });

      const newCart = cart ? [...cart] : [];
      if (matchFound) {
        newCart[matchIndex] = cartItem;
      } else {
        newCart.push(cartItem);
      }

      onAddProductToCart(cartItem, category, subcategory);
      onAddProductToCartXtreme(cartItem, category, subcategory, skin);
      cartContext.updateCart(newCart);

      setAddedToOrder(true);
      setAreOptionsValid(true);

      localforage.removeItem(skin + "__itemSelectionInProgress");
      sessionStorage.removeItem(skin + "__orderStoreState");

      if (state && state.from === "suggestedItems") {
        onAddSuggestedItemXtreme(cartItem, category, subcategory, skin);
        timerRef.current = setTimeout(() => {
          history.push({
            pathname: `/online-ordering/${
              activeOrderType === "dinein" ? "review-order" : "checkout"
            }`,
            state: { from: "item-page" },
          });

          resetSelectedAddonsModifiers();
        }, 1000);
      } else {
        timerRef.current = setTimeout(() => {
          if (category) {
            history.push({
              pathname: `/online-ordering/menu/category/${category}/subcategory/${subcategory}`,
              state: { from: "item-page" },
            });
          } else {
            history.push({
              pathname: `/online-ordering/menu/subcategory/${subcategory}`,
              state: { from: "item-page" },
            });
          }

          resetSelectedAddonsModifiers();
        }, 1000);
      }
    } else {
      setAreOptionsValid(false);
      setIsQuantityValid(false);
      timerRef.current = setTimeout(() => {
        setAreOptionsValid(null);
        setIsQuantityValid(null);
      }, 2000);

      /** Scroll to the addon/modifier group with the id idOfGroupWithErrors */
      const containerWithError =
        document.getElementById(`${idOfGroupWithErrors}-header`) ||
        document.getElementById(
          `${idOfGroupWithErrors}_${idOfParentOfGroupWithErrors}_${idOfMainAddon}`
        );
      
      if (errors > 0){

        let errorDetailsCopy = []
        errorDetailsCopy = errorDetails
        setErrorMessages(errorDetailsCopy)
      }
      const groupContainerButton = containerWithError.querySelectorAll("button");
      const optionItemContainer = containerWithError.nextSibling;
      /** if the options groups is collapsed, expand it */
      if (optionItemContainer && optionItemContainer.classList.value.includes("visually-hidden")) {
        groupContainerButton[0].click();
      }

      setTimeout(() => {
        document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
          top: findPos(containerWithError) - (deviceWidth >= 660 ? 140 : 200),
          behavior: "smooth",
        });
      }, 250);

      let elementLabels;
      /** if nested addon/modifier group */
      if (
        containerWithError &&
        containerWithError.classList.value.includes("nested-addons-fieldset-wrapper")
      ) {
        elementLabels = containerWithError.querySelectorAll(`span`);
        let elementButtons = containerWithError.querySelectorAll("button");
        elementButtons.forEach((button) => {
          button.classList.add("error");
        });
      } else {
        //if regular addon modifier group
        if ((elementLabels = containerWithError.nextSibling)) {
          elementLabels = containerWithError.nextSibling.querySelectorAll(
            `.addons-modifiers-form .form__field-label--checkbox-radio`
          );
        }
      }
      /** Add the error class to each label element */
      if (elementLabels) {
        elementLabels.forEach((label) => {
          label.classList.add("error");
        });
      }
    }
  
   
  };

  // Prevent redirect after user's already navigated to another route
  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const resetSelectedAddonsModifiers = () => {
    updateMenuItem(null, true);
  };

  const resetInvalidForm = () => {
    setAreOptionsValid(null);
    setIsQuantityValid(null);
    if (props.resetAPIError) props.resetAPIError();
  };

  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);
  const contrastTextColor = getContrastColor(branding["online-order-settings-background-colour"]);
  return (
    isFAB && (
      <>
        {cartData.quantity > 0 && isAtLastStage ? (
          <>
            <FAB
              onSubmit={addToOrder}
              buttonText={
                isStepByStepOrderingEnabled &&
                numberOfRequiredChoices > 0 &&
                useAddToOrderButtonText
                  ? addToOrderButtonText
                  : addedToOrder
                  ? appLabels["order"]["add-another"]
                  : numberOfRequiredChoices > 0
                  ? appLabels["order"]["number-of-required-choices"].replace(
                      "[number-of-choices]",
                      numberOfRequiredChoices
                    ) + `${showPriceOnOrderButton ? " (" + totalPriceText + ")" : ""}`
                  : appLabels["order"]["add-to-order"].replace("[item-quantity]", itemQuantity) +
                    `${showPriceOnOrderButton ? " (" + totalPriceText + ")" : ""}`
              }
              buttonSuccessText={appLabels["order"]["added"]}
              formError={
                (!areOptionsValid && areOptionsValid != null) || outOfStockError
                  ? appLabels["form"]["missing-required-addons-modifiers-error"]
                  : !isQuantityValid && isQuantityValid != null
                  ? appLabels["order"]["invalid-item-quantity-error"]
                  : ""
              }
              errorDetails = {errorMessages}
              isAddItemType={true}
              isDisabled={
                isOutOfStock ||
                (isStepByStepOrderingEnabled && numberOfRequiredChoices > 0) ||
                (!!onlineQuantityLimit &&
                  getItemQuantityFromCart(cart, menuItem.id) >= onlineQuantityLimit)
              }
              isFormValid={areOptionsValid || isQuantityValid}
              resetInvalidForm={resetInvalidForm}
              isLeftAligned={true}
              isBackgroundGradient={true}
              isAddToOrder={true}
              customClass={`FAB--add-to-order__with-cart ${
                isOutOfStock ||
                (!!onlineQuantityLimit &&
                  getItemQuantityFromCart(cart, menuItem.id) >= onlineQuantityLimit)
                  ? " disabled_order_button"
                  : ""
              } `}
              FABCustomClass={`FAB-wrapper__item-details-page FAB--add-to-order__with-cart-wrapper wizard ${
                animationClass ? animationClass : ""
              }`}
              customStyle={{
                color: contrastTextColor,
                backgroundColor: "transparent",
                border: `2px solid ${contrastTextColor}`,
                width: "100%",
                opacity:
                  isOutOfStock ||
                  (isStepByStepOrderingEnabled && numberOfRequiredChoices > 0) ||
                  (!!onlineQuantityLimit &&
                    getItemQuantityFromCart(cart, menuItem.id) >= onlineQuantityLimit)
                    ? 0.5
                    : 1,
              }}
              checkIconColor={contrastTextColor}
              isRelativePositioning={isRelativePositioning}
              relativeContainerId={relativeContainerId}
            />
            {ReactDOM.createPortal(
              <OrderSettingsCart />,
              deviceWidth < 660
                ? document.body
                : !!document.getElementsByClassName("FAB--add-to-order__with-cart-wrapper").length
                ? document.getElementsByClassName("FAB--add-to-order__with-cart-wrapper")[0]
                    .parentElement
                : document.body
            )}
          </>
        ) : (
          <FAB
            onSubmit={addToOrder}
            buttonText={
              isStepByStepOrderingEnabled && numberOfRequiredChoices > 0 && useAddToOrderButtonText
                ? addToOrderButtonText
                : addedToOrder
                ? appLabels["order"]["add-another"]
                : numberOfRequiredChoices > 0
                ? appLabels["order"]["number-of-required-choices"].replace(
                    "[number-of-choices]",
                    numberOfRequiredChoices
                  ) + `${showPriceOnOrderButton ? " (" + totalPriceText + ")" : ""}`
                : appLabels["order"]["add-to-order"].replace("[item-quantity]", itemQuantity) +
                  `${showPriceOnOrderButton ? " (" + totalPriceText + ")" : ""}`
            }
            buttonSuccessText={appLabels["order"]["added"]}
            formError={
              (!areOptionsValid && areOptionsValid != null) || outOfStockError
                ? appLabels["form"]["missing-required-addons-modifiers-error"]
                : !isQuantityValid
                ? appLabels["order"]["reorder-limited-stock-items"]
                : ""
            }
            isAddItemType={true}
            isDisabled={
              isOutOfStock || (isStepByStepOrderingEnabled && numberOfRequiredChoices > 0)
            }
            isFormValid={areOptionsValid || isQuantityValid}
            resetInvalidForm={resetInvalidForm}
            isLeftAligned={true}
            isBackgroundGradient={true}
            isAddToOrder={true}
            customClass={`${isOutOfStock ? " disabled_order_button" : ""}`}
            FABCustomClass={`FAB-wrapper__item-details-page centered wizard ${
              animationClass ? animationClass : ""
            }`}
            customStyle={{
              color: contrastTextColor,
              backgroundColor: "transparent",
              border: `2px solid ${contrastTextColor}`,
              opacity:
                isOutOfStock || (isStepByStepOrderingEnabled && numberOfRequiredChoices > 0)
                  ? 0.5
                  : 1,
            }}
            checkIconColor={contrastTextColor}
            isRelativePositioning={isRelativePositioning}
            relativeContainerId={relativeContainerId}
            errorDetails={errorMessages}
          />
        )}
      </>
    )
  );
};

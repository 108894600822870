export const getTodayName = (originalOrderDate = null) => {
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long", // e.g. displays Friday instead of Fri
  })
    .format(originalOrderDate ? originalOrderDate : new Date()) // converts the Date()-returned day number value to an actual day name
    .toLowerCase();
};

export const formatStoreHours = (tempOpeningTime, tempClosingTime, futureDate) => {
  if (tempOpeningTime === "closed" && tempClosingTime === "closed") {
    return { formattedOpeningTime: "closed", formattedClosingTime: "closed" };
  }

  const openingTimeHours = tempOpeningTime.split(":")[0];
  const openingTimeMinutes = tempOpeningTime.split(":")[1];

  const closingTimeHours = tempClosingTime.split(":")[0];
  const closingTimeMinutes = tempClosingTime.split(":")[1];
  const formattedOpeningTime = futureDate ? new Date(futureDate) : new Date();
  formattedOpeningTime.setHours(openingTimeHours, openingTimeMinutes, 0);

  const formattedClosingTime = futureDate ? new Date(futureDate) : new Date();
  formattedClosingTime.setHours(closingTimeHours, closingTimeMinutes, 0);

  const currentTime = new Date();

  //If Closing time is earlier than Opening time - one of them must be a different Date
  if (formattedClosingTime < formattedOpeningTime) {
    if (currentTime > formattedOpeningTime) {
      formattedClosingTime.setDate(formattedClosingTime.getDate() + 1);
    } else {
      formattedOpeningTime.setDate(formattedOpeningTime.getDate() - 1);
    }
  }

  return { formattedOpeningTime, formattedClosingTime };
};

export const getFormattedStoreHoursWithMidnight = (
  tempOpeningTime,
  tempClosingTime,
  activeDate,
  orderStore
) => {
  if (tempOpeningTime === "closed" || tempClosingTime === "closed") {
    return "closed";
  }

  const openingTimeHours = tempOpeningTime.split(":")[0];
  const openingTimeMinutes = tempOpeningTime.split(":")[1];

  const closingTimeHours = tempClosingTime.split(":")[0];
  const closingTimeMinutes = tempClosingTime.split(":")[1];
  const formattedOpeningTime = activeDate ? new Date(activeDate) : new Date();
  formattedOpeningTime.setHours(openingTimeHours, openingTimeMinutes, 0);

  const formattedClosingTime = activeDate ? new Date(activeDate) : new Date();
  formattedClosingTime.setHours(closingTimeHours, closingTimeMinutes, 0);

  //const currentTime = new Date();

  if (orderStore.openPassedMidnight[getDayOfWeekFromIndex(activeDate.getDay())] === true) {
    formattedClosingTime.setDate(formattedClosingTime.getDate() + 1);
  }

  /*//If Closing time is earlier than Opening time - one of them must be a different Date
  if (formattedClosingTime < formattedOpeningTime) {
    if (currentTime > formattedOpeningTime) {
      formattedClosingTime.setDate(formattedClosingTime.getDate() + 1);
    } else {
      formattedOpeningTime.setDate(formattedOpeningTime.getDate() - 1);
    }
  } else {
    if (orderStore.openPassedMidnight[getDayOfWeekFromIndex(activeDate.getDay())] === true) {
      formattedClosingTime.setDate(formattedClosingTime.getDate() + 1);
    }
  }*/

  return { formattedOpeningTime, formattedClosingTime };
};

export const convertFrom24HourFormat = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)/) || [time];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const getTodayClosingTime = (hours, originalOrderDate = null) => {
  let todayStoreHours = "";
  const today = originalOrderDate ? getTodayName(originalOrderDate) : getTodayName();

  for (let day in hours) {
    if (today === day.toLowerCase()) {
      todayStoreHours = hours[day];
    }
  }

  const todayStoreClosingTime =
    todayStoreHours === "closed" ? "closed" : todayStoreHours.split("-")[1].replace(/\s/g, "");

  return todayStoreClosingTime;
};

export const getTodayOpeningTime = (hours, originalOrderDate = null) => {
  let today = originalOrderDate ? getTodayName(originalOrderDate) : getTodayName();
  let todayStoreHours = "";
  for (let day in hours) {
    if (today === day.toLowerCase()) todayStoreHours = hours[day];
  }
  return todayStoreHours.split("-")[0].replace(/\s/g, "");
};

/**
 *
 * @param {int} dayIndex day index of the week. Sun = 0, Sat = 6
 */
export const getDayOfWeekFromIndex = (dayIndex, appLabels) => {
  var weekdays = appLabels
    ? [
        appLabels["general"]["sunday"],
        appLabels["general"]["monday"],
        appLabels["general"]["tuesday"],
        appLabels["general"]["wednesday"],
        appLabels["general"]["thursday"],
        appLabels["general"]["friday"],
        appLabels["general"]["saturday"],
      ]
    : ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  return weekdays[dayIndex];
};

/**
 *
 * @param {int} monthIndex
 */
export const getMonthFromIndex = (monthIndex, appLabels) => {
  let monthNames = appLabels
    ? [
        appLabels["general"]["january"],
        appLabels["general"]["february"],
        appLabels["general"]["march"],
        appLabels["general"]["april"],
        appLabels["general"]["may"],
        appLabels["general"]["june"],
        appLabels["general"]["july"],
        appLabels["general"]["august"],
        appLabels["general"]["september"],
        appLabels["general"]["october"],
        appLabels["general"]["november"],
        appLabels["general"]["december"],
      ]
    : [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

  return monthNames[monthIndex];
};

/*return the opening time of a specific day in the week */
/**
 *
 * @param {object} hours  store hours for the week
 * @param {int} dayIndex  day index of the day of the week
 */
export const getOpeningTime = (hours, dayIndex) => {
  const selectedDay = getDayOfWeekFromIndex(dayIndex);
  let storeHours = "";

  for (let day in hours) {
    if (selectedDay === day.toLowerCase()) storeHours = hours[day];
  }

  return storeHours.split("-")[0].replace(/\s/g, "");
};

/**
 *
 * @param {object} hours store hours for the week
 * @param {int} dayIndex day index of the day of the week
 */
export const getClosingTime = (hours, dayIndex) => {
  const selectedDay = getDayOfWeekFromIndex(dayIndex);
  let storeHours = "";

  for (let day in hours) {
    if (selectedDay === day.toLowerCase()) storeHours = hours[day];
  }

  return storeHours === "closed" ? "closed" : storeHours.split("-")[1].replace(/\s/g, "");
};

export const isStoreOpenOrClosed = (location) => {
  let isOpen;

  const now = new Date();
  const tempOpeningTime = getTodayOpeningTime(location.hours);
  const tempClosingTime = getTodayClosingTime(location.hours);
  const formattedStoreHours = formatStoreHours(tempOpeningTime, tempClosingTime);
  const formattedOpeningTime = formattedStoreHours.formattedOpeningTime;
  const formattedClosingTime = formattedStoreHours.formattedClosingTime;

  // console.log(location.name, { now, formattedStoreHours });

  if (now >= formattedOpeningTime && now < formattedClosingTime) {
    isOpen = true;
  } else {
    isOpen = false;
  }

  return isOpen;
};

export const checkPickupInterval = (minPickupInterval, closingTime, hours) => {
  const now = new Date();
  const formattedStoreHours = formatStoreHours(getTodayOpeningTime(hours), closingTime);
  const formattedClosingTime = formattedStoreHours.formattedClosingTime;

  let formattedPickupMinInterval = "";
  formattedPickupMinInterval = new Date(now.getTime() + minPickupInterval * 60000); // add pickup interval to current time

  let storeClosesBeforeMinPickup;

  if (formattedClosingTime <= formattedPickupMinInterval) {
    storeClosesBeforeMinPickup = true;
  } else {
    storeClosesBeforeMinPickup = false;
  }

  return storeClosesBeforeMinPickup;

  // For Debugging
  /* const diff = Math.abs(formattedClosingTime - now) / 1000;
    const diffHours = Math.floor(diff / 3600) % 24;
    const diffMinutes = Math.floor(diff / 60) % 60;

    console.log("Now: ", now);
    console.log("Closing time: ", formattedClosingTime);
    console.log(
      "%cPickup Min Interval:",
      "color: orange",
      pickupMinInterval + " min"
    );
    console.log(
      "%cClosing in: ",
      "color: red",
      diffHours + " hours, " + diffMinutes + " min"
    ); */
  // END OF For Debugging
};

/**
 * Formats the list of overrides
 * @param {*} overRideList - list of overrides from API 1306 on store level based on active order type (pickup/delivery)
 * @returns formated list of time overrides
 */
export const formatStoreTimeOverrides = (overRideList, activeOrderDate) => {
  // base case: there are no overrides
  if (!overRideList || overRideList.length === 0) {
    return [];
  }

  let formattedOverrides = {};
  Object.keys(overRideList).forEach((overrideDay) => {
    let dayOverride = [];
    overRideList[overrideDay].forEach((override) => {
      const startTime = { hour: override[0].split(":")[0], minutes: override[0].split(":")[1] };
      const endTime = { hour: override[1].split(":")[0], minutes: override[1].split(":")[1] };
      const leadTime = override[2];

      const startTimeDateObject = new Date(activeOrderDate);
      startTimeDateObject.setHours(startTime.hour);
      startTimeDateObject.setMinutes(startTime.minutes);
      startTimeDateObject.setSeconds(0);
      startTimeDateObject.setMilliseconds(0);

      const endTimeDateObject = new Date(activeOrderDate);
      endTimeDateObject.setHours(endTime.hour);
      endTimeDateObject.setMinutes(endTime.minutes);
      endTimeDateObject.setSeconds(0);
      endTimeDateObject.setMilliseconds(0);

      dayOverride.push({
        start: startTimeDateObject,
        end: endTimeDateObject,
        leadTime: leadTime,
        isBlocked: leadTime === "0",
      });
    });
    formattedOverrides[overrideDay] = dayOverride;
  });

  return formattedOverrides;
};

export const isTimeSlotBlocked = (timeSlot, blockedTimeIntervals) => {
  timeSlot.setMilliseconds(0);
  const isWithinBlockedHours = blockedTimeIntervals.filter(
    (blockedTime) =>
      timeSlot.getTime() >= blockedTime.start.getTime() &&
      timeSlot.getTime() <= blockedTime.end.getTime()
  ).length;

  return isWithinBlockedHours > 0;
};

/** this function returns the correct time increment interval that should be used based on user's current time and BE overrides */
export const getIntermittentIncrementInterval = (timeOverrides, timeSlot, incrementInterval, originalOrderDate = null) => {
  let tempIncrementInterval = incrementInterval;
  let originalOrderDateCopy = new Date(originalOrderDate)
  const timeSlotCopy = new Date(timeSlot.getTime() + incrementInterval * 60000);
  timeSlotCopy.setMilliseconds(0);
  const currentTime = originalOrderDate ? originalOrderDateCopy : new Date();
  currentTime.setDate(timeSlot.getDate());
  currentTime.setMilliseconds(0);

  const storeOverrideForOrderTime = timeOverrides[getDayOfWeekFromIndex(currentTime.getDay())];
  if (storeOverrideForOrderTime) {
    for (let i = 0; i < storeOverrideForOrderTime.length; i++) {
      const override = storeOverrideForOrderTime[i];
      if (
        !override.isBlocked &&
        timeSlotCopy.getTime() >= override.start.getTime() &&
        timeSlotCopy.getTime() <= override.end.getTime()
      ) {
        const currentPlusLead = new Date(currentTime.getTime() + override.leadTime * 60000);
        currentPlusLead.setSeconds(0);

        //return the difference between current time + lead time and the time slot
        if (currentPlusLead.getTime() < timeSlotCopy.getTime()) {
          tempIncrementInterval = incrementInterval;
        } else {
          tempIncrementInterval = Math.abs(
            parseInt((currentPlusLead.getTime() - timeSlot.getTime()) / 60000)
          );
        }
        break;
      }
    }
  }

  return tempIncrementInterval <= 0 || tempIncrementInterval < incrementInterval
    ? incrementInterval
    : tempIncrementInterval;
};

export const getMinInterval = (activeStoreVexilorConfig, activeOrderType, skin, orderDate) => {
  let activeOrderDate = new Date();
  if (orderDate) {
    activeOrderDate = new Date(orderDate);
  }
  const formattedStoreOverrides =
    activeOrderType === "dinein"
      ? []
      : formatStoreTimeOverrides(
          activeOrderType === "delivery"
            ? activeStoreVexilorConfig.delivery_min_interval_override
            : activeStoreVexilorConfig.pickup_min_interval_override,
          activeOrderDate
        );

  let currentMinInterval =
    activeOrderType === "delivery"
      ? activeStoreVexilorConfig.delivery_min_interval
      : activeStoreVexilorConfig.pickup_min_interval;

  const currentTime = new Date();
  currentTime.setDate(activeOrderDate.getDate());

  const storeOverrideForOrderTime =
    Object.keys(formattedStoreOverrides).length === 0
      ? []
      : formattedStoreOverrides[getDayOfWeekFromIndex(currentTime.getDay())];

  for (let i = 0; i < storeOverrideForOrderTime.length; i++) {
    const override = storeOverrideForOrderTime[i];
    if (
      currentTime.getTime() >= override.start.getTime() &&
      currentTime.getTime() <= override.end.getTime() &&
      override.leadTime > 0
    ) {
      currentMinInterval = override.leadTime;
      break;
    }
  }
  return currentMinInterval;
};

export const isWithinBusinessHours = (orderStore, orderTimeOption) => {
  const storeHours = orderStore.hours;
  //const todayApiOpeningTime = getTodayOpeningTime(storeHours);
  //const todayApiClosingTime = getTodayClosingTime(storeHours);

  const todayApiOpeningTime = getOpeningTime(storeHours, orderTimeOption.getDay());
  const todayApiClosingTime = getClosingTime(storeHours, orderTimeOption.getDay());

  const formattedStoreHours = getFormattedStoreHoursWithMidnight(
    todayApiOpeningTime,
    todayApiClosingTime,
    orderTimeOption,
    orderStore
  );

  const formattedOpeningTime = formattedStoreHours.formattedOpeningTime;
  const formattedClosingTime = formattedStoreHours.formattedClosingTime;

  if (orderTimeOption >= formattedOpeningTime && orderTimeOption <= formattedClosingTime) {
    return true;
  } else {
    //check the same logic for the day before, if the previous day, the store is also open passed midnight
    const previousDayTimeOption = new Date(orderTimeOption);
    previousDayTimeOption.setDate(previousDayTimeOption.getDate() - 1);

    if (
      orderStore.openPassedMidnight[getDayOfWeekFromIndex(previousDayTimeOption.getDay())] === true
    ) {
      const formattedStoreHoursForPreviousDay = getFormattedStoreHoursWithMidnight(
        todayApiOpeningTime,
        todayApiClosingTime,
        previousDayTimeOption,
        orderStore
      );

      const formattedClosingForPreviousDay = formattedStoreHoursForPreviousDay.formattedClosingTime;

      return orderTimeOption <= formattedClosingForPreviousDay;
    } else {
      return false;
    }
  }
};

import React, { useContext } from "react";
import AppLabelsContext from "../../App/AppLabelsContext";
import { Form } from "../../_common/Form/Form";
import { FormInput } from "../../_common/Form/FormInput";

function TableNumberForm(props) {
  const { storeContext, updateOrderTime, customClass, setVideoDevices, isDisableSubmit, skin } =
    props;

  const handleSubmit = (formData) => {
    setVideoDevices("stop");
    const tableNumber = formData[0].value;
    storeContext.updateActiveOrderStoreTable(tableNumber);
    updateOrderTime();
  };

  const appLabels = useContext(AppLabelsContext);

  const getSubmitNavigateURL = () => {
    const clickedOnFeaturedItem = sessionStorage.getItem(skin + "__featuredItemLink") !== null;
    if (clickedOnFeaturedItem) {
      const featuredItemLink = sessionStorage.getItem(skin + "__featuredItemLink");
      sessionStorage.removeItem(skin + "__featuredItemLink");
      sessionStorage.removeItem(skin + "__featuredItemStoreId");
      return featuredItemLink;
    } else {
      return "/online-ordering/menu";
    }
  };

  return (
    <Form
      submitForm={handleSubmit}
      submitButtonText={appLabels["order"]["show-menu-button"]}
      submitButtonSuccessText={appLabels["order"]["show-menu-button-processing"]}
      className={`dine-in__table-number-form ${customClass}`}
      id="form--table-number"
      submitNavigateURL={getSubmitNavigateURL()}
      submitNavigateURLStateFrom="table-number-form-submit"
      isAPISubmitValid={true}
      isAPISubmitting={false}
      isDisableSubmit={isDisableSubmit}
    >
      <FormInput
        type="text"
        label={appLabels["order"]["table-number-field-label"]}
        id="input--table-number"
        name="table-number"
        isRequired={true}
      />
    </Form>
  );
}

export default TableNumberForm;

import React, { useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import AppLanguageContext from "../../App/AppLanguageContext";

export const FormReCAPTCHA = (props) => {
  const { fieldData, onRecaptchaChange, value } = props;

  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

  const appLanguage = useContext(AppLanguageContext);

  return (
    <div
      className={`form__field-wrapper${
        !fieldData.isValid ? " form__field-wrapper--invalid" : ""
      } form__field-recaptcha-wrapper`}
    >
      <ReCAPTCHA
        sitekey={
          isLocalhost
            ? "6LfmP8gUAAAAAAY9-UklLmh3c6WV-HMMdXatV2ZR"
            : "6LdUCxYTAAAAANMjMuPFMrC1GyTHmem5M1llJ8Id"
        }
        onChange={onRecaptchaChange}
        value={value}
        hl={appLanguage || "en"}
      />
      {!fieldData.isValid && <div className="form__field-error">{fieldData.errorMessage}</div>}
    </div>
  );
};

import React, { useContext, useState } from "react";

/** Contexts */
import AppLabelsContext from "../../../App/AppLabelsContext";
import UserRoleContext from "../../../App/UserRoleContext";

/** UI Components */
import CollapsableSection from "../../../_common/components/CollapsableSection";
import ConfirmationEmail from "./ConfirmationEmail";

const ConfirmationEmailCollapsable = () => {
  const appLabels = useContext(AppLabelsContext);
  const userRoleContext = useContext(UserRoleContext);
  const [isCollapsed, setIsCollapsed] = useState(userRoleContext.status === "guest");

  return (
    <div className="confirmation-email__main-container">
      <CollapsableSection
        headerText={appLabels["order"]["confirmation-email"]}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        id={"confirmationEmailCollapsable"}
      />

      <div
        className={`${
          isCollapsed
            ? " collapsable-section__collapsed-content"
            : " collapsable-section__expanded-content"
        }`}
      >
        <ConfirmationEmail />
      </div>
    </div>
  );
};
export default ConfirmationEmailCollapsable;

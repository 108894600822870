import React, { useState, useContext } from "react";
import AppLabelsContext from "../../../../App/AppLabelsContext";
import { ReactComponent as IconPlus } from "../../../../_common/icons/IconPlus.svg";
import { ReactComponent as IconMinus } from "../../../../_common/icons/IconMinus.svg";
import { AddAnotherGiftCard } from "./AddAnotherGiftCard";
import { PaymentMethodCreditCard } from "../PaymentMethodCreditCard/PaymentMethodCreditCard";

export const RemainderGiftCardPayment = (props) => {
  const {
    paymentMethodExpanded,
    creditCardTypes,
    merchantConfig,
    appliedGiftCards,
    updateAppliedGiftCard,
    orderTotal,
    promoCode,
    rewards,
    coupon,
  } = props;

  const [isRemainderGiftCardFormOpen, setIsRemainderGiftCardFormOpen] = useState(false);
  const toggleRemainderGiftCard = () => {
    setIsRemainderGiftCardFormOpen(!isRemainderGiftCardFormOpen);
  };

  const [isRemainderCreditCardFormOpen, setIsRemainderCreditCardFormOpen] = useState(false);
  const toggleRemainderCreditCard = () => {
    setIsRemainderCreditCardFormOpen(!isRemainderCreditCardFormOpen);
  };

  const appLabels = useContext(AppLabelsContext);

  return (
    <>
      <button
        onClick={() => {
          toggleRemainderGiftCard();
          setIsRemainderCreditCardFormOpen(false);
        }}
        className="button button--giftcard-remainder-option"
        type="button"
      >
        {isRemainderGiftCardFormOpen ? <IconMinus aria-hidden="true" /> : <IconPlus aria-hidden="true" />}
        <span>
          {appliedGiftCards.length > 0
            ? appLabels["order"]["add-another-gift-card"]
            : appLabels["order"]["add-gift-card"]}
        </span>
      </button>
      {isRemainderGiftCardFormOpen && (
        <AddAnotherGiftCard
          isRemainderPayment={true}
          merchantConfig={merchantConfig}
          appliedGiftCards={appliedGiftCards}
          updateAppliedGiftCard={(giftCard) => {
            setTimeout(() => {
              setIsRemainderGiftCardFormOpen(false);
              updateAppliedGiftCard(giftCard);
            }, 1200);
          }}
        />
      )}
      <button
        onClick={() => {
          toggleRemainderCreditCard();
          setIsRemainderGiftCardFormOpen(false);
        }}
        className="button button--giftcard-remainder-option"
        type="button"
      >
        {isRemainderCreditCardFormOpen ? <IconMinus aria-hidden="true" /> : <IconPlus aria-hidden="true" />}
        <span>{appLabels["order"]["pay-remainder-with-credit-card"]}</span>
      </button>
      {isRemainderCreditCardFormOpen && (
        <div className="guest-giftcard-remaining">
          <PaymentMethodCreditCard
            appliedGiftCards={appliedGiftCards}
            paymentMethodExpanded={paymentMethodExpanded}
            creditCardTypes={creditCardTypes}
            orderTotal={orderTotal}
            promoCode={promoCode}
            rewards={rewards}
            coupon={coupon}
          />
        </div>
      )}
    </>
  );
};

// Libraries
import React, { useState, useEffect, useContext } from "react";
import { Route, useHistory } from "react-router-dom";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";

// API helper functions
import { getAPICardList } from "../OnlineOrdering/Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPICardList";
import { getApiSUN } from "./getApiSUN";

// Contexts
import AppSettingsContext from "../App/AppSettingsContext";
import AppLanguageContext from "../App/AppLanguageContext";
import AppLabelsContext from "../App/AppLabelsContext";
import BrandingContext from "../App/BrandingContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import UserRoleContext from "../App/UserRoleContext";

// UI Components
import { AppHeader } from "../App/AppHeader/AppHeader";
import { ScanInStoreActiveCardInfo } from "./ScanInStoreActiveCardInfo";
import { ScanInStoreQRCodeInfo } from "./ScanInStoreQRCodeInfo";
import { ScanInStoreAllCards } from "./ScanInStoreAllCards";
import { AddMoney } from "./AddMoney";
import { TransferFunds } from "./TransferFunds";
import { AppFooter } from "../App/AppFooter/AppFooter";
import { LoadingSpinner } from "../_common/LoadingSpinner";

// CSS
import "./PayInStore.css";

export const PayInStore = () => {
  const history = useHistory();

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const brandingContext = useContext(BrandingContext);
  const appLabels = useContext(AppLabelsContext);
  const userRoleContext = useContext(UserRoleContext);
  const merchantConfigContext = useContext(MerchantConfigContext);

  const skin = merchantConfigContext.skin;
  const loginToken = userRoleContext.loginToken;

  const isAddToWalletServiceEnabled =
    merchantConfigContext.vexilor.I2["wallet_download_url"] === "t";
  const isGiftCardAutoReloadServiceEnabled =
    merchantConfigContext.vexilor.I2["cws_get_reload"] === "t";

  // Redirect to Dashboard since Scan in Store is not supposed to exist on Desktop
  const deviceWidth = useWindowSize().width;
  useEffect(() => {
    if (deviceWidth >= 660 && !window.location.hash.includes("/pay-in-store/")) {
      history.push("/dashboard");
    }
  }, [deviceWidth]);

  useEffect(() => {
    if (userRoleContext.status !== "logged-in") {
      sessionStorage.setItem(skin + "__lastVisitedLink", window.location.hash.split("#")[1]);
      history.push("/login-register");
    }
  }, [userRoleContext]);

  if (history.location.hash === "#refresh") {
    history.push("/pay-in-store");
    window.location.reload();
  }

  const apiMerchantRewards = merchantConfigContext.merchant.I14;
  const currencySymbol = appSettings["currency-symbol"];
  const currencySymbolSide = appSettings["currency-symbol-side"];

  const [reloadScreen, setReloadScreen] = useState(false);
  const [loyaltyCards, setLoyaltyCards] = useState(null);

  // If the 'reloadScreen' flag's been set, call the API to pull the list of cards associated with user's account
  // FIXME: this shouldn't be necessary
  useEffect(() => {
    if (reloadScreen) {
      if (loginToken) {
        getAPICardList(
          skin,
          loginToken,
          currencySymbol,
          currencySymbolSide,
          appLanguage,
          brandingContext
        ).then((apiCardList) => {
          if (apiCardList.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else if (apiCardList.error) {
            history.push("/dashboard");
          } else {
            setLoyaltyCards(apiCardList);
          }
        });
      }

      setReloadScreen(false);
    }
  }, [reloadScreen]);

  const [currentSUNNumber, setCurrentSUNNumber] = useState(null);

  /**
   * Call API 944 to get SUN for a specific card
   * @param {*} loyaltyCard - number of the rewards card
   */

  const getNewSUN = (loyaltyCard) => {
    if (!window.location.href.includes("add-money")) {
      getApiSUN(skin, loginToken, loyaltyCard, appLanguage).then((apiSUN) => {
        if (apiSUN) {
          if (apiSUN.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else {
            setCurrentSUNNumber(apiSUN.SUN);
            loyaltyCard.SUN = apiSUN.SUN;
            loyaltyCard.preferredCodeFormat = apiSUN.preferredCodeFormat;
          }
        }
      });
    }
  };

  const [isStoredLoyaltyCard, setIsStoredLoyaltyCard] = useState(null);
  const [activeLoyaltyCard, setActiveLoyaltyCard] = useState(null);

  /**
   * Check to see if there is a loyalty card already selected from the rewards section.
   * If so set that as the selected loyalty card and its SUN.
   */
  useEffect(() => {
    if (loginToken) {
      const storedActiveLoyaltyCard = JSON.parse(
        sessionStorage.getItem(skin + "__activeLoyaltyCard")
      );

      if (storedActiveLoyaltyCard) {
        const tempLoyaltyCard = { ...storedActiveLoyaltyCard };

        if (tempLoyaltyCard) {
          getNewSUN(tempLoyaltyCard);
        }
        setActiveLoyaltyCard(tempLoyaltyCard);
        setIsStoredLoyaltyCard(true); // selected from My Rewards
      } else {
        setIsStoredLoyaltyCard(false);
      }
    }
  }, []);

  // Retrieve all active Reward Cards from 948 API
  useEffect(() => {
    if (loginToken && isStoredLoyaltyCard != null) {
      getAPICardList(
        skin,
        loginToken,
        currencySymbol,
        currencySymbolSide,
        appLanguage,
        brandingContext
      ).then((apiCardList) => {
        if (apiCardList.status === "expiredLoginToken") {
          userRoleContext.handleLoginTokenExpiration();
        } else if (apiCardList.error) {
          history.push("/dashboard");
        } else {
          // Get initial SUNs for all reward cards
          setLoyaltyCards(apiCardList);

          if (!isStoredLoyaltyCard) {
            setActiveLoyaltyCard(apiCardList[0]);
          }
        }
      });
    }
  }, [isStoredLoyaltyCard]);

  useEffect(() => {
    if (loyaltyCards && !loyaltyCards.error) {
      loyaltyCards.forEach((card) => {
        if (isStoredLoyaltyCard) {
          if (!(card.serial === activeLoyaltyCard.serial && !!activeLoyaltyCard.SUN)) {
            getNewSUN(card);
          } else {
            card.SUN = activeLoyaltyCard.SUN;
            card.preferredCodeFormat = activeLoyaltyCard.preferredCodeFormat;
          }
        } else {
          getNewSUN(card);
        }
      });
    }
  }, [loyaltyCards]);

  const [allCardsHaveSUN, setAllCardsHaveSUN] = useState(false);

  // If all cards have a SUN, but for some reason the selected loyalty card doesn't, call the API again
  useEffect(() => {
    if (loginToken && activeLoyaltyCard && allCardsHaveSUN) {
      if (!("SUN" in activeLoyaltyCard)) {
        getNewSUN(activeLoyaltyCard);
      } else {
        setCurrentSUNNumber(activeLoyaltyCard.SUN);
      }
    }
  }, [activeLoyaltyCard, allCardsHaveSUN]);

  // Check the number of SUNs generated, to see if all loyalty cards have a SUN
  useEffect(() => {
    if (loyaltyCards && currentSUNNumber) {
      const cardsWithSUN = loyaltyCards.filter((card) => card.SUN);

      if (cardsWithSUN.length >= loyaltyCards.length) {
        setAllCardsHaveSUN(true);
      }
    }
  }, [loyaltyCards, currentSUNNumber]);

  // Retrieve account info from 950 API
  const [storedMaskedCCInfo, setStoredMaskedCCInfo] = useState(null);

  useEffect(() => {
    if (storedMaskedCCInfo && history.location.hash === "#refresh-add-money") {
      history.push("/pay-in-store/add-money");
    }
  }, [storedMaskedCCInfo]);

  const updateActiveLoyaltyCard = (card) => {
    document.getElementById("reward-cards-dropdown").value = card.maskedNumber;
    setActiveLoyaltyCard(JSON.parse(card));
    sessionStorage.setItem(skin + "__activeLoyaltyCard", card);
  };

  const isScanInStoreNestedRoute = window.location.hash.includes("/pay-in-store/");

  return (
    <>
      {deviceWidth < 660 && (
        <AppHeader
          pageHeading={appLabels["account"]["pay-in-store"]}
          isHomeLink={true}
          isBackButton={true}
          //backButtonDestination="/dashboard"
        />
      )}

      {activeLoyaltyCard && activeLoyaltyCard.SUN && !reloadScreen && allCardsHaveSUN ? (
        <>
          {deviceWidth < 660 && (
            <main className="main-content scan-in-store-main-content">
              <div className={deviceWidth >= 660 ? "desktop-container" : ""}>
                <div className="scan-in-store__active-card-info-and-qr-code-info">
                  <ScanInStoreActiveCardInfo
                    loyaltyCards={loyaltyCards}
                    activeLoyaltyCard={activeLoyaltyCard}
                    updateActiveLoyaltyCard={updateActiveLoyaltyCard}
                    apiMerchantRewards={apiMerchantRewards}
                  />
                  <ScanInStoreQRCodeInfo
                    activeLoyaltyCard={activeLoyaltyCard}
                    setActiveLoyaltyCard={setActiveLoyaltyCard}
                    currentSUNNumber={currentSUNNumber}
                    setCurrentSUNNumber={setCurrentSUNNumber}
                    reloadScreen={reloadScreen}
                    isAddToWalletServiceEnabled={isAddToWalletServiceEnabled}
                    isGiftCardAutoReloadServiceEnabled={isGiftCardAutoReloadServiceEnabled}
                  />
                </div>

                <ScanInStoreAllCards
                  loyaltyCards={loyaltyCards}
                  setLoyaltyCards={setLoyaltyCards}
                  activeLoyaltyCard={activeLoyaltyCard}
                  updateActiveLoyaltyCard={updateActiveLoyaltyCard}
                />
              </div>
            </main>
          )}
        </>
      ) : (
        !isScanInStoreNestedRoute && <LoadingSpinner />
      )}
      {deviceWidth < 660 && <AppFooter />}
      <Route
        path="/pay-in-store/add-money"
        render={() => (
          <AddMoney
            loyaltyCards={loyaltyCards}
            storedMaskedCCInfo={storedMaskedCCInfo}
            setStoredMaskedCCInfo={setStoredMaskedCCInfo}
            activeLoyaltyCard={activeLoyaltyCard}
            setActiveLoyaltyCard={setActiveLoyaltyCard}
            getNewSUN={getNewSUN}
            setReloadScreen={setReloadScreen}
            isGiftCardAutoReloadServiceEnabled={isGiftCardAutoReloadServiceEnabled}
          />
        )}
      />
      <Route
        path="/pay-in-store/transfer-funds"
        render={() => (
          <TransferFunds
            rewardCards={loyaltyCards}
            selectedRewardCard={activeLoyaltyCard}
            setSelectedRewardsCard={setActiveLoyaltyCard}
            setReloadScreen={setReloadScreen}
            apiMerchantRewards={apiMerchantRewards}
          />
        )}
      />
    </>
  );
};

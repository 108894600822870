import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useWindowSize from "../../_common/hooks/useWindowSize";

/** Contexts */
import UserRoleContext from "../../App/UserRoleContext";

/** UI Components */
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { ActiveOrdersCard } from "./ActiveOrdersCard";
import { NoRecentOrders } from "./NoRecentOrders";

export const ActiveOrders = (props) => {
  const {
    recentOrders,
    appLabels,
    appLanguage,
    activeOrdersLength,
    formattedLastUpdateTimeStamp,
    formatOrderDateAndTime,
    lastUpdateTimeStamp,
    isOrderStatusEnabled,
  } = props;

  const deviceWidth = useWindowSize().width;
  const history = useHistory();

  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  useEffect(() => {
    if (loginToken === null) {
      history.push("/login-register");
    }
  }, [loginToken]);

  return (
    <>
      {recentOrders == null && (
        <span className="recent-order__loading">
          <LoadingSpinner />
          <div className="recent-order__loading-text">
            {deviceWidth < 600 ? (
              <p>
                {appLabels["order"]["active-orders-loading"].slice(0, 11)}
                <br />
                {appLabels["order"]["active-orders-loading"].split("!").pop("!")}
              </p>
            ) : (
              <p>{appLabels["order"]["active-orders-loading"]}</p>
            )}
          </div>
        </span>
      )}
      {recentOrders !== true && activeOrdersLength < 1 && recentOrders !== null && (
        <NoRecentOrders />
      )}
      {/** Orders in progress */}
      {recentOrders &&
        !!recentOrders.filter(
          (order) =>
            order.orderStatus &&
            order.orderStatus.isEnabled &&
            order.orderStatus.isFinished !== true
        ).length &&
        isOrderStatusEnabled && (
          <>
            <p className="recent-order__section-header">
              {appLabels["account"]["order-status-last-update-time"].replace(
                "[time-stamp]",
                formattedLastUpdateTimeStamp(lastUpdateTimeStamp)
              )}
            </p>
            {recentOrders
              .filter(
                (order) =>
                  order.orderStatus &&
                  order.orderStatus.isEnabled &&
                  order.orderStatus.isFinished !== true
              )
              .map((order) => (
                <React.Fragment key={order.id}>
                  <ActiveOrdersCard
                    order={order}
                    appLanguage={appLanguage}
                    activeOrdersLength={activeOrdersLength}
                    appLabels={appLabels}
                    formatOrderDateAndTime={formatOrderDateAndTime}
                  />
                </React.Fragment>
              ))}
          </>
        )}
    </>
  );
};

import React, { useState, useContext } from "react";

import { getAPICardBalance } from "./apiHelpers/getAPICardBalance";

import AppSettingsContext from "../../../../App/AppSettingsContext";
import AppLanguageContext from "../../../../App/AppLanguageContext";
import BrandingContext from "../../../../App/BrandingContext";
import AppLabelsContext from "../../../../App/AppLabelsContext";

import { ReactComponent as IconRevert } from "../../../../_common/icons/IconRevert.svg";

import { Form } from "../../../../_common/Form/Form";
import { FormInput } from "../../../../_common/Form/FormInput";
import { FormReCAPTCHA } from "../../../../_common/Form/FormReCAPTCHA";

export const AddAnotherGiftCard = (props) => {
  const {
    merchantConfig,
    appliedGiftCards,
    updateAppliedGiftCard,
    backToRegisteredCard,
    isRemainderPayment,
    isUnregistered,
  } = props;

  const brandingContext = useContext(BrandingContext);
  const secondaryColor = brandingContext["secondary-colour"];

  const skin = merchantConfig.skin;
  const isPINRequired = merchantConfig.merchant.I39 === "t";
  const isPSDIndicator = merchantConfig.merchant.I41;
  const isReCaptcha = merchantConfig.merchant.I26 === "t";

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [apiError, setAPIError] = useState("");
  const [isAPISubmitting, setAPISubmitting] = useState(false);

  const appLabels = useContext(AppLabelsContext);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  const checkBalance = (formData) => {
    setAPISubmitting(true);

    let givexNumber;
    let pin = "";
    const psdIndicator = isPSDIndicator || "";
    let reCAPTCHA;

    formData.forEach((fieldData) => {
      if (fieldData.name === "giftcard-number") {
        givexNumber = fieldData.value;
      } else if (fieldData.name === "giftcard-pin-number") {
        pin = fieldData.value;
      } else if (fieldData.name === "reCAPTCHA") {
        reCAPTCHA = fieldData.value;
      }
    });

    getAPICardBalance(
      skin,
      givexNumber,
      pin,
      psdIndicator,
      reCAPTCHA,
      appLabels["form"],
      appSettings["currency-symbol"],
      appSettings["currency-symbol-side"],
      appLanguage
    ).then((apiGiftCard) => {
      if (typeof apiGiftCard === "string") {
        // Failed to retrieve gift card's balance due to specific API errors
        setAPISubmitting(false);
        setAPIError(apiGiftCard);
        setIsAPISubmitValid(false);
      } else if (apiGiftCard) {
        // Successfully retrieve gift card's balance
        setAPISubmitting(false);

        let isCardAlreadyApplied = false;

        if (appliedGiftCards && appliedGiftCards.length > 0) {
          appliedGiftCards.forEach((appliedGiftCard) => {
            const isoSerialToBeApplied = apiGiftCard.iso + apiGiftCard.serial;
            const appliedIsoSerial = appliedGiftCard.iso + appliedGiftCard.serial;

            if (appliedIsoSerial === isoSerialToBeApplied) {
              isCardAlreadyApplied = true;
            }
          });
        }

        if (isCardAlreadyApplied) {
          setIsAPISubmitValid(false);
          setAPIError(appLabels["order"]["card-has-already-been-applied-message"]);
        } else {
          const tempAPIGiftCard = { ...apiGiftCard };
          tempAPIGiftCard.fullCardNumber = givexNumber;
          updateAppliedGiftCard(tempAPIGiftCard);
          setIsAPISubmitValid(true);
        }
      }
    });
  };

  return (
    <>
      <Form
        id="form--apply-giftcard"
        className="form--apply-giftcard"
        submitButtonText={appLabels["order"]["apply-gift-card-to-order-button"]}
        submitButtonSuccessText="Applying..."
        submitForm={checkBalance}
        submitAPIError={apiError}
        resetAPIError={() => setAPIError("")}
        isAPISubmitValid={isAPISubmitValid}
        isAPISubmitting={isAPISubmitting}
        submitButtonWrapperClass=" gift-card__apply-button-wrapper"
        submitButtonCustomClass="button--intermediary-submit gift-card__apply-button"
      >
        <FormInput
          type="text"
          label={appLabels["order"]["gift-card-number"]}
          id="giftcard-number"
          name="giftcard-number"
          isRequired={true}
        />
        {isPINRequired && (
          <FormInput
            type="text"
            label={appLabels["order"]["pin-number"]}
            id="input--giftcard-pin"
            name="giftcard-pin-number"
            isRequired={true}
          />
        )}
        {isReCaptcha && <FormReCAPTCHA name="reCAPTCHA" isRequired={true} />}
      </Form>
      {!isRemainderPayment && !isUnregistered && (
        <div className="expanded-payment-method__footer">
          <button
            onClick={backToRegisteredCard}
            className="button expanded-payment-method__footer-button"
            style={{ color: secondaryColor }}
            type="button"
          >
            <IconRevert style={{ stroke: secondaryColor }} aria-hidden="true"/>
            <span>{appLabels["order"]["back-to-registered-gift-card"]}</span>
          </button>
        </div>
      )}
    </>
  );
};

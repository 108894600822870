import { useState, useEffect } from "react";
import localforage from "localforage";
import {
  formatStoreTimeOverrides,
  getDayOfWeekFromIndex,
} from "../../OnlineOrdering/Locations/helpers/isStoreOpenOrClosed";
import { calculateNextAvailableOrderTime } from "../PaymentHelpers";
const THROTTLE_TIME = 5000; // in ms

function useOrderTypeMinInterval(
  activeStoreVexilorConfig,
  activeOrderType,
  skin,
  activeOrderStore
) {
  function getMinInterval() {
    localforage.getItem(skin + "__orderDate").then((orderDate) => {
      let activeOrderDate = new Date();
      if (orderDate) {
        // convert date number back to date in ms
        const month = orderDate.value.split("-")[0];
        const date = orderDate.value.split("-")[1];
        activeOrderDate.setMonth(month, 1); // 1 is a dummy value to prevent JS from skipping months without the current date
        activeOrderDate.setDate(date);
      }
      const formattedStoreOverrides =
        activeOrderType === "dinein"
          ? []
          : formatStoreTimeOverrides(
              activeOrderType === "delivery"
                ? activeStoreVexilorConfig.delivery_min_interval_override
                : activeStoreVexilorConfig.pickup_min_interval_override,
              activeOrderDate
            );

      let currentMinInterval =
        activeOrderType === "delivery"
          ? activeStoreVexilorConfig.delivery_min_interval
          : activeStoreVexilorConfig.pickup_min_interval;

      // change done to match next date and calculated valid date.
      let currentTime = new Date();
      let nextValidDate = calculateNextAvailableOrderTime(activeOrderStore, activeOrderType, skin)
      if (currentTime.toString() < nextValidDate.toString()) {
        currentTime = nextValidDate
      } else {
        currentTime.setDate(activeOrderDate.getDate());
      }
      const storeOverrideForOrderTime =
        Object.keys(formattedStoreOverrides).length === 0
          ? []
          : formattedStoreOverrides[getDayOfWeekFromIndex(currentTime.getDay())];
      for (let i = 0; i < storeOverrideForOrderTime.length; i++) {
        const override = storeOverrideForOrderTime[i];
        if (
          currentTime.getTime() >= override.start.getTime() &&
          currentTime.getTime() <= override.end.getTime() &&
          override.leadTime > 0
        ) {
          currentMinInterval = override.leadTime;
          break;
        }
      }
      setMinInterval(currentMinInterval);
    });
  }

  const [minInterval, setMinInterval] = useState(getMinInterval());
  useEffect(() => {
    const interval = setInterval(() => {
      getMinInterval();
    }, THROTTLE_TIME);

    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    return () => clearInterval(interval);
  }, []);

  return minInterval;
}

export default useOrderTypeMinInterval;

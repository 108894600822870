import { callAPI } from "../../../_common/Api";
import he from "he";

export const getRegistrationForm = async (skin, isLoyaltyEnabled, appLabels, language) => {
  const api1024Data = await callAPI(skin, "dc_1024", {
    params: [language, "1024", "mqid", "mqpass", "customer"],
    id: "1024",
  });

  if (api1024Data.result.I1 === "0") {
    const formFields = [];

    api1024Data.result.I2.forEach((field, index) => {
      let modifiedField = {};
      modifiedField.fieldName = field[0];

      //card number filed
      if (index === 0 && isLoyaltyEnabled) {
        modifiedField.fieldLabel = appLabels["login-register"]["rewards-card-question"];
      } else {
        modifiedField.fieldLabel = he.decode(field[1]);
      }

      modifiedField.isEnabled = field[2] === "t";
      modifiedField.isRequired = field[3] === "t";
      modifiedField.formIndex = index;

      formFields.push(modifiedField);
    });

    //place the card number field at the end
    const cardNumberField = formFields.splice(0, 1);
    formFields.push(cardNumberField[0]);

    //place country before province/state
    const countryField = formFields.splice(11, 1);
    formFields.splice(9, 0, countryField[0]);

    return formFields;
  } else {
    return null;
  }
};

export const orderLabels = {
  "dashboard-section-heading": "Commander en ligne",
  "select-store-for-menu-modal-message": "Veuillez sélectionner un établissement pour voir le menu",
  "select-order-time-for-menu-modal-message":
    "Veuillez choisir une heure de commande valide pour voir le menu",
  "closest-store": "Établissement le plus proche de chez vous",
  "select-store": "Sélectionner un établissement",
  "find-store": "Trouver un établissement",
  pickup: "À EMPORTER",
  delivery: "Livraison",
  "dine-in": "Repas sur place",
  "select-time": "Choisir une heure",
  "no-future-dates":
    "Aucune commande ne peut être passée à l'heure actuelle pour cet établissement",
  "pickup-and-delivery-times-disclaimer":
    "Les heures de ramassage et de livraison sont approximatives.",
  featured: "En vedette",
  "select-order-time-for-featured-modal-message":
    "Veuillez sélectionner une heure de commande valide pour visualiser les articles en vedette",
  "whats-new": "Qu'y a-t-il de nouveau",
  "selected-store": "Établissement sélectionné",
  today: "Aujourd'hui",
  "in-minutes": "Dans [number] minutes",
  "map-overlay-pickup-heading": "Trouver un établissement pour le ramassage",
  "map-overlay-delivery-heading": "Quel est le lieu de livraison?",
  "use-your-location": "Utilisez votre emplacement",
  "or-enter-an-address": "Ou entrez une adresse",
  "maps-searchbox-placeholder": "Entrez la ville, le code postal ou le code ZIP…",
  "previously-found-stores": "Précédent:",
  "order-here": "Commander ici",
  "order-ahead": "Commander à l'avance",
  "list-view": "Affichage de la liste",
  "map-view": "Vue cartographique",
  "more-store-info-button": "Info",
  "close-more-store-info-button": "Retour arrière",
  menu: "Menu",
  favorites: "Favoris",
  "no-favorites-message": "Vous n'avez sauvegardé aucun favori.",
  "remove-favorite-confirmation": "Êtes-vous sûr de vouloir retirer cet article de vos favoris?",
  "view-favorite-item": "Visualiser l'article",
  calories: "Cal",
  "select-options": "Sélectionner les options",
  "modify-this-item": "Modifier cet article",
  "add-extras": "Ajouter des ingrédients",
  "special-instructions": "Directives spéciales",
  "add-to-order": "Confirmer [item-quantity] article(s)",
  "add-another": "En ajouter un autre",
  "menu-disclaimer": "",
  "menu-item-disclaimer": "",
  added: "Ajouté",
  "save-changes": "Sauvegarder les changements",
  "button-save-changes-success": "Sauvegardés",
  "recent-orders": "Commandes récentes",
  reorder: "Commander de nouveau",
  "view-one-item": "Visualiser 1 article",
  "view-multiple-items": "Visualiser [number] articles",
  "view-cart": "Visualiser le panier",
  "back-to-menu": "Revenir au menu",
  "review-order": "Passer en revue la commande",
  "your-order-details": "Les détails de votre commande",
  "items-in-the-cart": "article(s) dans le panier",
  "discount-and-coupon-button": "Rabais/Coupon",
  "discount-code": "Code rabais",
  "check-discount": "Vérifier le rabais",
  "coupon-code": "Code coupon",
  "check-coupon": "Vérifier le coupon",
  "discount-remove-confirmation": "Êtes-vous sûr de vouloir retirer ce code rabais?",
  "use-rewards": "Utiliser les récompenses",
  discount: "Rabais",
  coupon: "Coupon",
  "apply-discount-coupon": "Appliquer",
  "invalid-discount-coupon-error": "Le code rabais n'est pas valide.",
  "only-one-discount-reward-error":
    "Un seul rabais / récompense peut être appliqué à votre commande. Veuillez supprimer le rabais actuellement appliqué si vous désirez en ajouter un différent.",
  tax: "Taxe",
  "add-tip": "Ajouter le pourboire",
  "no-tip": "Aucun pourboire",
  tip: "Pourboire",
  "custom-amount": "Montant personnalisé",
  modify: "Modifier",
  "remove-all-items": "Supprimer tous les articles",
  "add-more-items": "Ajouter plus d'articles",
  "empty-cart-message": "Votre panier d'achat est vide.",
  "begin-checkout": "Passer à la caisse",
  "customer-info-form-heading": "Confirmons les coordonnées pour votre commande",
  "order-contact-confirm": "Suivant",
  "order-contact-confirming": "Suivant…",
  "pickup-information": "Renseignements sur le ramassage",
  "review-order-disclaimer": "",
  "pickup-information-subheading": "",
  payment: "Paiement",
  "use-another-credit-card": "Utiliser une autre carte de crédit",
  "saved-credit-card-heading": "Carte de crédit sauvegardée",
  "return-to-saved-credit-card": "Revenir à la carte de crédit sauvegardée",
  "replacing-saved-credit-card-confirmation":
    "Vous avez déjà une carte de crédit de sauvegardée. Êtes-vous sûr de vouloir supprimer [card-number]?",
  "card-balance": "Solde de la carte",
  "apply-gift-card-to-order-button": "Appliquer",
  "registered-gift-card": "Carte-cadeau enregistrée",
  "back-to-registered-gift-card": "Revenir à la carte-cadeau enregistrée",
  "apply-rewards-to-order-button": "Appliquer",
  "use-another-gift-card": "Utiliser une autre carte-cadeau",
  "gift-card-number": "Numéro de la carte-cadeau",
  "pin-number": "NIP",
  "no-active-gift-cards-message": "Vous n'avez pas de cartes actives à l'heure actuelle.",
  "order-total": "Total de la commande",
  "remaining-balance-on-gift-card": "Montant restant sur la carte",
  "pay-remainder-with-credit-card": "Payer le reste à l'aide d'une carte de crédit",
  "amount-due": "Montant dû",
  "card-has-already-been-applied-message": "Cette carte a déjà été appliquée",
  "pay-on-pickup": "Payer lors du ramassage",
  "pay-on-delivery": "Payer à la livraison",
  "submit-order-button": "Exécuter la commande",
  "submit-order-button-processing": "Commande en cours d'exécution…",
  "payment-disclaimer": "",
  "order-too-close-to-closing-time-error":
    "Nous sommes au regret de vous informer que nous ne pouvons accepter votre commande pour l'instant. Les commandes en ligne ne sont pas acceptées après ou peu avant",
  "select-order-time-passed-message":
    "Votre heure de commande sélectionnée est écoulée. La prochaine heure de commande disponible serait",
  "confirm-updated-order-time-message":
    "Aimeriez-vous poursuivre avec l'heure de commande mise à jour?",
  "order-confirmation": "Confirmation de la commande",
  "order-thank-you-message": "Merci de votre commande",
  "your-order-number-is": "Le numéro de votre commande est",
  "we-sent-you-confirmation-email": "Nous vous avons adressé un courriel de confirmation.",
  "order-confirmation-disclaimer": "",
  "item-for-pickup-at": "[quantity] article pour [order-type] à",
  "items-for-pickup-at": "[quantity] articles pour [order-type] à",
  "item-for-delivery-or-dinein-from": "[quantity] article pour [order-type] de",
  "items-for-delivery-or-dinein-from": "[quantity] articles pour [order-type] de",
  "item-for-dine-in-at": "[quantity] article pour [order-type] à",
  "items-for-dine-in-at": "[quantity] articles pour [order-type] à",
  "order-type-change-confirmation":
    "Êtes-vous sûr de vouloir remplacer votre commande par [order-type]?",
  "order-store-change-confirmation":
    "Êtes-vous sûr de vouloir remplacer l'établissement par [order-store]?",
  "your-current-order-will-be-cleared": "Votre commande actuelle sera effacée.",
  "cart-item-removal-confirmation":
    "Êtes-vous sûr de vouloir retirer l'article [item-name] du panier?",
  "cart-item-removal-discount-also-removed-message":
    "Le retrait de cet article entraînera du même coup le retrait du code rabais qui y est associé",
  "order-type-order-date-at-order-time": "[order-type] [order-date] à [order-time]",
  "delivery-shipping-form-submit-button": "Confirmer",
  "delivery-shipping-form-submit-button-processing": "Confirmation en cours…",
  "no-pickup-stores-within-range-message":
    "Aucun ramassage établissement dans un rayon de [stores-range] km de votre emplacement.",
  "distance-to-store": "Distance [distance-from-user] km",
  "store-open-status": "Ouvert",
  "store-closed-status": "Fermé",
  "store-open-until-closing-time": "Fermeture à [closing-time]",
  "no-store-menu-message": "Aucun menu trouvé pour cet établissement",
  "select-up-to-n-items": "Sélectionner jusqu'à [max-quantity] article(s) (Facultatif)",
  "select-n-items": "Sélectionner [min-quantity] articles",
  "select-n-item": "Sélectionner [min-quantity] article",
  "select-between-n-and-n-items": "Sélectionner entre [min-quantity] et [max-quantity] articles",
  "calories-disclaimer":
    "Les adultes et les adolescents (âgés de 13 ans et plus) ont besoin de 2 000 calories par jour en moyenne et les enfants (âgés de 4 à 12 ans) ont besoin en moyenne de 1 500 calories par jour. Cependant, les besoins individuels varient.",
  "button-add-to-combo-group-choice": "Ajouter au choix [item-group-id]",
  "button-add-to-combo-group-choice-success": "Ajouté",
  "button-save-combo-choices": "Sauvegarder les changements",
  "button-save-combo-choices-success": "Sauvegardés",
  "combo-choice": "Choix",
  "combo-summary": "Sommaire",
  "combo-choose-item": "Choisir un article à partir des options ci-dessous",
  "add-gift-card": "Ajouter une carte-cadeau",
  "add-another-gift-card": "Ajouter une autre carte-cadeau",
  "complete-order-pay-on-pickup-blurb":
    "Terminez votre commande ci-dessous et payez au moment du ramassage à l'établissement.",
  "complete-order-pay-on-delivery-blurb":
    "Terminez votre commande ci-dessous et payez au moment de la livraison.",
  "bring-coupon-notice": "N'oubliez pas d'apporter votre coupon [balance]!",
  "complete-order-see-you-soon": "À bientôt!",
  "recent-order-item-modifier-heading": "Modifié",
  "recent-order-item-addon-heading": "Ajouté",
  "recent-order-unavailable-item-notification": "Un ou plusieurs articles ne sont plus disponibles",
  "recent-order-price-change-notification":
    "* Le sous-total de la commande précédente peut différer étant donné que les prix peuvent fluctuer",
  "card-autoreload-success-message":
    "Vous avez réglé avec succès le rechargement automatique [card-number] pour le montant de [amount]",
  "card-autoreload-when-balance-reaches": "lorsque le solde atteindra",
  "card-autoreload-weekly-frequency": "chaque semaine",
  "card-autoreload-monthly-frequency": "chaque mois",
  "add-money-add-credit-card": "Ajouter une carte de crédit",
  "pay-in-store-card-expiry": "Expire le",
  "qr-code": "Code QR",
  "pay-in-store-how-to-heading": "Prêt à installer le passage à la caisse exprès en établissement?",
  "pay-in-store-how-to-blurb-1":
    "Ajoutez une carte à votre compte pour pouvoir profiter du passage à la caisse exprès en établissement",
  "pay-in-store-how-to-blurb-2":
    "Vous pouvez ajouter plusieurs cartes à votre compte, vérifier le solde et ajouter de l'argent ou régler le rechargement automatique.",
  "pay-in-store-add-new-card": "Ajouter une nouvelle carte",
  "complete-order-pay-with-paypal":
    "Après avoir cliqué sur « Exécuter la commande », vous serez redirigé vers PayPal pour achever votre commande en toute sécurité.",
  "cart-removing-items-with-out-of-stock-addons-message":
    "Articles retirés avec des ajouts en rupture de stock.",
  "cart-removing-item-with-out-of-stock-addons-message":
    "[item-name] retiré avec ajouts en rupture de stock.",
  "item-detail-addons-out-of-stock-error":
    "[number] ajouts demandés sont maintenant en rupture de stock",
  "item-detail-addon-out-of-stock-error": "L'ajout demandé est maintenant en rupture de stock.",
  "dashboard-primary-order-button": "Commander maintenant",
  "dashboard-disclaimer": "",
  "dine-in-qr-scanner-instructions":
    "Scanner le code QR en vous assurant qu'il tienne dans le carré ci-dessous OU saisissez le numéro de table.",
  "complete-order-pay-later-dine-in-blurb":
    "Terminer votre commande et payer à la fin de votre repas.",
  "pay-later": "Payer plus tard",
  "show-menu-button": "Afficher le menu",
  "show-menu-button-processing": "Récupération du menu...",
  "table-number-field-label": "Numéro de table",
  "invalid-qr-code": "Code QR incorrect",
  "camera-access-not-supported":
    "Ce navigateur ne permet pas l'accès à l'appareil photo. Nous vous suggérons d'utiliser Safari pour cette fonction.",
  "invalid-coupon-number": "Numéro de bon incorrect",
  "back-to-combo-item-name": "Retour à [combo-item-name]",
  "confirm-modified-combo-changes-button": "Confirmer les modifications",
  "no-delivery-stores-within-range-message":
    "Aucun livraison établissement dans un rayon de [stores-range] km de votre emplacement.",
  "order-before-store-opening-time-error":
    "Nous sommes au regret de vous informer que nous ne pouvons accepter votre commande pour l'instant. Nous sommes fermés",
  "confirm-summary-details": "Confirmer les informations",
  "coupon-remove-confirmation": "Etes-vous sûrs de vouloir supprimer ce bon?",
  "applied-discount-code": "Code promo/rabais effectué",
  "applied-coupon-code": "Code de bon utilisé",
  asap: "Dès que possible",
  "minimum-delivery-amount-message":
    "La commande doit être d'au minimum [delivery-minimum-amount] pour être livrée",
  quantity: "Quantité",
  "check-balance": "Vérifier le solde",
  "out-of-stock": "Indisponible",
  "remove-item-from-favorites": "Supprimer [item-name] des favoris",
  "add-item-to-favorites": "Ajouter [item-name] aus favoris",
  "go-to-item-name": "Aller aux détails de la'article [item-name]",
  "go-to-whats-new-image-link": "Aller à [image-link]",
  "remove-item-from-cart": "Supprimer [item-name] du panier",
  "modify-item-in-cart": "Modifier [item-name]",
  "decrease-quantity": "Réduire la quantité",
  "increase-quantity": "Augmenter la quantité",
  "remove-tip": "Supprimer le pourboire",
  "show-category-subcategories": "Afficher les sous-catégories de la catégorie [category-name]",
  "show-subcategory-items": "Afficher les articles de la sous-catégorie [subcategory-name]",
  "close-item-details": "Fermer les détails de l'article [item-name]",
  "back-to-item": "Retour aux détails de l'article [item-name]",
  "open-options-group-options": "Ouvrir les options [option-group-name]",
  "select-payment-type": "Chosir [payment-type] pour le paiement",
  "remove-applied-giftcard": "Supprimer la carte-cadeau utilisée",
  edit: "Modifier",
  "delivering-to-address": "Livraison à",
  "edit-delivery-address": "Modifier l'adresse de livraison",
  "order-time-is-blocked":
    "Votre heure de livraison n'est plus disponible. Veuillez en choisir une nouvelle",
  "ordering-from": "Commande depuis",
  "select-another-store-prompt": "Choisir une autre succursale pour afficher le menu",
  "select-a-store-prompt": "Choisir une succursale pour afficher le menu",
  "discount-disclaimer": "",
  "coupon-disclaimer": "",
  "complete-your-order-below":
    "Terminez votre commande ci-dessous et payez au moment du ramassage à l'établissement.",
  "invalid-reward": "Récompense invalide",
  "price-based-on-selection": "Prix en fonction des choix",
  "total-combo-price-disclaimer":
    "Le prix total de la combinaison est calculé dans le panier d'achat",
  "order-type": "Type de commande",
  "number-of-required-choices": "[number-of-choices] Sélection(s) requise(s)",
  "minutes-short-hand": "min",
  "guest-attempt-favorite-item": "Veuillez vous connecter pour ajouter des articles à vos favoris",
  "favorite-add-to-order": "Confirmer article",
  "read-more": "Lire la suite",
  "read-less": "Lire moins",
  "ios-camera-disclaimer":
    'Pour connaître le menu, veuillez:[line-break]Sélectionner la fonction "Utilisez ma position" ci-dessous, ou[line-break]Scanner un code QR code à l\'aide de la caméra de votre appareil.',
  "lets-find-store-for-order-type": "Trouver un magasin pour [order-type]",
  "lets-find-store-blurb":
    "Veuillez saisir votre adresse, permettre à l'application d'accéder à votree position, ou choisir la succursale la plus proche dans la liste ci-dessous",
  "unit-can-be-added": "Le numéro d'appartement peut être rajouté au moment du paiement",
  "use-my-location": "Utiliser ma position",
  "select-store-all": "Tous",
  "select-store-nearby": "Proche",
  "select-store-favorites": "Favoris",
  "select-store-hours-info": "Horaires et info",
  "select-store-get-direction": "Comment y aller",
  "select-store-select-store": "Choisir cette succursale",
  "guest-attempt-favorite-location":
    "Veuillez vous identifier pour ajouter la succursale à vos favorites",
  "no-favorite-locations-blurb":
    "Taper/cliquer le cœur pour ajouter la succursale à vos favorites pour en faciliter l'accès futur",
  "no-nearby-locations-blurb":
    "Pour chercher les succursales les plus proches, veuillez saisir votre adresse ou cliquer sur 'Utiliser ma position'",
  "time-selection-panel-header": "Choisissez la date et l'heure [order-type]",
  "no-delivery-stores-within-range": "Aucune succursale acceptant les livraisons n'est disponible",
  "order-type-selection-blurb": "Comment souhaitez-vous commander",
  "type-in-address": "Rue et ville",
  "location-not-in-delivery-range": "La livraision à cet emplacement n'est pas possible",
  "no-nearby-locations-blurb-delivery":
    "Pour chercher les succursales les plus proches, veuillez saisir l'adresse de livraison ou cliquer sur 'Utiliser ma position'",
  "price-change-error-message":
    "Veuillez noter que les prix des articles de votre panier a été mis à jour pour refléter le menu actuel",
  "items-removed-from-menu-error-message":
    "Un ou des articles n'est plus disponible à la commande pour cette date et heure.",
  "dine-in-use-location-instructions":
    "Veuillez scanner le code QR ou autoriser l'application à utiliser votre emplacement pour continuer.",
  "dine-in-not-in-location-error":
    "Vous devez vous trouver au restaurant pour pouvoir commander sur place.",
  "dine-in-use-location-greetings":
    "Bienvenue chez [merchant_name]. Entrez votre numéro de table ci-dessous pour voir le menu.",
  "dine-in-scan-qr-code": "Scanner le code QR",
  "dinein-information-special-instructions": "Information pour commande sur place",
  "invalid-item-quantity-error": "Il n'y a plus de stock pour cet article",
  "item-not-available": "Cet article est actuellement indisponible",
  "app-rating-header": "Comment a été votre expérience avec l'application aujourd'hui?",
  "app-rating-thumbs-up": "Avis favorable",
  "app-rating-thumbs-down": "Avis défavorable",
  "merchant-email-subject": "Réactions des clients pour [marchant-name] CWS5",
  "merchant-email-body-template-line-1":
    "Nous vous remercions de nous avoir contactés. Veuillez nous faire savoir comment nous pouvons nous améliorer pour la prochaine fois. Si vous avez rencontré un problème technique, veuillez ajouter ci-dessous tous les les détails et les étapes qui vous ont conduit à rencontrer le problème.",
  "merchant-email-do-not-remove": "***Veuillez ne pas enlever***",
  "merchant-email-username": "Nom d'utilisateur",
  "merchant-email-guest-user": "Utilisateur invité",
  "merchant-email-device-type": "Type d'appareil",
  "merchant-email-os-version": "Version OS:",
  "merchant-email-description-of-issue": "Description du problème",
  "merchant-email-steps-taken": "Étapes qui ont conduit à la rencontre du problème.",
  "merchant-email-information-used-to-help":
    "Ces informations sont utilisées pour identifier et résoudre les problèmes techniques.",
  "merchant-email-enter-details": "<entrez les détails ici>",
  "cart-out-of-stock-item-error-message":
    "Malheureusement, [item-name] n'est plus disponible. Il a été retiré de votre panier.",
  "pickup-information-special-instruction-placeholder": "",
  "submit-order": "Passer la commande",
  "order-and-pay": "Commander & Payer",
  checkout: "Payer",
  "select-payment": "Choisir un paiement",
  "ready-to-pay": "Prêt à payer?",
  "view-bill": "Voir la facture",
  "ready-to-pay-blurb": "Vérifier, partager et payer votre facture ici",
  bill: "Facture",
  "confirmation-email": "Courriel de confirmation",
  "confirmation-email-blurb": "Soouhaitez-vous une copie du reçu par courriel?",
  table: "Table",
  "ordered-together": "Commandés en même temps",
  seat: "Siège [seat-number]",
  "submitting-order": "Commande en cours...",
  "payment-confirmation": "Confirmation du paiement",
  "thank-you-for-payment": "Merci de votre paiement",
  "email-receipt-blurb": "Nous avons envoyé la confirmation à [email]",
  "payment-confirmation-blurb": "C'est tout! Nous espérons vous revoir bientôt.",
  "submit-payment-button": "Terminer le paiement",
  "submit-payment-button-processing": "Paiement en cours",
  "review-items": "Vérifier les articles",
  "items-not-ready-for-billing":
    "Vous y êtes presque! Nous préparons votre commande et vous pourrez bientôt payer.",
  "bill-page-no-order-placed-yet": "Aucune commande disponible pour cette table.",
  refresh: "Rafraîchir",
  "bill-api-error": "Une erreur s'est produite. Veuillez en parler avec votre serveur.se",
  "back-to-home": "Retour à l'accueil",
  "view-details": "Afficher les détails",
  "hide-details": "Cacher les détails",
  "our-picks-for-you": "Nos suggestions pour vous",
  customize: "Personnaliser",
  "ordered-from": "Commandé de",
  "delivered-to": "Livré à",
  "points-earned": "Points gagnés",
  "points-redeemed": "Points encaissés",
  "charged-to": "Facturé à",
  "active-orders": "Commandes en cours",
  "past-orders": "Commandes passées",
  "no-orders-yet": "Aucune commande",
  "view-menu": "Voir le menu",
  "browse-menu": "Parcourir le menu et démarrer une commande",
  "confirm-reorder-details":
    "Pour commander de nouveau, veuillez confirmer les détails de votre commande!",
  "active-orders-loading": "Plus que quelques instants! Nous récupérons vos commandes en cours",
  "past-orders-loading": "Plus que quelques instants! Nous récupérons vos commandes passées",
  "addon-no-thank-you-option": "Non, merci. Passer ces choix",
  "select-n-items-or-more": "Choisissez [n-items] ou plus",
  "date-and-time": "Date & Heure",
  "combo-summary-header": "Résumé de la combinaison",
  "edit-combo-item-arial": "Editer l'article [item-name] de la combinaison",
  "setting-favorite-error":
    "Veuillez remplir les choix suivants avant d'ajouter l'article à vos favoris",
  oops: "Oups!",
  "reorder-limited-stock-items":
    "Les articles suivants n'ont pas pu être ajoutés à votre panier à cause d'un stock limité",
  "reorder-items-no-longer-available":
    "Les articles suivants n'ont pas pu être ajoutés à votre panier car ils ne sont plus disponibles",
  "reorder-items-out-of-stock-no-longer-available":
    "Il semblerait que les articles suivants n'ont pas pu être ajouté à votre panier car leur stock est limité ou ils sont indisponibles",
  "add-new-item": "Ajouter un article",
  customization: "Personnalisation",
  "remove-all-items-confirmation-modal-message":
    "Etes-vous sûrs de vouloir supprime tous les [item-name]?",
  "choice-n-of-total": "Choix [current-choice] de [total-choices]",
  "hang-tight-processing-payment": "Merci de votre patience! Nous traitons votre paiement",
  "hang-tight-reviewing-order": "Merci de votre patience! Nous traitons votre commande",
  "contacting-bank-to-confirm-payment": "Confirmation du paiement avec votre banque",
  "almost-done-passing-order-to-store":
    "C'est presque fini! Nous envoyons votre commaande à la succursale",
  "almost-done": "C'est presque fini!",
  "do-not-click-back-or-refresh":
    "Veuillez ne pas appuyer sur le bouton de retour en arrière, rafraîchir ou quitter cette page. Cela pourrait interrompre le traitement et/ou créer un deuxième paiement.",
  connecting: "Connection...",
  "complete-order-pay-with-google-pay":
    'Après avoir cliqué sur le bouton "Payer" ci-dessous, vous serez redirigé vers Google Pay pour finaliser votre commande en toute sécurité.',
  "complete-order-pay-with-apple-pay":
    "Après avoir cliqué sur « Vérifier avec [apple-pay-icon] », vous serez redirigé vers Apple Pay pour achever votre commande en toute sécurité.",
  "order-processed-using-digital-payment": "et celle-ci a été traitée avec succès par",
  "google-pay": "Google Pay",
  "apple-pay": "Apple Pay",
  "preparing-bill-for-verification": "Préparation de votre facture pour vérification...",
  "retrieving-bill-details": "Récupération de votre facture...",
  "processing-your-bill": "Traitement de votre facture...",
  "verifying-bill-details": "Vérification des détails de votre facture...",
  "double-checking-your-bill": "Deuxième vérification de votre facture...",
  "confirming-bill-details":
    "Confirmation des détails de votre facture, le traitement de votre paiement est en cours!",
  "processing-your-payment": "Traitement de votre paiement...",
  "all-done": "C'est fait!",
  "delivery-address": "Adresse de livraison",
  "delivery-addresses": "Adresses de livraison",
  "confirm-address": "Confirmer l'adresse",
  "use-my-location-for-delivery": "Utiliser ma position pour l'adresse de livraison",
  "use-my-location-for-pickup":
    "Utiliser ma position pour trouver les succursales les plus proches",
  "confirm-your-pickup-location": "Confirmer la succursale de cueillette",
  "modify-location": "Modifier la succursale",
  "find-address": "Trouver une adresse",
  "primary-delivery-address-message":
    "Souhaitez-vous enregistrer l'adresse de livraison ci-dessous sur votre compte?",
  "set-as-primary-delivery-address": "Définir comme adresse de livraison principale",
  "recommended-address-changes":
    "Veuillez vérifier le(s) changement(s) recommendé(s) et choississez l'option correct.",
  "save-address": "Sauvegarder l'adresse",
  "not-now": "Pas maintenant",
  "your-addresses": "Vos adresses",
  "no-saved-delivery-address": "Il n'y a pas encore d'adresse de livraison sauvegardée.",
  "you-entered": "Vous avez saisi:",
  recommended: "Recommendé:",
  "unable-to-verify-address": "Nous n'avons pas pu vérifier votre adresse!",
  "try-different-address": "Veuillez essayer une autre adresse",
  "store-filter-list": "Liste",
  "store-filter-map": "Carte",
  "verification-issues": "Problèmes de vérification",
  "street-number-not-found": "Numéro de rue introuvable",
  "use-entered-address": "Utiliser saisi",
  "make-changes-to-address": "Faire des changements",
  "street-number-missing": "Le numéro de rue est manquant",
  "confirm-seating": "Confirmer le siège",
  "edit-seating": "Modifier le siège",
  "order-pickup": "Cueillette de commande",
  "stadium-service-not-available": "Ce service n'est pas disponible dans votre section.",
  "in-seat-service": "Service au siège",
  "in-seat-service-time-title": "Sélectionnez l'heure du service au siège",
  "stadium-pickup-time-title": "Sélectionnez l'heure de prise en charge",
  "limit-per-order": "Limité à [qty-limit] par commande",
  "limit-reached": "Limite maximale par commande atteinte",
  "limit-reached-past-order":
    "Ces articles ne peuvent pas être ajoutés à votre panier. Un ou plusieurs articles dépasseront leur limite maximale par commande",
  "meal-voucher": "Chèque Repas",
  "address-already-exists": "L'adresse du client existe déjà",
  "select-dates": "Choisir les dates",
  "select-date-range": "Choisir la plage de dates",
  "load-more": "Charger plus",
  "select-one-bill": "Tout sélectionner (Payer en une facture)",
  "open-calendar": "Ouvrir le calendrier",
  "select-pickup-location": "Sélectionnez le lieu de prise en charge",
  "select-pickup-time": "Sélectionnez l'heure de prise en charge",
  "set-store-location-favorite": "Définir cet emplacement de magasin comme favori",
  "remove-store-location-favorite": "Supprimer cet emplacement de magasin comme favori",
  "edit-order-time": "Modifier les détails de l'heure de la commande",
  "edit-order-type": "Modifier le type de commande",
  "back-to-payment-method-select": "Revenir à la sélection du mode de paiement",
  "pay-with-google-pay": "Payer avec Google Pay",
  "pay-with-apple-pay": "Payer avec Apple Pay",
  "expand-section": "Agrandir la section pour",
  "collapse-section": "Réduire la section pour",
  "remove-discount": "Supprimer la remise/le coupon",
  "item-quantity": "Quantité d'article",
  "add-to-apple-wallet": "Ajouter à Apple Wallet",
  "add-to-google-pay": "Ajouter à Google Pay",
  "expand-order-details": "Ampliar detalles del pedido",
  "collapse-order-details": "Réduire les détails de la commande",
  "remove-promo": "Supprimer la promotion",
  "store-hours": "Heures d'ouverture",
  "edit-ordering-locaiton": "Modifier la commande à partir de l'emplacement",
  "missing-customer-id-error":
    "Pour sécuriser l'utilisation de cette carte bancaire enregistrée, merci de valider vos informations de connexion",
  "missing-customer-id-error-gc":
    "Pour sécuriser l'utilisation de cette carte cadeau enregistrée, merci de valider vos informations de connexion",
  "sorry-it-is-closing-time": "Désolé, c'est l'heure de fermer!",
  "store-closed-message":
    "Nous ne pouvons pas traiter votre commande maintenant car notre heure de fermeture est proche ou passée.",
  "pick-new-time": "Veuillez choisir une nouvelle heure",
  "view-full-menu": "Afficher le menu complet",
  "recent-order-guest-attempt": "Veuillez vous identifier pour afficher les commandes recentes",
  "invalid-direct-url-error": "[order-type] n'est pas disponible actuellement à cet endroit.",
  "guest-attempt-favorite-item-dashboard": "Veuillez vous connecter pour voir vos favoris",
  "order-types": "Types de commandes",
  "customer-info-dialog-message":
    "Veuiilez saisir les informations du client aavant de continuer à la page de paiement.",
  "input-custom-tip-amount": "Saisissez le pourboire personnalisé en",
  "press-tab-to-add-tip":
    "Veuillez appuyer sur laa tabulation pour enregistrer le montant du pourboire.",
  "currently-selected-tip-option": "Actuellement, l'option de pourboire est définie à",
  "currently-selected-category": "La catégorie actuellement choisie est",
  "menu-categories-list": "Liste de catégories du menu.",
  "on-combo-summary-page": "Vous êtes à la page de résumé de la combinaison.",
  "on-step": "Vous êtes à l'étape",
  "select-item-to-continue": "Choisissez un article pour continuer.",
  "go-to-combo-summary-page": "Allez à la page de résumé de la combinaison.",
  "go-to-choice": "Allez au choix",
  "please-select": "Veuillez sélectionner",
  "please-select-payment-method": "Veuillez choisir un méthode de paiement.",
  "select-date-time-current-selected-date":
    "Veuillez choisir une date et heure. La date choisie pour l'instant est",
  "currently-selected-date": "Choisie pour l'instant.",
  "select-time-option-for": "Veuillez choisir un option d'heure pour le",
  "time-option-for": "Option d'heure pour",
  "your-total-is": "Votre total est",
  "on-menu-item-details-page-for": "Sur la page de détails de l'article de menu pour",
  "additional-addons-area-expanded-message":
    "Les suppléments sont agrandis. Veuillez aappuyer sur tabulation pour naviguer est choisir des suppléments.",
  "you-have-n-items-in-cart": "Vous avez [item-quantity] articles dans votre panier.",
  "quantity-in-cart-is": "La quantité dans le panier est",
  "remove-from-order": "Supprimer de la commande [item-name]",
  "shopping-cart": "Panier",
  "decrease-quantity-by-one": "Diminuer la quantité de 1",
  "increase-quantity-by-one": "Augmenter la quantité de 1",
  "checkout-cart-summary-message":
    "Vous êtes sur la page de paiement. Vous avez [cart.quantity] articles dans votre panier.",
  "your-order-subtotal-is": "Votre sous-total est",
  "ordering-for-tomorrow-warning-message":
    "ATTENTION: votre commande est pour le prochain jour ouvré!",
  "ordering-for-tomorrow-info-message":
    "Cette succursale n'accepte plus de commandes pour aujourd'hui.",
  "current-item-quantity-in-cart": "Quantité en cours: [item-quantity] dans le panier.",
  "tip-amount-is": "Le montant du pourboire est [tip-amount]",
  "order-total-is": "Le montant total est [order-total]",
  "item-addons-modifications": "Suppléments ou modifications d'article",
  "confirm-order-date-time": "Confirmer la date et heure de commande.",
  "current-quantity": "Qunatité en cours: [item-quantity]",
};

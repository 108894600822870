import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import useWindowSize from "../_common/hooks/useWindowSize";
import AppLabelsContext from "../App/AppLabelsContext";
import "./Terms.css";

export const Terms = () => {
  const appLabels = useContext(AppLabelsContext);
  const history = useHistory();

  const [termsText, setTermsText] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch("terms/default.html");
        const data = await response.text();

        const sanitizedData = data.replace(/<div>&nbsp;<\/div>/g, ""); // removes unwanted Marqui's auto-inserted RTB spaces

        if (sanitizedData) setTermsText(sanitizedData);
        else history.push("/dashboard");
      } catch (error) {
        console.error(error);
        history.push("/dashboard");
      }
    })();
  }, []);

  const deviceWidth = useWindowSize().width;

  return (
    <>
      {deviceWidth >= 660 && <h1>{appLabels["general"]["terms-and-conditions"]}</h1>}
      {termsText && <div className="content-from-marqui-rtb">{ReactHtmlParser(termsText)}</div>}
    </>
  );
};

import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { callGetApplePaySession } from "../../_common/Api";
import AppLabelsContext from "../../App/AppLabelsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import ManifestContext from '../../App/ManifestContext';

const GCPaymentMethodApplePayButton = (props) => {
  const {
    skin,
    orderTotal,
    appLanguage,
    submitApplePayOrder,
    validateDeliveryDateTime
  } = props;

  const merchantConfig = useContext(MerchantConfigContext).merchant;
  const manifestContext = useContext(ManifestContext)
  const currencyCode = merchantConfig.I34
  const countryCode = merchantConfig.I58
  const appLabels = useContext(AppLabelsContext);

  const onClick = () => {
    let isDeliveryDateTimeValid = null
    isDeliveryDateTimeValid = validateDeliveryDateTime()
    if (isDeliveryDateTimeValid === true) {
      if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
        let request = {
          countryCode: countryCode,
          currencyCode: currencyCode,
          supportedNetworks: ["VISA", "MasterCard", "AMEX", "DISCOVER"],
          merchantCapabilities: ["supports3DS"],
          total: {
            label: manifestContext.name,
            type: "final",
            amount: Number(orderTotal).toFixed(2).toString(),
          },
        };

        let session = new window.ApplePaySession(3, request);
        session.begin();

        session.onvalidatemerchant = (event) => {
          callGetApplePaySession(
            skin,
            "apple_pay_get_session",
            {
              params: [skin, "consumer", appLanguage, event.validationURL],
              id: "apple_pay_get_session",
            },

          )
            .then((merchantSession) => {
              session.completeMerchantValidation(merchantSession.result);
            })
            .catch((err) => {
              console.error("Error fetching merchant session", err);
              session.abort();
            });
        };

        session.onpaymentmethodselected = (event) => {
          const update = {
            newTotal: {
              label: manifestContext.name,
              type: "final",
              amount: Number(orderTotal).toFixed(2).toString(),
            },
          };
          session.completePaymentMethodSelection(update);
        };

        session.onpaymentauthorized = (event) => {
          const paymentToken = event.payment.token;

          const authorizationResult = {
            status: window.ApplePaySession.STATUS_SUCCESS,
            errors: [],
          };

          session.completePayment(authorizationResult);
          submitApplePayOrder(paymentToken);
        };

        session.oncancel = (event) => {
          console.error("Payment Session Cancelled");
        };
      }
    }
  };

  return ReactDOM.createPortal(
    <div className="apple-pay-checkout-button__container">
      <button
        className="apple-pay-button apple-pay-button-black"
        aria-label={appLabels["order"]["pay-with-apple-pay"]}
        onClick={onClick}
        lang={appLanguage}
      ></button>
    </div>,
    document.body
  );
};

export default GCPaymentMethodApplePayButton;

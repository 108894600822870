import React, { useContext } from "react";
import AppLabelsContext from "../../App/AppLabelsContext";
import { LoginRegisterBackButton } from "../LoginRegisterBackButton/LoginRegisterBackButton";

import { ForgotPasswordForm } from "./ForgotPasswordForm";
import "./ForgotPassword.css";
import useWindowSize from "../../_common/hooks/useWindowSize";

export const ForgotPassword = () => {
  const appLabels = useContext(AppLabelsContext);
  const deviceWidth = useWindowSize().width;

  return (
    <div className="forgot-password__main-wrapper">
      {deviceWidth < 660 && <LoginRegisterBackButton />}
      <h1 className="forgot-password__heading">
        {appLabels["login-register"]["forgot-password-heading"]}
      </h1>
      <div className="forgot-password__confirm-email-message">
        <p>{appLabels["login-register"]["forgot-password-paragraph"]}</p>
      </div>
      <div>
        <ForgotPasswordForm />
      </div>
    </div>
  );
};

import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import localforage from "localforage";
import { Link } from "react-router-dom";

//Hooks
import { useHideOrderModule } from "../useHideOrderModule";
import { useModal } from "../../_common/Modal/useModal";

//Contexts
import BrandingContext from "../BrandingContext";
import AppLabelsContext from "../AppLabelsContext";
import AppSettingsContext from "../AppSettingsContext";
import MerchantConfigContext from "../MerchantConfigContext";
import UserRoleContext from "../UserRoleContext";
import StoreContext from "../../OnlineOrdering/StoreContext";
import StaticPagesDataContextContext from "../contexts/StaticPagesDataContext";
import CWS5ModuleContext from "../CWS5ModuleContext";
import AppLanguageContext from "../AppLanguageContext";

//UI Components
import { AppFooterSubnav } from "../AppFooter/AppFooterSubnav";
import { LogoutModalDialog } from "../../_common/components/LogoutModalDialog";
import { Modal } from "../../_common/Modal/Modal";
import { ChangePasswordForm } from "../../Account/ChangePasswordForm";

//Helpers
import { getAPIRegistrationFields } from "../../Account/apiHelpers/getAPIRegistrationFields";
import { ChangeAccountDetailsForm } from "../../Account/ChangeAccountDetailsForm";
import { getAPIAccountInfo } from "../../Account/apiHelpers/getAPIAccountInfo";
import { getAPICardList } from "../../OnlineOrdering/Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPICardList";

// Assets
import { ReactComponent as IconMenu } from "../../_common/icons/IconHamburger.svg";
import { ReactComponent as IconX } from "../../_common/icons/IconX.svg";

import { ReactComponent as IconArrowRight } from "../../_common/icons/IconArrowRight.svg";

//CSS
import "./AppHamburgerMenu.css";
import FocusTrap from "focus-trap-react";

export const AppHamburgerMenu = () => {
  const { hideOrderModule } = useHideOrderModule();
  const { isModal, setIsModal, toggleModal, closeAnimation } = useModal();

  const branding = useContext(BrandingContext);
  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const userRoleContext = useContext(UserRoleContext);
  const storeContext = useContext(StoreContext);
  const staticPagesData = useContext(StaticPagesDataContextContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const [, setRegistrationFields] = useState(null);

  const vexilorConfig = merchantConfigContext.vexilor.I2;
  const userStatus = userRoleContext.status;
  const isLoggedIn = userStatus === "logged-in";
  const loginToken = userRoleContext.loginToken;

  const skin = merchantConfigContext.skin;
  const isStadiumSchema = merchantConfigContext.merchant.I55 === "stadium";

  const isOnlineOrderingEnabled = cws5ModuleContext.isOnlineOrderingEnabled;
  const isGiftCardEnabled = cws5ModuleContext.isGiftCardEnabled;

  const isOrderStatusEnabled = vexilorConfig["order_status_access"] === "t";

  const paymentMethods = vexilorConfig.payment_methods;

  const [isOpen, setIsOpen] = useState(false);
  const [hideOrderButtonOnLanding, setHideOrderButtonOnLanding] = useState(false);
  const [isLogoutAttempt, setIsLogoutAttempt] = useState(false);
  const [subnavItems, setSubnavItems] = useState([]);
  const [accountInfo, setAccountInfo] = useState(null);
  const [reloadData, setReloadData] = useState(true);
  const [isStoredCreditCard, setIsStoredCreditCard] = useState("idle");
  const [modalSettings, setModalSettings] = useState(null);
  const [numberOfPaymentMethods, setNumberOfPaymentMethods] = useState(null);

  const handleClick = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (vexilorConfig && storeContext && appSettings) {
      const isPayOnly = appSettings["is-pay-only"] === "yes";
      if (isPayOnly) {
        if (
          storeContext.activeOrderStore === null &&
          vexilorConfig.active_order_types === "dinein"
        ) {
          setHideOrderButtonOnLanding(true);
        } else {
          setHideOrderButtonOnLanding(false);
        }
      } else {
        setHideOrderButtonOnLanding(false);
      }
    }
  }, [vexilorConfig, storeContext, appSettings]);

  useEffect(() => {
    const tempNavItems = [];
    if (branding["client-site-url"]) {
      tempNavItems.push({
        name: "merchant-website",
        displayName: appLabels["general"]["main-website"],
        isLink: true,
        isExternalLink: true,
        linkPathname: branding["client-site-url"],
        icon: null,
      });
    }

    if (appSettings["enable-terms"] === "Yes") {
      tempNavItems.push({
        name: "terms-and-conditions",
        displayName: appLabels["general"]["terms-and-conditions"],
        icon: null,
        isLink: true,
        linkPathname: "/terms-and-conditions",
      });
    }

    if (staticPagesData && staticPagesData.length > 0) {
      staticPagesData.forEach((staticPageData, index) => {
        if (staticPageData.isEnabled === "yes") {
          tempNavItems.push({
            name: `static-page-${index + 1}`,
            displayName: staticPageData.displayName,
            pageHeading: staticPageData.pageHeading,
            //marquiIconFilename: staticPageData.iconFilename,
            isLink: true,
            linkPathname: staticPageData.path,
          });
        }
      });
    }

    setSubnavItems(tempNavItems);
  }, [userRoleContext, userStatus, storeContext, hideOrderModule, hideOrderButtonOnLanding]);

  /** Only call API 950 here if __customerInfo is not stored in localforage */
  useEffect(() => {
    if (reloadData) {
      setReloadData(false);
      localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
        if (customerInfo) {
          setAccountInfo(customerInfo);
          setIsStoredCreditCard(customerInfo.storedCreditCard ? true : false);
        } else {
          getAPIAccountInfo(skin, loginToken, appLanguage).then(async (apiAccountInfo) => {
            if (apiAccountInfo) {
              if (apiAccountInfo.status === "expiredLoginToken") {
                userRoleContext.handleLoginTokenExpiration();
              } else if (!apiAccountInfo.error) {
                const accountInfo = await userRoleContext.updateAccountInfoInStorage({
                  result: apiAccountInfo,
                });
                setAccountInfo(accountInfo);
                setIsStoredCreditCard(accountInfo.storedCreditCard ? true : false);
              }
            }
          });
        }
      });
    }
  }, [reloadData]);

  useEffect(() => {
    const countMethodsOfPayment = async () => {
      let counter = 0;

      if (!!paymentMethods["GIFTCARD"]) {
        getAPICardList(
          skin,
          loginToken,
          appSettings["currency-symbol"],
          appSettings["currency-symbols-side"],
          appLanguage,
          branding
        ).then((apiData) => {
          if (!apiData.error) {
            if (apiData.status === "expiredLoginToken") {
              userRoleContext.handleLoginTokenExpiration();
            } else {
              counter += apiData.length;
              if (isStoredCreditCard) {
                counter += 1;
              }
              setNumberOfPaymentMethods(counter.toString());
            }
          }
        });
      }
    };

    if (loginToken && isStoredCreditCard !== "idle") {
      countMethodsOfPayment();
    }
  }, [isStoredCreditCard, loginToken]);

  const openPasswordForm = () => {
    setModalSettings({
      content: <ChangePasswordForm skin={skin} login={accountInfo.email} setIsModal={setIsModal} />,
      type: "fullscreen",
      description: "Change Password",
      wrapperClass: "modal-wrapper--account-update",
      isCloseButton: true,
    });
    toggleModal();
  };

  const openAccountDetailsForm = () => {
    getAPIRegistrationFields(skin, accountInfo, appLanguage).then((apiRegistrationFields) => {
      if (apiRegistrationFields && !apiRegistrationFields.error) {
        setRegistrationFields(apiRegistrationFields);

        setModalSettings({
          content: (
            <ChangeAccountDetailsForm
              skin={skin}
              fields={apiRegistrationFields}
              setRegistrationFields={setRegistrationFields}
              setIsModal={setIsModal}
              setReloadData={setReloadData}
            />
          ),
          type: "fullscreen",
          isTransparent: true,
          description: appLabels["account"]["update-account-details"],
          wrapperClass: "modal-wrapper--account-update",
          isCloseButton: true,
        });
        toggleModal();
      }
    });
  };

  /**
   * Mutation Observer Helper function
   * //developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe
   * @param {string} sel The DOM selector to watch
   * @param {object} opt MutationObserver options
   * @param {function} cb Pass Mutation object to a callback function
   */
  const Observe = (sel, opt, cb) => {
    const Obs = new MutationObserver((m) => [...m].forEach(cb));
    document.querySelectorAll(sel).forEach((el) => Obs.observe(el, opt));
  };

  useEffect(() => {
    Observe(
      "#mobile-hamburger-menu-container",
      { attributes: true, attributeFilter: ["style"] },
      (m) => {
        if (m.target.style.display === "none") {
          if (isModal) {
            toggleModal();
          }
          setIsOpen(false);
        }
      }
    );
  });

  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        aria-label={`${
          isOpen ? appLabels["general"]["hide-menu"] : appLabels["order"]["show-menu-button"]
        }`}
        className="mobile-hamburger-menu-button"
      >
        <IconMenu aria-hidden={true} />
      </button>

      {isOpen &&
        ReactDOM.createPortal(
          <>
            <FocusTrap>
            <div
              className="mobile-hamburger-menu-container"
              id="mobile-hamburger-menu-container"
              style={{ display: "block" }}
            >
              <div className="mobile-hamburger-menu-x-icon">
                <button
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <IconX aria-hidden={true} />
                </button>
              </div>

              <section className="account-section">
                <h2 className="account-section__heading">{appLabels["account"]["profile"]}</h2>
                <ul className="account-section__editable-items-list">
                  {isLoggedIn ? (
                    <>
                      <li className="account-section__editable-item">
                        <button
                          onClick={openAccountDetailsForm}
                          className="account-section__edit-item-button"
                          type="button"
                        >
                          <span>{appLabels["account"]["personal-info"]}</span>
                          <IconArrowRight aria-hidden="true" />
                        </button>
                      </li>

                      <li className="account-section__editable-item">
                        <button
                          onClick={openPasswordForm}
                          className="account-section__edit-item-button"
                          type="button"
                        >
                          <span>{appLabels["account"]["change-password"]}</span>
                          <IconArrowRight aria-hidden="true" />
                        </button>
                      </li>

                      <li className="account-section__editable-item">
                        <Link
                          to="/account/account-deletion"
                          className="account-section__edit-item-link"
                          onClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          {appLabels["account"]["delete-account"]}
                          <IconArrowRight aria-hidden="true" />
                        </Link>
                      </li>

                      {vexilorConfig.active_order_types.includes("delivery") &&
                        !isStadiumSchema &&
                        isOnlineOrderingEnabled && (
                          <li className="account-section__editable-item">
                            <Link
                              to="/account/delivery-address"
                              className="account-section__edit-item-link"
                              onClick={() => {
                                setIsOpen(false);
                              }}
                            >
                              {appLabels["order"]["delivery-addresses"]}
                              <IconArrowRight aria-hidden="true" />
                            </Link>
                          </li>
                        )}

                      {(isGiftCardEnabled || isOnlineOrderingEnabled) && (
                        <li className="account-section__editable-item">
                          <Link
                            to="/account/payment-methods"
                            className="account-section__edit-item-link"
                            onClick={() => {
                              setIsOpen(false);
                            }}
                          >
                            <div>
                              {appLabels["account"]["payment-methods"]}
                              <span className="account-section__payment-methods-counter">
                                {numberOfPaymentMethods && "(" + numberOfPaymentMethods + ")"}
                              </span>
                            </div>
                            <IconArrowRight aria-hidden="true" />
                          </Link>
                        </li>
                      )}
                      <li className="account-section__editable-item">
                        <button
                          onClick={() => {
                            setIsLogoutAttempt(true);
                          }}
                          className="account-section__edit-item-button"
                          type="button"
                        >
                          <span>{appLabels["account"]["log-out"]}</span>
                        </button>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="account-section__editable-item">
                        <Link
                          to="/login-register"
                          className="account-section__edit-item-link"
                          onClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          {appLabels["login-register"]["sign-in"]}
                          <IconArrowRight aria-hidden="true" />
                        </Link>
                      </li>
                      <li className="account-section__editable-item">
                        <Link
                          to="/register"
                          className="account-section__edit-item-link"
                          onClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          {appLabels["login-register"]["register"]}
                          <IconArrowRight aria-hidden="true" />
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </section>

              {isLoggedIn &&
                ((!hideOrderModule && isOnlineOrderingEnabled) || isGiftCardEnabled) && (
                  <section className="account-section">
                    <h2 className="account-section__heading">
                      {appLabels["account"]["my-orders"]}
                    </h2>
                    <ul className="account-section__editable-items-list">
                      {isOnlineOrderingEnabled && (
                        <>
                          {isOrderStatusEnabled && (
                            <li className="account-section__editable-item">
                              <Link
                                to="/online-ordering/recent-orders/active-orders"
                                className="account-section__edit-item-link"
                                onClick={() => {
                                  setIsOpen(false);
                                }}
                              >
                                <span>{appLabels["order"]["active-orders"]}</span>
                                <IconArrowRight aria-hidden="true" />
                              </Link>
                            </li>
                          )}
                          <li className="account-section__editable-item">
                            <Link
                              to="/online-ordering/recent-orders/past-orders"
                              className="account-section__edit-item-link"
                              onClick={() => {
                                setIsOpen(false);
                              }}
                            >
                              <span>{appLabels["order"]["past-orders"]}</span>
                              <IconArrowRight aria-hidden="true" />
                            </Link>
                          </li>
                        </>
                      )}
                      {isGiftCardEnabled && (
                        <li className="account-section__editable-item giftCardRecentOrdersNavListItem">
                          <Link
                            to="/gift-card/recent"
                            className="account-section__edit-item-link"
                            onClick={() => {
                              setIsOpen(false);
                            }}
                          >
                            <span>{appLabels["gift-card"]["gift-card-nav-label"]}</span>
                            <IconArrowRight aria-hidden="true" />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </section>
                )}

              <section className="account-section">
                <h2 className="account-section__heading">{appLabels["general"]["resources"]}</h2>
                <AppFooterSubnav
                  name={"settings"}
                  subnavItems={subnavItems}
                  closeSubnav={() => {}}
                  customClass={"mobile-hamburger-menu"}
                  isMobileHamburgerMenu={true}
                  listCustomeClass={"account-section__editable-items-list"}
                  listItemCustomClass={"account-section__editable-item"}
                />
              </section>
              </div>
            </FocusTrap>
            {modalSettings != null && (
              <Modal
                isModal={isModal}
                isTransparent={modalSettings.isTransparent}
                toggleModal={toggleModal}
                content={modalSettings.content}
                type={modalSettings.type}
                description={modalSettings.description}
                closeAnimation={closeAnimation}
                isCloseButton={modalSettings.isCloseButton}
                wrapperClass={modalSettings.wrapperClass}
              />
            )}

            {isLogoutAttempt && (
              <LogoutModalDialog resetRemoveDialog={() => setIsLogoutAttempt(false)} />
            )}
          </>,
          document.body
        )}
    </>
  );
};

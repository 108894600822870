import React, { useState, useEffect, useContext } from "react";

//Context
import BrandingContext from "../../App/BrandingContext";

//Helper functions
import { getContrastColor } from "../colorHelpers";

//UI Component
import { ReactComponent as IconCart } from "../icons/IconCart.svg";

//CSS
import "./CartIndicator.css";

function CartIndicator({ isAnimated, quantity, isFloating, activeOrderStore }) {
  const [animatedClass, setAnimatedClass] = useState("");

  useEffect(() => {
    if (isAnimated) setAnimatedClass(" cart-indicator--blink");
    else setAnimatedClass("");
  }, [isAnimated]);

  const branding = useContext(BrandingContext);
  const cartIconColor =
    window.location.href.includes("review-order") ||
    window.location.href.includes("checkout") ||
    window.location.href.includes("bill")
      ? "#2a2a2a"
      : getContrastColor(branding["online-order-settings-background-colour"]);

  return (
    <div

      className={`cart-indicator${animatedClass}${quantity === 0 ? " cart-indicator--empty" : ""} ${
        isFloating ? " cart-indicator-floating" : ""
      }`}
    >
      <IconCart style={{ fill: cartIconColor }} />
      {quantity > 0 && activeOrderStore && (
        <>
          <span
            className="cart-indicator__counter"
            style={{ color: getContrastColor(branding["online-order-settings-active-colour"]) }}
            aria-hidden
          >
            {quantity}
          </span>

        </>
      )}
    </div>
  );
}

export default CartIndicator;

import React, { useContext } from "react";
import { Route } from "react-router-dom";

import AppLabelsContext from "../App/AppLabelsContext";
import BrandingContext from "../App/BrandingContext";

import { PageComponentTemplate } from "../_common/components/PageComponentTemplate";
import { MyRewards } from "../MyRewards/MyRewards";
import { HowRewardsWork } from "../MyRewards/HowRewardsWork/HowRewardsWork";
import { Rewards } from "../Rewards/Rewards";

export const LoyaltyRoutes = () => {
  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);

  return (
    <>
      <Route
        path="/my-rewards"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["loyalty"]["my-rewards"]}
            isBackButton={true}
            isHomeLink={true}
            mainClassOverride="my-rewards-main-content"
            bannerImage={branding["loyalty-main-tab"]}
            backButtonDestination={"/dashboard"}
            component={<MyRewards />}
          />
        )}
      />
      <Route
        path="/how-rewards-work"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["loyalty"]["how-rewards-work"]}
            isBackButton={true}
            isHomeLink={true}
            bannerImage={branding["loyalty-how-it-works"]}
            mainClassOverride="how-rewards-work-main-content desktop-container"
            component={<HowRewardsWork />}
          />
        )}
      />
      <Route
        path="/rewards"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["loyalty"]["rewards"]}
            isBackButton={true}
            isHomeLink={true}
            bannerImage={branding["loyalty-main-tab"]}
            mainClassOverride="rewards-main-content desktop-container"
            component={<Rewards />}
          />
        )}
      />
    </>
  );
};

import React, { useRef, useState, useEffect, useContext } from "react";
import AppLabelsContext from "../../App/AppLabelsContext";
import { ReactComponent as IconArrowDown } from "../icons/IconArrowDown.svg";
import "./FormSelect.css";
import "./formSelectInputCombo.css";
import AppSettingsContext from "../../App/AppSettingsContext";

export const FormSelectInputCombo = (props) => {
  const {
    onSelectChange,
    defaultValue,
    fieldData,
    isSmall,
    id,
    label,
    type,
    name,
    isRequired,
    isSkipEmptyOption,
    showAstr,
    customLabelClass,
    displayCurrencySymbol = false,
    customOptions = null,
    handleInputChangeFromParent = null,
    isEditingGiftCardAmount = false,
    placeholder,
    maxValue,
    maxlength,
    onInputChange,
    resetInvalidField,
    resetPatternTooltip,
    onInputBlur,
    onFocusAction,
    title,
    autocomplete,
    isCustomAmountEntered = false,
    inputInformationText = null
  } = props;

  const [selectInFocusClass, setSelectInFocusClass] = useState("");
  const appSettings = useContext(AppSettingsContext);
  let currencySymbol = appSettings["currency-symbol"];
  const [displayCurrency, setDisplayCurrency] = useState()
  const [customInput, setCustomInput] = useState(null)
  const [inputInFocusClass, setInputInFocusClass] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (defaultValue && !inputInFocusClass && inputRef.current) {
      setInputInFocusClass(" form__field-wrapper--in-focus");
      onFocus(inputRef.current);
    }
  }, [inputRef.current]);
  useEffect(() => {
    setCustomInput(isCustomAmountEntered)

  }, [isCustomAmountEntered])

  useEffect(() => {
    // If a non-empty option is pre-selected set the select style to be in-focus
    if (fieldData.value !== "") setSelectInFocusClass(" form__field-wrapper--in-focus");

  }, []);

  useEffect(() => {
    if (currencySymbol === "") {
      setDisplayCurrency("$")
    } else {
      setDisplayCurrency(currencySymbol)
    }
  }, [displayCurrencySymbol, currencySymbol])

  useEffect(() => {
    if (isEditingGiftCardAmount) {
      fieldData.value = defaultValue
    }
    else if (fieldData.name === "eGiftCardAmount") {
      fieldData.value = ""
    }
  }, [fieldData, defaultValue])

  const onChange = (event) => {
    let selectElement = document.getElementsByClassName("comboSelectField")[0]
    selectElement.name = name
    onSelectChange(event.target);
    handleSelectValue(event)

    if (!!props.callBack) {
      props.callBack(event.target);
    }

    if (event.target.value !== "") {
      setSelectInFocusClass(" form__field-wrapper--in-focus");
    } else {
      setSelectInFocusClass("");
    }
    if (handleInputChangeFromParent) {
      handleInputChangeFromParent(event)
    }
  };

  // if Select has a default value - Set focused label class and perform its onSelectChange
  const selectRef = useRef();
  useEffect(() => {
    if (defaultValue && !selectInFocusClass && selectRef.current) {
      setSelectInFocusClass(" form__field-wrapper--in-focus");
      onSelectChange(selectRef.current);
    }
  }, [selectRef.current]);

  const appLabels = useContext(AppLabelsContext);

  useEffect(() => {
    if (customInput === true) {
      let selectElement = document.getElementById(id)
      selectElement.setAttribute("value", "other")
      selectElement.id = ""
      selectElement.name = ""
      let inputElement = document.getElementsByClassName("comboInputField")[0]
      inputElement.id = id
      inputElement.name = name
      inputElement.value = isCustomAmountEntered ? defaultValue : ""
      if (!isCustomAmountEntered) {
        inputElement.setAttribute("value", "")
      }

    } else if (customInput === false) {
      let selectElement = document.getElementsByClassName("comboSelectField")[0]
      selectElement.id = id
      selectElement.name = name

      let inputElement = document.getElementsByClassName("comboInputField")[0]
      if (inputElement) {
        inputElement.id = ""
        inputElement.name = ""
      }
    }
  }, [customInput])

  const handleSelectValue = (e) => {
    let optionSelected = e.target.value
    if (optionSelected === "other") {
      setCustomInput(true)
    }
    else if (optionSelected !== "other") {
      setCustomInput(false)
    }
  }

  function checkForPositiveInteger(e) {
    if (e.key === "-") {
      e.preventDefault()
    }
  }

  const onFocus = (field) => {
    resetInvalidField(field);
    setInputInFocusClass(" form__field-wrapper--in-focus");
    if (onFocusAction) {
      onFocusAction();
    }
  };

  const onClickAway = (field) => {
    if (onInputBlur) {
      onInputBlur(field);
    } else {
      /* If any value is present keep placeholder minimized, otherwise expand it */
      field.value === "" && setInputInFocusClass("");
      resetPatternTooltip(field);
    }
  };

  return (
    !fieldData.isHidden && (
      <div
        style={!!props.isHidden ? { display: "none" } : {}}
        className={`form__field-wrapper${isSmall ? " form__field-wrapper--small" : ""
          }${selectInFocusClass}`}
      >
        {inputInformationText && customInput &&
          <span className="inputInfoText" >{inputInformationText}</span>
        }
        <label htmlFor={id} className={`form__field-label ${customLabelClass}`}>
          {!!showAstr && <span aria-hidden="true">*</span>}
          {(fieldData.label || label) +
            (!fieldData.isRequired ? ` (${appLabels["form"]["optional-label"]})` : "")}
        </label>

        <div className="formSelectInputCombo">
          <div className={`formSelectInputComboSelect ${customInput ? "gcSelectInputShrink" : ""}`}>
            {displayCurrencySymbol && !customInput && fieldData.value !== "" &&
              <p className="selectIconStart">{displayCurrency}</p>
            }

            <select
              onChange={onChange}
              className={`form__field comboSelectField ${customInput || fieldData.value === "" ? "" : "selectInputValuePaddingLeft"}`}
              value={fieldData.value !== "" ? customInput ? "other" : fieldData.value : isEditingGiftCardAmount ? customInput ? "" : "" : customInput ? "other" : defaultValue}
              type={type}
              id={id}
              name={name}
              aria-required={isRequired}
              ref={selectRef}
              placeholder="Select amount"
            >
              {!isSkipEmptyOption && <option value=""></option>}
              {fieldData.options &&
                fieldData.options.length > 0 &&
                fieldData.options.map((option) => {

                  return (

                    <option key={option.value} value={option.value}>
                      {option.displayValue}
                    </option>
                  )
                })}
              {customOptions &&
                customOptions.length > 0 &&
                <>
                <option value="" >{appLabels["form"]["select-one"]}</option>
                  {customOptions.map((option) => {

                    return option === "-1" ? (
                      <option key={"other"} value={"other"}>
                        Custom amount
                      </option>
                    ) : (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </>
              }

            </select>

            <IconArrowDown />
          </div>
          {customInput &&
            <div className="formSelectInputComboInput">
              {displayCurrencySymbol && customInput &&
                <p className="selectIconStart">{displayCurrency}</p>
              }
              <label htmlFor={id} className={`form__field-label ${customLabelClass}`}>
                {!!showAstr && <span aria-hidden="true">*</span>}
                {appLabels["gift-card"]["custom-amount"]}
              </label>

              <input
                className={`form__field${type === "password" ? " form__field--with-right-button" : ""
                  } ${displayCurrencySymbol ? "currencySymbolPadding" : ""} comboInputField`}
                max={maxValue ? maxValue : null}
                type={type}
                onKeyDown={(e) => name === "eGiftCardAmount" ? checkForPositiveInteger(e) : null}
                //value={defaultValue || fieldData.value}
                placeholder={placeholder || fieldData.placeholder}
                defaultValue={isEditingGiftCardAmount ? customInput ? defaultValue : "" : ""}
                id={id}
                name={name}
                onChange={(e) => {
                  if (handleInputChangeFromParent) handleInputChangeFromParent(e);
                  onInputChange(e);
                }}
                onFocus={(e) => onFocus(e.target)}
                onBlur={(e) => onClickAway(e.target)}
                aria-required={isRequired}
                title={title}
                maxLength={`${name === "credit-card-number" ? fieldData.maxLength : maxlength}`}
                minLength={fieldData.minLength}
                ref={inputRef}
                autoCapitalize={name.includes("email") ? "none" : "on"} // prevent iOS auto-capitalization
                autoComplete={autocomplete === "off" || !autocomplete ? "new-password" : ""}

              />
            </div>
          }
        </div>
        {!fieldData.isValid && <div className="form__field-error">{fieldData.errorMessage}</div>}
      </div >
    )
  );
};

FormSelectInputCombo.displayName = "FormSelectInputCombo";

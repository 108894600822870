import React, { useContext } from "react";
import { getArray, jsonCopy, toDollars } from "./helpers";
import AppSettingsContext from "../App/AppSettingsContext";
import AppLanguageContext from "../App/AppLanguageContext";
import {
  getOrderAddonOccurrence,
  removeDuplicatesAndUpdateCount,
} from "../OnlineOrdering/RecentOrders/orderHistoryHelpers";

// Get total numeric quantity of items in cart
export const getCartQuantity = (cart) => {
  let counter = 0;
  if (cart) {
    cart.map((item) => {
      counter += parseInt(item.quantity);
      return counter;
    });
  }
  return counter;
};

export const getCartItemByKey = (entryKey, cart) => {
  const foundItem = cart.find((item) => item.entryKey === entryKey);
  const i = cart.findIndex((item) => item.entryKey === entryKey);
  return { value: foundItem, index: i };
};

export const getItemModifiersTotalFromCartData = (item) => {
  let modifiersTotal = 0;
  const modifierGroups = getArray(item, "modifierGroups");
  const addonGroups = getArray(item, "addonGroups");

  if (modifierGroups.length) {
    modifierGroups.map(([key, modifierGroup]) =>
      getArray(modifierGroup, "items").map(
        ([key, modifier]) =>
          !!parseFloat(modifier.price_for_display) &&
          (modifiersTotal += parseFloat(modifier.price_for_display))
      )
    );
  }
  if (addonGroups.length) {
    addonGroups.map(([key, addonGroup]) =>
      getArray(addonGroup, "items").map(([key, addon]) => {
        if (!!parseFloat(addon.price_for_display)) {
          modifiersTotal += parseFloat(addon.price_for_display);
        }
        const addonModifierGroups = getArray(addon, "modifierGroups");
        if (addonModifierGroups.length) {
          return addonModifierGroups.map(
            (addonModifierGroup) =>
              getArray(addonModifierGroup, "items").length &&
              addonModifierGroup.items.map(
                (addonModifier) =>
                  !!parseFloat(addonModifier.price_for_display) &&
                  (modifiersTotal += parseFloat(addonModifier.price_for_display))
              )
          );
        }
        return modifiersTotal;
      })
    );
  }

  if (!!item.combo_child_items && !!item.combo_child_items.length) {
    const comboChildItems = item.combo_child_items;
    comboChildItems.forEach((childItem) => {
      if (!!parseFloat(childItem.price_for_display)) {
        modifiersTotal += parseFloat(childItem.price_for_display);
      }

      const childItemModifierGroup = getArray(childItem, "modifierGroups");
      const childItemAddonGroup = getArray(childItem, "addonGroups");

      if (childItemModifierGroup.length) {
        childItemModifierGroup.map(([key, modifierGroup]) =>
          getArray(modifierGroup, "items").map(
            ([key, modifier]) =>
              !!parseFloat(modifier.price_for_display) &&
              (modifiersTotal += parseFloat(modifier.price_for_display))
          )
        );
      }
      if (childItemAddonGroup.length) {
        childItemAddonGroup.map(([key, addonGroup]) =>
          getArray(addonGroup, "items").map(([key, addon]) => {
            if (!!parseFloat(addon.price_for_display)) {
              modifiersTotal += parseFloat(addon.price_for_display);
            }
            const addonModifierGroups = getArray(addon, "modifierGroups");
            if (addonModifierGroups.length) {
              return addonModifierGroups.map(
                (addonModifierGroup) =>
                  getArray(addonModifierGroup, "items").length &&
                  addonModifierGroup.items.map(
                    (addonModifier) =>
                      !!parseFloat(addonModifier.price_for_display) &&
                      (modifiersTotal += parseFloat(addonModifier.price_for_display))
                  )
              );
            }
            return modifiersTotal;
          })
        );
      }
    });
  }
  return modifiersTotal;
};

export const getItemModifiersTotal = (item) => {
  let modifiersTotal = 0;
  const modifierGroups = getArray(item, "modifierGroups");
  const addonGroups = getArray(item, "addonGroups");

  if (modifierGroups.length) {
    modifierGroups.map(([key, modifierGroup]) =>
      getArray(modifierGroup, "items").map(
        ([key, modifier]) =>
          modifier.isSelected &&
          !!parseFloat(modifier.price) &&
          (modifiersTotal += parseFloat(modifier.price))
      )
    );
  }
  if (addonGroups.length) {
    addonGroups.map(([key, addonGroup]) =>
      getArray(addonGroup, "items").map(([key, addon]) => {
        if (addon.isSelected && !!parseFloat(addon.price)) {
          modifiersTotal += parseFloat(addon.price);
        }
        const addonModifierGroups = getArray(addon, "modifierGroups");
        if (addonModifierGroups.length) {
          return addonModifierGroups.map(
            (addonModifierGroup) =>
              getArray(addonModifierGroup, "items").length &&
              addonModifierGroup.items.map(
                (addonModifier) =>
                  addonModifier.isSelected &&
                  !!parseFloat(addonModifier.price) &&
                  (modifiersTotal += parseFloat(addonModifier.price))
              )
          );
        }
        return modifiersTotal;
      })
    );
  }

  if (!!item.combo_child_items && !!item.combo_child_items.length) {
    const comboChildItems = item.combo_child_items;
    comboChildItems.forEach((childItem) => {
      if (!!parseFloat(childItem.price)) {
        modifiersTotal += parseFloat(childItem.price);
      }

      const childItemModifierGroup = getArray(childItem, "modifierGroups");
      const childItemAddonGroup = getArray(childItem, "addonGroups");

      if (childItemModifierGroup.length) {
        childItemModifierGroup.map(([key, modifierGroup]) =>
          getArray(modifierGroup, "items").map(
            ([key, modifier]) =>
              modifier.isSelected &&
              !!parseFloat(modifier.price) &&
              (modifiersTotal += parseFloat(modifier.price))
          )
        );
      }
      if (childItemAddonGroup.length) {
        childItemAddonGroup.map(([key, addonGroup]) =>
          getArray(addonGroup, "items").map(([key, addon]) => {
            if (addon.isSelected && !!parseFloat(addon.price)) {
              modifiersTotal += parseFloat(addon.price);
            }
            const addonModifierGroups = getArray(addon, "modifierGroups");
            if (addonModifierGroups.length) {
              return addonModifierGroups.map(
                (addonModifierGroup) =>
                  getArray(addonModifierGroup, "items").length &&
                  addonModifierGroup.items.map(
                    (addonModifier) =>
                      addonModifier.isSelected &&
                      !!parseFloat(addonModifier.price) &&
                      (modifiersTotal += parseFloat(addonModifier.price))
                  )
              );
            }
            return modifiersTotal;
          })
        );
      }
    });
  }
  return modifiersTotal;
};

export const getCartSubtotal = (orderSummaryData) => {
  let cart = orderSummaryData.items;
  let subtotal = 0;
  cart.forEach((item) => {
    subtotal += parseFloat(item.price_for_display);
    subtotal += getItemModifiersTotalFromCartData(item);
  });

  return subtotal;
};

export const getCartTotal = (cart) => {
  let cartTotal = 0;
  cart.forEach((item) => {
    cartTotal += parseFloat(item.price);
    cartTotal += getItemModifiersTotal(item);
  });
  return cartTotal;
};

export const listItemOptions = (groupArray, displayType) => {
  const selectedOptions = [];
  if (groupArray.length) {
    groupArray.map(([key, group], x) => {
      const options = getArray(group, "items");
      return options.map(([key, option], i) => {
        if (option.isSelected) selectedOptions.push(option);
        return selectedOptions;
      });
    });
  }

  const isInline = displayType === "inline";
  const isColumn = displayType === "column";

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  return selectedOptions.map((option, i) => {
    const itemPrice = option.price
      ? ` - ${toDollars(
          appSettings["currency-symbol"],
          appSettings["currency-symbol-side"],
          option.price,
          appLanguage
        )}`
      : "";
    const comma = i + 1 < selectedOptions.length ? ", " : "";

    let output = "";

    if (isInline) {
      output = `${option.name}${itemPrice}${comma}`;
    } else if (isColumn) {
      output = (
        <div className="options-list__row" key={option.id}>
          {"- " + option.name + itemPrice}
        </div>
      );
    }

    return output;
  });
};

/**
 *
 * @param {*} optionsList : addon/modifier Group
 * @param {*} optionId : addon/modifier id
 * @returns number of occurrences of a particular addon/modifier
 */
const getAddonModifierOccurrences = (optionsList, optionId) => {
  return optionsList.filter((option) => option.id === optionId).length;
};

export const getOptionNameAndPriceFromOrderSummary = (optionGroups) => {
  let optionsWithNameAndPrice = [];

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  for (let groupKey in optionGroups) {
    const options = optionGroups[groupKey].items;
    for (let itemKey in options) {
      const option = options[itemKey];
      const optionWithNameAndPrice = {
        name: option.name,
        price:
          parseFloat(option.price) > 0
            ? toDollars(
                appSettings["currency-symbol"],
                appSettings["currency-symbol-side"],
                option["price_for_display"],
                appLanguage
              )
            : false,
        nestedMods: [],
        quantity: getAddonModifierOccurrences(options, option.id),
        parentId: optionGroups[groupKey].id,
      };

      for (let modKey in option.modifierGroups) {
        const modOption = option.modifierGroups[modKey];
        for (let modItemKey in modOption.items) {
          const nestedMod = modOption.items[modItemKey];

          const mod = nestedMod.name;
          optionWithNameAndPrice.nestedMods.push(mod);
        }
      }

      optionsWithNameAndPrice.push(optionWithNameAndPrice);
    }
  }

  //remove duplicates from optionsList
  optionsWithNameAndPrice = removeDuplicates(optionsWithNameAndPrice, (item) =>
    JSON.stringify(item)
  );

  return optionsWithNameAndPrice;
};

export const getOptionNameAndPrice = (optionGroups) => {
  let optionsWithNameAndPrice = [];

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  for (let groupKey in optionGroups) {
    const options = optionGroups[groupKey].items;

    for (let itemKey in options) {
      const option = options[itemKey];

      if (option.isSelected) {
        const optionWithNameAndPrice = {
          name: option.name,
          price:
            parseFloat(option.price) > 0
              ? toDollars(
                  appSettings["currency-symbol"],
                  appSettings["currency-symbol-side"],
                  option.price,
                  appLanguage
                )
              : false,
          nestedMods: [],
        };

        for (let modKey in option.modifierGroups) {
          const modOption = option.modifierGroups[modKey];
          if (modOption.isSelected) {
            for (let modItemKey in modOption.items) {
              const nestedMod = modOption.items[modItemKey];
              if (nestedMod.isSelected) {
                const mod = nestedMod.name;
                optionWithNameAndPrice.nestedMods.push(mod);
              }
            }
          }
        }

        optionsWithNameAndPrice.push(optionWithNameAndPrice);
      }
    }
  }

  return optionsWithNameAndPrice;
};

export const dynamicSort = (property) => {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder === -1) {
      return parseInt(b[property]) > parseInt(a[property]) ? 1 : -1;
    } else {
      return parseInt(a[property]) > parseInt(b[property]) ? 1 : -1;
    }
  };
};

export const formatCartForApi = (cart) => {
  let formattedCart = [];
  cart.forEach((item) => {
    //Format addons
    const addonList = [];
    const addonGroupsArray = getArray(item, "addonGroups");
    if (addonGroupsArray.length) {
      addonGroupsArray.forEach(([key, addonGroup]) => {
        const addonItemsArray = getArray(addonGroup, "items");
        if (addonItemsArray.length) {
          addonItemsArray.forEach(([key, addon]) => {
            if (addon.isSelected) {
              let formattedNestedModifierGroups = [];
              const nestedModifierGroups = getArray(addon, "modifierGroups");

              if (nestedModifierGroups.length) {
                nestedModifierGroups.forEach(([key, nestedModifierGroup]) => {
                  const nestedModifiers = getArray(nestedModifierGroup, "items");

                  nestedModifiers.forEach(([key, nestedModifier]) => {
                    if (nestedModifier.isSelected) {
                      const formattedNestedModifier = {
                        item_id: nestedModifier.id,
                        item_sku_code: "",
                        modifier_list: [],
                        modifier_group_id: nestedModifier.parentId,
                        discount_ref: "",
                        discount_amount: "",
                      };
                      formattedNestedModifierGroups.push(formattedNestedModifier);
                    }
                  });
                });
              }

              const formattedAddon = {
                item_id: addon.id,
                item_sku_code: "",
                modifier_list: formattedNestedModifierGroups,
                discount_ref: "",
                discount_amount: "",
                addon_group_id: addonGroup.isStandalone ? "" : addonGroup.id,
              };

              const addonModGroupsArray = getArray(formattedAddon, "modifierGroups");
              if (addonModGroupsArray.length) {
                addonModGroupsArray.forEach(([key, addonModGroup]) => {
                  const addonModItemsArray = getArray(addonModGroup, "items");
                  if (addonModItemsArray.length) {
                    addonModItemsArray.forEach(([key, addonModItem]) => {
                      if (addonModItem.isSelected) formattedAddon.modifier_list.push(addonModItem);
                    });
                  }
                });
              }

              let addonQuantity = addon.quantity || 1;
              for (let i = 0; i < addonQuantity; i++) {
                addonList.push(formattedAddon);
              }
            }
          });
        }
      });
    }
    //END OF Format addons
    //Format modifiers
    const modifierList = [];
    const modifierGroupsArray = getArray(item, "modifierGroups");
    if (modifierGroupsArray.length) {
      modifierGroupsArray.forEach(([key, modifierGroup]) => {
        const modifierItemsArray = getArray(modifierGroup, "items");
        if (modifierItemsArray.length) {
          modifierItemsArray.forEach(([key, modifier]) => {
            if (modifier.isSelected) {
              const formattedModifier = {
                item_id: modifier.id,
                item_sku_code: "",
                modifier_list: [],
                modifier_group_id: modifier.parentId ? modifier.parentId : "",
                discount_ref: "",
                discount_amount: "",
              };
              modifierList.push(formattedModifier);
            }
          });
        }
      });
    }
    //END OF Format modifiers

    //Formate Combo Child Items
    const comboChildItems = [];
    if (item.hasOwnProperty("combo_child_items") && item["combo_child_items"].length > 0) {
      item.combo_child_items.forEach((comboChild) => {
        //Formatting Combo Child Items Addons
        const comboChildAddonList = [];
        const comboChildAddonGroupArray = getArray(comboChild, "addonGroups");
        if (comboChildAddonGroupArray.length) {
          comboChildAddonGroupArray.forEach(([key, addonGroup]) => {
            const comboChildAddonItemsArray = getArray(addonGroup, "items");
            if (comboChildAddonItemsArray.length) {
              comboChildAddonItemsArray.forEach(([key, addon]) => {
                if (addon.isSelected) {
                  let comboChildFormattedNestedModifierGroups = [];
                  const comboChildNestedModifierGroups = getArray(addon, "modifierGroups");
                  if (comboChildNestedModifierGroups.length) {
                    comboChildNestedModifierGroups.forEach(
                      ([key, comboChildNestedModifierGroup]) => {
                        const comboChildNestedModifiers = getArray(
                          comboChildNestedModifierGroup,
                          "items"
                        );

                        comboChildNestedModifiers.forEach(([key, comboChildNestedModifier]) => {
                          if (comboChildNestedModifier.isSelected) {
                            const comboChildFormattedNestedModifier = {
                              item_id: comboChildNestedModifier.id,
                              modifier_group_id: comboChildNestedModifier.parentId,
                              item_sku_code: "",
                              modifier_list: [],
                              discount_ref: "",
                              discount_amount: "",
                            };
                            comboChildFormattedNestedModifierGroups.push(
                              comboChildFormattedNestedModifier
                            );
                          }
                        });
                      }
                    );
                  }

                  const comboChildFormattedAddon = {
                    item_id: addon.id,
                    item_sku_code: "",
                    modifier_list: comboChildFormattedNestedModifierGroups,
                    discount_ref: "",
                    discount_amount: "",
                    addon_group_id: addonGroup.isStandalone ? "" : addonGroup.id,
                  };

                  const comboChildAddonModGroupsArray = getArray(
                    comboChildFormattedAddon,
                    "modifierGroups"
                  );
                  if (comboChildAddonModGroupsArray.length) {
                    comboChildAddonModGroupsArray.forEach(([key, comboChildAddonModGroup]) => {
                      const comboChildAddonModItemsArray = getArray(
                        comboChildAddonModGroup,
                        "items"
                      );
                      if (comboChildAddonModItemsArray.length) {
                        comboChildAddonModItemsArray.forEach(([key, comboChildAddonModItem]) => {
                          if (comboChildAddonModItem.isSelected) {
                            comboChildFormattedAddon.modifier_list.push(comboChildAddonModItem);
                          }
                        });
                      }
                    });
                  }

                  let comboChildAddonQuantity = addon.quantity || 1;
                  for (let i = 0; i < comboChildAddonQuantity; i++) {
                    comboChildAddonList.push(comboChildFormattedAddon);
                  }
                }
              });
            }
          });
        }
        //End of Combo Child Items Addons

        //Formatting Combo Child Items Modifiers
        const comboChildModifierList = [];
        const comboChildModifierGroupsArray = getArray(comboChild, "modifierGroups");
        if (comboChildModifierGroupsArray.length) {
          comboChildModifierGroupsArray.forEach(([key, comboChildModifierGroup]) => {
            const comboChildModifierItemsArray = getArray(comboChildModifierGroup, "items");
            if (comboChildModifierItemsArray.length) {
              comboChildModifierItemsArray.forEach(([key, comboChildModifier]) => {
                if (comboChildModifier.isSelected) {
                  const comboChildFormattedModifier = {
                    item_id: comboChildModifier.id,
                    modifier_group_id: comboChildModifier.parentId
                      ? comboChildModifier.parentId
                      : "",
                    item_sku_code: "",
                    modifier_list: [],
                    discount_ref: "",
                    discount_amount: "",
                  };
                  comboChildModifierList.push(comboChildFormattedModifier);
                }
              });
            }
          });
        }
        //End of Combo Child Items Modifiers

        const formattedComboChildItems = {
          item_id: comboChild.id,
          item_sku_code: "",
          addon_list: comboChildAddonList,
          modifier_list: comboChildModifierList,
          cooking_instruction: "",
          combo_child_items: [],
          discount_ref: "",
          discount_amount: "",
          item_components_group_id: comboChild["group_id"],
        };
        let comboChildItemQuantity = comboChild.quantity || 1;
        for (let i = 0; i < comboChildItemQuantity; i++) {
          comboChildItems.push(formattedComboChildItems);
        }
      });
    }
    //END OF Combo Child Items
    for (let i = 0; i < item.quantity; i++) {
      formattedCart.push({
        item_id: item.id,
        item_sku_code: "",
        addon_list: addonList,
        modifier_list: modifierList,
        cooking_instruction: item.specialInstructions || "",
        combo_child_items: comboChildItems,
        discount_ref: "",
        discount_amount: "",
      });
    }
  });

  return formattedCart;
};

//formats the tipConfig Data from the API to a more usable data structure
export const formatTipConfig = (tipConfig, appLabels, activeOrderType) => {
  let formattedTipConfig = {};
  formattedTipConfig.isCustomTipEnabled = tipConfig.custom_tip === "t";
  const defaultPercentage = Number(
    tipConfig.tip_default_percent.split(activeOrderType + ":")[1].split("|")[0]
  );
  formattedTipConfig.defaultTipPercentage = defaultPercentage;
  formattedTipConfig.percentages = tipConfig.tip_percent_denom.split(",").map((per, index) => {
    return {
      displayName: Number(per) + "%",
      calcPercentage: Number(per) / 100,
      index: index + 1,
      isDefault: Number(per) === defaultPercentage,
      isCustomAmount: false,
    };
  });
  formattedTipConfig.percentages.splice(0, 0, {
    displayName: appLabels["order"]["no-tip"],
    calcPercentage: 0,
    index: 0,
    isDefault: defaultPercentage === 0,
    isCustomAmount: false,
  });

  return formattedTipConfig;
};

export const sortByPriceAscending = (rewards) => {
  return rewards.sort((item1, item2) => parseInt(item1.price) - parseInt(item2.price));
};

export const formatMerchantRewards = (apiMerchantRewards) => {
  let reformattedRewards = [];

  apiMerchantRewards.forEach((apiReward) => {
    //Filter out Free rewards(TypeCode 21) && Points to Moeny Conversion (TypeCode 6),
    if (apiReward[5] !== "21" && apiReward[5] !== "6") {
      const reformattedReward = {
        id: apiReward[0],
        name: apiReward[1],
        description: apiReward[2],
        price: parseInt(apiReward[3]),
        image: apiReward[4] || false,
        typeCode: apiReward[5],
        typeName: apiReward[6],
      };

      reformattedRewards.push(reformattedReward);
    }
  });

  reformattedRewards = sortByPriceAscending(reformattedRewards);
  return reformattedRewards;
};

export const removeDuplicates = (data, key) => {
  return [...new Map(data.map((item) => [key(item), item])).values()];
};

export const getOccurrence = (
  array,
  value,
  discounts,
  addons,
  modifiers,
  totalAddonDiscount,
  comboChildItems,
  specialInstructions
) => {
  if (comboChildItems) {
    return array.filter(
      (item) =>
        item.id === value &&
        JSON.stringify(item.discounts) === discounts &&
        JSON.stringify(item.addonGroups) === addons &&
        JSON.stringify(item.modifierGroups) === modifiers &&
        item.total_addon_discount === totalAddonDiscount &&
        JSON.stringify(item.combo_child_items) === comboChildItems &&
        item.specialInstructions === specialInstructions
    ).length;
  } else {
    return array.filter(
      (item) =>
        item.id === value &&
        JSON.stringify(item.discounts) === discounts &&
        JSON.stringify(item.addonGroups) === addons &&
        JSON.stringify(item.modifierGroups) === modifiers &&
        item.total_addon_discount === totalAddonDiscount &&
        item.specialInstructions === specialInstructions
    ).length;
  }
};

export const getOrderOccurrence = (
  array,
  value,
  discounts,
  addons,
  modifiers,
  totalAddonDiscount,
  comboChildItems
) => {
  if (comboChildItems) {
    return array.filter(
      (item) =>
        item.id === value &&
        JSON.stringify(item.discounts) === discounts &&
        JSON.stringify(item.addonGroups) === addons &&
        JSON.stringify(item.modifierGroups) === modifiers &&
        item.total_addon_discount === totalAddonDiscount &&
        JSON.stringify(item.combo_child_items) === comboChildItems
    ).length;
  } else {
    return array.filter(
      (item) =>
        item.id === value &&
        JSON.stringify(item.discounts) === discounts &&
        JSON.stringify(item.addonGroups) === addons &&
        JSON.stringify(item.modifierGroups) === modifiers &&
        item.total_addon_discount === totalAddonDiscount
    ).length;
  }
};

const getOrderItemFromCleanedUpOrder = (orderWithAddonQuantity, orderItemIndex) => {
  let relatedItem = null;
  if (orderItemIndex === 0) {
    relatedItem = orderWithAddonQuantity.items[0];
  } else {
    let quantityCounter = 0;
    for (let i = 0; i < orderWithAddonQuantity.items.length; i++) {
      let orderItem = orderWithAddonQuantity.items[i];
      quantityCounter += orderItem.quantity;
      if (quantityCounter > orderItemIndex) {
        relatedItem = orderItem;
        break;
      }
    }
  }

  return relatedItem;
};

//function for modified reorders only
export const cleanUpItemsListBasedOnQuantity = (itemsList, isAddonQuantityEnabled, order) => {
  let cleanedUpOrder = jsonCopy(order);

  cleanedUpOrder.items = removeDuplicatesAndUpdateCount(
    jsonCopy(order.items),
    isAddonQuantityEnabled
  );
  //when dealing with discounts, exclude the discount_amount since the amount could differ for each item even if the discount id is the same
  itemsList.forEach((tempItem) => {
    if (!!tempItem.discounts) {
      tempItem.discounts.forEach((discount) => {
        delete discount.discount_amount;
      });

      if (!tempItem.combo_child_items || tempItem.combo_child_items.length === 0) {
        Object.keys(tempItem.addonGroups).forEach((addonGroupKey) => {
          const addonGroupItems = tempItem.addonGroups[addonGroupKey].items;
          Object.keys(addonGroupItems).forEach((addonItemKey) => {
            if (!!addonGroupItems[addonItemKey].discounts) {
              addonGroupItems[addonItemKey].discounts.forEach((discount) => {
                delete discount.discount_amount;
              });
            }
          });
        });
      }
    }
  });

  /** if addon quanaity is enabled, calculate the addon quantity */
  if (isAddonQuantityEnabled) {
    itemsList.forEach((tempItem, tempItemIndex) => {
      order.items.forEach((item, orderItemIndex) => {
        if (item.addons && getOrderItemFromCleanedUpOrder(cleanedUpOrder, tempItemIndex).addons) {
          getOrderItemFromCleanedUpOrder(cleanedUpOrder, tempItemIndex).addons.forEach((addon) => {
            addon.items.forEach((addonItem) => {
              if (tempItem.id === item.id) {
                let foundAddonGroup =
                  tempItem.addonGroups[
                    "addonGroup-" +
                      `${addonItem["addon_group_id"] ? addonItem["addon_group_id"] : addonItem.id}`
                  ];

                if (
                  foundAddonGroup &&
                  foundAddonGroup.items &&
                  foundAddonGroup.items["addon-" + addonItem.id]
                ) {
                  foundAddonGroup.items["addon-" + addonItem.id].quantity = addonItem.quantity;
                }
              }
            });
          });
        }

        if (item.combo_child_items) {
          item.combo_child_items.forEach((childItem, childItemIndex) => {
            if (childItem.addonGroups) {
              childItem.addonGroups.forEach((addonGroup) => {
                addonGroup.items.forEach((addonItem) => {
                  if (tempItem.combo_child_items) {
                    tempItem.combo_child_items.forEach(
                      (tempItemComboChild, tempItemComboChildIndex) => {
                        if (
                          tempItemIndex === orderItemIndex &&
                          childItemIndex === tempItemComboChildIndex &&
                          tempItemComboChild.id === childItem.id &&
                          childItemIndex + 1 === Number(tempItemComboChild.group_id)
                        ) {
                          if (addonItem["addon_group_id"]) {
                            tempItemComboChild.addonGroups
                              .find(
                                (tempAddonGroup) =>
                                  tempAddonGroup.id === addonItem["addon_group_id"]
                              )
                              .items.find(
                                (tempAddonItem) => tempAddonItem.id === addonItem.id
                              ).quantity = getOrderAddonOccurrence(
                              addonGroup.items,
                              JSON.stringify(addonItem.id),
                              JSON.stringify(addonItem.modifierGroups)
                            );
                          } else {
                            let foundAddon = tempItemComboChild.addonGroups.forEach(
                              (addonGroup) => {
                                addonGroup.items.find(
                                  (tempAddonGroupItem) => tempAddonGroupItem.id === addonItem.id
                                );
                                if (foundAddon) {
                                  foundAddon.quantity = getOrderAddonOccurrence(
                                    addonGroup.items,
                                    JSON.stringify(addonItem.id),
                                    JSON.stringify(addonItem.modifierGroups)
                                  );
                                }
                              }
                            );
                          }
                        }
                      }
                    );
                  }
                });
              });
            }
          });
        }
      });
    });
  }

  //calculate quantity
  itemsList.forEach((tempItem) => {
    delete tempItem.quantity;
    delete tempItem.entryKey;

    tempItem.quantity = getOrderOccurrence(
      itemsList,
      tempItem.id,
      JSON.stringify(tempItem.discounts),
      JSON.stringify(tempItem.addonGroups),
      JSON.stringify(tempItem.modifierGroups),
      tempItem.total_addon_discount,
      JSON.stringify(tempItem.combo_child_items)
    );
  });

  const itemListWithOccurrence = jsonCopy(itemsList);

  //set all the object keys alphabetically
  itemListWithOccurrence.forEach((tempItem) => {
    delete tempItem.parentId;
  });

  //remove duplicates from itemsList
  const itemListWithOutDuplicates = removeDuplicates(itemListWithOccurrence, (item) =>
    JSON.stringify(item)
  );

  return itemListWithOutDuplicates;
};

export const calculateItemIndex = (index, itemsList) => {
  if (index === 0) {
    return 0;
  }

  let quantityCounter = 0;
  for (var i = 0; i < index; i++) {
    let itemQuantity = !!itemsList[i].quantity ? itemsList[i].quantity : 1;
    quantityCounter += parseInt(itemQuantity);
  }

  return quantityCounter;
};

export const getTotalItemPriceBasedOn1307 = (item) => {
  let totalPrice = Number(item["price_for_display"]);

  item.addonGroups.forEach((addonGroup) => {
    const addonGroupItems = addonGroup.items;
    addonGroupItems.forEach((addon) => {
      totalPrice += Number(addon["price_for_display"]);
    });
  });
  return totalPrice;
};

export const checkPriceChanges = (orderSummaryData, menuContext, activeOrderType) => {
  const orderSummaryItems = orderSummaryData.items;
  const menu = menuContext.apiData;
  if (!menu || activeOrderType === "dinein") {
    return false;
  }
  const isThreeLevelMenu = menuContext.menuLevel === "threeLevels";
  let atLeastOnePriceChanges = false;
  if (isThreeLevelMenu) {
    Object.keys(menu).forEach((categoryKey) => {
      const subcategories = menu[categoryKey].subcategories;
      Object.keys(subcategories).forEach((subcategoryKey) => {
        const items = subcategories[subcategoryKey].items;
        Object.keys(items).forEach((itemKey) => {
          const item = items[itemKey];

          const matchingItems = orderSummaryItems.filter((tempItem) => tempItem.id === item.id);
          if (matchingItems.length > 0) {
            if (matchingItems[0]["price_for_display"] !== item.price && item.type !== "comboItem") {
              atLeastOnePriceChanges = true;
            }
          }
        });
      });
    });
  } else {
    Object.keys(menu).forEach((subcategoryKey) => {
      const items = menu[subcategoryKey].items;
      Object.keys(items).forEach((itemKey) => {
        const item = items[itemKey];

        const matchingItems = orderSummaryItems.filter((tempItem) => tempItem.id === item.id);
        if (matchingItems.length > 0) {
          if (matchingItems[0]["price_for_display"] !== item.price && item.type !== "comboItem") {
            atLeastOnePriceChanges = true;
          }
        }
      });
    });
  }

  return atLeastOnePriceChanges;
};

/**
 *
 * @param arr (Array): The array of objects to count instances of.
 * @param obj {object}: The object which we want to count the instances of
 * @param ignoreKeys (Array): An optional array of keys to ignore when counting instances.
 * @returns (Int) number of instances of obj in arr
 */
export const countInstances = (arr, obj, ignoreKeys = []) => {
  let count = 0;

  arr.forEach((item) => {
    if (typeof item === "object" && item !== null) {
      if (isEqual(item, obj, ignoreKeys)) {
        if (obj.quantity) {
          count = count + parseInt(obj.quantity);
        } else {
          count++;
        }
      } else {
        count += countInstances(Object.values(item), obj, ignoreKeys);
      }
    }
  });

  return count;
};

/**
 *
 * @param obj1 (Object): The first object to compare.
 * @param obj2 (Object): The second object to compare.
 * @param ignoreKeys (Array): An optional array of keys to ignore when comparing the objects.
 * @returns (Boolean): true if the two objects are equal (excluding the ignored keys), false otherwise.
 */
export const isEqual = (obj1, obj2, ignoreKeys = []) => {
  if (!obj1 || !obj2) {
    return false;
  }
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  for (let key of obj1Keys) {
    if (ignoreKeys.includes(key)) {
      continue;
    }

    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === "object" && val1 !== null) {
      if (!isEqual(val1, val2, ignoreKeys)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
};

/**
 *
 * @param arr (Array): The array of objects to remove duplicates from.
 * @param ignoreKeys (Array): An optional array of keys to ignore when removing duplicates.
 * @returns (Array): A new array containing only the unique objects from arr, excluding objects with any of the ignored keys.
 */
const removeDuplicateInstances = (arr, ignoreKeys = []) => {
  const uniqueObjects = [];
  const visitedObjects = new Set();

  arr.forEach((item) => {
    if (typeof item === "object" && item !== null) {
      if (!visitedObjects.has(item)) {
        visitedObjects.add(item);

        let isDuplicate = false;

        for (let i = 0; i < uniqueObjects.length; i++) {
          if (isEqual(item, uniqueObjects[i], ignoreKeys)) {
            isDuplicate = true;
            break;
          }
        }

        if (!isDuplicate) {
          uniqueObjects.push(item);
        }
      }
    } else {
      uniqueObjects.push(item);
    }
  });

  return uniqueObjects;
};

/**
 *
 * @param arr (Array) list of items to clean up
 * @param keyToRemove (String) key to remove from all objects in arr
 * @returns
 */
const removeKeyFromArrayObjects = (arr, keyToRemove) => {
  arr.forEach((obj) => {
    if (obj[keyToRemove]) {
      delete obj[keyToRemove];
    }

    Object.values(obj).forEach((val) => {
      if (Array.isArray(val)) {
        val.forEach((item) => {
          if (typeof item === "object" && item !== null) {
            removeKeyFromObject(item, keyToRemove);
          }
        });
      } else if (typeof val === "object" && val !== null) {
        removeKeyFromObject(val, keyToRemove);
      }
    });
  });

  return arr;
};

/**
 *
 * @param obj (Object) to clean up
 * @param keyToRemove (String) key to remove from obj
 */
const removeKeyFromObject = (obj, keyToRemove) => {
  if (obj[keyToRemove]) {
    delete obj[keyToRemove];
  }

  Object.values(obj).forEach((val) => {
    if (Array.isArray(val)) {
      val.forEach((item) => {
        if (typeof item === "object" && item !== null) {
          removeKeyFromObject(item, keyToRemove);
        }
      });
    } else if (typeof val === "object" && val !== null) {
      removeKeyFromObject(val, keyToRemove);
    }
  });
};

/**
 *
 * @param itemsList (Array) list of items which we want to consolidate the child items of the combos
 * @returns (Array) a new list of items which the combo items have been consolidated if required
 */
export const consolidateComboChildItems = (itemsList) => {
  const KEYS_TO_IGNORE = ["entryKey", "quantity", "isFirstInstanceDefault"];
  let itemsListCopy = jsonCopy(itemsList);
  itemsListCopy.forEach((item) => {
    //if item is a combo item
    if (item.combo_child_items && item.combo_child_items.length > 0) {
      item.combo_child_items = removeKeyFromArrayObjects(
        item.combo_child_items,
        "isFirstInstanceDefault"
      );

      //for each combo childItem calculate the quantity
      item.combo_child_items.forEach((childItem) => {
        const numberOfInstances = countInstances(item.combo_child_items, childItem, KEYS_TO_IGNORE);
        childItem.quantity = numberOfInstances;
      });

      //remove the duplicates from the list
      item.combo_child_items = removeDuplicateInstances(item.combo_child_items, KEYS_TO_IGNORE);
    }
  });
  return itemsListCopy;
};

/// debug Helper function to detect the difference between two objects
export const diffObjects = (obj1, obj2) => {
  const result = {};

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const allKeys = new Set([...keys1, ...keys2]);

  allKeys.forEach((key) => {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === "object" && val1 !== null && typeof val2 === "object" && val2 !== null) {
      const nestedDiff = diffObjects(val1, val2);

      if (Object.keys(nestedDiff).length > 0) {
        result[key] = nestedDiff;
      }
    } else if (!isEqualObjects(val1, val2)) {
      result[key] = [val1, val2];
    }
  });

  return result;
};

export const isEqualObjects = (val1, val2) => {
  if (typeof val1 === "object" && val1 !== null && typeof val2 === "object" && val2 !== null) {
    return JSON.stringify(val1) === JSON.stringify(val2);
  } else {
    return val1 === val2;
  }
};

/**
 *
 * @param {Array} cart - array of items in the cart
 * @param {String || number} itemId - id of the item is questions
 * @returns total quantity of the item in question
 */
export const getItemQuantityFromCart = (cart, itemId) => {
  let counter = 0;

  cart.forEach((item) => {
    if (item.id === itemId) {
      counter += item.quantity || 1;
    }
  });

  return Number(counter);
};

export const hasMultipleInstancesOfItemInCart = (cart, item) => {
  const list = cart.filter((tempItem) => tempItem.id === item.id);
  const matchedItem = list.filter((tempItem) => tempItem.entryKey === item.entryKey)[0];
  const nonMatchedList = list.filter(
    (tempItem) => JSON.stringify(tempItem) !== JSON.stringify(matchedItem)
  );
  let nonMatchedListQuantity = 0;
  nonMatchedList.forEach((item) => {
    nonMatchedListQuantity += item.quantity;
  });
  return {
    multipleInstances: list.length > 1, // flag to see if there are multiple instances of this item
    instanceList: list, // list of all instances of the item
    matchingElement: matchedItem, //matched item from the list of instances
    matchingElementQuantity: matchedItem ? matchedItem.quantity : "", // quantity of the matchend item
    nonmatchingElementsQuantities: nonMatchedListQuantity, // combined quantity of all the other instances that are not matched item
  };
};

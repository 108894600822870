import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useWindowSize from "../../_common/hooks/useWindowSize";

/** Contexts */
import UserRoleContext from "../../App/UserRoleContext";

/** UI Components */
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { NoRecentOrders } from "./NoRecentOrders";
import { PastOrdersCard } from "./PastOrdersCard";

export const PastOrders = (props) => {
  const {
    recentOrders,
    appLabels,
    formatOrderDateAndTime,
    foodMenu,
    cart,
    updateIsNotification,
    setIsAlertNotification,
    menuLevel,
  } = props;

  const deviceWidth = useWindowSize().width;
  const history = useHistory();

  let pastOrderLength = 0;

  if (recentOrders) {
    pastOrderLength = recentOrders.filter(
      (order) =>
        order.orderStatus && order.orderStatus.isEnabled && order.orderStatus.isFinished !== false
    ).length;
  }

  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  useEffect(() => {
    if (loginToken === null) {
      history.push("/login-register");
    }
  }, [loginToken]);

  return (
    <>
      {recentOrders == null && (
        <span className="recent-order__loading">
          <LoadingSpinner />
          <div className="recent-order__loading-text">
            {deviceWidth < 600 ? (
              <p>
                {appLabels["order"]["past-orders-loading"].slice(0, 11)}
                <br />
                {appLabels["order"]["past-orders-loading"].split("!").pop("!")}
              </p>
            ) : (
              <p>{appLabels["order"]["past-orders-loading"]}</p>
            )}
          </div>
        </span>
      )}
      {recentOrders !== null && recentOrders !== true && pastOrderLength < 1 && <NoRecentOrders />}

      {recentOrders &&
        !!recentOrders.filter(
          (order) =>
            order.orderStatus &&
            order.orderStatus.isEnabled &&
            order.orderStatus.isFinished !== false
        ).length && (
          <section>
            <p className="recent-order__section-header">
              {appLabels["account"]["recent-orders-disclaimer"]}
            </p>
            {recentOrders.map((order) => (
              <React.Fragment key={order.id}>
                {order.orderStatus.isFinished && (
                  <PastOrdersCard
                    order={order}
                    appLabels={appLabels}
                    formatOrderDateAndTime={formatOrderDateAndTime}
                    foodMenu={foodMenu}
                    cart={cart}
                    updateIsNotification={updateIsNotification}
                    setIsAlertNotification={setIsAlertNotification}
                    menuLevel={menuLevel}
                  />
                )}
              </React.Fragment>
            ))}
          </section>
        )}
    </>
  );
};

//Libraries
import React from "react";

//Helper functions
import { callAPI } from "../../_common/Api";
import { capitalizeWords } from "../../_common/stringHelpers";

//UI
import { ReactComponent as IconClock } from "../../_common/icons/IconClock.svg";

export const getOrderStatusData = async (skin, language, order) => {
  const orderStatusData = await getOrderStatus(skin, language, order.id);
  if (!orderStatusData.error) {
    const tempOrderStatus = { ...orderStatusData };
    return tempOrderStatus;
  } else {
    console.error(orderStatusData.error);
  }
};

export const getOrderStatus = async (skin, language, onlineOrderId) => {
  const orderStatusData = await callAPI(skin, "vxl_1326", {
    params: [language, "1326", "mqid", "mqpass", onlineOrderId, "on"],
    id: "1326",
  });

  if (orderStatusData) {
    if (orderStatusData.result.I1 === "0") {
      let orderStatus = {};
      orderStatus.status = orderStatusData.result.I2;
      orderStatus.isFinished = orderStatusData.result.I3 === "t";
      orderStatus.onlineOrderId = onlineOrderId;
      orderStatus.isEnabled = true;
      orderStatus.allStatuses = orderStatusData.result.I4.split(",");
      return orderStatus;
    } else {
      return { error: orderStatusData.result, errorCode: orderStatusData.status, isEnabled: false };
    }
  }
};

export const getDayOfWeekFromIndexForOrderStatus = (dayIndex, labels) => {
  const weekdays = [
    labels["general"]["sunday"],
    labels["general"]["monday"],
    labels["general"]["tuesday"],
    labels["general"]["wednesday"],
    labels["general"]["thursday"],
    labels["general"]["friday"],
    labels["general"]["saturday"],
  ];
  return weekdays[dayIndex];
};

export const getMonthFromIndexForOrderStatus = (monthIndex, labels) => {
  const monthNames = [
    labels["general"]["january"],
    labels["general"]["february"],
    labels["general"]["march"],
    labels["general"]["april"],
    labels["general"]["may"],
    labels["general"]["june"],
    labels["general"]["july"],
    labels["general"]["august"],
    labels["general"]["september"],
    labels["general"]["october"],
    labels["general"]["november"],
    labels["general"]["december"],
  ];

  return monthNames[monthIndex];
};

export const getOrderDateAndAddress = (orderDetails, appLabels, fullInfo) => {
  const orderType = orderDetails.orderType;
  /** Format the date object so that it can be handled on both Chrome and iOS browsers */
  const crossPlatformDateObject = new Date(orderDetails.date.replace(" ", "T"));

  const orderDay = capitalizeWords(
    getDayOfWeekFromIndexForOrderStatus(crossPlatformDateObject.getDay(), appLabels).substr(0, 3)
  );
  const orderDate = crossPlatformDateObject.getDate();
  const orderMonth = capitalizeWords(
    getMonthFromIndexForOrderStatus(crossPlatformDateObject.getMonth(), appLabels).substr(0, 3)
  );
  const orderHour = crossPlatformDateObject.getHours().toString().padStart(2, "0");
  const orderMinute = crossPlatformDateObject.getMinutes().toString().padStart(2, "0");
  const orderAddress = orderDetails.orderAddress;
  const orderStoreName = orderDetails.storeLocation.storeName;

  return (
    <>
      <div className="order-status__order-id">
        <span>
          <IconClock />
          {`${orderDay.substr(0, 3)} ${orderMonth} ${orderDate} ${
            appLabels["account"]["order-timestamp-at"]
          } ${orderHour}:${orderMinute}`}
        </span>
        <span>#{orderDetails.id}</span>
      </div>
      {fullInfo && (
        <p className="order-status__order-details-date-address">
          <span className="order-status__order-details-date-address__header">{`${appLabels["order"]["order-type"]} :`}</span>
          {` ${appLabels["order"][orderType.toLowerCase()]} `}
          <br />
          <span className="order-status__order-details-date-address__header">{`${appLabels["order"]["ordering-from"]}:`}</span>
          {` ${decodeURI(orderStoreName)}, ${decodeURI(orderAddress.street1)} ${decodeURI(
            orderAddress.street2
          )} `}
          <br />
          {`${decodeURI(orderAddress.city)}, ${orderAddress.stateCode.split("-")[1]}, ${
            orderAddress.zipCode
          }`}
        </p>
      )}
    </>
  );
};

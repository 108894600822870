import React, { useContext, useState, useEffect, useRef } from "react";

import { Form } from "../../_common/Form/Form"
import { FormInput } from "../../_common/Form/FormInput"
import { FormFieldset } from "../../_common/Form/FormFieldset";
import { FormSelect } from "../../_common/Form/FormSelect";
import useWindowSize from "../../_common/hooks/useWindowSize";
import { FormTextarea } from "../../_common/Form/FormTextarea";
import { FormDateTimeInputCombo } from '../../_common/Form/FormDateTimeInputCombo';
import MerchantConfigContext from "../../App/MerchantConfigContext";
import { nextDeliveryDateTime } from "../giftCardHelpers";
import { toDollars } from "../../_common/helpers";
import AppLanguageContext from "../../App/AppLanguageContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import { FormSelectInputCombo } from "../../_common/Form/FormSelectInputCombo";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import GCCartContext from "../GCCartContext";

export const AddGiftCardDetailsForm = (props) => {

  const {
    denominationList,
    isCustomDenomination,
    updateGiftCardDetails,
    recipientIndex,
    isEditingRecipient = false,
    cartItemDetails = null,
    triggerFormsSubmit = false,
    isEditingCartItem = false,
    setFormRecipientsAreValid,
    recipientStatus,
    appLabels,
    storedUserFirstName,
    clearFormGiftCardAmount = false,
    setClearFormGiftCardAmount,
    setIsAnyValuePresent = null,
    setTriggerFormsSubmit = null,
    numberOfRecipients = 0
  } = props;

  const appLanguage = useContext(AppLanguageContext)
  const appSettings = useContext(AppSettingsContext)
  const [isSmall, setIsSmall] = useState(false)
  const [quickSubmit, setQuickSubmit] = useState(true) //used to show quick action button attached to greetings input
  const [quickSubmitActive, setQuickSubmitActive] = useState(false) // flag to set quick submit button active or inactive
  const [clearFormActive, setClearFormActive] = useState(false) // flag to set clear form button active or inactive
  const deviceWidth = useWindowSize().width;
  const [gcDenominationList, setGCDenominationList] = useState([]) // arrary to store denominations if present for the selected gift card
  const [clearDateInput, setClearDateInput] = useState(false) // used to manually clear date input field when clear form button is pressed
  const [isOptionalOtherInputAllowed, setisOptionalOtherInputAllowed] = useState(false)
  const [showInputSelectCombo, setShowInputSelectCombo] = useState(false)
  const [isCustomAmountEntered, setIsCustomAmountEntered] = useState(false)
  const [isDeliveryTimeChanged, setIsDeliveryTimeChanged] = useState(false)
  const [isSoloAmount, setIsSoloAmount] = useState(false)

  const [hasGreetingsMessage, setHasGreetingsMessage] = useState(false)

  const merchantConfigContext = useContext(MerchantConfigContext)
  const isTimePickerEnabled = merchantConfigContext.merchant.I57 !== "" ? true : false
  const calendarOptions = merchantConfigContext.merchant.I29

  // trackedFormData is collection of all input field values. Used to check value and deactivate/activate action buttons
  const [trackedFormData, setTrackedFormData] = useState({ "recipientName": "", "eGiftCardAmount": null, "senderName": storedUserFirstName, "recipientEmail": "", "confirmRecipientEmail": "", "deliveryDateAndTime": nextDeliveryDateTime(false, isTimePickerEnabled).timestamp.toString(), "greetingsMessage": "" })
  const [cartItemsLimitReached, setCartItemsLimitReached] = useState(false)
  const [numOfItemsInCart, setNumOfItemsInCart] = useState(0)
  const [defaultDateValue, setDefaultDateValue] = useState(null)

  const formRef = useRef();
  const cartContext = useContext(GCCartContext)

  let maxAmount = merchantConfigContext.merchant.I4[1] // max amount for a gift card for a given merchant
  let minAmount = merchantConfigContext.merchant.I4[0] // minimmum amount for a gift card for a given merchant
  const cartItemsLimit = merchantConfigContext.merchant.I4[3]


  if (maxAmount && maxAmount !== "") {
  maxAmount = toDollars(
    appSettings["currency-symbol"],
    appSettings["currency-symbol-side"],
    maxAmount,
    appLanguage
  )
  }
  if (minAmount && minAmount !== "") {
  minAmount = toDollars(
    appSettings["currency-symbol"],
    appSettings["currency-symbol-side"],
    minAmount,
    appLanguage
  )
  }

  useEffect(() => {
    if (deviceWidth < 660) {
      setIsSmall(false)
    }
    else {
      setIsSmall(true)
    }
  }, [deviceWidth])

  // useEffect(() => {
  //   if (defaultDateValue) {
  //     let tmpTrackedFormData = { ...trackedFormData }
  //     tmpTrackedFormData.deliveryDateAndTime = defaultDateValue.toString()
  //     setTrackedFormData(tmpTrackedFormData)
  //   }

  // }, [defaultDateValue])

  useEffect(() => {
    if (storedUserFirstName && storedUserFirstName !== "") {
      let trackedDataCopy = { ...trackedFormData }
      trackedDataCopy.senderName = storedUserFirstName
      setTrackedFormData(trackedDataCopy)
    }

  }, [storedUserFirstName])

  useEffect(() => {
    if (isEditingCartItem) {
      let trackedDataCopy = { ...trackedFormData }
      trackedDataCopy.eGiftCardAmount = cartItemDetails.amount
      trackedDataCopy.senderName = cartItemDetails.senderName
      trackedDataCopy.recipientName = cartItemDetails.recipientName
      trackedDataCopy.recipientEmail = cartItemDetails.recipientEmail

      trackedDataCopy.confirmRecipientEmail = cartItemDetails.recipientEmail
      trackedDataCopy.deliveryDateAndTime = cartItemDetails.deliveryDateAndTime

      trackedDataCopy.greetingsMessage = cartItemDetails.message
      setHasGreetingsMessage(true)
      setTrackedFormData(trackedDataCopy)
    }
  }, [isEditingCartItem])

  useEffect(() => {
    // isCustomDenomination is true or false if selected gift card allows regular input of amount or set amount which is selected from select list
    if (isCustomDenomination && denominationList && denominationList[0] !== "-1") {
      setGCDenominationList(denominationList)
    }
    else {
      setGCDenominationList([])
    }

    if (cartItemDetails) {
      setClearFormActive(true)
      setQuickSubmitActive(true)
    }
    if (cartContext.value) {
      setNumOfItemsInCart(cartContext.value.length)
    }

    if (isTimePickerEnabled) {
      let splitTime = merchantConfigContext.merchant.I57.split(":");
      let now = new Date()
      let defaultDateTime = new Date()
      defaultDateTime.setHours(splitTime[0])
      defaultDateTime.setMinutes(splitTime[1])

      if (now < defaultDateTime) {
        setDefaultDateValue(defaultDateTime)
      } else {
        setDefaultDateValue(null)
      }
    }
  }, [])

  useEffect(() => {
    // triggerFormsSubmit flag sets in motion automatic submission of recipient form
    if (triggerFormsSubmit) {
      let element = document.getElementById(recipientIndex + "-submitRecipientButton")
      if (recipientStatus !== "deleted") {
        element.click()
      } 
    }
  }, [triggerFormsSubmit])

  useEffect(() => {
    let isKeyEmpty = false
    let doesFormHasValue = false
    for (const key in trackedFormData) {
      if ((trackedFormData[key] === "" || !trackedFormData[key]) && key !== "greetingsMessage") {
        isKeyEmpty = true
        break
      }
    }
    let totalEmptyValues = 0

    for (const key in trackedFormData) {
      if (trackedFormData[key] && trackedFormData[key] !== "") {

        if (key === "deliveryDateAndTime" && isDeliveryTimeChanged) {
          doesFormHasValue = true
        }
        else if (key === "deliveryDateAndTime" && !isDeliveryTimeChanged) {
          doesFormHasValue = false
        }
        else if (storedUserFirstName && storedUserFirstName !== "" && key === "senderName" && trackedFormData[key] === storedUserFirstName) {
          doesFormHasValue = false
        } else if (key === "eGiftCardAmount" && isSoloAmount) {
          doesFormHasValue = false
        }
        else {
          doesFormHasValue = true
          break;
        }
      } else if ((!trackedFormData[key] || trackedFormData[key] === "") && key.toString() !== "deliveryDateAndTime") {
        totalEmptyValues = totalEmptyValues + 1
      }
    }
    if (totalEmptyValues !== 0) {
      setQuickSubmitActive(false)
    }
    else if (!isKeyEmpty) {
      setQuickSubmitActive(true)
    } 
    if (doesFormHasValue) {
      if (!isEditingCartItem)
        setIsAnyValuePresent(true)

      setClearFormActive(true)

    }
    if (!doesFormHasValue) {
      setIsAnyValuePresent(false)
    }

  }, [trackedFormData, gcDenominationList])

  useEffect(() => {
    if (defaultDateValue && !isEditingCartItem) {

      let trackedDataCopy = { ...trackedFormData }
      trackedDataCopy.deliveryDateAndTime = defaultDateValue
      setTrackedFormData(trackedDataCopy)
    }
  }, [defaultDateValue])

  const trackInput = (event, deliveryDateTimeValue = null) => {
    let trackedCopy = { ...trackedFormData }
    trackedCopy.eGiftCardAmount = trackedFormData.eGiftCardAmount 

    if (deliveryDateTimeValue) {
      event.target = event
      event.target.value = deliveryDateTimeValue
      setIsDeliveryTimeChanged(true)
    }
    if (event.target.name in trackedFormData) {
      trackedCopy[event.target.name] = event.target.value
      setTrackedFormData(trackedCopy)
      if (isEditingCartItem) {
        trackInputChanges(trackedCopy)
      }
    }
  }

  function submitGiftCardRecipient(data, isFormValid) {
    let element = data.find((i) => i.name === "recipientName")
    setFormRecipientsAreValid(isFormValid)
    if (!isFormValid) {
      setTriggerFormsSubmit(false)
    }
    if (isFormValid) {
      if (element && element.name !== "" && isFormValid) {
        data.recipientIndex = recipientIndex
        updateGiftCardDetails(data, isEditingRecipient)

      }
    }
  }

  function submitRecipientData(isTryingToAddNewRow) {

    if (cartItemsLimit !== "" && (numberOfRecipients + numOfItemsInCart) >= Number(cartItemsLimit) && !triggerFormsSubmit) {
      setCartItemsLimitReached(true)
      setTimeout(() => {
        setCartItemsLimitReached(false)
      }, 3000);
    } else if (cartItemsLimit !== "" && (numberOfRecipients + numOfItemsInCart) > Number(cartItemsLimit) && triggerFormsSubmit) {
      setCartItemsLimitReached(true)
      setTimeout(() => {
        setCartItemsLimitReached(false)
      }, 3000);
    } else {
    formRef.current.handleQuickSubmit()
      setQuickSubmit(true)
    }
  }

  function clearForm() {
    let targetForm = document.getElementById("form--gift-card-" + recipientIndex)
    targetForm.reset()
    formRef.current.handleFormAllClear()
    setClearFormActive(false)
    setClearDateInput(true)
    setQuickSubmitActive(false)
    setIsDeliveryTimeChanged(false)
    setIsCustomAmountEntered(false)

    // reset/clear all form data in object
    let trackedDataCopy = { ...trackedFormData }
    if (!isSoloAmount) {
      trackedDataCopy.eGiftCardAmount = null
    }
    trackedDataCopy.deliveryDateAndTime = defaultDateValue && !isEditingCartItem ? defaultDateValue : nextDeliveryDateTime(false, isTimePickerEnabled).timestamp
    trackedDataCopy.senderName = ""
    trackedDataCopy.recipientName = ""
    trackedDataCopy.recipientEmail = ""
    trackedDataCopy.confirmRecipientEmail = ""
    trackedDataCopy.greetingsMessage = ""
    setTrackedFormData(trackedDataCopy)
  }
  const trackInputChanges = (newData) => {

    if (newData.recipientName !== cartItemDetails.recipientName) {
      setIsAnyValuePresent(true)
    }
    else if (newData.senderName !== cartItemDetails.senderName) {
      setIsAnyValuePresent(true)
    }
    else if (newData.recipientEmail !== cartItemDetails.recipientEmail) {
      setIsAnyValuePresent(true)
    }
    else if (newData.confirmRecipientEmail !== cartItemDetails.confirmRecipientEmail) {
      setIsAnyValuePresent(true)
    }
    else if (newData.eGiftCardAmount !== cartItemDetails.amount) {
      setIsAnyValuePresent(true)
    }
    else if (newData.deliveryDateAndTime !== cartItemDetails.deliveryDateAndTime) {
      setIsAnyValuePresent(true)
    }
    else if (newData.greetingsMessage !== cartItemDetails.message) {
      setIsAnyValuePresent(true)
    } else {
      setIsAnyValuePresent(false)
    }

  }

  /*
  Another useEffect to check for denominationList. This useEffect is used 
  when user switches between gift cards of different types 
  */
  useEffect(() => {
    if (denominationList) {
      setGCDenominationList(denominationList)
      if (denominationList.length === 1) {
        let trackedDataCopy = { ...trackedFormData }
        trackedDataCopy.eGiftCardAmount = denominationList[0]
        setIsSoloAmount(true)
        setTrackedFormData(trackedDataCopy)
      } else if (denominationList.length > 0) {
        denominationList.forEach(option => {
          if (option === "-1") {
            setisOptionalOtherInputAllowed(true)
            setShowInputSelectCombo(true)
          }
        })
      } 

      if (!isEditingRecipient)
        formRef.current.clearSelectValue()
    } else {
      setGCDenominationList([])
    }

  }, [isCustomDenomination, denominationList])

  useEffect(() => {
    if (isOptionalOtherInputAllowed) {
      if (isEditingCartItem) {
        let findOption = denominationList.find((option) => option === cartItemDetails.amount)
        if (!findOption) {
          setShowInputSelectCombo(true)
          setIsCustomAmountEntered(true)
        }
      }
    }
  }, [isOptionalOtherInputAllowed])

  return (
    <div className="order-item__options-form ">
      <Form
        id={"form--gift-card-" + recipientIndex}
        ref={formRef}
        submitButtonText={"Done"}
        submitButtonSuccessText={"done"}
        submitForm={submitGiftCardRecipient}
        isQuickSubmit={quickSubmit}
        submitNavigateURL={"/dashboard"}
        submitNavigateURLStateFrom="login-register"
        isAPISubmitValid={false}
        isAPISubmitting={true}
        isFab={true}
        showAddonGroupImages={true}
        gcDenominationList={isEditingCartItem ? null : gcDenominationList}
        eGiftCardAmountLimits={{ maxAmount: merchantConfigContext.merchant.I4[1], minAmount: merchantConfigContext.merchant.I4[0] }}
        clearFormGiftCardAmount={clearFormGiftCardAmount}
        setClearFormGiftCardAmount={setClearFormGiftCardAmount}
        customAmountEntered={isCustomAmountEntered}
        showInputSelectCombo={showInputSelectCombo}
        inputSelectComboValue={cartItemDetails ? cartItemDetails.amount : null}
        isGiftCardSoloAmount={isSoloAmount}
        greetingsMessage={cartItemDetails ? cartItemDetails.message : ""}
        hasGreetingsMessage={hasGreetingsMessage}
        setHasGreetingsMessage={setHasGreetingsMessage}
        defaultDateValue={defaultDateValue}
      >
        {isCustomDenomination ?
          <FormFieldset additionalClassnames="gift-card-form-fieldset">

            <FormInput
              type="number"
              id="input--eGiftCardAmount"
              name="eGiftCardAmount"
              label={appLabels["gift-card"]["amount"]}
              isSmall={isSmall}
              isRequired={true}
              autocomplete="off"
              isHidden={false}
              showAstr={true}
              maxValue={maxAmount}
              defaultValue={cartItemDetails ? cartItemDetails.amount : trackedFormData.eGiftCardAmount}
              handleInputChangeFromParent={trackInput}
              inputInformationText={maxAmount !== "" && minAmount !== "" ? "(" + appLabels["gift-card"]["minimum"] + " " + minAmount + " " + appLabels["gift-card"]["and-text"] + " " + appLabels["gift-card"]["maximum"] + " " + maxAmount + ")" : ""}
              displayCurrencySymbol={true}
            />

            <FormDateTimeInputCombo
              type="text"
              label={appLabels["gift-card"]["delivery-date-time"]}
              id="input--deliveryDateAndTime"
              name="deliveryDateAndTime"
              isRequired={true}
              autocomplete="off"
              isHidden={false}
              isSmall={isSmall}
              handleInputChangeFromParent={trackInput}
              value={cartItemDetails ? cartItemDetails.deliveryDateTime : trackedFormData.deliveryDateAndTime}
              defaultValue={cartItemDetails ? cartItemDetails.deliveryDateTime : null}
              clearDateInput={clearDateInput}
              setClearDateInput={setClearDateInput}
              isTimePickerEnabled={isTimePickerEnabled}
              calendarOptions={calendarOptions}
            />

            <FormInput
              type="email"
              label={appLabels["gift-card"]["recipient-email"]}
              id="input--recipientEmail"
              name="recipientEmail"
              isRequired={true}
              autocomplete="off"
              isHidden={false}
              isSmall={isSmall}
              showAstr={true}
              defaultValue={cartItemDetails ? cartItemDetails.recipientEmail : null}
              handleInputChangeFromParent={trackInput}
            />

            <FormInput
              type="email"
              label={appLabels["gift-card"]["confirm-recipient-email"]}
              id="input--confirmRecipientEmail"
              name="confirmRecipientEmail"
              isRequired={true}
              autocomplete="off"
              defaultValue={cartItemDetails ? cartItemDetails.recipientEmail : null}
              isHidden={false}
              isSmall={isSmall}
              showAstr={true}
              handleInputChangeFromParent={trackInput}
            />

            <FormInput
              type="text"
              label={appLabels["gift-card"]["recipient-name"]}
              id="input--recipientName"
              name="recipientName"
              isRequired={true}
              autocomplete="off"
              defaultValue={cartItemDetails ? cartItemDetails.recipientName : null}
              isHidden={false}
              isSmall={isSmall}
              showAstr={true}

              handleInputChangeFromParent={trackInput}
            />

            <FormInput
              type="text"
              label={appLabels["gift-card"]["sender-name"]}
              id="input--senderName"
              name="senderName"
              isSmall={isSmall}
              isRequired={true}
              autocomplete="off"
              isHidden={false}
              showAstr={true}
              defaultValue={cartItemDetails ? cartItemDetails.senderName : trackedFormData.senderName}
              handleInputChangeFromParent={trackInput}
              isGCSenderName={true}
            />
            <FormTextarea
              type="textarea"
              label={appLabels["gift-card"]["greeting-message"]}
              id="input--greetings"
              name="greetingsMessage"
              isRequired={false}
              autocomplete="off"
              isHidden={false}
              isSmall={true}
              showAstr={false}
              hasActionButtons={true}
              quickSubmitActive={quickSubmitActive}
              clearFormActive={clearFormActive}
              submitForm={submitRecipientData}
              clearForm={clearForm}
              defaultValue={cartItemDetails ? trackedFormData.greetingsMessage : null}
              handleInputChangeFromParent={trackInput}
              isEditingRecipient={isEditingRecipient}
              recipientIndex={recipientIndex}
              isEditingCartItem={isEditingCartItem}
            />

          </FormFieldset> :
          (
            showInputSelectCombo ?
              <FormFieldset additionalClassnames="gift-card-form-fieldset">
                <FormSelectInputCombo
                  id="input--eGiftCardAmount"
                  name="eGiftCardAmount"
                  label={appLabels["gift-card"]["gift"] + " " + appLabels["gift-card"]["amount"]}
                  isRequired={true}
                  isSmall={isSmall}
                  showAstr={true}
                  handleInputChangeFromParent={trackInput}
                  displayCurrencySymbol={true}
                  hasStartIcon={true}
                  customOptions={gcDenominationList}
                  value={cartItemDetails ? cartItemDetails.amount : ""}
                  defaultValue={cartItemDetails ? isCustomDenomination ? cartItemDetails.amount : cartItemDetails.amount : ""}
                  isEditingGiftCardAmount={isEditingCartItem}
                  isCustomAmountEntered={isCustomAmountEntered}
                  type="number"
                  isSkipEmptyOption={true}
                  inputInformationText={maxAmount !== "" && minAmount !== "" ? "(" + appLabels["gift-card"]["minimum"] + " " + minAmount + " " + appLabels["gift-card"]["and-text"] + " " + appLabels["gift-card"]["maximum"] + " " + maxAmount + ")" : ""}
                >
                </FormSelectInputCombo>

                <FormDateTimeInputCombo
                  type="text"
                  label={appLabels["gift-card"]["delivery-date-time"]}
                  id="input--deliveryDateAndTime"
                  name="deliveryDateAndTime"
                  isRequired={true}
                  autocomplete="off"
                  isHidden={false}
                  isSmall={isSmall}
                  handleInputChangeFromParent={trackInput}
                  value={cartItemDetails ? cartItemDetails.deliveryDateTime : trackedFormData.deliveryDateAndTime}
                  defaultValue={cartItemDetails ? cartItemDetails.deliveryDateTime : null}
                  clearDateInput={clearDateInput}
                  setClearDateInput={setClearDateInput}
                  isTimePickerEnabled={isTimePickerEnabled}
                  calendarOptions={calendarOptions}
                />

                <FormInput
                  type="email"
                  label={appLabels["gift-card"]["recipient-email"]}
                  id="input--recipientEmail"
                  name="recipientEmail"
                  isRequired={true}
                  autocomplete="off"
                  defaultValue={cartItemDetails ? cartItemDetails.recipientEmail : null}
                  isHidden={false}
                  isSmall={isSmall}
                  showAstr={true}
                  handleInputChangeFromParent={trackInput}
                />
                <FormInput
                  type="email"
                  label={appLabels["gift-card"]["confirm-recipient-email"]}
                  id="input--confirmRecipientEmail"
                  name="confirmRecipientEmail"
                  isRequired={true}
                  autocomplete="off"
                  defaultValue={cartItemDetails ? cartItemDetails.recipientEmail : null}
                  isHidden={false}
                  isSmall={isSmall}
                  showAstr={true}
                  handleInputChangeFromParent={trackInput}
                />

                <FormInput
                  type="text"
                  label={appLabels["gift-card"]["recipient-name"]}
                  id="input--recipientName"
                  name="recipientName"
                  isRequired={true}
                  autocomplete="off"
                  defaultValue={cartItemDetails ? cartItemDetails.recipientName : null}
                  isHidden={false}
                  isSmall={isSmall}
                  showAstr={true}
                  handleInputChangeFromParent={trackInput}
                />
                <FormInput
                  type="text"
                  label={appLabels["gift-card"]["sender-name"]}
                  id="input--senderName"
                  name="senderName"
                  isSmall={isSmall}
                  isRequired={true}
                  autocomplete="off"
                  isHidden={false}
                  showAstr={true}
                  handleInputChangeFromParent={trackInput}
                  defaultValue={cartItemDetails ? cartItemDetails.senderName : trackedFormData.senderName}
                />

                <FormTextarea
                  type="textarea"
                  label={appLabels["gift-card"]["greeting-message"]}
                  id="input--greetings"
                  name="greetingsMessage"
                  isRequired={false}
                  autocomplete="off"
                  isHidden={false}
                  isSmall={true}
                  showAstr={false}
                  hasActionButtons={true}
                  quickSubmitActive={quickSubmitActive}
                  submitForm={submitRecipientData}
                  clearForm={clearForm}
                  clearFormActive={clearFormActive}
                  handleInputChangeFromParent={trackInput}
                  isEditingRecipient={isEditingRecipient}
                  recipientIndex={recipientIndex}
                  defaultValue={cartItemDetails ? trackedFormData.greetingsMessage : null}
                  isEditingCartItem={isEditingCartItem}

                />

              </FormFieldset>

              :
              <FormFieldset additionalClassnames="gift-card-form-fieldset">
                <FormSelect
                  id="input--eGiftCardAmount"
                  name="eGiftCardAmount"
                  label={appLabels["gift-card"]["amount"]}
                  isRequired={true}
                  isSmall={isSmall}
                  showAstr={true}
                  handleInputChangeFromParent={trackInput}
                  displayCurrencySymbol={true}
                  hasStartIcon={true}
                  customOptions={gcDenominationList}
                  defaultValue={cartItemDetails ? cartItemDetails.amount : isEditingCartItem ? trackedFormData.eGiftCardAmount : trackedFormData.eGiftCardAmount}
                  isEditingGiftCardAmount={isEditingCartItem}
                  isSkipEmptyOption={true}
                >
                </FormSelect>

                <FormDateTimeInputCombo
                  type="text"
                  label={appLabels["gift-card"]["delivery-date-time"]}
                  id="input--deliveryDateAndTime"
                  name="deliveryDateAndTime"
                  isRequired={true}
                  autocomplete="off"
                  isHidden={false}
                  isSmall={isSmall}
                  handleInputChangeFromParent={trackInput}
                  value={cartItemDetails ? cartItemDetails.deliveryDateTime : trackedFormData.deliveryDateAndTime}
                  defaultValue={cartItemDetails ? cartItemDetails.deliveryDateTime : null}
                  clearDateInput={clearDateInput}
                  setClearDateInput={setClearDateInput}
                  isTimePickerEnabled={isTimePickerEnabled}
                  calendarOptions={calendarOptions}
                />


                <FormInput
                  type="email"
                  label={appLabels["gift-card"]["recipient-email"]}
                  id="input--recipientEmail"
                  name="recipientEmail"
                  isRequired={true}
                  autocomplete="off"
                  defaultValue={cartItemDetails ? cartItemDetails.recipientEmail : null}
                  isHidden={false}
                  isSmall={isSmall}
                  showAstr={true}
                  handleInputChangeFromParent={trackInput}
                />
                <FormInput
                  type="email"
                  label={appLabels["gift-card"]["confirm-recipient-email"]}
                  id="input--confirmRecipientEmail"
                  name="confirmRecipientEmail"
                  isRequired={true}
                  autocomplete="off"
                  defaultValue={cartItemDetails ? cartItemDetails.recipientEmail : null}
                  isHidden={false}
                  isSmall={isSmall}
                  showAstr={true}
                  handleInputChangeFromParent={trackInput}
                />

                <FormInput
                  type="text"
                  label={appLabels["gift-card"]["recipient-name"]}
                  id="input--recipientName"
                  name="recipientName"
                  isRequired={true}
                  autocomplete="off"
                  defaultValue={cartItemDetails ? cartItemDetails.recipientName : null}
                  isHidden={false}
                  isSmall={isSmall}
                  showAstr={true}
                  handleInputChangeFromParent={trackInput}
                />
                <FormInput
                  type="text"
                  label={appLabels["gift-card"]["sender-name"]}
                  id="input--senderName"
                  name="senderName"
                  isSmall={isSmall}
                  isRequired={true}
                  autocomplete="off"
                  isHidden={false}
                  showAstr={true}
                  handleInputChangeFromParent={trackInput}
                  defaultValue={cartItemDetails ? cartItemDetails.senderName : trackedFormData.senderName}
                />

                <FormTextarea
                  type="textarea"
                  label={appLabels["gift-card"]["greeting-message"]}
                  id="input--greetings"
                  name="greetingsMessage"
                  isRequired={false}
                  autocomplete="off"
                  isHidden={false}
                  isSmall={true}
                  showAstr={false}
                  hasActionButtons={true}
                  quickSubmitActive={quickSubmitActive}
                  submitForm={submitRecipientData}
                  clearForm={clearForm}
                  clearFormActive={clearFormActive}
                  handleInputChangeFromParent={trackInput}
                  isEditingRecipient={isEditingRecipient}
                  recipientIndex={recipientIndex}
                  defaultValue={cartItemDetails ? trackedFormData.greetingsMessage : null}
                  isEditingCartItem={isEditingCartItem}

                />

              </FormFieldset>
          )
        }
      </Form>

      <div className={`giftCard_sendMoreButtonWrapper ${isEditingCartItem ? "visually-hidden hideSendMoreButton" : ""}`} >
        {cartItemsLimitReached && !isEditingCartItem &&
          <p className="sendMoreButtonError">{appLabels["gift-card"]["item-order-limit"] + " " + cartItemsLimit + " " + appLabels["gift-card"]["reached"]}</p>}
        <Button type="submit" id={recipientIndex + "-submitRecipientButton"}
          onClick={() => submitRecipientData(true)}
          startIcon={<Add />}
          variant="outlined"
          className={`${quickSubmitActive ? " activeText" : ''} ${isEditingCartItem ? " form-visuaally-hidden-button" : ''} ${!quickSubmitActive ? "disabledQuickSubmitButton" : ""} `}
        >
          {appLabels["gift-card"]["send-to-more-people"]}
        </Button>

      </div>

    </div>
  )
}
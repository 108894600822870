import React from "react";
import { LoginForm } from "./LoginForm";
import { ForgotPasswordLink } from "../ForgotPassword/ForgotPasswordLink";
import "./Login.css";

export const Login = () => {
  return (
    <>
      <LoginForm />
      <ForgotPasswordLink />
    </>
  );
};

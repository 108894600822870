import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
//import AppLabelsContext from "../../App/AppLabelsContext";
//import BrandingContext from "../../App/BrandingContext";
//import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";
import { MenuContext } from "../../OnlineOrdering/Menu/MenuContext";
import { onProductClickXtreme } from "../../_common/helpers/xtremePushHelper";
import MerchantConfigContext from "../../App/MerchantConfigContext";
//import { toDollars } from "../../_common/helpers";
//import AppSettingsContext from "../../App/AppSettingsContext";
//import AppLanguageContext from "../../App/AppLanguageContext";
import { MenuCategoryCard } from "../../OnlineOrdering/Menu/MenuCategoryCard/MenuCategoryCard";
//import MerchantConfigContext from "../../App/MerchantConfigContext";
//import StoreContext from "../../OnlineOrdering/StoreContext";

export const DashboardImageAndTextCard = ({ productItem, featuredCategoryData }) => {
  //const imagePlaceholder = useContext(BrandingContext)["no-image-available-placeholder"];
  //const appLabels = useContext(AppLabelsContext);
  // const { value: orderType } = useContext(OrderTypeContext);
  const orderMenu = useContext(MenuContext);
  const skin = useContext(MerchantConfigContext).skin;
  //const appSettings = useContext(AppSettingsContext);
  //const appLanguage = useContext(AppLanguageContext);
  //const activeOrderStore = useContext(StoreContext).activeOrderStore;
  const history = useHistory();

  const goToFeaturedItem = (itemID, itemType, item) => {
    let path = "";
    if (featuredCategoryData.type.toLowerCase() === "category") {
      const categoryID = featuredCategoryData.id;
      const subcategoryID = Object.keys(featuredCategoryData.subcategories)[0].split(
        "subcategory-"
      )[1];

      //Trigger the on product click for XP when a featured item is clicked
      onProductClickXtreme(item, categoryID, subcategoryID, skin);
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: categoryID,
        activeSubcategory: subcategoryID,
        activeItem: itemID,
      });

      if (itemType === "productItem") {
        path = `online-ordering/menu/category/${categoryID}/subcategory/${subcategoryID}/item/${itemID}`;
      } else if (itemType === "comboItem") {
        path = `online-ordering/menu/category/${categoryID}/subcategory/${subcategoryID}/combo/${itemID}`;
      }
    } else if (featuredCategoryData.type.toLowerCase() === "subcategory") {
      const subcategoryID = featuredCategoryData.id;

      //Trigger the on product click for XP when a featured item is clicked
      onProductClickXtreme(item, "", subcategoryID, skin);
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: null,
        activeSubcategory: subcategoryID,
        activeItem: itemID,
      });

      path = `online-ordering/menu/subcategory/${subcategoryID}/item/${itemID}`;

      if (itemType === "productItem") {
        path = `online-ordering/menu/subcategory/${subcategoryID}/item/${itemID}`;
      } else if (itemType === "comboItem") {
        path = `online-ordering/menu/subcategory/${subcategoryID}/combo/${itemID}`;
      }
    }

    /*
    if (orderType !== "pickup") {
      sessionStorage.setItem(skin + "__featuredItemLink", path);
      sessionStorage.setItem(skin + "__featuredItemStoreId", activeOrderStore.storeId);
      // If current order type is not pickup -> redirect to that order flow
      path = `/online-ordering/${orderType}`;
    }
    */

    history.push({
      pathname: path,
      state: { from: "menu-slider" },
    });
  };

  /*const buttonLabel = appLabels["order"]["go-to-item-name"].replace(
    "[item-name]",
    productItem.name
  );*/

  return (
    <>
      {/*<li className="dashboard-horizontal-slider__card dashboard-horizontal-slider__card-featured">
      <div className="dashboard-slide__image-and-text-wrapper">
        <div className="dashboard__featured-item-img-wrapper">
          <img
            src={productItem.imageUrl || imagePlaceholder}
            alt={productItem.description}
            className="dashboard__featured-item-img"
          />
        </div>
        <div className="dashboard__featured-item-text-wrapper">
          <h3 className="dashboard__featured-item-name">{productItem.name}</h3>
          <span className="dashboard__featured-item-price">
            {toDollars(
              appSettings["currency-symbol"],
              appSettings["currency-symbol-side"],
              productItem.price,
              appLanguage
            )}
          </span>
          {productItem.calorie_count && (
            <>
              <span className="dashboard__featured-item-calory">
                {productItem.calorie_count} {appLabels["order"]["calories"]}
              </span>
            </>
          )}
        </div>
        <button
          onClick={() => goToFeaturedItem(productItem.id, productItem.type, productItem)}
          className="dashboard__slide-button"
          aria-label={buttonLabel}
          type="button"
        />
      </div>
    </li>*/}
      <MenuCategoryCard
        key={`${productItem.id}`}
        handleClick={() => goToFeaturedItem(productItem.id, productItem.type, productItem)}
        //index={index}
        itemData={{
          ...productItem,
          //category: category,
          //subcategory: subcategory,
          onlineQuantityLimit:
            !!productItem["online_qty_limit"] && productItem["online_qty_limit"] !== "None"
              ? productItem["online_qty_limit"]
              : null,
        }}
      />
    </>
  );
};

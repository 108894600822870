import React from "react";
import { Marker } from "@react-google-maps/api";
import IconStreetView from "../../_common/icons/IconStreetView.svg";
import IconMarker from "../../_common/icons/IconMarker.svg";
import IconMarkerActive from "../../_common/icons/IconMarkerActive.svg";
import IconFavMapMarker from "../../_common/icons/IconMapFav.svg";
import IconFavMapMarkerActive from "../../_common/icons/IconMapFavActive.svg";

export const GoogleMapsMarkers = (props) => {
  const {
    onUserMarkerChange,
    userLocation,
    storesInRange,
    handleMarkerClick,
    storeOnImpression,
    favoriteLocations,
  } = props;

  return (
    <>
      <Marker
        onPositionChanged={onUserMarkerChange}
        position={userLocation}
        icon={{
          url: IconStreetView,
        }}
      />
      {storesInRange &&
        storesInRange !== "idle" &&
        storesInRange.map((store) => (
          <Marker
            key={store.storeId}
            onClick={() => handleMarkerClick(store)}
            position={{
              lat: store.latitude,
              lng: store.longitude,
            }}
            icon={{
              url:
                !!favoriteLocations &&
                !!favoriteLocations.filter((storeId) => storeId === store.storeId).length
                  ? storeOnImpression && storeOnImpression.storeId === store.storeId
                    ? IconFavMapMarkerActive
                    : IconFavMapMarker
                  : storeOnImpression && storeOnImpression.storeId === store.storeId
                  ? IconMarkerActive
                  : IconMarker,
            }}
          />
        ))}
    </>
  );
};

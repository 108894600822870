import React from "react";
import { ReactComponent as IconVisa } from "../../../../_common/icons/IconVisa.svg";
import { ReactComponent as IconMastercard } from "../../../../_common/icons/IconMastercard.svg";
import { ReactComponent as IconAmex } from "../../../../_common/icons/IconAmex.svg";
import { ReactComponent as IconJCB } from "../../../../_common/icons/IconJCB.svg";
import { ReactComponent as IconDinersClub } from "../../../../_common/icons/IconDinersClub.svg";
import { ReactComponent as IconDiscover } from "../../../../_common/icons/IconDiscover.svg";
import { ReactComponent as IconHiperCard } from "../../../../_common/icons/IconHiperCard.svg";
import { ReactComponent as IconElo } from "../../../../_common/icons/IconElo.svg";
import { ReactComponent as IconSideCard } from "../../../../_common/icons/IconSideCard.svg";
import { ReactComponent as IconFininvest } from "../../../../_common/icons/IconFininvest.svg";

import { ReactComponent as IconSodexo } from "../../../../_common/icons/IconSodexo.svg";
import { ReactComponent as IconTicketDebit } from "../../../../_common/icons/IconTicketDebit.svg";

import { ReactComponent as IconEloDebit } from "../../../../_common/icons/IconEloDebit.svg";
import { ReactComponent as IconPrivateLabel } from "../../../../_common/icons/IconPrivateLabel.svg";
import { ReactComponent as IconVisaElectron } from "../../../../_common/icons/IconVisaElectron.svg";

import { ReactComponent as IconVisavale } from "../../../../_common/icons/IconVisavale.svg";

import "./PaymentMethodCreditCardIcons.css";

export const PaymentMethodCreditCardIcons = (props) => {
  const { creditCardTypes, activeType } = props;
  return (
    <div className="payment-method__credit-card-icons">
      {creditCardTypes.map((creditCardType) => {
        switch (creditCardType) {
          case "visa":
            return (
              <IconVisa
                key="visa"
                className={`icon icon--visa ${
                  (activeType !== "visa" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "mastercard":
            return (
              <IconMastercard
                key="mc"
                className={`icon icon--mc ${
                  (activeType !== "mc" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "amex":
            return (
              <IconAmex
                key="amex"
                className={`icon icon--amex ${
                  (activeType !== "amex" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "jcb":
            return (
              <IconJCB
                key="jcb"
                className={`icon icon--jcb ${
                  (activeType !== "jcb" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "dinersclub":
            return (
              <IconDinersClub
                key="dinersclub"
                className={`icon icon--dinersclub ${
                  (activeType !== "dinersclub" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "dscvr":
            return (
              <IconDiscover
                key="discovery"
                className={`icon icon--discovery ${
                  (activeType !== "discovery" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "hipercard":
            return (
              <IconHiperCard
                key="hipercard"
                className={`icon icon--hipercard ${
                  (activeType !== "hipercard" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "sidecard":
            return (
              <IconSideCard
                key="sidecard"
                className={`icon icon--sidecard ${
                  (activeType !== "sidecard" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "elo":
            return (
              <IconElo
                key="elo"
                className={`icon icon--elo ${
                  (activeType !== "elo" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "fininvest":
            return (
              <IconFininvest
                key="fininvest"
                className={`icon icon--fininvest ${
                  (activeType !== "fininvest" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );

          case "sodexo":
            return (
              <IconSodexo
                key="sodexo"
                className={`icon icon--sodexo ${
                  (activeType !== "sodexo" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "alelo":
            return (
              <IconVisavale
                key="alelo"
                className={`icon icon--alelo ${
                  (activeType !== "alelo" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "ticket_debit":
            return (
              <IconTicketDebit
                key="ticket-debit"
                className={`icon icon--ticket-debit ${
                  (activeType !== "ticket-debit" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "elodebit":
            return (
              <IconEloDebit
                key="elo-debit"
                className={`icon icon--elo-debit ${
                  (activeType !== "elo-debit" && activeType !== "elo" && activeType !== "") ||
                  activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "private":
            return (
              <IconPrivateLabel
                key="private-label"
                className={`icon icon--private-label ${
                  (activeType !== "private-label" && activeType !== "") || activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          case "visadebit":
            return (
              <IconVisaElectron
                key="visa-debit"
                className={`icon icon--visa-debit ${
                  (activeType !== "visadebit" && activeType !== "visa" && activeType !== "") ||
                  activeType === "invalid"
                    ? "cc-type--inactive"
                    : ""
                }`}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

import React from "react";

export const AdditionalDisclaimer = (props) => {
  const { disclaimer, styleObject } = props;

  return (
    <>
      {!!disclaimer && (
        <p className="additional-disclaimer" style={styleObject || {}}>
          {disclaimer}
        </p>
      )}
    </>
  );
};

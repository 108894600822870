import React from "react";
import { ReactComponent as IconPlus } from "../../../_common/icons/IconPlus.svg";
import { ReactComponent as IconMinus } from "../../../_common/icons/IconMinus.svg";
//import { jsonCopy } from "../../_common/helpers";

export const RewardQuantityButtons = (props) => {
  const {
    quantityValue,
    inputId,
    appLabels,
    updateRewardQuantity,
  } = props;

  return (
    <div className="reward__quantity-container">
      <div className="reward__quantity-buttons-container">
        <button
          data-quantity="minus"
          data-field="quantity"
          type="button"
          className="reward__quantity-button reward__quantity-button-minus"
          disabled={quantityValue <= 1}
          aria-label={appLabels["order"]["decrease-quantity"]}
          onClick={() => {
            updateRewardQuantity(false);
          }}
        >
          <IconMinus />
        </button>
        <input
          className="reward__quantity-input"
          readOnly
          type="number"
          name="quantity"
          id={inputId}
          value={quantityValue}
          min={1}
        />
        <button
          data-quantity="plus"
          data-field="quantity"
          type="button"
          className="reward__quantity-button reward__quantity-button-plus"
          aria-label={appLabels["order"]["increase-quantity"]}
          onClick={() => {
            updateRewardQuantity(true);
          }}
        >
          <IconPlus />
        </button>
      </div>
    </div>
  );
};

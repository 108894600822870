import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";
import he from "he";

import { formatCartForApi } from "../../../_common/CartHelpers";
import { formatOrderType, getDeviceTypeId, toDollars } from "../../../_common/helpers";
import {
  getDayOfWeekFromIndex,
  getMonthFromIndex,
} from "../../Locations/helpers/isStoreOpenOrClosed";
import { getTimeAmPm, getTrueBusinessDate } from "../../../Dashboard/DashboardOrder/dateHelpers";

import { callAPI } from "../../../_common/Api";
import {
  updateLocationData,
  ensureOrderTimeMinInterval,
  isOrderTimePassed,
  isOrderTimeBeforeOpeningTime,
  isOrderTimeBlocked,
  isLateOrderAttempted,
  getOrderStoreAddressForDialogModal,
  isStoreClosedToday,
} from "../../../_common/PaymentHelpers";

import MerchantConfigContext from "../../../App/MerchantConfigContext";
import OrderTypeContext from "../../OrderTypeContext";
import StoreContext from "../../StoreContext";
import CartContext from "../../Cart/CartContext";
import AppSettingsContext from "../../../App/AppSettingsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import OrderTimeContext from "../../OrderTimeContext";
import AppLabelsContext from "../../../App/AppLabelsContext";

import { ReactComponent as IconUtensils } from "../../../_common/icons/IconUtensils.svg";
import { ReactComponent as IconPaperBag } from "../../../_common/icons/IconPaperBag.svg";

import { Form } from "../../../_common/Form/Form";
import { DialogModal } from "../../../_common/DialogModal/DialogModal";
import PaymentOverlay from "../PaymentOverlay";
import { LoadingSpinner } from "../../../_common/LoadingSpinner";

export const PayLater = (props) => {
  const { promoCode, rewards, paymentMethodExpanded } = props;

  const history = useHistory();

  // Scroll to the top of the expand/collapse toggle button after details panel is expanded
  useEffect(() => {
    const timer = setTimeout(() => paymentMethodExpanded(), 300);
    return () => clearTimeout(timer);
  }, []);

  const merchantConfig = useContext(MerchantConfigContext);
  const skin = merchantConfig.skin;

  const orderTypeContext = useContext(OrderTypeContext);
  const storeContext = useContext(StoreContext);
  const cart = useContext(CartContext);

  const activeOrderType = orderTypeContext.value;
  const activeOrderStore = storeContext.activeOrderStore;

  const [apiError, setAPIError] = useState("");
  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [isAPISubmitting, setAPISubmitting] = useState(false);

  const orderTimeContext = useContext(OrderTimeContext);

  const getCurrentTimestamp = () => {
    const date = new Date(
      ensureOrderTimeMinInterval(orderTimeContext.value.value, activeOrderStore, activeOrderType)
    );
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return timestamp;
  };

  const [customerInfo, setCustomerInfo] = useState({});
  const [orderTypeInstructions, setOrderTypeInstructions] = useState("None");
  const [orderDate, setOrderDate] = useState(null);
  const [orderTime, setOrderTime] = useState(null);

  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      if (customerInfo) setCustomerInfo(customerInfo);
      //else history.goBack();
    });

    localforage.getItem(skin + "__orderTypeSpecialInstruction").then((orderInstruction) => {
      if (orderInstruction) {
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let orderInstructionCopy = orderInstruction;
            orderInstructionCopy += `, ${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(he.encode(orderInstructionCopy, { decimal: true }));
          } else {
            setOrderTypeInstructions(he.encode(orderInstruction, { decimal: true }));
          }
        });
      } else {
        //there is no order instruction set
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let sectionRowSeatInfo = `${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(he.encode(sectionRowSeatInfo, { decimal: true }));
          }
        });
      }
    });

    localforage.getItem(skin + "__orderDate").then((orderDate) => {
      if (orderDate) {
        setOrderDate(orderDate.displayValue);
      }
    });

    localforage.getItem(skin + "__orderTime").then((orderTime) => {
      if (orderTime) {
        setOrderTime(orderTime.displayValue);
      }
    });
  }, []);

  const [deliveryAddress, setDeliveryAddress] = useState(null);
  useEffect(() => {
    if (activeOrderType === "delivery") {
      localforage.getItem(skin + "__userDeliveryAddress").then((storedDeliveryAddress) => {
        if (storedDeliveryAddress) setDeliveryAddress(storedDeliveryAddress);
        else setDeliveryAddress(false);
      });
    }
  }, []);

  const confirmOrder = (orderID) => {
    callAPI(skin, "vxl_1308", {
      params: [
        appLanguage,
        "1308",
        "mqid",
        "mqpass",
        activeOrderStore.storeId, // Outlet ID
        orderID, // Online Order ID
        [], // PaymentList
      ],
      id: "1308",
    }).then((data1308) => {
      if (data1308.result.I1 === "0") {
        // Order was successfully cancelled
        history.push({
          pathname: "/online-ordering/order-confirmation",
        });
      } else {
        // Failed to cancel order
        setShowPaymentOverlay(false);
        setAPIError(data1308.result.message);
      }
    });
  };

  const [showPaymentOverlay, setShowPaymentOverlay] = useState(false);
  const [headerLabel, setHeaderLabel] = useState(null);

  const isStadiumSchema = merchantConfig.merchant.I55 === "stadium";

  const placeOrder = () => {
    setAPISubmitting(true);

    const formattedOrderType = formatOrderType(activeOrderType);
    const timestamp = activeOrderType === "dinein" ? "" : getCurrentTimestamp();
    const formattedCartItems = formatCartForApi(cart.value);

    let order_address = activeOrderStore.address;
    let order_postal = activeOrderStore.postal;
    let order_city = activeOrderStore.city;

    if (activeOrderType === "delivery") {
      if (isStadiumSchema) {
        order_postal = deliveryAddress;
      } else {
        const isSecondAddress = deliveryAddress["second-address"];
        order_address =
          deliveryAddress["first-address"] +
          (isSecondAddress ? ", #" + deliveryAddress["second-address"] : "");
        order_postal = deliveryAddress.postal;
        order_city = deliveryAddress.city;
      }
    }

    setHeaderLabel(appLabels["order"]["hang-tight-reviewing-order"]);

    setTimeout(() => {
      setHeaderLabel(appLabels["order"]["almost-done-passing-order-to-store"]);
    }, 2000);

    // Call 1302
    callAPI(skin, "vxl_1302", {
      params: [
        appLanguage,
        "1302",
        "mqid",
        "mqpass",
        activeOrderStore.storeId,
        formattedOrderType,
        timestamp || "", // Order Pickup/Delivery Timestamp (optional)
        [], // PaymentList
        {
          customer_id: customerInfo.id || "",
          order_email: customerInfo.email || "",
          order_fname: customerInfo.firstName || "",
          order_lname: customerInfo.lastName || "",
          order_phone: customerInfo.phone || "",
          order_mobile: customerInfo.mobile || "",
          order_address: order_address || "",
          order_postal,
          order_city: order_city,
          order_prov: activeOrderStore.province,
          order_country: activeOrderStore.country,
          discount_for_the_whole_order: promoCode,
          redeem_rewards: rewards,
        }, // CustomerInfo
        formattedCartItems, // ItemsList
        activeOrderType === "delivery" ? orderTypeInstructions : "None", // Delivery Instructions
        activeOrderType === "dinein" ? storeContext.activeOrderStoreTable : "", // Table Number (optional)
        customerInfo.cpfNumber, //Government ID
        activeOrderType === "pickup" || activeOrderType === "dinein"
          ? orderTypeInstructions
          : "None", //Special Instruction (pickup / dinein)
        null, //Order Delivery Reference (optional)
        null, //Driver Pickup Timestamp (optional)
        getDeviceTypeId(activeOrderType), //Device Type (optional)
      ],
      id: "1302",
    }).then((data1302) => {
      if (data1302.result.I2) {
        // Order was successfully placed
        const orderID = data1302.result.I2;
        localforage.setItem(skin + "__completedOrderId", orderID);
        localforage.setItem(skin + "__usedPaymentMethod", appLabels["order"]["pay-later"]);
        setAPIError("");
        setIsAPISubmitValid(true);
        setAPISubmitting(false);

        confirmOrder(orderID); // 1308 API
      } else {
        // Failed to place an order
        setShowPaymentOverlay(false);
        setAPIError(data1302.result.message);
        console.error(data1302.result.message);
      }
    });
  };

  const formatOrderTime = (orderTime) => {
    const date = new Date(orderTime);
    const month = date.getMonth();
    const calDate = date.getDate();
    const day = date.getDay();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;

    const nameOfDay = getDayOfWeekFromIndex(day);
    const timestamp = `${
      nameOfDay.substr(0, 3).charAt(0).toUpperCase() + nameOfDay.substr(1, 2)
    } ${getMonthFromIndex(month)} ${calDate} at ${strTime}`;

    return (
      <>
        <br /> <br /> <span style={{ fontWeight: 700 }}>{timestamp} </span>
        <br /> <br />{" "}
      </>
    );
  };

  const [nextAvailableOrderTime, setNextAvailableOrderTime] = useState(null);

  const updateOrderTime = () => {
    orderTimeContext.update({
      value: nextAvailableOrderTime,
      displayValue: getTimeAmPm(nextAvailableOrderTime),
      trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
      timeAtOrdering: new Date()
    });
    setIsPassedOrderTime(false);
  };

  const [isLateOrderAttempt, setIsLateOrderAttempt] = useState(false);
  const [isPassedOrderTime, setIsPassedOrderTime] = useState(false);
  const [isOrderTimeBeforeOpening, setIsOrderTimeBeforeOpening] = useState(false);
  const [orderTimeIsBlocked, setOrderTimeIsBlocked] = useState(false);
  const [showLoadingSpinnerWhenCallingAPIs, setShowLoadingSpinnerWhenCallingAPIs] = useState(false);
  const [showDoubleConfirmation, setShowDoubleConfirmation] = useState(false);
  const [isStoreClosed, setIsStoreClosed] = useState(false);

  const onCompleteOrderClick = async () => {
    if (activeOrderType === "dinein") {
      placeOrder(); // call 1302
    } else {
      setShowLoadingSpinnerWhenCallingAPIs(true);

      //update the location with the latest data, in case the store hours have been changed
      const updatedLocation = await updateLocationData(
        activeOrderStore,
        activeOrderType,
        skin,
        appLanguage
      );
      if (updatedLocation) {
        setShowLoadingSpinnerWhenCallingAPIs(false);

        let isStoreClosedForTheDay = isStoreClosedToday(updatedLocation, orderTimeContext)

        const orderTimeIsPassed = isOrderTimePassed(
          updatedLocation,
          activeOrderType,
          orderTimeContext,
          setNextAvailableOrderTime
        );
        const orderTimeIsBeforeOpening = isOrderTimeBeforeOpeningTime(
          updatedLocation,
          orderTimeContext
        );
        const orderTimeIsBlocked = isOrderTimeBlocked(
          updatedLocation,
          orderTimeContext,
          activeOrderType
        );
        const lateOrderAttempt = isLateOrderAttempted(
          updatedLocation,
          activeOrderType,
          orderTimeContext,
          skin,
          setNextAvailableOrderTime
        );
        
        if (lateOrderAttempt) {
          isStoreClosedForTheDay = true
        }

        if (isStoreClosedForTheDay) {
          setIsStoreClosed(true)
          return;
        }
        else if (orderTimeIsPassed) {
          setIsPassedOrderTime(true);

          setAPISubmitting(false);
          setIsAPISubmitValid(false);
          setAPIError(<>&nbsp;</>); // reset the disabled state on submit FAB
        } else if (orderTimeIsBeforeOpening) {
          setIsOrderTimeBeforeOpening(true);

          setAPISubmitting(false);
          setIsAPISubmitValid(false);
          setAPIError(<>&nbsp;</>); // reset the disabled state on submit FAB
        } else if (orderTimeIsBlocked) {
          setOrderTimeIsBlocked(true);
          return;
        } else {
          if (activeOrderType === "pickup") {
            setShowDoubleConfirmation(true);
          } else {
            setShowPaymentOverlay(true);
            placeOrder(); // call 1302
          }
        }
      } else {
        // if updatedLocation returns null, it means the selected location does not exist anymore
        history.push("/dashboard");
      }
    }
  };

  const onConfirmClick = () => {
    setShowDoubleConfirmation(false);
    setShowPaymentOverlay(true);
    placeOrder(); // call 1302
  };

  const [appliedCoupon, setAppliedCoupon] = useState(null);
  useEffect(() => {
    if (appliedCoupon == null) {
      localforage.getItem(skin + "__coupon").then((coupon) => {
        if (coupon) setAppliedCoupon(coupon);
      });
    }
  }, [appliedCoupon]);

  const appLabels = useContext(AppLabelsContext);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  return (
    <div style={{ textAlign: `center` }}>
      <p style={{ margin: `0 0 28px` }}>
        {activeOrderType === "pickup" && appLabels["order"]["complete-your-order-below"]}
        {activeOrderType === "delivery" &&
          appLabels["order"]["complete-order-pay-on-delivery-blurb"]}
        {activeOrderType === "dinein" &&
          appLabels["order"]["complete-order-pay-later-dine-in-blurb"]}
      </p>
      <Form
        id="form--pay-on-pickup"
        isFAB={true}
        submitButtonText={appLabels["order"]["submit-order-button"]}
        submitButtonSuccessText={appLabels["order"]["submit-order-button-processing"]}
        submitForm={onCompleteOrderClick}
        submitAPIError={apiError}
        resetAPIError={() => setAPIError("")}
        isAPISubmitValid={isAPISubmitValid}
        isAPISubmitting={isAPISubmitting}
        isAllowEmptySubmit={true}
        buttonWrapperClass={"form--credit-card__submit-button"}></Form>
      <div
        style={{
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
          marginBottom: `28px`,
        }}>
        {activeOrderType !== "dinein" && (
          <IconPaperBag style={{ width: `35px`, height: `35px`, marginRight: `8px` }} />
        )}
        <IconUtensils style={{ width: `35px`, height: `35px` }} />
      </div>
      {!!appliedCoupon ? (
        <p className="coupon-notice">
          <em>
            {appLabels["order"]["bring-coupon-notice"].split("[balance]")[0] +
              toDollars(
                appSettings["currency-symbol"],
                appSettings["currency-symbol-side"],
                appliedCoupon.balance,
                appLanguage
              ) +
              appLabels["order"]["bring-coupon-notice"].split("[balance]")[1]}
          </em>
        </p>
      ) : (
        <p style={{ margin: `0` }}>{appLabels["order"]["complete-order-see-you-soon"]}</p>
      )}
      {showDoubleConfirmation && (
        <DialogModal
          message={getOrderStoreAddressForDialogModal(
            activeOrderStore,
            appLabels,
            orderDate,
            orderTime,
            activeOrderType,
            orderTimeContext,
            skin
          )}
          isConfirmText={true}
          isModifyText={appLabels["order"]["modify-location"]}
          isCancelConfirm={true}
          confirmAction={onConfirmClick}
          resetRemoveDialog={() => {
            history.push("/online-ordering/order-store");
          }}
          closeModalAction={() => {
            setShowDoubleConfirmation(!showDoubleConfirmation);
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}
      {isStoreClosed && (
        <DialogModal
          hideCloseButton={true}
          closeModalAction={() => {
            setIsStoreClosed(false);
          }}
          isCancelConfirm={false}
          hideConfirmButton={false}
          isCustomConfirmText={appLabels["order"]["pick-new-time"]}
          resetRemoveDialog={() => {
            history.push({
              pathname: "/dashboard",
              state: {
                showTimePanel: true,
                timeAtOrdering: orderTimeContext.value.timeAtOrdering,
                originalOrderTimeContext: orderTimeContext
              },
            });
          }}
          message={
            <div>
              <h2>{appLabels["order"]["sorry-it-is-closing-time"]}</h2>
              <p>{appLabels["order"]["store-closed-message"]}</p>
            </div>
          }
        />
      )}
      {isLateOrderAttempt && (
        <DialogModal
          message={appLabels["order"]["order-too-close-to-closing-time-error"]}
          resetRemoveDialog={() => {
            setIsLateOrderAttempt(false);
            orderTimeContext.update({
              value: nextAvailableOrderTime,
              displayValue: getTimeAmPm(nextAvailableOrderTime),
              trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
            });
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}
      {isPassedOrderTime && (
        <DialogModal
          message={
            <div>
              {appLabels["order"]["select-order-time-passed-message"]}
              {": "}
              {formatOrderTime(nextAvailableOrderTime)}
              {appLabels["order"]["confirm-updated-order-time-message"]}
            </div>
          }
          resetRemoveDialog={() => setIsPassedOrderTime(false)}
          isCancelConfirm={true}
          confirmAction={updateOrderTime}
          isHTMLContent={true}
        />
      )}
      {isOrderTimeBeforeOpening && (
        <DialogModal
          message={appLabels["order"]["order-before-store-opening-time-error"]}
          resetRemoveDialog={() => {
            setIsOrderTimeBeforeOpening(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {orderTimeIsBlocked && (
        <DialogModal
          message={appLabels["order"]["order-time-is-blocked"]}
          resetRemoveDialog={() => {
            setOrderTimeIsBlocked(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}
      {showLoadingSpinnerWhenCallingAPIs && <LoadingSpinner />}

      {showPaymentOverlay && <PaymentOverlay headerLabels={headerLabel} />}
    </div>
  );
};

import React, { useEffect, useState, useContext } from "react";
import localforage from "localforage";
import useWindowSize from "../_common/hooks/useWindowSize";
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";

export const LastLoginDialog = (props) => {
  const { lastLoginTimeStamp } = props;

  const skin = useContext(MerchantConfigContext).skin;

  const deviceWidth = useWindowSize().width;

  const appLabels = useContext(AppLabelsContext);

  const [customerInfo, setCustomerInfo] = useState("");
  const [animationClass, setAnimationClass] = useState(
    " last-login-timestamp__container_slidedown"
  );

  const isLocalhost = process.env.NODE_ENV === "development";
  const IS_GIVEX_HOST = !isLocalhost;
  const PATHNAME = window.location.pathname;
  const IS_DEFAULT_LANGUAGE = IS_GIVEX_HOST
    ? PATHNAME.split("/")[3] === ""
    : PATHNAME.split("/")[1] === "";

  let CURRENT_LANGUAGE = "";

  if (IS_DEFAULT_LANGUAGE) {
    CURRENT_LANGUAGE = "en"; // add conditional exceptions for merchants with a non-English default language
  } else {
    CURRENT_LANGUAGE = IS_GIVEX_HOST ? PATHNAME.split("/")[3] : PATHNAME.split("/")[1];
    if (CURRENT_LANGUAGE === "es-mx") {
      CURRENT_LANGUAGE = "sp";
    }
  }

  setTimeout(() => {
    setAnimationClass(" last-login-timestamp__container_slideup");
  }, 5000);

  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      if (customerInfo) {
        setCustomerInfo(customerInfo);
      }
    });
  }, []);

  const formatDate = (date) => {
    const options = { weekday: "short", year: "numeric", month: "long", day: "numeric" };

    const formatedDate = date.toLocaleString(
      CURRENT_LANGUAGE +
        ("-" +
          (isNaN(customerInfo.country) && !!customerInfo.country ? customerInfo.country : "CA")),
      options
    );

    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();

    if (minutes.length === 1) {
      minutes = minutes.padStart(2, "0");
    }

    if (hours.length === 1) {
      hours = hours.padStart(2, "0");
    }

    return `${formatedDate.replace(/,/g, "")} ${hours}:${minutes} `;
  };

  return (
    <>
      {!!customerInfo && (
        <>
          {deviceWidth >= 660 ? (
            <div className={`last-login-timestamp__container${animationClass}`}>
              <p>
                {appLabels["general"]["welcome"]}, {customerInfo.firstName}.{" "}
                {appLabels["general"]["your-last-visit"]}:{" "}
                {formatDate(new Date(lastLoginTimeStamp))}
              </p>
            </div>
          ) : (
            <div className={`last-login-timestamp__container${animationClass}`}>
              <p>
                {appLabels["general"]["welcome"]}, {customerInfo.firstName}. <br />{" "}
                {appLabels["general"]["your-last-visit"]}:{" "}
                {formatDate(new Date(lastLoginTimeStamp))}
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};

import React from "react";

const paragraph1 = () => {
  return (
    <p>
      Scannez votre carte en appuyant sur le bouton <br />
      «Payer en magasin» sur le tableau de bord pour payer avec votre carte au magasin ou commandez
      à l'avance pour le ramassage et accumuler des points de récompense.
    </p>
  );
};

const paragraph2 = () => {
  return (
    <p>
      Gagnez <strong>1 point de récompense</strong> pour chaque dollar dépensé chaque fois que vous
      passez une commande chez nous.
    </p>
  );
};

const paragraph3 = () => {
  return (
    <p>
      Sélectionnez <br /> «Options de récompenses» dans le tableau de bord et découvrez les
      récompenses auxquelles vous avez droit ou à échanger contre de la marchandise.
    </p>
  );
};

const paragraph4 = () => {
  return (
    <p>
      Lors de la commande en ligne, sur la page <br />
      «Révision de la commande», cliquez sur le bouton
      <br />
      «Utiliser les récompenses» pour voir les récompenses disponibles pour vous. Échangez des
      points de récompense contre des boissons et de la nourriture gratuites.
    </p>
  );
};

const paragraph5 = () => {
  return (
    <>
      <p>
        N'oubliez pas de vérifier l'application le jour de votre anniversaire, nous aurons un cadeau
        d'anniversaire surprise * pour vous.
      </p>
      <p>
        <small>
          <em>* Les termes et conditions peuvent s'appliquer</em>
        </small>
      </p>
    </>
  );
};

const paragraph6 = () => {
  return (
    <p>
      Consultez votre tableau de bord pour des mises à jour sur les promotions et comment collecter
      plus de points de récompense.
    </p>
  );
};

export const paragraphs = {
  "step-1": paragraph1(),
  "step-2": paragraph2(),
  "step-3": paragraph3(),
  "step-4": paragraph4(),
  "step-5": paragraph5(),
  "step-6": paragraph6(),
};

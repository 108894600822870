import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

// Helper functions
import { capitalizeWords } from "../../_common/stringHelpers";

/** Hooks */
import { useHideOrderModule } from "../useHideOrderModule";

// Contexts
import AppSettingsContext from "../AppSettingsContext";
import AppLanguageContext from "../AppLanguageContext";
import AppLabelsContext from "../AppLabelsContext";
import BrandingContext from "../BrandingContext";
import MerchantConfigContext from "../MerchantConfigContext";
import UserRoleContext from "../UserRoleContext";
import OrderContext from "../OrderContext";
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext"; // TODO: remove once OrderContext includes the order type state/update
import StoreContext from "../../OnlineOrdering/StoreContext";
import OrderTimeContext from "../../OnlineOrdering/OrderTimeContext";
import CWS5ModuleContext from "../CWS5ModuleContext";

export const useAppDesktopHeaderNavData = (showHeaderOptions) => {
  const [navData, setNavData] = useState(null);
  const history = useHistory();
  const { hideOrderModule } = useHideOrderModule();

  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const branding = useContext(BrandingContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const userRoleContext = useContext(UserRoleContext);
  const orderContext = useContext(OrderContext);
  const orderTypeContext = useContext(OrderTypeContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const storeContext = useContext(StoreContext);
  const orderTimeContext = useContext(OrderTimeContext);

  const userStatus = userRoleContext.status;
  const activeOrderType = orderTypeContext.value;

  const isLoyaltyModuleEnabled = cws5ModuleContext.isLoyaltyEnabled;
  const isOrderModuleEnabled = cws5ModuleContext.isOnlineOrderingEnabled;
  const isGiftCardModuleEnabled = cws5ModuleContext.isGiftCardEnabled;
  const isRegistrationModuleEnabled = cws5ModuleContext.isRegistrationEnabled;

  const skin = merchantConfigContext.skin;
  const defaultLanguage = merchantConfigContext["default-language"];
  const languageCodes = appSettings["languages-list"];

  useEffect(() => {
    const location = history.location.pathname;
    const tempNavData = [];

    if (isOrderModuleEnabled && showHeaderOptions && !hideOrderModule) {
      const orderNavData = {
        name: "order",
        ariaLabel: "order options",
        displayName: appLabels["general"]["order"],
        isActive: location.includes("/online-ordering"),
        action: () => {
          localforage.removeItem(skin + "__itemSelectionInProgress");
          localforage.removeItem(skin + "__storeIdToBeFaved");
          sessionStorage.removeItem(skin + "__orderStoreState");
          orderContext.checkOrderSettings(activeOrderType, storeContext, orderTimeContext);
        },
      };

      tempNavData.push(orderNavData);
    }

    if (isLoyaltyModuleEnabled && showHeaderOptions) {
      tempNavData.push({
        name: "loyalty",
        displayName: appLabels["loyalty"]["rewards"],
        isActive: location.includes("rewards"),
        linkPathname: userStatus === "guest" ? "/rewards" : "/my-rewards",
      });
    }
    if (isGiftCardModuleEnabled && showHeaderOptions) {
      tempNavData.push({
        name: "gift-card",
        displayName: appLabels["gift-card"]["gift"],
        isActive: location.includes("gift-card"),
        linkPathname: "/gift-card/purchase",
      });
    }

    if (branding["client-site-url"]) {
      tempNavData.push({
        name: "merchant-website",
        displayName: appLabels["general"]["main-website"],
        linkPathname: branding["client-site-url"],
        isExternalLink: true,
      });
    }
    if (languageCodes) {
      const languageNavData = {
        name: "language",
        displayName: appLabels["general"]["language-dropdown-display-name"],
        subnavItems: [],
      };

      const isoLangs = require("../../_common/langCodes").isoLangs;

      if (appLanguage !== "en" && defaultLanguage === "") {
        languageNavData.subnavItems.push({
          name: "english",
          displayName: "English",
          lang: "en",
          linkPathname: `${
            window.location.pathname.split(`${appLanguage}/`)[0]
          }${window.location.hash.replace(/\?lang=[a-z]*/g, "")}?lang=en`,
          isLanguageLink: true,
        });
      }

      const isLocalhost = process.env.NODE_ENV === "development";
      const isGivexHost = !isLocalhost;
      const pathnameSplit = window.location.pathname.split("/");
      const languageFromPathname = isGivexHost ? pathnameSplit[3] : pathnameSplit[1];

      languageCodes.forEach((lang) => {
        const langExists = isoLangs[lang];

        if (lang !== appLanguage && langExists) {
          const displayNativeName = capitalizeWords(
            isoLangs[lang]["nativeName"].split(/[;,]+/)[0].trim()
          );

          languageNavData.subnavItems.push({
            name: isoLangs[lang]["name"].toLowerCase(),
            displayName: displayNativeName,
            lang: lang,
            linkPathname:
              lang !== "en"
                ? `${
                    !!languageFromPathname
                      ? window.location.pathname.replace(`/${languageFromPathname}`, "")
                      : window.location.pathname
                  }${lang}/${window.location.hash.replace(/\?lang=[a-z]*/g, "")}?lang=${lang}`
                : `${window.location.pathname
                    .replace(`/${appLanguage}`, "")
                    .replace(`/${languageFromPathname}`, "")}${window.location.hash.replace(
                    /\?lang=[a-z]*/g,
                    ""
                  )}?lang=${lang}`,
            isLanguageLink: true,
          });
        }
      });
      tempNavData.push(languageNavData);
    }
    if (isRegistrationModuleEnabled) {
      if (userStatus === "guest") {
        tempNavData.push({
          name: "sign-in",
          displayName: appLabels["login-register"]["sign-in"],
          action: () => {
            localforage.removeItem(skin + "__itemSelectionInProgress");
            localforage.removeItem(skin + "__storeIdToBeFaved");
            sessionStorage.setItem(skin + "__lastVisitedLink", window.location.hash.split("#")[1]);
            sessionStorage.removeItem(skin + "__orderStoreState");
            history.push("/login-register");
          },
        });

        tempNavData.push({
          name: "register",
          displayName: appLabels["login-register"]["register"],
          action: () => {
            localforage.removeItem(skin + "__itemSelectionInProgress");
            localforage.removeItem(skin + "__storeIdToBeFaved");
            sessionStorage.setItem(skin + "__lastVisitedLink", window.location.hash.split("#")[1]);
            sessionStorage.removeItem(skin + "__orderStoreState");
            history.push("/register");
          },
        });
      } else if (userStatus === "logged-in") {
        tempNavData.push({
          name: "account",
          displayName: appLabels["general"]["my-account"],
        });
      }
    }

    setNavData(tempNavData);
  }, [
    showHeaderOptions,
    userStatus,
    userRoleContext,
    activeOrderType,
    storeContext,
    orderTimeContext,
  ]);

  return navData;
};

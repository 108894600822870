// Libraries
import React, { useContext } from "react";
import ReactDOM from "react-dom";

//Contexts
import AppLabelsContext from "../../App/AppLabelsContext";

//UI Components
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { IconCheck } from "../../_common/icons";

// CSS
import "./PaymentOverlay.css";
import { Box, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const PaymentOverlay = ({ headerLabels, paymentProgressSpeed, secondaryHeaderLabel }) => {
  const appLabels = useContext(AppLabelsContext);
  const isPrepairingBill = headerLabels === appLabels["order"]["preparing-bill-for-verification"];
  const isAllDone = headerLabels === appLabels["order"]["all-done"];
  const isDineInBillPayment = window.location.href.includes("bill-payment");
  const paymentProgressSpeedInSeconds = paymentProgressSpeed / 1000;

  const useStyles = makeStyles({
    root: {
      width: "100%",
    },
    bar1Indeterminate: {
      width: "auto",
      animation: `$indeterminate1 ${paymentProgressSpeedInSeconds}s linear forwards`,
      animationIterationCount: "infinite",
    },
    bar2Indeterminate: {
      display: "none",
    },
    "@keyframes indeterminate1": {
      "0%": {
        left: "-35%",
        right: "100%",
      },
      "100%": {
        left: "0%",
        right: "0%",
      },
    },
  });

  const classes = useStyles();

  return ReactDOM.createPortal(
    <div
      role="dialog"
      aria-modal="true"
      aria-label={headerLabels}
      className="payment-overlay__container"
    >
      <div className="payment-overlay">
        <div className="payment-overlay__text">
          <div className={`${isDineInBillPayment ? "payment-overlay__header-dine-in" : "payment-overlay__header"}`}>
            <h2
              key={headerLabels}
              className={`${isDineInBillPayment ? "payment-overlay__header-text-dine-in" : "payment-overlay__header-text"}`}
            >
              {headerLabels}
              {isAllDone && isDineInBillPayment && (
                <span className="checkmark">
                  <IconCheck />
                </span>
              )}
            </h2>
            {isDineInBillPayment && (
              <h3 className="payment-overlay__secondary-header-text">
                <TransitionGroup>
                  <CSSTransition
                    key={secondaryHeaderLabel}
                    in={true}
                    timeout={450}
                    classNames="slide"
                  >
                    <span>{secondaryHeaderLabel}</span>
                  </CSSTransition>
                </TransitionGroup>
              </h3>
            )}
          </div>
          {isDineInBillPayment && (
            <div
              className={`${isPrepairingBill ? "payment-overlay__progress-bar-start" : ""} ${
                isAllDone ? "payment-overlay__progress-bar-finish" : ""
              }`}
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  classes={{
                    bar1Indeterminate: classes.bar1Indeterminate,
                    bar2Indeterminate: classes.bar2Indeterminate,
                  }}
                  variant="indeterminate"
                />
              </Box>
            </div>
          )}
          <p className="payment-overlay__do-not-refresh">
            {appLabels["order"]["do-not-click-back-or-refresh"]}
          </p>
        </div>
        {!isDineInBillPayment && <LoadingSpinner />}
      </div>
    </div>,
    document.body
  );
};

export default PaymentOverlay;

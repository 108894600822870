import React, { useState, useEffect, useCallback, useContext } from "react";
import AppLabelsContext from "./AppLabelsContext";
import AppSettingsContext from "./AppSettingsContext";
import { ReactComponent as IconX } from "../_common/icons/IconX.svg";

export const AppInstallPrompt = () => {
  const [isPromptEnabled, setIsPromptEnabled] = useState("idle");
  const [promptInstall, setPromptInstall] = useState(false);

  const handleBeforeInstallPrompt = useCallback((event) => {
    event.preventDefault(); // prevent the mini-infobar from appearing on mobile

    setIsPromptEnabled(true); // show custom Add to Home Screen prompt
    setPromptInstall(event); // stash the event so it can be triggered later
  }, []);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
  }, [handleBeforeInstallPrompt]);

  const showNativeInstallPrompt = (event) => {
    event.preventDefault();

    promptInstall.prompt();
    setIsPromptEnabled(false);
  };

  const appLabels = useContext(AppLabelsContext);

  const relativePath = useContext(AppSettingsContext)["relative-path"];
  const appLogo = relativePath + "files/Images/pwa/ios/touch-icon-iphone.png";

  return (
    isPromptEnabled &&
    isPromptEnabled !== "idle" && (
      <div className="ad2hs-prompt">
        <div className="ad2hs-prompt__image-and-name">
          <div className="ad2hs-prompt__image-wrapper">
            <img src={appLogo} alt="app logo" className="ad2hs-prompt__image" />
          </div>
          <button
            onClick={showNativeInstallPrompt}
            className="button ad2hs-prompt__install-button"
            type="button"
          >
            {appLabels["general"]["add-to-home-screen-prompt"]}
          </button>
        </div>
        <button
          onClick={() => setIsPromptEnabled(false)}
          className="button ad2hs-prompt__close-button"
          type="button"
          aria-label={appLabels["general"]["close-install-prompt"]}
        >
          <IconX />
        </button>
      </div>
    )
  );
};

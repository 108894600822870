import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";

import { formatTipConfig, getCartSubtotal } from "../../../_common/CartHelpers";

import OrderTypeContext from "../../OrderTypeContext";
import StoreContext from "../../StoreContext";
import AppLabelsContext from "../../../App/AppLabelsContext";

import { ReactComponent as IconXInCircle } from "../../../_common/icons/IconXInCircle.svg";
import { TipButton } from "./TipButton";

import "./Tip.css";
import AppLanguageContext from "../../../App/AppLanguageContext";
import AppSettingsContext from "../../../App/AppSettingsContext";

export const Tip = (props) => {
  const { orderSummaryData, skin, updateStoredTipAmount, isCollapsed } = props;

  const appSettings = useContext(AppSettingsContext);
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const storeContext = useContext(StoreContext);
  const orderTypeContext = useContext(OrderTypeContext);

  const activeOrderStore = storeContext.activeOrderStore;
  const vexilorConfig = activeOrderStore.vexilorConfig;
  const activeOrderType = orderTypeContext.value;

  const tipConfig = vexilorConfig["tips_config"];
  const formattedTipConfig = formatTipConfig(tipConfig, appLabels, activeOrderType);
  const [activeTip, setActiveTip] = useState(null); //tracks the current active tip
  const [customTipAmount, setCustomTipAmount] = useState(""); //tacks the custom tip amount (if any)

  const updateCustomTipAmount = (value) => {
    setCustomTipAmount(value);
  };
  const updateActiveTip = (tipObject) => {
    setActiveTip(tipObject);

    if (tipObject.isCustomAmount) {
      updateCustomTipAmount(tipObject.displayName);
    } else {
      updateCustomTipAmount("");
    }
  };

  //setup the active tip
  useEffect(() => {
    if (orderSummaryData && orderSummaryData !== "reset" && activeTip === null) {
      //if there is any stored tip set that as active tip, else select the default tip
      localforage.getItem(skin + "__storedTip").then((storedTip) => {
        if (storedTip) {
          const tipIndex = storedTip.index;
          if (tipIndex === "custom") {
            //handle custom tips
            updateActiveTip(storedTip);
            updateStoredTipAmount(orderSummaryData, storedTip);
          } else {
            onClickTipButton(tipIndex);
          }
        } else {
          const defaultTip = formattedTipConfig.percentages.filter((tip) => tip.isDefault)[0];
          // if there is no default found due to invalid BE setup, fallback to 'no tip'
          onClickTipButton(defaultTip ? defaultTip.index : 0);
        }
      });
    }
  }, [orderSummaryData, activeTip]);

  const onClickTipButton = (index) => {
    const selectedTip = formattedTipConfig.percentages[index];
    updateActiveTip(selectedTip);
    let subtotal = getCartSubtotal(orderSummaryData);
    const tipAmount = selectedTip.calcPercentage * subtotal;
    const displayPrice = parseFloat(tipAmount).toLocaleString(appLanguage, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const numericalValue = parseFloat(tipAmount).toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const tipObjectToBeStored = {
      ...selectedTip,
      tipAmount: numericalValue,
      tipDisplayPrice: displayPrice,
    };

    updateStoredTipAmount(orderSummaryData, tipObjectToBeStored);
  };

  const applyCustomTipAmount = (value) => {
    if (!!value) {
      const tipAmount = parseFloat(value);
      const displayPrice = parseFloat(
        parseFloat(tipAmount).toLocaleString(appLanguage, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
      const tipObjectToBeStored = {
        tipAmount: displayPrice,
        index: "custom",
        displayName: tipAmount.toFixed(2),
        isCustomAmount: true,
        isDefault: false,
      };

      updateActiveTip(tipObjectToBeStored);
      updateStoredTipAmount(orderSummaryData, tipObjectToBeStored);
    }
  };

  const removeCustomTipAmount = () => {
    updateCustomTipAmount("");
    updateStoredTipAmount(orderSummaryData, {});
  };

  return (
    <>
      {activeTip && (
        <div
          className={`cart__tip-container ${
            isCollapsed
              ? " collapsable-section__collapsed-content"
              : " collapsable-section__expanded-content"
          }`}
        >
          {/*<h2>{appLabels["order"]["add-tip"]}</h2>*/}
          <div className="cart__tip-amounts">
            {formattedTipConfig &&
              formattedTipConfig.percentages.map((tip, index) => (
                <TipButton
                  label={tip.displayName}
                  isDefault={tip.isDefault}
                  percentage={tip.calcPercentage}
                  key={tip.displayName + "-" + index}
                  isSelected={tip.index === activeTip.index}
                  onClickTipButton={onClickTipButton}
                  index={tip.index}
                />
              ))}
          </div>
          {formattedTipConfig.isCustomTipEnabled && (
            <div className="cart__tip-custom-amount-container">
              <div>
                <label htmlFor="customTipAmount" className="cart__tip-custom-amount-label">
                  {appLabels["order"]["custom-amount"]}
                </label>
              </div>
              <div>
                <span className="cart__tip-custom-amount-currency-sign">
                  {`${appSettings["currency-symbol"] || "$"}`}
                </span>
                <input
                  aria-label={appLabels["order"]["input-custom-tip-amount"] + `${appSettings["currency-symbol"] || "$"}. ` + appLabels["order"]["press-tab-to-add-tip"]}
                  type="number"
                  min="0.00"
                  step="0.01"
                  id="customTipAmount"
                  className="cart__tip-custom-amount-input"
                  value={customTipAmount}
                  onKeyDown={(e) => {
                    if(e.key === 'Enter'){
                      applyCustomTipAmount(e.target.value)
                    }
                  }}
                  onChange={(e) => {
                    updateCustomTipAmount(e.target.value);
                  }}
                  onBlur={(e) => applyCustomTipAmount(e.target.value)}
                ></input>
                {activeTip.isCustomAmount && activeTip.tipAmount > 0 && (
                  <button
                    className="cart__tip-remove-custom-amount"
                    onClick={() => {
                      removeCustomTipAmount();
                      onClickTipButton(0);
                    }}
                    type="button"
                    aria-label={appLabels["order"]["remove-tip"]}
                  >
                    <IconXInCircle />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

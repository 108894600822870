import React from "react";
/** Helpers */
import { capitalizeWords } from "../../_common/stringHelpers";
import "./OrderSettings.css";
import AddressInfoCard from "../AddressInfoCard";

export const getTranslatedOrderType = (orderType, appLabels) => {
  if (orderType === "") {
    return "";
  }
  let translatedOrderType;
  if (orderType === "pickup") {
    translatedOrderType = appLabels["order"]["pickup"];
  } else if (orderType === "delivery") {
    translatedOrderType = appLabels["order"]["delivery"];
  } else if (orderType === "dinein") {
    translatedOrderType = appLabels["order"]["dine-in"];
  }

  return capitalizeWords(translatedOrderType);
};

export const getValidatedUserAddressForDialogModal = (userAddress, appLabels) => {
  return (
    <>
      <h2 style={{ fontSize: "1.2rem" }}>
        {appLabels["order"]["primary-delivery-address-message"]}
      </h2>
      <AddressInfoCard address={userAddress} />
    </>
  );
};

export const getRecommendedUserAddressForDialogModal = (appLabels) => {
  return (
    <>
      <span className="order-status__order-details-date-address">
        <h2
          className="address-validation__recommended-address-header-container"
          style={{ paddingBottom: "1.2em", marginBottom: "0" }}
        >
          {appLabels["order"]["recommended-address-changes"]}
        </h2>
      </span>
    </>
  );
};

export const getUnverifiedAddressForDialogModal = (appLabels) => {
  return (
    <>
      <span className="order-status__order-details-date-address">
        <p className="address-validation__recommended-address-header-container address-validation__unverified-address-header-container">
          {appLabels["order"]["unable-to-verify-address"]}
        </p>
      </span>
    </>
  );
};

export const getAddressAlreadyExistsForDialogModal = (appLabels) => {
  return (
    <>
      <span className="order-status__order-details-date-address">
        <p className="address-validation__recommended-address-header-container address-validation__unverified-address-header-container">
          {appLabels["order"]["address-already-exists"]}
        </p>
      </span>
    </>
  );
};

import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helper functions
import {
  getOrderItemDetails,
  getOrderStoreAddress,
  getTotalPointsRedeemed,
  removeDuplicatesAndUpdateCount,
} from "./orderHistoryHelpers";
import {
  getDayOfWeekFromIndexForOrderStatus,
  getMonthFromIndexForOrderStatus,
} from "../OrderStatus/OrderStatusHelpers";
import { capitalizeWords } from "../../_common/stringHelpers";
import { jsonCopy, toDollars } from "../../_common/helpers";

// Contexts
import LocationsContext from "../Locations/LocationsContext";
import { MenuContext } from "../Menu/MenuContext";
import CartContext from "../Cart/CartContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";

// UI components
import StarIcon from "@material-ui/icons/Star";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { ReorderButton } from "./ReorderButton";
import { RecentOrderOptionsList } from "./RecentOrderOptionsList";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { DialogModal } from "../../_common/DialogModal/DialogModal";

export const CompletedOrderDetails = (props) => {
  const params = useParams();

  const merchantConfigContext = useContext(MerchantConfigContext);
  const appSettings = useContext(AppSettingsContext);
  const skin = merchantConfigContext.skin;

  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);

  const allStores = useContext(LocationsContext);
  const foodMenuContext = useContext(MenuContext);
  const cart = useContext(CartContext);

  const deviceWidth = useWindowSize().width;

  const foodMenu = foodMenuContext.apiData;
  const menuLevel = foodMenuContext.menuLevel;

  const isAddonQuantityEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";

  const [currentOrder, setCurrentOrder] = useState(null);

  useEffect(() => {
    localforage.getItem(skin + "__orderHistory").then((orderHistory) => {
      if (orderHistory) {
        const filteredOrder = orderHistory.filter((order) => order.id === params.id);
        getOrderItemDetails(
          skin,
          filteredOrder[0].id,
          filteredOrder[0].storeLocation.id,
          appLanguage
        ).then((itemDetails) => {
          let filteredOrderWithDetails = jsonCopy(filteredOrder[0]);
          filteredOrderWithDetails.discountTotal = itemDetails.discountTotal;
          filteredOrderWithDetails.items = itemDetails.items;
          filteredOrderWithDetails.paymentDetails = itemDetails.paymentDetails;
          filteredOrderWithDetails.rewardDetails = itemDetails.rewardDetails;
          filteredOrderWithDetails.orderAddress = itemDetails.orderAddress;
          setCurrentOrder({ ...filteredOrderWithDetails });
        });
      }
    });
  }, []);

  const [isNotification, setIsNotification] = useState(false);
  const [isAlertNotification, setIsAlertNotification] = useState(false);

  const updateIsNotification = (isOpen) => {
    setIsNotification(isOpen);
    if (!isOpen && isAlertNotification) setIsAlertNotification(false); // reset alert
  };

  const formatOrderDateAndTime = (orderDateString) => {
    const crossPlatformDateObject = new Date(orderDateString.replace(" ", "T"));

    const orderDay = capitalizeWords(
      getDayOfWeekFromIndexForOrderStatus(crossPlatformDateObject.getDay(), appLabels).substr(0, 3)
    );
    const orderDate = crossPlatformDateObject.getDate();
    const orderMonth = capitalizeWords(
      getMonthFromIndexForOrderStatus(crossPlatformDateObject.getMonth(), appLabels).substr(0, 3)
    );
    const orderHour = crossPlatformDateObject.getHours().toString().padStart(2, "0");
    const orderMinute = crossPlatformDateObject.getMinutes().toString().padStart(2, "0");

    return `${orderDay.substr(0, 3)} ${orderMonth} ${orderDate} ${
      appLabels["account"]["order-timestamp-at"]
    } ${orderHour}:${orderMinute}`;
  };

  let uniqueOrderItems = [];

  if (currentOrder !== null) {
    uniqueOrderItems = removeDuplicatesAndUpdateCount(currentOrder.items, isAddonQuantityEnabled);
  }

  let paymentRewardDetailsAvailable = false;

  if (currentOrder) {
    paymentRewardDetailsAvailable =
      (currentOrder.paymentDetails && currentOrder.paymentDetails.length > 0) ||
      (currentOrder.rewardDetails && currentOrder.rewardDetails.length > 0)
        ? true
        : false;
  }

  return (
    <>
      {currentOrder == null && <LoadingSpinner />}
      {currentOrder && (
        <>
          {deviceWidth >= 600 && (
            <div className="recent-order__back-button">
              <button className="recent-order__back-button-arrow">
                <Link
                  to={{
                    pathname: `/online-ordering/recent-orders/past-orders`,
                  }}
                >
                  <KeyboardBackspaceIcon />
                </Link>
              </button>
              <span className="recent-order__back-button-label">
                {appLabels["order"]["past-orders"]}
              </span>
            </div>
          )}
          <Box p={2} sx={{ paddingBottom: "7em" }}>
            <Typography component={"span"}>
              <div className="recent-order__current-order">
                <section className="recent-order__header">
                  <span>{formatOrderDateAndTime(currentOrder.date)}</span>
                  <span>#{currentOrder.id}</span>
                </section>
                <section className="recent-order__main">
                  {
                    <div className="order-status__transition">
                      <div
                        className={`${
                          deviceWidth >= 660 ? "order-status__completed-order-reorder-desktop" : ""
                        }`}
                      >
                        <div
                          className={`${
                            deviceWidth >= 660
                              ? "order-status__completed-order-reorder-desktop-child"
                              : ""
                          }`}
                        >
                          <h2 className="recent-order__order-details-store-name">
                            {currentOrder.storeLocation.storeName}
                          </h2>
                          {getOrderStoreAddress(currentOrder, appLabels, true)},
                          {allStores &&
                            !!allStores.find(
                              (store) => store.storeId === currentOrder.storeLocation.id
                            ) && (
                              <span className="order-status__order-details-phone-number">
                                {appLabels["account"]["order-status-make-changes-blurb"]
                                  .replace(
                                    "[phone-number]",
                                    allStores.find(
                                      (store) => store.storeId === currentOrder.storeLocation.id
                                    ).phone
                                  )
                                  .split("+1")
                                  .pop()}
                              </span>
                            )}
                        </div>
                        <div
                          className={`${
                            deviceWidth >= 660
                              ? "order-status__completed-order-reorder-desktop-child"
                              : ""
                          }`}
                        >
                          {deviceWidth >= 660 && (
                            <span className="recent-order__reorder-button-desktop">
                              {!currentOrder.isFromDisabledStore &&
                                <ReorderButton
                                  order={currentOrder}
                                  foodMenu={foodMenu}
                                  cart={cart}
                                  updateIsNotification={updateIsNotification}
                                  setIsAlertNotification={setIsAlertNotification}
                                  menuLevel={menuLevel}
                                  orderStore={currentOrder.storeLocation}
                                />
                              }
                            </span>
                          )}
                        </div>
                      </div>
                      {currentOrder.items && !!currentOrder.items.length && (
                        <ul className="order-status__order-details-items-list-current-order">
                          {uniqueOrderItems.map((item, index) => (
                            <React.Fragment key={item.name + "-" + index}>
                              <li className="recent-order__item">
                                <h2 className="recent-order__item-name-price">
                                  <span className="recent-order__order-units">{item.quantity}</span>
                                  <span className="recent-order__item-name">{item.name}</span>
                                  <span className="recent-order__item-price">
                                    {`
                          ${toDollars(
                            appSettings["currency-symbol"],
                            appSettings["currency-symbol-side"],
                            item.price,
                            appLanguage
                          )}
                          `}
                                  </span>
                                </h2>
                                {item.modifiers && (
                                  <RecentOrderOptionsList
                                    type="modifier"
                                    options={item.modifiers}
                                    isAddonQuantityEnabled={isAddonQuantityEnabled}
                                  />
                                )}
                                {item.addons && (
                                  <RecentOrderOptionsList
                                    type="addon"
                                    options={item.addons}
                                    isAddonQuantityEnabled={isAddonQuantityEnabled}
                                  />
                                )}
                                {item.combo_child_items && (
                                  <ul
                                    className="recent-order__combo-child-items"
                                    key={"combo-child-item-" + item.id + "-" + index}
                                  >
                                    {item.combo_child_items.map((childItem, childItemIndex) => (
                                      <React.Fragment key={childItem.id + "-" + childItemIndex}>
                                        <li>
                                          <h2 className="recent-order__combo-child-item-name">
                                            {childItem.name}
                                          </h2>
                                          {!!childItem.modifierGroups.length && (
                                            <RecentOrderOptionsList
                                              type="modifier"
                                              options={childItem.modifierGroups}
                                              isAddonQuantityEnabled={isAddonQuantityEnabled}
                                            />
                                          )}
                                          {!!childItem.addonGroups.length && (
                                            <RecentOrderOptionsList
                                              type="addon"
                                              options={childItem.addonGroups}
                                              isAddonQuantityEnabled={isAddonQuantityEnabled}
                                            />
                                          )}
                                        </li>
                                      </React.Fragment>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            </React.Fragment>
                          ))}
                          <span
                            className={` recent-order__tip-total-completed-orders ${
                              paymentRewardDetailsAvailable
                                ? "recent-order__border-bottom-completed-order"
                                : ""
                            }`}
                          >
                            {currentOrder.discountTotal &&
                              parseFloat(currentOrder.discountTotal) > 0 && (
                                <p className="recent-order__tip-discount-completed-orders">
                                  <span>{appLabels["order"]["discount"]}</span>
                                  <span>-${currentOrder.discountTotal}</span>
                                </p>
                              )}
                            {currentOrder.paymentDetails &&
                              currentOrder.paymentDetails.length > 0 &&
                              currentOrder.paymentDetails[0].paymentTip &&
                              parseFloat(currentOrder.paymentDetails[0].paymentTip) > 0 && (
                                <p className="recent-order__tip-discount-completed-orders">
                                  <span>{appLabels["order"]["tip"]}</span>
                                  <span>${currentOrder.paymentDetails[0].paymentTip}</span>
                                </p>
                              )}
                            <p className="recent-order__total-completed-orders">
                              <span>
                                {appLabels["general"]["total"]}
                                {": "}
                              </span>
                              <span>{currentOrder.total}</span>
                            </p>
                          </span>
                        </ul>
                      )}
                    </div>
                  }
                </section>
                <section
                  className={`${
                    paymentRewardDetailsAvailable
                      ? "recent-order__footer-completed-order-details"
                      : ""
                  }`}
                >
                  {currentOrder.paymentDetails &&
                    currentOrder.paymentDetails.length > 0 &&
                    currentOrder.paymentDetails[0].pointsEarned &&
                    currentOrder.paymentDetails[0].pointsEarned !== null &&
                    currentOrder.paymentDetails[0].pointsEarned !== "" &&
                    !isNaN(currentOrder.paymentDetails[0].pointsEarned) && (
                      <p className="recent-order__footer-completed-current-order">
                        <span>{appLabels["order"]["points-earned"]}</span>
                        <span className="recent-order__footer-points">
                          +{parseInt(currentOrder.paymentDetails[0].pointsEarned)}
                          <StarIcon className="recent-order__footer-star-icon" />
                        </span>
                      </p>
                    )}
                  {currentOrder.rewardDetails && currentOrder.rewardDetails.length > 0 && (
                    <p className="recent-order__footer-completed-current-order">
                      <span>{appLabels["order"]["points-redeemed"]}</span>
                      <span className="recent-order__footer-points">
                        -{getTotalPointsRedeemed(currentOrder.rewardDetails)}
                        <StarIcon className="recent-order__footer-star-icon" />
                      </span>
                    </p>
                  )}
                  {currentOrder.paymentDetails && currentOrder.paymentDetails.length > 0 && (
                    <p className="recent-order__footer-completed-current-order">
                      <span>
                        {appLabels["order"]["charged-to"]}{" "}
                        {currentOrder.paymentDetails[0].paymentType}
                      </span>
                      {currentOrder.paymentDetails[0].paymentCard !== "None" && (
                        <span>{currentOrder.paymentDetails[0].paymentCard}</span>
                      )}
                    </p>
                  )}
                </section>
              </div>
            </Typography>
          </Box>
          {deviceWidth < 660 && (
            <div className="recent-order__footer-reorder-button">
              {!currentOrder.isFromDisabledStore &&
                <ReorderButton
                  order={currentOrder}
                  foodMenu={foodMenu}
                  cart={cart}
                  updateIsNotification={updateIsNotification}
                  setIsAlertNotification={setIsAlertNotification}
                  menuLevel={menuLevel}
                  orderStore={currentOrder.storeLocation}
                />
              }
            </div>
          )}
        </>
      )}
      {isNotification && isAlertNotification && (
        <DialogModal
          message={appLabels["account"]["recent-orders-item-doesnt-exist-message"]}
          resetRemoveDialog={updateIsNotification}
        />
      )}
    </>
  );
};

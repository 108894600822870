import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

import AppLabelsContext from "../../App/AppLabelsContext";

export const ViewDetailsButton = ({ recentOrders }) => {
  const deviceWidth = useWindowSize().width;
  const appLabels = useContext(AppLabelsContext);

  return (
    <Link
      className={`button recent-order__reorder-button button--pill-shaped ${
        deviceWidth < 660
          ? "recent-order-view-details-button"
          : "recent-order-view-details-button-desktop"
      }`}
      to={{
        pathname: `/online-ordering/order-status?order_id=${recentOrders.id}`,
        state: {
          recentOrders: recentOrders,
        },
      }}
    >
      {appLabels["account"]["order-details"]}
    </Link>
  );
};

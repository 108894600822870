import { callAPI } from "../../../../../_common/Api";

export const getAPIAccountInfo = async (skin, loginToken, language) => {
  if (loginToken === "idle" || loginToken === null) {
    return;
  }

  const api950Data = await callAPI(skin, "dc_950", {
    params: [language, "950", "mqid", "mqpass", loginToken, ""],
    id: "950",
  });

  if (api950Data.result.I0) {
    const creditCardId = api950Data.result.I27;
    const creditCardInfo = api950Data.result.I34;
    const maskedCardNumber = creditCardInfo.split("|")[0];
    const cardExpiry = creditCardInfo.split("|")[1];
    const isStoredCard = creditCardId && creditCardId !== "" ? true : false;

    if (isStoredCard) {
      return { id: creditCardId, maskedNumber: maskedCardNumber, expiry: cardExpiry };
    }
  } else if (api950Data.status === 249 || api950Data.status === 150 || api950Data.status === 148) {
    return { status: "expiredLoginToken" };
  } else {
    return { error: api950Data.error };
  }
};

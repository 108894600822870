// Libraries
import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

// Helper functions
import { clearTemporaryPasswordOnExpiry } from "../clearTemporaryPasswordOnExpiry";

// Contexts
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";

// UI components
import { LoginRegisterBackButton } from "../LoginRegisterBackButton/LoginRegisterBackButton";
import { LoginForm } from "./LoginForm";
import useWindowSize from "../../_common/hooks/useWindowSize";

export const LoginWithTemporaryPassword = () => {
  const skin = useContext(MerchantConfigContext).skin;
  const appLabels = useContext(AppLabelsContext);
  const deviceWidth = useWindowSize().width;

  const history = useHistory();

  useEffect(() => {
    clearTemporaryPasswordOnExpiry(skin).then((isExpired) => {
      if (isExpired) {
        history.push({
          pathname: "/",
        });
      }
    });
  }, []);

  return (
    <div className="login-with-temp-password__wrapper">
      {deviceWidth < 660 && <LoginRegisterBackButton isCloseButton={true} />}
      <h1 className="login-with-temp-password__heading">
        {appLabels["login-register"]["temporary-login-paragraph"]}
      </h1>
      <div>
        <LoginForm
          customFormWrapperClass=" login-temporary-password__form-wrapper"
          isResetPasswordLogin={true}
        />
      </div>
    </div>
  );
};

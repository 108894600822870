import React, { useContext } from "react";
import AppLabelsContext from "../App/AppLabelsContext";

export const LoginRegisterGuestLink = ({ proceedToDashboard }) => {
  const appLabels = useContext(AppLabelsContext);

  return (
    <div className="login-register__bottom-link-wrapper link--continue-as-guest-wrapper">
      <button
        onClick={proceedToDashboard}
        className="login-register__bottom-link link--continue-as-guest"
        type="button"
      >
        {appLabels["login-register"]["continue-as-guest"]}
      </button>
    </div>
  );
};

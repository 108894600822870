import React, { useContext, useState } from "react";

/** Contexts */
import AppLabelsContext from "../../../App/AppLabelsContext";

/** UI Components */
import BillItem from "./BillItem";
import CollapsableSection from "../../../_common/components/CollapsableSection";

const BillItemsGroupCollapsable = (props) => {
  const {
    billItemsGroup,
    groupIndex,
    updateSelectedBillItems,
    selectedBillItems,
    hasCheckbox,
    initiallyClosed,
    readOnlyItem,
    numberOfAllBillItems,
  } = props;

  const allItemsInGroupArePaid =
    billItemsGroup.filter((item) => item["pay_status"] === "full").length === billItemsGroup.length;

  const allItemsInGroupAreBilled =
    billItemsGroup.filter((item) => item["pay_status"] === "billed").length ===
    billItemsGroup.length;

  const allItemsInGroupArePartiallyPaid =
    billItemsGroup.filter((item) => item["pay_status"] === "partial").length ===
    billItemsGroup.length;

  const allItemsInGroupAreReadyOnly =
    readOnlyItem ||
    billItemsGroup.filter((item) => item["pay_status"] === "readonly").length ===
      billItemsGroup.length;

  const appLabels = useContext(AppLabelsContext);

  const [isCollapsed, setIsCollapsed] = useState(allItemsInGroupArePaid ? true : initiallyClosed);

  const onCheckBoxChange = (e) => {
    const currentSelectedBillItems = selectedBillItems ? [...selectedBillItems] : [];

    for (let i = 0; i < billItemsGroup.length; i++) {
      const billItem = billItemsGroup[i];
      const elem = document.getElementById(
        `${billItem["item_id"]}-${billItem["seat_num"]}-${billItem["vxl_order_line_id"]}-checkbox`
      );
      if (elem) {
        if (
          elem.checked !== e.target.checked &&
          billItem["pay_status"] !== "full" &&
          billItem["pay_status"] !== "paid" &&
          billItem["pay_status"] !== "billed" &&
          billItem["pay_status"] !== "partial" &&
          billItem["pay_status"] !== "readonly"
        ) {
          elem.checked = e.target.checked;
          if (elem.checked) {
            currentSelectedBillItems.push(billItem);

            //Check if all the items in the bill are selected, if so, check the group checkbox (pay all items)
            if (currentSelectedBillItems.length === numberOfAllBillItems) {
              if (document.getElementById("pay-one-bill-0")) {
                document.getElementById("pay-one-bill-0").checked = true;
              }
            }
          } else {
            const unselectedItemIndex = currentSelectedBillItems.indexOf(
              currentSelectedBillItems.find(
                (tempItem) =>
                  tempItem["item_id"] === billItem["item_id"] &&
                  tempItem["seat_num"] === billItem["seat_num"] &&
                  tempItem["vxl_order_line_id"] === billItem["vxl_order_line_id"] &&
                  tempItem["total_price"] === billItem["total_price"]
              )
            );
            currentSelectedBillItems.splice(unselectedItemIndex, 1);
            const oneBillButton = document.getElementById("pay-one-bill-0");
            if (oneBillButton && oneBillButton.checked) {
              oneBillButton.checked = false;
            }
          }
        }
      }
    }

    updateSelectedBillItems(currentSelectedBillItems);
  };

  return (
    <>
      <CollapsableSection
        headerText={
          allItemsInGroupArePaid
            ? appLabels["order"]["seat"].replace("[seat-number]", billItemsGroup[0]["seat_num"])
            : window.location.href.includes("bill-payment")
            ? appLabels["account"]["ordered-items"]
            : appLabels["order"]["ordered-together"]
        }
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        id={
          "billItemsCollapsable-" +
          groupIndex +
          "-" +
          billItemsGroup[0]["seat_num"] +
          "-" +
          billItemsGroup[0]["vxl_order_line_id"]
        }
        customStyle={
          allItemsInGroupArePaid
            ? { backgroundColor: "#ebebeb", color: "#4caf52", paddingLeft: "25px" }
            : { backgroundColor: "#ebebeb", paddingLeft: "25px" }
        }
        hasCheckbox={window.location.href.includes("bill-payment") ? false : true}
        hasCheckmark={allItemsInGroupArePaid}
        onCheckboxChange={(e) => onCheckBoxChange(e)}
        groupIndex={groupIndex}
        isCheckboxDisabled={
          allItemsInGroupAreBilled ||
          allItemsInGroupArePartiallyPaid ||
          readOnlyItem ||
          allItemsInGroupAreReadyOnly
        }
      />
      <ul
        className={` bill-items-group-container ${
          isCollapsed
            ? " collapsable-section__collapsed-content"
            : " collapsable-section__expanded-content"
        }`}
      >
        {billItemsGroup.map((billItem, billItemIndex) => (
          <BillItem
            item={billItem}
            key={billItem["item_id"] + "-" + billItemIndex + "-" + billItem["vxl_order_line_id"]}
            seatItem={billItem["seat_num"]}
            updateSelectedBillItems={updateSelectedBillItems}
            selectedBillItems={selectedBillItems}
            hasCheckbox={hasCheckbox}
            readOnlyItem={readOnlyItem || billItem["pay_status"] === "readonly"}
            orderedTogetherCheckboxId={
              "billItemsCollapsable-" +
              groupIndex +
              "-" +
              billItemsGroup[0]["seat_num"] +
              "-" +
              billItemsGroup[0]["vxl_order_line_id"] +
              "-" +
              groupIndex
            }
            billItemsGroup={billItemsGroup}
            numberOfAllBillItems={numberOfAllBillItems}
          />
        ))}
      </ul>
    </>
  );
};
export default BillItemsGroupCollapsable;

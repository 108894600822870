import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { getSubcategoryList } from "./helpers/getMenuData";
import AppLabelsContext from "../../App/AppLabelsContext";
import { MenuContext } from "./MenuContext";
import { MenuFavoritesCategoryCard } from "./MenuCategoryCard/MenuFavoritesCategoryCard";
import { MenuCategoryCard } from "./MenuCategoryCard/MenuCategoryCard";
import { AdditionalDisclaimer } from "../../_common/AdditionalDisclaimer";
import UserRoleContext from "../../App/UserRoleContext";

export const MenuTopCategoriesGrid = () => {
  const appLabels = useContext(AppLabelsContext);
  const foodMenu = useContext(MenuContext);

  const userRoleContext = useContext(UserRoleContext);
  const userStatus = userRoleContext.status;
  const isLoggedIn = userStatus === "logged-in";

  const history = useHistory();

  useEffect(() => {
    //reset scroll to position zero
    if (!!document.getElementsByClassName("menu-main-content").length) {
      document.getElementsByClassName("menu-main-content")[0].scrollTo(0, 0);
    }
  }, []);

  const navigateToNextMenuLevel = (id) => {
    const subcategoryList = getSubcategoryList(foodMenu, id);

    if (Object.entries(subcategoryList).length === 1) {
      // If there is only one subcategory under the category skip to item level
      const subcategoryId = Object.entries(subcategoryList)[0][1].id;

      foodMenu.updateMenu({
        ...foodMenu,
        activeTopCategory: id,
        activeSubcategory: subcategoryId,
        activeItem: null,
      });

      history.push(`/online-ordering/menu/category/${id}/subcategory/${subcategoryId}`);
    } else {
      foodMenu.updateMenu({
        ...foodMenu,
        activeTopCategory: id,
        activeSubcategory: null,
        activeItem: null,
      });
      history.push(`/online-ordering/menu/category/${id}`);
    }

    //reset scroll to position zero
    if (!!document.getElementsByClassName("menu-main-content").length) {
      document.getElementsByClassName("menu-main-content")[0].scrollTo(0, 0);
    }
  };

  const topCategories =
    !!foodMenu.apiData && foodMenu.apiData !== "reset" ? foodMenu.apiData : null;

  return (
    <ul className="menu__category-cards-grid">
      {isLoggedIn && <MenuFavoritesCategoryCard />}
      {topCategories &&
        Object.keys(topCategories).map((key, index) => (
          <React.Fragment key={`${key}-${index}`}>
            {parseInt(topCategories[key].noOfItems) > 0 && (
              <MenuCategoryCard
                handleClick={navigateToNextMenuLevel}
                itemData={topCategories[key]}
                index={index}
              />
            )}
            {index === Object.keys(topCategories).length - 1 && (
              <AdditionalDisclaimer disclaimer={appLabels["order"]["menu-disclaimer"]} />
            )}
          </React.Fragment>
        ))}
    </ul>
  );
};

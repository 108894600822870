// Libraries
import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Helper functions
import { toDollars } from "../_common/helpers";

// API helper functions
import { callAPI } from "../_common/Api";

// Contexts
import AppSettingsContext from "../App/AppSettingsContext";
import AppLanguageContext from "../App/AppLanguageContext";
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import UserRoleContext from "../App/UserRoleContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";

// Assets
import { ReactComponent as IconGift } from "../_common/icons/IconGift.svg";
import { ReactComponent as IconPlusCircle } from "../_common/icons/IconPlusCircle.svg";
import { ReactComponent as IconCheck } from "../_common/icons/IconCheck.svg";
import { ReactComponent as IconGoldStar } from "../_common/icons/IconGoldStar.svg";

export const ScanInStoreAllCards = ({ loyaltyCards, setLoyaltyCards, activeLoyaltyCard }) => {
  const merchantConfigContext = useContext(MerchantConfigContext);
  const appLanguage = useContext(AppLanguageContext);
  const userRoleContext = useContext(UserRoleContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const appSettings = useContext(AppSettingsContext);
  const appLabels = useContext(AppLabelsContext);

  const skin = merchantConfigContext.skin;
  const loginToken = userRoleContext.loginToken;

  const isBalanceTransferEnabled = cws5ModuleContext.isBalanceTransferEnabled;
  const isLoyaltyEnabled = cws5ModuleContext.isLoyaltyEnabled;

  const removeCard = (card) => {
    // TODO: add a confirmation dialog

    callAPI(skin, "dc_952", {
      params: [appLanguage, "952", "mqid", "mqpass", loginToken, "", card.iso, card.serial],
      id: "dc_952",
    })
      .then((data952) => {
        if (data952.result.I1 === "0") {
          const remainingLoyaltyCards = data952.result.I5;
          setLoyaltyCards(remainingLoyaltyCards);
        } else {
          console.error(data952);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <section className="scan-in-store__gift-cards-section">
      <div className="scan-in-store__gift-cards-header-and-transfer-funds">
        <div className="scan-in-store-gift-cards-section__header">
          <IconGift aria-hidden="true" />
          <h2 className="payment-method-section__heading">{appLabels["general"]["gift-card"]}</h2>
        </div>
        <div className="scan-in-store-gift-cards-section__transfer-funds">
          {isBalanceTransferEnabled && (
            <p className="scan-in-store-gift-cards-section__transfer-funds-text">
              {appLabels["account"]["transfer-funds-blurb"]}
            </p>
          )}
          {isBalanceTransferEnabled && (
            <Link
              to="/pay-in-store/transfer-funds"
              className="scan-in-store-gift-cards-section__link"
            >
              <IconPlusCircle aria-hidden="true" />
              <span>{appLabels["account"]["transfer-funds"]}</span>
            </Link>
          )}
        </div>
      </div>
      {loyaltyCards && (
        <ul className="scan-in-store__gift-cards-list">
          {loyaltyCards.map((card, index) => (
            <li key={index} className="scan-in-store-gift-cards-list__item">
              <div className="scan-in-store-gift-cards-list-item__info">
                <div className="scan-in-store-gift-cards-list-item-info__icon-and-masked-number">
                  {card.serial === activeLoyaltyCard.serial && (
                    <div className="scan-in-store-gift-cards-list-item-info__icon-wrapper">
                      <IconCheck aria-hidden="true" />
                    </div>
                  )}
                  <div className="scan-in-store-gift-cards-list-item-info__masked-number">
                    {card.maskedNumber}
                  </div>
                </div>
                {(card.name || card.expiry) && (
                  <div className="scan-in-store-gift-cards-list-item-info__name-and-expiry">
                    {card.name && <div>{card.name}</div>}
                    {card.expiry && (
                      <div>
                        {appLabels["general"]["expires-on"]} {card.expiry}
                      </div>
                    )}
                  </div>
                )}
                {index > 0 && isLoyaltyEnabled && (
                  <div className="payment-method-section__balance">
                    <span>
                      {toDollars(
                        appSettings["currency-symbol"],
                        appSettings["currency-symbol-side"],
                        card.balance,
                        appLanguage
                      )}
                      <span className="visually-hidden"> card balance</span>
                    </span>
                    <span>
                      {card.points}
                      <span className="visually-hidden"> card points</span>
                      <IconGoldStar aria-hidden="true" />
                    </span>
                  </div>
                )}
              </div>
              {index > 0 && (
                <button
                  onClick={() => removeCard(card)}
                  aria-label={`${appLabels["general"]["remove"]} ${card.name} card`}
                  className="payment-methods__remove-button"
                  type="button"
                >
                  {appLabels["general"]["remove"]}
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

import React, { useEffect, useContext } from "react";
import DatePicker from "react-multi-date-picker";

/** Contexts */
import AppLabelsContext from "../../App/AppLabelsContext";
import BrandingContext from "../../App/BrandingContext";
import { ReactComponent as IconCalendar } from "../../_common/icons/IconCalendar.svg";

const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
export const PastOrdersCalaendar = (props) => {
  const { setOrderHistoryDates, orderHistoryDates, datePickerRef, hideCalendar } = props;

  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);

  const secondaryColour = branding["secondary-colour"];

  useEffect(() => {
    if (!!orderHistoryDates && orderHistoryDates.length > 0) {
      if (document.getElementById("past-orders__calendar")) {
        const startDate = new Date(` ${orderHistoryDates[0].format()} 00:00:00`).toLocaleDateString(
          "en-US"
        );
        const endDate = new Date(`${orderHistoryDates[1].format()} 23:59:59`).toLocaleDateString(
          "en-US"
        );

        document.getElementById("past-orders__calendar").placeholder = `${startDate} - ${endDate}`;
      }
    }
  }, [orderHistoryDates]);

  useEffect(() => {
    //put the icon under rmdp-container div
    if (
      document.getElementsByClassName("rmdp-container").length > 0 &&
      document.getElementsByClassName("past-orders__icon-container").length > 0
    ) {
      document
        .getElementsByClassName("rmdp-container")[0]
        .appendChild(document.getElementsByClassName("past-orders__icon-container")[0]);
    }
  }, []);

  // Returns the last date of the current week (ending on Saturday)
  const getCalandarMaxDate = () => {
    const dt = new Date();
    const maxDate = new Date(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate() - (new Date().getDay() - 1) + 6
    );

    return maxDate;
  };

  return (
    <div
      className="past-orders__calendar-container"
      style={{ display: hideCalendar ? "none" : "" }}
    >
      <label
        className="past-orders__calendar-label"
        htmlFor="past-orders__calendar"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        {appLabels["order"]["select-date-range"]}
      </label>

      <DatePicker
        range
        weekPicker
        editable={false}
        weekDays={weekDays}
        headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
        onChange={(dateObjects) => {
          setOrderHistoryDates(dateObjects);
        }}
        placeholder={appLabels["order"]["select-dates"]}
        id="past-orders__calendar"
        inputClass="past-orders__calendar-input"
        dateSeparator=" - "
        minDate={new Date("01/01/2015")} //set the min date to 01/01/2019
        maxDate={getCalandarMaxDate()} //set the max date to last day of the week
        onOpenPickNewDate={false}
        ref={datePickerRef}
        mapDays={({ date, today, selectedDate, currentMonth, isSameDate }) => {
          let props = {};

          props.style = {
            backgroundColor: date.month.index === currentMonth.index ? "trasparent" : "",
          };

          if (isSameDate(date, today)) {
            props.style.color = secondaryColour;
            props.style.backgroundColor = "transparent";
          }

          /** add the custom class to dates that fall in betweens selected range */
          if (selectedDate && selectedDate.length > 1) {
            const dateFrom = new Date(`${selectedDate[0].format()} 00:00:00`);
            const dateTo = new Date(`${selectedDate[1].format()} 23:59:59`);
            const dateToCheck = new Date(`${date.format()} 00:00:00`);
            if (
              dateToCheck.getTime() > dateFrom.getTime() &&
              dateToCheck.getTime() < dateTo.getTime()
            ) {
              props.className = "past-orders__calendar-day-selected";
            }
          }

          return props;
        }}
      />

      <button
        className="past-orders__icon-container"
        aria-label={appLabels["order"]["open-calendar"]}
        onClick={() => {
          datePickerRef.current.openCalendar();
        }}
      >
        <IconCalendar aria-hidden="true" className="icon icon-calendar" />
      </button>
    </div>
  );
};

// Libraries
import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// Custom hooks
import { useAppDesktopHeaderNavData } from "./useAppDesktopHeaderNavData";
import { useOutsideClickDetection } from "../../_common/hooks/useOutsideClickDetection";

// Contexts
import BrandingContext from "../BrandingContext";
import ManifestContext from "../ManifestContext";
import MerchantConfigContext from "../MerchantConfigContext";
import AppLabelsContext from "../AppLabelsContext";
import CWS5ModuleContext from "../CWS5ModuleContext";

// Assets
import { ReactComponent as IconMenu } from "../../_common/icons/IconHamburger.svg";

// CSS
import "./AppDesktopHeader.css";

// UI components
import { AppDesktopHeaderNavItem } from "./AppDesktopHeaderNavItem";

export const AppDesktopHeader = () => {
  const merchantConfigContext = useContext(MerchantConfigContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const isGuestPurchaseEnabled = cws5ModuleContext.isGuestEnabled;
  const skin = merchantConfigContext.skin;

  const [showHeaderOptions, setShowHeaderOptions] = useState(false);
  useEffect(() => {
    if (
      window.location.hash === "#/login-register" ||
      window.location.hash === "#/forgot-password" ||
      window.location.hash === "#/register" ||
      window.location.hash === "#/terms-and-conditions" ||
      window.location.hash === "#/"
    ) {
      if (isGuestPurchaseEnabled) {
        setShowHeaderOptions(true);
      } else {
        const storedLogin = JSON.parse(sessionStorage.getItem(skin + "__login"));
        if (storedLogin) setShowHeaderOptions(true);
      }
    } else {
      setShowHeaderOptions(true);
    }
  }, [window.location.hash]);

  const navData = useAppDesktopHeaderNavData(showHeaderOptions);

  const [isNavOpen, setIsNavOpen] = useState(false);
  function toggleMenu() {
    setIsNavOpen(!isNavOpen);
  }

  useEffect(() => {
    if (isNavOpen) {
      document.querySelector(".App").addEventListener("scroll", closeMenu);
      return () => document.querySelector(".App").removeEventListener("scroll", closeMenu);
    }
  }, [isNavOpen]);

  const closeMenu = () => {
    setIsNavOpen(false);
  };

  const navToggleRef = useRef(null);
  const navRef = useRef(null);

  const isClickOutsideNav = useOutsideClickDetection(navToggleRef, navRef);

  useEffect(() => {
    if (isClickOutsideNav) {
      setIsNavOpen(false);
    }
  }, [isClickOutsideNav]);

  return (
    <header className="desktop-header">
      <div className="desktop-container desktop-header-container">
        <MainLogo showHeaderOptions={showHeaderOptions} />
        <button
          ref={navToggleRef}
          onClick={toggleMenu}
          id="desktop-header__menu-toggle"
          aria-label={`${isNavOpen ? "Hide" : "Show"} menu`}
          aria-expanded={isNavOpen}
          type="button"
        >
          <IconMenu aria-hidden={true} />
        </button>
        <nav ref={navRef} className="desktop-header__nav-menu">
          <ul className="desktop-header__nav-list">
            {navData &&
              navData.map((module, index) => (
                <AppDesktopHeaderNavItem
                  key={index}
                  module={module}
                  noAdditionalLanguage={
                    navData.filter((data) => data.name === "language").length === 0
                  }
                />
              ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

const MainLogo = ({ showHeaderOptions }) => {
  const branding = useContext(BrandingContext);
  const manifestShortName = useContext(ManifestContext).short_name;
  const appLabels = useContext(AppLabelsContext);
  const merchantLogo = (
    <img
      src={branding["main-logo"]}
      alt={`${manifestShortName} logo`}
      className="app-header__logo"
    />
  );

  const hiddenH1 = <h1 className="visually-hidden">{manifestShortName}</h1>;

  return (
    <>
      {showHeaderOptions ? (
        <Link
          to="/dashboard"
          aria-label={appLabels["general"]["go-to-dashboard"]}
          className="desktop-app-header__logo-wrapper desktop-app-header__logo-link"
        >
          {merchantLogo}
          {hiddenH1}
        </Link>
      ) : (
        <div className="desktop-app-header__logo-wrapper">
          {merchantLogo}
          {hiddenH1}
        </div>
      )}
    </>
  );
};

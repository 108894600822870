import React, { useContext } from "react";
import { toDollars } from "../../../../../_common/helpers";

import AppSettingsContext from "../../../../../App/AppSettingsContext";
import AppLanguageContext from "../../../../../App/AppLanguageContext";
import AppLabelsContext from "../../../../../App/AppLabelsContext";

import { ReactComponent as IconX } from "../../../../../_common/icons/IconX.svg";
import "./AppliedGiftCardsBreakdown.css";

export const AppliedGiftCardsBreakdown = (props) => {
  const { orderTotal, appliedGiftCards, amountDue, removeAppliedCard } = props;

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  const displayOrderTotal = toDollars(
    appSettings["currency-symbol"],
    appSettings["currency-symbol-side"],
    orderTotal,
    appLanguage
  );
  const displayAmountDue = toDollars(
    appSettings["currency-symbol"],
    appSettings["currency-symbol-side"],
    amountDue,
    appLanguage
  );

  const remainingTotalClass = amountDue > 0 ? " guest-giftcard__post-apply-total--remaining" : "";

  const appLabels = useContext(AppLabelsContext);

  return (
    <div className="guest-giftcard__balance-summary">
      <div className="guest-giftcard__order-total-wrapper">
        <span>{appLabels["order"]["order-total"] + ":"}</span>
        <span className="guest-giftcard__value-column guest-giftcard__order-total">
          {displayOrderTotal}
        </span>
      </div>
      {appliedGiftCards.length === 1 && (
        <div key={appliedGiftCards[0].maskedNumber} className="guest-giftcard__applied-card-row">
          <GiftCardRowContents
            giftCard={appliedGiftCards[0]}
            removeAppliedCard={removeAppliedCard}
            currencySymbol={appSettings["currency-symbol"]}
            currencySymbolSide={appSettings["currency-symbol-side"]}
            language={appLanguage}
          />
        </div>
      )}
      {appliedGiftCards.length > 1 && (
        <ul className="guest-giftcard__applied-cards">
          {appliedGiftCards.map((appliedGiftCard) => (
            <li key={appliedGiftCard.maskedNumber} className="guest-giftcard__applied-card-row">
              <GiftCardRowContents
                giftCard={appliedGiftCard}
                removeAppliedCard={removeAppliedCard}
                currencySymbol={appSettings["currency-symbol"]}
                currencySymbolSide={appSettings["currency-symbol-side"]}
                language={appLanguage}
              />
            </li>
          ))}
        </ul>
      )}
      <div className={`guest-giftcard__post-apply-total-wrapper${remainingTotalClass}`}>
        <span>{appLabels["order"]["amount-due"] + ":"}</span>
        <span className="guest-giftcard__value-column guest-giftcard__post-apply-total">
          {displayAmountDue}
        </span>
      </div>
    </div>
  );
};

const GiftCardRowContents = (props) => {
  const { giftCard, removeAppliedCard, currencySymbol, currencySymbolSide, language } = props;

  const appLabels = useContext(AppLabelsContext);

  return (
    <>
      <div className="gift-card__row_contents">
        <button
          onClick={() => removeAppliedCard(giftCard)}
          className="button button--remove-gift-card"
          type="button"
          aria-label={appLabels["order"]["remove-applied-giftcard"]}
        >
          <IconX />
        </button>
        <div>
          <div className="guest-giftcard__name">{giftCard.maskedNumber}</div>
          <div className="guest-giftcard__remainder-wrapper">
            <em>
              {appLabels["order"]["remaining-balance-on-gift-card"] + ": "}
              <span>
                {toDollars(currencySymbol, currencySymbolSide, giftCard.remainingBalance, language)}
              </span>
            </em>
          </div>
        </div>
      </div>
      <div>
        <span className="guest-giftcard__value-column guest-giftcard__pre-apply-total">
          {"-" + toDollars(currencySymbol, currencySymbolSide, giftCard.deductedBalance, language)}
        </span>
      </div>
    </>
  );
};

import React, { useContext } from "react";
import { toDollars } from "../../../../_common/helpers";
import AppSettingsContext from "../../../../App/AppSettingsContext";
import AppLanguageContext from "../../../../App/AppLanguageContext";
import AppLabelsContext from "../../../../App/AppLabelsContext";

export const AddonModifierLabel = (props) => {
  const { option, isRadio, isNested, topOptionGroupId, inputId, customClass, id } = props;

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabel = useContext(AppLabelsContext);

  const calories = option.calorie_count;
  const price =
    option.price > 0
      ? toDollars(
          appSettings["currency-symbol"],
          appSettings["currency-symbol-side"],
          option.price,
          appLanguage
        )
      : null;

  // option.item_count = "0"; // for testing the "out of stock" label

  return (
    <label
      htmlFor={
        inputId
          ? inputId
          : (isNested ? topOptionGroupId + "__" : "") +
            "option-" +
            option.parentId +
            "-" +
            option.id
      }
      className={`form__field-label--checkbox-radio ${
        isRadio ? "form__field-label--radio" : "form__field-label--checkbox"
      } ${customClass ? customClass : ""}`}
      id={id ? id : ""}
    >
      <span>
        {option.name}
        {Number(option.item_count) <= 0 && (
          <span className="item-addon__out-of-stock-label">
            {" "}
            {appLabel["order"]["out-of-stock"]}
          </span>
        )}
      </span>
      {(price || calories) && (
        <span className="menu-item__addon-price">
          {calories && price && calories + " " + appLabel["order"]["calories"] + " | " + price}
          {calories && !price && calories + " " + appLabel["order"]["calories"]}
          {price && !calories && price}
        </span>
      )}
    </label>
  );
};

import React from "react";

//CSS
import "./DashboardActionButton.css";

export const DashboardActionButton = (props) => {
  const {
    isLeftAligned,
    isCenterAligned,
    title,
    subtitle,
    icon,
    iconBackGroundColour,
    onClick,
    ariaLabel,
    id,
    hasArrow,
    collapsedArrow,
    expandedArrow,
    isCollapsed,
    customClass,
  } = props;

  const handleOnClick = () => {
    onClick();
  };

  return (
    <>
      <div
        className={`dashboard-action-button ${isLeftAligned ? "left-aligned" : ""} ${
          isCenterAligned ? "center-aligned" : ""
        } ${customClass ? customClass : ""}`}
        id={id ? id : ""}
      >
        <div
          className="dashboard-action-button__icon-container"
          style={iconBackGroundColour ? { backgroundColor: iconBackGroundColour } : {}}
        >
          {icon}
        </div>
        <div className="dashboard-action-button__title-container">
          <span className="dashboard-action-button__title">{title}</span>
          {subtitle && <span className="dashboard-action-button__subtitle">{subtitle}</span>}
        </div>
        {hasArrow && (
          <div className="dashboard-action-button__arrow-container">
            {isCollapsed ? collapsedArrow : expandedArrow}
          </div>
        )}
        <button
          type="button"
          aria-label={ariaLabel}
          onClick={() => handleOnClick()}
          className="dashboard-action-button__button"
        ></button>
      </div>
    </>
  );
};

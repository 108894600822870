import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";
import he from "he";

import { formatCartForApi } from "../../../../_common/CartHelpers";
import { formatOrderType, getDeviceTypeId } from "../../../../_common/helpers";
import { callAPI, callPaymentAPI } from "../../../../_common/Api";
import {
  getDayOfWeekFromIndex,
  getMonthFromIndex,
} from "../../../Locations/helpers/isStoreOpenOrClosed";
import { getTimeAmPm, getTrueBusinessDate } from "../../../../Dashboard/DashboardOrder/dateHelpers";
import {
  updateLocationData,
  ensureOrderTimeMinInterval,
  isOrderTimePassed,
  isOrderTimeBeforeOpeningTime,
  isOrderTimeBlocked,
  isLateOrderAttempted,
  isStoreClosedToday,
} from "../../../../_common/PaymentHelpers";

import MerchantConfigContext from "../../../../App/MerchantConfigContext";
import OrderTypeContext from "../../../OrderTypeContext";
import StoreContext from "../../../StoreContext";
import CartContext from "../../../Cart/CartContext";
import OrderTimeContext from "../../../OrderTimeContext";
import AppLabelsContext from "../../../../App/AppLabelsContext";
import AppLanguageContext from "../../../../App/AppLanguageContext";
import UserRoleContext from "../../../../App/UserRoleContext";

import { ReactComponent as IconPaypal2 } from "../../../../_common/icons/IconPaypal2.svg";

import { DialogModal } from "../../../../_common/DialogModal/DialogModal";
import { LoadingSpinner } from "../../../../_common/LoadingSpinner";
import { Form } from "../../../../_common/Form/Form";
import { getOrderStoreAddressForDialogModal } from "../../../../_common/PaymentHelpers";

export const PaymentMethodPaypal = (props) => {
  const { promoCode, rewards, paymentMethodExpanded } = props;

  const history = useHistory();

  const merchantConfig = useContext(MerchantConfigContext);
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const userRoleContext = useContext(UserRoleContext);

  const skin = merchantConfig.skin;

  const orderTypeContext = useContext(OrderTypeContext);
  const storeContext = useContext(StoreContext);
  const cart = useContext(CartContext);

  const activeOrderType = orderTypeContext.value;
  const activeOrderStore = storeContext.activeOrderStore;

  const loginToken = userRoleContext.loginToken;
  const isLoggedIn = userRoleContext.status === "logged-in";

  const [apiError, setAPIError] = useState("");

  const [isAPISubmitting, setIsAPISubmitting] = useState(null);
  const [isAPISubmitValid, setIsApiSubmitValid] = useState(null);

  const [customerInfo, setCustomerInfo] = useState({});
  const [orderTypeInstructions, setOrderTypeInstructions] = useState("None");
  const [deliveryAddress, setDeliveryAddress] = useState(null);

  const [isLateOrderAttempt, setIsLateOrderAttempt] = useState(false);
  const [isPassedOrderTime, setIsPassedOrderTime] = useState(false);
  const [isOrderTimeBeforeOpening, setIsOrderTimeBeforeOpening] = useState(false);
  const [nextAvailableOrderTime, setNextAvailableOrderTime] = useState(null);
  const [orderTimeIsBlocked, setOrderTimeIsBlocked] = useState(false);
  const [isStoreClosed, setIsStoreClosed] = useState(false);

  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [tipAmount, setTipAmount] = useState(0);

  const [orderDate, setOrderDate] = useState(null);
  const [orderTime, setOrderTime] = useState(null);

  useEffect(() => {
    if (appliedCoupon == null) {
      localforage.getItem(skin + "__coupon").then((coupon) => {
        if (coupon) setAppliedCoupon(coupon);
      });
    }
  }, [appliedCoupon]);

  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      if (customerInfo) setCustomerInfo(customerInfo);
      //else history.goBack();
    });

    localforage.getItem(skin + "__orderTypeSpecialInstruction").then((orderInstruction) => {
      if (orderInstruction) {
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let orderInstructionCopy = orderInstruction;
            orderInstructionCopy += `, ${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(orderInstructionCopy);
          } else {
            setOrderTypeInstructions(orderInstruction);
          }
        });
      } else {
        //there is no order instruction set
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let sectionRowSeatInfo = `${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(sectionRowSeatInfo);
          }
        });
      }
    });

    if (activeOrderType === "delivery") {
      localforage.getItem(skin + "__userDeliveryAddress").then((storedDeliveryAddress) => {
        if (storedDeliveryAddress) setDeliveryAddress(storedDeliveryAddress);
        else setDeliveryAddress(false);
      });
    }

    localforage.getItem(skin + "__storedTip").then((storedTip) => {
      if (storedTip) {
        setTipAmount(storedTip.tipAmount);
      }
    });

    localforage.getItem(skin + "__orderDate").then((orderDate) => {
      if (orderDate) {
        setOrderDate(orderDate.displayValue);
      }
    });

    localforage.getItem(skin + "__orderTime").then((orderTime) => {
      if (orderTime) {
        setOrderTime(orderTime.displayValue);
      }
    });
  }, []);

  // Scroll to the top of the expand/collapse toggle button after details panel is expanded
  useEffect(() => {
    const timer = setTimeout(() => paymentMethodExpanded(), 300);
    return () => clearTimeout(timer);
  }, []);

  const orderTimeContext = useContext(OrderTimeContext);

  const getCurrentTimestamp = () => {
    const date = new Date(
      ensureOrderTimeMinInterval(orderTimeContext.value.value, activeOrderStore, activeOrderType)
    );
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return timestamp;
  };

  const formatOrderTime = (orderTime) => {
    const date = new Date(orderTime);
    const month = date.getMonth();
    const calDate = date.getDate();
    const day = date.getDay();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;

    const nameOfDay = getDayOfWeekFromIndex(day);
    const timestamp = `${
      nameOfDay.substr(0, 3).charAt(0).toUpperCase() + nameOfDay.substr(1, 2)
    } ${getMonthFromIndex(month)} ${calDate} at ${strTime}`;

    return (
      <>
        <br /> <br /> <span style={{ fontWeight: 700 }}>{timestamp} </span>
        <br /> <br />{" "}
      </>
    );
  };
  useEffect(() => {
    localforage.getItem(skin + "__paypalPaymentStarted").then((paypalPaymentStarted) => {
      if (paypalPaymentStarted) {
        localforage.getItem(skin + "__completedOrderId").then((completedOrderId) => {
          if (completedOrderId) {
            cancelOrder(completedOrderId);
          }
        });
      }
    });
  }, []);

  const cancelOrder = (orderID) => {
    //cancel The order using API 1303
    callAPI(skin, "vxl_1303", {
      params: ["en", "1303", "mqid", "mqpass", activeOrderStore.storeId, orderID],
      id: "1303",
    }).then((data1303) => {
      localforage.removeItem(skin + "__paypalPaymentStarted")
      localforage.removeItem(skin + "__completedOrderId")
      history.push("/online-ordering/payment");
    });
  };

  const updateOrderTime = () => {
    orderTimeContext.update({
      value: nextAvailableOrderTime,
      displayValue: getTimeAmPm(nextAvailableOrderTime),
      trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
      timeAtOrdering: new Date()
    });
    setIsPassedOrderTime(false);
  };

  const isStadiumSchema = merchantConfig.merchant.I55 === "stadium";

  const submitPaypalOrder = async () => {
    setIsAPISubmitting(true);

    const formattedOrderType = formatOrderType(activeOrderType);
    const timestamp = getCurrentTimestamp();
    const formattedCartItems = formatCartForApi(cart.value);

    let order_address = activeOrderStore.address;
    let order_postal = activeOrderStore.postal;
    let order_city = activeOrderStore.city;

    if (activeOrderType === "delivery") {
      if (isStadiumSchema) {
        order_postal = deliveryAddress;
      } else {
        const isSecondAddress = deliveryAddress["second-address"];
        order_address =
          deliveryAddress["first-address"] +
          (isSecondAddress ? ", #" + deliveryAddress["second-address"] : "");
        order_postal = deliveryAddress.postal;
        order_city = deliveryAddress.city;
      }
    }

    const placeOrderData = await callPaymentAPI(skin, "dc_cws_vxl_online_order", {
      params: [
        skin,
        "consumer",
        "en",
        activeOrderStore.storeId,
        formattedOrderType,
        timestamp || "", // Order Pickup/Delivery Timestamp (optional),
        {
          customer_id: customerInfo.id || "",
          order_email: customerInfo.email || "",
          order_fname: customerInfo.firstName || "",
          order_lname: customerInfo.lastName || "",
          order_phone: customerInfo.phone || "",
          order_mobile: customerInfo.mobile || "",
          order_address,
          order_postal,
          order_city,
          order_prov: activeOrderStore.province,
          order_country: activeOrderStore.country,
          discount_for_the_whole_order: promoCode,
          redeem_rewards: rewards,
          customer_token: isLoggedIn ? loginToken : ""
        }, // CustomerInfo
        formattedCartItems, // ItemsList
        activeOrderType === "delivery" ? orderTypeInstructions : "None", // Delivery Instructions // Table Number (optional)
        activeOrderType === "dinein" ? storeContext.activeOrderStoreTable : "", // Table Number (optional),
        customerInfo.cpfNumber, // government_id
        "", // gc_number_list
        "", // save_credit_card
        "", // tm_session_id
        "", // scene_cardnum
        "PAYPAL", // payment_type
        "", // cc_id
        "", // cc_type
        "", // cc_number
        "", // cc_name
        "", // cc_security
        "", // cc_issue
        "", // cc_expiry_month
        "", // cc_expiry_year
        "", // cc_start_month
        "", // cc_start_year
        "", // cc_address1
        "", // cc_address2
        "", // cc_city
        "", // cc_province
        "", // cc_country
        "", // cc_postal
        customerInfo.email, // cc_email
        customerInfo.phone, // cc_phone
        "", // pa_res
        window.location.origin + window.location.pathname + "#/online-ordering/payment/paypal", // return_url
        window.location.origin +
          window.location.pathname +
          "#/online-ordering/payment/paypal?cancel=true", // cancel_url
        window.location.origin + window.location.pathname + "#/online-ordering/payment/paypal", // success_url
        "", // error_url
        "", // fail_url
        "", // unique_pay_id
        "", // wechat_oauth_id
        "", // url_3ds_success
        "", // url_3ds_failure
        activeOrderType === "pickup" || activeOrderType === "dinein"
          ? orderTypeInstructions
          : "None", // //Special Instruction (pickup)
        tipAmount, //tip
        getDeviceTypeId(formattedOrderType), // device type id
      ],
      id: "dc_cws_vxl_online_order",
    });

    if (placeOrderData) {
      //if no errors:
      if (placeOrderData.status === 0) {
        setIsApiSubmitValid(true);
        const completedOrderId = placeOrderData.result.I0;
        localforage.setItem(skin + "__completedOrderId", completedOrderId);
        localforage.setItem(skin + "__usedPaymentMethod", "PAYPAL");
        localforage.setItem(skin + "__paypalPaymentStarted", true);
        window.location = placeOrderData.result.I4;
      } else {
        setIsApiSubmitValid(false);
        setIsAPISubmitting(null);

        if (
          placeOrderData.error &&
          placeOrderData.error.message.includes(
            "Customer ID can only be used when a customer is logged in"
          )
        ) {
          userRoleContext.handleLoginTokenExpiration();
        } else {
          setAPIError(
            `${appLabels["general"]["error-code"]}: ${placeOrderData.error.code}. ${he.decode(
              placeOrderData.result.message
            )}`
          );
        }
      }
    }
  };

  const [showDoubleConfirmation, setShowDoubleConfirmation] = useState(false);

  const validateOrderTime = async () => {
    //update the location with the latest data, in case the store hours have been changed
    const updatedLocation = await updateLocationData(
      activeOrderStore,
      activeOrderType,
      skin,
      appLanguage
    );
    if (updatedLocation) {
      let isStoreClosedForTheDay = isStoreClosedToday(updatedLocation, orderTimeContext)

      const orderTimeIsPassed = isOrderTimePassed(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        setNextAvailableOrderTime
      );
      const orderTimeIsBeforeOpening = isOrderTimeBeforeOpeningTime(
        updatedLocation,
        orderTimeContext
      );
      const orderTimeIsBlocked = isOrderTimeBlocked(
        updatedLocation,
        orderTimeContext,
        activeOrderType
      );
      const lateOrderAttempt = isLateOrderAttempted(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        skin,
        setNextAvailableOrderTime
      );

      if (lateOrderAttempt) {
        isStoreClosedForTheDay = true
      }

      if (isStoreClosedForTheDay) {
        setIsStoreClosed(true);
        return;
      } else if (orderTimeIsPassed) {
        setIsPassedOrderTime(true);
        setAPIError(<>&nbsp;</>); // reset the disabled state
        return false;
      } else if (orderTimeIsBeforeOpening) {
        setIsOrderTimeBeforeOpening(true);

        setAPIError(<>&nbsp;</>); // reset the disabled state
        return false;
      } else if (orderTimeIsBlocked) {
        setOrderTimeIsBlocked(true);
        return;
      } else {
        if (activeOrderType === "pickup") {
          setShowDoubleConfirmation(true);
        } else {
          submitPaypalOrder();
          return true;
        }
      }
    } else {
      // if updatedLocation returns null, it means the selected location does not exist anymore
      history.push("/dashboard");
    }
  };

  const onConfirmClick = () => {
    setShowDoubleConfirmation(false);
    submitPaypalOrder(); // call 1302
  };

  return (
    <>
      <div className="paypal-button__container">
        <p>{appLabels["order"]["complete-order-pay-with-paypal"]}</p>
        <IconPaypal2 className="icon icon--paypal2" />
      </div>

      <Form
        id="form--pay-with-paypal"
        isFAB={true}
        submitButtonText={appLabels["order"]["submit-order-button"]}
        submitButtonSuccessText={appLabels["order"]["submit-order-button-processing"]}
        submitForm={validateOrderTime}
        submitAPIError={apiError}
        resetAPIError={() => setAPIError("")}
        isAPISubmitValid={isAPISubmitValid}
        isAPISubmitting={isAPISubmitting}
        isAllowEmptySubmit={true}
        buttonWrapperClass={"form--credit-card__submit-button"}
      ></Form>
      {isStoreClosed && (
        <DialogModal
          hideCloseButton={true}
          closeModalAction={() => {
            setIsStoreClosed(false);
          }}
          isCancelConfirm={false}
          hideConfirmButton={false}
          isCustomConfirmText={appLabels["order"]["pick-new-time"]}
          resetRemoveDialog={() => {
            history.push({
              pathname: "/dashboard",
              state: {
                showTimePanel: true,
                timeAtOrdering: orderTimeContext.value.timeAtOrdering,
                originalOrderTimeContext: orderTimeContext
              },
            });
          }}
          message={
            <div>
              <h2>{appLabels["order"]["sorry-it-is-closing-time"]}</h2>
              <p>{appLabels["order"]["store-closed-message"]}</p>
            </div>
          }
        />
      )}
      {isLateOrderAttempt && (
        <DialogModal
          message={appLabels["order"]["order-too-close-to-closing-time-error"]}
          resetRemoveDialog={() => {
            setIsLateOrderAttempt(false);
            orderTimeContext.update({
              value: nextAvailableOrderTime,
              displayValue: getTimeAmPm(nextAvailableOrderTime),
              trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
            });
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}
      {isPassedOrderTime && (
        <DialogModal
          message={
            <div>
              {appLabels["order"]["select-order-time-passed-message"]}
              {": "}
              {formatOrderTime(nextAvailableOrderTime)}
              {appLabels["order"]["confirm-updated-order-time-message"]}
            </div>
          }
          resetRemoveDialog={() => setIsPassedOrderTime(false)}
          isCancelConfirm={true}
          confirmAction={updateOrderTime}
          isHTMLContent={true}
        />
      )}

      {isOrderTimeBeforeOpening && (
        <DialogModal
          message={appLabels["order"]["order-before-store-opening-time-error"]}
          resetRemoveDialog={() => {
            setIsOrderTimeBeforeOpening(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {orderTimeIsBlocked && (
        <DialogModal
          message={appLabels["order"]["order-time-is-blocked"]}
          resetRemoveDialog={() => {
            setOrderTimeIsBlocked(false);
            history.push("/dashboard");
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {showDoubleConfirmation && (
        <DialogModal
          message={getOrderStoreAddressForDialogModal(
            activeOrderStore,
            appLabels,
            orderDate,
            orderTime,
            activeOrderType,
            orderTimeContext,
            skin
          )}
          isConfirmText={true}
          isModifyText={appLabels["order"]["modify-location"]}
          isCancelConfirm={true}
          confirmAction={onConfirmClick}
          resetRemoveDialog={() => {
            history.push("/online-ordering/order-store");
          }}
          closeModalAction={() => {
            setShowDoubleConfirmation(!showDoubleConfirmation);
            window.location.reload(); // This will have to change when the BE adds support for validating store hours.
          }}
        />
      )}

      {!!apiError && <DialogModal message={apiError} resetRemoveDialog={() => setAPIError(null)} />}

      {isAPISubmitting && <LoadingSpinner />}
    </>
  );
};

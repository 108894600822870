import React, { useContext, useEffect, useState } from "react";
import localforage from "localforage";
import { useHistory } from "react-router-dom";

/** Contexts */
import AppLanguageContext from "../../../App/AppLanguageContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";
import StoreContext from "../../StoreContext";
import BillContext from "./BillContext";
import OrderTypeContext from "../../OrderTypeContext";

//Hooks
import { useHideOrderModule } from "../../../App/useHideOrderModule";

/** Helpers */
import {
  calculateBillItemsSubtotal,
  cleanUpOrderListBasedOnUnits,
  getOrderFromTable,
} from "./BillHelpers";
import { jsonCopy } from "../../../_common/helpers";

/** UI Components */
import { LoadingSpinner } from "../../../_common/LoadingSpinner";
import MenuBillToggle from "../MenuBillToggle";
import BillHeaderDetails from "./BillHeaderDetails";
import BillItemsGroupCollapsable from "./BillItemsGroupCollapsable";
import PaymentCollapsable from "../../Payment/PaymentCollapsable";
import { CartSummaryCollapsable } from "../../Cart/CartSummaryCollapsable";
import ConfirmationEmailCollapsable from "../../Cart/ConfirmationEmail/ConfirmationEmailCollapsable";

const BillPayment = () => {
  const history = useHistory();
  const appLanguage = useContext(AppLanguageContext);
  const merchantConfig = useContext(MerchantConfigContext);
  const billContext = useContext(BillContext);
  const orderTypeContext = useContext(OrderTypeContext);

  const billItems = billContext.value;

  const storeContext = useContext(StoreContext);

  const activeStore = storeContext.activeOrderStore;
  const activeTableNumber = storeContext.activeOrderStoreTable;
  const skin = merchantConfig.skin;
  const activeOrderType = orderTypeContext && orderTypeContext.value;
  const activeStoreConfig = activeStore.vexilorConfig;

  const billPaymentIsEnabled = activeStoreConfig["get_order_from_table"] === "t";
  const { hideOrderModule } = useHideOrderModule();

  useEffect(() => {
    /** If bill payment is not enabled but user somehow lands on this page, redirect to dashboard */
    if (billPaymentIsEnabled === false) {
      history.push("/dashboard");
    }
  }, [billPaymentIsEnabled]);

  const [orderSummary1322, setOrderSummary1322] = useState(null);
  const [storedPromoCode, setStoredPromoCode] = useState("");
  const [coupon, setCoupon] = useState("");
  const [storedRewards, setStoredRewards] = useState([]);
  const [isTaxIncluded, setIsTaxIncluded] = useState(null);
  const [billOrderSummary, setBillOrderSummary] = useState(null);

  const [storedRewardsDetails, setStoredRewardsDetails] = useState(null);

  let uniqueBillItems = cleanUpOrderListBasedOnUnits(jsonCopy(billItems));

  useEffect(() => {
    getOrderFromTable(appLanguage, skin, activeTableNumber, activeStore.storeId).then((apiData) => {
      if (apiData) {
        if (apiData.status === 0) {
          setOrderSummary1322(apiData.result.I2);
        }
      }
    });
  }, []);

  /** Tip Related functions */
  const updateStoredTipAmount = (newOrderSummaryData, tipObject) => {
    const amount = tipObject.tipAmount || 0;

    localforage.setItem(skin + "__storedTip", tipObject);
    const tempOrderSummaryData = { ...newOrderSummaryData };
    tempOrderSummaryData.tipAmount = amount > 0 ? parseFloat(amount).toFixed(2) : 0;
    setBillOrderSummary(tempOrderSummaryData);
  };

  /**
   *
   * @param {Array} selectedItems - array of selected items
   * @returns the total tax and subtotal of the selected items
   */
  const getSelectedItemsTotal = (selectedItems) => {
    let totalTax = 0;

    selectedItems.forEach((item) => {
      totalTax += Number(item["tax_amount"]);
    });

    return {
      tax: Number(totalTax.toFixed(2)),
      price: Number(calculateBillItemsSubtotal(selectedItems).toFixed(2)),
    };
  };

  /** End of  Tip Related functions */
  useEffect(() => {
    if (orderSummary1322 && billContext && billContext.value) {
      const selectedItemsTotal = getSelectedItemsTotal(billContext.value);
      const temp = {
        discountTotal: "",
        orderTotal: selectedItemsTotal.price + selectedItemsTotal.tax, // Number(orderSummary1322["order_grand_total"]),
        serviceChargeAmount:
          orderSummary1322["service_charge"] > 0 ? orderSummary1322["service_charge"] : "",
        serviceChargeDesc: orderSummary1322["service_charge_desc"],
        taxTotal: selectedItemsTotal.tax, //orderSummary1322["order_taxes"],
        tipAmount: "",
        items: billContext.value, // orderSummary1322["item_list"],
        orderSubtotal: selectedItemsTotal.price,
      };

      setIsTaxIncluded(!!temp.taxTotal ? false : true);
      localforage.setItem(skin + "__orderSummary", JSON.stringify(temp));
      setBillOrderSummary(temp);
    }
  }, [orderSummary1322, billContext]);

  const isOnBillPaymentPage = window.location.href.includes("bill-payment");

  return (
    <>
      {billPaymentIsEnabled && !hideOrderModule && <MenuBillToggle initialState="bill" />}
      {orderSummary1322 && billOrderSummary ? (
        <>
          <BillHeaderDetails
            orderSummary1322={orderSummary1322}
            billOrderSummary={billOrderSummary}
          />

          {activeOrderType === "dinein" && <ConfirmationEmailCollapsable />}

          {billItems && !!billItems.length && (
            <div className="bill__items_container">
              <BillItemsGroupCollapsable
                billItemsGroup={uniqueBillItems}
                groupIndex={0}
                hasCheckbox={false}
                initiallyClosed={true}
              />
            </div>
          )}

          <div className="desktop-container cart-content-desktop">
            <div className="cart-summary-tip-desktop">
              <CartSummaryCollapsable
                items={billItems}
                isAnimateIcon={false}
                orderSummaryData={billOrderSummary}
                currentLocation={activeStore}
                storedPromoCode={storedPromoCode}
                setStoredPromoCode={setStoredPromoCode}
                coupon={coupon}
                setCoupon={setCoupon}
                storedRewards={storedRewards}
                setStoredRewards={setStoredRewards}
                setShowCheckoutButton={null}
                isTaxIncluded={isTaxIncluded}
                setStoredRewardsDetails={setStoredRewardsDetails}
                storedRewardsDetails={storedRewardsDetails}
                skin={skin}
                updateStoredTipAmount={updateStoredTipAmount}
                activeOrderType={activeOrderType}
              />

              {billOrderSummary && (
                <PaymentCollapsable
                  orderTotal={
                    billOrderSummary.orderTotal + parseFloat(billOrderSummary.tipAmount || 0)
                  }
                  orderSummaryData={billOrderSummary}
                  isOnBillPaymentPage={isOnBillPaymentPage}
                />
              )}

              {/*
              {deviceWidth >= 850 && (
                <AdditionalDisclaimer disclaimer={appLabels["order"]["review-order-disclaimer"]} />
              )}
              */}
            </div>
          </div>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
export default BillPayment;

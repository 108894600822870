// Libraries
import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { styled } from "@material-ui/core";

// Hooks
import useWindowSize from "../_common/hooks/useWindowSize";

// Contexts
import AppLabelsContext from "../App/AppLabelsContext";
import BrandingContext from "../App/BrandingContext";

//Helpers
import { getContrastColor } from "../_common/colorHelpers";

//UI Components
import { LoadingSpinner } from "../../src/_common/LoadingSpinner";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { GoogleMaps } from "../OnlineOrdering/GoogleMaps/GoogleMaps";
import AddressInfoCard from "./AddressInfoCard";

// Assets
import { ReactComponent as IconX } from "../_common/icons/IconX.svg";

// CSS
import "./../_common/DialogModal/DialogModal.css";

const DialogModalPortal = ({
  message,
  confirmAction,
  isCancelConfirm,
  resetRemoveDialog,
  isConfirmText,
  isModifyText,
  closeModalAction,
  hideCloseButton,
  hideConfirmButton,
  showConfirmLoadingSpinner,
  addressType,
  recommendedAddress,
  enteredAddress,
  userLocation,
  customClass,
  onMakeChanges,
  onUseEntered,
  missingComponents,
}) => {
  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);

  const deviceWidth = useWindowSize().width;
  const contrastTextColor = getContrastColor(branding["online-order-settings-background-colour"]);

  const confirmChoice = () => {
    confirmAction(isDefaultChecked);
  };

  const closeModal = () => {
    resetRemoveDialog();
  };

  const recommendedStreetAddress = recommendedAddress.addressLines
    ? recommendedAddress.addressLines[0]
    : "";
  const recommendedCity = recommendedAddress.locality;
  const recommendedProvince = recommendedAddress.administrativeArea;
  const recommendedPostal = recommendedAddress.postalCode;

  const formattedRecommendedAddress = {
    "first-address": recommendedStreetAddress,
    city: recommendedCity,
    province: recommendedProvince,
    postal: recommendedPostal,
    "second-address": enteredAddress["second-address"],
  };

  const [value, setValue] = useState("entered");
  const [isDefaultChecked, setisDefaultChecked] = useState(false);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    confirmAction(value);
  };

  const handleCheckBox = () => {
    const defaultAddressCheckbox = document.getElementById("default-address");
    if (defaultAddressCheckbox.checked) {
      setisDefaultChecked(true);
    } else {
      setisDefaultChecked(false);
    }
  };

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#fff",
    backgroundImage: "none",
    border: "1px solid #ccc",
    "&:before": {
      display: "block",
      width: "50px",
      height: "50px",
      backgroundImage: `radial-gradient(${branding["primary-colour"]},${branding["primary-colour"]} 28%,transparent 32%)`,
      content: '""',
      position: "absolute",
      left: "0px",
      top: "0px",
    },
  });

  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  return ReactDOM.createPortal(
    <div role="dialog" aria-modal="true" className="dialog-modal-container">
      <div
        className={`dialog-modal ${
          addressType === "recommended" ? "dialog-modal__recommended-address-container" : ""
        } ${addressType === "address-issue" ? "dialog-modal__address-issue-container" : ""}
        ${addressType === "verificationIssue" ? "dialog-modal__verification-issue-container" : ""}
        ${addressType === "missingComponent" ? "dialog-modal__missing-component-container" : ""}`}
      >
        {
          <div
            className={`dialog-modal__message ${customClass ? customClass : ""}`}
            style={
              addressType === "recommended" && deviceWidth >= 660
                ? { zIndex: "2", position: "relative" }
                : {}
            }
          >
            {message}
          </div>
        }
        {addressType === "validated" && (
          <>
            <label className="address-validation__primary-address-checkbox">
              <input id="default-address" type="checkbox" onChange={handleCheckBox} />
              {appLabels["order"]["set-as-primary-delivery-address"]}
            </label>
            <div
              className={`dialog-modal__buttons ${
                isCancelConfirm ? " dialog-modal__buttons-two" : ""
              }`}
              style={{ justifyContent: "center" }}
            >
              {isCancelConfirm && (
                <>
                  <button
                    onClick={closeModal}
                    className={`button dialog-modal__button  button--primary-with-border button--primary button--primary-small`}
                    type="button"
                  >
                    {isModifyText ? isModifyText : appLabels["general"]["dialog-modal-cancel"]}
                  </button>
                  <button
                    onClick={confirmChoice}
                    className={`button dialog-modal__button button--primary button--primary-small`}
                    type="button"
                  >
                    {showConfirmLoadingSpinner ? (
                      <LoadingSpinner />
                    ) : isConfirmText ? (
                      isConfirmText
                    ) : (
                      appLabels["general"]["dialog-modal-confirm"]
                    )}
                  </button>
                </>
              )}
            </div>{" "}
          </>
        )}

        {addressType === "recommended" && (
          <div style={deviceWidth >= 660 ? { height: "100%", paddingRight: "0" } : {}}>
            <div className="address-validation__form-and-map">
              <form>
                <FormControl variant="standard">
                  <RadioGroup
                    aria-labelledby="address-validation"
                    name="address-validaiton"
                    value={value}
                    defaultValue="entered"
                    onChange={handleRadioChange}
                  >
                    <div className="address-validation__entered-address-container">
                      <h2 className="address-validation__entered-recommended-address-header">
                        {appLabels["order"]["you-entered"]}
                      </h2>
                      <div className="address-validation__entered-address-radio-container">
                        <AddressInfoCard
                          address={enteredAddress}
                          recommendedAddress={recommendedAddress}
                        />

                        <FormControlLabel value="entered" control={<BpRadio />} label="" />
                      </div>
                    </div>
                    <div className="address-validation__recommended-address-container">
                      <h2 className="address-validation__entered-recommended-address-header">
                        {appLabels["order"]["recommended"]}
                      </h2>
                      <div className="address-validation__entered-address-radio-container">
                        <AddressInfoCard
                          address={formattedRecommendedAddress}
                          recommendedAddress={enteredAddress}
                        />
                        <FormControlLabel value="recommended" control={<BpRadio />} label="" />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </form>
              {userLocation && (
                <div
                  aria-hidden="true"
                  className="confirm-address__google-maps-container-dialog-modal"
                  style={deviceWidth >= 660 ? { zIndex: 1 } : {}}
                >
                  <GoogleMaps userLocation={userLocation} />
                </div>
              )}
            </div>

            {!hideConfirmButton && (
              <>
                {deviceWidth < 660 ? (
                  <div
                    className="dialog-modal__buttons dialog-modal__position-absolute"
                    style={{ justifyContent: "center" }}
                  >
                    <>
                      <button
                        className={`button dialog-modal__button button--primary`}
                        type="submit"
                        style={{ minWidth: "300px" }}
                        onClick={handleSubmit}
                        aria-label={appLabels["account"]["confirm-delivery-address"]}
                      >
                        {isConfirmText
                          ? appLabels["general"]["dialog-modal-confirm"]
                          : appLabels["general"]["dialog-modal-ok"]}
                      </button>
                    </>
                  </div>
                ) : (
                  <div className="FAB-wrapper FAB-wrapper__item-details-page centered wizard show-add-to-order address-validation__desktop-button-container">
                    <div className="FAB-wrapper__gradient"></div>
                    <div className="desktop-container">
                      <button
                        id="button--submit-form"
                        className="FAB button button-primary FAB--left-aligned  FAB--add-to-order"
                        type="submit"
                        style={{
                          color: contrastTextColor,
                          backgroundColor: "transparent",
                          border: `2px solid ${contrastTextColor}`,
                          opacity: 1,
                        }}
                        onClick={handleSubmit}
                      >
                        {isConfirmText
                          ? appLabels["general"]["dialog-modal-confirm"]
                          : appLabels["general"]["dialog-modal-ok"]}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {addressType === "unverified" && (
          <div className="dialog-modal__buttons" style={{ justifyContent: "center" }}>
            {!hideConfirmButton && (
              <button
                onClick={closeModalAction ? () => closeModalAction() : closeModal}
                className={`button dialog-modal__button button--primary`}
                type="submit"
                style={{ minWidth: "300px" }}
              >
                {isConfirmText ? isConfirmText : appLabels["general"]["dialog-modal-ok"]}
              </button>
            )}
          </div>
        )}

        {addressType === "verificationIssue" && (
          <>
            <div className="address-validation__entered-address-container address-validation__verification-entered-address-container">
              <h2 className="address-validation__entered-recommended-address-header">
                {appLabels["order"]["you-entered"]}
              </h2>
              <div className="address-validation__entered-address-radio-container">
                <AddressInfoCard address={enteredAddress} />
              </div>
            </div>
            <div className="address-validation__recommended-address-container">
              <h2 className="address-validation__entered-recommended-address-header">
                {appLabels["order"]["verification-issues"]}
              </h2>
              <p>
                {missingComponents ? (
                  <>
                    {missingComponents === "street-number" && (
                      <span>{appLabels["order"]["street-number-missing"]}</span>
                    )}
                    {missingComponents === "multiple" && (
                      <span>{appLabels["order"]["unable-to-verify-address"]}</span>
                    )}
                  </>
                ) : (
                  <>{appLabels["order"]["street-number-not-found"]}</>
                )}
              </p>
            </div>

            <div className={`dialog-modal__buttons dialog-modal__buttons-two`}>
              <>
                <button
                  onClick={onUseEntered}
                  className={`button dialog-modal__button  button--primary-with-border button--primary button--primary-small `}
                  type="button"
                >
                  {appLabels["order"]["use-entered-address"]}
                </button>
                <button
                  onClick={onMakeChanges}
                  className={`button dialog-modal__button button--primary button--primary-small `}
                  type="button"
                >
                  {appLabels["order"]["make-changes-to-address"]}
                </button>
              </>
            </div>
          </>
        )}

        {addressType === "missingComponent" && (
          <>
            {missingComponents && (
              <p>
                {missingComponents === "street-number" && (
                  <span>{appLabels["order"]["street-number-missing"]}</span>
                )}
              </p>
            )}

            <div className="dialog-modal__buttons" style={{ justifyContent: "center" }}>
              {!hideConfirmButton && (
                <button
                  onClick={closeModalAction ? () => closeModalAction() : closeModal}
                  className={`button dialog-modal__button button--primary`}
                  type="submit"
                  style={{ minWidth: "300px" }}
                >
                  {isConfirmText ? isConfirmText : appLabels["general"]["dialog-modal-ok"]}
                </button>
              )}
            </div>
          </>
        )}

        {!hideCloseButton && (
          <button
            onClick={closeModalAction ? () => closeModalAction() : closeModal}
            aria-label={appLabels["general"]["close-dialog-modal"]}
            className="button dialog-modal__close-button"
            type="button"
          >
            <IconX aria-hidden="true" />
          </button>
        )}
      </div>
    </div>,
    document.body
  );
};

export const AddressValidationDialogModal = React.memo(DialogModalPortal);

/*global google*/
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import localforage from "localforage";

//Contexts
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import AppLanguageContext from "../App/AppLanguageContext";
import UserRoleContext from "../App/UserRoleContext";

//helper functions
import {
  addressAlreadyExist,
  addressAlreadyExistWithoutPrimaryFlag,
  formatDeliveryAddress,
  getAddressIndex,
  getAllStoredAddresses,
  getProvinceValueFromDisplayName,
  handleNewAddressAddition,
  handleSetPrimaryAddress,
} from "../Account/apiHelpers/userAddressHelpers";
import { callGoogleAddressValidationAPI } from "../_common/Api";
import { MAP_KEY } from "../App/AppSetup";

//UI Components
import DeliveryAddressComponent from "./DeliveryAddressComponent";
import { LoadingSpinner } from "../_common/LoadingSpinner";

const DeliveryAddressesComponent = () => {
  const history = useHistory();
  const { state } = useLocation();

  const appLabels = useContext(AppLabelsContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const appLanguage = useContext(AppLanguageContext);
  const userRoleContext = useContext(UserRoleContext);

  const [userAddresses, setUserAddresses] = useState(null);
  const [addressAutoSelection, setAddressAutoSelection] = useState(null);
  const [autoSelectPrimaryAddress, setAutoSelectPrimaryAddress] = useState(null);

  const loginToken = userRoleContext.loginToken;
  const isLoggedIn = userRoleContext.status === "logged-in";
  const skin = merchantConfigContext.skin;

  const noPrimaryAddressSet = (addressList) => {
    let noPrimaryAddressSet = true;
    for (let i = 0; i < addressList.length; i++) {
      let address = addressList[i];
      if (address[2] === "t") {
        noPrimaryAddressSet = false;
        break;
      }
    }
    return noPrimaryAddressSet;
  };
  /** This function calls API 1002 to update the list of addresses stored in user's account */
  const updateAddressList = (autoSelect = true) => {
    getAllStoredAddresses(skin, loginToken, appLanguage, "stored_shipping").then((data1002) => {
      if (data1002) {
        if (data1002.status === "expiredLoginToken") {
          userRoleContext.handleLoginTokenExpiration();
        } else if (data1002.storedAddresses) {
          if (noPrimaryAddressSet(data1002.storedAddresses)) {
            setAddressAutoSelection(false);
          } else {
            if (autoSelect) {
              setAddressAutoSelection(true);
            }
          }

          localforage.getItem(skin + "__deliveryAddressToBeStored").then((deliveryAddress) => {
            if (deliveryAddress) {
              const parsedAddress = JSON.parse(deliveryAddress);

              localforage.removeItem(skin + "__deliveryAddressToBeStored").then(() => {
                localforage
                  .setItem(skin + "__userStoredAddresses", JSON.stringify(data1002.storedAddresses))
                  .then(async () => {
                    const addressExistsWithoutFlag = await addressAlreadyExistWithoutPrimaryFlag(
                      parsedAddress,
                      skin
                    );
                    const addressExists = await addressAlreadyExist(
                      {
                        ...parsedAddress,
                        isDefaultChecked: parsedAddress.isDefaultChecked ? "t" : "f",
                        isDefaultAddress: parsedAddress.isDefaultChecked ? "t" : "f",
                      },
                      skin
                    );

                    const addressIndex = getAddressIndex(parsedAddress, data1002.storedAddresses);
                    if (!addressExistsWithoutFlag.matchFound) {
                      addStoredAddressToAccount(parsedAddress, data1002.storedAddresses);
                    } else if (addressExistsWithoutFlag.matchFound) {
                      if (addressExists) {
                        //if exact match exists
                        updateAddressList(false);
                      } else {
                        onPrimaryAddressChange(
                          data1002.storedAddresses[addressIndex],
                          data1002.storedAddresses
                        );
                      }
                    } else {
                      setUserAddresses(data1002.storedAddresses);
                    }
                  });
              });
            } else {
              setUserAddresses(data1002.storedAddresses);
            }
          });
        } else {
          setUserAddresses([]);
        }
      }
    });
  };

  /** This function change the primary address selected by user */
  const onPrimaryAddressChange = async (selectedAddress, userAddressesParam = null) => {
    setUserAddresses(null);
    const data = await handleSetPrimaryAddress(
      userAddressesParam ? userAddressesParam : userAddresses,
      selectedAddress,
      skin,
      appLanguage,
      loginToken
    );
    if (data) {
      if (data.addressUpdated) {
        updateAddressList(false);
      } else if (data.status === "expiredLoginToken") {
        userRoleContext.handleLoginTokenExpiration();
      } else {
        console.error(data.error);
      }
    }
  };

  /**
   * Handles address addition, when user tried to add an address before logging in.
   * @param {Object} storedAddress , object containing the address stored in localforage
   * @param {Array} currentAddresses , array of object containing all the current stored addresses
   */
  const addStoredAddressToAccount = async (storedAddress, currentAddresses) => {
    const data = await handleNewAddressAddition(
      storedAddress,
      currentAddresses,
      skin,
      appLanguage,
      loginToken
    );

    if (data) {
      if (data.addressAdded) {
        updateAddressList();
      } else if (data.status === "expiredLoginToken") {
        userRoleContext.handleLoginTokenExpiration();
      }
      // if the address already exists
      else if (data.error && data.statusCode === 363) {
        updateAddressList();
      } else {
        console.error(data.error);
      }
    }
  };

  const reverseGeoCodeFromAddress = async (address) => {
    //Reverse geocode
    const geocoder = new google.maps.Geocoder();
    const geoCodeInfo = await geocoder.geocode({ address: address });
    if (geoCodeInfo) {
      if (geoCodeInfo.results[0]) {
        return geoCodeInfo.results[0];
      } else {
        window.alert("No results found");
      }
    }
  };

  const handleSelectedAddress = async (userAddress) => {
    const userAddressComponents = formatDeliveryAddress(userAddress);

    let payload = {
      address: {
        addressLines: [userAddressComponents["first-address"]],
        administrativeArea: userAddressComponents.province,
        locality: userAddressComponents.city,
        regionCode: userAddressComponents.country,
        postalCode: userAddressComponents.postal,
      },
    };

    if (userAddressComponents.country === "HK") {
      const addressString = `${userAddressComponents["first-address"]}, ${
        userAddressComponents["city"]
      }, ${userAddressComponents["province"] ? userAddressComponents["province"] + "," : ""} ${
        userAddressComponents["postal"] ? userAddressComponents["postal"] + "," : ""
      } ${userAddressComponents["country"]}}`;
      const geocodeData = await reverseGeoCodeFromAddress(addressString);
      let lat = geocodeData.geometry.location.lat();
      let lng = geocodeData.geometry.location.lng();

      let userCoordinates = { lat: lat, lng: lng };
      const userDeliveryAddress = {
        "first-address": userAddressComponents["first-address"],
        "second-address": userAddressComponents["second-address"],
        city: userAddressComponents.city,
        country: userAddressComponents.country,
        postal: userAddressComponents.postal,
        province: userAddressComponents.province,
        userCoordinates: userCoordinates,
      };

      localforage.setItem(skin + "__userDeliveryAddress", userDeliveryAddress);
      history.push({
        pathname: "/online-ordering/order-store",
        state: {
          from: "saved-delivery-address",
          userAddress: userDeliveryAddress,
          userCoordinates: userCoordinates,
        },
      });
    } else {
      callGoogleAddressValidationAPI(payload, MAP_KEY)
        .then((apiData) => {
          if (apiData.result) {
            if (apiData.result && apiData.result.verdict) {
              let apiAddressComponent = apiData.result.address.postalAddress;

              //The language code in the address returned by API is reserved for future uses and is ignored today.
              //The API returns the address in the appropriate language for where the address is located.
              //Removing langauge code in order to compare the user enterred address and api returned address
              delete apiAddressComponent.languageCode;

              let lng = apiData.result.geocode.location.longitude;
              let lat = apiData.result.geocode.location.latitude;
              let placeId = apiData.result.geocode.placeId;

              let userCoordinates = { lat: lat, lng: lng, placeId: placeId };

              const userDeliveryAddress = {
                "first-address": apiAddressComponent.addressLines[0],
                "second-address": userAddressComponents["second-address"],
                city: apiAddressComponent.locality,
                country: apiAddressComponent.regionCode,
                postal: apiAddressComponent.postalCode,
                province: apiAddressComponent.administrativeArea,
                userCoordinates: userCoordinates,
              };

              localforage.setItem(skin + "__userDeliveryAddress", userDeliveryAddress);
              history.push({
                pathname: "/online-ordering/order-store",
                state: {
                  from: "saved-delivery-address",
                  userAddress: userDeliveryAddress,
                  userCoordinates: userCoordinates,
                },
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  /**
   * Check if the user has a primary address stores, if so, select that for the delivery address and for the next step
   */
  useEffect(() => {
    if (
      userAddresses &&
      state &&
      state.from === "order-type-screen" &&
      addressAutoSelection !== null
    ) {
      let primaryAddress = null;
      for (let i = 0; i < userAddresses.length; i++) {
        const userAddress = userAddresses[i];
        const isDefaultAddress = userAddress[2] === "t";

        if (isDefaultAddress) {
          primaryAddress = userAddress;
          break;
        }
      }
      if (primaryAddress && addressAutoSelection) {
        handleSelectedAddress(primaryAddress);
      } else {
        setAutoSelectPrimaryAddress(false);
      }
    } else {
      if (userAddresses && (!state || (state && state.from !== "order-stores-screen"))) {
        setAutoSelectPrimaryAddress(false);
      }
    }
  }, [userAddresses, state, addressAutoSelection]);

  useEffect(() => {
    if (loginToken) {
      setTimeout(() => {
        updateAddressList();
      }, 500);
    } else {
      setUserAddresses([]);
    }
  }, [loginToken]);

  useEffect(() => {
    localforage.setItem(skin + "__userStoredAddresses", JSON.stringify(userAddresses));
  }, [userAddresses]);

  useEffect(() => {
    if (!isLoggedIn) {
      localforage.getItem(skin + "__deliveryAddressToBeStored").then((deliveryAddress) => {
        if (deliveryAddress) {
          const parsedAddress = JSON.parse(deliveryAddress);
          localforage.removeItem(skin + "__deliveryAddressToBeStored").then(() => {
            history.push({
              pathname: "/online-ordering/confirm-address",
              state: {
                from: "delivery-address",
                userAddress:
                  parsedAddress.province.length <= 3
                    ? parsedAddress
                    : {
                        ...parsedAddress,
                        province: getProvinceValueFromDisplayName(
                          parsedAddress.country,
                          parsedAddress.province
                        ),
                      },
              },
            });
          });
        }
      });
    }
  }, []);

  return (
    <div className="delivery-address__saved-addresses-wrapper">
      <div className="delivery-address__header-container desktop-container">
        <h2 className="delivery-address__header-your-addresses">
          {appLabels["order"]["your-addresses"]}
        </h2>
        {userAddresses && userAddresses.length === 0 && (
          <p>{appLabels["order"]["no-saved-delivery-address"]}</p>
        )}
      </div>
      {userAddresses !== null && autoSelectPrimaryAddress === false ? (
        <>
          {userAddresses.length > 0 && (
            <section className="desktop-container">
              {userAddresses.map((userAddress) => (
                <React.Fragment key={userAddress[0]}>
                  <DeliveryAddressComponent
                    userAddress={userAddress}
                    appLabels={appLabels}
                    skin={skin}
                    appLanguage={appLanguage}
                    loginToken={loginToken}
                    setUserAddresses={setUserAddresses}
                    setPrimaryAddress={onPrimaryAddressChange}
                    handleSelectedAddress={handleSelectedAddress}
                  />
                </React.Fragment>
              ))}
            </section>
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default DeliveryAddressesComponent;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import localforage from "localforage";

/** Contexts */
import AppLabelsContext from "../../../App/AppLabelsContext";
import BrandingContext from "../../../App/BrandingContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

/** UI Assets */
import { ReactComponent as IconHeart } from "../../../_common/icons/IconHeart.svg";

export const MenuFavoritesCategoryFilterButton = ({ isActive }) => {
  const appLabels = useContext(AppLabelsContext);
  const secondaryColor = useContext(BrandingContext)["secondary-colour"];
  const { skin } = useContext(MerchantConfigContext);
  return (
    <li className="menu__category-filter-button-wrapper">
      <Link
        to="/online-ordering/menu/favorites"
        className="menu__category-filter-button"
        style={{
          color: isActive ? "#fff" : secondaryColor,
          backgroundColor: isActive ? secondaryColor : "#fff",
        }}
        onClick={() => {
          localforage.removeItem(skin + "__itemSelectionInProgress");
          sessionStorage.removeItem(skin + "__orderStoreState");
        }}
      >
        <IconHeart width="12" height="12" fill={isActive ? "#fff" : secondaryColor} />
        <span>{appLabels["order"]["favorites"]}</span>
      </Link>
    </li>
  );
};

import React, { useContext, useState } from "react";
import AppLabelsContext from "../../../App/AppLabelsContext";

/** UI Section */
import CollapsableSection from "../../../_common/components/CollapsableSection";
import SuggestedItems from "./SuggestedItems";

const SuggestedItemsCollapsableSection = (props) => {
  const {
    setShowSuggestedItems,
    suggestedItemsGotGeneratedRef,
    setSuggestedItems,
    suggestedItems,
  } = props;

  const appLabels = useContext(AppLabelsContext);
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="suggestedItems__collapsable_wrapper">
      <CollapsableSection
        headerText={appLabels["order"]["our-picks-for-you"]}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        id={"suggestedItemsCollapsable"}
      />

      <div
        className={`suggestedItems__main_container  ${
          isCollapsed
            ? " collapsable-section__collapsed-content"
            : " collapsable-section__expanded-content"
        }`}
      >
        <SuggestedItems
          setShowSuggestedItems={setShowSuggestedItems}
          suggestedItemsGotGeneratedRef={suggestedItemsGotGeneratedRef}
          setSuggestedItems={setSuggestedItems}
          suggestedItems={suggestedItems}
        />
      </div>
    </div>
  );
};

export const SuggestedItemsCollapsable = React.memo(SuggestedItemsCollapsableSection);

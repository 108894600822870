// shows all the gift cards available to order

// Libraries
import React, { useState, useEffect, useContext } from "react";
import "./GiftCard.css"
import "../_common/CartIndicator/CartIndicator.css"

import { MenuCategoryFilterButton } from "../OnlineOrdering/Menu/MenuCategoryFilterButtonsList/MenuCategoryFilterButton";

import GiftCardCartIndicator from "../_common/GiftCardCartIndicator/GiftCardCartIndicator";
import { GiftCardSlide } from "./GiftCardSlide";
import { GiftCardMenuBreadcrumbs } from "./GiftCardMenuBreadcrumbs";
import GCCartContext from "./GCCartContext";
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import AppLabelsContext from "../App/AppLabelsContext";
import { useLocation } from 'react-router-dom';
import { LoadingSpinner } from "../_common/LoadingSpinner";
import useWindowSize from "../_common/hooks/useWindowSize";

export const AvailableCategorizedGiftCards = (props) => {

  const [categorizedGiftCards, setCategorizedGiftCards] = useState([])
  const [allGiftCards, setAllGiftCards] = useState([])
  const [activeCategory, setActiveCategory] = useState()
  const [loading, setLoading] = useState(false)
  const [hideCategoryChips, setHideCategoryChips] = useState(false)
  const [onlyOneCategory, setOnlyOneCategory] = useState(false)

  const cartContext = useContext(GCCartContext)
  const appLabels = useContext(AppLabelsContext)
  let params = useParams()
  let history = useHistory()
  const { state } = useLocation()
  const deviceWidth = useWindowSize().width

  // checking if list of gift cards has only one category
  useEffect(() => {
    if (state && state.isOnlyOneCategory) {
      setOnlyOneCategory(true)
    }
  }, []);


  useEffect(() => {
    setCategorizedGiftCards(props.categorizedGiftCardsList[props.selectedCategory].data)
    setAllGiftCards(props.categorizedGiftCardsList)
    setActiveCategory(props.selectedCategory)

    // if only one key exisit for all gift cards then there is only one category
    if (props.categorizedGiftCardsList && Object.keys(props.categorizedGiftCardsList).length === 1) {
      setHideCategoryChips(true)
      setOnlyOneCategory(true)
    }
  }, [props.categorizedGiftCardsList]);

  // filter and show correct gift cards based on route or selected chip
  const filterGiftCards = (categoryName) => {
    setLoading(true)
    if (categoryName in allGiftCards) {
      let availableGiftCards = allGiftCards[categoryName].data
      setCategorizedGiftCards([...availableGiftCards])
    }
    setActiveCategory(categoryName)
    history.push(categoryName)
    setLoading(false)
  }
  useEffect(() => {
    let category = params.category
    setActiveCategory(category)
  })
  useEffect(() => {
    if (deviceWidth < 660) {
      setHideCategoryChips(true)
    }
  }, [deviceWidth])

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="availableGiftCardsContainer categorizedCardContainer">

      {categorizedGiftCards && categorizedGiftCards.length > 0 &&
        <>

          {!hideCategoryChips &&
            <ul className="menu__category-buttons-list" >
              {allGiftCards &&
                Object.keys(allGiftCards).length > 0 &&
                Object.keys(allGiftCards).map((key, index) => {
                  return (
                    <React.Fragment key={`${key}-${index}`}>
                      {allGiftCards[key] && (
                        <MenuCategoryFilterButton
                          categoryData={allGiftCards[key]}
                          handleClick={(event) => filterGiftCards(event)}
                          isActive={activeCategory === key ? true : false}
                        />
                      )}
                    </React.Fragment>
                  )
                })}
            </ul>
        }
          {!onlyOneCategory &&
            <GiftCardMenuBreadcrumbs currentCategory={activeCategory} />
          }
        <div>
            <ul className={`menu__category-cards-grid giftCardItems ${onlyOneCategory ? "giftCardsListPaddingTop" : ""} `} >
            {categorizedGiftCards.map((gc, index) => {
              return (

                <GiftCardSlide key={gc.id} giftCard={gc} customClass="invertMargin giftCardItem_display" />

              )
            })}

          </ul>
        </div>
        </>
      }

      {(!loading && categorizedGiftCards && categorizedGiftCards.length) === 0 &&
          <h3>{appLabels["gift-card"]["no-gift-cards-available"]}</h3>
      }
      {cartContext.quantity > 0 &&
        <button
          className={"button button--cart button--cart-floating}"}
            aria-hidden
            onClick={() => history.push("/gift-card/checkout")}
        >
            <GiftCardCartIndicator appLabels={appLabels} quantity={cartContext.quantity} isFloating={true} isCheckoutScreen={false} />
        </button>
      }
    </div>
    </>
  )
}
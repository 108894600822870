import {
  getDayOfWeekFromIndex,
  getFormattedStoreHoursWithMidnight,
} from "../../OnlineOrdering/Locations/helpers/isStoreOpenOrClosed";

export const roundToNearestQuarter = (minutes) => {
  return Math.ceil(minutes / 5) * 5;
};

export const roundTime = (date, roundedMinutes, placingOrderForToday = false) => {
  if (placingOrderForToday) {
    return new Date(date.setTime(date.getTime()));
  } else {
    if (roundedMinutes === 0) {
      var rounded = new Date(Math.ceil(date.getTime()));
      return rounded;
    } else {
      return new Date(date.setTime(date.getTime()));
    }
  }
};

export const newTime = (oldDate, difference) => {
  return new Date(oldDate.getTime() + difference * 60000);
};

export const getTimeAmPm = (newDate) => {
  let date = new Date(newDate);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const amORpm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;

  const startTime = hours + ":" + minutes + " " + amORpm;
  return startTime;
};

export const getMaxIteration = (current, closingTime, minInterval, increment) => {
  // Сalculate the difference between current time and closing time in minutes
  let timeStart = current.getTime();
  let timeEnd = closingTime.getTime();

  let millisecondsDiff = timeEnd - timeStart; // in ms
  let minDiff = millisecondsDiff / 60 / 1000; // in minutes

  let iteration = Math.round(minDiff / increment);

  return iteration;
};

export const prettifyDate = (date, appLanguage) => {
  const splitString = date
    .toLocaleDateString(appLanguage, { weekday: "short", month: "short", day: "numeric" })
    .toString()
    .split(" ");
  return `${splitString[0]} ${splitString[1]} ${splitString[2]}`;
};

export const getTrueBusinessDate = (orderTime, store) => {
  const orderDay = new Date(orderTime);
  const yesterday = new Date(orderDay);
  yesterday.setDate(orderDay.getDate() - 1);

  const orderDayHours = store.hours[getDayOfWeekFromIndex(orderDay.getDay())];
  const orderDayOpeningTime = orderDayHours.split(" - ")[0];
  const orderDayClosingTime = orderDayHours.split(" - ")[1];

  const formattedOrderDayHours = getFormattedStoreHoursWithMidnight(
    orderDayOpeningTime,
    orderDayClosingTime,
    orderDay,
    store
  );
  if (
    formattedOrderDayHours !== "closed" &&
    orderTime > formattedOrderDayHours.formattedOpeningTime &&
    orderTime < formattedOrderDayHours.formattedOpeningTime
  ) {
    return new Date(orderTime);
  } else {
    //check the day before
    const yesterdayHours = store.hours[getDayOfWeekFromIndex(yesterday.getDay())];
    const yesterdayOpeningTime = yesterdayHours.split(" - ")[0];
    const yesterdayClosingTime = yesterdayHours.split(" - ")[1];

    const formattedYesterdayHours = getFormattedStoreHoursWithMidnight(
      yesterdayOpeningTime,
      yesterdayClosingTime,
      yesterday,
      store
    );

    if (
      formattedYesterdayHours !== "closed" &&
      orderDay <= formattedYesterdayHours.formattedClosingTime
    ) {
      return new Date(yesterday);
    } else {
      return new Date(orderTime);
    }
  }
};

export const isLastDayOfMonth = (dt) => {
  var test = new Date(dt.getTime());
  test.setDate(test.getDate() + 1);
  return test.getDate() === 1;
};

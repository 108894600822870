import React, { useContext, useEffect, useState } from "react";
import { Form } from "../../_common/Form/Form";
import { CallApi1013 } from "../CallAPI1013";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import { FormInput } from "../../_common/Form/FormInput";
import localforage from "localforage";
import UserRoleContext from "../../App/UserRoleContext";

export const GCCartDiscount = (props) => {
  const [isPromoAPISubmitValid, setPromoAPISubmitValid] = useState(null);
  const [promoAPIError, setPromoAPIError] = useState("");
  const [isPromoAPIsubmitting, setPromoAPIsubmitting] = useState(false);
  const [appliedPromo, setAppliedPromo] = useState(null);
  const [appliedPromoDetails, setAppliedPromoDetails] = useState() // used for storing promo details for Gift Card cart

  const merchantConfig = useContext(MerchantConfigContext)
  const skin = merchantConfig.skin
  const appLanguage = useContext(AppLanguageContext)
  const appLabels = useContext(AppLabelsContext)
  const userRoleContext = useContext(UserRoleContext)
  const loginToken = userRoleContext.loginToken

  const applyGCPromoCode = (data) => {
    setPromoAPIsubmitting(true);

    const promoCode = data[0].value.trim();
    CallApi1013(skin, props.formattedCartItems, appLanguage, promoCode, loginToken).then(data => {
      setPromoAPIsubmitting(false);
      if (data.status === 249 || data.status === 150 || data.status === 148) {
        userRoleContext.handleLoginTokenExpiration();
      }
      else {
        if (data.result.I0) {
          let preAppliedDiscountLength = 0
          if (props.preAppliedDiscounts) {
            preAppliedDiscountLength = props.preAppliedDiscounts.length
          }

          let orderSummaryData = props.orderSummaryData
          let apiDiscounts = []
          if (data.result.I4) {
            data.result.I4.forEach(discount => {
              apiDiscounts.push({ "name": discount[0], "discount": Number(discount[2]) })
            })
          }
          // filter arrays and see if any new discounts are visible in api call
          let filteredArray = apiDiscounts.filter((item) =>
            orderSummaryData.discount.map((x) => x.name).includes(item.name)
          );

          if (data.result.I4 && filteredArray.length < apiDiscounts.length) {
            setPromoAPISubmitValid(true);
            let calculateDiscountTotal = 0
            data.result.I4.forEach((discount, index) => {
              if (index + 1 > preAppliedDiscountLength) {
                calculateDiscountTotal = calculateDiscountTotal + Number(discount[2])
              }
            })
            let totalBonus = 0
            data.result.I4.forEach((discount, index) => {
              if (index + 1 > preAppliedDiscountLength) {
                totalBonus = totalBonus + Number(discount[3])
              }
            })
            let newPromoIndex = []
            if (props.preAppliedDiscounts) {
              props.preAppliedDiscounts.forEach((preAppliedDiscount) => {
                data.result.I4.forEach((discount, index) => {
                  if (discount[0] !== preAppliedDiscount.name) {
                    newPromoIndex.push(index)
                  }
                })
              })
            }
            if (newPromoIndex.length === 0) {
              newPromoIndex.push("0")
            }

            setAppliedPromo({ code: promoCode, discount: calculateDiscountTotal });
            setAppliedPromoDetails({
              code: promoCode, discount: calculateDiscountTotal, promoDetails: {
                "promoName": data.result.I4[newPromoIndex[0]][0],
                "promoType": data.result.I4[newPromoIndex[0]][1],
                "totalDiscount": calculateDiscountTotal,
                "totalPromoBonus": totalBonus
              }
            })

          } else {
            setPromoAPISubmitValid(false);
            setPromoAPIError(appLabels["gift-card"]["invalid-promo-code"]);
          }
        } else {
          setPromoAPISubmitValid(false);
          setPromoAPIError(data.result.message);
        }
      }

    })
  }

  useEffect(() => {
    if (appliedPromoDetails && appliedPromoDetails.code) {
      applyCodesToOrder()
    }
  }, [appliedPromoDetails])

  const applyCodesToOrder = () => {
    let promoCode = "";

    if (appliedPromo) {
      promoCode = appliedPromo.code;
      props.setStoredPromoCode(promoCode);

      localforage.setItem(merchantConfig.skin + "__gcStoredPromoCode", promoCode);
      localforage.setItem(merchantConfig.skin + "__gcStoredPromoDetails", appliedPromoDetails);

    }

    props.updateOrderSummary(promoCode, "", "", appliedPromoDetails);
  };

  return (
    <>
      <div
        className={`cart-summary__container gcCartDiscountSection collapsable-section__expanded-content
        }`}
      >

        <div className="cart-summary__totals-column fullRowWidth gcCartDiscountForm">
          <Form
            id="form--gcPromo"
            submitForm={applyGCPromoCode}
            submitAPIError={promoAPIError}
            resetAPIError={() => setPromoAPIError("")}
            isAPISubmitValid={isPromoAPISubmitValid}
            isAPIsubmitting={isPromoAPIsubmitting}
            isDisableSubmit={isPromoAPIsubmitting || promoAPIError}
            isSubmitResettable={true}
            submitButtonText={appLabels["gift-card"]["promo-apply"]}
            submitButtonLoadingText={appLabels["general"]["checking"] + "..."}
            showLoadingText={false}
            submitButtonCustomClass={
              " button--primary button--primary-small button--primary-with-border button--pill-shaped gcDiscountButton"
            }

          >
            <FormInput
              type="text"
              label={appLabels["gift-card"]["promo-code"]}
              id="input--gcPromoCode"
              name="promo-code"
              isRequired={true}
              placeholder={"enter promo here"}
            />
          </Form>

        </div>
        {promoAPIError && promoAPIError.length > 0 &&
          <p className="gcDiscountError">{promoAPIError}</p>
        }

      </div>

    </>
  )
}
import React from "react";

const paragraph1 = () => {
  return (
    <p>
      Scan your card by tapping on "Scan in store" button on dashboard to pay with your card at the
      store or order ahead for pickup and collect reward points.
    </p>
  );
};

const paragraph2 = () => {
  return (
    <p>
      Earn <strong>1 reward points</strong> for every dollar spent each time you place an order with
      us.
    </p>
  );
};

const paragraph3 = () => {
  return (
    <p>
      Select "Reward Options" from dashboard and check out what rewards you are eligible for or
      redeem towards merchandise.
    </p>
  );
};

const paragraph4 = () => {
  return (
    <p>
      When ordering online, on "Order Review" page click on "Use Rewards" button to see available
      rewards for you. Redeem reward points for free drinks and food.
    </p>
  );
};

const paragraph5 = () => {
  return (
    <>
      <p>
        Remember to check the app on your birthday, we will have a surprise birthday gift* for you.
      </p>
      <p>
        <small>
          <em>* Terms and conditions may apply</em>
        </small>
      </p>
    </>
  );
};

const paragraph6 = () => {
  return (
    <p>Check your dashboard for updates on promotions and how to collect more reward points.</p>
  );
};

export const paragraphs = {
  "step-1": paragraph1(),
  "step-2": paragraph2(),
  "step-3": paragraph3(),
  "step-4": paragraph4(),
  "step-5": paragraph5(),
  "step-6": paragraph6(),
};

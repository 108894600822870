// Libraries
import React, { useState, useEffect, useContext } from "react";
import localforage from "localforage";
import { useHistory } from "react-router-dom";

// API helper functions

// UI components
import MerchantConfigContext from "../../App/MerchantConfigContext";
import { parseURLParams } from "../../_common/helpers";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { callAPI, callMarquiPaymentAPI } from "../../_common/Api";
import AppLanguageContext from "../../App/AppLanguageContext";

function GCPaymentMethodPaypalHandlePayment() {
  const history = useHistory();

  const merchantConfig = useContext(MerchantConfigContext);

  const skin = merchantConfig.skin;

  const [completedOrderId, setCompletedOrderId] = useState(null);
  const appLanguage = useContext(AppLanguageContext)

  useEffect(() => {
    localforage.getItem(skin + "__gcCompletedOrderId").then((storedOrderId) => {
      if (storedOrderId) setCompletedOrderId(storedOrderId);
    });
  }, []);

  useEffect(() => {
    if (!window.location.href.includes("PayerID")) {
      history.push("/gift-card/payment");
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("PayerID") && completedOrderId) {
      const urlParams = parseURLParams(window.location.href.replace("#/", ""));
      const payerId = urlParams.PayerID[0];
      const token = urlParams.token[0];

      if (!!urlParams.cancel) {
        cancelOrder(completedOrderId);
      } else {
        paypalConfirm(token, payerId);
      }
    }
  }, [completedOrderId]);

  const paypalConfirm = (token, payerId) => {
    callMarquiPaymentAPI(skin, "paypal_confirm", {
      params: [
        skin,
        "consumer",
        "en",
        token, //token returned from paypal
        payerId, //payer id returned from paypal
        completedOrderId, //customer_order_id
        "", //certrans_id
        "", //customer_id
        "", //loyalty_enroll_id
        "", //online_order_id
        "", //scene_cardnum
        "", //cc_email
      ],
      id: "paypal_confirm",
    }).then((confirmPaypalData) => {

      if (confirmPaypalData.status === 0) {
        setTimeout(() => {
          history.push({
            pathname: "/gift-card/order-confirmation",
          });
        }, 500);
      } else {

        cancelOrder(completedOrderId);
      }
    });
  };

  /* not sure if this function is valid for gift cards */
  const cancelOrder = (orderID) => {
    callAPI(skin, "vxl_957", {
      params: [
        appLanguage,
        "957",
        "mqid",
        "mqpass",
        orderID,
        "Do not want it"
      ],
      id: "957"
    }).then((data) => {
      console.log("957 CANCELED ORDER")
    })
  };

  return (
    <div style={{ height: "100vh" }}>
      <LoadingSpinner />
    </div>
  );
}

export default GCPaymentMethodPaypalHandlePayment;

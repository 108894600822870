// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import localforage from "localforage";

// Helper functions
import { jsonCopy } from "../../../_common/helpers";
import {
  getItemQuantityFromCart,
  hasMultipleInstancesOfItemInCart,
} from "../../../_common/CartHelpers";

// Contexts
import AppLabelsContext from "../../../App/AppLabelsContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

// Assets
import { ReactComponent as IconPlus } from "../../../_common/icons/IconPlus.svg";
import { ReactComponent as IconMinus } from "../../../_common/icons/IconMinus.svg";

// CSS
import "./QuantityButtons.css";

export const QuantityButtons = (props) => {
  const {
    menuItem,
    cartItem,
    isMenuDisplay,
    isModifyDisplay,
    updateMenuItem,
    cartData,
    explicitMaxValue,
    comboCartItem,
    currentSubgroupNumber,
    isEditingComboItem,
    isComboSubItem,
    isComboSummary,
    comboQuantity,
  } = props;

  const cartItems = cartData.value || [];
  const skin = useContext(MerchantConfigContext).skin;

  const onlineQuantityLimit =
    !!menuItem["online_qty_limit"] && menuItem["online_qty_limit"] !== "None"
      ? menuItem["online_qty_limit"]
      : null;

  const [itemQuantity, setItemQuantity] = useState(
    isComboSubItem &&
      isEditingComboItem &&
      comboCartItem.combo_child_items.filter(
        (item) => item.group_id === currentSubgroupNumber && item.id === menuItem.id
      ).length > 0
      ? comboCartItem.combo_child_items.filter(
          (item) => item.group_id === currentSubgroupNumber && item.id === menuItem.id
        )[0].quantity
      : isComboSummary && comboQuantity
      ? comboQuantity
      : cartItem && cartItem.quantity && !isComboSubItem
      ? cartItem.quantity
      : 1
  );
  const [itemMaxQuantity, setItemMaxQuantity] = useState(null);
  const [isAtMaxLimit, setIsAtMaxLimit] = useState(false);
  const appLabels = useContext(AppLabelsContext);
  const usedOriginalCartItemQuantity = useRef(false);
  useEffect(() => {
    if (isModifyDisplay) {
      usedOriginalCartItemQuantity.current = true;
      setItemQuantity(cartItem.quantity);
    } else if (
      isEditingComboItem &&
      comboCartItem &&
      comboCartItem.combo_child_items.length > 0 &&
      comboCartItem.combo_child_items[currentSubgroupNumber - 1]
    ) {
      setItemQuantity(
        comboCartItem.combo_child_items.filter(
          (item) => item.group_id === currentSubgroupNumber && item.id === menuItem.id
        )[0].quantity
      );
    }
    let ele = document.getElementsByClassName("item-detail__quantity-input")[0];
    if (ele){
      ele.blur();
    }
  }, []);

  /** This use effect takes care of,
   * when user is modifying an item from cart or customization popup and changes the quantity and decied to
   * make a modification before confirming the change*/
  useEffect(() => {
    if (!!comboQuantity && isModifyDisplay && isComboSummary) {
      setItemQuantity(comboQuantity);
    }
  }, [comboQuantity, isModifyDisplay, isComboSummary]);

  useEffect(() => {
    if (menuItem && itemMaxQuantity === null) {
      if (onlineQuantityLimit) {
        const currentItemQuantityFromCart = getItemQuantityFromCart(cartItems, menuItem.id);
        if (currentItemQuantityFromCart === 0) {
          setItemMaxQuantity(onlineQuantityLimit);
        } else {
          if (isMenuDisplay) {
            setItemMaxQuantity(Number(onlineQuantityLimit - currentItemQuantityFromCart));
          } else if (isModifyDisplay) {
            setItemMaxQuantity(Number(onlineQuantityLimit));
          }
        }
      }

      //set the max quantity value that can be selected based on the item_count
      if (!onlineQuantityLimit) {
        setItemMaxQuantity(
          menuItem.item_count === "None"
            ? ""
            : !!menuItem.item_count
            ? parseInt(menuItem.item_count)
            : ""
        );
      } else {
        if (menuItem.item_count !== "None") {
          setItemMaxQuantity(!!menuItem.item_count ? parseInt(menuItem.item_count) : "");
        }
      }

      if (menuItem.item_count === "0") {
        setItemQuantity(0);
      }

      if (explicitMaxValue) {
        setItemMaxQuantity(explicitMaxValue);
      }

      //Check if there is a stored item in localforage for fav selection, if so use the stored quantity
      const hasStoredItemInLocalForage = async () => {
        const hasStoredItem = await localforage.getItem(skin + "__itemSelectionInProgress");
        if (isMenuDisplay && !isEditingComboItem && !isComboSummary && !hasStoredItem) {
          updateItemQuantity(false);
        } else if (hasStoredItem) {
          setItemQuantity(JSON.parse(hasStoredItem).quantity);
        }
      };

      hasStoredItemInLocalForage();
    }
  }, [menuItem, explicitMaxValue, onlineQuantityLimit]);

  useEffect(() => {
    if (explicitMaxValue && explicitMaxValue !== itemMaxQuantity) {
      setItemMaxQuantity(explicitMaxValue);
    }
  }, [explicitMaxValue]);

  const updateItemQuantity = (isAdding) => {
    let currentQuantity = itemQuantity;
    if (isAdding) {
      currentQuantity++;
      if (itemMaxQuantity !== "" && currentQuantity >= itemMaxQuantity) {
        currentQuantity = itemMaxQuantity;
      }
    } else {
      currentQuantity--;
      if (currentQuantity <= 1) {
        currentQuantity = 1;
      }
    }
    setItemQuantity(currentQuantity);

    //update the menu item
    const menuItemCopy = jsonCopy(menuItem);
    menuItemCopy.quantity = currentQuantity;
    updateMenuItem(menuItemCopy);
  };

  const maxLimitReached = () => {
    let limitReched = false;

    localforage.getItem(skin + "__activeItemForModifying").then((item) => {
      const modifyingItem = JSON.parse(item);
      const instances = hasMultipleInstancesOfItemInCart(cartItems, modifyingItem);
      limitReched =
        getItemQuantityFromCart(cartItems, menuItem.id) -
          instances.nonmatchingElementsQuantities -
          itemQuantity <=
        0;

      if (itemQuantity + instances.nonmatchingElementsQuantities < onlineQuantityLimit) {
        limitReched = false;
      }
      setIsAtMaxLimit(limitReched);
    });
  };

  useEffect(() => {
    if (isModifyDisplay) {
      maxLimitReached();
    }
  }, [itemQuantity]);

  return (
    <div
      className={`item-detail__quantity-container ${
        !!props.customClass ? ` ${props.customClass}` : ""
      }`}
    >
      <h4 className=" order-item__option-header item-detail__options-subheading item-detail__quantity-header">
        {appLabels["order"]["quantity"]}
        {onlineQuantityLimit && (
          <span>
            {getItemQuantityFromCart(cartItems, menuItem.id) >= onlineQuantityLimit ? (
              <>{appLabels["order"]["limit-reached"]}</>
            ) : (
              <>
                {appLabels["order"]["limit-per-order"].replace("[qty-limit]", onlineQuantityLimit)}
              </>
            )}
          </span>
        )}
      </h4>
      <div className="item-detail__quantity-buttons-container">
        <button
          data-quantity="minus"
          data-field="quantity"
          type="button"
          className="item-detail__quantity-button item-detail__quantity-button-minus"
          onClick={() => {
            updateItemQuantity(false);
          }}
          disabled={
            (explicitMaxValue !== 0 || (explicitMaxValue === 0 && isEditingComboItem)) &&
            (menuItem.item_count === "0" || itemQuantity <= 1)
          }
          aria-label={appLabels["order"]["decrease-quantity"] + " " + appLabels["general"]["for-text"] + " " + menuItem.name}
        >
          <IconMinus aria-hidden={true} />
        </button>
        <input
          className="item-detail__quantity-input"
          readOnly
          type="number"
          name="quantity"
          value={itemQuantity}
          max={itemMaxQuantity}
          min="1"
          disabled
        />
        <button
          data-quantity="plus"
          data-field="quantity"
          type="button"
          className="item-detail__quantity-button item-detail__quantity-button-plus"
          onClick={() => {
            updateItemQuantity(true);
          }}
          disabled={
            (menuItem.item_count !== "None" &&
              (menuItem.item_count === "0" ||
                itemQuantity >= itemMaxQuantity ||
                cartItems.filter((item) => item.id === menuItem.id).length >=
                  menuItem.item_count)) ||
            (!!onlineQuantityLimit &&
              isMenuDisplay &&
              getItemQuantityFromCart(cartItems, menuItem.id) >= onlineQuantityLimit) ||
            (!!onlineQuantityLimit && isModifyDisplay && isAtMaxLimit) ||
            (itemMaxQuantity && itemQuantity >= itemMaxQuantity) ||
            explicitMaxValue === 0
          }
          aria-label={appLabels["order"]["increase-quantity"] + " " + appLabels["general"]["for-text"] + " " + menuItem.name}
        >
          <IconPlus aria-hidden={true} />
        </button>
      </div>
    </div>
  );
};

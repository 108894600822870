import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import localforage from "localforage";

/** UI Components */
import { ReactComponent as IconX } from "../icons/IconX.svg";
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";

const XButton = (props) => {
  const { destination, appLabels, skin } = props;
  const { state } = useLocation();
  const history = useHistory();
  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;

  const handleGoBack = () => {
    if (skin) {
      /** If use closes the combo page, remove their selection from localfoage */
      localforage.removeItem(skin + "__itemSelectionInProgress");
      localforage.removeItem(skin + "__storeIdToBeFaved");
      sessionStorage.removeItem(skin + "__orderStoreState");
    }
    if (destination) {
      if (state && state.from === "suggestedItems") {
        history.push({
          pathname: `/online-ordering/${
            activeOrderType === "dinein" ? "review-order" : "checkout"
          }`,
          state: { from: "item-page" },
        });
      } else {
        history.push({ pathname: destination, state: { from: "app-header-back-button" } });
      }
    } else {
      history.goBack();
    }
  };

  return (
    <button type="button" aria-label={appLabels["general"]["go-back"]} onClick={handleGoBack}>
      <IconX aria-hidden="true" />
    </button>
  );
};
export default XButton;

/**
 * Background: _blank is a placeholder asset that's usually uploaded to Marqui in either .png or .gif format.
 *
 * It is used when wxget doesn't have anything selected yet.
 * Without this check it'd be considered as a valid image
 * when deciding whether to render it or not.
 */
export const isValidImage = (imagePath) => {
  const imageUrlPattern = /\.(jpg|jpeg|png|gif|bmp|webp|tiff|svg)$/i;
  return (
    imagePath !== "" &&
    imagePath !== null &&
    imagePath !== undefined &&
    !imagePath.includes("/_blank.") &&
    imageUrlPattern.test(imagePath)
  );
};

import React, { useContext, useEffect, useState } from "react";
import useWindowSize from "../../_common/hooks/useWindowSize";

/** Contexts */
import UserRoleContext from "../../App/UserRoleContext";

/** UI Components */
import { callAPI } from "../../_common/Api";
import AppLanguageContext from "../../App/AppLanguageContext";
import MerchantConfigContext from '../../App/MerchantConfigContext';
import AppLabelsContext from "../../App/AppLabelsContext";
import { GCPastOrderCard } from "./GCPastOrderCard";
import { GCNotLoggedIn } from "./GCNotLoggedIn";
import { GCNoRecentOrders } from "./GCNoRecentOrders";
import { LoadingSpinner } from "../../_common/LoadingSpinner";

export const GCRecentOrders = (props) => {


  const deviceWidth = useWindowSize().width;
  const appLanguage = useContext(AppLanguageContext)
  const merchantConfigContext = useContext(MerchantConfigContext)
  const skin = merchantConfigContext.skin
  const appLabels = useContext(AppLabelsContext)
  const [recentOrders, setRecentOrders] = useState(null)
  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  const isLoggedIn = userRoleContext.status === "logged-in";
  // 1007 is used to get user's past gift card orders
  const callApi1007 = async () => {

    await callAPI(skin, "dc_1007", {
      params: [
        appLanguage,
        "1007",
        "mqid",
        "mqpass",
        loginToken,
        ""
      ],
      id: "1007",
    }).then((data) => {
      if (data.status === 249 || data.status === 150 || data.status === 148) {
        userRoleContext.handleLoginTokenExpiration();
      }
      else if (data.result.I2) {
        let recentOrdersList = []
        data.result.I2.forEach(item => {
          let recentOrder = {
            "id": item[0],
            "date": item[4],
            "time": item[5],
            "total": item[9]
          }
          recentOrdersList.push(recentOrder)
        })
        setRecentOrders(recentOrdersList)
      }

    });
  }
  useEffect(() => {
    if (!recentOrders && isLoggedIn) {
      callApi1007()
    }
    sessionStorage.removeItem(skin + "__lastVisitedLink");
  }, []);

  return (
    <>
      {isLoggedIn === false && (
        <GCNotLoggedIn skin={skin} />
      )}
      {isLoggedIn && recentOrders == null && loginToken && (
        <span className="recent-order__loading">
          <LoadingSpinner />
          <div className="recent-order__loading-text">
            {deviceWidth < 600 ? (
              <p>
                {appLabels["order"]["past-orders-loading"].slice(0, 11)}
                <br />
                {appLabels["order"]["past-orders-loading"].split("!").pop("!")}
              </p>
            ) : (
              <p>{appLabels["order"]["past-orders-loading"]}</p>
            )}
          </div>
        </span>
      )}
      {isLoggedIn && recentOrders !== null && recentOrders !== true && recentOrders.length < 1 && <GCNoRecentOrders />}

      {isLoggedIn && recentOrders &&
        recentOrders.length > 0 && (
          <section>
            <p className="recent-order__section-header">
              {appLabels["account"]["recent-orders-disclaimer"]}
            </p>
            {recentOrders.map((order) => (
              <React.Fragment key={order.id}>
                <GCPastOrderCard order={order} appLabels={appLabels} appLanguage={appLanguage} />
              </React.Fragment>
            ))}
          </section>
        )}
    </>
  );
};
import { callAPI } from "../../../../../_common/Api";

export const removeAPICreditCard = async (skin, loginToken, language) => {
  const api1019Data = await callAPI(skin, "dc_1019", {
    params: [language, "1019", "mqid", "mqpass", loginToken, "", "t"],
    id: "dc_1019",
  });

  if (api1019Data.result.I0) {
    return api1019Data.result.I1;
  } else {
    return { error: api1019Data.result.message };
  }
};

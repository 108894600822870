import localforage from "localforage";

export const clearTemporaryPasswordOnExpiry = async skin => {
  try {
    const timestamp = await localforage.getItem(skin + "__tempPasswordTimestamp");

    if (timestamp) {
      const now = new Date().getTime().toString();
      const diff = now - timestamp;
      const diffInMinutes = Math.floor(diff / (60 * 1000)); // convert to minutes
      /* const diffInSeconds = Math.floor(diff / 60); // convert to seconds
      console.log(diffInSeconds); */

      if (diffInMinutes >= 15) {
        // When temporary password expires clear storage and redirect to root
        localforage.clear();
        return true;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

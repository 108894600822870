import React, { useState, useEffect, useContext } from "react";
import useWindowSize from "../../../../_common/hooks/useWindowSize";

/** Contexts */
import AppLabelsContext from "../../../../App/AppLabelsContext";

/** UI Components */
import { NestedSingleChoiceAddonModifier } from "./NestedSingleChoiceAddonModifier";
import { NestedMultipleChoiceAddonModifier } from "./NestedMultipleChoiceAddonModifier";

/** Assets */
import { ReactComponent as IconCheck } from "../../../../_common/icons/IconCheck.svg";

/** Helpers */
import { findPos, objectToArray } from "../../../../_common/helpers";
import {
  groupHasDefaultSelection,
  groupHasNestedOptionsToBeSelected,
} from "../../helpers/orderingHelpers";

export const NestedAddonModifierGroup = (props) => {
  const {
    nestedOptionGroup,
    selectedOption,
    updateSelectedOption,
    topLevelOptionKey,
    selectTopLevelOption,
    isInCart,
    isBeingSelected,
    topLevelOption,
    showAddonGroupImages,
    setOpenNestedGroupParentId,
    setNestedGroupIsOpen,
    setGoToNextGroup,
    labelId,
    topLevelOptionGroup,
    scrolledToNestedGroup,
    isStepByStepOrderingEnabled,
    isStandaloneParent,
    mainAddonGroupParentId,
    isEditingComboItem,
    comboChildItem,
    cartItem,
    mainAddonGroupId,
    setInitialStateAsCollapsed,
    comboCartItem,
    isParentGroupAlreadySelected,
    onPressDone,
    modifierId,
  } = props;

  const deviceWidth = useWindowSize().width;
  const nestedOptions = nestedOptionGroup.items;
  const isSingleChoice =
    parseInt(nestedOptionGroup.maxQuantity) === 1 && parseInt(nestedOptionGroup.minQuantity) === 1;

  const isOptionalNestedGroup = parseInt(nestedOptionGroup.minQuantity) === 0;
  const appLabels = useContext(AppLabelsContext);

  const [instructionsText, setInstructionsText] = useState("");
  const [mandatoryOptionClass, setMandatoryOptionClass] = useState(
    " item-detail__option-note--mandatory"
  );

  useEffect(() => {
    if (isStepByStepOrderingEnabled) {
      setTimeout(() => {
        const containerElement = document.getElementById(`${nestedOptionGroup.parentId}`);
        if (
          document.getElementsByClassName("menu-item-detail-wizard").length > 0 &&
          containerElement &&
          scrolledToNestedGroup.current === false
        ) {
          document.getElementsByClassName("menu-item-detail-wizard")[0].scrollTo({
            top: findPos(containerElement) - (deviceWidth >= 660 ? 100 : 150),
            behavior: "smooth",
          });
          scrolledToNestedGroup.current = true;
        }
      }, 750);
    }
  }, []);
  // Instructions text based on option group's min/max quantity rules
  useEffect(() => {
    const minQuantity = parseInt(nestedOptionGroup.minQuantity);
    const maxQuantity = parseInt(nestedOptionGroup.maxQuantity);

    if (maxQuantity > 1 && !minQuantity) {
      setInstructionsText(
        `${appLabels["order"]["select-up-to-n-items"].split("[max-quantity]")[0]}${maxQuantity}${
          appLabels["order"]["select-up-to-n-items"].split("[max-quantity]")[1]
        }`
      );
      setMandatoryOptionClass("");
    } else if (minQuantity > 1 && !maxQuantity) {
      setInstructionsText(
        `${appLabels["order"]["select-n-items"].split("[min-quantity]")[0]}${minQuantity}${
          appLabels["order"]["select-n-items"].split("[min-quantity]")[1]
        }`
      );
    } else if ((minQuantity === 1 && maxQuantity === 0) || (minQuantity > 1 && maxQuantity === 0)) {
      setInstructionsText(
        `${appLabels["order"]["select-n-items-or-more"].replace("[n-items]", minQuantity)}`
      );
    } else if (minQuantity === 1 && maxQuantity === 1) {
      setInstructionsText(
        `${appLabels["order"]["select-n-item"].split("[min-quantity]")[0]}${minQuantity}${
          appLabels["order"]["select-n-item"].split("[min-quantity]")[1]
        }`
      );
    } else if (minQuantity >= 1 && maxQuantity > 1) {
      setInstructionsText(
        `${
          appLabels["order"]["select-between-n-and-n-items"].split("[min-quantity]")[0]
        }${minQuantity}${
          appLabels["order"]["select-between-n-and-n-items"]
            .split("[min-quantity]")[1]
            .split("[max-quantity]")[0]
        }${maxQuantity}${
          appLabels["order"]["select-between-n-and-n-items"]
            .split("[min-quantity]")[1]
            .split("[max-quantity]")[1]
        }`
      );
    } else {
      setMandatoryOptionClass("");
    }
  }, []);

  const [anyOptionSelected, setAnyOptionSelected] = useState(false)

  /** change the instruction text to the selected addon/modifier item name */
  useEffect(() => {
    let tempInstructionText = [];
    if (selectedOption && Object.keys(selectedOption).length > 0) {
      if (selectedOption.modifierGroups) {
        if (selectedOption.modifierGroups["addonModifierGroup-" + nestedOptionGroup.id]) {
          const nestedItems =
            selectedOption.modifierGroups["addonModifierGroup-" + nestedOptionGroup.id].items;
          Object.keys(nestedItems).forEach((nestedItemKey) => {
            if (nestedItems[nestedItemKey].isSelected) {
              tempInstructionText.push(nestedItems[nestedItemKey].name);
              setAnyOptionSelected(true)
            }
          });
        }
      } else {
        Object.keys(selectedOption).forEach((optionGroupKey) => {
          if (
            selectedOption[optionGroupKey].modifierGroups &&
            !!Object.keys(selectedOption[optionGroupKey].modifierGroups).length
          ) {
            if (
              selectedOption[optionGroupKey].modifierGroups[
                "addonModifierGroup-" + nestedOptionGroup.id
              ]
            ) {
              const nestedItems =
                selectedOption[optionGroupKey].modifierGroups[
                  "addonModifierGroup-" + nestedOptionGroup.id
                ].items;
              Object.keys(nestedItems).forEach((nestedItemKey) => {
                if (nestedItems[nestedItemKey].isSelected) {
                  setAnyOptionSelected(true)
                  tempInstructionText.push(nestedItems[nestedItemKey].name);
                }
              });
            }
          }
        });
      }
    } else if (nestedOptionGroup) {
      const nestedItems = nestedOptionGroup.items;
      Object.keys(nestedItems).forEach((nestedItemKey) => {
        if (nestedItems[nestedItemKey].isSelected) {
          tempInstructionText.push(nestedItems[nestedItemKey].name);
        }
      });
    }

    if (tempInstructionText.length > 0) {
      setInstructionsText(tempInstructionText.join(","));
      setMandatoryOptionClass("");
    } else {
      const minQuantity = parseInt(nestedOptionGroup.minQuantity);
      const maxQuantity = parseInt(nestedOptionGroup.maxQuantity);

      if (maxQuantity > 1 && !minQuantity) {
        setInstructionsText(
          `${appLabels["order"]["select-up-to-n-items"].split("[max-quantity]")[0]}${maxQuantity}${
            appLabels["order"]["select-up-to-n-items"].split("[max-quantity]")[1]
          }`
        );
        setMandatoryOptionClass("");
      } else if (minQuantity > 1 && !maxQuantity) {
        setInstructionsText(
          `${appLabels["order"]["select-n-items"].split("[min-quantity]")[0]}${minQuantity}${
            appLabels["order"]["select-n-items"].split("[min-quantity]")[1]
          }`
        );
        setMandatoryOptionClass(" item-detail__option-note--mandatory");
      } else if (
        (minQuantity === 1 && maxQuantity === 0) ||
        (minQuantity > 1 && maxQuantity === 0)
      ) {
        setInstructionsText(
          `${appLabels["order"]["select-n-items-or-more"].replace("[n-items]", minQuantity)}`
        );
        setMandatoryOptionClass(" item-detail__option-note--mandatory");
      } else if (minQuantity === 1 && maxQuantity === 1) {
        setInstructionsText(
          `${appLabels["order"]["select-n-item"].split("[min-quantity]")[0]}${minQuantity}${
            appLabels["order"]["select-n-item"].split("[min-quantity]")[1]
          }`
        );
        setMandatoryOptionClass(" item-detail__option-note--mandatory");
      } else if (minQuantity >= 1 && maxQuantity > 1) {
        setInstructionsText(
          `${
            appLabels["order"]["select-between-n-and-n-items"].split("[min-quantity]")[0]
          }${minQuantity}${
            appLabels["order"]["select-between-n-and-n-items"]
              .split("[min-quantity]")[1]
              .split("[max-quantity]")[0]
          }${maxQuantity}${
            appLabels["order"]["select-between-n-and-n-items"]
              .split("[min-quantity]")[1]
              .split("[max-quantity]")[1]
          }`
        );
        setMandatoryOptionClass(" item-detail__option-note--mandatory");
      } else if (minQuantity === 0) {
        setInstructionsText("(" + appLabels["general"]["optional"] + ")");
        setMandatoryOptionClass("");
      } else {
        setMandatoryOptionClass("");
        setInstructionsText("");
      }
    }
  }, [nestedOptionGroup, selectedOption]);

  const [areNestedOptionsExpanded, setAreNestedOptionsExpanded] = useState(true);

  useEffect(() => {
    /** If user is modifying an item from the cart, or is editing a combo sub item from combo summary,
     * set the initial display of the nested addon groups to collapsed
     * */
    if (
      (isInCart || isEditingComboItem) &&
      isParentGroupAlreadySelected &&
      setInitialStateAsCollapsed.current.indexOf(nestedOptionGroup.id) === -1
    ) {
      let groupPreviouslySelected = false;

      if (isInCart) {
        if (cartItem.combo_child_items) {
          /** when trying to detect if the option group was previously selected or not, look in comboCartItem */
          if (
            comboCartItem.combo_child_items.find(
              (childItem) =>
                childItem.group_id === comboChildItem.group_id && childItem.id === comboChildItem.id
            )
          ) {
            groupPreviouslySelected = comboCartItem.combo_child_items.find(
              (childItem) =>
                childItem.group_id === comboChildItem.group_id && childItem.id === comboChildItem.id
            ).addonGroups[`addonGroup-${mainAddonGroupParentId}`].items[
              `addon-${nestedOptionGroup.parentId}`
            ].isSelected;
          }
        } else {
          if (
            cartItem.addonGroups[`addonGroup-${mainAddonGroupId}`] &&
            cartItem.addonGroups[`addonGroup-${mainAddonGroupId}`].items[
              `addon-${nestedOptionGroup.parentId}`
            ]
          ) {
            groupPreviouslySelected =
              cartItem.addonGroups[`addonGroup-${mainAddonGroupId}`].items[
                `addon-${nestedOptionGroup.parentId}`
              ].isSelected;
          } else if (
            cartItem.addonGroups[`addonGroup-${mainAddonGroupParentId}`] &&
            cartItem.addonGroups[`addonGroup-${mainAddonGroupParentId}`].items[
              `addon-${nestedOptionGroup.parentId}`
            ]
          ) {
            groupPreviouslySelected =
              cartItem.addonGroups[`addonGroup-${mainAddonGroupParentId}`].items[
                `addon-${nestedOptionGroup.parentId}`
              ].isSelected;
          }
        }
      } else {
        if (
          comboChildItem.addonGroups[`addonGroup-${mainAddonGroupId}`] &&
          comboChildItem.addonGroups[`addonGroup-${mainAddonGroupId}`].items[
            `addon-${nestedOptionGroup.parentId}`
          ]
        ) {
          groupPreviouslySelected =
            comboChildItem.addonGroups[`addonGroup-${mainAddonGroupId}`].items[
              `addon-${nestedOptionGroup.parentId}`
            ].isSelected;
        } else if (
          comboChildItem.addonGroups[`addonGroup-${mainAddonGroupParentId}`] &&
          comboChildItem.addonGroups[`addonGroup-${mainAddonGroupParentId}`].items[
            `addon-${nestedOptionGroup.parentId}`
          ]
        ) {
          groupPreviouslySelected =
            comboChildItem.addonGroups[`addonGroup-${mainAddonGroupParentId}`].items[
              `addon-${nestedOptionGroup.parentId}`
            ].isSelected;
        }
      }

      if (
        groupPreviouslySelected &&
        document.getElementById(
          `${nestedOptionGroup.name}-${nestedOptionGroup.id}-${nestedOptionGroup.parentId}-${mainAddonGroupParentId}-done-button`
        )
      ) {
        document
          .getElementById(
            `${nestedOptionGroup.name}-${nestedOptionGroup.id}-${nestedOptionGroup.parentId}-${mainAddonGroupParentId}-done-button`
          )
          .click();
      }
      setInitialStateAsCollapsed.current.push(nestedOptionGroup.id);
    }
  }, []);

  const [expandCollapseAnimationClass, setExpandCollapseAnimationClass] = useState("");

  const allNestedGroupsAreCollapsed = () => {
    let allNestedGroupsAreCollapsed = true;
    Object.keys(topLevelOption.modifierGroups).forEach((key) => {
      if (
        document.getElementById(
          `${topLevelOption.modifierGroups[key].id}_${topLevelOption.modifierGroups[key].parentId}_${mainAddonGroupParentId}`
        ).style.display !== "none"
      ) {
        allNestedGroupsAreCollapsed = false;
      }
    });
    return allNestedGroupsAreCollapsed;
  };

  useEffect(() => {
    if (areNestedOptionsExpanded) {
      setOpenNestedGroupParentId(nestedOptionGroup.parentId);
    } else {
      /** check if all the nested modifier groups are closed */
      if (allNestedGroupsAreCollapsed()) {
        setOpenNestedGroupParentId(null);
      }
    }
  }, [areNestedOptionsExpanded]);

  useEffect(() => {
    return () => {
      setOpenNestedGroupParentId(null);
    };
  }, []);

  const setupClosedNestedGroupButtons = (target, closedNestedGroupFlag) => {
    if (target && closedNestedGroupFlag) {
      if (
        !(
          target.parentElement &&
          target.parentElement.parentElement &&
          target.parentElement.parentElement.parentElement
        )
      ) {
        return;
      }

      const parentContainer = target.parentElement.parentElement.parentElement;

      const parentId = parentContainer.dataset.parentid;
      const nestedGroupId = parentContainer.id;

      const buttonContainerId = nestedGroupId + "-" + parentId;
      if (document.getElementById(buttonContainerId)) {
        document.getElementById(buttonContainerId).remove();
      }

      const addonContainer = document.getElementById(parentId);

      let buttonContainer = document.createElement("div");
      buttonContainer.className = ` closed-nested-group wizard ${
        showAddonGroupImages ? "" : "no-image-nested-group"
      }`;
      buttonContainer.id = buttonContainerId;

      let span = document.createElement("span");
      span.innerText = nestedOptionGroup.name.substr(0, 10) + "...";

      let editButton = document.createElement("button");
      editButton.type = "button";
      editButton.innerText = appLabels["order"]["modify"];
      editButton.ariaLabel = appLabels["order"]["modify"] + " " + nestedOptionGroup.name;

      buttonContainer.appendChild(span);
      buttonContainer.appendChild(editButton);

      addonContainer.appendChild(buttonContainer);

      editButton.onclick = function () {
        buttonContainer.remove();
        if (addonContainer.querySelectorAll(".closed-nested-group.wizard").length === 0) {
          addonContainer.querySelector(
            ".form__field-wrapper--checkbox-radio.form__field-wrapper--radio.is-checked"
          ).style.borderBottomLeftRadius = "15px";
          addonContainer.querySelector(
            ".form__field-wrapper--checkbox-radio.form__field-wrapper--radio.is-checked"
          ).style.borderBottomRightRadius = "15px";
        }

        setAreNestedOptionsExpanded(true);
      };

      /** border styling */
      if (
        addonContainer.querySelector(
          ".form__field-wrapper--checkbox-radio.form__field-wrapper--radio.is-checked"
        )
      ) {
        addonContainer.querySelector(
          ".form__field-wrapper--checkbox-radio.form__field-wrapper--radio.is-checked"
        ).style.borderBottomLeftRadius = 0;
        addonContainer.querySelector(
          ".form__field-wrapper--checkbox-radio.form__field-wrapper--radio.is-checked"
        ).style.borderBottomRightRadius = 0;
      }
    }
  };

  const toggleNestedOptions = (e) => {
    let closedNestedGroupFlag;

    let target = null;
    if (e && e.target) {
      target = e.target;
    }
    if (areNestedOptionsExpanded) {
      setExpandCollapseAnimationClass(" form__field-wrappers--collapsing");
      setTimeout(() => {
        closedNestedGroupFlag = true;
        setupClosedNestedGroupButtons(target, closedNestedGroupFlag);
        setAreNestedOptionsExpanded(false);
        setExpandCollapseAnimationClass("");
      }, 500);

      setTimeout(() => {
        if (
          selectedOption &&
          !groupHasDefaultSelection(selectedOption) &&
          !groupHasNestedOptionsToBeSelected(selectedOption) &&
          allNestedGroupsAreCollapsed() &&
          topLevelOptionGroup &&
          topLevelOptionGroup.maxQuantity !== "0" &&
          ((topLevelOptionGroup.maxQuantity !== "1" &&
            (Object.keys(selectedOption).length === parseInt(topLevelOptionGroup.maxQuantity) ||
              (Object.keys(selectedOption).length > 0 && topLevelOptionGroup.isStandalone))) ||
            (topLevelOptionGroup.maxQuantity === "1" && Object.keys(selectedOption).length > 0))
        ) {
          setGoToNextGroup(true);
        }
      }, 500);
    } else {
      closedNestedGroupFlag = false;
      setupClosedNestedGroupButtons(target, closedNestedGroupFlag);
      setExpandCollapseAnimationClass(" form__field-wrappers--expanding");
      setAreNestedOptionsExpanded(true);
    }
  };

  const [noNestedSelectionMade, setNoNestedSelectionMade] = useState(
    isOptionalNestedGroup
      ? false
      : objectToArray(nestedOptionGroup.items).filter((item) => item.isSelected).length === 0
      ? true
      : false
  );

  useEffect(() => {
    setNestedGroupIsOpen(!allNestedGroupsAreCollapsed());
  }, [areNestedOptionsExpanded]);

  useEffect(() => {
    return () => {
      setNestedGroupIsOpen(false);
    };
  }, []);

  const [progressiveIconClass, setProgressiveIconClass] = useState("");
  useEffect(() => {
    document.getElementById(labelId).classList.remove("full-progression");
    document.getElementById(labelId).classList.remove("halfWay-progression");
    if (progressiveIconClass !== "") {
      document.getElementById(labelId).classList.add(progressiveIconClass);
    }
  }, [progressiveIconClass]);

  return (
    <div
      className={`${
        deviceWidth < 660
          ? !showAddonGroupImages
            ? "full__width mobile-container"
            : "full__width "
          : "desktop-nested-addons-fieldset-container"
      } ${expandCollapseAnimationClass}`}
      style={!areNestedOptionsExpanded ? { display: "none" } : {}}
    >
      <span tabIndex={-1} className="visually-hidden" id={modifierId} role="contentinfo">
        {nestedOptionGroup.name}. {instructionsText}.
      </span>
      <div
        className={`nested-addons-fieldset-wrapper wizard ${
          !showAddonGroupImages ? "smaller" : ""
        }`}
        id={`${nestedOptionGroup.id}_${nestedOptionGroup.parentId}_${mainAddonGroupParentId}`}
        data-parentid={
          isStandaloneParent
            ? `${nestedOptionGroup.parentId}-standalone`
            : `${nestedOptionGroup.parentId}_${mainAddonGroupParentId}`
        }
        style={!areNestedOptionsExpanded ? { display: "none" } : {}}
      >
        <fieldset className="form__fieldset form__fieldset--nested">
          <legend className="form__fieldset-legend form__fieldset-legend--nested">
            {deviceWidth >= 660 ? (
              <span className="form__fieldset-legend-text--nested">{nestedOptionGroup.name}</span>
            ) : (
              <span
                className={`form__fieldset-legend-text--nested  item-detail__option-note${mandatoryOptionClass}`}
              >
                {nestedOptionGroup.name}
                {!mandatoryOptionClass && <IconCheck />}
              </span>
            )}

            <span className={`item-detail__option-note${mandatoryOptionClass}`}>
              {instructionsText}
            </span>
          </legend>

          {areNestedOptionsExpanded && (
            <div
              className={`form__field-wrappers`}
              aria-label={
                !anyOptionSelected ?
                nestedOptionGroup.name + ". " + appLabels["general"]["for-text"] + props.topLevelOption.name + ". " + instructionsText + "." :  nestedOptionGroup.name + ". " + appLabels["general"]["for-text"] + props.topLevelOption.name + ". " + appLabels["order"]["currently-selected-date"]  + " " + instructionsText + "."}
              role="heading"
            >
              {isSingleChoice ? (
                <NestedSingleChoiceAddonModifier
                  nestedOptionGroup={nestedOptionGroup}
                  nestedOptions={nestedOptions}
                  topLevelOptionKey={topLevelOptionKey}
                  selectTopLevelOption={selectTopLevelOption}
                  selectedOption={selectedOption}
                  updateSelectedOption={updateSelectedOption}
                  isInCart={isInCart}
                  isBeingSelected={isBeingSelected}
                  topLevelOption={topLevelOption}
                  mandatoryOptionClass={mandatoryOptionClass}
                  instructionsText={instructionsText}
                  setNoNestedSelectionMade={setNoNestedSelectionMade}
                  setProgressiveIconClass={setProgressiveIconClass}
                  labelId={labelId}
                />
              ) : (
                <NestedMultipleChoiceAddonModifier
                  nestedOptionGroup={nestedOptionGroup}
                  nestedOptions={nestedOptions}
                  topLevelOptionKey={topLevelOptionKey}
                  selectTopLevelOption={selectTopLevelOption}
                  maxQuantity={nestedOptionGroup.maxQuantity}
                  minQuantity={nestedOptionGroup.minQuantity}
                  selectedOptions={selectedOption}
                  updateSelectedOption={updateSelectedOption}
                  topLevelOption={topLevelOption}
                  mandatoryOptionClass={mandatoryOptionClass}
                  instructionsText={instructionsText}
                  setNoNestedSelectionMade={setNoNestedSelectionMade}
                  setProgressiveIconClass={setProgressiveIconClass}
                  labelId={labelId}
                  modifierId={modifierId}
                />
              )}
              <button
                type="button"
                className="nested-group__done_button"
                onClick={(e) => {
                  onPressDone(e, topLevelOptionKey, modifierId);
                  toggleNestedOptions(e);
                }}
                aria-label={appLabels["general"]["done"]}
                disabled={
                  (!isOptionalNestedGroup &&
                    objectToArray(nestedOptionGroup.items).filter((item) => item.isSelected)
                      .length < Number(nestedOptionGroup.minQuantity)) ||
                  noNestedSelectionMade
                }
                id={`${nestedOptionGroup.name}-${nestedOptionGroup.id}-${nestedOptionGroup.parentId}-${mainAddonGroupParentId}-done-button`}
              >
                {appLabels["general"]["done"]}
              </button>
            </div>
          )}
        </fieldset>
      </div>
    </div>
  );
};

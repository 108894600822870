import he from "he";
import { callPaymentAPI } from "../../../../../_common/Api";

export const placeUnifiedAPIOrder = async (
  skin,
  activeStore,
  orderType,
  customerInfo,
  rewards,
  promoCode,
  giftCardNumberList,
  orderTypeInstruction = "",
  tipAmount,
  language,
  generalLabels,
  hostTransactionId
) => {
  let order_address = activeStore.address;
  let order_postal = activeStore.postal;
  let order_city = activeStore.city;

  const placeOrderData = await callPaymentAPI(skin, "dc_vxl_pay_bill", {
    params: [
      skin,
      "consumer",
      language,
      activeStore.storeId, //outlet id
      hostTransactionId, //Host transaction ID
      "", //workstation ID (optional)
      {
        customer_id: customerInfo.id || "",
        order_email: customerInfo.email || "",
        order_fname: customerInfo.firstName || "",
        order_lname: customerInfo.lastName || "",
        order_phone: customerInfo.phone || "",
        order_mobile: customerInfo.mobile || "",
        order_address,
        order_postal,
        order_city,
        order_prov: activeStore.province,
        order_country: activeStore.country,
        discount_for_the_whole_order: promoCode,
        redeem_rewards: rewards,
      }, // CustomerInfo
      giftCardNumberList, // gc_number_list
      "", // save_credit_card
      "", // tm_session_id
      "", // scene_cardnum
      "CREDITCARD", // payment_type
      "", // cc_id
      "", // cc_type
      "", // cc_number
      "", // cc_name
      "", // cc_security
      "", // cc_issue
      "", // cc_expiry_month
      "", // cc_expiry_year
      "", // cc_start_month
      "", // cc_start_year
      "", // cc_address1
      "", // cc_address2
      "", // cc_city
      "", // cc_province
      "", // cc_country
      "", // cc_postal
      customerInfo.email, // cc_email
      customerInfo.phone, // cc_phone
      "", // pa_res
      "", // return_url
      "", // cancel_url
      "", // success_url
      "", // error_url
      "", // fail_url
      "", // unique_pay_id
      "", // wechat_oauth_id
      "", // url_3ds_success
      "", // url_3ds_failure
      orderType === "pickup" || orderType === "dinein" ? orderTypeInstruction : "None", //Special Instruction (pickup / dinein)
      tipAmount, //tip
      customerInfo.email || "", //Receipt Email
    ],
    id: "dc_vxl_pay_bill",
  });

  if (placeOrderData.result.I0) {
    /* successfully placed an order */
    return {
      isPlaced: true,
      orderId: placeOrderData.result.I0,
      pointsIssued: placeOrderData.result.I10,
    };
  } else {
    /* failed to place an order */
    return {
      isPlaced: false,
      error: `${generalLabels["error-code"]}: ${placeOrderData.error.code}. ${he.decode(
        placeOrderData.result.message
      )}`,
    };
  }
};

import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Contexts
import AppLabelsContext from "../../App/AppLabelsContext";
import CWS5ModuleContext from "../../App/CWS5ModuleContext";

// UI Components
import { LoginRegisterGuestLink } from "../LoginRegisterGuestLink";

// CSS
import "./LoginRegisterLinks.css";

export const LoginRegisterLinks = ({ proceedToDashboard }) => {
  const appLabels = useContext(AppLabelsContext);

  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const isRegistrationEnabled = cws5ModuleContext.isRegistrationEnabled;
  const isGuestEnabled = cws5ModuleContext.isGuestEnabled;

  return (
    <div className="login-or-register-buttons">
      {isRegistrationEnabled && (
        <div className="login-or-register__register">
          <Link to="/register">
            {appLabels["login-register"]["dont-have-account"]}?
            <span>{appLabels["login-register"]["register"]}</span>
          </Link>
        </div>
      )}

      {isGuestEnabled && <LoginRegisterGuestLink proceedToDashboard={proceedToDashboard} />}
    </div>
  );
};

import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

/** Hooks */
import useWindowSize from "../../_common/hooks/useWindowSize";

/** Contexts */
import BrandingContext from "../../App/BrandingContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import OrderTypeContext from "../OrderTypeContext";
import AppSettingsContext from "../../App/AppSettingsContext";

/** Helper Functions */
import { calculateItemIndex, getTotalItemPriceBasedOn1307 } from "../../_common/CartHelpers";

/** UI Components */
import CartItem from "./CartItem";
import { AdditionalDisclaimer } from "../../_common/AdditionalDisclaimer";
import CollapsableSection from "../../_common/components/CollapsableSection";

/** CSS */
import "./CartItemsCollapsable.css";

const CartItemsCollapsableSection = (props) => {
  const {
    setIsCartFAB,
    cartData,
    itemsArray,
    orderSummaryData,
    containerModifier,
    isCalling1307,
    items,
    storedRewards,
    removeItem,
    getComboChildImages,
    updateItemQuantity,
    getItemAddonDiscounts,
    skin,
    setStoredRewards,
    setOrderSummaryData,
    storedRewardsDetails,
    setStoredRewardsDetails,
    error1307,
    setIsRemovingAllItems,
    initiallyIsCollapsed,
    isOnCheckoutPage,
    subHeaderContent,
    customClass,
    setShowCheckoutButton,
  } = props;

  const brandingContext = useContext(BrandingContext);
  const appLabels = useContext(AppLabelsContext);
  const deviceWidth = useWindowSize().width;
  const history = useHistory();
  const activeOrderType = useContext(OrderTypeContext).value;
  const appSettings = useContext(AppSettingsContext);

  const isAddonQuantityEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";

  const addMoreItems = (
    <button
      className="link-wrapper--add-items "
      onClick={() => {
        history.push("/online-ordering/menu", { from: "cart" });
      }}
      type="button"
    >
      {appLabels["order"]["add-more-items"]}
    </button>
  );

  const [isCollapsed, setIsCollapsed] = useState(initiallyIsCollapsed);

  return (
    <>
      <CollapsableSection
        headerText={appLabels["order"]["review-items"]}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        id={"cartItemsCollapsable"}
        subHeaderContent={subHeaderContent}
      />

      <div
        className={`cart-items-desktop ${
          itemsArray.length === 0 ? " cart-items-desktop-empty" : ""
        }   ${
          isCollapsed
            ? " collapsable-section__collapsed-content"
            : " collapsable-section__expanded-content"
        } ${customClass ? customClass : ""}`}
      >
        {items != null &&
          !error1307 &&
          !isCalling1307 &&
          (activeOrderType !== "dinein" ||
            (activeOrderType === "dinein" &&
              window.location.href.includes("review-order") &&
              deviceWidth < 660)) && (
            <div className={` cart-items-collapsable__content-buttons-container `}>
              {addMoreItems}
              {itemsArray.length > 0 && (
                <button
                  onClick={() => setIsRemovingAllItems(true)}
                  type="button"
                  className="link-wrapper--add-items"
                >
                  {appLabels["order"]["remove-all-items"]}
                </button>
              )}
            </div>
          )}
        <ul
          className={`cartItem__container${containerModifier}`}
          style={
            window.location.href.includes("checkout") && (deviceWidth < 660 || deviceWidth > 1280)
              ? { paddingBottom: 0 }
              : window.location.href.includes("review-order") && !isCollapsed
              ? { paddingBottom: "15em" }
              : {}
          }
        >
          {!!itemsArray.length &&
            orderSummaryData &&
            orderSummaryData !== "reset" &&
            !isCalling1307 &&
            items.map((item, index) => (
              <CartItem
                item={item}
                itemPrice={getTotalItemPriceBasedOn1307(
                  orderSummaryData.items[calculateItemIndex(index, items)]
                )}
                cart={cartData.value}
                handleRemove={removeItem}
                updateItemQuantity={updateItemQuantity}
                updateCart={cartData.updateCart}
                key={`${item.entryKey}-${index}`}
                setIsCartFAB={setIsCartFAB}
                itemDiscounts={orderSummaryData.items[calculateItemIndex(index, items)].discounts}
                itemAddonDiscount={getItemAddonDiscounts(
                  orderSummaryData.items[calculateItemIndex(index, items)]
                )}
                skin={skin}
                storedRewards={storedRewards}
                setStoredRewards={setStoredRewards}
                setOrderSummaryData={setOrderSummaryData}
                orderSummaryData={orderSummaryData}
                branding={brandingContext}
                itemIndex={calculateItemIndex(index, items)}
                storedRewardsDetails={storedRewardsDetails}
                setStoredRewardsDetails={setStoredRewardsDetails}
                comboChildImage={getComboChildImages(
                  orderSummaryData.items[calculateItemIndex(index, items)]
                )}
                isOnCheckoutPage={isOnCheckoutPage}
                setShowCheckoutButton={setShowCheckoutButton}
                isAddonQuantityEnabled={isAddonQuantityEnabled}
              />
            ))}
          {items != null && itemsArray.length === 0 && !error1307 && (
            <div className="empty-cart-message">{appLabels["order"]["empty-cart-message"]}</div>
          )}
        </ul>

        {deviceWidth < 850 && !isOnCheckoutPage && (
          <AdditionalDisclaimer
            disclaimer={appLabels["order"]["review-order-disclaimer"]}
            styleObject={{ paddingLeft: "30px", paddingRight: "30px" }}
          />
        )}
      </div>
    </>
  );
};
export const CartItemsCollapsable = React.memo(CartItemsCollapsableSection);

import { callAPI } from "../_common/Api";

export const addAPIGiftCard = async (
  skin,
  loginToken,
  cardNumber,
  cardDescription,
  reCAPTCHA,
  language,
  formLabels
) => {
  try {
    const api951Data = await callAPI(skin, "dc_951", {
      params: [
        language,
        "951",
        "mqid",
        "mqpass",
        loginToken,
        "", // customerPassword
        cardNumber,
        "", // securityCode (optional)
        cardDescription || "",
        "", // new securityCode (optional)
      ],
      id: "951",
      "g-recaptcha-response": reCAPTCHA,
    });

    if (api951Data.result.I1 === "0") {
      return { isAdded: true };
    } else if (api951Data.status === 249 || api951Data.status === 150) {
      return { status: "expiredLoginToken" };
    } else if (api951Data.status === 2) {
      return { error: formLabels["add-new-gift-card-invalid-card-error"] };
    } else {
      return { error: api951Data.result.message };
    }
  } catch (error) {
    console.error(error);
  }
};

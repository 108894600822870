//Libraries
import React, { useContext, useState, useEffect } from "react";
import { Route } from "react-router-dom";

//Contexts
import AppLabelsContext from "../App/AppLabelsContext";
import UserRoleContext from "../App/UserRoleContext";
import AppSettingsContext from "../App/AppSettingsContext";

//UI Components
import { PageComponentTemplate } from "../_common/components/PageComponentTemplate";
import { Register } from "./Register/Register";
import { Login } from "./Login/Login";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import { LoginWithTemporaryPassword } from "./Login/LoginWithTemporaryPassword";
import { ChangeTemporaryPassword } from "./ChangeTemporaryPassword/ChangeTemporaryPassword";
import { LoginRegister } from "./LoginRegister";
import defaultBackgroundImage from "./assets/loginRegisterBackgroundImage.png";

export const LoginRegisterRoutes = () => {
  const appLabels = useContext(AppLabelsContext);
  const userRoleContext = useContext(UserRoleContext);
  const relativePath = useContext(AppSettingsContext)["relative-path"];

  const [backgroundImage, setBackgroundImage] = useState("");

  const isLocalhost = process.env.NODE_ENV === "development";
  const isGivexHost = !isLocalhost;
  const PATHNAME = window.location.pathname;
  const IS_DEFAULT_LANGUAGE = isGivexHost
    ? PATHNAME.split("/")[3] === ""
    : PATHNAME.split("/")[1] === "";

  const getLoginRegisterImages = async (relativePath) => {
    try {
      const pathnameSplit = window.location.pathname.split("/");
      const languageFromPathname = isGivexHost ? pathnameSplit[3] : pathnameSplit[1];
      const url = `${relativePath}${
        languageFromPathname !== "" ? languageFromPathname + "/" : ""
      }login-register/images.json`;

      const headers = new Headers();
      headers.append("Cache-Control", "no-cache"); // force the browser to check the server to see if the file is different from the file it already has in the cache

      const init = {
        headers: headers,
      };

      const response = await fetch(url, init);
      const loginRegisterImagesData = await response.json();

      return loginRegisterImagesData;
    } catch (error) {
      console.error(error);
    }
  };

  const setDefaultBackground = () => {
    setBackgroundImage(defaultBackgroundImage);
  };
  useEffect(() => {
    getLoginRegisterImages(relativePath).then((imageData) => {
      if (imageData) {
        if (isLocalhost) {
          setDefaultBackground();
        } else {
          if (IS_DEFAULT_LANGUAGE) {
            setBackgroundImage(imageData["login-register"]["background-image"]);
          } else {
            setBackgroundImage("../" + imageData["login-register"]["background-image"]);
          }
        }
      }
    });
  }, []);

  return (
    <>
      <Route
        path="/register"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["login-register"]["register"]}
            isBackButton={true}
            //backButtonDestination="/login-register"
            component={<Register />}
            isFooter={false}
          />
        )}
      />
      <Route
        path="/login-register"
        render={() => (
          <PageComponentTemplate
            component={<LoginRegister />}
            isFooter={false}
            hasHeaderLogo={false}
            isLanguageButton={true}
            mainClassOverride="login-register__main-container"
            backgroundURL={backgroundImage}
            pageHeading={appLabels["login-register"]["sign-in"]}
          />
        )}
      />

      <Route
        path="/login"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["login-register"]["sign-in"]}
            isBackButton={true}
            backButtonDestination="/"
            isHomeLink={userRoleContext.status === "guest"}
            mainClassOverride="user-is-greeted"
            component={<Login />}
            isFooter={false}
          />
        )}
      />
      <Route
        path="/forgot-password"
        render={() => (
          <PageComponentTemplate
            component={<ForgotPassword />}
            isFooter={false}
            backgroundURL={backgroundImage}
          />
        )}
      />
      <Route
        path="/login-with-temporary-password"
        render={() => (
          <PageComponentTemplate
            component={<LoginWithTemporaryPassword />}
            isFooter={false}
            backgroundURL={backgroundImage}
          />
        )}
      />
      <Route
        path="/change-password"
        render={() => (
          <PageComponentTemplate
            component={<ChangeTemporaryPassword />}
            isFooter={false}
            backgroundURL={backgroundImage}
          />
        )}
      />
    </>
  );
};

import React, { useContext } from "react";

// Helpers
import { toDollars } from "../_common/helpers";

// Contexts
import AppSettingsContext from "../App/AppSettingsContext";
import AppLanguageContext from "../App/AppLanguageContext";
import AppLabelsContext from "../App/AppLabelsContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";

// Assets
import { ReactComponent as IconArrowDown } from "../_common/icons/IconArrowDown.svg";
import { ReactComponent as IconStar } from "../_common/icons/IconStar.svg";

export const ScanInStoreActiveCardInfo = (props) => {
  const { loyaltyCards, activeLoyaltyCard, updateActiveLoyaltyCard, apiMerchantRewards } = props;

  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const isLoyaltyEnabled = cws5ModuleContext.isLoyaltyEnabled;
  const isGiftCardModuleEnabled = cws5ModuleContext.isGiftCardModuleEnabled;
  const isOnlineOrderingEnabled = cws5ModuleContext.isOnlineOrderingEnabled;

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  const cardBalanceDisplayValue = toDollars(
    appSettings["currency-symbol"],
    appSettings["currency-symbol-side"],
    loyaltyCards.filter((card) => card.serial === activeLoyaltyCard.serial)[0]
      ? loyaltyCards.filter((card) => card.serial === activeLoyaltyCard.serial)[0].balance
      : 0,
    appLanguage
  );

  const appLabels = useContext(AppLabelsContext);

  let reformattedRewards = [];
  apiMerchantRewards.forEach((apiReward) => {
    const reformattedReward = {
      id: apiReward[0],
      name: apiReward[1],
      description: apiReward[2],
      price: parseInt(apiReward[3]),
      image: apiReward[4] || false,
      typeCode: apiReward[5],
      typeName: apiReward[6],
    };

    reformattedRewards.push(reformattedReward);
  });
  const showCardPoints = !!reformattedRewards.filter(
    (reward) => reward.typeCode === "6" || reward.typeCode === "7"
  ).length;

  return (
    <div className="scan-in-store__active-card-info">
      <div className="scan-in-store-active-card-info__points-and-balance">
        {isLoyaltyEnabled && (
          <>
            <div className="scan-in-store-active-card-info__points-and-star">
              <>
                {showCardPoints && (
                  <>
                    <div className="scan-in-store-active-card-info__points">
                      {activeLoyaltyCard.points}
                    </div>
                    <IconStar aria-hidden="true" />
                  </>
                )}
              </>
            </div>

            <div className={`${showCardPoints ? "scan-in-store-active-card-info__balance" : ""}`}>
              {cardBalanceDisplayValue}
            </div>
          </>
        )}
        {!isLoyaltyEnabled && (isGiftCardModuleEnabled || isOnlineOrderingEnabled) && (
          <div className="scan-in-store-active-card-info__points-and-star">
            <div className="scan-in-store-active-card-info__points">{cardBalanceDisplayValue}</div>
          </div>
        )}
      </div>
      <div>
        <div className="pay-in-store__select-giftcard-wrapper">
          {loyaltyCards && (
            <>
              <label htmlFor="reward-cards-dropdown" className="visually-hidden">
                {appLabels["form"]["card-number"]}
              </label>
              <select
                value={activeLoyaltyCard.maskedNumber}
                onChange={(e) =>
                  updateActiveLoyaltyCard(e.target.options[e.target.selectedIndex].dataset.card)
                }
                className="pay-in-store__select-giftcard"
                id="reward-cards-dropdown"
                disabled={loyaltyCards.length === 1}
                style={loyaltyCards.length === 1 ? { paddingRight: 0 } : {}}
              >
                {loyaltyCards.map((card, index) => (
                  <option
                    key={`${card.serial}-${index}`}
                    value={card.maskedNumber}
                    data-card={JSON.stringify(card)}
                  >
                    {card.maskedNumber}
                  </option>
                ))}
              </select>
            </>
          )}
          {loyaltyCards && loyaltyCards.length > 1 && <IconArrowDown aria-hidden="true" />}
        </div>
        {activeLoyaltyCard.expiry && (
          <div className="scan-in-store-active-card-info__expiry">
            {appLabels["order"]["pay-in-store-card-expiry"]} {activeLoyaltyCard.expiry}
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import localforage from "localforage";

/** Hooks */
import useWindowSize from "../../../_common/hooks/useWindowSize";

/** Helpers */
import { getMarquiThankYouImage } from "../OrderConfirmation/getMarquiThankYouImage";

/** Contexts */
import AppSettingsContext from "../../../App/AppSettingsContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

/** UI Components */
import { ReactComponent as IconCheck } from "../../../_common/icons/IconCheck.svg";

/** CSS */
import "./PaymentConfirmation.css";

const PaymentConfirmation = () => {
  const appSettings = useContext(AppSettingsContext);
  const appLabels = useContext(AppLabelsContext);

  const relativePath = useContext(AppSettingsContext)["relative-path"];

  const merchantConfig = useContext(MerchantConfigContext);

  const [thankYouImage, setThankYouImage] = useState(null);
  const [isIOSNativeApp, setIsIOSNativeApp] = useState(null);
  const [isAndroidNativeApp, setIsAndroidNativeApp] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);

  const deviceWidth = useWindowSize().width;
  const skin = merchantConfig.skin;
  const isLocalhost = process.env.NODE_ENV === "development";
  const IS_GIVEX_HOST = !isLocalhost;
  const PATHNAME = window.location.pathname;
  const IS_DEFAULT_LANGUAGE = IS_GIVEX_HOST
    ? PATHNAME.split("/")[3] === ""
    : PATHNAME.split("/")[1] === "";

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setThankYouImage(require("../../../_common/Images/order-confirmation-image.jpg")); // localhost fallback
    } else {
      getMarquiThankYouImage(relativePath).then((marquiImages) => {
        if (
          marquiImages &&
          marquiImages["order-confirmation"] &&
          marquiImages["order-confirmation"]["thank-you-image-src"] !==
            "files/Images/online-ordering/" &&
          !marquiImages["order-confirmation"]["thank-you-image-src"].includes("_blank.")
        ) {
          if (IS_DEFAULT_LANGUAGE) {
            setThankYouImage(marquiImages["order-confirmation"]["thank-you-image-src"]);
          } else {
            setThankYouImage("../" + marquiImages["order-confirmation"]["thank-you-image-src"]);
          }
        } else {
          setThankYouImage(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    /** Detect if it is native ios */
    var standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    if (!standalone && !safari && ios && deviceWidth <= 660) {
      //show the ios Disclaimer
      setIsIOSNativeApp(true);
    } else {
      //for debugging set to true
      setIsIOSNativeApp(false);
    }
  }, [deviceWidth]);

  useEffect(() => {
    /** Detect if it is native Android app */
    if (
      document.referrer &&
      document.referrer.includes("android-app://com.givex.cws5") &&
      deviceWidth <= 660
    ) {
      setIsAndroidNativeApp(true);
    } else {
      setIsAndroidNativeApp(false);
    }
  }, [deviceWidth]);

  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      setCustomerInfo(customerInfo);
    });
  }, []);

  useEffect(() => {
    localforage.removeItem(skin + "__storedRewardsDetails");
    localforage.removeItem(skin + "__storedPromoCode");
    localforage.removeItem(skin + "__storedRewards");
    localforage.removeItem(skin + "__coupon");
    localforage.removeItem(skin + "__pointsIssued");
    localforage.removeItem(skin + "__paymentDetails");
    localforage.removeItem(skin + "__storedTip");
  }, []);

  return (
    <div className="payment-confirmation-container">
      {thankYouImage && (
        <div
          className="payment-confirmation__image"
          style={
            (isIOSNativeApp && appSettings["ios-app-link"] !== "") ||
            (isAndroidNativeApp && appSettings["android-app-link"] !== "")
              ? { maxWidth: "190px", margin: "0 auto" }
              : {}
          }
        >
          <img
            src={thankYouImage}
            aria-hidden="true"
            alt=""
            style={
              (isIOSNativeApp && appSettings["ios-app-link"] !== "") ||
              (isAndroidNativeApp && appSettings["android-app-link"] !== "")
                ? { textAlign: "center", marginTop: "1.2em", marginBottom: "0.4em" }
                : {}
            }
          />
        </div>
      )}

      <div className="payment-confirmation__thankyou-note">
        <div className="payment-confirmation__checkmark">
          <IconCheck />
        </div>
        <h2 className="payment-confirmation__heading">
          {appLabels["order"]["thank-you-for-payment"]}
        </h2>
      </div>

      {customerInfo && customerInfo.email && (
        <div className="payment-confirmation__email">
          {appLabels["order"]["email-receipt-blurb"].replace("[email]", customerInfo.email)}
        </div>
      )}

      <div className="payment-confirmation__blurb">
        {appLabels["order"]["payment-confirmation-blurb"]}
      </div>

      <div
        className={`order-confirmation__rewards-bottom button-container--single-bottom order-confirmation__rewards-bottom__bottom-margin payment-confirmation__button-wrapper `}
      >
        <Link to="/dashboard" className="button  button--primary">
          <span>{appLabels["order"]["back-to-home"]}</span>
        </Link>
      </div>
    </div>
  );
};
export default PaymentConfirmation;

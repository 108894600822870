// Libraries
import React, { useContext } from "react";

//Context
import AppLabelsContext from "../../App/AppLabelsContext";

//CSS
import "./OrderStatusProgressBar.css";

export const OrderStatusProgressBar = (props) => {
  const { order } = props;

  // For Debugging
  /*const order = {
    orderStatus: {
      status: "order_received",
      isFinished: false,
      allStatuses: "placed,payment_processed,pending_review",
    },
    orderType: "Pickup",
  };*/

  //console.log("order: ", order);

  const appLabels = useContext(AppLabelsContext);

  const isPickupOrder = order.orderType === "Pickup";

  const pastStatuses = order.orderStatus.allStatuses;
  /** Possible statuses from 1326 */
  const isPlaced = order.orderStatus.status === "placed";
  const isReceived = order.orderStatus.status === "order_received";
  const isPaymentProcessed = order.orderStatus.status === "payment_processed";
  const isPendingReview = order.orderStatus.status === "pending_review";

  const isCancelled = order.orderStatus.status === "cancelled";
  const isVoid = order.orderStatus.status === "void";
  const isRejected = order.orderStatus.status === "rejected";
  const isNotApproved = isCancelled || isVoid || isRejected;

  const isProduced = order.orderStatus.status === "produced";
  const isReadyForPickup = order.orderStatus.status === "ready_for_pickup";
  const isClosed = order.orderStatus.status === "closed";
  const isDeliveryPending = order.orderStatus.status === "pending_delivery";
  const isDeliveryCompleted = order.orderStatus.status === "delivery_completed";
  const isCompleted = order.orderStatus.status === "completed";

  const isDeliveryReceived = order.orderStatus.status === "received";
  const isDeliveryAccepted = order.orderStatus.status === "accepted";
  const isDeliveryPickedUp = order.orderStatus.status === "pickedup";

  const isDeliveryCancelled = order.orderStatus.status === "delivery_cancelled";
  const isDeliveryCleared = order.orderStatus.status === "delivery_cleared";

  const isReceivedCurrent =
    (isPlaced || isPaymentProcessed) &&
    !isReceived &&
    (pastStatuses.includes("placed") || pastStatuses.includes("payment_processed")) &&
    !pastStatuses.includes("order_received");

  const isReceivedDone =
    isReceived ||
    isNotApproved ||
    isProduced ||
    isReadyForPickup ||
    isClosed ||
    isPendingReview ||
    isDeliveryPending ||
    isDeliveryCompleted ||
    isCompleted ||
    isDeliveryAccepted ||
    isDeliveryReceived ||
    isDeliveryPickedUp ||
    isDeliveryCancelled ||
    isDeliveryCleared ||
    pastStatuses.includes("order_received");

  const isAcceptedCurrent =
    (isReceivedDone &&
      isPendingReview &&
      !isProduced &&
      !isNotApproved &&
      !isReadyForPickup &&
      !isClosed &&
      !isDeliveryCompleted &&
      !isCompleted &&
      !pastStatuses.includes("produced")) ||
    (isReceived && !pastStatuses.includes("pending_review"));

  const isAcceptedDone =
    (isProduced ||
      isReadyForPickup ||
      isClosed ||
      isDeliveryPending ||
      isDeliveryCompleted ||
      isCompleted ||
      isDeliveryAccepted ||
      isDeliveryReceived ||
      isDeliveryPickedUp ||
      isDeliveryCancelled ||
      isDeliveryCleared ||
      pastStatuses.includes("pending_review") ||
      pastStatuses.includes("produced")) &&
    !isPlaced &&
    !isPendingReview;

  const isPreparingCurrent =
    isAcceptedDone &&
    !isReadyForPickup &&
    !isClosed &&
    !isProduced &&
    !isDeliveryPending &&
    !isDeliveryCompleted &&
    !isCompleted &&
    !isDeliveryAccepted &&
    !isDeliveryReceived &&
    !isDeliveryCancelled &&
    !isDeliveryCleared &&
    !isDeliveryPickedUp &&
    !pastStatuses.includes("produced");

  const isPreparingDone =
    isReadyForPickup ||
    isClosed ||
    isProduced ||
    isDeliveryPending ||
    isDeliveryCompleted ||
    isCompleted ||
    isDeliveryAccepted ||
    isDeliveryReceived ||
    isDeliveryCancelled ||
    isDeliveryCleared ||
    isDeliveryPickedUp ||
    pastStatuses.includes("produced");

  const isReadyForPickupCurrent =
    (isPreparingDone &&
      !isReadyForPickup &&
      !isClosed &&
      !isDeliveryCompleted &&
      !isCompleted &&
      !isDeliveryPickedUp) ||
    isDeliveryPending ||
    (isClosed && !pastStatuses.includes("ready_for_pickup"));

  const isReadyForPickupDone =
    isReadyForPickup ||
    (isClosed && pastStatuses.includes("ready_for_pickup")) ||
    isDeliveryCompleted ||
    isCompleted ||
    isDeliveryPickedUp ||
    pastStatuses.includes("ready_for_pickup") ||
    (pastStatuses.includes("closed") && pastStatuses.includes("ready_for_pickup")) ||
    pastStatuses.includes("delivery_completed") ||
    pastStatuses.includes("completed") ||
    pastStatuses.includes("pickedup");

  const isCompleteCurrent =
    (isReadyForPickup || isDeliveryPickedUp || pastStatuses.includes("pending_delivery")) &&
    ((!isClosed && !pastStatuses.includes("closed") && isPickupOrder) ||
      ((isClosed || pastStatuses.includes("closed")) && !isPickupOrder));
      
  const isCompleteDone =
    (isClosed && pastStatuses.includes("ready_for_pickup") && isPickupOrder) ||
    isDeliveryCompleted ||
    isCompleted ||
    (pastStatuses.includes("closed") && isReadyForPickupDone && isPickupOrder) ||
    pastStatuses.includes("completed") ||
    pastStatuses.includes("delivery_completed");

  return (
    <ol className="order-status__progress-bar">
      {/**Placed */}
      <li className={`order-status__progress-bar-item  is-done `} aria-live="assertive">
        {appLabels["account"]["order-placed"]}
        <div className="checkMark draw"></div>
      </li>

      {/**Received */}
      <li
        className={`order-status__progress-bar-item  ${isReceivedDone ? " is-done" : ""} ${
          isReceivedCurrent ? " current" : ""
        }  `}
        aria-live="assertive"
      >
        {appLabels["account"]["order-received"]}
        <div
          className={`${
            isReceivedCurrent
              ? " order-status__progress-bar-animation-circle"
              : " order-status__progress-bar-animation-circle-hidden"
          }`}
        ></div>

        {isReceivedDone && <div className="checkMark draw"></div>}
      </li>

      {/**Accepted */}
      <li
        className={`order-status__progress-bar-item  
            ${isAcceptedDone ? " is-done" : ""}${isNotApproved ? " is-rejected" : ""}
            ${isAcceptedCurrent ? "current" : ""} 
        `}
        aria-live="assertive"
      >
        {isCancelled
          ? appLabels["account"]["order-cancelled"]
          : isVoid
          ? appLabels["account"]["order-void"]
          : isRejected
          ? appLabels["account"]["order-rejected"]
          : isAcceptedDone
          ? appLabels["account"]["order-accepted"]
          : appLabels["account"]["order-reviewing"]}

        <div
          className={`${
            isAcceptedCurrent
              ? " order-status__progress-bar-animation-circle"
              : " order-status__progress-bar-animation-circle-hidden"
          }`}
        ></div>
        {isAcceptedDone && <div className="checkMark draw"></div>}
        {isNotApproved && <div className="xMark draw"></div>}
      </li>

      {/**Preparing */}
      <li
        aria-live="assertive"
        className={`order-status__progress-bar-item 
            ${isPreparingDone ? " is-done" : ""}
            ${isPreparingCurrent ? " current" : ""}
        `}
      >
        {isPreparingDone && isReadyForPickupDone
          ? appLabels["account"]["order-prepared"]
          : appLabels["account"]["order-preparing"]}
        <div
          className={`${
            isPreparingCurrent
              ? " order-status__progress-bar-animation-circle"
              : " order-status__progress-bar-animation-circle-hidden"
          }`}
        ></div>
        {isPreparingDone && <div className="checkMark draw"></div>}
      </li>

      {/**Ready for pickup , on its way */}
      <li
        aria-live="assertive"
        className={`order-status__progress-bar-item 
            ${isReadyForPickupDone ? " is-done" : ""}
            ${isReadyForPickupCurrent ? " current" : ""}
        `}
      >
        {isPickupOrder
          ? `${appLabels["account"]["order-ready-for-pickup"]}`
          : `${appLabels["account"]["order-on-its-way"]}`}
        <div
          className={`${
            isReadyForPickupCurrent
              ? " order-status__progress-bar-animation-circle"
              : " order-status__progress-bar-animation-circle-hidden"
          }`}
        ></div>
        {isReadyForPickupDone && <div className="checkMark draw"></div>}
      </li>

      {/**Complete */}
      <li
        aria-live="assertive"
        className={`order-status__progress-bar-item 
            ${isCompleteDone ? " is-done" : ""}   
            ${isCompleteCurrent ? " current" : ""}
        `}
      >
        {appLabels["account"]["order-complete"]}
        <div
          className={`${
            isCompleteCurrent
              ? " order-status__progress-bar-animation-circle"
              : " order-status__progress-bar-animation-circle-hidden"
          }`}
        ></div>
        {isCompleteDone && <div className="checkMark draw"></div>}
      </li>
    </ol>
  );
};

import React, { useState, useEffect, useContext } from "react";
import AppLabelsContext from "../App/AppLabelsContext";
import Scanner from "./Scanner";

export const BarcodeScanner = ({ handleScan, videoDevices, setStreamError }) => {
  const [currentVideoDevice, setCurrentVideoDevice] = useState(0);
  const [activeDeviceId, setActiveDeviceId] = useState("idle");

  const numberOfVideoDevices =
    videoDevices !== "idle" && videoDevices !== "stop" && videoDevices !== null
      ? videoDevices.length
      : 0;

  useEffect(() => {
    if (videoDevices !== "idle" && numberOfVideoDevices > 0) {
      getNextDeviceId();
    } else if (videoDevices === null) {
      setActiveDeviceId("");
    }
  }, [videoDevices]);

  /** Set the state of the active Device Id
   * if there is not more devices left, set the active device id to ""
   */
  const getNextDeviceId = () => {
    let videoDevice = false;
    if (currentVideoDevice < numberOfVideoDevices) {
      videoDevice = videoDevices[currentVideoDevice];
      setCurrentVideoDevice(currentVideoDevice + 1);
    }

    setActiveDeviceId(videoDevice !== false ? videoDevice.deviceId : "");
  };

  const appLabels = useContext(AppLabelsContext);

  return (
    <>
      {activeDeviceId !== "idle" && (
        <div
          style={{
            width: `100%`,
            height: `100%`,
          }}
        >
          <Scanner
            onDetected={handleScan}
            deviceId={activeDeviceId}
            videoDevices={videoDevices}
            getNextDeviceId={getNextDeviceId}
            currentVideoDevice={currentVideoDevice}
            numberOfVideoDevices={numberOfVideoDevices}
            setStreamError={setStreamError}
            orderLabels={appLabels["order"]}
          />
        </div>
      )}
    </>
  );
};

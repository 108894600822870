import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

/** Hooks */
import useWindowSize from "../../_common/hooks/useWindowSize";

/** Contexts */
import BrandingContext from "../../App/BrandingContext";
import AppLabelsContext from "../../App/AppLabelsContext";

/** UI Components */
import { AdditionalDisclaimer } from "../../_common/AdditionalDisclaimer";
import CollapsableSection from "../../_common/components/CollapsableSection";

/** CSS */
import "../../OnlineOrdering/Cart/CartItemsCollapsable.css";
import GCCartItem from "./GCCartItem";

const GCCartItemsCollapsableSection = (props) => {
  const {
    cartData,
    itemsArray,
    orderSummaryData,
    isCalling1307,
    items,
    removeItem,
    updateItemQuantity,
    skin,
    setIsRemovingAllItems,
    initiallyIsCollapsed,
    isOnCheckoutPage,
    subHeaderContent,
    customClass,
  } = props;

  const brandingContext = useContext(BrandingContext);
  const appLabels = useContext(AppLabelsContext);
  const deviceWidth = useWindowSize().width;
  const history = useHistory();
  const addMoreItems = (
    <button
      className="link-wrapper--add-items "
      onClick={() => {
        history.push("/gift-card/purchase", { from: "gift card cart" })
      }}
      type="button"
    >
      {appLabels["order"]["add-more-items"]}
    </button>
  );

  const [isCollapsed, setIsCollapsed] = useState(initiallyIsCollapsed);

  return (
    <>
      <CollapsableSection
        headerText={appLabels["order"]["review-items"]}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        id={"cartItemsCollapsable"}
        subHeaderContent={subHeaderContent}
      />

      <div
        className={`cart-items-desktop ${itemsArray.length === 0 ? " cart-items-desktop-empty" : ""
          }   ${isCollapsed
            ? " collapsable-section__collapsed-content"
            : " collapsable-section__expanded-content"
          } ${customClass ? customClass : ""}`}
      >
        {items != null && (
          <div className={` cart-items-collapsable__content-buttons-container `}>
            {addMoreItems}
            {itemsArray.length > 0 && (
              <button
                onClick={() => setIsRemovingAllItems(true)}
                type="button"
                className="link-wrapper--add-items"
              >
                {appLabels["order"]["remove-all-items"]}
              </button>
            )}
          </div>
        )}
        <ul
          className={`cartItem__container`}
          style={
            window.location.href.includes("checkout") && (deviceWidth < 660 || deviceWidth > 1280)
              ? { paddingBottom: 0 }
              : window.location.href.includes("review-order") && !isCollapsed
                ? { paddingBottom: "15em" }
                : {}
          }
        >
          {itemsArray.length > 0 && items.length > 0 &&
            orderSummaryData &&
            orderSummaryData !== "reset" &&
            !isCalling1307 &&
            items.map((item, index) => (
              <GCCartItem
                item={item}
                itemPrice={100}
                cart={cartData.value}
                handleRemove={removeItem}
                updateItemQuantity={updateItemQuantity}
                updateCart={cartData.updateCart}
                key={`${item.entryKey}-${index}`}
                setIsCartFAB={false}
                skin={skin}
                setOrderSummaryData={null}
                orderSummaryData={cartData.value}
                branding={brandingContext}
                itemIndex={0}
                isOnCheckoutPage={true}
                setShowCheckoutButton={null}
                isAddonQuantityEnabled={false}
              />
            ))}
          {(cartData === null || cartData.length === 0) && (
            <div className="empty-cart-message">{appLabels["order"]["empty-cart-message"]}</div>
          )}
        </ul>

        {deviceWidth < 850 && !isOnCheckoutPage && (
          <AdditionalDisclaimer
            disclaimer={appLabels["order"]["review-order-disclaimer"]}
            styleObject={{ paddingLeft: "30px", paddingRight: "30px" }}
          />
        )}
      </div>
    </>
  );
};
export const GCCartItemsCollapsable = React.memo(GCCartItemsCollapsableSection);

import { useContext, useEffect, useState } from "react";

/** Contexts */
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";
import AppSettingsContext from "./AppSettingsContext";

export const useHideOrderModule = () => {
  const appSettings = useContext(AppSettingsContext);
  const activeOrderType = useContext(OrderTypeContext).value;

  const [hideOrderModule, setHideOrderModule] = useState(false);

  useEffect(() => {
    if (appSettings && activeOrderType) {
      const isPayOnly = appSettings["is-pay-only"] === "yes";
      const isDinein = activeOrderType === "dinein";

      if (isPayOnly && isDinein) {
        setHideOrderModule(true);
      } else {
        setHideOrderModule(false);
      }
    }
  }, [appSettings, activeOrderType]);

  return { hideOrderModule: hideOrderModule };
};

import { hasNumber } from "../../helpers";
import { cpf } from "cpf-cnpj-validator";

export function removeAllSpaces(string) {
  return string.replace(/\s/g, "");
}

export function isEmailValid(email) {
  const pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return pattern.test(email.replace(/ /g, ""));
}

export function isCreditCardValid(number, minLength) {
  if (number.length < minLength || isNaN(number)) {
    return false;
  } else {
    return true;
  }
}

export function isExpiryDateValid(date) {
  const month = date.split("/")[0];
  const currentYear = new Date().getFullYear().toString();
  const year = currentYear.substring(0, currentYear.length - 2) + date.split("/")[1];
  const today = new Date();
  let someday = new Date();
  someday.setFullYear(year, month, 1);
  return someday > today && month !== "00" && month < "13";
}

export function isCVVValid(cvv, minLength, maxLength = 3) {
  return cvv.length < minLength && cvv.length > maxLength ? false : true;
}

export function isPostalValid(postal, minLength) {
  return postal.length !== minLength ? false : true;
}

export function isBirthdayValid(date, minLength) {
  return date.length !== minLength ? false : true;
}

export function isPasswordValid(password, minLength) {
  return password.length < minLength ? false : true;
}

export function validateForm(formData, formLabels, isSavedCCForm = false, eGiftCardAmountLimits = null) {
  let errors = 0;
  let isValid = false;
  let fieldErrors = []
  const errorEmailsDontMatch = formLabels["emails-dont-match"];

  /*
  Adding Gift Card amount limits.
  These values are populated using merchant config context.
  */
  let maxEGiftCardAmount = null
  let minEGiftCardAmount = null
  if (eGiftCardAmountLimits) {
    maxEGiftCardAmount = Number(eGiftCardAmountLimits.maxAmount);
    minEGiftCardAmount = Number(eGiftCardAmountLimits.minAmount);
  }

  for (let field in formData) {
    const isFieldRequired = formData[field].isRequired;
    let fieldValue = formData[field].value;
    if (fieldValue) {
      if (field === "deliveryDateAndTime") {
        fieldValue.toString()
      }
      formData[field].value = fieldValue.trim();

      if (field === "giftcard-number") {
        formData[field].value = removeAllSpaces(formData[field].value);
      }
    }

    if (
      (isFieldRequired && fieldValue === "") ||
      (isFieldRequired &&
        typeof formData[field].isChecked !== "undefined" &&
        !formData[field].isChecked)
    ) {
      formData[field].errorMessage = formLabels["required"];
      formData[field].isValid = false;
      fieldErrors.push(formData[field].errorMessage + " " + field.toString().replaceAll('-', " ") + " field")
      errors++;
    } else if (fieldValue !== "" && field === "first-name" && hasNumber(fieldValue)) {
      formData[field].errorMessage = formLabels["first-name-numbers-error"];
      fieldErrors.push(formData[field].errorMessage + " " + field.toString().replaceAll('-', " ") + " field")
      formData[field].isValid = false;
      errors++;
    } else if (fieldValue !== "" && field === "middle-name" && hasNumber(fieldValue)) {
      formData[field].errorMessage = formLabels["middle-name-numbers-error"];
      formData[field].isValid = false;
      errors++;
    } else if (fieldValue !== "" && field === "last-name" && hasNumber(fieldValue)) {
      formData[field].errorMessage = formLabels["last-name-numbers-error"];
      formData[field].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      (field === "email" || field === "new-email" || field === "confirm-new-email") &&
      !isEmailValid(fieldValue)
    ) {
      formData[field].errorMessage = formLabels["invalid-email-error"];
      formData[field].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      (field === "phone" || field === "phone-number" || field === "cell-number") &&
      isNaN(removeAllSpaces(fieldValue.replace(/[- )(]/g, "")))
    ) {
      formData[field].errorMessage = formLabels["phone-only-numbers-error"];
      formData[field].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      (field === "phone" || field === "phone-number" || field === "cell-number") &&
      removeAllSpaces(fieldValue.replace(/[- )(]/g, "")).length < formData[field].minLength
    ) {
      //if length of the phone number does not match the minLength attribute
      formData[field].errorMessage = formLabels["invalid-phone-number"];
      formData[field].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      field === "confirm-email" &&
      fieldValue.replace(/ /g, "") !== formData.email.value.replace(/ /g, "")
    ) {
      formData[field].errorMessage = errorEmailsDontMatch;
      formData[field].isValid = false;

      formData["email"].errorMessage = errorEmailsDontMatch;
      formData["email"].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      field === "confirm-new-email" &&
      fieldValue.replace(/ /g, "") !== formData["new-email"].value.replace(/ /g, "")
    ) {
      formData[field].errorMessage = errorEmailsDontMatch;
      formData[field].isValid = false;
      formData["new-email"].errorMessage = errorEmailsDontMatch;
      formData["new-email"].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      field === "confirmRecipientEmail" &&
      fieldValue.replace(/ /g, "") !== formData["recipientEmail"].value.replace(/ /g, "")
    ) {
      formData[field].errorMessage = errorEmailsDontMatch;
      formData[field].isValid = false;
      formData["recipientEmail"].errorMessage = errorEmailsDontMatch;
      formData["recipientEmail"].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      field === "deliveryDateAndTime" &&
      new Date().getTime() > new Date(fieldValue).getTime()
    ) {

      formData[field].errorMessage = formLabels["eGiftCard-date-time-incorrect"];
      formData[field].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      field === "credit-card-number" &&
      !isCreditCardValid(removeAllSpaces(fieldValue), formData[field].minLength)
    ) {
      formData[field].errorMessage = formLabels["invalid-card-number"];
      formData[field].isValid = false;
      errors++;
    } else if (fieldValue !== "" && field === "credit-card-name" && hasNumber(fieldValue)) {
      formData[field].errorMessage = formLabels["credit-card-numbers-error"];
      formData[field].isValid = false;
      errors++;
    } else if (field === "credit-card-expiry" && !isExpiryDateValid(fieldValue)) {
      formData[field].errorMessage = formLabels["invalid-date-error"];
      formData[field].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      field === "credit-card-cvv" &&
      !isCVVValid(fieldValue, formData[field].minLength, isSavedCCForm ? 4 : 3)
    ) {
      formData[field].errorMessage = formLabels["invalid-cvv-error"];
      formData[field].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      field === "postal" &&
      formData[field].maskPattern &&
      !isPostalValid(removeAllSpaces(fieldValue), formData[field].minLength)
    ) {
      formData[field].errorMessage = formLabels["invalid-postal-error"];
      formData[field].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      field === "birthday" &&
      !isBirthdayValid(fieldValue, formData[field].minLength) &&
      field.isRequired
    ) {
      formData[field].errorMessage = formLabels["invalid-birthday-error"];
      formData[field].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      field.includes("password") &&
      !field.includes("confirm") &&
      !isPasswordValid(fieldValue, formData[field].minLength)
    ) {
      formData[field].errorMessage = formLabels["password-char-length-error"];
      formData[field].isValid = false;
      errors++;
    } else if (
      fieldValue !== "" &&
      ((field === "confirm-password" && fieldValue !== formData["password"].value) ||
        (field === "confirm-new-password" && fieldValue !== formData["new-password"].value))
    ) {
      formData[field].errorMessage = formLabels["passwords-dont-match"];
      formData[field].isValid = false;

      formData[field.split("confirm-")[1]].errorMessage = formLabels["passwords-dont-match"];
      formData[field.split("confirm-")[1]].isValid = false;
      errors++;
    } else if (field === "cpf-number") {
      if (isFieldRequired) {
        if (fieldValue.length !== 11 || !/^\d+$/.test(fieldValue) || !cpf.isValid(fieldValue)) {
          formData[field].errorMessage = "Por favor, insira um número de CPF válido.";
          formData[field].isValid = false;
          errors++;
        }
      } else if (
        fieldValue !== "" &&
        (fieldValue.length !== 11 || !/^\d+$/.test(fieldValue) || !cpf.isValid(fieldValue))
      ) {
        formData[field].errorMessage = "Por favor, insira um número de CPF válido.";
        formData[field].isValid = false;
        errors++;
      }
    } else if (field === "eGiftCardAmount" && eGiftCardAmountLimits) {

      // check that all characters are positive and numeric
      // the check for "other" is to flag a selectiInput combo that has custom amount set in select but input field is empty
      if (fieldValue === "other") {
        formData[field].errorMessage = formLabels["required"];
        formData[field].isValid = false;
        errors++;
      }
      else if (!parseFloat(fieldValue)) {
        formData[field].errorMessage = formLabels["invalid-amount"];
        formData[field].isValid = false;
        errors++;
      } else if (parseFloat(fieldValue) <= 0) {
        formData[field].errorMessage = formLabels["invalid-amount"];
        formData[field].isValid = false;
        errors++;
      }
      else if ((maxEGiftCardAmount && minEGiftCardAmount) && (Number(fieldValue) > maxEGiftCardAmount || Number(fieldValue) < minEGiftCardAmount)) {
        formData[field].errorMessage = formLabels["eGiftCard-amount-incorrect"];
        formData[field].isValid = false;
        errors++;
      }

    }
  }

  if (errors > 0) isValid = false;
  else isValid = true;

  return { isValid, fieldErrors };
}

import image1 from "./images/how-rewards-work-step-1.jpg";
import image2 from "./images/how-rewards-work-step-2.jpg";
import image3 from "./images/how-rewards-work-step-3.jpg";
import image4 from "./images/how-rewards-work-step-4.jpg";
import image5 from "./images/how-rewards-work-step-5.jpg";

export const fallbackImages = {
  "how-rewards-work-steps": {
    "step-1": {
      "image-src": image1,
      "image-alt": "",
    },
    "step-2": {
      "image-src": image2,
      "image-alt": "",
    },
    "step-3": {
      "image-src": image3,
      "image-alt": "",
    },
    "step-4": {
      "image-src": image4,
      "image-alt": "",
    },
    "step-5": {
      "image-src": image5,
      "image-alt": "",
    },
  },
};

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import localforage from "localforage";

/** UI Assets */
import { ReactComponent as IconChevronRight } from "../_common/icons/IconArrowRight.svg";

/** CSS */
import "../OnlineOrdering/Menu/MenuBreadcrumbs.css";
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";


export const GiftCardMenuBreadcrumbs = ({ currentCategory }) => {
  const appLabels = useContext(AppLabelsContext);
  const { skin } = useContext(MerchantConfigContext);

  return (
    <div className="menu-breadcrumbs gcMenuBreadcrumbs">
      <MenuBreadcrumbLink
        path={{ pathname: "/gift-card/purchase", state: { from: "menu-home-breadcrumb" } }}
        displayName={appLabels["gift-card"]["gift-cards"]}
        skin={skin}
        appLabels={appLabels}
      />
      {currentCategory && (
        <>
          <IconChevronRight aria-hidden="true" />
          <CurrentMenuBreadcrumb
            displayName={currentCategory}
            appLabels={appLabels}
          />

        </>
      )}
    </div>
  );
};

const MenuBreadcrumbLink = ({ path, displayName, skin, appLabels }) => {
  const handleClick = () => {
    localforage.removeItem(skin + "__itemSelectionInProgress");
    localforage.removeItem(skin + "__storeIdToBeFaved");
    sessionStorage.removeItem(skin + "__orderStoreState");
  };
  return (
    <Link to={path} className="menu-breadcrumbs__item menu-breadcrumbs__link" onClick={handleClick}>
      {displayName.toLowerCase() === "featured" ? appLabels["order"]["featured"] : displayName}
    </Link>
  );
};

const CurrentMenuBreadcrumb = ({ displayName, appLabels }) => {
  //const appLabels = useContext(AppLabelsContext);
  //const isFeaturedItem = displayName.toLowerCase() === "featured";
  return (
    <span className="menu-breadcrumbs__item">
      {displayName.toLowerCase() === "featured" ? appLabels["order"]["featured"] : displayName}
    </span>
  );
};

import React, { useState, useContext, useEffect } from "react";

/** Helpers */
import { capitalizeWords } from "../../_common/stringHelpers";

/** Contexts */
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import AppSettingsContext from "../../App/AppSettingsContext";

/** CSS */
import "./DashboardHeaderLanguageDropdown.css";

export const DashboardHeaderLanguageDropdown = () => {
  const merchantConfig = useContext(MerchantConfigContext);
  const defaultLanguage = merchantConfig["default-language"];
  const appLanguage = useContext(AppLanguageContext);
  const appSettings = useContext(AppSettingsContext);
  const languageCodes = appSettings["languages-list"];

  const [dropdownData, setDropdownData] = useState(null);

  const isoLangs = require("../../_common/langCodes").isoLangs;

  useEffect(() => {
    const tempDropdownData = [];

    if (appLanguage !== "en" && defaultLanguage === "") {
      tempDropdownData.push({
        name: "english",
        displayName: "English",
        lang: "en",
        linkPathname: `${
          window.location.pathname.split(`${appLanguage}/`)[0]
        }${window.location.hash.replace(/\?lang=[a-z]*/g, "")}?lang=en`,
      });
      //
    } else if (appLanguage === "en" && defaultLanguage !== "") {
      /*const displayNativeName = capitalizeWords(
        isoLangs["pt"]["nativeName"].split(/[;,]+/)[0].trim()
      );

      tempDropdownData.push({
        name: isoLangs["pt"]["name"].toLowerCase(),
        displayName: displayNativeName,
        linkPathname: `/${window.location.hash}`,
      });*/
    }

    const isLocalhost = process.env.NODE_ENV === "development";
    const isGivexHost = !isLocalhost;
    const pathnameSplit = window.location.pathname.split("/");
    const languageFromPathname = isGivexHost ? pathnameSplit[3] : pathnameSplit[1];

    languageCodes.forEach((lang) => {
      const langExists = isoLangs[lang];
      if (lang !== appLanguage && langExists) {
        const displayNativeName = capitalizeWords(
          isoLangs[lang]["nativeName"].split(/[;,]+/)[0].trim()
        );

        tempDropdownData.push({
          name: isoLangs[lang]["name"].toLowerCase(),
          displayName: displayNativeName,
          lang: lang,
          linkPathname:
            lang !== "en"
              ? `${
                  !!languageFromPathname
                    ? window.location.pathname.replace(`/${languageFromPathname}`, "")
                    : window.location.pathname
                }${lang}/${window.location.hash.replace(/\?lang=[a-z]*/g, "")}?lang=${lang}`
              : `${window.location.pathname
                  .replace(`/${appLanguage}`, "")
                  .replace(`/${languageFromPathname}`, "")}${window.location.hash.replace(
                  /\?lang=[a-z]*/g,
                  ""
                )}?lang=${lang}`,
        });
      }
    });

    setDropdownData(tempDropdownData);
  }, []);

  return (
    <>
      {dropdownData && (
        <div className="dashboard-header__language-dropdown">
          <ul className="language-dropdown__list">
            {dropdownData.map((lang, index) => (
              <li key={index} className="language-dropdown-list__item">
                <a href={lang.linkPathname} className="language-dropdown-list-item__link">
                  {lang.displayName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

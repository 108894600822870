/*global google*/
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

/** Contexts */
import AppLabelsContext from "../App/AppLabelsContext";
import useWindowSize from "../_common/hooks/useWindowSize";
import MerchantConfigContext from "../App/MerchantConfigContext";

/** UI Components */
import { OrderStoresSearchComponent } from "../OrderSettings/OrderStoresSearchComponent";
import DeliveryAddressesComponent from "../OrderSettings/DeliveryAddressesComponent";

const AddDeliveryAddress = (props) => {
  const { isShowingGoogleMap, orderType, searchboxInputRef } = props;
  const appLabels = useContext(AppLabelsContext);
  const deviceWidth = useWindowSize().width;
  const skin = useContext(MerchantConfigContext).skin;

  const [geoLocationButtonIsActive, setGeoLocationButtonIsActive] = useState(null);
  const autocompleteRef = useRef(null);
  const history = useHistory();

  const getUserGeolocation = () => {
    setGeoLocationButtonIsActive(true);
    if (navigator && navigator.geolocation) {
      document.activeElement.blur(); // resets document focus to remove focus from the Find My Location button

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setGeoLocationButtonIsActive(false);

          const storeCoordsObject = new google.maps.LatLng(lat, lng);
          reverseGeoCode(storeCoordsObject).then((reverseGeoCodeResponse) => {
            if (reverseGeoCodeResponse) {
              const reverseGeoCodeComponents = reverseGeoCodeResponse["address_components"];
              const country = getAddressComponent(
                "country",
                reverseGeoCodeComponents,
                "short_name"
              );
              const postalCodeComponent = reverseGeoCodeComponents.filter(
                (component) =>
                  component["types"].includes("postal_code") &&
                  !component["types"].includes("postal_code_prefix")
              );
              const postalCode =
                postalCodeComponent.length > 0 ? postalCodeComponent[0]["long_name"] : "";

              const userAddress = getDeliveryAddress(reverseGeoCodeComponents, country, postalCode);

              const userCoordinates = {
                lat: lat,
                lng: lng,
              };

              history.push({
                pathname: "/account/confirm-address",
                state: {
                  from: "delivery-addresses-geocode",
                  userAddress: userAddress,
                  userCoordinates: userCoordinates,
                },
              });
            }
          });
        },

        function error() {
          setGeoLocationButtonIsActive(false);
          alert(appLabels["general"]["disabled-geolocation-alert"]);
          console.log(
            "GPS position feature was disabled. To enable again - click on the location icon in the top right corner of the browser within a search field, then clear these settings for future visits and reload."
          );
        },

        { enableHighAccuracy: true }
      );
    }
  };

  const getDeliveryAddress = (addressComponent, country, postalCode) => {
    let hasLocalityComponent =
      addressComponent.filter((comp) => comp.types.includes("locality")).length > 0;

    const provinceComponent = getAddressComponent(
      "administrative_area_level_1",
      addressComponent,
      "short_name"
    );
    return {
      "first-address": `${getAddressComponent(
        "street_number",
        addressComponent,
        "long_name"
      )} ${getAddressComponent("route", addressComponent, "short_name")}`,
      city: getAddressComponent(
        country === "HK"
          ? "neighborhood"
          : country === "BR"
          ? "administrative_area_level_1"
          : hasLocalityComponent
          ? "locality"
          : "sublocality",
        addressComponent,
        "long_name"
      ),
      country: country,
      postal: postalCode,
      province:
        country === "BR"
          ? `${provinceComponent}:BR`
          : country === "MX"
          ? `${provinceComponent}:MX`
          : country === "CN"
          ? `${provinceComponent}:CN`
          : provinceComponent,
    };
  };

  const reverseGeoCode = async (coordinates) => {
    //Reverse geocode
    const geocoder = new google.maps.Geocoder();
    const geoCodeInfo = await geocoder.geocode({ location: coordinates });
    if (geoCodeInfo) {
      if (geoCodeInfo.results[0]) {
        return geoCodeInfo.results[0];
      } else {
        window.alert("No results found");
      }
    }
  };

  const geocodePlaceId = async (placeId) => {
    const geocoder = new google.maps.Geocoder();
    const geoCodeInfo = await geocoder.geocode({ placeId: placeId });
    if (geoCodeInfo) {
      if (geoCodeInfo.results[0]) {
        return geoCodeInfo.results[0];
      } else {
        window.alert("No results found");
      }
    }
  };

  const getUserInputAddressComponent = (input) => {
    return input.address_components;
  };

  const getAddressComponent = (addressKey, components, nameFormat) => {
    const info = components.filter((component) => component["types"].includes(addressKey));
    return info.length > 0 ? info[0][nameFormat] : "";
  };

  const onSearchBoxPlacesChanged = async (prediction) => {
    await geocodePlaceId(prediction.place_id).then(async (places) => {
      if (places && Object.keys(places).length > 0) {
        const position = places.geometry.location;
        const lat = position.lat();
        const lng = position.lng();
        const storeCoordsObject = new google.maps.LatLng(lat, lng);
        const userInputAddressComponent = getUserInputAddressComponent(places);
        const reverseGeoCodeResponse = userInputAddressComponent
          ? userInputAddressComponent
          : await reverseGeoCode(storeCoordsObject);

        if (!userInputAddressComponent) {
          return;
        }

        if (reverseGeoCodeResponse) {
          const country = getAddressComponent("country", reverseGeoCodeResponse, "short_name");
          const postalCodeComponent = reverseGeoCodeResponse.filter(
            (component) =>
              component["types"].includes("postal_code") &&
              !component["types"].includes("postal_code_prefix")
          );
          const postalCode =
            postalCodeComponent.length > 0 ? postalCodeComponent[0]["long_name"] : "";

          const userAddress = getDeliveryAddress(userInputAddressComponent, country, postalCode);
          const userCoordinates = {
            lat: lat,
            lng: lng,
          };
          history.push({
            pathname: "/account/confirm-address",
            state: {
              from: "delivery-addresses",
              userAddress: userAddress,
              userCoordinates: userCoordinates,
            },
          });
        }
      }
    });
  };

  const [customerInfo, setCustomerInfo] = useState(null);
  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((info) => {
      if (info) {
        setCustomerInfo(info);
      }
    });
  }, []);

  return (
    <>
      {deviceWidth >= 660 ? (
        <section className="account-info__change-password-page-title">
          <div className="desktop-container" style={{ margin: "0" }}>
            <h1>{appLabels["account"]["add-delivery-address"]}</h1>
            {customerInfo && <p>{customerInfo.email}</p>}
          </div>
        </section>
      ) : (
        <h2 className="account__delivery-address-header">
          {appLabels["account"]["add-delivery-address"]}
        </h2>
      )}

      <OrderStoresSearchComponent
        onSearchBoxPlacesChanged={onSearchBoxPlacesChanged}
        searchboxInputRef={searchboxInputRef}
        autocompleteRef={autocompleteRef}
        getUserGeolocation={getUserGeolocation}
        isShowingGoogleMap={isShowingGoogleMap}
        geoLocationButtonIsActive={geoLocationButtonIsActive}
        orderType={orderType}
      />
      <DeliveryAddressesComponent />
    </>
  );
};

export default AddDeliveryAddress;

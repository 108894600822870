import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppSettingsContext from "../AppSettingsContext";
import AppLabelsContext from "../AppLabelsContext";
import BrandingContext from "../BrandingContext";
import StaticPagesDataContextContext from "../contexts/StaticPagesDataContext";
import { getContrastColor } from "../../_common/colorHelpers";

export const AppDesktopFooter = () => {
  const appSettings = useContext(AppSettingsContext);
  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);
  const staticPagesData = useContext(StaticPagesDataContextContext);

  const isTermsEnabled = appSettings["enable-terms"] === "Yes";
  const secondaryColor = branding["secondary-colour"];
  const textColor = getContrastColor(secondaryColor);

  return (
    <footer className="desktop-footer" style={{ backgroundColor: secondaryColor, zIndex: 2 }}>
      <div className="desktop-container desktop-footer__wrapper">
        <ul className="desktop-footer__nav-list">
          {isTermsEnabled && (
            <NavListItem
              path="/terms-and-conditions"
              displayName={appLabels["general"]["terms-and-conditions"]}
              textColor={textColor}
            />
          )}
          {staticPagesData &&
            staticPagesData.length > 0 &&
            staticPagesData.map((staticPageData, index) => (
              <React.Fragment key={index}>
                {staticPageData.isEnabled === "yes" && (
                  <NavListItem
                    path={staticPageData.path}
                    displayName={staticPageData.displayName}
                    textColor={textColor}
                  />
                )}
              </React.Fragment>
            ))}
        </ul>
        <span className="desktop-footer__app-version-wrapper" style={{ color: textColor }}>
          {appLabels["general"]["app-version"].replace("[number]", process.env.REACT_APP_VERSION)}
        </span>
      </div>
    </footer>
  );
};

const NavListItem = ({ path, displayName, textColor }) => {
  return (
    <li>
      <Link to={path} className="desktop-footer__link" style={{ color: textColor }}>
        {displayName}
      </Link>
    </li>
  );
};

import React, { useEffect, useState } from 'react';

import { GiftCardSlide } from './GiftCardSlide';
import { Carousel } from '@trendyol-js/react-carousel';
import { ReactComponent as IconArrowLeft } from "../_common/icons/IconArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../_common/icons/IconArrowRight.svg";

// Styles must use direct files imports
import useWindowSize from '../_common/hooks/useWindowSize';
import { GiftCardCarousel } from './GiftCardCarousel';
import "./GiftCardCarousel.css"

export const GCCarousel = ({ allGiftCards, selectedGiftCard = null, compactCarousel = false }) => {
  const deviceWidth = useWindowSize().width
  const [numOfSlidesToShow, setNumOfSlidesToShow] = useState(4)
  const [slidesToScroll, setSlidesToScroll] = useState(3.5)
  const [hideCarouselArrows, setHideCarouselArrows] = useState(false)

  useEffect(() => {
    let element = document.getElementById("compactCarouselWrapper")
    let containerSize;
    let carouselTrack = document.getElementsByClassName("styles-module_item-provider__YgMwz")[0]
    let carouselItem = document.getElementsByClassName("styles-module_item-container__a8zaY")[0]
    if (carouselTrack && carouselItem) {
      if (carouselTrack.clientWidth > carouselItem.clientWidth * allGiftCards.length) {
        setHideCarouselArrows(true)
      } else {
        setHideCarouselArrows(false)
      }
    }
    if (element) {
      containerSize = element.getBoundingClientRect().width
    }
    else {
      containerSize = deviceWidth
    }
    // compact carousel is used in only add gift card details component
    if (compactCarousel) {
      if (containerSize >= 1250) {
        setNumOfSlidesToShow(6)
        setSlidesToScroll(4)
      } else
        if (containerSize >= 1024 && containerSize < 1250) {
        setNumOfSlidesToShow(6.5)
        setSlidesToScroll(4)
      } else if (containerSize >= 800 && containerSize < 1024) {
        setNumOfSlidesToShow(5.8)
        setSlidesToScroll(4.5)
      } else if (containerSize > 660 && containerSize < 800) {
        setNumOfSlidesToShow(3.8)
        setSlidesToScroll(2.8)
      } else if (containerSize > 500 && containerSize <= 660) {
        setNumOfSlidesToShow(4)
        setSlidesToScroll(2)
      } else if (containerSize > 300 && containerSize <= 500) {
        setNumOfSlidesToShow(2.9)
        setSlidesToScroll(2.2)
      }
    }
    else {
      if (carouselTrack && carouselItem) {
        let slidesCount = (carouselTrack.clientWidth - 100) / carouselItem.clientWidth
        setNumOfSlidesToShow(slidesCount)
        setSlidesToScroll(slidesCount - 1)
      }
    }

  }, [deviceWidth])

  return (
    <>
      {deviceWidth > 750 &&
        <div id="compactCarouselWrapper">
          <Carousel
            show={numOfSlidesToShow}
            slide={slidesToScroll}
            transition={0.5}
            hideArrows={hideCarouselArrows ? true : false}
            leftArrow={<IconArrowLeft className={`custom-arrow-buttons carousel-left-arrow ${compactCarousel ? "compact-arrows" : ""} ${hideCarouselArrows ? "disableArrows" : ""}`} />}
            rightArrow={<IconArrowRight className={`custom-arrow-buttons carousel-right-arrow ${compactCarousel ? "compact-arrows" : ""} ${hideCarouselArrows ? "disableArrows" : ""}`} />}
            responsive={false}
            infinite={false}
            dynamic={true}
            swiping={false}
          >
            {allGiftCards.map(gc =>
              <React.Fragment key={gc.id}>

                <GiftCardSlide isQuickSelected={selectedGiftCard ? true : false} selectedGiftCard={selectedGiftCard || null} giftCard={gc} allGiftCards={allGiftCards} compactCarouselSlide={compactCarousel} />
              </React.Fragment>)}
          </Carousel>
        </div >
      }
      {deviceWidth <= 750 &&
        <GiftCardCarousel slidesData={allGiftCards} isCompactCarousel={false} />
      }
    </>
  )
};
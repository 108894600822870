import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AppLabelsContext from "../../App/AppLabelsContext";
import { ReactComponent as IconX } from "../../_common/icons/IconX.svg";
import { ReactComponent as IconArrowBack } from "../../_common/icons/IconArrowBack.svg";
import "./LoginRegisterBackButton.css";

export const LoginRegisterBackButton = ({ goToDashboard, isCloseButton }) => {
  const history = useHistory();
  const appLabels = useContext(AppLabelsContext);
  const handleClick = () => {
    const pathname = history.location.pathname;

    if (pathname === "/login-with-temporary-password" || pathname === "/login-register") {
      // Back to Register or Login choice
      history.push("/");
    } else if (goToDashboard) {
      history.push("/dashboard");
    } else {
      history.goBack();
    }
  };

  return (
    <button
      onClick={handleClick}
      className="button button--go-back"
      aria-label={
        isCloseButton ? appLabels["general"]["close-modal"] : appLabels["general"]["go-back"]
      }
      type="button"
    >
      {isCloseButton ? <IconX /> : <IconArrowBack />}
    </button>
  );
};

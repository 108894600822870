import { callAPI } from "../_common/Api";

// used to get total for gift cards in cart
export const CallApi1013 = async (
  skin,
  eCertOrderInfo,
  language,
  promoCode = "",
  loginToken = ""
) => {

  return callAPI(skin, "dc_1013", {
    params: [
      language,
      "1013",
      "mqid",
      "mqpass",
      eCertOrderInfo,
      promoCode, // promo offer code
      loginToken || "", // customer login
      "", // customer password
      "", // 3rd party discount id
      "", // bulk order
      "", //email address
    ],
    id: "1013",
  });
};

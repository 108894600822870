export const fetchText = async (path) => {
  try {
    const headers = new Headers();

    // force browser to check the server to see if the file is different from the file it already has in the cache
    headers.append("Cache-Control", "no-cache");

    const init = {
      headers: headers,
    };

    const res = await fetch(path, init);
    const data = await res.text();

    // Make sure to skip the fallback HTML result
    return data.toLowerCase().includes("<!doctype html") ? "" : data;
    //
  } catch (error) {
    // Fetch error
    console.error(`Fetch error | ${error}`);
  }
};

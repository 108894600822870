import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// Hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helpers
import { toDollars } from "../../_common/helpers";

// Contexts
import BrandingContext from "../../App/BrandingContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";

// UI Components
import { LoadingSpinner } from "../../_common/LoadingSpinner";

// Assets
import { ReactComponent as IconStar } from "../../_common/icons/IconStar.svg";

// CSS
import "./DashboardRewards.css";

export const DashboardRewards = ({ userGiftCards }) => {
  const deviceWidth = useWindowSize().width;

  const merchantConfigContext = useContext(MerchantConfigContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);
  const brandingContext = useContext(BrandingContext);

  const skin = merchantConfigContext.skin;

  const secondaryColor = brandingContext["secondary-colour"];

  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    const storedActiveLoyaltyCard = JSON.parse(
      sessionStorage.getItem(skin + "__activeLoyaltyCard")
    );

    if (storedActiveLoyaltyCard) setActiveCard(storedActiveLoyaltyCard);
    else if (userGiftCards) setActiveCard(userGiftCards[0]);
  }, []);

  return (
    <section className="dashboard__module dashboard__rewards-module ">
      <div className="dashboard__module-main">
        {userGiftCards && activeCard ? (
          <>
            <div className="rewards-module__balance-and-my-rewards-link">
              {deviceWidth < 660 && (
                <div className="rewards-module__row">
                  <span className="rewards-module__balance-label-header">
                    {" "}
                    {appLabels["loyalty"]["my-rewards"]}
                  </span>
                  {deviceWidth < 660 && (
                    <Link
                      to="/my-rewards"
                      className="link--section-header rewards-module__my-rewards-link"
                      style={{ color: secondaryColor }}
                    >
                      {appLabels["gift-card"]["see-all"]}
                    </Link>
                  )}
                </div>
              )}

              <div
                className={`${
                  deviceWidth < 660 ? "rewards-module__row" : "rewards-module__column"
                }`}
              >
                {activeCard.points > 0 && (
                  <div
                    className={`${
                      deviceWidth < 660 ? "rewards-module__column" : "rewards-module__row"
                    }`}
                  >
                    <span className="rewards-module__balance-label rewards-module__balance-label-rewards">
                      {appLabels["loyalty"]["reward-balance"]}
                    </span>

                    <span className="rewards-module__balance">
                      {activeCard.points}
                      <IconStar aria-hidden="true" />
                    </span>
                  </div>
                )}

                <div
                  className={`${
                    deviceWidth < 660 ? "rewards-module__column" : "rewards-module__row"
                  }`}
                  style={
                    deviceWidth < 600 && activeCard.points > 0 ? { alignItems: "flex-end" } : {}
                  }
                >
                  <span className="rewards-module__balance-label">
                    {appLabels["general"]["gift-card"]}
                  </span>

                  <span
                    className="rewards-module__balance rewards-module__balance-money"
                    style={deviceWidth < 600 ? { marginRight: 0 } : {}}
                  >
                    {toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      activeCard.balance,
                      appLanguage
                    )}
                  </span>
                </div>
              </div>

              {deviceWidth >= 660 ? (
                <>
                  <div className="rewards-module__row view-rewards-link">
                    <Link
                      to="/my-rewards"
                      className="link--section-header rewards-module__my-rewards-link"
                      style={{ color: secondaryColor }}
                    >
                      {appLabels["loyalty"]["see-all-rewards"]}
                    </Link>
                  </div>
                  <div
                    className={` dashboard_rewards-module-question-container ${
                      deviceWidth < 660 ? "rewards-module__row" : "rewards-module__column"
                    }`}
                  >
                    <Link
                      to="/how-rewards-work"
                      className="link--section-header rewards-module__my-rewards-link"
                      style={{ color: secondaryColor }}
                    >
                      {appLabels["loyalty"]["how-rewards-work"]}
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="rewards-module__row view-rewards-link">
                    <Link
                      to="/how-rewards-work"
                      className="link--section-header rewards-module__my-rewards-link"
                      style={{ color: secondaryColor }}
                    >
                      {appLabels["loyalty"]["how-rewards-work"]}
                    </Link>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </section>
  );
};

import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import useWindowSize from "../hooks/useWindowSize";

export const StaticPage = ({ contentPath, pageHeading }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    async function getMarquiContent() {
      try {
        const response = await fetch(contentPath);
        const data = await response.text();

        // Remove unwanted auto-inserted RTB spaces by Marqui
        const sanitizedData = data.replace(/<div>&nbsp;<\/div>/g, "");
        if (sanitizedData) setContent(sanitizedData);
        //
      } catch (error) {
        console.error("Fetch error | Page content");
      }
    }
    getMarquiContent();
  }, []);

  const deviceWidth = useWindowSize().width;

  return (
    <>
      {deviceWidth >= 660 ? (
        <div className="desktop-container">
          <h1>{pageHeading}</h1>
          {content && <div className="content-from-marqui-rtb">{ReactHtmlParser(content)}</div>}
        </div>
      ) : (
        <>
          {!!content && <div className="content-from-marqui-rtb">{ReactHtmlParser(content)}</div>}
        </>
      )}
    </>
  );
};

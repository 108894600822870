import localforage from "localforage";
import { callMarquiPaymentAPI } from "../_common/Api";
import { castToApiObject } from "./giftCardHelpers";
import he from "he";

// used to place order for gift cards
export const CallApiDCPlaceorder = async (
  skin,
  language,
  gcCartItems,
  orderTotal,
  activeType,
  formValues = {},
  storedCreditCardId = null,
  isRegularForm = false,
  isLoggedIn = false,
  loginToken = "",
  generalLabels,
  isTimePickerEnabled
) => {
  let customerInfo = null;
  let promoOfferCode = null;
  const ccType = activeType ? activeType.toUpperCase() : "";

  let ccNumber = "";
  let ccName = "";
  let ccExpiryMonth = "";
  let ccExpiryYear = "";
  let cvv = "";
  let ccSave = "";

  if (!storedCreditCardId || isRegularForm) {
    ccNumber = formValues["credit-card-number"].replace(/\s/g, "");
    ccName = formValues["credit-card-name"];

    const ccExpiry = formValues["credit-card-expiry"];
    ccExpiryMonth = ccExpiry.split("/")[0];
    ccExpiryYear = "20" + ccExpiry.split("/")[1];
    cvv = formValues["credit-card-cvv"];
    storedCreditCardId = "";
    ccSave =
      document.getElementById("save-credit-card") &&
      document.getElementById("save-credit-card").checked;
  }

  if (storedCreditCardId) {
    cvv = formValues["credit-card-cvv"];
  }

  const address1 = formValues["first-address"] || "";
  const address2 = formValues["second-address"] || "";
  const city = formValues["city"] || "";
  const province = formValues["province"] || "";
  const country = formValues["country"] || "";
  const postal = formValues["postal"] || "";

  await localforage.getItem(skin + "__gcCustomerInfo").then((custInfo) => {
    if (custInfo) {
      customerInfo = custInfo;
    } else {
      customerInfo = { phone: "", email: "" };
    }
  });
  await localforage.getItem(skin + "__gcStoredPromoCode").then((storedPromoCode) => {
    if (storedPromoCode) {
      promoOfferCode = storedPromoCode;
    }
  });
  let itemsList = [];
  for (let i = 0; i < gcCartItems.length; i++) {
    let formattedItem = castToApiObject(gcCartItems[i], isTimePickerEnabled);
    itemsList.push(formattedItem);
  }

  const placeOrderData = await callMarquiPaymentAPI(skin, "placeorder", {
    params: [
      skin, // merchant name
      "consumer",
      language, // language
      orderTotal, //amount
      "", // tm session id
      "ECERT", // order type
      itemsList, // shipping_order_ref or cart items formatted
      loginToken, //customer login
      "", // customer password
      promoOfferCode || "", // promo offer code
      "", // scene card number
      "CREDITCARD", // payment type
      storedCreditCardId || "", // cc_id
      ccType || "VISA", // cc_type, double check this with reular order placement
      ccNumber || "", // cc_number
      ccName || "", // cc_name
      cvv || "", // cc_security
      "", // cc_issue
      ccExpiryMonth || "", // cc_expiry_month
      ccExpiryYear || "", // cc_expiry_year
      "", // cc_start_month
      "", // cc_start_year
      address1 || "", // cc_address1
      address2 || "", // cc_address2
      city || "", // cc_city
      province || "", // cc_province
      country || "", // cc_country
      postal || "", // cc_postal
      customerInfo.email || "", // cc_email
      customerInfo.phone || "", // cc_phone
      "", // pa_res
      "", // return_url
      "", // cancel_url
      "f", // bulk ecert
      "", // pay_dolloar_success_url
      "", // pay_dolloar_error_url
      "", //pay_dollar fail url
      "", // unique_pay_id
      "", // order_payment_ref
      "", // third_party_discount_val
      "", // customer_order_ref
      "", // wechat_oauth_id
      "", // use_message_for_all_cards
      "", // require_confirmation
      "", // pickup_template
      "", // payment_gift_card
      window.location.origin + window.location.pathname + "#/gift-card/order-confirmation", // url_3ds_success
      window.location.origin + window.location.pathname + "#/gift-card/payment?3dsecure=rejected", // url_3ds_failure
      "", //excel_formatted_file
      "", // add_card_to_customer _account
      "", // uptix_order
      ccSave ? "t" : "f", // save_cust_cc
      storedCreditCardId ? "t" : "f", //use_cust_cc
    ],
    id: "dc_placeorder",
  });
  if (placeOrderData.result.I0) {
    /* successfully placed an order */
    return {
      isPlaced: true,
      orderId: placeOrderData.result.I0,
      pointsIssued: placeOrderData.result.I10,
      mpiForm: !!placeOrderData.result.I9 ? placeOrderData.result.I9 : null,
    };
  } else {
    /* failed to place an order */

    return {
      isPlaced: false,
      error: `${generalLabels["error-code"]}: ${placeOrderData.error.code}. ${he.decode(
        placeOrderData.result.message
      )}`,
    };
  }
};

import { callAPI } from "../../_common/Api";

export const updateAPICustomerPassword = async (
  skin,
  loginEmail,
  currentPassword,
  newPassword,
  language
) => {
  const api953Data = await callAPI(skin, "dc_953", {
    params: [language, "953", "mqid", "mqpass", loginEmail, currentPassword, newPassword],
    id: "953",
  });

  if (api953Data.result.I0) {
    return api953Data.result.I2;
  } else {
    return { error: api953Data.result.message };
  }
};

export const capitalizeWords = (string) => {
  let capitalized = "";
  let tempArray = [];
  const words = string.toLowerCase().split(" ");

  words.forEach((word) => {
    tempArray.push(word.charAt(0).toUpperCase() + word.slice(1));
  });

  capitalized = tempArray.join(" ");
  return capitalized;
};

export const removeAllSpaces = (string) => {
  return string.replace(/\s/g, "");
};

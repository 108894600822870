export const getSubcategoryList = (foodMenu, categoryId) => {
  return categoryId === null || categoryId.length === 0
    ? {}
    : foodMenu.apiData[`category-${categoryId}`].subcategories;
};

/** Looks for isSelected Key within an addon/modifier group to determine the number of selected items within a group  */
export const checkNumberOfSelectedItemsInOptionsGroup = (optionsGroup) => {
  let selectedOptions = 0;
  Object.keys(optionsGroup.items).forEach((optionsKey) => {
    if (optionsGroup.items[optionsKey].isSelected) {
      selectedOptions++;
    }
  });
  return selectedOptions;
};

/** Looks for isSelected Key within an addon/modifier group to get the selected items within a group  */
export const getSelectionOptionInOptionsGroup = (optionsGroup) => {
  let selectedOptions = {};
  Object.keys(optionsGroup.items).forEach((optionsKey) => {
    if (optionsGroup.items[optionsKey].isSelected) {
      selectedOptions[optionsKey] = optionsGroup.items[optionsKey];
    }
  });
  return selectedOptions;
};

export const isOptionGroupMandatory = (optionGroup) => {
  if (parseInt(optionGroup.minQuantity) >= 1) {
    return true;
  } else {
    return false;
  }
};

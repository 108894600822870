// Libraries
import React, { useContext, useEffect, useState } from "react";
import reactStringReplace from "react-string-replace";

// Custom hooks
import { toDollars } from "../helpers";

// Contexts
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import AppLabelsContext from "../../App/AppLabelsContext";

export const useAutoReloadScheduleText = (autoReloadSchedule) => {
  const [autoReloadScheduleText, setAutoReloadScheduleText] = useState("");

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);

  useEffect(() => {
    if (autoReloadSchedule && autoReloadSchedule.isActive) {
      const reloadAmount = formatAmountForDisplay(autoReloadSchedule.amount);

      let fullText;
      let replacedText;

      if (autoReloadSchedule.threshold) {
        const thresholdAmount = formatAmountForDisplay(autoReloadSchedule.threshold);
        fullText = appLabels["account"]["auto-reload-schedule-with-threshold"];
        replacedText = replaceStringWithJSX(fullText, "[threshold-amount]", thresholdAmount);
        //
      } else if (autoReloadSchedule.frequency === "weekly") {
        fullText = appLabels["account"]["auto-reload-schedule-weekly"];

        replacedText = replaceStringWithJSX(
          fullText,
          "[frequency]",
          appLabels["form"]["weekly"].toLowerCase()
        );
        //
      } else if (autoReloadSchedule.frequency === "monthly") {
        fullText = appLabels["account"]["auto-reload-schedule-monthly"];

        replacedText = replaceStringWithJSX(
          fullText,
          "[frequency]",
          appLabels["form"]["monthly"].toLowerCase()
        );
      }

      replacedText = replaceStringWithJSX(
        replacedText || fullText,
        "[auto-reload]",
        appLabels["account"]["auto-reload"]
      );

      replacedText = replaceStringWithJSX(replacedText, "[reload-amount]", reloadAmount);

      setAutoReloadScheduleText(replacedText);
    }
    else
    {
      setAutoReloadScheduleText("");
    }
  }, [autoReloadSchedule]);

  const replaceStringWithJSX = (string, matchSubstring, content) => {
    return reactStringReplace(string, matchSubstring, (match, i) => (
      <strong key={match + i}>{content}</strong>
    ));
  };

  const formatAmountForDisplay = (amount) => {
    return toDollars(
      appSettings["currency-symbol"],
      appSettings["currency-symbol-side"],
      amount,
      appLanguage
    );
  };

  return autoReloadScheduleText;
};

// Libraries
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";

// Helper functions
import { jsonCopy, toDollars } from "../_common/helpers";

// API helper functions
import { callAPI } from "../_common/Api";
import { getAPIFullCardNumber } from "../Dashboard/DashboardRewards/apiHelpers/getAPIFullCardNumber";
import { getAPICardBalance } from "../OnlineOrdering/Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPICardBalance";

// Contexts
import UserRoleContext from "../App/UserRoleContext";
import AppLanguageContext from "../App/AppLanguageContext";
import AppLabelsContext from "../App/AppLabelsContext";
import AppSettingsContext from "../App/AppSettingsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";

// UI components
import { AppHeader } from "../App/AppHeader/AppHeader";
import { AppDesktopHeader } from "../App/AppHeader/AppDesktopHeader";
import { Form } from "../_common/Form/Form";
import { FormInput } from "../_common/Form/FormInput";
import { FormSelect } from "../_common/Form/FormSelect";
import { FormReCAPTCHA } from "../_common/Form/FormReCAPTCHA";
import { AdditionalDisclaimer } from "../_common/AdditionalDisclaimer";
import { DialogModal } from "../_common/DialogModal/DialogModal";

// Assets
import { ReactComponent as IconGoldStar } from "../_common/icons/IconGoldStar.svg";
import { ReactComponent as IconCheck } from "../_common/icons/IconCheck.svg";

// CSS
import "./TransferFunds.css";

export const TransferFunds = (props) => {
  const {
    rewardCards,
    //setReloadScreen,
    setSelectedRewardsCard,
    selectedRewardCard,
    apiMerchantRewards,
  } = props;

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const isPSDIndicator = merchantConfigContext.merchant.I41;
  const isReCaptcha = merchantConfigContext.merchant.I26 === "t";
  const isLoyaltyEnabled = cws5ModuleContext.isLoyaltyEnabled;

  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  const history = useHistory();
  const deviceWidth = useWindowSize().width;

  const [apiError, setAPIError] = useState(null);
  const [isAPISubmitting, setIsAPISubmitting] = useState(null);
  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);

  const [isTransferSuccessful, setIsTransferSuccessful] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  let reformattedRewards = [];
  apiMerchantRewards.forEach((apiReward) => {
    const reformattedReward = {
      id: apiReward[0],
      name: apiReward[1],
      description: apiReward[2],
      price: parseInt(apiReward[3]),
      image: apiReward[4] || false,
      typeCode: apiReward[5],
      typeName: apiReward[6],
    };

    reformattedRewards.push(reformattedReward);
  });
  const showCardPoints = !!reformattedRewards.filter(
    (reward) => reward.typeCode === "6" || reward.typeCode === "7"
  ).length;

  const balanceTransfer = async (formData) => {
    const formValues = {};
    formData.forEach((formField) => (formValues[formField.name] = formField.value));

    const fromGivexNumber = formValues["giftcard-number"].replace(/ /g, "");
    const toGivexNumber = formValues["transfer-funds__card-number-to"];

    setIsAPISubmitting(true);
    getAPIFullCardNumber(skin, loginToken, toGivexNumber, appLanguage).then((apiFullCardNumber) => {
      if (apiFullCardNumber) {
        if (apiFullCardNumber.status === "expiredLoginToken") {
          setIsAPISubmitting(false);
          userRoleContext.handleLoginTokenExpiration();
        } else {
          callAPI(skin, "dc_910", {
            params: [
              appLanguage,
              "910",
              "mqid",
              "mqpass",
              fromGivexNumber, //Givex number /Ticket (From)
              apiFullCardNumber, //Givex number /Ticket (To)
              "", //security code (optional)
              "", //comments (optional)
            ],
            id: "910",
          })
            .then((data910) => {
              if (data910) {
                setIsAPISubmitting(false);
                if (data910.result.I0) {
                  setIsAPISubmitValid(true);
                  const oldBalance = parseFloat(selectedRewardCard.balance);
                  const newMoneyBalance = data910.result.I5;

                  const amountAdded = newMoneyBalance - oldBalance;

                  const newPointBalance = data910.result.I6;
                  const pointsWhereTransferred = data910.result.I3 !== "0";
                  const currencyCode = data910.result.I8;

                  const maskedNumber = selectedRewardCard.maskedNumber;

                  const toTransactionNumber = data910.result.I2;

                  //TODO: show points balance only if it has changed
                  //TODO: calculate how much was addded

                  setSuccessMessage(
                    <div>
                      <div className="transfer-funds__success-message-header-wrapper">
                        <div>
                          <h2 className="transfer-funds__success-message-header">
                            <span>
                              {" "}
                              <IconCheck />
                              {appLabels["account"]["balance-transfer-success-message"]}
                            </span>
                          </h2>
                        </div>
                      </div>

                      <p className="transfer-funds__success-message-reward-detail">
                        {`              
                          ${toDollars(
                            appSettings["currency-symbol"],
                            appSettings["currency-symbol-side"],
                            amountAdded,
                            appLanguage
                          )} 
                          ${appLabels["loyalty"]["was-added-to-card"].replace(
                            "[card-number]",
                            ` ${maskedNumber}`
                          )}  
                      `}
                      </p>

                      <p className="transfer-funds__success-message-reward-detail">
                        <>
                          {`
                            ${
                              appLabels["loyalty"]["your-new-card-balance"].split(
                                "[card-balance]"
                              )[0]
                            } 
                            ${toDollars(
                              appSettings["currency-symbol"],
                              appSettings["currency-symbol-side"],
                              newMoneyBalance,
                              appLanguage
                            )}
                          `}

                          {pointsWhereTransferred && (
                            <>
                              <br />
                              <span>
                                {appLabels["account"]["balance-transfer-new-points-balance"]}:
                                {Number(newPointBalance)}
                                <IconGoldStar />
                              </span>
                            </>
                          )}
                        </>
                      </p>

                      <hr />
                      <i className="transfer-funds__success-message-reward-transaction">
                        {appLabels["loyalty"]["transaction-reference-number"]}:{" "}
                        {toTransactionNumber}
                      </i>
                    </div>
                  );
                  setIsTransferSuccessful(true);

                  let tempSelectedRewardCard = jsonCopy(selectedRewardCard);
                  tempSelectedRewardCard.points = Number(newPointBalance);
                  tempSelectedRewardCard.balance = parseFloat(newMoneyBalance);
                  tempSelectedRewardCard.displayBalance =
                    toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      newMoneyBalance,
                      appLanguage
                    ) +
                    " " +
                    currencyCode;

                  setSelectedRewardsCard(tempSelectedRewardCard);
                  sessionStorage.setItem(
                    skin + "__activeLoyaltyCard",
                    JSON.stringify(tempSelectedRewardCard)
                  );
                } else {
                  setAPIError(data910.result.message);
                  setIsAPISubmitValid(false);

                  setTimeout(() => {
                    setIsAPISubmitValid(null);
                  }, 2000);
                }
              }
            })
            .catch((error) => {
              setIsAPISubmitting(false);
              console.error(error);
            });
        }
      }
    });
  };

  const updateSelectedRewardCard = (select) => {
    const card = rewardCards.filter((card) => card.serial === select.value.split("-")[1])[0];
    setSelectedRewardsCard(card);
    sessionStorage.setItem(skin + "__activeLoyaltyCard", JSON.stringify(card));
  };

  const [cartData, setCardData] = useState(null);
  const checkBalance = () => {
    var elements = document.getElementById("form--transfer-funds").elements;
    var formData = {};
    for (var i = 0; i < elements.length; i++) {
      var item = elements.item(i);
      formData[item.name] = item.value;
    }

    let givexNumber = formData["giftcard-number"].replace(/ /g, "");
    let pin = "";
    let reCAPTCHA = formData["g-recaptcha-response"];
    const psdIndicator = isPSDIndicator || "";

    getAPICardBalance(
      skin,
      givexNumber,
      pin,
      psdIndicator,
      reCAPTCHA,
      appLabels["form"],
      appSettings["currency-symbol"],
      appSettings["currency-symbol-side"],
      appLanguage
    ).then((apiGiftCard) => {
      if (typeof apiGiftCard === "string") {
        //some sort of error here
        setAPIError(apiGiftCard);
        setTimeout(() => {
          setAPIError("");
        }, 2000);
      } else {
        //successfull resposne
        setCardData(apiGiftCard);
      }
    });
  };

  return (
    <>
      {rewardCards && !rewardCards.error && (
        <div className="transfer-funds__container">
          {deviceWidth < 660 ? (
            <AppHeader
              pageHeading={appLabels["account"]["transfer-funds"]}
              isHomeLink={true}
              isBackButton={true}
              /* backButtonDestination={
                window.location.href.includes("paymentmethod")
                  ? "/account/payment-methods"
                  : "/pay-in-store"
              }*/
            />
          ) : (
            <AppDesktopHeader />
          )}
          <div className="transfer-funds__form_wrapper">
            <Form
              submitForm={balanceTransfer}
              resetAPIError={() => setAPIError("")}
              submitButtonText={appLabels["account"]["transfer-funds"]}
              submitButtonSuccessText={appLabels["account"]["funds-transferred"]}
              id="form--transfer-funds"
              className="form--transfer-funds"
              isAPISubmitValid={isAPISubmitValid}
              isAPISubmitting={isAPISubmitting}
              submitAPIError={apiError}
              isFAB={false}
            >
              <FormInput
                isMaskedInput={false}
                type="text"
                label={appLabels["order"]["gift-card-number"]}
                id="input--transfer-funds__card-number-from"
                name="giftcard-number"
                isRequired={true}
                isSmall={false}
              />
              {isReCaptcha && <FormReCAPTCHA name="reCAPTCHA" isRequired={true} />}
              <div className="transfer-funds__check-balance-button-wrapper">
                <div>
                  {cartData && (
                    <>
                      <span>{cartData.displayBalance}</span>
                      {showCardPoints && (
                        <>
                          {" | "}
                          <span>
                            {cartData.points}
                            <IconGoldStar />
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
                <button
                  className="button button--primary button--primary-small button--primary-with-border"
                  onClick={checkBalance}
                  type="button"
                  aria-label={appLabels["order"]["check-balance"]}
                >
                  {appLabels["order"]["check-balance"]}
                </button>
              </div>
              <FormSelect
                isMaskedInput={false}
                type="number"
                label={
                  isLoyaltyEnabled
                    ? appLabels["account"]["balance-transfer-to-card-number"]
                    : appLabels["account"]["balance-transfer-to-gift-card"]
                }
                id="input--transfer-funds__card-number-to"
                name="transfer-funds__card-number-to"
                isSmall={false}
                isSkipEmptyOption={true}
                isRequired={true}
                defaultValue={
                  selectedRewardCard ? selectedRewardCard.iso + "-" + selectedRewardCard.serial : ""
                }
                callBack={updateSelectedRewardCard}
              >
                {rewardCards.map((rewardsCard) => (
                  <option
                    key={rewardsCard.maskedNumber}
                    data-card={JSON.stringify(rewardsCard)}
                    value={rewardsCard.iso + "-" + rewardsCard.serial}
                    text={rewardsCard.maskedNumber}
                  />
                ))}
              </FormSelect>
              <AdditionalDisclaimer
                disclaimer={appLabels["account"]["balance-transfer-disclaimer"]}
                styleObject={
                  deviceWidth >= 660
                    ? { marginTop: "3em", marginBottom: "3em" }
                    : { marginTop: "1.5em" }
                }
              />
            </Form>
          </div>
        </div>
      )}
      {isTransferSuccessful && (
        <DialogModal
          isHTMLContent={true}
          resetRemoveDialog={() => {
            setIsTransferSuccessful(null);
            //setReloadScreen(true);
            if (history.location.hash === "#paymentmethod") history.goBack();
            else history.push("/pay-in-store#refresh");
          }}
          message={successMessage}
        />
      )}
    </>
  );
};

import React, { useContext, useEffect, useState } from "react";
import localforage from "localforage";
import useWindowSize from "../../../_common/hooks/useWindowSize";

/** Contexts */
import AppLabelsContext from "../../../App/AppLabelsContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

/** Helpers */
import { isEmailValid } from "../../../_common/Form/helpers/formValidation";

/** UI Components */
import { Form } from "../../../_common/Form/Form";
import { FormInput } from "../../../_common/Form/FormInput";

/** CSS */
import "./ConfirmationEmail.css";

const ConfirmationEmail = () => {
  const merchantConfig = useContext(MerchantConfigContext);
  const appLabels = useContext(AppLabelsContext);

  const deviceWidth = useWindowSize().width;

  const [formError, setFormError] = useState("");
  const [customerInfo, setCustomerInfo] = useState(null);
  useEffect(() => {
    if (merchantConfig.skin) {
      localforage.getItem(merchantConfig.skin + "__customerInfo").then((storedCustomerInfo) => {
        if (storedCustomerInfo) setCustomerInfo(storedCustomerInfo);
        else setCustomerInfo(false);
      });
    }
  }, [merchantConfig]);

  const handleOnChange = (e) => {
    setFormError("");
  };

  const handleOnBlur = (target) => {
    const input = target;
    const value = input.value;

    if (isEmailValid(value)) {
      const tempCustomerInfo = { ...customerInfo };
      tempCustomerInfo.email = value;

      localforage.setItem(merchantConfig.skin + "__customerInfo", tempCustomerInfo);
    } else {
      setFormError(appLabels["form"]["invalid-email-error"]);
    }
    document.getElementById("form--confirmation-email__submit-button").click();

    if (!!document.getElementsByClassName("cart-content__desktop-checkout-buttons").length) {
      document.getElementsByClassName("cart-content__desktop-checkout-buttons")[0].style.position =
        "fixed";
    }
  };

  const handleOnFocus = () => {
    if (
      !!document.getElementsByClassName("cart-content__desktop-checkout-buttons").length &&
      deviceWidth < 600 &&
      navigator.userAgent.toLowerCase().includes("android")
    ) {
      document.getElementsByClassName("cart-content__desktop-checkout-buttons")[0].style.position =
        "relative";
    }
  };

  return (
    <>
      {customerInfo !== null && (
        <div className="confirmation-email__main-wrapper">
          <div className="confirmation-email__container">
            {/*<div className="confirmation-email__header-wrapper">
              <h3>{appLabels["order"]["confirmation-email"]}</h3>
      </div>*/}

            <div className="confirmation-email__body-wrapper">
              <Form
                id="form--confirmation-email"
                //submitButtonText={appLabels["order"]["order-contact-confirm"]}
                //submitButtonSuccessText={appLabels["order"]["order-contact-confirming"]}
                //submitForm={collectCustomerInfo}
                isAllowEmptySubmit={true}
                submitButtonCustomClass="button--primary"
                isBufferBeforeNavigationDisabled={true}
                submitButtonWrapperClass="button-container--single-relative"
                hideSubmitButton={false}
                submitAPIError={formError}
              >
                <FormInput
                  type="text"
                  label={appLabels["order"]["confirmation-email-blurb"]}
                  id="input--email"
                  placeholder="email@example.com"
                  name="email"
                  isRequired={false}
                  handleInputChangeFromParent={(e) => {
                    handleOnChange(e);
                  }}
                  onInputBlur={(e) => {
                    handleOnBlur(e);
                  }}
                  defaultValue={customerInfo && customerInfo.email ? customerInfo.email : ""}
                  autocomplete={customerInfo && customerInfo.email ? "on" : "off"}
                  onFocusAction={() => {
                    handleOnFocus();
                  }}
                />
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ConfirmationEmail;

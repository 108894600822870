import { callAPI } from "../_common/Api";

export const getAPIBillingAddress = async (skin, loginToken, language) => {
  if (loginToken === "idle" || loginToken === null) {
    return;
  }

  const api950Data = await callAPI(skin, "dc_950", {
    params: [language, "950", "mqid", "mqpass", loginToken, ""],
    id: "950",
  });

  if (api950Data.result.I0) {
    const billingAddress = {
      name: api950Data.result.I7 + (api950Data.result.I9 ? " " + api950Data.result.I9 : ""),
      "first-address": api950Data.result.I12.split("||")[0],
      "second-address": api950Data.result.I12.split("||")[1],
      country: api950Data.result.I16,
      province: api950Data.result.I14,
      city: api950Data.result.I13,
      postal: api950Data.result.I17,
      phone: api950Data.result.I18,
    };

    return billingAddress;
  } else if (api950Data.status === 249 || api950Data.status === 150 || api950Data.status === 148) {
    return { status: "expiredLoginToken" };
  } else {
    return { error: api950Data.result.message };
  }
};

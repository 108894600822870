import React, { useContext, useState } from "react";

//Helpers
import { getAPIWalletDownload, getOSPlatform } from "./AddToWalletLinkHelpers";
import { jsonCopy } from "../_common/helpers";
import { getAPIFullCardNumber } from "../Dashboard/DashboardRewards/apiHelpers/getAPIFullCardNumber";

//Context
import AppLanguageContext from "../App/AppLanguageContext";
import MerchantConfigContext from "../App/MerchantConfigContext";

//UI Components
import { ReactComponent as IconAppleWalletEN } from "../_common/icons/IconAppleWalletEN.svg";
import { ReactComponent as IconAppleWalletFR } from "../_common/icons/IconAppleWalletFR.svg";
import { ReactComponent as IconAppleWalletPT } from "../_common/icons/IconAppleWalletPT.svg";
import { ReactComponent as IconGooglePayEN } from "../_common/icons/IconGooglePayEN.svg";
import { ReactComponent as IconGooglePayFR } from "../_common/icons/IconGooglePayFR.svg";
import { ReactComponent as IconGooglePayPT } from "../_common/icons/IconGooglePayPT.svg";
import { LoadingSpinner } from "../_common/LoadingSpinner";
import AppLabelsContext from "../App/AppLabelsContext";

export const AddToWalletLink = (props) => {
  const { activeLoyaltyCard, loginToken } = props;

  const appLanguage = useContext(AppLanguageContext);

  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;

  const [isCallingAPI, setIsCallingAPI] = useState(null);
  const appLabels = useContext(AppLabelsContext)

  const getAppleGoogleWalletIcon = () => {
    const platform = getOSPlatform();

    if (platform === "ios") {
      if (appLanguage === "fr") {
        return <IconAppleWalletFR aria-label={appLabels["order"]["add-to-apple-wallet"]} />;
      } else if (appLanguage === "pt") {
        return <IconAppleWalletPT aria-label={appLabels["order"]["add-to-apple-wallet"]} />;
      } else {
        return <IconAppleWalletEN aria-label={appLabels["order"]["add-to-apple-wallet"]} />;
      }
    } else if (platform === "android") {
      if (appLanguage === "fr") {
        return <IconGooglePayFR aria-label={appLabels["order"]["add-to-google-pay"]} />;
      } else if (appLanguage === "pt") {
        return <IconGooglePayPT aria-label={appLabels["order"]["add-to-goole-pay"]} />;
      } else {
        return <IconGooglePayEN aria-label={appLabels["order"]["add-to-google-pay"]} />;
      }
    }
  };

  const onWalletIconClick = () => {
    setIsCallingAPI(true);
    const tempActiveLoyaltyCard = jsonCopy(activeLoyaltyCard);
    //get card full number
    getAPIFullCardNumber(
      skin,
      loginToken,
      tempActiveLoyaltyCard.iso + "-" + tempActiveLoyaltyCard.serial,
      appLanguage
    ).then((fullCardNumber) => {
      if (fullCardNumber) {
        //get the card wallet url
        getAPIWalletDownload(skin, appLanguage, fullCardNumber, getOSPlatform()).then(
          (walletResponse) => {
            setIsCallingAPI(false);
            window.open(walletResponse, "_top");
          }
        );
      }
    });
  };

  return (
    <>
      {isCallingAPI ? (
        <LoadingSpinner />
      ) : (
        <button
          type="button"
          className="payment-method-section__details-wallet-link"
          onClick={onWalletIconClick}
        >
          {getAppleGoogleWalletIcon()}
        </button>
      )}
    </>
  );
};

// Libraries
import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, Link } from "react-router-dom";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { LoadingSpinner } from "../_common/LoadingSpinner";
import { HeroSection } from "../_common/DesktopLayout/HeroSection";
import BrandingContext from "../App/BrandingContext";

import { callAPI } from "../_common/Api";
import MerchantConfigContext from "../App/MerchantConfigContext";
import AppLanguageContext from "../App/AppLanguageContext";
import { AvailableGiftCards } from "./AvailableGiftCards";
import { useLocation } from "react-router-dom";
import { AvailableCategorizedGiftCards } from "./AvailableCategorizedGiftCards";
import { useHistory } from "react-router-dom";
import GCAvailableContext from "./GiftCardsAvailableContext";
import { GCRecentOrders } from "./GCOrderHistory/GCRecentOrders";
import { GCCompletedOrderDetails } from "./GCOrderHistory/GCCompletedOrderDetails";
import AppLabelsContext from "../App/AppLabelsContext";

export const GiftCard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [availableGiftCards, setAvailableGiftCards] = useState([]);
  const [giftCardCategorizedList, setGiftCardCategorizedList] = useState([]);
  const [onlyOneCategory, setOnlyOneCategory] = useState(false);
  const [onlyOneCategoryName, setOnlyOneCategoryName] = useState();
  const classes = useStyles();

  const gcAvailable = useContext(GCAvailableContext);
  const branding = useContext(BrandingContext);
  const deviceWidth = useWindowSize().width;
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const { skin } = merchantConfigContext;
  const location = useLocation();
  const history = useHistory();

  let categorizedGiftCardsList = {};
  const callApi1005 = async () => {
    setIsLoading(true);
    await callAPI(skin, "dc_1005", {
      params: [appLanguage, "1005", "mqid", "mqpass", "consumer"],
      id: "1005",
    }).then((data) => {
      if (data.result.I2) {
        gcAvailable.updateGCAvailable(data.result.I2);
        let listOfGiftCards = [];
        data.result.I2.forEach((gc) => {
          let tempGiftCard = {
            id: gc[0],
            title: gc[1],
            reference: gc[2],
            giftCardType: gc[3],
            bundleSize: gc[4],
            denominationList: gc[5],
            denominationCustomizable: gc[6],
            cardFaceCustomizable: gc[7],
            orderLimit: gc[8],
            giftCardImageUrl: gc[9],
            cwsThemeId: gc[10],
            cwsThemeName: gc[11] && gc[11] !== "" ? gc[11] : "Other",
            replacementItem: gc[12],
            language: gc[13],
            description: gc[14],
          };
          if (!(tempGiftCard.cwsThemeName in categorizedGiftCardsList)) {
            categorizedGiftCardsList[tempGiftCard.cwsThemeName] = {
              id: tempGiftCard.cwsThemeName,
              name: tempGiftCard.cwsThemeName,
              data: [],
            };
          }
          if (tempGiftCard.cwsThemeName in categorizedGiftCardsList) {
            categorizedGiftCardsList[tempGiftCard.cwsThemeName].data.push(tempGiftCard);
          }

          listOfGiftCards.push(tempGiftCard);
        });

        //setAvailableGiftCards(listOfGiftCards)
        setAvailableGiftCards(listOfGiftCards);
        setGiftCardCategorizedList(categorizedGiftCardsList);
        setIsLoading(false);

        // if only one key exists then only one category exists
        if (Object.keys(categorizedGiftCardsList).length === 1) {
          let category = Object.keys(categorizedGiftCardsList)[0];
          setOnlyOneCategory(true);
          setOnlyOneCategoryName(category);
          if (location.pathname.includes("purchase")) {
            history.push({
              pathname: "/gift-card/category/" + category,
              state: {
                category: category,
                giftCardsList: categorizedGiftCardsList[category].data,
                isOnlyOneCategory: true,
              },
            });
          }
        }
      } else {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (location.state && location.state.category) {
      setAvailableGiftCards(location.state.giftCardsList);
    }

    if (gcAvailable && gcAvailable.length > 0) {
      let listOfGiftCards = [];
      let giftCards = gcAvailable;
      giftCards.forEach((gc) => {
        let tempGiftCard = {
          id: gc[0],
          title: gc[1],
          reference: gc[2],
          giftCardType: gc[3],
          bundleSize: gc[4],
          denominationList: gc[5],
          denominationCustomizable: gc[6],
          cardFaceCustomizable: gc[7],
          orderLimit: gc[8],
          giftCardImageUrl: gc[9],
          cwsThemeId: gc[10],
          cwsThemeName: gc[11] && gc[11] !== "" ? gc[11] : "Other",
          replacementItem: gc[12],
          language: gc[13],
          description: gc[14],
        };
        if (!(tempGiftCard.cwsThemeName in categorizedGiftCardsList)) {
          categorizedGiftCardsList[tempGiftCard.cwsThemeName] = {
            id: tempGiftCard.cwsThemeName,
            name: tempGiftCard.cwsThemeName,
            data: [],
          };
        }
        if (tempGiftCard.cwsThemeName in categorizedGiftCardsList) {
          categorizedGiftCardsList[tempGiftCard.cwsThemeName].data.push(tempGiftCard);
        }

        listOfGiftCards.push(tempGiftCard);
      });
      setAvailableGiftCards(listOfGiftCards);
      setGiftCardCategorizedList(categorizedGiftCardsList);
      setIsLoading(false);

      if (Object.keys(categorizedGiftCardsList).length === 1) {
        let category = Object.keys(categorizedGiftCardsList)[0];
        setOnlyOneCategory(true);
        setOnlyOneCategoryName(category);
        if (location.pathname.includes("purchase")) {
          history.push({
            pathname: "/gift-card/category/" + category,
            state: {
              category: category,
              giftCardsList: categorizedGiftCardsList[category].data,
              isOnlyOneCategory: true,
            },
          });
        }
      }
    } else {
      callApi1005();
    }
  }, []);

  /*
  This will return a tab component and has few routes to display under two tabs. All purchase routes are shown with Gift Cards Tab active, only if route includes "recent" then tab Recent Orders will be active
  */
  return (
    <div className={classes.root}>
      {deviceWidth >= 660 &&
        branding["gift-cards-banner-image"] &&
        branding["gift-cards-banner-image"] !== "" && (
          <HeroSection imageUrl={branding["gift-cards-banner-image"]} />
        )}
      <>
        <Route
          path="/gift-card"
          render={(history) => (
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
              <Tabs
                value={
                  history.location.pathname.includes("gift-card") &&
                  !history.location.pathname.includes("recent")
                    ? "/gift-card/purchase"
                    : "/gift-card/recent"
                }
                className={`${classes.tabs} gift-card-tabs`}
                variant="fullWidth"
              >
                <Tab
                  label={appLabels["gift-card"]["gift-cards"]}
                  value="/gift-card/purchase"
                  component={Link}
                  to={
                    !onlyOneCategory && !onlyOneCategoryName
                      ? "/gift-card/purchase"
                      : "/gift-card/category/" + onlyOneCategoryName
                  }
                  className={classes.tab1}
                  fullWidth
                />
                <Tab
                  label={appLabels["gift-card"]["order-history"]}
                  value="/gift-card/recent"
                  component={Link}
                  to="/gift-card/recent"
                  className={classes.tab2}
                  fullWidth
                />
              </Tabs>
            </AppBar>
          )}
        />

        <Switch>
          <Route
            exact
            path="/gift-card/purchase/"
            render={() => (
              <div>
                {!isLoading && availableGiftCards.length > 0 && (
                  <AvailableGiftCards
                    availableGiftCards={availableGiftCards}
                    giftCardCategorizedList={giftCardCategorizedList}
                  />
                )}
                {!isLoading && availableGiftCards.length === 0 && (
                  <Box
                    p={4}
                    flex="row"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <h3>{appLabels["gift-card"]["no-gift-cards-available"]}</h3>
                  </Box>
                )}
              </div>
            )}
          />

          <Route
            path="/gift-card/category/:category"
            render={() => (
              <div>
                {!isLoading && availableGiftCards.length > 0 && (
                  <AvailableCategorizedGiftCards
                    selectedCategory={history.location.pathname.slice(
                      location.pathname.lastIndexOf("/") + 1,
                      location.pathname.length
                    )}
                    categorizedGiftCardsList={giftCardCategorizedList}
                  />
                )}
                {!isLoading && availableGiftCards.length === 0 && <h4>No Gift Cards</h4>}
              </div>
            )}
          />

          <Route
            exact
            path="/gift-card/recent"
            render={() => (
              <Box p={2}>
                <Typography component={"section"}>
                  <GCRecentOrders />
                </Typography>
              </Box>
            )}
          />
          <Route
            exact
            path="/gift-card/recent/order-details/:id"
            render={() => (
              <Box p={2}>
                <Typography component={"section"}>
                  <GCCompletedOrderDetails />
                </Typography>
              </Box>
            )}
          />
        </Switch>
      </>

      {isLoading && <LoadingSpinner customClass={"recent-orders__loader"} />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiBox-root": {
      maxWidth: "1200px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "black",
      height: 4,
      maxWidth: "600px",
    },
    "& .MuiTabs-root": {
      backgroundColor: "#f5f5f5",
      textTransform: "none",
    },
    "& .MuiTab-root": {
      color: "black",
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: "black",
      fontWeight: "bold",
    },
    "& .MuiButtonBase-root": {
      maxWidth: "600px",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "center",
    },
  },
  tab1: {
    "& .MuiTab-wrapper": {
      width: "100%",
      [theme.breakpoints.up(660)]: {
        width: "50%",
        display: "inline-flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-end",
      },
    },
    whiteSpace: "nowrap",
  },
  tab2: {
    "& .MuiTab-wrapper": {
      width: "100%",
      maxWidth: "600px",
      [theme.breakpoints.up(660)]: {
        width: "50%",
        display: "inline-flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-start",
      },
    },
    whiteSpace: "nowrap",
  },
}));

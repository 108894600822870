import React, { useContext } from "react";

// UI Components
import { ReactComponent as IconCart } from "../../_common/icons/IconCart.svg";

//Contexts
import AppLabelsContext from "../../App/AppLabelsContext";
import { Link } from "react-router-dom";
import useWindowSize from "../../_common/hooks/useWindowSize";

export const GCNoRecentOrders = () => {
  const appLabels = useContext(AppLabelsContext);
  const deviceWidth = useWindowSize().width
  return (
    <section className="recent-order__no-orders-message">
      <IconCart style={{ fill: "#e8e8e8", height: "200px" }} />
      <h3 className="recent-orders__no-orders-yet-label">{appLabels["order"]["no-orders-yet"]}</h3>
      <p className="recent-orders__browse-menu-label">{"Browse Gift Cards"}</p>
      <Link
        className={`button recent-order__reorder-button button--pill-shaped buttonFilled ${deviceWidth < 660
          ? "recent-order-view-details-button"
          : "recent-order-view-details-button-desktop"
          }`}
        to={{
          pathname: `/gift-card/purchase`,
        }}
      >
        {appLabels["gift-card"]["view-gift-cards-button"]}
      </Link>
    </section>
  );
};

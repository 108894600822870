import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/** Contexts */
import AppLabelsContext from "../../../App/AppLabelsContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";
import StoreContext from "../../StoreContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import AppSettingsContext from "../../../App/AppSettingsContext";
import BillContext from "./BillContext";
import UserRoleContext from "../../../App/UserRoleContext";
import BrandingContext from "../../../App/BrandingContext";

/** Hooks */
import { useHideOrderModule } from "../../../App/useHideOrderModule";

/** Helpers */
import { calculateBillItemsSubtotal, getOrderFromTable } from "./BillHelpers";
import { sortByKey, toDollars } from "../../../_common/helpers";

/** UI Components */
import { LoadingSpinner } from "../../../_common/LoadingSpinner";
import BillHeaderDetails from "./BillHeaderDetails";
import { FAB } from "../../../_common/buttons/FAB/FAB";
import MenuBillToggle from "../MenuBillToggle";
import BillItemsGroupCollapsable from "./BillItemsGroupCollapsable";
import { ReactComponent as IconRefresh } from "../../../_common/icons/IconRefresh.svg";
import CollapsableSection from "../../../_common/components/CollapsableSection";

/** CSS */
import "./Bill.css";

const Bill = () => {
  const history = useHistory();
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const billContext = useContext(BillContext);
  const userRolContext = useContext(UserRoleContext);
  const branding = useContext(BrandingContext);

  const merchantConfig = useContext(MerchantConfigContext);
  const storeContext = useContext(StoreContext);

  const activeOrderStore = storeContext.activeOrderStore;
  const activeTableNumber = storeContext.activeOrderStoreTable;
  const skin = merchantConfig.skin;

  const billPaymentIsEnabled =
    activeOrderStore && activeOrderStore["vexilorConfig"]["get_order_from_table"] === "t";

  const { hideOrderModule } = useHideOrderModule();

  useEffect(() => {
    /** If bill payment is not enabled but user somehow lands on this page, redirect to dashboard */
    if (billPaymentIsEnabled === false) {
      history.push("/dashboard");
    }
  }, [billPaymentIsEnabled]);

  const [orderSummary1322, setOrderSummary1322] = useState(null);
  const [billItems, setBillItems] = useState(null);
  const [unproducedItems, setUnproducedItems] = useState(null);

  const [selectedBillItems, setSelectedBillItems] = useState(null);
  const updateSelectedBillItems = (newBillItems) => {
    setSelectedBillItems(newBillItems);
    const billDetails = { ...orderSummary1322 };
    delete billDetails["item_list"];
    billContext.updateBill(newBillItems, billDetails);
  };

  useEffect(() => {
    if (activeOrderStore) {
      refreshTableOrderData();
    }
  }, [activeOrderStore]);

  useEffect(() => {
    const pathName = window.location.pathname;
    sessionStorage.removeItem(pathName + "scannedQRCodeURLHash");
  }, []);

  const [apiError, setApiError] = useState(null);
  const refreshTableOrderData = () => {
    setOrderSummary1322(null);
    setSelectedBillItems(null);
    getOrderFromTable(appLanguage, skin, activeTableNumber, activeOrderStore.storeId).then(
      (apiData) => {
        if (apiData) {
          if (apiData.status === 0) {
            setLastUpdateTimeStamp(new Date());
            setOrderSummary1322(apiData.result.I2);
          } else {
            setApiError(apiData.result.message);
          }
        }
      }
    );
  };

  /** Group the item_list together based on the seat_num (items that were placed together) */
  useEffect(() => {
    if (
      orderSummary1322 &&
      orderSummary1322["item_list"] &&
      orderSummary1322["item_list"].length > 0
    ) {
      let groupedItemsList = [];

      let orderSummary1322Items = orderSummary1322["item_list"];
      orderSummary1322Items.forEach((item) => {
        item["seat_num"] = parseInt(item["seat_num"]);
      });

      const topSeatNumber = sortByKey(orderSummary1322Items, "seat_num", true)[0]["seat_num"];
      for (let i = -1; i < topSeatNumber; i++) {
        if (orderSummary1322["item_list"].filter((item) => item["seat_num"] === i + 1).length > 0) {
          groupedItemsList.push(
            orderSummary1322["item_list"].filter((item) => item["seat_num"] === i + 1)
          );
        }
      }

      setBillItems(groupedItemsList);
    } else if (
      orderSummary1322 &&
      orderSummary1322["item_list"] &&
      orderSummary1322["item_list"].length === 0
    ) {
      setBillItems([]);
    }
  }, [orderSummary1322]);

  /** Group the unproduced-online-items together based on the seat_num (items that were placed together) */
  useEffect(() => {
    if (
      orderSummary1322 &&
      orderSummary1322["unproduced_online_items"] &&
      orderSummary1322["unproduced_online_items"].length > 0
    ) {
      let groupedItemsList = [];

      let unproducedItems = orderSummary1322["unproduced_online_items"];
      unproducedItems.forEach((unproducedItem) => {
        const unproducedItemsFromSameOrder = unproducedItems.filter(
          (item) => item["online_order_id"] === unproducedItem["online_order_id"]
        );

        if (
          !JSON.stringify(groupedItemsList).includes(JSON.stringify(unproducedItemsFromSameOrder))
        ) {
          groupedItemsList.push(unproducedItemsFromSameOrder);
        }
      });

      setUnproducedItems(groupedItemsList);
    } else if (
      orderSummary1322 &&
      orderSummary1322["unproduced_online_items"] &&
      orderSummary1322["unproduced_online_items"].length === 0
    ) {
      setUnproducedItems([]);
    } else {
      setUnproducedItems(null);
    }
  }, [orderSummary1322]);

  const goToBillPaymentScreen = () => {
    if (userRolContext.status === "guest") {
      sessionStorage.setItem(skin + "__lastVisitedLink", "/online-ordering/bill-payment");
      history.push("/login-register");
    } else {
      history.push("/online-ordering/bill-payment");
    }
  };

  const [lastUpdateTimeStamp, setLastUpdateTimeStamp] = useState("");
  const formattedLastUpdateTimeStamp = (date) => {
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const [isCollapsed, setIsCollapsed] = useState(false);
  const onCheckBoxChange = (e) => {
    const isChecked = e.target.checked;

    billItems.forEach((billItemsGroup, groupIndex) => {
      const groupButton = document.getElementById(
        "billItemsCollapsable-" +
          groupIndex +
          "-" +
          billItemsGroup[0]["seat_num"] +
          "-" +
          billItemsGroup[0]["vxl_order_line_id"] +
          "-" +
          groupIndex
      );
      if (groupButton) {
        if (
          (isChecked && groupButton.checked === false) ||
          (!isChecked && groupButton.checked === true)
        ) {
          setTimeout(() => {
            groupButton.click();
          }, 100);
        }
      }
    });
  };

  /**
   *
   * @param {*} billItems list of all bill item groups
   * @returns number of all bill items
   */
  const getNumberOfAllBillItems = (billItems) => {
    let numberOfAllBillItems = 0;
    billItems.forEach((billItemsGroup) => {
      billItemsGroup.forEach((billItem) => {
        if (billItem["pay_status"] === "unpaid") {
          numberOfAllBillItems += 1;
        }
      });
    });
    return numberOfAllBillItems;
  };

  const hasSelectableItems = (billItems) => {
    let isSelectableItem = false;
    billItems.forEach((billItemsGroup) => {
      billItemsGroup.forEach((billItem) => {
        if (billItem["pay_status"] === "unpaid") {
          isSelectableItem = true;
        }
      });
    });
    return isSelectableItem;
  };

  return (
    <>
      {billPaymentIsEnabled && !hideOrderModule && <MenuBillToggle initialState="bill" />}
      {orderSummary1322 ? (
        <>
          <BillHeaderDetails
            orderSummary1322={orderSummary1322}
            onlyShowTableNumber={(billItems && billItems.length === 0) || billItems === null}
          />

          {billItems && billItems.length === 0 && unproducedItems && unproducedItems.length > 0 && (
            <p className="bill__items_blurb">{appLabels["order"]["items-not-ready-for-billing"]}</p>
          )}

          {billItems === null && unproducedItems === null && (
            <p className="bill__items_blurb">
              {appLabels["order"]["bill-page-no-order-placed-yet"]}
            </p>
          )}

          {lastUpdateTimeStamp && (
            <div className="bill__last-update-timestamp__container">
              <span className="bill__last-update-timestamp">
                {appLabels["account"]["order-status-last-update-time"].replace(
                  "[time-stamp]",
                  formattedLastUpdateTimeStamp(lastUpdateTimeStamp)
                )}
              </span>

              <button type="button" onClick={refreshTableOrderData}>
                <IconRefresh aria-hidden="true" />
                {appLabels["order"]["refresh"]}
              </button>
            </div>
          )}

          {billItems &&
            hasSelectableItems(billItems) &&
            ((billItems && !!billItems.length) ||
              (unproducedItems && unproducedItems.length > 0)) && (
              <CollapsableSection
                headerText={appLabels["order"]["select-one-bill"]}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                id={"pay-one-bill"}
                customStyle={{
                  backgroundColor: branding["primary-colour"],
                  color: branding["button-text-colour"],
                }}
                hasCheckbox={true}
                hasCheckmark={false}
                onCheckboxChange={(e) => onCheckBoxChange(e)}
                groupIndex={0}
                isCheckboxDisabled={!billItems || billItems.length === 0 ? true : false}
                isContainerDisabled={true}
                hideMinusIcon={true}
              />
            )}

          <div className="bill__items_container">
            {billItems && !!billItems.length ? (
              <>
                {billItems.map((billItemGroup, index) => (
                  <React.Fragment key={index}>
                    <BillItemsGroupCollapsable
                      billItemsGroup={billItemGroup}
                      groupIndex={index}
                      updateSelectedBillItems={updateSelectedBillItems}
                      selectedBillItems={selectedBillItems}
                      hasCheckbox={true}
                      initiallyClosed={false}
                      numberOfAllBillItems={getNumberOfAllBillItems(billItems)}
                    />
                  </React.Fragment>
                ))}

                {unproducedItems &&
                  unproducedItems.length > 0 &&
                  unproducedItems.map((billItemGroup, index) => (
                    <React.Fragment key={index}>
                      <BillItemsGroupCollapsable
                        billItemsGroup={billItemGroup}
                        groupIndex={index}
                        updateSelectedBillItems={updateSelectedBillItems}
                        selectedBillItems={selectedBillItems}
                        hasCheckbox={true}
                        initiallyClosed={false}
                        readOnlyItem={true}
                      />
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <>
                {unproducedItems &&
                  unproducedItems.length > 0 &&
                  unproducedItems.map((billItemGroup, index) => (
                    <React.Fragment key={index}>
                      <BillItemsGroupCollapsable
                        billItemsGroup={billItemGroup}
                        groupIndex={index}
                        updateSelectedBillItems={updateSelectedBillItems}
                        selectedBillItems={selectedBillItems}
                        hasCheckbox={true}
                        initiallyClosed={false}
                        readOnlyItem={true}
                      />
                    </React.Fragment>
                  ))}
              </>
            )}
          </div>

          <FAB
            id="button--pay-bill--checkout"
            isModalTrigger={true}
            buttonText={
              selectedBillItems && !!selectedBillItems.length
                ? `${appLabels["order"]["checkout"]} (${toDollars(
                    appSettings["currency-symbol"],
                    appSettings["currency-symbol-side"],
                    calculateBillItemsSubtotal(selectedBillItems),
                    appLanguage
                  )})`
                : appLabels["order"]["checkout"]
            }
            onClick={goToBillPaymentScreen}
            isBackgroundGradient={true}
            isDisabled={!selectedBillItems || selectedBillItems.length === 0}
          />
        </>
      ) : (
        <>{apiError ? <p className="bill__items_blurb">{apiError}</p> : <LoadingSpinner />}</>
      )}
    </>
  );
};
export default Bill;

import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

import AppLabelsContext from "../../App/AppLabelsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import UserRoleContext from "../../App/UserRoleContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import { callAPI } from "../../_common/Api";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { Box, Typography } from "@material-ui/core";
import "./gcOrderHistory.css"
import { formatRecentOrderDateAndTime } from "../giftCardHelpers";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { toDollars } from "../../_common/helpers";
import AppSettingsContext from "../../App/AppSettingsContext";
export const GCCompletedOrderDetails = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [orderDetails, setOrderDetails] = useState()

  const params = useParams();
  const deviceWidth = useWindowSize().width
  const appLanguage = useContext(AppLanguageContext)
  const merchantConfigContext = useContext(MerchantConfigContext)
  const skin = merchantConfigContext.skin
  const userRoleContext = useContext(UserRoleContext)
  const loginToken = userRoleContext.loginToken
  const appLabels = useContext(AppLabelsContext)
  const appSettings = useContext(AppSettingsContext)

  // 1009 is used to get details for a particular gift card order
  const callApi1009 = async () => {
    setIsLoading(true)


    await callAPI(skin, "dc_1009", {
      params: [
        appLanguage,
        "1009",
        "mqid",
        "mqpass",
        loginToken,
        "",
        params.id
      ],
      id: "1009",
    }).then((data) => {
      if (data.status === 249 || data.status === 150 || data.status === 148) {
        userRoleContext.handleLoginTokenExpiration()
      } else 
      if (data.result.I2) {
        let res = data.result
        let orderInfo = {
          "id": res.I2,
          "date": res.I6,
          "time": res.I7,
          "total": res.I8,
          "senderName": res.I9,
          "senderEmail": res.I10,
          "giftCardsSent": []
        }

        res.I14.forEach(gcSent => {
          let giftCardRecipientInfo = {
            "checkoutId": gcSent[0],
            "orderType": gcSent[1],
            "recipientName": gcSent[2],
            "recipientEmail": gcSent[10],
            "giftCardSentDetails": {}
          }

          giftCardRecipientInfo.giftCardSentDetails = {
            "sentId": gcSent[15][0][0],
            "giftCardId": gcSent[15][0][2],
            "giftCardReferenceId": gcSent[15][0][3],
            "title": gcSent[15][0][4],
            "amount": gcSent[15][0][7],
            "quantity": gcSent[15][0][8],
          }
          orderInfo.giftCardsSent.push(giftCardRecipientInfo)
        })

        setOrderDetails(orderInfo)
        setIsLoading(false)
      }
      else {
        setIsLoading(false)
      }
    });
  }
  useEffect(() => {
    callApi1009()
  }, [])

  return (
    <>
      {(orderDetails == null || isLoading) && <LoadingSpinner />}
      {orderDetails && (
        <>
          {deviceWidth >= 600 && (
            <div className="recent-order__back-button">
              <button className="recent-order__back-button-arrow">
                <Link
                  to={{
                    pathname: `/gift-card/recent`,
                  }}
                >
                  <KeyboardBackspaceIcon />
                </Link>
              </button>
              <span className="recent-order__back-button-label recentGiftCardOrderBackButtonLabel">
                {appLabels["gift-card"]["order-history"]}
              </span>
            </div>
          )}

          <Box p={2} sx={{ paddingBottom: "7em" }} className="gcRecentOrderDetailsWrapper">
            <Typography component={"span"}>
              <div className="recent-order__current-order gcRecentOrderDetailsContainer">
                <section className="recent-order__header">
                  <span>{formatRecentOrderDateAndTime(orderDetails.date, orderDetails.time, appLanguage).date + " " + appLabels["gift-card"]["at"] + " " + formatRecentOrderDateAndTime(orderDetails.date, orderDetails.time, appLanguage).time}</span>
                  <span>#{orderDetails.id}</span>
                </section>
                <section className="recent-order__main">
                  {
                    <div className="order-status__transition">
                      {orderDetails.giftCardsSent && !!orderDetails.giftCardsSent.length && (
                        <>
                          <div className="giftCardOrderDetails">
                            {orderDetails.giftCardsSent.map((item, index) => (
                              <div key={index} className="giftCardOrderDetailsContent">
                                <p><b>{item.giftCardSentDetails.title}</b> </p>
                                <p><b>{appLabels["gift-card"]["recipient-email"]} : </b>{item.recipientEmail}</p>
                                <p><b>{appLabels["gift-card"]["recipient-name"]} : </b>{item.recipientName}</p>
                                <p><b>{appLabels["gift-card"]["gift-card-value-text"]}: </b>{
                                  toDollars(appSettings["currency-symbol"],
                                    appSettings["currency-symbol-side"],
                                    item.giftCardSentDetails.amount,
                                    appLanguage
                                  )}
                                </p>

                              </div>

                            ))}
                          </div>
                          <ul className="order-status__order-details-items-list-current-order giftCardOrderDetailsTotal">
                            <span
                              className={` recent-order__tip-total-completed-orders`}
                            >

                              <p className="recent-order__total-completed-orders gcTotalCompletedOrder">
                                <span>
                                  {appLabels["general"]["total"]}
                                  {": "}
                                </span>
                                <span>{toDollars(appSettings["currency-symbol"],
                                  appSettings["currency-symbol-side"],
                                  orderDetails.total,
                                  appLanguage
                                )} </span>
                              </p>
                            </span>
                          </ul>
                        </>
                      )}
                    </div>
                  }
                </section>

              </div>
            </Typography>
          </Box>

        </>

      )}
    </>
  )
}
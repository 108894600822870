export const getOrderTypeImages = async (relativePath) => {
  try {
    const isLocalhost = process.env.NODE_ENV === "development";
    const isGivexHost = !isLocalhost;
    const pathnameSplit = window.location.pathname.split("/");
    const languageFromPathname = isGivexHost ? pathnameSplit[3] : pathnameSplit[1];
    const url = `${relativePath}${
      languageFromPathname !== "" ? languageFromPathname + "/" : ""
    }online-ordering/images.json`;

    const headers = new Headers();
    headers.append("Cache-Control", "no-cache"); // force the browser to check the server to see if the file is different from the file it already has in the cache

    const init = {
      headers: headers,
    };

    const response = await fetch(url, init);
    const onlineOrderingImagesData = await response.json();

    return onlineOrderingImagesData["order-types"] || "no-images-set";
  } catch (error) {
    console.error(error);
  }
};

import React, { useContext } from "react";
import AppLabelsContext from "../../../App/AppLabelsContext";
import useWindowSize from "../../../_common/hooks/useWindowSize";
import { getTodayName, convertFrom24HourFormat } from "../dateHelpers";
import "./StoreInfoDetails.css";

export const StoreInfoDetailsHours = ({ allHours }) => {
  const appLabels = useContext(AppLabelsContext);
  const deviceWidth = useWindowSize().width;
  let days = [];

  // Prepare working hours for each day for the table
  for (let day in allHours) {
    const dayHours = allHours[day];

    // Check for days with empty hours of operation
    // TODO: if empty strings always mean the store is closed on that day then push "Closed" to the days array
    if (dayHours) {
      const dayHoursObject = {};

      dayHoursObject.displayName = appLabels["general"][day];
      dayHoursObject.isToday = day === getTodayName();

      if (dayHours === "closed") {
        dayHoursObject.hours = "Closed";
      } else {
        const openingHours = convertFrom24HourFormat(dayHours.split("-")[0].replace(/\s/g, ""));
        const closingHours = convertFrom24HourFormat(dayHours.split("-")[1].replace(/\s/g, ""));

        dayHoursObject.hours = openingHours + " - " + closingHours;
      }

      days.push(dayHoursObject);
    }
  }

  return (
    <>
      {deviceWidth < 660 ? (
        <table className="store-info-details__hours-table">
          <tbody>
            {days.map((day) => (
              <tr
                key={day.displayName}
                className={day.isToday ? "store-info-details__table-row--active" : ""}
              >
                <td align="left">{day.displayName}</td>
                <td align="right">{day.hours}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="order-stores__store-row">
          <div className="order-stores__store-column first-hours-column">
            {days.map((day, index) => (
              <React.Fragment key={day.displayName}>
                {index < 4 && (
                  <div
                    className={`order-stores__store-row ${
                      day.isToday ? " store-info-details__table-row--active" : ""
                    }`}
                  >
                    <div className="order-stores__store-column">{day.displayName}</div>
                    <div className="order-stores__store-column">{day.hours}</div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>

          <div className="order-stores__store-column">
            {days.map((day, index) => (
              <React.Fragment key={day.displayName}>
                {index >= 4 && (
                  <div
                    className={`order-stores__store-row ${
                      day.isToday ? " store-info-details__table-row--active" : ""
                    }`}
                  >
                    <div className="order-stores__store-column">{day.displayName}</div>
                    <div className="order-stores__store-column right-align">{day.hours}</div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

import localforage from "localforage";
import React, { useContext, useState, useEffect } from "react";

/** Hooks */
import useWindowSize from "../_common/hooks/useWindowSize";

/** Context */
import AppLabelsContext from "../App/AppLabelsContext";
import AppLanguageContext from "../App/AppLanguageContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import LocationsContext from "../OnlineOrdering/Locations/LocationsContext";

/** Helper Functions */
import { callAPI } from "../_common/Api";
import { updateAPIAccount } from "./updateAPIAccount";

/** UI Components */
import { Form } from "../_common/Form/Form";
import { FormInput } from "../_common/Form/FormInput";
import { LoadingSpinner } from "../_common/LoadingSpinner";

const AccountDeletion = () => {
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const allStoresContext = useContext(LocationsContext);

  const deviceWidth = useWindowSize().width;

  const skin = useContext(MerchantConfigContext).skin;

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [isAPISubmitting, setAPISubmitting] = useState(false);
  const [submitNavigateURL, setSubmitNavigateURL] = useState(false);
  const [apiError, setAPIError] = useState(null);

  const [customerInfo, setCustomerInfo] = useState(null);

  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      if (customerInfo) {
        setCustomerInfo(customerInfo);
      }
    });
  }, []);

  const resetAPIError = () => setAPIError("");

  const verifyLogin = (data) => {
    let password;

    data.forEach((field) => {
      if (field.name === "password") password = field.value;
    });

    setAPISubmitting(true);
    callAPI(skin, "dc_958", {
      params: [appLanguage, "958", "mqid", "mqpass", customerInfo.email, password, ""],
      id: "958",
    }).then((data958) => {
      setAPISubmitting(false);
      console.log("data958: ", data958);
      if (data958.result.I2) {
        if (data958.result.I2 === "0") {
          deleteAccount();
        } else if (data958.result.I2 === "1") {
          setAPIError(appLabels["form"]["incorrect-username-password"]);
          setIsAPISubmitValid(false);
        } else {
          setAPIError(appLabels["form"]["generic-fallback-api-error"]);
          setIsAPISubmitValid(false);
        }
      }
    });
  };

  const deleteAccount = () => {
    setAPISubmitting(true);

    const updatedFields = {
      currentLogin: customerInfo.email,
      newLogin: "",
      password: "",
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      birthdate: "",
      email: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      province: "",
      country: "",
      postal: "",
      cpfNumber: "",
      promoOptInEmail: "",
      deleteAccount: "t",
    };

    updateAPIAccount(skin, updatedFields, appLanguage, null, allStoresContext)
      .then((api947Data) => {
        if (api947Data) {
          setAPISubmitting(false);

          if (api947Data.error) {
            setAPIError(api947Data.error);
            setIsAPISubmitValid(false);
          } else {
            let numberOfDaysToDelete = api947Data.result.I25 !== "-1" ? api947Data.result.I25 : "";
            if (!!numberOfDaysToDelete) {
              localforage
                .setItem(skin + "___DaysToDeleteAccount", numberOfDaysToDelete)
                .then(() => {
                  setSubmitNavigateURL("/account/account-deletion-confirmation");
                  setIsAPISubmitValid(true);
                });
            } else {
              setSubmitNavigateURL("/account/account-deletion-confirmation");
              setIsAPISubmitValid(true);
            }
          }
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      {customerInfo ? (
        <>
          {deviceWidth >= 660 && customerInfo && (
            <section className="delete-account-desktop-title">
              <div className="desktop-container">
                <h1 className="account-deletion-title">{appLabels["account"]["delete-account"]}</h1>
                <p>{customerInfo.email}</p>
              </div>
            </section>
          )}
          <div className="desktop-container mobile-container">
            <h2 className="account-deletion-subtitle">
              {appLabels["account"]["delete-account-sorry-to-see-you-go"]}
            </h2>
            <p>{appLabels["account"]["delete-account-permanently"]}</p>
            <p>{appLabels["account"]["delete-account-confirm-deletion"]}</p>

            <Form
              id="form--delete-account"
              submitButtonText={appLabels["account"]["delete-my-account"]}
              submitButtonSuccessText={appLabels["account"]["deleting"]}
              submitForm={verifyLogin}
              submitAPIError={apiError}
              resetAPIError={resetAPIError}
              isAPISubmitValid={isAPISubmitValid}
              isAPISubmitting={isAPISubmitting}
              submitNavigateURL={submitNavigateURL || false}
              submitNavigateURLStateFrom="account-deletion"
              submitButtonWrapperClass={"account-deletion-submit-button-wrapper"}
            >
              <FormInput
                type="text"
                label={appLabels["login-register"]["login-email"]}
                id="input--email"
                name="email"
                isRequired={false}
                isHidden={true}
              />

              <FormInput
                type="password"
                label={appLabels["account"]["enter-password"]}
                id="input--password"
                name="password"
                isRequired={true}
                isSmall={deviceWidth >= 660}
              />
            </Form>
          </div>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
export default AccountDeletion;

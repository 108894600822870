// Libraries
import React, { useContext, useState, useEffect } from "react";
import he from "he";
import { Link, useHistory } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import useWindowSize from "../../../_common/hooks/useWindowSize";

// Helper functions
import { callAPI } from "../../../_common/Api";
import { removeAllSpaces } from "../../../_common/Form/helpers/formValidation";
import { getDateFormat } from "../../../_common/Form/helpers/formFieldFormattingHelpers";

// Contexts
import AppSettingsContext from "../../../App/AppSettingsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import LocationsContext from "../../../OnlineOrdering/Locations/LocationsContext";
import StoreContext from "../../../OnlineOrdering/StoreContext";
import CWS5ModuleContext from "../../../App/CWS5ModuleContext";

// UI components
import { Modal } from "../../../_common/Modal/Modal";
import { Form } from "../../../_common/Form/Form";
import { FormFieldset } from "../../../_common/Form/FormFieldset";
import { FormInput } from "../../../_common/Form/FormInput";
import { FormSelect } from "../../../_common/Form/FormSelect";
import { FormCheckbox } from "../../../_common/Form/FormCheckbox";
import UserAlreadyExists from "../UserAlreadyExists/UserAlreadyExists";

// JSON data
import canadianProvinces from "../../../_common/Form/data/CanadianProvinces.json";
import unitedStates from "../../../_common/Form/data/UnitedStates.json";

// CSS
import "../../LoginRegisterBackButton/LoginRegisterBackButton.css";
import "./RegisterForm.css";

var AES = require("crypto-js/aes");

export const RegisterForm = (props) => {
  const { registrationFormFields, setShowSuccessScreen } = props;

  const history = useHistory();

  const appLabels = useContext(AppLabelsContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const allStoresContext = useContext(LocationsContext);
  const activeOrderStore = useContext(StoreContext).activeOrderStore;
  const appLanguage = useContext(AppLanguageContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);

  const skin = merchantConfigContext.skin;
  const isOfnerMerchant =
    skin === "ofnerbr" || skin === "ofnerbr_en" || skin === "benjamin" || skin === "QA3PWA";
  let cardWillBeMapped = false;

  const merchantConfig = merchantConfigContext.merchant;
  const isCPFEnabled = merchantConfig.I38.includes("CPF");

  const isRegistrationEnabled = cws5ModuleContext.isRegistrationEnabled;
  const [countryOptions, setCountryOptions] = useState(null);
  const [provinceOptions, setProvinceOptions] = useState([
    {
      value: "",
      label: appLabels["form"]["select-one"],
    },
  ]);

  useEffect(() => {
    let tempCountryList;
    //if merchant has multiple countries then create a list for the drop down
    //if only has one country, auto select the country
    if (merchantConfig.I16.length > 1) {
      //setupCountryOptions
      tempCountryList = [
        {
          value: "",
          label: appLabels["form"]["select-one"],
        },
      ];
      merchantConfig.I16.forEach((country) => {
        tempCountryList.push({
          value: country[0],
          label: country[1],
        });
      });
    } else {
      tempCountryList = [
        {
          value: merchantConfig.I16[0][0],
          label: merchantConfig.I16[0][1],
        },
      ];

      if (tempCountryList[0].value === "CA") {
        updateProvinceOptions(canadianProvinces.provinces);
      } else if (tempCountryList[0].value === "US") {
        updateProvinceOptions(unitedStates.states);
      }
    }

    setCountryOptions(tempCountryList);
  }, []);

  const [showCardNumberField, setShowCardNumberField] = useState("idle");

  const [isNewCardRequested, setIsNewCardRequested] = useState(true);

  const updateNewCardRequest = (value) => {
    setIsNewCardRequested(value);
  };

  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const registerUser = (formData) => {
    const formValues = {};
    formData.forEach((formField) => (formValues[formField.name] = formField.value));

    const keepLogin = true;

    let formattedBday = "";
    if (formValues["birthday"]) {
      const birthdayFormat = getDateFormat(activeOrderStore, allStoresContext);
      const birthdayPlaceholder = birthdayFormat.placeholder;
      const birthday = formValues["birthday"].split("/");
      if (birthdayPlaceholder === "mm/dd/yyyy") {
        formattedBday = birthday[2] + "-" + birthday[0] + "-" + birthday[1];
      } else {
        formattedBday = birthday[2] + "-" + birthday[1] + "-" + birthday[0];
      }
    }

    if (formValues["phone-number"]) {
      formValues["phone-number"] = formValues["phone-number"].replace(/[- )(]/g, "");
    }
    if (formValues["cell-number"]) {
      formValues["cell-number"] = formValues["cell-number"].replace(/[- )(]/g, "");
    }

    setAPISubmitting(true);

    const email = formValues["new-email"] || formValues["confirm-new-email"];
    /** to handle the edge case where the "password" key is missing from the formData object */
    const password = formValues["password"] || formValues["confirm-password"];

    let cardNumber = isNewCardRequested
      ? ""
      : formValues["giftcard-number"]
      ? formValues["giftcard-number"].replace(/ /g, "")
      : ""; // givexNumber

    let newCardRequest = isNewCardRequested ? "t" : "f";
    if (isOfnerMerchant) {
      if (cardWillBeMapped) {
        newCardRequest = "t";
      } else {
        cardNumber = formValues["gov-id"].replace(/-|\s|\./g, "");
        newCardRequest = "";
      }
    }
    // Check if CPF mapping is required AND if the CPF field is enabled
    const CPFMappingRequired = !!formattedForm[26] && formattedForm[26].isEnabled && isCPFEnabled;

    callAPI(skin, "dc_946", {
      params: [
        appLanguage,
        "946",
        "mqid",
        "mqpass",
        cardNumber, // givexNumber
        "customer", //customer Type
        email.replace(/ /g, ""), //customer login/email
        formValues["title"] || "", // title
        formValues["first-name"] || "", // first name
        formValues["middle-name"] || "", // middle name
        formValues["last-name"] || "", // last name
        formValues["gender"] || "", // gender
        formattedBday, // birth date
        formValues["address"] || "", // address 1
        formValues["address-2"] || "", // address 2
        formValues["city"] || "", // city
        formValues["province"] || "", // province
        formValues["county"] || "", //county
        formValues["country"] || "", // country
        formValues["postal"] || "", // postal code
        formValues["phone-number"] ? removeAllSpaces(formValues["phone-number"]) : "", // phone
        0, //discount
        formValues["promotion-gift-card-email"] || "", // promotion OptInt
        email.replace(/ /g, ""), //customer email
        password || "", //password
        formValues["cell-number"] ? removeAllSpaces(formValues["cell-number"]) : "", //mobile
        "", //customer company
        "", // security code
        newCardRequest, //new card request
        formValues["promotion-gift-card-mail"] || "", //promotionOptInMail
        "", //member type
        "", // customerLangPref
        "", // message Type
        "", // message Delivery Method
        "", // company ABN
        "", // position
        "", // company industry
        "", // manual approval
        "", // hold for EnrPayment
        "t", // return Token
        "", // customer Waiting Activation
        "", // customer Social Login list
        appLabels["general"]["default-loyalty-card"], // card Description
        formValues["gov-id"] || "", // Government ID
        formValues["promotion-redemption-email"] || "", // redemption Opt in
        "", // promotion Opt In Phone
        "", // new security code
        "", // customer Alternative Name
        "", // customer Nationality
      ],
      id: "946",
    }).then((data) => {
      setAPISubmitting(false);

      if (data.result.I0) {
        // User successfully registered
        setIsAPISubmitValid(true);
        setAPIError(null);

        localStorage.setItem(
          skin + "__login",
          JSON.stringify({
            email: email,
            password: AES.encrypt(password, "blurb").toString(),
          })
        );
        sessionStorage.setItem(skin + "__isSessionLogin", true);

        localforage.setItem(skin + "__customerID", data.result.I2);
        localforage.setItem(skin + "__userFirstName", formValues["first-name"]);

        if (keepLogin) {
          localStorage.setItem(skin + "__isRememberMeOn", true);
        } else {
          localStorage.removeItem(skin + "__isRememberMeOn");
        }

        if (CPFMappingRequired && formValues["gov-id"]) {
          /** If CPF mapping is required get the user's card number */
          const givexNumber = data.result.I6;

          /** once we have the full card number, call API 974 to perform the mapping */
          const govId = formValues["gov-id"].replace(/-|\s|\./g, "");
          const groupmappedID = [[govId, "CPF"]];

          callAPI(skin, "dc_974", {
            params: [
              appLanguage,
              "974",
              "mqid",
              "mqpass",
              givexNumber, //givex number
              groupmappedID, //Group Mapped ID(array)
              "", //Security Code(optional)
              "", //Old Group Mapped ID (optional)
            ],
            id: "974",
          }).then((data974) => {
            if (data974.result.I0) {
              setShowSuccessScreen(true);
            } else {
              setIsAPISubmitValid(false);
              setAPIError(he.decode(data974.error.message));
            }
          });
        } else {
          setShowSuccessScreen(true);
        }
      } else {
        // User failed to register
        if (data.status === 182 || data.status === 248 || data.status === 110) {
          // Customer already exists
          setUserAlreadyExists(true);
        } else if (data.status === 2 && isOfnerMerchant) {
          cardWillBeMapped = true;
          registerUser(formData);
        } else {
          // wxget: generic fallback API error
          setIsAPISubmitValid(false);
          setAPIError(he.decode(data.error.message));
        }
      }
    });
  };

  const updateProvinceOptions = (options) => {
    let tempOptionsList = [
      {
        value: "",
        label: appLabels["form"]["select-one"],
      },
    ];
    options.forEach((option) => {
      tempOptionsList.push({
        value: option.value,
        label: option.displayValue,
      });
    });

    setProvinceOptions(tempOptionsList);
  };

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [apiError, setAPIError] = useState("");
  const [isAPISubmitting, setAPISubmitting] = useState(false);

  const cardNumberOnChange = (select) => {
    if (select.value === "y-y") {
      updateNewCardRequest(false);
      setShowCardNumberField(true);
    } else {
      updateNewCardRequest(true);
      setShowCardNumberField(false);
    }
  };

  const [formattedForm, setFormattedForm] = useState(null);
  const [isSmall, setIsSmall] = useState(false);
  const deviceWidth = useWindowSize().width;
  useEffect(() => {
    if (deviceWidth >= 660) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  }, [deviceWidth]);

  useEffect(() => {
    if (registrationFormFields && formattedForm === null) {
      let tempFormattedForm = [];
      registrationFormFields.forEach((field) => {
        let tempFormattedFormItem = field;
        tempFormattedFormItem.fieldLabel = he.decode(field.fieldLabel);
        tempFormattedFormItem.fieldName = he.decode(field.fieldName);

        tempFormattedForm[field.formIndex] = tempFormattedFormItem;
      });

      setFormattedForm(tempFormattedForm);
    }
  }, [registrationFormFields]);

  const appSettings = useContext(AppSettingsContext);

  useEffect(() => {
    if (formattedForm) {
      if (!!formattedForm[0] && formattedForm[0].isEnabled) {
        updateNewCardRequest(true);
      } else {
        updateNewCardRequest(false);
        if (!!formattedForm[0] && formattedForm[0].isEnabled) {
          setShowCardNumberField(true);
        }
      }
    }
  }, [formattedForm]);

  const [isMaskedPhoneNumber, setIsMaskedPhoneNumber] = useState(true);
  const onCountryDropDownChange = (target) => {
    const selectedCountryCode = target.value;
    if (selectedCountryCode === "CA" || selectedCountryCode === "US") {
      setIsMaskedPhoneNumber(true);
    } else {
      setIsMaskedPhoneNumber(false);
    }
  };

  return (
    <>
      <div className="form-wrapper register-form-wrapper">
        {!!registrationFormFields && !!isRegistrationEnabled && !!formattedForm && (
          <>
            <div aria-live="polite">
              <p>You are on the register page.</p>
            </div>
            <Form
              submitForm={registerUser}
              resetAPIError={() => setAPIError("")}
              submitButtonText={appLabels["login-register"]["register"]}
              id="form--register-new-user"
              className="form--registration"
              isAPISubmitValid={isAPISubmitValid}
              isAPISubmitting={isAPISubmitting}
              submitAPIError={apiError}
            >
              <FormFieldset className="register-form-fieldset">
                {/** Title */}
                {!!formattedForm[1] && formattedForm[1].isEnabled ? (
                  <FormSelect
                    id="input--title"
                    name="title"
                    label={formattedForm[1].fieldLabel}
                    isRequired={formattedForm[1].isRequired}
                    defaultValue={""}
                    isSmall={isSmall}
                    showAstr={formattedForm[1].isRequired}
                  >
                    <option id="mr" value="mr" text="Mr." />
                    <option id="ms" value="ms" text="Ms." />
                    <option id="mrs" value="mrs" text="Mrs." />
                  </FormSelect>
                ) : (
                  <></>
                )}

                {/** First Name */}
                {!!formattedForm[2] && formattedForm[2].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={formattedForm[2].fieldLabel}
                    id={`input--${formattedForm[2].fieldName.replace(/ /g, "-")}`}
                    name={"first-name"}
                    isRequired={formattedForm[2].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[2].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** Middle Name */}
                {!!formattedForm[3] && formattedForm[3].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={formattedForm[3].fieldLabel}
                    id={`input--${formattedForm[3].fieldName.replace(/ /g, "-")}`}
                    name={"middle-name"}
                    isRequired={formattedForm[3].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[3].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** Last Name */}
                {!!formattedForm[4] && formattedForm[4].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={formattedForm[4].fieldLabel}
                    id={`input--${formattedForm[4].fieldName.replace(/ /g, "-")}`}
                    name={"last-name"}
                    isRequired={formattedForm[4].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[4].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** Gender */}
                {!!formattedForm[5] && formattedForm[5].isEnabled ? (
                  <FormSelect
                    id="input--gender"
                    name="gender"
                    label={formattedForm[5].fieldLabel}
                    isRequired={formattedForm[5].isRequired}
                    defaultValue={""}
                    isSmall={isSmall}
                    showAstr={formattedForm[5].isRequired}
                  >
                    <option id="female" value="F" text={appLabels["form"]["female"]} />
                    <option id="male" value="M" text={appLabels["form"]["male"]} />
                    <option id="none" value="N" text={appLabels["form"]["other-gender"]} />
                  </FormSelect>
                ) : (
                  <></>
                )}

                {/** Birthday */}
                {!!formattedForm[6] && formattedForm[6].isEnabled ? (
                  <FormInput
                    isMaskedInput={true}
                    type="text"
                    label={formattedForm[6].fieldLabel}
                    id="input--birthday"
                    name="birthday"
                    isRequired={formattedForm[6].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[6].isRequired}
                  />
                ) : (
                  <></>
                )}
              </FormFieldset>

              <FormFieldset className="register-form-fieldset">
                {/** Email Address */}
                {!!formattedForm[17] && formattedForm[17].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={formattedForm[17].fieldLabel}
                    id={`input--${formattedForm[17].fieldName.replace(/ /g, "-")}`}
                    name={"new-email"}
                    isRequired={formattedForm[17].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[17].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/**Email Address (Confirm) */}
                {!!formattedForm[171] && formattedForm[171].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={formattedForm[171].fieldLabel}
                    id={`input--${formattedForm[171].fieldName.replace(/ /g, "-")}`}
                    name={"confirm-new-email"}
                    isRequired={formattedForm[171].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[171].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** Password */}
                {!!formattedForm[18] && formattedForm[18].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="password"
                    label={formattedForm[18].fieldLabel}
                    id={`input--${formattedForm[18].fieldName.replace(/ /g, "-")}`}
                    name={"password"}
                    isRequired={formattedForm[18].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[18].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** Password (Confirm) */}
                {!!formattedForm[181] && formattedForm[181].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="password"
                    label={formattedForm[181].fieldLabel}
                    id={`input--${formattedForm[181].fieldName.replace(/ /g, "-")}`}
                    name={"confirm-password"}
                    isRequired={formattedForm[181].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[181].isRequired}
                  />
                ) : (
                  <></>
                )}
              </FormFieldset>

              <FormFieldset className="register-form-fieldset">
                {/** Address */}
                {!!formattedForm[7] && formattedForm[7].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={formattedForm[7].fieldLabel}
                    id={`input--${formattedForm[7].fieldName.replace(/ /g, "-")}`}
                    name={"address"}
                    isRequired={formattedForm[7].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[7].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** Address 2 */}
                {!!formattedForm[8] && formattedForm[8].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={formattedForm[8].fieldLabel}
                    id={`input--${formattedForm[8].fieldName.replace(/ /g, "-")}`}
                    name={"address-2"}
                    isRequired={formattedForm[8].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[8].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** City */}
                {!!formattedForm[9] && formattedForm[9].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={formattedForm[9].fieldLabel}
                    id={`input--${formattedForm[9].fieldName.replace(/ /g, "-")}`}
                    name={"city"}
                    isRequired={formattedForm[9].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[9].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** Country */}
                {!!formattedForm[12] && formattedForm[12].isEnabled ? (
                  <FormSelect
                    id="select--country"
                    name="country"
                    label={formattedForm[12].fieldLabel}
                    isRequired={formattedForm[12].isRequired}
                    defaultValue={countryOptions ? countryOptions[0].value : null}
                    isSmall={isSmall}
                    showAstr={formattedForm[12].isRequired}
                    callBack={onCountryDropDownChange}
                  />
                ) : (
                  <></>
                )}

                {/** State Province*/}
                {!!formattedForm[10] && formattedForm[10].isEnabled ? (
                  <FormSelect
                    id="select--province"
                    name="province"
                    label={formattedForm[10].fieldLabel}
                    isRequired={formattedForm[10].isRequired}
                    defaultValue={updateProvinceOptions ? provinceOptions[0].value : null}
                    isSmall={isSmall}
                    showAstr={formattedForm[10].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** Zip / Postal */}
                {!!formattedForm[13] && formattedForm[13].isEnabled ? (
                  <FormInput
                    isMaskedInput={true}
                    type="text"
                    label={formattedForm[13].fieldLabel}
                    id="input--postal"
                    name="postal"
                    isRequired={formattedForm[13].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[13].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** County */}
                {!!formattedForm[11] && formattedForm[11].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={formattedForm[11].fieldLabel}
                    id={`input--${formattedForm[11].fieldName.replace(/ /g, "-")}`}
                    name={"county"}
                    isRequired={formattedForm[11].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[11].isRequired}
                  />
                ) : (
                  <></>
                )}

                {/** Phone Number */}
                {!!formattedForm[14] && formattedForm[14].isEnabled ? (
                  <FormInput
                    isMaskedInput={isMaskedPhoneNumber}
                    type="tel"
                    label={formattedForm[14].fieldLabel}
                    id={`input--${formattedForm[14].fieldName.replace(/ /g, "-")}`}
                    name={"phone-number"}
                    isRequired={formattedForm[14].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[14].isRequired}
                    autocomplete="tel"
                  />
                ) : (
                  <></>
                )}

                {/** Cell Phone */}
                {!!formattedForm[19] && formattedForm[19].isEnabled ? (
                  <FormInput
                    isMaskedInput={isMaskedPhoneNumber}
                    type="tel"
                    label={formattedForm[19].fieldLabel}
                    id={`input--${formattedForm[19].fieldName.replace(/ /g, "-")}`}
                    name={"cell-number"}
                    isRequired={formattedForm[19].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[19].isRequired}
                    autocomplete="tel"
                  />
                ) : (
                  <></>
                )}

                {/** Government Identification */}
                {!!formattedForm[26] && formattedForm[26].isEnabled ? (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={formattedForm[26].fieldLabel}
                    id={`input--${formattedForm[26].fieldName.replace(/ /g, "-")}`}
                    name={"gov-id"}
                    isRequired={formattedForm[26].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[26].isRequired}
                  />
                ) : (
                  <></>
                )}
              </FormFieldset>

              <FormFieldset className="register-form-fieldset">
                {!!formattedForm[0] && formattedForm[0].isEnabled ? (
                  <FormSelect
                    label={formattedForm[0].fieldLabel}
                    name={"giftcard-request-question"}
                    id={`select--${formattedForm[0].fieldName.replace(/ /g, "-")}`}
                    isRequired={true}
                    defaultValue="n-y"
                    isSkipEmptyOption={true}
                    isSmall={isSmall}
                    callBack={cardNumberOnChange}
                    showAstr={formattedForm[0].isRequired}
                  >
                    <option
                      value="n-y"
                      text={appLabels["login-register"]["no-rewards-card-want-one"]}
                    />
                    <option value="y-y" text={appLabels["login-register"]["have-rewards-card"]} />
                  </FormSelect>
                ) : (
                  <></>
                )}

                {/** Card Number */}
                {!!formattedForm[0] && formattedForm[0].isEnabled ? (
                  <FormInput
                    type="text"
                    label={appLabels["login-register"]["rewards-card-question-blurb-2"]}
                    name="giftcard-number"
                    id={`input--giftcard-number`}
                    isRequired={
                      formattedForm[0].isRequired &&
                      !!showCardNumberField &&
                      showCardNumberField !== "idle"
                    }
                    isHidden={showCardNumberField === "idle" || !showCardNumberField}
                    defaultValue=""
                    isSmall={isSmall}
                    showAstr={formattedForm[0].isRequired}
                  />
                ) : (
                  <></>
                )}
              </FormFieldset>

              <FormFieldset className="register-form-fieldset">
                {/** Gift Card Promo by email */}
                {!!formattedForm[16] && formattedForm[16].isEnabled ? (
                  <FormSelect
                    label={formattedForm[16].fieldLabel}
                    name={"promotion-gift-card-email"}
                    id={`select--${formattedForm[16].fieldName.replace(/ /g, "-")}`}
                    isRequired={formattedForm[16].isRequired}
                    defaultValue={formattedForm[16].isRequired ? "t" : ""}
                    isSkipEmptyOption={formattedForm[16].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[16].isRequired}
                    customLabelClass={"registration-form__custom-label-styling"}
                  >
                    <option value="t" text={appLabels["form"]["yes-to-promo-email"]} />
                    <option value="f" text={appLabels["form"]["no-to-promo-email"]} />
                  </FormSelect>
                ) : (
                  <></>
                )}

                {/** Gift Card Promo by mail */}
                {!!formattedForm[21] && formattedForm[21].isEnabled ? (
                  <FormSelect
                    label={formattedForm[21].fieldLabel}
                    name={"promotion-gift-card-mail"}
                    id={`select--${formattedForm[21].fieldName.replace(/ /g, "-")}`}
                    isRequired={formattedForm[21].isRequired}
                    defaultValue={formattedForm[21].isRequired ? "t" : ""}
                    isSkipEmptyOption={formattedForm[21].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[21].isRequired}
                  >
                    <option value="t" text={appLabels["form"]["yes-to-promo-email"]} />
                    <option value="f" text={appLabels["form"]["no-to-promo-email"]} />
                  </FormSelect>
                ) : (
                  <></>
                )}

                {/** Redemption Promo by email */}
                {!!formattedForm[27] && formattedForm[27].isEnabled ? (
                  <FormSelect
                    label={formattedForm[27].fieldLabel}
                    name={"promotion-redemption-email"}
                    id={`select--${formattedForm[27].fieldName.replace(/ /g, "-")}`}
                    isRequired={formattedForm[27].isRequired}
                    defaultValue={formattedForm[27].isRequired ? "t" : ""}
                    isSkipEmptyOption={formattedForm[27].isRequired}
                    isSmall={isSmall}
                    showAstr={formattedForm[27].isRequired}
                  >
                    <option value="t" text={appLabels["form"]["yes-to-promo-email"]} />
                    <option value="f" text={appLabels["form"]["no-to-promo-email"]} />
                  </FormSelect>
                ) : (
                  <></>
                )}
              </FormFieldset>
              {!!formattedForm[22] && formattedForm[22].isEnabled && <></>}
              {!!formattedForm[23] && formattedForm[23].isEnabled && <></>}
              {!!formattedForm[24] && formattedForm[24].isEnabled && <></>}
              {!!formattedForm[25] && formattedForm[25].isEnabled && <></>}
              {!!formattedForm[28] && formattedForm[28].isEnabled && <></>}
              {!!formattedForm[29] && formattedForm[29].isEnabled && <></>}
              {!!formattedForm[30] && formattedForm[30].isEnabled && <></>}
              {appSettings["enable-terms"] === "Yes" ? (
                <FormCheckbox
                  label={
                    <>
                      {appLabels["form"]["accept-terms-text"].split("[terms-and-conditions]")[0]}
                      <Link
                        to="/terms-and-conditions"
                        className="checkbox__link terms-checkbox__link"
                      >
                        {appLabels["form"]["accept-terms-link-text"]}
                      </Link>
                      {appLabels["form"]["accept-terms-text"].split("[terms-and-conditions]")[1]}
                    </>
                  }
                  id="accept-terms"
                  name="accept-terms"
                  value="accepted-terms"
                  isRequired={true}
                  fieldWrapperClass={" form__field-wrapper--register-checkbox"}
                />
              ) : (
                <></>
              )}
            </Form>
          </>
        )}
      </div>

      {userAlreadyExists && (
        <Modal
          isModal={true}
          isCloseButton={true}
          toggleModal={() => {
            setUserAlreadyExists(false);
            history.push("/");
          }}
          type="fullscreen"
          isTransparent={true}
          customModalClass="register-user-exists__modal"
          content={<UserAlreadyExists />}
          heading={appLabels["login-register"]["user-already-exists-heading"]}
          description="User Already Exists"
        />
      )}
    </>
  );
};

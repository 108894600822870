import { callAPI } from "../../_common/Api";

export const getAPIAccountInfo = async (skin, loginToken, language) => {
  if (loginToken === "idle" || loginToken === null) {
    return;
  }
  const api950Data = await callAPI(skin, "dc_950", {
    params: [language, "950", "mqid", "mqpass", loginToken, ""],
    id: "950",
  });

  // To test login token expiry uncomment the block below
  /* const tokenExpiryTested = localStorage.getItem("token-expiry-tested"); // makes sure the test runs only once unless the "token-expiry-tested" is removed from localStorage
  if (!tokenExpiryTested) {
    api950Data.result.I0 = false;
    api950Data.status = 249; // forces the expired login token status
    localStorage.setItem("token-expiry-tested", true);
  } */

  if (api950Data.result.I0) {
    return api950Data.result;
  } else if (api950Data.status === 249 || api950Data.status === 150 || api950Data.status === 148) {
    return { status: "expiredLoginToken" };
  } else {
    return { error: api950Data.error };
  }
};

import React, { useState } from "react";

import { ReactComponent as IconStar } from "../_common/icons/IconStar.svg";
import { RewardsPointsItem } from "./RewardsPointsItem";

import "../Dashboard/DashboardRewards/DashboardRewards.css";
import "./RewardPointsScroll.css";

export const RewardPointsScroll = (props) => {
  const { merchantRewards, selectedCard } = props;

  const [activeReward, setActiveReward] = useState(null);
  return (
    <section className="dashboard__rewards-module my-rewards__section my-rewards__points-scroll-wrapper">
      <div className="rewards-module__stepper-wrapper">
        <ol className="rewards-module__stepper">
          <li className="rewards-module__step rewards-module__step--redeemable">
            <button className="button rewards-module__toggle-reward-name-button">
              <IconStar aria-hidden="true" />
              <span className="rewards-module__step-value">0</span>
            </button>
            <span className="rewards-module__step-line" />
          </li>
          {merchantRewards.map(
            (reward, index) =>
              reward.typeCode !== "21" &&
              reward.price > 0 && (
                <RewardsPointsItem
                  selectedCard={selectedCard}
                  key={reward.id}
                  isLast={index === merchantRewards.length - 1}
                  reward={reward}
                  isActive={activeReward && activeReward.id === reward.id}
                  updateActiveReward={() => setActiveReward(reward)}
                  isFirst={
                    reward.id ===
                    merchantRewards.filter((r) => r.typeCode !== "21" && r.price > 0)[0].id
                  }
                />
              )
          )}
        </ol>
      </div>
    </section>
  );
};

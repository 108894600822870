export const hasMandatoryCustomization = (item) => {
  const addonGroups = item.addonGroups;
  const modifierGroups = item.modifierGroups;
  if (
    Object.keys(addonGroups).length === 0 &&
    Object.keys(modifierGroups).length === 0 &&
    item.type !== "comboItem"
  ) {
    return false;
  } else {
    return true;
  }
};

export const getAllMenuItems = (isCategoryBased, menuData) => {
  let tempItemsList = [];
  /** Gather all the items */
  if (isCategoryBased) {
    /** Category based */
    Object.keys(menuData).forEach((categoryKey) => {
      const subcategories = menuData[categoryKey].subcategories;
      Object.keys(subcategories).forEach((subcategoryKey) => {
        const subcategoryItems = subcategories[subcategoryKey].items;
        Object.keys(subcategoryItems).forEach((itemKey) => {
          const item = subcategoryItems[itemKey];
          tempItemsList.push({
            ...item,
            requiresCustomization: hasMandatoryCustomization(item),
            subcategoryId: item["parentId"],
            categoryId: subcategories[subcategoryKey].parentId,
          });
        });
      });
    });
  } else {
    /** Subcategory based */
    Object.keys(menuData).forEach((subcategoryKey) => {
      const subcategoryItems = menuData[subcategoryKey].items;
      Object.keys(subcategoryItems).forEach((itemKey) => {
        const item = subcategoryItems[itemKey];
        tempItemsList.push({
          ...item,
          requiresCustomization: hasMandatoryCustomization(item),
          subcategoryId: item["parentId"],
          categoryId: menuData[subcategoryKey].parentId,
        });
      });
    });
  }

  return tempItemsList;
};

/** This function will check out of stock items
 */
export const checkOutOfStockItem = (menuItem) => {
  return Number(menuItem.item_count) <= 0;
};

/** This function will filter out itemsList by removing all the items that exist in the cart
 */
export const removeCartItemsFromList = (itemsList, cartItems) => {
  let cartItemsIdList = [];
  cartItems.forEach((cartItem) => {
    cartItemsIdList.push(cartItem.id);
  });

  /** Remove any item from allMenuItems which has the same id as the items in the cart or belong to the same subcategories */
  let filteredItems = itemsList.filter(
    (menuItem) =>
      !cartItemsIdList.includes(menuItem.id) &&
      !!menuItem.imageUrl &&
      !checkOutOfStockItem(menuItem)
  );

  return filteredItems;
};

/** This function will remove duplicate items from Suggested Items List
 */
export const removeDuplicatesFromSuggestedItemsBasedOnId = (itemList) => {
  return itemList.filter(
    (value, index, items) =>
      items.findIndex((filteredItems) => filteredItems.id === value.id) === index
  );
};

/** Group the items of itemsList based on their subcategories */
export const groupItemsBasedOnSubcategory = (itemsList) => {
  let filteredItemsGroupedBasedOnSubcategory = [];
  itemsList.forEach((item) => {
    let groupItems = [];
    let itemSubcategory = item.subcategoryId;
    groupItems = itemsList.filter((tempItem) => tempItem.subcategoryId === itemSubcategory);
    if (
      !JSON.stringify(filteredItemsGroupedBasedOnSubcategory).includes(JSON.stringify(groupItems))
    ) {
      filteredItemsGroupedBasedOnSubcategory.push(groupItems);
    }
  });

  /** Shuffle the array */
  filteredItemsGroupedBasedOnSubcategory = filteredItemsGroupedBasedOnSubcategory.sort(
    () => 0.5 - Math.random()
  );

  return filteredItemsGroupedBasedOnSubcategory;
};

export const addItemToCart = (item, cartContext, cartItems, event) => {
  const button = event.target;
  button.setAttribute(
    "data-state",
    button.getAttribute("data-state") === "inactive" ? "active" : "inactive"
  );
  event.preventDefault();

  setTimeout(() => {
    const cartItem = JSON.parse(JSON.stringify(item));
    cartItem.specialInstructions = "";
    cartItem.category = item.categoryId;
    cartItem.subcategory = item.subcategoryId;
    cartItem.quantity = 1;
    cartItem.entryKey = Date.now();

    const newCart = cartItems ? [...cartItems] : [];
    newCart.push(cartItem);
    cartContext.updateCart(newCart, true);
  }, 500);
};

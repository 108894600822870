export const getMarquiNewsFeedSliderData = async (relativePath) => {
  try {
    const isLocalhost = process.env.NODE_ENV === "development";
    const isGivexHost = !isLocalhost;
    const pathnameSplit = window.location.pathname.split("/");
    const languageFromPathname = isGivexHost ? pathnameSplit[3] : pathnameSplit[1];
    const url = isLocalhost
      ? `${relativePath}online-ordering/images.json`
      : `${relativePath}${
          languageFromPathname !== "" ? languageFromPathname + "/" : ""
        }online-ordering/images.json`;
    const headers = new Headers();

    // force the browser to check the server to see if the file is different from the file it already has in the cache
    headers.append("Cache-Control", "no-cache");

    const init = {
      headers: headers,
    };

    const response = await fetch(url, init);
    const json = await response.json();
    if (json["news-feed"] && !!Object.keys(json["news-feed"]).length) {
      // Convert object of objects to array of objects and filter out any slide data with invalid images
      const slidesData = Object.keys(json["news-feed"])
        .map((key) => {
          const slideData = { ...json["news-feed"][key] };

          // Attach proper IDs for JSX keys
          slideData.id = key;

          // Double check that Marqui has a valid image selected
          if (
            slideData["image-src"].split("/news-feed/")[1] &&
            !slideData["image-src"].includes("/_blank.")
          ) {
            // Update relative image paths to force all language instances to load the same images
            slideData["image-src"] = relativePath + slideData["image-src"];
          } else {
            delete slideData["image-src"];
          }

          // Catch partial external link entry and assign "//" for it to be treated as an external link
          if (
            slideData["button-link"] &&
            (slideData["is-external-link"].toLowerCase() === "yes" ||
              slideData["button-link"].includes(".")) &&
            !slideData["button-link"].includes("http")
          ) {
            slideData["button-link"] = "//" + slideData["button-link"];
          }

          // Simplify the external link boolean for UI components
          slideData.isExternalLink =
            slideData["is-external-link"].toLowerCase() === "yes" ||
            slideData["button-link"].includes("//");

          delete slideData["is-external-link"];

          return slideData;
        })
        .filter(
          (slideData) => slideData["title"] && slideData["button-text"] && slideData["button-link"]
        );

      return slidesData;
    } else {
      return [];
    }
  } catch (error) {
    console.error(`Fetch error | ${error}`);
  }
};

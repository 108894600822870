import React from "react";
import { ReactComponent as IconArrowDown } from "../../_common/icons/IconArrowDown.svg";
import "./DashboardSelect.css";

export const DashboardSelect = (props) => {
  const { id, label, name, options, activeOption, handleChangeAttempt, icon } = props;

  const handleSelectChange = (event) => {
    const { options, selectedIndex, value } = event.target;
    const selectedOption = options[selectedIndex];
    handleChangeAttempt(value, selectedOption.text);
  };

  return (
    <>
      {!!activeOption && (
        <div className="dashboard-select-wrapper">
          {icon}
          {name === "stores" && (
            <span className="dashboard-select__display-value">{activeOption.displayValue}</span>
          )}
          <label htmlFor={id} className="visually-hidden">
            {label}
          </label>
          <select
            value={activeOption.value}
            onChange={handleSelectChange}
            id={id}
            name={name}
            className="dashboard-select"
          >
            {options.map((option) => (
              <option value={option.value} key={option.value} disabled={!!option.disabled}>
                {option.displayValue}
              </option>
            ))}
          </select>
          <IconArrowDown />
        </div>
      )}
    </>
  );
};

// Libraries
import React, { useContext } from "react";
import "react-tabs/style/react-tabs.css";

import { formatRecentOrderDateAndTime } from "../giftCardHelpers";

// CSS
import "../../OnlineOrdering/RecentOrders/RecentOrders.css";
import { GCSeeDetailsButton } from "./GCSeeDetailsButton";
import AppSettingsContext from "../../App/AppSettingsContext";
import { toDollars } from "../../_common/helpers";

export const GCPastOrderCard = (props) => {
  const {
    order,
    appLabels,
    appLanguage,
  } = props;
  const appSettings = useContext(AppSettingsContext)
  return (
    <div className="recent-order">
      <header className="recent-order__header">
        <span>{formatRecentOrderDateAndTime(order.date, order.time, appLanguage).date + " " + appLabels["gift-card"]["at"] + " " + formatRecentOrderDateAndTime(order.date, order.time, appLanguage).time}</span>
        <span>#{order.id}</span>
      </header>
      <section className="recent-order__main">
        <h4 className="recent-order__total-past-orders">{appLabels["general"]["total"]}: &nbsp; </h4>
        {toDollars(
          appSettings["currency-symbol"],
          appSettings["currency-symbol-side"],
          order.total,
          appLanguage
        )}
      </section>
      <section className={"recent-order__footer-past-orders show-only-order-details"}>
        <GCSeeDetailsButton recentOrders={order} />

      </section>
    </div>
  );
};

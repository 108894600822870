// Libraries
import React, { useState, useContext, useEffect } from "react";

// Helper functions
import { updateAPIAccount } from "./updateAPIAccount";
import { removeAllSpaces } from "../_common/Form/helpers/formValidation";
import { getDateFormat } from "../_common/Form/helpers/formFieldFormattingHelpers";

// Contexts
import AppLanguageContext from "../App/AppLanguageContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import AppLabelsContext from "../App/AppLabelsContext";
import LocationsContext from "../OnlineOrdering/Locations/LocationsContext";
import StoreContext from "../OnlineOrdering/StoreContext";
import UserRoleContext from "../App/UserRoleContext";

// UI components
import { Form } from "../_common/Form/Form";
import { FormInput } from "../_common/Form/FormInput";
import { FormSelect } from "../_common/Form/FormSelect";
import { FormRadios } from "../_common/Form/FormRadios";
import { LoadingSpinner } from "../_common/LoadingSpinner";

export const ChangeAccountDetailsFormDesktop = (props) => {
  const { fields, setRegistrationFields, setIsModal } = props;

  const skin = useContext(MerchantConfigContext).skin;
  const appLabels = useContext(AppLabelsContext);

  const [apiError, setAPIerror] = useState(null);
  const [isAPIsubmitting, setIsAPIsubmitting] = useState(null);
  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);

  const appLanguage = useContext(AppLanguageContext);
  const userRoleContext = useContext(UserRoleContext);

  const allStoresContext = useContext(LocationsContext);
  const activeOrderStore = useContext(StoreContext).activeOrderStore;

  const submitAccountDetails = (formData) => {
    setIsAPIsubmitting(true);

    let title = !fields.title.isActive && fields.title.value !== "-1" ? fields.title.value : "";

    let firstName =
      !fields.firstName.isActive && fields.firstName.value !== "-1" ? fields.firstName.value : "";

    let middleName =
      !fields.middleName.isActive && fields.middleName.value !== "-1"
        ? fields.middleName.value
        : "";

    let lastName =
      !fields.lastName.isActive && fields.lastName.value !== "-1" ? fields.lastName.value : "";

    let gender = !fields.gender.isActive && fields.gender.value !== "-1" ? fields.gender.value : "";

    let birthdate =
      !fields.birthdate.isActive && fields.birthdate.value !== "-1" ? fields.birthdate.value : "";

    let phone = !fields.phone.isActive && fields.phone.value !== "-1" ? fields.phone.value : "";

    let address1 =
      !fields.address.isActive && fields.address.value !== "-1" ? fields.address.value : "";

    let address2 =
      !fields.address2.isActive && fields.address2.value !== "-1" ? fields.address2.value : "";

    let city = !fields.city.isActive && fields.city.value !== "-1" ? fields.city.value : "";

    let province =
      !fields.province.isActive && fields.province.value !== "-1" ? fields.province.value : "";

    let country =
      !fields.country.isActive && fields.country.value !== "-1" ? fields.country.value : "";

    let postal = !fields.postal.isActive && fields.postal.value !== "-1" ? fields.postal.value : "";

    let password = "";

    let cpfNumber = !fields.CPFNumber.isActive ? fields.CPFNumber.value : "";

    let promoOptInEmail =
      !fields.promoOptInEmail.isActive && fields.promoOptInEmail.value !== "-1"
        ? fields.promoOptInEmail.value
        : "";

    formData.forEach((field) => {
      if (field.name === "title") title = field.value;
      if (field.name === "first-name") firstName = field.value;
      if (field.name === "middle-name") middleName = field.value;
      if (field.name === "last-name") lastName = field.value;
      if (field.name === "gender") gender = field.value;
      if (field.name === "birthday") birthdate = field.value;
      if (field.name === "phone") phone = field.value.replace(/[- )(]/g, "");

      if (field.name === "address") address1 = field.value;
      if (field.name === "address2") address2 = field.value;
      if (field.name === "city") city = field.value;
      if (field.name === "province") province = field.value;
      if (field.name === "country") country = field.value;
      if (field.name === "postal") postal = field.value;

      if (field.name === "password") password = field.value;
      if (field.name === "cpf-number") cpfNumber = field.value;
      if (field.name === "promo-opt-in-email") promoOptInEmail = field.value;
    });

    const updatedFields = {
      currentLogin: fields.login.value,
      newLogin: "",
      password,
      title,
      firstName,
      middleName,
      lastName,
      gender,
      birthdate,
      email: fields.login.value,
      phone: removeAllSpaces(phone),
      address1,
      address2,
      city,
      province,
      country,
      postal,
      cpfNumber,
      promoOptInEmail,
    };

    updateAPIAccount(skin, updatedFields, appLanguage, activeOrderStore, allStoresContext)
      .then(async (data947) => {
        if (data947) {
          setIsAPIsubmitting(false);

          if (data947.error) {
            setAPIerror(data947.error);
            setIsAPISubmitValid(false);
          } else {
            const newAccountInfo = { ...fields };
            //TODO: update values
            newAccountInfo.firstName.value = firstName;
            newAccountInfo.middleName.value = middleName;
            newAccountInfo.lastName.value = lastName;
            newAccountInfo.gender.value = gender;

            const apiData = {
              result: {
                I2: data947.result.I2 || "",
                I5: updatedFields.email || "",
                I6: updatedFields.title || "",
                I7: updatedFields.firstName || "",
                I8: updatedFields.middleName || "",
                I9: updatedFields.lastName || "",
                I10: updatedFields.gender || "",
                I11: data947.result.I7 || "",
                I12:
                  updatedFields.address1 +
                    `${!!updatedFields.address2 ? "||" + updatedFields.address2 : ""}` || "",
                I13: updatedFields.city || "",
                I14: updatedFields.province || "",
                I15: data947.result.I15 || "",
                I16: updatedFields.country || "",
                I17: updatedFields.postal || "",
                I18: updatedFields.phone || "",
                I19: data947.result.I19 || "",
                I20: updatedFields.promoOptInEmail === "yes" ? "True" : "False",
                I21: updatedFields.email || "",
                I22: updatedFields.mobile || "",
                I23: data947.result.I23 || "",
                I24: data947.result.I24 || "",
                I25: data947.result.I25 || "",
                I26: data947.result.I26 || "",
                I28: data947.result.I28 || "",
                I29: data947.result.I29 || "",
                I30: data947.result.I30 || "",
                I31: updatedFields.cpfNumber || "",
              },
            };

            await userRoleContext.updateAccountInfoInStorage(apiData);

            setRegistrationFields(newAccountInfo);
            setIsModal(false);

            setTimeout(() => {
              window.location.reload();
            }, 200);
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const [isMaskedPhoneNumber, setIsMaskedPhoneNumber] = useState(true);
  const onCountryDropDownChange = (target) => {
    const selectedCountryCode = target.value;
    if (selectedCountryCode === "CA" || selectedCountryCode === "US") {
      setIsMaskedPhoneNumber(true);
    } else {
      setIsMaskedPhoneNumber(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("select--country")) {
        onCountryDropDownChange(document.getElementById("select--country"));
      }
    }, 500);
  }, []);

  const getBirthdayFormat = (birthdayValue) => {
    const birthdayFormat = getDateFormat(activeOrderStore, allStoresContext);
    const birthdayPlaceholder = birthdayFormat.placeholder;
    let formattedBday = "";

    const birthday = birthdayValue.split("-");
    if (birthdayValue === "") {
      formattedBday = "";
    } else if (birthdayPlaceholder === "mm/dd/yyyy") {
      formattedBday = birthday[1] + "-" + birthday[2] + "-" + birthday[0];
    } else {
      formattedBday = birthday[2] + "-" + birthday[1] + "-" + birthday[0];
    }

    return formattedBday;
  };

  return (
    <>
      {allStoresContext && (
        <>
          <section className="account-info__page-title">
            <div className="desktop-container">
              <h2>{appLabels["account"]["personal-info"]}</h2>
              <p>{fields.login.value}</p>
            </div>
          </section>
          <div className="desktop-container">
            <div className="modal-content-container account-form-modal-container account-info__form-wrapper">
              <h1 className="account_info__title">
                {appLabels["account"]["update-your-personal-info"]}
              </h1>
              <Form
                id="form--update-account-details"
                className="form--account-update"
                submitForm={submitAccountDetails}
                submitAPIError={apiError}
                resetAPIError={() => setAPIerror("")}
                isAPISubmitValid={isAPISubmitValid}
                isAPIsubmitting={isAPIsubmitting}
                isDisableSubmit={isAPIsubmitting}
                submitButtonText={appLabels["account"]["update"]}
                submitButtonLoadingText={appLabels["account"]["updating"]}
                isFAB={false}
                isModalFAB={false}
              >
                {fields.title.isActive && (
                  <FormInput
                    type="text"
                    label={fields.title.label}
                    placeholder="Mr."
                    id="input--title"
                    name="title"
                    isRequired={fields.title.isMandatory}
                    defaultValue={fields.title.value}
                  />
                )}
                {fields.firstName.isActive && (
                  <FormInput
                    type="text"
                    label={fields.firstName.label}
                    placeholder="John"
                    id="input--first-name"
                    name="first-name"
                    isRequired={fields.firstName.isMandatory}
                    defaultValue={fields.firstName.value}
                  />
                )}
                {fields.middleName.isActive && (
                  <FormInput
                    type="text"
                    label={fields.middleName.label}
                    id="input--middle-name"
                    name="middle-name"
                    isRequired={fields.middleName.isMandatory}
                    defaultValue={fields.middleName.value}
                  />
                )}
                {fields.lastName.isActive && (
                  <FormInput
                    type="text"
                    label={fields.lastName.label}
                    placeholder="Smith"
                    id="input--last-name"
                    name="last-name"
                    isRequired={fields.lastName.isMandatory}
                    defaultValue={fields.lastName.value}
                  />
                )}
                {fields.gender.isActive && (
                  <FormSelect
                    id="input--gender"
                    name="gender"
                    label={fields.gender.label}
                    isRequired={fields.gender.isMandatory}
                    defaultValue={fields.gender.value}
                    isSkipEmptyOption={true}
                  >
                    <option value="" text={appLabels["form"]["select-one"]} />
                    <option id="female" value="F" text={appLabels["form"]["female"]} />
                    <option id="male" value="M" text={appLabels["form"]["male"]} />
                    <option id="none" value="N" text={appLabels["form"]["other-gender"]} />
                  </FormSelect>
                )}
                {fields.birthdate.isActive && (
                  <FormInput
                    isMaskedInput={true}
                    type="text"
                    label={fields.birthdate.label}
                    id="input--birthday"
                    name="birthday"
                    isRequired={fields.birthdate.isMandatory}
                    defaultValue={getBirthdayFormat(fields.birthdate.value)}
                  />
                )}
                {fields.phone.isActive && (
                  <FormInput
                    isMaskedInput={isMaskedPhoneNumber}
                    type="tel"
                    label={fields.phone.label}
                    id="input--phone"
                    name="phone"
                    isRequired={fields.phone.isMandatory}
                    defaultValue={fields.phone.value}
                    autocomplete="tel"
                  />
                )}
                {fields.address.isActive && (
                  <FormInput
                    type="text"
                    label={fields.address.label}
                    id="input--address"
                    name="address"
                    isRequired={fields.address.isMandatory}
                    defaultValue={fields.address.value.split("||")[0]}
                  />
                )}
                {fields.address2.isActive && (
                  <FormInput
                    type="text"
                    label={fields.address2.label}
                    id="input--address2"
                    name="address2"
                    isRequired={fields.address2.isMandatory}
                    defaultValue={fields.address.value.split("||")[1]}
                  />
                )}
                {fields.city.isActive && (
                  <FormInput
                    type="text"
                    label={fields.city.label}
                    id="input--city"
                    name="city"
                    isRequired={fields.city.isMandatory}
                    defaultValue={fields.city.value}
                  />
                )}
                {fields.country.isActive && (
                  <FormSelect
                    id="select--country"
                    name="country"
                    label={fields.country.label}
                    isRequired={fields.country.isMandatory}
                    defaultValue={fields.country.value}
                    callBack={onCountryDropDownChange}
                  />
                )}
                {fields.province.isActive && (
                  <FormSelect
                    id="select--province"
                    name="province"
                    label={fields.province.label}
                    isRequired={fields.province.isMandatory}
                    defaultValue={fields.province.value}
                  />
                )}
                {fields.postal.isActive && (
                  <FormInput
                    isMaskedInput={true}
                    type="text"
                    label={fields.postal.label}
                    id="input--postal"
                    name="postal"
                    isRequired={fields.postal.isMandatory}
                    defaultValue={fields.postal.value}
                  />
                )}
                {fields.promoOptInEmail.isActive && (
                  <FormRadios
                    initialValue={fields.promoOptInEmail.value === "False" ? "no" : "yes"}
                    legend={fields.promoOptInEmail.label}
                    name="promo-opt-in-email"
                    fields={[
                      {
                        id: "input--yes-to-promo-opt-in-email",
                        value: "yes",
                        label: appLabels["form"]["yes-to-promo-email"],
                      },
                      {
                        id: "input--no-to-promo-opt-in-email",
                        value: "no",
                        label: appLabels["form"]["no-to-promo-email"],
                      },
                    ]}
                  />
                )}
                {fields.CPFNumber.isActive && (
                  <FormInput
                    isMaskedInput={false}
                    type="text"
                    label={fields.CPFNumber.label}
                    id="input--cpf-number"
                    name="cpf-number"
                    isRequired={fields.CPFNumber.isMandatory}
                    defaultValue={fields.CPFNumber.value}
                  />
                )}
                <FormInput
                  type="password"
                  label={appLabels["account"]["enter-password-to-confirm-changes"]}
                  id="input--password"
                  name="password"
                  isRequired={true}
                />
              </Form>
            </div>
          </div>
        </>
      )}
      {allStoresContext === null && <LoadingSpinner />}
    </>
  );
};

// Libraries
import React, { useContext, useEffect, useState } from "react";
import localforage from "localforage";

// API helpers
import { callAPI } from "../_common/Api";

// Contexts
import UserRoleContext from "./UserRoleContext";
import MerchantConfigContext from "./MerchantConfigContext";
import AppLanguageContext from "./AppLanguageContext";
import AppLabelsContext from "./AppLabelsContext";

// UI components
import { DialogModal } from "../_common/DialogModal/DialogModal";

const CRYPTO_JS = require("crypto-js");

const EXPIRATION_COUNTDOWN = 60; // in seconds

export const ExpiredSessionDialog = ({ closeSessionExpiredDialog }) => {
  const merchantSkinName = useContext(MerchantConfigContext).skin;
  const appLanguage = useContext(AppLanguageContext);
  const userRoleContext = useContext(UserRoleContext);
  const [isCallingAPI, setIsCallingAPI] = useState(false);

  const [expirationCountdown, setExpirationCountdown] = useState(EXPIRATION_COUNTDOWN);
  let timer;
  useEffect(() => {
    if (expirationCountdown > 0) {
      // reduce the countdown by 1 every second
      timer = setTimeout(() => {
        const remainingCountdown = expirationCountdown - 1;
        setExpirationCountdown(remainingCountdown);
      }, 1000);
    } else if (expirationCountdown <= 0) {
      userRoleContext.declineExpiredSessionRenewal();
    }

    return () => clearTimeout(timer);
  }, [expirationCountdown]);

  function renewExpiredSession() {
    const storedLogin = JSON.parse(sessionStorage.getItem(merchantSkinName + "__login"));

    if (storedLogin) {
      setIsCallingAPI(true);
      const storedEmail = storedLogin.email;
      const storedPassword = CRYPTO_JS.AES.decrypt(storedLogin.password, "blurb").toString(
        CRYPTO_JS.enc.Utf8
      );

      clearTimeout(timer);
      callAPI(merchantSkinName, "dc_958", {
        params: [appLanguage, "958", "mqid", "mqpass", storedEmail, storedPassword, "t"],
        id: "958",
      }).then((api958Data) => {
        const apiResult = api958Data.result.I2;

        if (apiResult && apiResult === "0") {
          const newLoginToken = api958Data.result.I4;
          userRoleContext.updateStatus("logged-in");
          userRoleContext.updateLoginToken(newLoginToken);

          localforage.getItem(merchantSkinName + "__customerInfo").then((customerInfo) => {
            if (customerInfo) {
              closeSessionExpiredDialog();
              setIsCallingAPI(false);
              window.location.reload(false);
            } else {
              callAPI(merchantSkinName, "dc_950", {
                params: [appLanguage, "950", "mqid", "mqpass", newLoginToken, ""],
                id: "950",
              }).then(async (data950) => {
                const customerInfo = await userRoleContext.updateAccountInfoInStorage(data950);
                localforage.setItem(merchantSkinName + "__userFirstName", customerInfo.firstName);
                closeSessionExpiredDialog();
                setIsCallingAPI(false);
                window.location.reload(false);
              });
            }
          });
        }
      });
    }
  }

  const appLabels = useContext(AppLabelsContext);
  const popupHeader = appLabels["general"]["renew-expired-session-confirmation"].split(",")[0];
  const popupMessage = appLabels["general"]["renew-expired-session-confirmation"].split(",")[1];
  const countdownLabelNumberSplit =
    appLabels["general"]["expired-session-logout-countdown"].split("[number]");

  return (
    <DialogModal
      isHTMLContent={true}
      message={
        <>
          <h2>{popupHeader}</h2>
          <p style={{ margin: `0` }}>
            {popupMessage} {countdownLabelNumberSplit[0]}
            {expirationCountdown}
            {countdownLabelNumberSplit[1]}
          </p>
        </>
      }
      confirmAction={renewExpiredSession}
      isConfirmText={true}
      isCancelConfirm={false}
      resetRemoveDialog={() => {
        renewExpiredSession();
      }}
      closeModalAction={() => {
        userRoleContext.declineExpiredSessionRenewal();
      }}
      customClass={"expired-session_popup"}
      showConfirmLoadingSpinner={isCallingAPI}
    />
  );
};

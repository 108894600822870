import { callAPI } from "../../_common/Api";

const setField = (id, isActive, isMandatory, label, value) => ({
  id,
  isActive: isActive === "t" ? true : false,
  isMandatory: isMandatory === "t" ? true : false,
  label,
  value,
});

export const getAPIRegistrationFields = async (skin, api950Data, language) => {
  const api1024Data = await callAPI(skin, "dc_1024", {
    params: [language, "1024", "mqid", "mqpass", "customer"],
    id: "1024",
  });

  if (api1024Data.result.I0) {
    const apiFields = api1024Data.result.I2;

    const newAccountInfo = {
      login: setField("login", "t", "t", "Login", api950Data.login ? api950Data.login : api950Data.email),
      password: setField("password", "t", "t", "Password", ""),
      title: setField("title", apiFields[1][2], apiFields[1][3], apiFields[1][1], api950Data.title),
      firstName: setField(
        "first-name",
        apiFields[2][2],
        apiFields[2][3],
        apiFields[2][1],
        api950Data.firstName
      ),
      middleName: setField(
        "middle-name",
        apiFields[3][2],
        apiFields[3][3],
        apiFields[3][1],
        api950Data.middleName
      ),
      lastName: setField(
        "last-name",
        apiFields[4][2],
        apiFields[4][3],
        apiFields[4][1],
        api950Data.lastName
      ),
      gender: setField(
        "gender",
        apiFields[5][2],
        apiFields[5][3],
        apiFields[5][1],
        api950Data.gender
      ),
      birthdate: setField(
        "birth-date",
        apiFields[6][2],
        apiFields[6][3],
        apiFields[6][1],
        api950Data.birthday
      ),
      phone: setField(
        "phone",
        apiFields[14][2],
        apiFields[14][3],
        apiFields[14][1],
        api950Data.phone
      ),
      address: setField(
        "address",
        apiFields[7][2],
        apiFields[7][3],
        apiFields[7][1],
        api950Data.address
      ),
      address2: setField("address2", apiFields[8][2], apiFields[8][3], apiFields[8][1], null),
      city: setField("city", apiFields[9][2], apiFields[9][3], apiFields[9][1], api950Data.city),
      province: setField(
        "province",
        apiFields[10][2],
        apiFields[10][3],
        apiFields[10][1],
        api950Data.province
      ),
      country: setField(
        "country",
        apiFields[12][2],
        apiFields[12][3],
        apiFields[12][1],
        api950Data.country
      ),
      postal: setField(
        "postal",
        apiFields[13][2],
        apiFields[13][3],
        apiFields[13][1],
        api950Data.postal
      ),
      promoOptInEmail: setField(
        "promo-opt-in-email",
        apiFields[16][2],
        apiFields[16][3],
        apiFields[16][1],
        api950Data.optInEmail
      ),
      CPFNumber: setField(
        "cpf-number",
        apiFields[26][2],
        apiFields[26][3],
        apiFields[26][1],
        api950Data.cpfNumber
      ),
    };

    return newAccountInfo;
  }
};

import React, { useContext, useState } from "react";

/** Contexts */
import AppLabelsContext from "../../App/AppLabelsContext";

/** UI Components */
import CollapsableSection from "../../_common/components/CollapsableSection";
import { GCCartSummary } from "./GCCartSummary";

const GCCartSummaryCollapsableSection = (props) => {
  const appLabels = useContext(AppLabelsContext);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const {
    items,
    orderSummaryData,
    updateOrderSummary,
    currentLocation,
    formattedCartItems,
    storedPromoCode,
    setStoredPromoCode,
    setOrderSummaryData,
    preAppliedDiscounts
  } = props;

  return (
    <>
      <CollapsableSection
        headerText={appLabels["order"]["combo-summary"]}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        id={"cartSummaryCollapsable"}
      />

      <div
        className={`${isCollapsed
          ? " collapsable-section__collapsed-content"
          : " collapsable-section__expanded-content"
          }`}
      >
        <GCCartSummary
          items={items}
          isAnimateIcon={true}
          orderSummaryData={orderSummaryData}
          setOrderSummaryData={setOrderSummaryData}
          updateOrderSummary={updateOrderSummary}
          currentLocation={currentLocation}
          setShowCheckoutButton={null}
          isTaxIncluded={false}
          formattedCartItems={formattedCartItems}
          storedPromoCode={storedPromoCode}
          setStoredPromoCode={setStoredPromoCode}
          preAppliedDiscounts={preAppliedDiscounts}
        />

      </div>
    </>
  );
};
export const GCCartSummaryCollapsable = React.memo(GCCartSummaryCollapsableSection);

import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import BrandingContext from "../../App/BrandingContext";
import AppLabelsContext from "../../App/AppLabelsContext";

import { getContrastColor } from "../../_common/colorHelpers";

import "./Welcome.css";

const NUMBER_OF_LOADING_DOTS = 4;

// TODO: move to be an overlay over Dashboard while its APIs are loading
export const Welcome = () => {
  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);
  const primaryColor = branding["primary-colour"];

  const history = useHistory();

  useEffect(() => {
    // Keep showing Welcome overlay for at least 3 seconds and hide when merchantConfig is loaded
    const timer = setTimeout(() => {
      history.push("/dashboard");
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="dashboard-welcome-overlay" style={{ backgroundColor: primaryColor }}>
      <div className="dashboard-welcome-overlay__content">
        <div className="dashboard-welcome-overlay__circle-mask">
          <h1 className="dashboard-welcome-overlay__heading">{appLabels["general"]["welcome"]}</h1>
        </div>
        <div className="dashboard-welcome-overlay__loading-wrapper">
          <p
            className="dashboard-welcome-overlay__loading-text"
            style={{ color: getContrastColor(primaryColor) }}
          >
            {appLabels["general"]["loading-your-setup"]}
          </p>
          <div className="dashboard-welcome-overlay__loading-dots">
            {Array.from(Array(NUMBER_OF_LOADING_DOTS)).map((loadingDot, index) => (
              <span className="dashboard-welcome-overlay__loading-dot" key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

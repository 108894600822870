export const loyaltyLabels = {
  rewards: "Récompenses",
  "start-collecting-now": "Commencez à accumuler vos points dès maintenant!",
  "add-new-card-to-join":
    "Rejoignez notre famille et commencez à collecter des points. Suivez vos progrès et échangez-les contre des boissons gratuites, de la nourriture et bien plus encore.",
  "still-not-convinced": "Toujours pas convaincu?",
  "check-out-how-rewards-work": "Voyez comment fonctionnent les récompenses",
  "check-out-how-rewards-work-extra-text":
    "et découvrez les avantages dont profitent nos loyaux clients",
  "how-rewards-work": "Comment les récompenses fonctionnent",
  "add-a-new-card": "Ajouter une nouvelle carte",
  "register-now": "S'inscrire maintenant",
  "my-rewards": "Voir mes récompenses",
  "ends-with": "Finit par",
  "reward-options": "Options de récompenses",
  "learn-how-it-works": "Découvrez comment elles fonctionnent",
  "start-collecting-points-toward":
    "Commencez à accumuler des points que vous pourrez échanger contre des boissons et des aliments gratuits, etc.",
  "expiry-date": "Date d'expiration",
  "bonus-rewards": "Récompenses en prime",
  "redeem-rewards": "Encaissez vos récompenses",
  "online-order": "En ligne",
  "when-ordering-online":
    "Ajoutez tout article applicable à votre commande. Lorsque vous serez prêt à passer à la caisse, cliquez sur le bouton «Utiliser les récompenses» de votre panier d'achat et choisissez la récompense souhaitée.",
  "in-store": "En établissement",
  "click-on-any-available-reward":
    "À partir de votre page d'accueil, cliquez sur le bouton «Scanner sur place», scannez le code puis dites au caissier ou à la caissière quelle récompense vous désirez obtenir.",
  redeem: "Encaisser",
  "you-need-to-earn-more-points": "Vous avez besoin d'accumuler plus de points",
  "collect-points": "Accumuler des points",
  "reward-redemption-confirmation":
    "Êtes-vous sûr de vouloir encaisser la récompense [reward-name] pour [reward-cost]",
  "order-confirmation-check-my-rewards-link": "Vérifier mes récompenses",
  "register-today-and-start-collecting-points-message":
    "Inscrivez-vous aujourd'hui même et commencez à accumuler des points à échanger contre des boissons et des aliments gratuits, etc.",
  "see-how-rewards-work-link": "Voyez comment fonctionnent les récompenses",
  "you-could-have-earned-points-message":
    "Vous auriez pu obtenir [points] points de fidélisation pour cette commande!",
  "confirm-rewards-redemption-message":
    "Vous êtes sur le point d'encaisser la récompense [reward-name]",
  "redeem-reward-associated-item-error":
    "Veuillez vérifier si l'article connexe a bien été ajouté à votre commande avant d'essayer d'encaisser cette récompense",
  "moving-applied-reward-on-card-switch-confirm-text":
    "Lors d'un changement de carte, toute récompense appliquée auparavant sera déplacée d'une carte à l'autre. Aimeriez-vous continuer?",
  "available-rewards-subheading": "Récompenses disponibles",
  "add-associated-item-before-redeeming-reward-blurb":
    "Veuillez ajouter les articles associés à votre commande avant d'utiliser votre récompense. Vous pouvez appliquer plus d'une récompense en ajustant la quantité",
  "no-rewards-for-card": "Aucune récompense disponible pour cette carte",
  "cart-change-reapply-rewards-message":
    "Une ou plusieurs récompenses appliquées auparavant ont cessé d'être disponibles lors de la dernière modification. Veuillez réappliquer toute récompense que vous désirez encaisser.",
  "order-confirmation-points-issued":
    "Vous avez accumulé [points] points de fidélisation [icon] pour cette commande!",
  "redeem-reward-success-message": "Encaissement effectué avec succès",
  "transaction-reference-number": "Numéro de référence de la transaction",
  "where-to-redeem-this-reward": "Où souhaitez-vous encaisser cette récompense",
  "was-added-to-card": "ont été ajouté à [card-number]",
  "your-new-card-balance": "Votre nouveau solde est: [card-balance]",
  "show-reward-item": "Afficher la récompense [reward-name]",
  "not-enough-points-for-redeeming":
    "Vous n'avez pas suffisamment de points pour encaisser [reward-quantity] [reward-name]",
  "reward-balance": "Solde de récompense",
  "rewards-remaining": "[reward-balance] récompenses restantes",
  "rewards-remaining-single": "[reward-balance] récompense restante",
  "view-rewards": "Afficher les récompenses",
  "see-all-rewards": "Voir toutes les récompenses",
};

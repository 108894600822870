import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

import useWindowSize from "../../_common/hooks/useWindowSize";

import { toDollars } from "../../_common/helpers";
import {
  calculateItemIndex,
  getOptionNameAndPriceFromOrderSummary,
} from "../../_common/CartHelpers";
import { onRemoveProductFromCart } from "../../_common/helpers/dataLayerHelpers";
import { onRemoveProductFromCartXtreme } from "../../_common/helpers/xtremePushHelper";

import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import BrandingContext from "../../App/BrandingContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import { MenuContext } from "../Menu/MenuContext";
import CartContext from "./CartContext";
import OrderTypeContext from "../OrderTypeContext";

import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { MenuItemDetail } from "../Menu/MenuItemDetail/MenuItemDetail";
import { MenuComboDetail } from "../Menu/MenuComboDetail/MenuComboDetail";
import { CartItemQuantityButtons } from "./CartItemQuantityButtons";

import { IconDelete, IconEdit } from "../../_common/icons";
import { ReactComponent as IconXInCircle } from "../../_common/icons/IconXInCircle.svg";
import { ReactComponent as IconStar } from "../../_common/icons/IconStar.svg";

function CartItem(props) {
  const {
    item,
    itemPrice,
    setIsCartFAB,
    handleRemove,
    updateItemQuantity,
    storedRewards,
    setStoredRewards,
    orderSummaryData,
    setOrderSummaryData,
    skin,
    itemIndex,
    storedRewardsDetails,
    setStoredRewardsDetails,
    itemAddonDiscount,
    comboChildImage,
    isOnCheckoutPage,
    setShowCheckoutButton,
    isAddonQuantityEnabled,
  } = props;

  const orderMenu = useContext(MenuContext);

  const history = useHistory();
  const brandingContext = useContext(BrandingContext);
  const placeholderImage = brandingContext["no-image-available-placeholder"];

  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;

  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const removeItem = () => {
    onRemoveProductFromCart(item, item.category, item.subcategory);
    onRemoveProductFromCartXtreme(item, item.category, item.subcategory, false, skin);
    setShowRemovePopup(false);
    handleRemove(item.entryKey);
  };

  const [showEditItemModal, setShowEditItemModal] = useState(false);

  useEffect(() => {
    if (showEditItemModal === false) {
      if (setShowCheckoutButton) {
        setShowCheckoutButton(true);
      }
      setIsCartFAB(true);
    }
  }, [showEditItemModal]);

  const removeItemDiscount = (itemRewardId) => {
    let tempStoredRewards = [...storedRewards];
    let tempStoredRewardsDetails = [...storedRewardsDetails];

    //filter out the rewards byt keeping only the ones that do not have the same id as itemRewardId
    tempStoredRewards = tempStoredRewards.filter((reward) => reward[1].toString() !== itemRewardId);
    tempStoredRewardsDetails = tempStoredRewardsDetails.filter(
      (reward) => reward.id !== itemRewardId
    );

    setStoredRewards(tempStoredRewards);
    setStoredRewardsDetails(tempStoredRewardsDetails);
  };

  //check if there are any additional discounts applied to the addons of the item
  const [additionalDiscountValue, setAdditionalDiscountValue] = useState(0);
  useEffect(() => {
    if (item.total_addon_discount) {
      setAdditionalDiscountValue(item.total_addon_discount);
    }
  }, [item, item ? item.total_addon_discount : ""]);

  const [itemDiscountIsTotalDiscount, setItemDiscountIsTotalDiscount] = useState(false);
  useEffect(() => {
    if (
      !!orderSummaryData.discountTotal &&
      !!props.itemDiscounts &&
      Number(
        Number(props.itemDiscounts[0].discount_amount) + Number(additionalDiscountValue)
      ).toFixed(2) === Number(orderSummaryData.discountTotal).toFixed(2)
    ) {
      setItemDiscountIsTotalDiscount(true);
    }
  }, [orderSummaryData, props.itemDiscounts, additionalDiscountValue]);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  const appLabels = useContext(AppLabelsContext);

  const deviceWidth = useWindowSize().width;

  const cartData = useContext(CartContext);
  const [isQuantityButtonsDisabled, setIsQuantitybuttonsDisabled] = useState(false);
  useEffect(() => {
    if (cartData && (item.hasOwnProperty("discounts") || !!additionalDiscountValue)) {
      const cartItem = item;
      const cartItems = cartData.value;
      const duplicates = cartItems.filter(
        (item) =>
          item.id === cartItem.id &&
          JSON.stringify(item.addonGroups) === JSON.stringify(cartItem.addonGroups) &&
          JSON.stringify(item.modifierGroups) === JSON.stringify(cartItem.modifierGroups)
      );
      if (duplicates && duplicates.length >= 1) {
        setIsQuantitybuttonsDisabled(true);
      } else if (duplicates && duplicates.length === 1 && duplicates[0].quantity > 1) {
        setIsQuantitybuttonsDisabled(true);
      } else {
        setIsQuantitybuttonsDisabled(false);
      }
    }
  }, [cartData, additionalDiscountValue]);
  const [ariaLabelAdditonalText, setAriaLabelAdditonalText] = useState();
  useEffect(() => {
    let addonAriaLabel = "";
    if (
      item.hasOwnProperty("addonGroups") &&
      orderSummaryData.items &&
      orderSummaryData.items[itemIndex]
    ) {
      let cartItemsFormatted = getCartItemsNames(orderSummaryData.items[itemIndex].addonGroups);
      cartItemsFormatted.forEach((option) => {
        addonAriaLabel = addonAriaLabel + (option.quantity || 1) + "x " + option.name + ". ";
      });
    }
    if (
      item.hasOwnProperty("modifierGroups") &&
      orderSummaryData.items &&
      orderSummaryData.items[itemIndex]
    ) {
      let cartItemsFormatted = getCartItemsNames(orderSummaryData.items[itemIndex].modifierGroups);
      cartItemsFormatted.forEach((option) => {
        addonAriaLabel = addonAriaLabel + (option.quantity || 1) + "x " + option.name + ". ";
      });
    }
    setAriaLabelAdditonalText(addonAriaLabel);
  }, [orderSummaryData]);

  const removeDuplicates = (data, key) => {
    return [...new Map(data.map((item) => [key(item), item])).values()];
  };

  const getAddonModifierOccurrences = (optionsList, optionId) => {
    return optionsList.filter((option) => option.id === optionId).length;
  };

  const getCartItemsNames = (optionGroups) => {
    let optionsWithNameAndPrice = [];

    for (let groupKey in optionGroups) {
      const options = optionGroups[groupKey].items;
      for (let itemKey in options) {
        const option = options[itemKey];
        const optionWithNameAndPrice = {
          name: option.name,
          price:
            parseFloat(option.price) > 0
              ? toDollars(
                  appSettings["currency-symbol"],
                  appSettings["currency-symbol-side"],
                  option["price_for_display"],
                  appLanguage
                )
              : false,
          nestedMods: [],
          quantity: getAddonModifierOccurrences(options, option.id),
          parentId: optionGroups[groupKey].id,
        };

        for (let modKey in option.modifierGroups) {
          const modOption = option.modifierGroups[modKey];
          for (let modItemKey in modOption.items) {
            const nestedMod = modOption.items[modItemKey];

            const mod = nestedMod.name;
            optionWithNameAndPrice.nestedMods.push(mod);
          }
        }

        optionsWithNameAndPrice.push(optionWithNameAndPrice);
      }
    }
    //remove duplicates from optionsList
    optionsWithNameAndPrice = removeDuplicates(optionsWithNameAndPrice, (item) =>
      JSON.stringify(item)
    );

    return optionsWithNameAndPrice;
  };


  const handleModifyClick = () => {
    localforage.setItem(skin + "__activeItemForModifying", JSON.stringify(item)).then(() => {
      const itemCopy = { ...item };

      if (!item.category && !item.subcategory) {
        Object.keys(orderMenu.apiData).forEach((categoryKey) => {
          const category = orderMenu.apiData[categoryKey];

          if (category.type.toLowerCase() === "category") {
            Object.keys(category.subcategories).forEach((subcategoryKey) => {
              const subcategory = category.subcategories[subcategoryKey];

              Object.keys(subcategory.items).forEach((itemKey) => {
                const orderMenuItem = subcategory.items[itemKey];

                if (item.id === orderMenuItem.id) {
                  itemCopy.category = category.id;
                  itemCopy.subcategory = subcategory.id;
                }
              });
            });
          } else if (category.type.toLowerCase() === "subcategory") {
            Object.keys(category.items).forEach((itemKey) => {
              const orderMenuItem = category.items[itemKey];

              if (item.id === orderMenuItem.id) {
                itemCopy.subcategory = category.id;
              }
            });
          }
        });
      }

      history.push({
        pathname:
          itemCopy.hasOwnProperty("combo_child_items") && itemCopy["combo_child_items"].length > 0
            ? itemCopy.category
              ? `/online-ordering/menu/category/${itemCopy.category}/subcategory/${itemCopy.subcategory}/combo/${itemCopy.id}`
              : `/online-ordering/menu/subcategory/${itemCopy.subcategory}/combo/${itemCopy.id}`
            : itemCopy.category
            ? `/online-ordering/menu/category/${itemCopy.category}/subcategory/${itemCopy.subcategory}/item/${itemCopy.id}`
            : `/online-ordering/menu/subcategory/${itemCopy.subcategory}/item/${itemCopy.id}`,
        state: { isModifyingItem: true },
      });
    });
  };

  return (
    <>
      {item && (
        <li key={Date.now()} className={`cartItem ${item.className ? item.className : ""}`}>
          <div className="cartItem__wrapper">
            <div>
              {deviceWidth >= 850 && (
                <div className="cartItem__image-wrapper">
                  <img
                    className="cartItem__image"
                    src={item.imageUrl || placeholderImage}
                    alt={item.name}
                  />
                </div>
              )}
              <div className="cartItem__infoWrapper">
                <div className="cart-item__name-price-wrapper">
                  <h3 className="cartItem__heading">
                    {isOnCheckoutPage && activeOrderType === "dinein" && (
                      <>
                        <span className="cartItem__quantity-span">{`${item.quantity}x`}</span>
                      </>
                    )}{" "}
                    {item.name}
                  </h3>
                  <span className="cartItem__price">
                    {!!item.calorie_count &&
                      item.calorie_count + ` ${appLabels["order"]["calories"]} | `}
                    {toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      itemPrice,
                      appLanguage
                    )}
                  </span>
                </div>
                <ul className="cartItem__modifiers">
                  {item.hasOwnProperty("modifierGroups") &&
                    orderSummaryData.items &&
                    orderSummaryData.items[itemIndex] &&
                    getOptionNameAndPriceFromOrderSummary(
                      orderSummaryData.items[itemIndex].modifierGroups
                    ).map((option, index) => (
                      <li key={index} className="cart-item__option">
                        <p className="cart-item__option-name">
                          {isAddonQuantityEnabled && <span>{option.quantity || 1}x </span>}
                          <span>{option.name}</span>
                        </p>

                        {option.price && (
                          <span className="cart-item__option-price">
                            {!!option.calorie_count &&
                              option.calorie_count + ` ${appLabels["order"]["calories"]} | `}
                            {option.price}
                          </span>
                        )}
                      </li>
                    ))}
                  {item.hasOwnProperty("addonGroups") &&
                    orderSummaryData.items &&
                    orderSummaryData.items[itemIndex] &&
                    getOptionNameAndPriceFromOrderSummary(
                      orderSummaryData.items[itemIndex].addonGroups
                    ).map((option, index) => (
                      <React.Fragment key={index}>
                        <li className="cart-item__option">
                          <p className="cart-item__option-name">
                            {isAddonQuantityEnabled && <span>{option.quantity || 1}x </span>}
                            <span>{option.name}</span>
                          </p>
                          {option.price && (
                            <span className="cart-item__option-price">
                              {!!option.calorie_count &&
                                option.calorie_count + ` ${appLabels["order"]["calories"]} | `}
                              {option.price}
                            </span>
                          )}
                        </li>
                        {option.nestedMods && option.nestedMods.length > 0 && (
                          <>
                            {option.nestedMods.map((nestedMod, i) => (
                              <li
                                key={`${nestedMod}-${i}-${Date.now()}`}
                                className="cart-item__option"
                              >
                                <span className="cart-item__option-name cart-combo-child-item__option-name cart-combo-child-item__nested-option-name">
                                  {nestedMod}
                                </span>
                              </li>
                            ))}
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  {item.hasOwnProperty("combo_child_items") &&
                    item["combo_child_items"].length > 0 &&
                    item.combo_child_items.map((comboChildItem, comboChildIndex) => (
                      <React.Fragment key={`${comboChildItem.id}-${comboChildIndex}`}>
                        <li className="cart-item__option cart-combo-child-item">
                          <span className="cart-item__option-name cart-combo-child-item__name">
                            {comboChildItem.groupMinQty && (
                              <span>{comboChildItem.quantity || 1}x </span>
                            )}
                            {comboChildItem.name}
                          </span>
                          {comboChildItem["price_for_display"] &&
                            comboChildItem["price_for_display"] > 0 && (
                              <span className="cart-item__option-price cart-combo-child-item__price">
                                {!!comboChildItem.calorie_count &&
                                  comboChildItem.calorie_count +
                                    ` ${appLabels["order"]["calories"]} | `}
                                {toDollars(
                                  appSettings["currency-symbol"],
                                  appSettings["currency-symbol-side"],
                                  comboChildItem["price_for_display"],
                                  appLanguage
                                )}
                              </span>
                            )}
                        </li>
                        {orderSummaryData.items &&
                          orderSummaryData.items[itemIndex] &&
                          orderSummaryData.items[itemIndex].items &&
                          orderSummaryData.items[itemIndex].items[
                            calculateItemIndex(comboChildIndex, item.combo_child_items)
                          ] &&
                          getOptionNameAndPriceFromOrderSummary(
                            orderSummaryData.items[itemIndex].items[
                              calculateItemIndex(comboChildIndex, item.combo_child_items)
                            ].modifierGroups
                          ).map((option, optionIndex) => (
                            <li key={optionIndex} className="cart-item__option">
                              <span className="cart-item__option-name cart-combo-child-item__option-name">
                                {isAddonQuantityEnabled && <span>{option.quantity || 1}x </span>}
                                {option.name}
                              </span>
                              {option.price && option.price > 0 && (
                                <span className="cart-item__option-price cart-combo-child-item__option-price">
                                  {!!option.calorie_count &&
                                    option.calorie_count + ` ${appLabels["order"]["calories"]} | `}
                                  {option.price}
                                </span>
                              )}
                            </li>
                          ))}
                        {orderSummaryData.items &&
                          orderSummaryData.items[itemIndex] &&
                          orderSummaryData.items[itemIndex].items &&
                          orderSummaryData.items[itemIndex].items[
                            calculateItemIndex(comboChildIndex, item.combo_child_items)
                          ] &&
                          getOptionNameAndPriceFromOrderSummary(
                            orderSummaryData.items[itemIndex].items[
                              calculateItemIndex(comboChildIndex, item.combo_child_items)
                            ].addonGroups
                          ).map((option, optionIndex) => (
                            <React.Fragment key={optionIndex}>
                              <li className="cart-item__option">
                                <span className="cart-item__option-name cart-combo-child-item__option-name">
                                  {isAddonQuantityEnabled && <span>{option.quantity || 1}x </span>}
                                  {option.name}
                                </span>
                                {option["price_for_display"] && (
                                  <span className="cart-item__option-price cart-combo-child-item__option-price">
                                    {!!option.calorie_count &&
                                      option.calorie_count +
                                        ` ${appLabels["order"]["calories"]} | `}
                                    {option["price_for_display"]}
                                  </span>
                                )}
                              </li>
                              {option.nestedMods && option.nestedMods.length > 0 && (
                                <>
                                  {option.nestedMods.map((nestedMod, i) => (
                                    <li key={`${nestedMod}-${i}`} className="cart-item__option">
                                      <span className="cart-item__option-name cart-combo-child-item__option-name cart-combo-child-item__nested-option-name">
                                        {nestedMod}
                                      </span>
                                    </li>
                                  ))}
                                </>
                              )}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                </ul>
                {item.specialInstructions && (
                  <div
                    className={`cart-item__special-instructions cart-item__special-instructions--expanded`}
                  >
                    {item.specialInstructions}
                  </div>
                )}
              </div>
            </div>
            {!!props.itemDiscounts && (
              <div className="cartItem__discounts-wrapper">
                {props.itemDiscounts.map((discount, index) => (
                  <React.Fragment key={discount.discount_id}>
                    {!!discount.reward_id &&
                      storedRewardsDetails &&
                      storedRewardsDetails.length > 0 &&
                      storedRewardsDetails.filter((r) => r.id === discount.reward_id).length >
                        0 && (
                        <div
                          key={discount.discount_id}
                          className="cartItem__discount-line"
                          data-discount-id={discount.discount_id}
                        >
                          <button
                            className="cartItem__discount-label"
                            onClick={() => removeItemDiscount(discount.reward_id)}
                            type="button"
                          >
                            <IconXInCircle aria-hidden="true" />{" "}
                            {
                              storedRewardsDetails.filter((r) => r.id === discount.reward_id)[0]
                                .name
                            }
                          </button>
                          <span className="cartItem__discount-value">
                            {storedRewardsDetails.filter((r) => r.id === discount.reward_id)[0]
                              .price > 0 && (
                              <span className="cartItem__discount-value-points">
                                <span>
                                  -
                                  {storedRewardsDetails.filter(
                                    (r) => r.id === discount.reward_id
                                  )[0].price * item.quantity}
                                </span>
                                <IconStar />
                              </span>
                            )}
                            {(!!discount.discount_amount || !!additionalDiscountValue) && (
                              <span>
                                -
                                {toDollars(
                                  appSettings["currency-symbol"],
                                  appSettings["currency-symbol-side"],
                                  (parseFloat(discount.discount_amount) + additionalDiscountValue) *
                                    item.quantity,
                                  appLanguage
                                )}
                              </span>
                            )}
                          </span>
                        </div>
                      )}
                  </React.Fragment>
                ))}
              </div>
            )}

            {/** Only Addon discounts */}
            {!!itemAddonDiscount && !!itemAddonDiscount.length && !props.itemDiscounts && (
              <div className="cartItem__discounts-wrapper">
                <div
                  key={itemAddonDiscount[0].discount_id}
                  className="cartItem__discount-line"
                  data-discount-id={itemAddonDiscount[0].discount_id}
                >
                  <button
                    className="cartItem__discount-label"
                    onClick={() => removeItemDiscount(itemAddonDiscount[0].reward_id)}
                    type="button"
                    aria-label={appLabels["order"]["remove-discount"]}
                  >
                    <IconXInCircle aria-hidden="true" />{" "}
                    {
                      storedRewardsDetails.filter((r) => r.id === itemAddonDiscount[0].reward_id)[0]
                        .name
                    }
                  </button>
                  <span className="cartItem__discount-value">
                    {storedRewardsDetails.filter((r) => r.id === itemAddonDiscount[0].reward_id)[0]
                      .price > 0 && (
                      <span className="cartItem__discount-value-points">
                        <span>
                          -
                          {storedRewardsDetails.filter(
                            (r) => r.id === itemAddonDiscount[0].reward_id
                          )[0].price * item.quantity}
                        </span>
                        <IconStar />
                      </span>
                    )}
                    {!!additionalDiscountValue && (
                      <span>
                        -
                        {toDollars(
                          appSettings["currency-symbol"],
                          appSettings["currency-symbol-side"],
                          additionalDiscountValue * item.quantity,
                          appLanguage
                        )}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            )}

            {((!isOnCheckoutPage && activeOrderType === "dinein") ||
              activeOrderType !== "dinein") && (
              <div className="cartItem__buttons-container">
                <button
                  className="button cartItem__button--delete"
                  onClick={() => setShowRemovePopup(true)}
                  aria-label={(appLabels["order"]["remove-item-from-cart"].replace(
                    "[item-name]",
                    item.name) + (ariaLabelAdditonalText ? ". " + appLabels["order"]["item-addons-modifications"] + ariaLabelAdditonalText : "" )
                  )}
                  type="button"
                >
                  <IconDelete
                    className="cartItem__icon cartItem__icon--delete"
                    aria-hidden="true"
                  />
                  <span className="cartItem__button-text">{appLabels["general"]["remove"]}</span>
                </button>

                <CartItemQuantityButtons
                  cartItem={item}
                  updateItemQuantity={updateItemQuantity}
                  isQuantityButtonsDisabled={isQuantityButtonsDisabled}
                  appLabels={appLabels}
                  cartData={cartData}
                />

                {deviceWidth >= 850 ? (
                  <>
                    <button
                      className="button cartItem__button--edit"
                      onClick={handleModifyClick}
                      aria-label={(appLabels["order"]["modify-item-in-cart"].replace(
                        "[item-name]",
                        item.name
                      )) +  (ariaLabelAdditonalText ? ". " + appLabels["order"]["item-addons-modifications"] + ". " + ariaLabelAdditonalText : "")}
                    >
                      <IconEdit
                        className="cartItem__icon cartItem__icon--edit"
                        aria-hidden="true"
                      />
                      <span className="cartItem__button-text">{appLabels["order"]["modify"]}</span>
                    </button>
                  </>
                ) : (
                  <button
                    className="button cartItem__button--edit"
                    onClick={() => {
                      //setIsCartFAB(false);
                      //setShowEditItemModal(true);
                      handleModifyClick();
                    }}
                    aria-label={appLabels["order"]["modify-item-in-cart"].replace(
                      "[item-name]",
                      item.name
                    )}
                    type="button"
                  >
                    <IconEdit className="cartItem__icon cartItem__icon--edit" />
                    <span className="cartItem__button-text">{appLabels["order"]["modify"]}</span>
                  </button>
                )}
                {showRemovePopup && (
                  <DialogModal
                    message={
                      appLabels["order"]["cart-item-removal-confirmation"].split("[item-name]")[0] +
                      item.name +
                      appLabels["order"]["cart-item-removal-confirmation"].split("[item-name]")[1] +
                      (itemDiscountIsTotalDiscount
                        ? " " +
                          appLabels["order"]["cart-item-removal-discount-also-removed-message"]
                        : "")
                    }
                    confirmAction={removeItem}
                    isCancelConfirm={true}
                    resetRemoveDialog={() => setShowRemovePopup(false)}
                  />
                )}
              </div>
            )}
          </div>
        </li>
      )}
      {showEditItemModal && (
        <>
          {item.hasOwnProperty("combo_child_items") && item["combo_child_items"].length > 0 ? (
            <MenuComboDetail
              cartItem={item}
              displayType="cart-modify"
              setShowEditItemModal={setShowEditItemModal}
              setIsCartFAB={setIsCartFAB}
              isInCart={true}
              setOrderSummaryData={setOrderSummaryData}
              comboChildImage={comboChildImage}
            />
          ) : (
            <MenuItemDetail
              cartItem={item}
              displayType="cart-modify"
              setShowEditItemModal={setShowEditItemModal}
              setIsCartFAB={setIsCartFAB}
              isInCart={true}
              setOrderSummaryData={setOrderSummaryData}
            />
          )}
        </>
      )}
    </>
  );
}

export default CartItem;

//Libraries
import React, { useContext } from "react";
import { useHistory } from "react-router";

//Contexts
import AppLabelsContext from "../App/AppLabelsContext";

//UI Component
import GiftCardCartIndicator from '../_common/GiftCardCartIndicator/GiftCardCartIndicator';
import GCCartContext from "./GCCartContext";

export const GCOrderSettingsCart = (props) => {

  const history = useHistory();
  const cartData = useContext(GCCartContext);

  const appLabels = useContext(AppLabelsContext);

  const onCartButtonClick = (event) => {
    if (cartData.quantity > 0) {
      history.push("/gift-card/checkout");
    }
  };
  return (
    <button
      type="button"
      className={"order-settings__cart-button " + props.customClass}
      aria-label={appLabels["general"]["go-to-cart"]}
      onClick={(e) => {
        onCartButtonClick(e);
      }}
    >
      <GiftCardCartIndicator
        quantity={cartData.quantity}
        isAnimated={cartData.isAnimated}
        isFloating={false}
        isCheckoutScreen={true}
        appLabels={appLabels}
      />
    </button>
  );
};

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";
import he from "he";

import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import UserRoleContext from "../../App/UserRoleContext";
import GCPaymentMethodGooglePayButton from "./GCPaymentMethodGooglePayButton";
import { callMarquiPaymentAPI } from "../../_common/Api";
import { checkDeliveryDateTime, nextDeliveryDateTime } from "../giftCardHelpers";
import GCCartContext from "../GCCartContext";

export const GCPaymentMethodGooglePay = (props) => {
  const { orderTotal, paymentMethodExpanded, formattedCartItems, promoCode, isTimePickerEnabled } = props;

  const history = useHistory();

  const merchantConfig = useContext(MerchantConfigContext);
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const userRoleContext = useContext(UserRoleContext);
  const cartContext = useContext(GCCartContext)

  const skin = merchantConfig.skin;
  const loginToken = userRoleContext.loginToken;

  const [apiError, setAPIError] = useState("");
  const [isAPISubmitting, setIsAPISubmitting] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({});
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [isPassedDeliveryDateTime, setIsPassedDeliveryDateTime] = useState(false);
  const [invalidDeliveryDateGiftCardIndexes, setInvalidDeliveryDateGiftCardIndexes] = useState()

  useEffect(() => {
    if (appliedCoupon == null) {
      localforage.getItem(skin + "__gcCoupon").then((coupon) => {
        if (coupon) setAppliedCoupon(coupon);
      });
    }
  }, [appliedCoupon]);

  useEffect(() => {
    localforage.getItem(skin + "__gcCustomerInfo").then((customerInfo) => {
      if (customerInfo) setCustomerInfo(customerInfo);
    });

  }, []);

  // Scroll to the top of the expand/collapse toggle button after details panel is expanded
  useEffect(() => {
    const timer = setTimeout(() => paymentMethodExpanded(), 300);
    return () => clearTimeout(timer);
  }, []);


  const submitGooglePayOrder = async (paymentData) => {
    setIsAPISubmitting(true);

    const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
    const encodedPaymentToken = Buffer.from(paymentToken).toString("base64");

    /** Order Submition */
    let { invalidItemIndexes, pastDeliveryDateTime } = checkDeliveryDateTime(cartContext.value, !isTimePickerEnabled)
    if (pastDeliveryDateTime) {
      setIsPassedDeliveryDateTime(true)
      setInvalidDeliveryDateGiftCardIndexes(invalidItemIndexes)
    } else {
    const placeOrderData = await callMarquiPaymentAPI(skin, "placeorder", {
      params: [
        skin, // merchant name
        "consumer",
        appLanguage, // language
        orderTotal, //amount
        "", // tm session id
        "ECERT", // order type
        formattedCartItems, // shipping_order_ref or cart items formatted
        loginToken, //customer login
        "", // customer password
        promoCode || "", // promo offer code
        "", // scene card number
        "GOOGLPAY", // payment type
        "", // cc_id
        "", // cc_type, double check this with reular order placement
        "", // cc_number
        "", // cc_name
        "", // cc_security
        "", // cc_issue
        "", // cc_expiry_month
        "", // cc_expiry_year
        "", // cc_start_month
        "", // cc_start_year
        "", // cc_address1
        "", // cc_address2
        "", // cc_city
        "", // cc_province
        "", // cc_country
        "", // cc_postal
        customerInfo.email, // cc_email
        customerInfo.phone || "", // cc_phone
        "", // pa_res
        "", // return_url
        "", // cancel_url
        "f", // bulk ecert
        "", // pay_dollar_success_url
        "", // pay_dollar_error_url,
        "", //pay_dollar fail url
        encodedPaymentToken, // unique_pay_id
        "", // order_payment_ref
        "", // third_party_discount_val
        "", // customer_order_ref
        "", // wechat_oauth_id
        "", // use_message_for_all_cards
        "", // require_confirmation
        "", // pickup_template
        "", // payment_gift_card
        "", // url_3ds_success , this is one of the urls seen in the end of params in cws4 call
        "", // url_3ds_failure, this is one of the urls seen in the end of params in cws4 call
        "", //excel_formatted_file
        "", // add_card_to_customer _account
        "" // uptix_order
      ],
      id: "dc_placeorder",
    });

    if (placeOrderData) {
      //if no errors:
      if (placeOrderData.status === 0) {
        const completedOrderId = placeOrderData.result.I0;
        const paymentDetails =
          appLabels["order"]["google-pay"] +
          " (" +
          paymentData.paymentMethodData.description +
          ")";

        localforage.setItem(skin + "__gcCompletedOrderId", completedOrderId);
        localforage.setItem(skin + "__gcUsedPaymentMethod", "GOOGLEPAY");
        localforage.setItem(skin + "__gcPaymentDetails", paymentDetails);

        setTimeout(() => {
          history.push("/gift-card/order-confirmation");
        }, 2000);
      } else {
        // failed to place an order
        setIsAPISubmitting(null);
        if (
          placeOrderData.error &&
          placeOrderData.error.message.includes(
            "Customer ID can only be used when a customer is logged in"
          )
        ) {
          userRoleContext.handleLoginTokenExpiration();
        } else {
          setAPIError(
            `${appLabels["general"]["error-code"]}: ${placeOrderData.error.code}. ${he.decode(
              placeOrderData.result.message
            )}`
          );
        }
      }
    }
    }
  };
  const validateDeliveryDateTime = () => {
    let { invalidItemIndexes, pastDeliveryDateTime } = checkDeliveryDateTime(cartContext.value, !isTimePickerEnabled)
    if (pastDeliveryDateTime) {
      setIsPassedDeliveryDateTime(true)
      setInvalidDeliveryDateGiftCardIndexes(invalidItemIndexes)
      return false
    } else {
      setIsPassedDeliveryDateTime(false)
      return true
    }
  }
  const declineTimeUpdate = () => {
    setIsPassedDeliveryDateTime(null);
    window.location.reload()
  };

  const updateDeliveryDateTime = () => {
    let updatedCartItems = []
    let cartItemsCopy = cartContext.value
    let nextAvailableTime = nextDeliveryDateTime(false, props.isTimePickerEnabled).timestamp

    for (let i = 0; i < invalidDeliveryDateGiftCardIndexes.length; i++) {
      let index = invalidDeliveryDateGiftCardIndexes[i]
      let itemCopy = cartContext.value[index]
      itemCopy.deliveryDateTime = nextAvailableTime.toString()
      updatedCartItems.push(itemCopy)
      cartItemsCopy[index] = itemCopy
    }

    cartContext.updateGCCart(cartItemsCopy)
    setIsPassedDeliveryDateTime(false)
    window.location.reload()
  }

  return (
    <>
      <div className="google-pay-button__container">
        <p>{appLabels["order"]["complete-order-pay-with-google-pay"]}</p>
      </div>

      <GCPaymentMethodGooglePayButton
        orderTotal={orderTotal}
        appLanguage={appLanguage}
        submitGooglePayOrder={submitGooglePayOrder}
        validateDeliveryDateTime={validateDeliveryDateTime}
      />

      {!!apiError && (
        <DialogModal
          message={apiError}
          resetRemoveDialog={() => {
            setAPIError(null);
            setIsAPISubmitting(null);
          }}
        />
      )}

      {isAPISubmitting && <LoadingSpinner />}
      {isPassedDeliveryDateTime && (
        <DialogModal
          message={
            <div>
              <span><b>{appLabels["gift-card"]["invalid-delivery-date-time-gift-card-title-message"]}</b></span>
              <br /> <br />
              {appLabels["gift-card"]["invalid-delivery-date-time-gift-card-message"]}

              <br /> <br /> <span><b>{nextDeliveryDateTime(true, isTimePickerEnabled, appLabels["gift-card"]["at"]).displayValue}</b> </span>

            </div>
          }
          resetRemoveDialog={declineTimeUpdate}
          isCancelConfirm={true}
          confirmAction={() => updateDeliveryDateTime()}
          isHTMLContent={true}
        />
      )}
    </>
  );
};

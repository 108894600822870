const postalFirstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
const postalLetter = /(?!.*[DFIOQU])[A-Z]/i;
const postalDigit = /[0-9]/;

export const postalMask = [
  postalFirstLetter,
  postalDigit,
  postalLetter,
  " ",
  postalDigit,
  postalLetter,
  postalDigit,
];

// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import jsQR from "jsqr";
import localforage from "localforage";

// Custom hooks
import useWindowPath from "../../_common/hooks/useWindowPath";
import useWindowSize from "../../_common/hooks/useWindowSize";
import useUserMedia from "../../_common/hooks/useUserMedia";

// Helper functions
import { setStoreHoursProperties } from "../GoogleMaps/dateHelpers";
import { getTrueBusinessDate } from "../../Dashboard/DashboardOrder/dateHelpers";

// API helper functions
import { get1300Stores } from "../../OnlineOrdering/GoogleMaps/get1300Stores";
import { getStore1306Config } from "../../App/apiHelpers/getStore1306Config";
import { updateCustomerFavorite } from "../Favorites/favoriteHelpers";

// Contexts
import AppLanguageContext from "../../App/AppLanguageContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import StoreContext from "../StoreContext";
import OrderTimeContext from "../OrderTimeContext";
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import UserRoleContext from "../../App/UserRoleContext";
import ManifestContext from "../../App/ManifestContext";
import AppSettingsContext from "../../App/AppSettingsContext";

// UI Components
import TableNumberForm from "./TableNumberForm";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { Geolocation } from "../GoogleMaps/Geolocation/Geolocation";
import { OrderStoreInfoCard } from "../../OrderSettings/OrderStoreInfoCard";

// CSS
import "./DineIn.css";
import { checkAvailableOrderItems, formatItems } from "../Menu/helpers/orderingHelpers";
import CartContext from "../Cart/CartContext";
import { MenuContext } from "../Menu/MenuContext";
import { isValidItemQuantity } from "../../_common/MenuHelpers";

/**
 * Valid QR code URL format:
 * https://dilbert.givex.com/cws5/pwa-test3/#/online-ordering/dinein?outlet_id=196407&table_id=1
 * localhost:3000/#/online-ordering/dinein?outlet_id=196407&table_id=1
 */

export const DineIn = (props) => {
  const { hasMoreThanOrderType } = props;
  const [videoDevices, setVideoDevices] = useState("idle");
  const [showIOSCameraDisclaimer, setShowIOSCameraDisclaimer] = useState(null);
  const [disableTableNumberForm, setDisableTableNumberForm] = useState(false);

  const [customerID, setCustomerID] = useState(null);
  const [favoriteLocations, setFavoriteLocations] = useState([]);
  const [geoLocationButtonIsActive, setGeoLocationButtonIsActive] = useState(false);
  const [userLocation, setUserLocation] = useState({});
  const [storesInRange, setStoresInRange] = useState(null);
  const [isGuestFavoriteAttempt, setIsGuestFavoriteAttempt] = useState(null);

  const userRoleContext = useContext(UserRoleContext);
  const cartData = useContext(CartContext);
  const menuContext = useContext(MenuContext);

  const loginToken = userRoleContext.loginToken;
  const isLoggedIn = userRoleContext.status === "logged-in";

  const appSettings = useContext(AppSettingsContext);
  const isPayOnly = appSettings["is-pay-only"] === "yes";

  const manifestContext = useContext(ManifestContext);
  useEffect(() => {
    var standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    if (!standalone && !safari && ios) {
      //show the ios Disclaimer
      setShowIOSCameraDisclaimer(true);
      setTimeout(() => {
        setVideoDevices("stop");
      }, 2000);
    } else {
      setShowIOSCameraDisclaimer(false);
    }
  }, []);
  useEffect(() => {
    if (videoDevices === "idle" && showIOSCameraDisclaimer === false) {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        // "enumerateDevices() not supported.""
        console.error("enumerateDevices() not supported");
        setVideoDevices(null);
        return;
      } else {
        navigator.mediaDevices
          .enumerateDevices()
          .then(function (devices) {
            if (devices) {
              const videoDevices = devices.filter(
                (device) => device.kind === "videoinput" && !device.label.includes("front")
              );
              setVideoDevices(videoDevices);
            } else {
              setVideoDevices(null);
            }
          })
          .catch(function (err) {
            console.error(err.name + ": " + err.message);
          });
      }
    }
  }, [videoDevices, showIOSCameraDisclaimer]);

  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;

  const storeContext = useContext(StoreContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const orderTypeContext = useContext(OrderTypeContext);
  const orderType = orderTypeContext.value;
  const appLanguage = useContext(AppLanguageContext);

  const videoRef = useRef();
  const canvasRef = useRef();

  const history = useHistory();

  const [dineInStores, setDineInStores] = useState(null);

  const handlingReOrderData = sessionStorage.getItem(skin + "__tempReorderData") !== null;
  const [isHandlingReorder, setIsHandlingReorder] = useState(false);
  const [isInvalidOrder, setIsInvalidOrder] = useState(false);

  useEffect(() => {
    get1300Stores(skin, "dinein", appLanguage).then((apiStores) => {
      if (apiStores && !apiStores.error) setDineInStores(apiStores);
      else setDineInStores(false);
    });
  }, []);

  /**
   * check the url and look for outlet_id and table_id
   * if they exist validate the url without the need to scan a qr code
   * this is to handle the case where user scans a qr code outside the application but should get directed to menu
   */
  useEffect(() => {
    if (dineInStores) {
      const urlHash = window.location.hash;
      const url = window.location.href;
      const hasOutletId = urlHash.includes("outlet_id");
      const hasTableId = urlHash.includes("table_id");

      if (hasOutletId && hasTableId) {
        checkQrCodeValidity(url);
      }
    }
  }, [dineInStores]);

  const deviceWidth = useWindowSize().width;
  const path = useWindowPath()[0];

  const appLabels = useContext(AppLabelsContext);

  const { mediaStream, streamError } =
    deviceWidth < 660 && useUserMedia(appLabels["order"], videoDevices);

  /** monitor the path and set the videoDevices to 'stop' to stop all the media tracks from useUserMedia.js component */
  useEffect(() => {
    if (path && !path.hash.includes("dinein")) {
      setVideoDevices("stop");
    }
  }, [path]);

  useEffect(() => {
    if (!!storesInRange && storesInRange.length > 0) {
      setVideoDevices("stop");
    }
  }, [storesInRange]);

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    //console.log(`Using video device: ${mediaStream.getVideoTracks()[0].label}`);
    videoRef.current.width = videoRef.current.srcObject = mediaStream;
  }

  function handleCanPlay() {
    // due to video's autoPlay attribute not working consistently across all platforms
    videoRef.current.play();
  }

  const [qrScanDataFound, setQrScanDataFound] = useState(false);
  const [scanError, setScanError] = useState("");

  const [previousTime, setPreviousTime] = useState(null);
  const THROTTLE_VALUE = 1000; //in milliseconds

  const handleTimeUpdate = (event) => {
    const currentTime = new Date().getTime();

    if (!previousTime || currentTime - previousTime >= THROTTLE_VALUE) {
      if (canvasRef.current && !qrScanDataFound && dineInStores) {
        const canvas = canvasRef.current;
        const video = event.target;

        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;

        const canvasContext = canvas.getContext("2d");

        canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);

        var imageData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);

        const qrCode = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });

        if (qrCode && qrCode.data) {
          setQrScanDataFound(true);
          checkQrCodeValidity(qrCode.data);
        }
      }
      setPreviousTime(currentTime);
    }
  };

  useEffect(() => {
    if (mediaStream) {
      return () => {
        mediaStream.getTracks().forEach((track) => track.stop());
      };
    }
  }, [mediaStream]);

  useEffect(() => {
    return () => {
      setVideoDevices("stop");
    };
  }, []);

  useEffect(() => {
    if (
      isHandlingReorder &&
      menuContext &&
      menuContext.apiData &&
      storeContext &&
      storeContext.activeOrderStore
    ) {
      (async () => {
        const orderData = JSON.parse(sessionStorage.getItem(skin + "__tempReorderData"));

        const availableOrderItems = await checkAvailableOrderItems(
          menuContext.menuLevel,
          menuContext.apiData,
          orderData,
          appLanguage,
          storeContext.activeOrderStore.storeId,
          orderType,
          orderTimeContext,
          skin
        );

        if (availableOrderItems.length > 0) {
          const tempCart = cartData.value ? [...cartData.value] : []; // get cart from storage, if doesn't exist start a fresh array

          //assign the group id to each combo child item as well as formatting their addon/modifier groups
          availableOrderItems.forEach((reOrderItem) => {
            if (reOrderItem.combo_child_items) {
              reOrderItem.combo_child_items = formatItems(reOrderItem.combo_child_items);
            }
            // validate the item quantity
            if (isValidItemQuantity(tempCart, reOrderItem)) {
              tempCart.push(reOrderItem);
            }
          });

          cartData.updateCart(tempCart);
          setTimeout(() => {
            history.push("/online-ordering/menu");
          }, 500);
        } else {
          setIsInvalidOrder(true);
        }

        sessionStorage.removeItem(skin + "__tempReorderData");
        setIsHandlingReorder(false);
      })();
    }
  }, [handlingReOrderData, menuContext, storeContext]);

  function checkQrCodeValidity(qrCodeData) {
    const hashSplit = qrCodeData.split("/#");
    const querySplit = qrCodeData.split("?");

    /* for debugging
    const isLocalhost = Boolean(
      window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );*/

    if (hashSplit[0] && hashSplit[1] && isURL(hashSplit[0] + hashSplit[1]) && querySplit[1]) {
      // string is in URL format

      // start retrieving query strings
      const urlParams = new URLSearchParams(querySplit[1]);

      if (
        urlParams.has("outlet_id") &&
        urlParams.get("outlet_id") &&
        urlParams.has("table_id") &&
        urlParams.get("table_id")
      ) {
        const scannedStoreId = urlParams.get("outlet_id");
        const scannedTableId = urlParams.get("table_id");

        let scannedStoreIdExists = false;
        let foundStore = null;

        for (let i = 0; i < dineInStores.length; i++) {
          if (Number(dineInStores[i].storeId) === Number(scannedStoreId)) {
            scannedStoreIdExists = true;
            foundStore = dineInStores[i];
            break;
          }
        }
        if (scannedStoreIdExists && !foundStore.__isUnavailable) {
          storeContext.updateActiveOrderStore(foundStore, skin);
          storeContext.updateActiveOrderStoreTable(scannedTableId);
          orderTypeContext.update("dinein", skin); //adding this line in case the user scanned the QR code from outside the app
          updateOrderTime(foundStore);

          const clickedOnFeaturedItem =
            sessionStorage.getItem(skin + "__featuredItemLink") !== null;
          if (clickedOnFeaturedItem) {
            const featuredItemLink = sessionStorage.getItem(skin + "__featuredItemLink");
            sessionStorage.removeItem(skin + "__featuredItemLink");
            sessionStorage.removeItem(skin + "__featuredItemStoreId");
            history.push({
              pathname: featuredItemLink,
              state: { from: "menu-slider" },
            });
          } else if (handlingReOrderData) {
            resetMenu();
            storeContext.updateActiveOrderStore(foundStore, skin);
            storeContext.updateActiveOrderStoreTable(scannedTableId);
            setIsHandlingReorder(true);
          } else {
            if (isPayOnly) {
              history.push({ pathname: "/online-ordering/bill", state: { from: "dinein" } });
            } else {
              history.push({ pathname: "/online-ordering/menu", state: { from: "dinein" } });
            }
          }
        } else if (scannedStoreIdExists && foundStore.__isUnavailable) {
          console.error("Scanned store id does not have vexilor config");
          setScanError(appLabels["order"]["no-future-dates"]);
          setDisableTableNumberForm(true);
        } else {
          console.error("Scanned store id doesn't match existing stores");
          setScanError(appLabels["order"]["invalid-qr-code"]);
        }
      } else {
        console.error("Scanned URL doesn't have all of the required parameters");
        setScanError(appLabels["order"]["invalid-qr-code"]);
      }
    } else {
      console.error("Scanned QR code is not of a URL format");
      setScanError(appLabels["order"]["invalid-qr-code"]);
    }
  }

  function resetQrScanner() {
    setQrScanDataFound(null);
    setScanError("");
    setDisableTableNumberForm(false);
  }

  const resetMenu = () => {
    localforage.removeItem(skin + "__storedMenu");
    menuContext.updateMenu({
      ...menuContext,
      apiData: null,
      activeTopCategory: null,
      activeSubcategory: null,
      activeItem: null,
      menuLevel: null,
    });
  };

  const updateOrderTime = async (foundStore) => {
    /* Set the order time as now + min interval */
    let foundStoreWithVexilorConfig = null;
    if (foundStore && !foundStore.vexilorConfig) {
      foundStoreWithVexilorConfig = await getStore1306Config(skin, appLanguage, foundStore);
    } else if (!foundStore) {
      foundStoreWithVexilorConfig = await getStore1306Config(skin, appLanguage, storesInRange[0]);
    }

    foundStoreWithVexilorConfig = setStoreHoursProperties(foundStoreWithVexilorConfig);
    const storeConfig = foundStoreWithVexilorConfig
      ? foundStoreWithVexilorConfig.vexilorConfig
      : storeContext.activeOrderStore.vexilorConfig;

    if (storeConfig) {
      const minInterval = Number(storeConfig.pickup_min_interval);

      const orderTime = new Date();
      orderTime.setTime(orderTime.getTime() + minInterval * 60000);

      orderTimeContext.update({
        value: new Date(orderTime),
        displayValue: `${appLabels["order"]["asap"]} (~${minInterval} ${appLabels["order"]["minutes-short-hand"]})`,
        triggerMenu: true,
        trueBusinessDate: getTrueBusinessDate(orderTime, foundStoreWithVexilorConfig),
      });

      localforage.setItem(skin + "__orderDate", {
        value: `${orderTime.getMonth()}-${orderTime.getDate()}`,
        displayValue: appLabels["order"]["today"],
        dateObject: new Date(orderTime),
      });

      localforage.setItem(
        skin + "__trueBusinessDate",
        getTrueBusinessDate(orderTime, foundStoreWithVexilorConfig)
      );

      //Check if the session storage to detect if user needs to be redirected to an item page instead of the menu
      let storeIdToCheck = "";

      if (foundStore) {
        storeIdToCheck = foundStore ? foundStore.storeId : storeContext.activeOrderStore.storeId;
      } else {
        storeIdToCheck = storesInRange[0].storeId;
        storeContext.updateActiveOrderStore(storesInRange[0], skin);
      }
      const clickedOnFeaturedItem = sessionStorage.getItem(skin + "__featuredItemLink") !== null;
      const selectedFeaturedItemStoreId = sessionStorage.getItem(skin + "__featuredItemStoreId");

      if (
        clickedOnFeaturedItem &&
        selectedFeaturedItemStoreId !== null &&
        selectedFeaturedItemStoreId === storeIdToCheck
      ) {
        const featuredItemLink = sessionStorage.getItem(skin + "__featuredItemLink");
        sessionStorage.removeItem(skin + "__featuredItemLink");
        sessionStorage.removeItem(skin + "__featuredItemStoreId");
        history.push({
          pathname: "/" + featuredItemLink,
          state: { from: "menu-slider" },
        });
      }
    }
  };

  const updateUserLocation = (lat, lng) => {
    setUserLocation({
      lat,
      lng,
    });
  };

  /** Sort the locations based on their distance (closest to furthest) */
  const getClosestStoreInRange = (tempStoresInRange) => {
    tempStoresInRange.sort(function (a, b) {
      return a.distanceFromUserLocationInMeters - b.distanceFromUserLocationInMeters;
    });
  };

  const getDineinStoresInRange = () => {
    let tempStoresInRange = [];
    get1300Stores(skin, "dinein", appLanguage, userLocation.lat, userLocation.lng).then(
      (api1300Stores) => {
        if (api1300Stores) {
          api1300Stores.forEach((store) => {
            store = setStoreHoursProperties(store);
            tempStoresInRange.push(store);
          });

          getClosestStoreInRange(tempStoresInRange);
          if (!!tempStoresInRange.length) {
            setStoresInRange([tempStoresInRange[0]]);
            setShowScanner(false);
          } else {
            setStoresInRange(false);
          }
        }
      }
    );
  };

  const getUserGeolocation = () => {
    setGeoLocationButtonIsActive(true);
    if (navigator && navigator.geolocation) {
      document.activeElement.blur(); // resets document focus to remove focus from the Find My Location button

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          updateUserLocation(latitude, longitude);
          //setGeoLocationButtonIsActive(false);
        },

        function error() {
          setGeoLocationButtonIsActive(false);
          alert(appLabels["general"]["disabled-geolocation-alert"]);
          console.log(
            "GPS position feature was disabled. To enable again - click on the location icon in the top right corner of the browser within a search field, then clear these settings for future visits and reload."
          );
        },

        { enableHighAccuracy: true }
      );
    }
  };

  const removeLocationFromFavorites = (store) => {
    const updatedLocations = [...favoriteLocations];
    updatedLocations.splice(updatedLocations.indexOf(store.storeId), 1);

    updateCustomerFavorite(
      skin,
      appLanguage,
      loginToken,
      customerID,
      "update",
      [],
      "LOCATIONS",
      updatedLocations
    )
      .then((updatedFavLocations) => {
        if (updatedFavLocations) {
          setFavoriteLocations(updatedLocations);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addLocationToFavorites = (store) => {
    if (!customerID || !loginToken) {
      //use is not logged-in
      setIsGuestFavoriteAttempt(true);
      return;
    }

    updateCustomerFavorite(skin, appLanguage, loginToken, customerID, "add", [], "LOCATIONS", [
      store.storeId,
    ])
      .then((updatedFavorites) => {
        if (updatedFavorites) {
          if (updatedFavorites.favItemAdded) {
            setFavoriteLocations(updatedFavorites.favItems);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //get the customer id from storage
  useEffect(() => {
    localforage.getItem(skin + "__customerID").then((storedCustomerID) => {
      if (storedCustomerID) setCustomerID(storedCustomerID);
    });
  }, []);

  useEffect(() => {
    if (customerID && isLoggedIn) {
      updateCustomerFavorite(skin, appLanguage, loginToken, customerID, "get", [], "LOCATIONS", [])
        .then((favoriteLocations) => {
          if (favoriteLocations) {
            if (favoriteLocations.length === 0) {
              setFavoriteLocations([]);
            } else {
              setFavoriteLocations(favoriteLocations);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [customerID, isLoggedIn]);

  useEffect(() => {
    if (userLocation && userLocation.lat && dineInStores) {
      getDineinStoresInRange();
    }
  }, [userLocation, dineInStores]);

  const [showScanner, setShowScanner] = useState(null);

  //** this useEffect will set the 'showScanner'  */
  useEffect(() => {
    if (showIOSCameraDisclaimer === false && deviceWidth < 660) {
      setShowScanner(true);
    }
  }, [showIOSCameraDisclaimer, deviceWidth]);

  const resetScanner = () => {
    setStoresInRange(null);
    setShowScanner(true);
    setVideoDevices("idle");
    setGeoLocationButtonIsActive(false);
    window.location.reload();
  };

  /** Check if order type is selected without any store
   * if so clear the order type as well, when onlineorder routes is unmounted
   */
  useEffect(() => {
    return () => {
      setTimeout(() => {
        localforage.getItem(skin + "__activeOrderStore").then((orderStore) => {
          if (!orderStore) {
            orderTypeContext.update("");
          }
        });
      }, 1000);
    };
  }, []);

  return (
    <>
      {deviceWidth < 660 && (
        <>
          <div className="dine-in__qr-scanner-instructions__container">
            {!!storesInRange ? (
              <>
                <p className="dine-in__qr-scanner-instructions">
                  {appLabels["order"]["dine-in-use-location-greetings"].replace(
                    "[merchant-name]",
                    manifestContext.name
                  )}
                </p>
                {storesInRange.map((store, index) => (
                  <React.Fragment key={index}>
                    <OrderStoreInfoCard
                      store={store}
                      userLocation={userLocation}
                      favoriteLocations={favoriteLocations}
                      setFavoriteLocations={setFavoriteLocations}
                      addLocationToFavorites={addLocationToFavorites}
                      removeLocationFromFavorites={removeLocationFromFavorites}
                      isDineinInfoCard={true}
                    />
                  </React.Fragment>
                ))}

                <div
                  className={`dine-in_qr-toggle-container ${showScanner ? "visually-hidden" : ""}`}
                >
                  <button type="button" onClick={resetScanner}>
                    {appLabels["order"]["dine-in-scan-qr-code"]}
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="dine-in__qr-scanner-instructions">
                  {showIOSCameraDisclaimer ? (
                    <>
                      <span>
                        {appLabels["order"]["ios-camera-disclaimer"].split("[line-break]")[0]}
                      </span>
                      <br />
                      <span>
                        {appLabels["order"]["ios-camera-disclaimer"].split("[line-break]")[1]}
                      </span>
                      <br />
                      <span>
                        {appLabels["order"]["ios-camera-disclaimer"].split("[line-break]")[2]}
                      </span>
                    </>
                  ) : (
                    appLabels["order"]["dine-in-use-location-instructions"]
                  )}
                </p>
                <Geolocation
                  getUserGeolocation={getUserGeolocation}
                  isActive={geoLocationButtonIsActive}
                />
              </>
            )}
          </div>
          {!storesInRange && !showIOSCameraDisclaimer && showScanner && (
            <div className="dine-in__qr-scanner-container">
              {!mediaStream && !streamError && <LoadingSpinner />}
              <video
                ref={videoRef}
                onCanPlay={handleCanPlay}
                onTimeUpdate={handleTimeUpdate}
                autoPlay
                playsInline
                muted
                className="qr-scanner__video-stream"
                id="scanner-video-tag"
              />
              <canvas ref={canvasRef} hidden></canvas>
              {streamError && <div className="dine-in__video-stream-error">{streamError}</div>}
              <div
                className="qr-scanner__square-frame"
                style={{
                  backgroundColor: scanError ? `rgba(255,255,255,0.95)` : `transparent`,
                }}
              />
              {scanError && (
                <>
                  <div className="qr-scanner__error">{scanError}</div>
                  <button
                    onClick={resetQrScanner}
                    className="button button--primary button--primary-with-border button--primary-small button--pill-shaped button--retry-qr-scan"
                    type="button"
                  >
                    Try Again
                  </button>
                </>
              )}
            </div>
          )}

          {!!storesInRange && (
            <div className="dine-in__table-number-form-wrapper">
              <TableNumberForm
                storeContext={storeContext}
                updateOrderTime={updateOrderTime}
                setVideoDevices={setVideoDevices}
                isDisableSubmit={disableTableNumberForm}
                skin={skin}
              />
            </div>
          )}
        </>
      )}
      {deviceWidth >= 660 && (
        <DialogModal
          isHTMLContent={true}
          isConfirmText={true}
          hideCloseButton={false}
          hideConfirmButton={true}
          closeModalAction={() => {
            if (hasMoreThanOrderType) {
              history.push("/online-ordering/order-type");
            } else {
              history.push("/dashboard");
            }
          }}
          customClass="dine-in-desktop-popup"
          message={
            <>
              {!!storesInRange ? (
                <>
                  <p className="dine-in__qr-scanner-instructions">
                    {appLabels["order"]["dine-in-use-location-greetings"].replace(
                      "[merchant-name]",
                      manifestContext.name
                    )}
                  </p>
                  {storesInRange.map((store, index) => (
                    <React.Fragment key={index}>
                      <OrderStoreInfoCard
                        store={store}
                        userLocation={userLocation}
                        favoriteLocations={favoriteLocations}
                        setFavoriteLocations={setFavoriteLocations}
                        addLocationToFavorites={addLocationToFavorites}
                        removeLocationFromFavorites={removeLocationFromFavorites}
                        isDineinInfoCard={true}
                      />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  <p className="dine-in__qr-scanner-instructions">
                    {appLabels["order"]["dine-in-use-location-instructions"]}
                  </p>
                  <Geolocation
                    getUserGeolocation={getUserGeolocation}
                    isActive={geoLocationButtonIsActive}
                  />
                </>
              )}

              {!!storesInRange && (
                <div className="dine-in__table-number-form-wrapper">
                  <TableNumberForm
                    storeContext={storeContext}
                    updateOrderTime={updateOrderTime}
                    customClass={"dine-in__table-number-form__popup"}
                    setVideoDevices={setVideoDevices}
                    skin={skin}
                  />
                </div>
              )}
            </>
          }
        />
      )}

      {isGuestFavoriteAttempt && (
        <DialogModal
          isConfirmText={false}
          hideConfirmButton={true}
          description="Guest User attempting to set item as favourite"
          message={
            <div className="menu-item__favourite-dialog-modal">
              <div>
                <p>{appLabels["order"]["guest-attempt-favorite-location"]}</p>
              </div>
              <div className="menu-item__favourite-dialog-modal-buttons-wrapper">
                <button
                  onClick={() => {
                    sessionStorage.setItem(
                      skin + "__lastVisitedLink",
                      window.location.hash.split("#")[1]
                    );
                    history.push("/login-register");
                  }}
                  className="button dialog-modal__button  button--primary button--primary-small"
                  type="button"
                >
                  {appLabels["login-register"]["sign-in"]}
                </button>

                <button
                  onClick={() => {
                    sessionStorage.setItem(
                      skin + "__lastVisitedLink",
                      window.location.hash.split("#")[1]
                    );
                    history.push("/register");
                  }}
                  className="button dialog-modal__button  button--primary button--primary-small"
                  type="button"
                >
                  {appLabels["login-register"]["register"]}
                </button>
              </div>
            </div>
          }
          resetRemoveDialog={() => {
            setIsGuestFavoriteAttempt(false);
          }}
          isHTMLContent={true}
        />
      )}

      {storesInRange === false && (
        <DialogModal
          message={appLabels["order"]["dine-in-not-in-location-error"]}
          resetRemoveDialog={() => {
            history.push("/online-ordering/order-type");
          }}
        />
      )}

      {isInvalidOrder && (
        <DialogModal
          message={appLabels["account"]["recent-orders-item-doesnt-exist-message"]}
          resetRemoveDialog={() => {
            setIsInvalidOrder(false);
            setTimeout(() => {
              history.push("/online-ordering/menu");
            }, 500);
          }}
        />
      )}
    </>
  );
};

// doesn't work with hashes
function isURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helpers
import { onProductClickXtreme } from "../../_common/helpers/xtremePushHelper";
import { isTouchDevice } from "../../_common/helpers/isTouchDevice";

// Contexts
import AppLabelsContext from "../../App/AppLabelsContext";
import { MenuContext } from "../../OnlineOrdering/Menu/MenuContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";

//UI Components
import { Carousel } from "@trendyol-js/react-carousel";
import { MenuCategoryCard } from "../../OnlineOrdering/Menu/MenuCategoryCard/MenuCategoryCard";
import { DashboardModuleHeader } from "../DashboardModuleHeader/DashboardModuleHeader";
import {
  DashboardCardsSlider,
  SliderScrollButton,
} from "../DashboardCardsSlider/DashboardCardsSlider";

// Assets
import { ReactComponent as IconArrowLeft } from "../../_common/icons/IconArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../../_common/icons/IconArrowRight.svg";

// CSS
import "./DashboardFeatured.css";

export const DashboardFeatured = () => {
  const history = useHistory();
  const deviceWidth = useWindowSize().width;

  const appLabels = useContext(AppLabelsContext);
  const orderMenu = useContext(MenuContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;

  const [featuredItems, setFeaturedItems] = useState([]);
  const [featuredCategoryData, setFeaturedCategoryData] = useState(null);

  // TODO: move that to its own hook which should be called in Dashboard and DashboardFeatured should be conditionally rendered based on the existence of that items list
  useEffect(() => {
    if (orderMenu.apiData) {
      let featuredCategoryObject = null;

      let featuredItemsArray = [];
      let featuredItemsObject = {};

      if (orderMenu.menuLevel === "threeLevels") {
        Object.keys(orderMenu.apiData).forEach((catKey) => {
          const category = orderMenu.apiData[catKey];

          if (category.name.toLowerCase() === "featured") {
            featuredCategoryObject = category;

            Object.keys(category.subcategories).forEach((subcatKey) => {
              const subcategory = category.subcategories[subcatKey];

              if (
                subcategory.name.toLowerCase() === "featured" &&
                Object.keys(subcategory.items).length > 0
              ) {
                featuredItemsObject = subcategory.items;
              }
            });
          }
        });
      } else if (orderMenu.menuLevel === "twoLevels") {
        Object.keys(orderMenu.apiData).forEach((key) => {
          const subcategory = orderMenu.apiData[key];

          if (
            subcategory.name.toLowerCase() === "featured" &&
            Object.keys(subcategory.items).length > 0
          ) {
            featuredCategoryObject = subcategory;
            featuredItemsObject = subcategory.items;
          }
        });
      }

      Object.keys(featuredItemsObject).forEach((key) => {
        featuredItemsArray.push(featuredItemsObject[key]);
      });
      setFeaturedItems(featuredItemsArray);

      setFeaturedCategoryData(featuredCategoryObject);
    }
  }, [orderMenu.apiData]);

  const goToFeaturedItem = (itemID, itemType, item) => {
    let path = "";
    if (featuredCategoryData.type.toLowerCase() === "category") {
      const categoryID = featuredCategoryData.id;
      const subcategoryID = Object.keys(featuredCategoryData.subcategories)[0].split(
        "subcategory-"
      )[1];

      //Trigger the on product click for XP when a featured item is clicked
      onProductClickXtreme(item, categoryID, subcategoryID, skin);
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: categoryID,
        activeSubcategory: subcategoryID,
        activeItem: itemID,
      });

      if (itemType === "productItem") {
        path = `online-ordering/menu/category/${categoryID}/subcategory/${subcategoryID}/item/${itemID}`;
      } else if (itemType === "comboItem") {
        path = `online-ordering/menu/category/${categoryID}/subcategory/${subcategoryID}/combo/${itemID}`;
      }
    } else if (featuredCategoryData.type.toLowerCase() === "subcategory") {
      const subcategoryID = featuredCategoryData.id;

      //Trigger the on product click for XP when a featured item is clicked
      onProductClickXtreme(item, "", subcategoryID, skin);
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: null,
        activeSubcategory: subcategoryID,
        activeItem: itemID,
      });

      path = `online-ordering/menu/subcategory/${subcategoryID}/item/${itemID}`;

      if (itemType === "productItem") {
        path = `online-ordering/menu/subcategory/${subcategoryID}/item/${itemID}`;
      } else if (itemType === "comboItem") {
        path = `online-ordering/menu/subcategory/${subcategoryID}/combo/${itemID}`;
      }
    }

    /*
    if (orderType !== "pickup") {
      sessionStorage.setItem(skin + "__featuredItemLink", path);
      sessionStorage.setItem(skin + "__featuredItemStoreId", activeOrderStore.storeId);
      // If current order type is not pickup -> redirect to that order flow
      path = `/online-ordering/${orderType}`;
    }
    */

    history.push({
      pathname: path,
      state: { from: "menu-slider" },
    });
  };

  const [carouselChildren, setCarouselChildren] = useState(null);
  const [numberOfSlidesInView, setNumberOfSlidesInView] = useState(6);
  const [nummerOfSlidesToMove, setNumberOfSlidesToMove] = useState(3);
  const [numOfGroups, setNumOfGroups] = useState();
  const [scrollRightClickCount, setScrollRightClickCount] = useState(1);

  useEffect(() => {
    if (featuredItems && Object.keys(featuredItems).length > 0) {
      let tempList = [];
      featuredItems.forEach((item, index) => {
        tempList.push(
          <MenuCategoryCard
            key={`${item.id}`}
            handleClick={() => goToFeaturedItem(item.id, item.type, item)}
            //index={index}
            itemData={{
              ...item,
              //category: category,
              //subcategory: subcategory,
              onlineQuantityLimit:
                !!item["online_qty_limit"] && item["online_qty_limit"] !== "None"
                  ? item["online_qty_limit"]
                  : null,
            }}
            isFeaturedItemCard={true}
          />
        );
      });

      setCarouselChildren(tempList);
    }
  }, [featuredItems, deviceWidth]);

  useEffect(() => {
    if (deviceWidth >= 660 && carouselChildren) {
      let slideInView = "";
      let slidesToMove = "";

      if (deviceWidth >= 1280) {
        slideInView = 6.2;
        slidesToMove = 4;
      } else if (deviceWidth >= 1080 && deviceWidth < 1280) {
        slideInView = 5.2;
        slidesToMove = 2;
      } else if (deviceWidth >= 768 && deviceWidth < 1080) {
        slideInView = 4.2;
        slidesToMove = 2;
      } else {
        slideInView = 3.2;
        slidesToMove = 1;
      }

      if (slideInView > carouselChildren.length) {
        slideInView = carouselChildren.length;
      }

      setNumberOfSlidesInView(slideInView);
      setNumberOfSlidesToMove(slidesToMove);
      setTimeout(() => {
        let totalGroups = 0;

        if (slideInView + Number(slidesToMove) < carouselChildren.length) {
          totalGroups = Math.ceil(carouselChildren.length / (slideInView - slidesToMove));
        } else {
          totalGroups = Math.ceil(carouselChildren.length / slideInView);
        }
        let arrayGroups = [];
        for (let i = 1; i <= totalGroups; i++) {
          arrayGroups.push(i);
        }
        setNumOfGroups(arrayGroups);
      }, 100);
    }
  }, [deviceWidth, carouselChildren]);

  function scrollRight() {
    setScrollRightClickCount(scrollRightClickCount + 1);
  }
  function scrollLeft() {
    setScrollRightClickCount(scrollRightClickCount - 1);
  }
  return (
    <>
      {deviceWidth < 660 ? (
        <>
          {featuredItems && Object.keys(featuredItems).length > 0 && (
            <section className="dashboard__module dashboard__module--horizontal-slider dashboard__featured-module">
              <div>
                <DashboardModuleHeader name={appLabels["order"]["featured"]} />
              </div>
              <DashboardCardsSlider
                slidesData={featuredItems}
                featuredCategoryData={featuredCategoryData}
              />
            </section>
          )}
        </>
      ) : (
        <>
          {carouselChildren && (
            <section className="dashboard__module dashboard__module--horizontal-slider dashboard__featured-module carousel">
              <div>
                <DashboardModuleHeader name={appLabels["order"]["featured"]} />
              </div>

              <Carousel
                show={numberOfSlidesInView}
                slide={nummerOfSlidesToMove}
                children={carouselChildren}
                useArrowKeys={true}
                infinite={false}
                responsive={true}
                swiping={true}
                transition={0.25}
                leftArrow={
                  !isTouchDevice() ? (
                    <SliderScrollButton
                      handleClick={scrollLeft}
                      actionDescription="Scroll to the previous group of slides"
                      icon={<IconArrowLeft aria-hidden="true" style={{ width: "30px" }} />}
                      direction="previous"
                    />
                  ) : null
                }
                rightArrow={
                  !isTouchDevice() ? (
                    <SliderScrollButton
                      handleClick={scrollRight}
                      actionDescription="Scroll to the next group of slides"
                      icon={<IconArrowRight aria-hidden="true" />}
                      direction="next"
                    />
                  ) : null
                }
                className={"dashboard__featured-carousel"}
              ></Carousel>
              {numOfGroups && numOfGroups.length > 1 && !isTouchDevice() && (
                <div className="featuredSliderIndicator">
                  {numOfGroups.map((i) => (
                    <span
                      key={i}
                      className="horizontal-slider-status-bar__step"
                      data-is-active={scrollRightClickCount === i}
                    />
                  ))}
                </div>
              )}
            </section>
          )}
        </>
      )}
    </>
  );
};

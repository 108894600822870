import React, { useEffect, useState } from "react";
import useWindowSize from "../../../../_common/hooks/useWindowSize";

/** Helpers */
import { objectToArray } from "../../../../_common/helpers";

/** UI Components */
import { AddonModifierLabel } from "./AddonModifierLabel";

/** Assets */
import { ReactComponent as IconCheck } from "../../../../_common/icons/IconCheck.svg";

export const NestedSingleChoiceAddonModifier = (props) => {
  const {
    nestedOptionGroup,
    nestedOptions,
    selectedOption,
    updateSelectedOption,
    isInCart,
    isBeingSelected,
    topLevelOption,
    instructionsText,
    mandatoryOptionClass,
    setNoNestedSelectionMade,
    setProgressiveIconClass,
    labelId,
  } = props;

  const [isCombo, setIsCombo] = useState(null);
  useEffect(() => {
    if (window.location.href.includes("combo")) {
      setIsCombo(true);
    } else {
      setIsCombo(false);
    }
  }, []);

  //if there are any selected options show the check mark
  const [showCheckMark, setShowCheckMark] = useState(
    objectToArray(nestedOptions).filter((option) => option.isSelected).length > 0 &&
      (isInCart || isBeingSelected)
  );

  useEffect(() => {
    if (nestedOptions) {
      if (
        objectToArray(nestedOptions).filter((option) => option.isSelected).length > 0 &&
        (isInCart || isBeingSelected)
      ) {
        setShowCheckMark(true);
      }
    }
  }, [nestedOptions]);

  const onRadioChange = (event) => {
    const field = event.target;
    setShowCheckMark(true);
    let tempSelectedOption = { ...selectedOption };
    if (selectedOption === null || Object.keys(selectedOption).length === 0) {
      tempSelectedOption = { ...topLevelOption };
    }

    setNoNestedSelectionMade(false);
    /** Remove the class 'error' to remove the red borders */
    const targetParentContainer = event.target.parentElement.parentElement;
    targetParentContainer.querySelectorAll("label").forEach((label) => {
      label.classList.remove("error");
    });

    const parentId = field.id.split("__")[0];
    const isSingleChoiceParent = tempSelectedOption.id;

    if (isSingleChoiceParent) {
      const optionsGroup =
        tempSelectedOption.modifierGroups["addonModifierGroup-" + field.dataset.groupId];
      optionsGroup.isSelected = true;

      const options = optionsGroup.items;
      Object.keys(options).forEach((key) => {
        delete options[key].isSelected;
      });
      options[field.dataset.keyName].isSelected = true;
    } else {
      for (let key in tempSelectedOption) {
        if (
          tempSelectedOption[key].modifierGroups &&
          (Object.keys(tempSelectedOption[key].modifierGroups).length > 0 ||
            tempSelectedOption[key].modifierGroups.length > 0)
        ) {
          const optionsGroup =
            tempSelectedOption[key].modifierGroups["addonModifierGroup-" + field.dataset.groupId];
          //compare the parent id of the options group vs. selected option
          if (optionsGroup && optionsGroup.parentId === parentId) {
            optionsGroup.isSelected = true;
            const options = optionsGroup.items;
            Object.keys(options).forEach((key) => {
              delete options[key].isSelected;
            });
            options[field.dataset.keyName].isSelected = true;
          }
        } else {
          const optionsGroup = tempSelectedOption[key];
          optionsGroup.isSelected = true;

          const options = optionsGroup.items;

          if (options) {
            Object.keys(options).forEach((key) => {
              delete options[key].isSelected;
            });
            options[field.dataset.keyName].isSelected = true;
          }
        }
      }
    }

    if (document.getElementById(labelId)) {
      document.getElementById(labelId).classList.remove("error");
    }
    updateSelectedOption(tempSelectedOption);
  };

  const deviceWidth = useWindowSize().width;

  useEffect(() => {
    setProgressiveIconClass("halfWay-progression");
  }, []);

  useEffect(() => {
    if (showCheckMark) {
      setProgressiveIconClass("full-progression");
    }
  }, [showCheckMark]);

  return (
    <>
      <header className="nested-options__form-header">
        <span>
          {nestedOptionGroup.name}
          {showCheckMark && <IconCheck />}
        </span>

        <span className={`item-detail__option-note${mandatoryOptionClass}`}>
          {instructionsText}
        </span>
      </header>

      {deviceWidth > 660 ? (
        <div>
          {Object.keys(nestedOptions).map((key) => (
            <div
              key={key}
              className="form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--radio nested-modifiers__option-wrapper"
            >
              {isCombo ? (
                <input
                  type="radio"
                  value={nestedOptions[key].id}
                  name={nestedOptionGroup.parentId + "__" + nestedOptionGroup.name}
                  id={
                    nestedOptionGroup.parentId +
                    "__option-" +
                    nestedOptions[key].parentId +
                    "-" +
                    nestedOptions[key].id +
                    "__" +
                    topLevelOption.parentId
                  }
                  className="form__field--checkbox-radio form__field--radio visually-hidden"
                  data-key-name={key}
                  onChange={onRadioChange}
                  data-group-id={nestedOptions[key].parentId}
                  //defaultChecked={nestedOptions[key].isSelected && (isInCart || isBeingSelected)}
                  checked={
                    nestedOptions[key].isSelected && (isInCart || isBeingSelected) ? true : false
                  }
                  disabled={Number(nestedOptions[key].item_count) <= 0}
                />
              ) : (
                <>
                  {deviceWidth > 660 ? (
                    <input
                      type="radio"
                      value={nestedOptions[key].id}
                      name={nestedOptionGroup.parentId + "__" + nestedOptionGroup.name}
                      id={
                        nestedOptionGroup.parentId +
                        "__option-" +
                        nestedOptions[key].parentId +
                        "-" +
                        nestedOptions[key].id +
                        "__" +
                        topLevelOption.parentId
                      }
                      className="form__field--checkbox-radio form__field--radio visually-hidden"
                      data-key-name={key}
                      onChange={onRadioChange}
                      data-group-id={nestedOptions[key].parentId}
                      //defaultChecked={nestedOptions[key].isSelected && (isInCart || isBeingSelected)}
                      checked={
                        nestedOptions[key].isSelected && (isInCart || isBeingSelected)
                          ? true
                          : false
                      }
                      disabled={Number(nestedOptions[key].item_count) <= 0}
                    />
                  ) : (
                    <input
                      type="radio"
                      value={nestedOptions[key].id}
                      name={nestedOptionGroup.parentId + "__" + nestedOptionGroup.name}
                      id={
                        nestedOptionGroup.parentId +
                        "__option-" +
                        nestedOptions[key].parentId +
                        "-" +
                        nestedOptions[key].id +
                        "__" +
                        topLevelOption.parentId
                      }
                      className="form__field--checkbox-radio form__field--radio visually-hidden"
                      data-key-name={key}
                      onChange={onRadioChange}
                      data-group-id={nestedOptions[key].parentId}
                      //defaultChecked={nestedOptions[key].isSelected && (isInCart || isBeingSelected)}
                      disabled={Number(nestedOptions[key].item_count) <= 0}
                      checked={
                        nestedOptions[key].isSelected && (isInCart || isBeingSelected)
                          ? true
                          : false
                      }
                    />
                  )}
                </>
              )}

              <AddonModifierLabel
                option={nestedOptions[key]}
                isRadio={true}
                isNested={true}
                topOptionGroupId={nestedOptionGroup.parentId}
                inputId={
                  nestedOptionGroup.parentId +
                  "__option-" +
                  nestedOptions[key].parentId +
                  "-" +
                  nestedOptions[key].id +
                  "__" +
                  topLevelOption.parentId
                }
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {Object.keys(nestedOptions).map((key) => (
            <div
              key={key}
              className="form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--radio nested-modifiers__option-wrapper"
            >
              {isCombo ? (
                <input
                  type="radio"
                  value={nestedOptions[key].id}
                  name={nestedOptionGroup.parentId + "__" + nestedOptionGroup.name}
                  id={
                    nestedOptionGroup.parentId +
                    "__option-" +
                    nestedOptions[key].parentId +
                    "-" +
                    nestedOptions[key].id +
                    "__" +
                    topLevelOption.parentId
                  }
                  className="form__field--checkbox-radio form__field--radio visually-hidden"
                  data-key-name={key}
                  onChange={onRadioChange}
                  data-group-id={nestedOptions[key].parentId}
                  //defaultChecked={nestedOptions[key].isSelected && (isInCart || isBeingSelected)}
                  checked={
                    nestedOptions[key].isSelected && (isInCart || isBeingSelected) ? true : false
                  }
                  disabled={Number(nestedOptions[key].item_count) <= 0}
                />
              ) : (
                <>
                  {deviceWidth > 660 ? (
                    <input
                      type="radio"
                      value={nestedOptions[key].id}
                      name={nestedOptionGroup.parentId + "__" + nestedOptionGroup.name}
                      id={
                        nestedOptionGroup.parentId +
                        "__option-" +
                        nestedOptions[key].parentId +
                        "-" +
                        nestedOptions[key].id +
                        "__" +
                        topLevelOption.parentId
                      }
                      className="form__field--checkbox-radio form__field--radio visually-hidden"
                      data-key-name={key}
                      onChange={onRadioChange}
                      data-group-id={nestedOptions[key].parentId}
                      //defaultChecked={nestedOptions[key].isSelected && (isInCart || isBeingSelected)}
                      checked={
                        nestedOptions[key].isSelected && (isInCart || isBeingSelected)
                          ? true
                          : false
                      }
                      disabled={Number(nestedOptions[key].item_count) <= 0}
                    />
                  ) : (
                    <input
                      type="radio"
                      value={nestedOptions[key].id}
                      name={nestedOptionGroup.parentId + "__" + nestedOptionGroup.name}
                      id={
                        nestedOptionGroup.parentId +
                        "__option-" +
                        nestedOptions[key].parentId +
                        "-" +
                        nestedOptions[key].id +
                        "__" +
                        topLevelOption.parentId
                      }
                      className="form__field--checkbox-radio form__field--radio visually-hidden"
                      data-key-name={key}
                      onChange={onRadioChange}
                      data-group-id={nestedOptions[key].parentId}
                      //defaultChecked={nestedOptions[key].isSelected && (isInCart || isBeingSelected)}
                      disabled={Number(nestedOptions[key].item_count) <= 0}
                      checked={
                        nestedOptions[key].isSelected && (isInCart || isBeingSelected)
                          ? true
                          : false
                      }
                    />
                  )}
                </>
              )}

              <AddonModifierLabel
                option={nestedOptions[key]}
                isRadio={true}
                isNested={true}
                topOptionGroupId={nestedOptionGroup.parentId}
                inputId={
                  nestedOptionGroup.parentId +
                  "__option-" +
                  nestedOptions[key].parentId +
                  "-" +
                  nestedOptions[key].id +
                  "__" +
                  topLevelOption.parentId
                }
                id={
                  nestedOptionGroup.parentId +
                  "__option-" +
                  nestedOptions[key].parentId +
                  "-" +
                  nestedOptions[key].id +
                  "__" +
                  topLevelOption.parentId +
                  "-label"
                }
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};

import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import GooglePayButton from "@google-pay/button-react";
import countryToCurrency from "country-to-currency";
import AppLabelsContext from "../../../../App/AppLabelsContext";

const PaymentMethodGooglePayButton = (props) => {
  const { activeOrderStore, orderTotal, validateOrderTime, appLanguage, submitGooglePayOrder } =
    props;

  const [preventDefaultBehaviour, setPreventDefaultBehaviour] = useState(true);

  const isLocalhost =
    process.env.NODE_ENV === "development" ||
    window.location.hostname.includes("alpha") ||
    window.location.hostname.includes("beta") ||
    window.location.hostname.includes("dilbert");
  const storeWithVexilorConfig = activeOrderStore.vexilorConfig;
  const allowedCardNetworks = storeWithVexilorConfig.payment_methods["CREDIT"]
    .map((allowedCards) => allowedCards[0])
    .filter((entry) => !entry.toLowerCase().includes("other"));

  const GOOGLE_PAY_PRODUCTION_ID = "3336759522887621560";
  const GOOGLE_PAY_TEST_ID = "BCR2DN4TZLH43BBN";

  const currencyCode =
    countryToCurrency[
      activeOrderStore.country === "UK" ? "GB" : activeOrderStore.country
    ].toString();

  const googlePayConfigGateway = storeWithVexilorConfig.google_pay_config.gateway;

  const googlePayConfigGatewayMechantId =
    storeWithVexilorConfig.google_pay_config.gateway_merchant_id;

  const appLabels = useContext(AppLabelsContext);

  return ReactDOM.createPortal(
    <div className="google-pay-checkout-button__container">
      <GooglePayButton
        aria-label={appLabels["order"]["pay-with-google-pay"]}
        environment={isLocalhost ? "TEST" : "PRODUCTION"}
        buttonSizeMode="fill"
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: allowedCardNetworks,
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: googlePayConfigGateway,
                  gatewayMerchantId: googlePayConfigGatewayMechantId,
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: isLocalhost ? GOOGLE_PAY_TEST_ID : GOOGLE_PAY_PRODUCTION_ID,
            merchantName: "Givex Corp",
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: Number(orderTotal).toFixed(2).toString(),
            currencyCode: currencyCode,
            countryCode: activeOrderStore.country === "UK" ? "GB" : activeOrderStore.country,
          },
          shippingAddressRequired: false,
          callbackIntents: ["PAYMENT_AUTHORIZATION"],
          emailRequired: true,
        }}
        onClick={async (event) => {
          if (preventDefaultBehaviour) {
            event.preventDefault();
            const isValidOrderTime = await validateOrderTime();
            if (isValidOrderTime) {
              setPreventDefaultBehaviour(false);
              document.getElementsByClassName("gpay-button")[0].click();
              return true;
            }
          }
        }}
        onCancel={(reason) => {
          setPreventDefaultBehaviour(true);
        }}
        onLoadPaymentData={(paymentRequest) => {
          submitGooglePayOrder(paymentRequest);
        }}
        onPaymentAuthorized={(paymentData) => {
          if (paymentData) {
            return { transactionState: "SUCCESS" };
          } else {
            return {
              transactionState: "ERROR",
              error: {
                reason: "PAYMENT_DATA_INVALID",
                message: "Cannot pay with payment credentials",
                intent: "PAYMENT_AUTHORIZATION",
              },
            };
          }
        }}
        buttonLocale={appLanguage}
        buttonColor="default"
        buttonType="checkout"
      />
    </div>,
    document.body
  );
};

export default PaymentMethodGooglePayButton;

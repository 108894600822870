export const fetchJSON = async (path) => {
  try {
    const headers = new Headers();

    // force browser to check the server to see if the file is different from the file it already has in the cache
    headers.append("Cache-Control", "no-cache");

    const init = {
      headers: headers,
    };

    const res = await fetch(path, init);
    const data = await res.json();

    return data;
    //
  } catch (error) {
    // Fetch error
    console.error(`Fetch error | ${error}`);
    return false;
  }
};

import React, { useEffect, useContext, useState } from "react";
import useWindowSize from "../../../_common/hooks/useWindowSize";

/** Contexts */
import AppLabelsContext from "../../../App/AppLabelsContext";

/** UI Components */
import { MenuComboItem } from "./MenuComboItem";
import { LoadingSpinner } from "../../../_common/LoadingSpinner";
import { CaloriesDisclaimer } from "../CaloriesDisclaimer";
import { AdditionalDisclaimer } from "../../../_common/AdditionalDisclaimer";
import { MenuComboItemDetail } from "./MenuComboItemDetail";

/** Helpers */
import { isSafariBrowser } from "../../../_common/isSafariBrowser";

/** Assets */
import { ReactComponent as IconCheck } from "../../../_common/icons/IconCheck.svg";

export const MenuComboItems = (props) => {
  const {
    sliderItems,
    isAtLeastOneCalorie,
    comboCartItem,
    mainComboItemName,
    displayType,
    cartItem,
    comboChildImage,
    currentSubgroupNumber,
    updateActiveItem,
    isModifyDisplay,
    isComboChildItem,
    isQuantityCombo,
    subGroupQuantityReached,
    groupIsComplete,
    mainComboId,
    comboSubgroupNumber,
    setValidateGoingToNextLevel,
    setIsEditingComboItem,
  } = props;

  const deviceWidth = useWindowSize().width;
  const showSelectedItem = () => {
    if (document.getElementsByClassName("menu-slider__item is-selected")[0]) {
      document
        .getElementsByClassName("menu-slider__item is-selected")[0]
        .scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    if (displayType === "menu") {
      setTimeout(() => {
        intiScroll();
      }, 300);
    }
  }, [sliderItems]);

  const intiScroll = () => {
    if (document.getElementsByClassName("menu-combo-slider")[0]) {
      document.getElementsByClassName("menu-combo-slider")[0].scrollTo(0, 0);
    }
  };

  const appLabels = useContext(AppLabelsContext);
  const [isModal, setIsModal] = useState(false);

  const [isRelatedComboItem, setIsRelatedComboItem] = useState(false);
  const [selectedComboChildItem, setSelectedComboChildItem] = useState(null);

  //const [showCheckMark, setShowCheckMark] = useState(false);
  const showCheckMark = groupIsComplete(currentSubgroupNumber);

  const itemIsSelected = (itemId, groupId) => {
    let selected = false;
    if (comboCartItem) {
      comboCartItem.combo_child_items.forEach((item) => {
        if (item.id === itemId && item.group_id === groupId) {
          selected = true;
        }
      });
    }

    return selected;
  };

  const getSelectedItemsNames = (sliderItems) => {
    let selectedItems = [];

    Object.keys(sliderItems).forEach((key) => {
      const sliderItem = sliderItems[key];
      if (itemIsSelected(sliderItem.id, sliderItem.group_id)) {
        selectedItems.push(sliderItem.name);
      }
    });

    return selectedItems.join(", ");
  };

  return (
    <>
      <div className="order-item__option-header space-between-wrapper space-between-wrapper--center-aligned combo-subgroup-header" aria-atomic="false" aria-live="polite" role="contentinfo">
        <div className="heading-with-icon combo">
          <h2 className="order-item__option-heading heading-with-icon">
            <span className="visually-hidden">{appLabels["order"]["on-step"]} {currentSubgroupNumber}.</span>
            <span>
              {appLabels["order"]["choice-n-of-total"]
                .replace("[current-choice]", currentSubgroupNumber)
                .replace("[total-choices]", comboSubgroupNumber)}
              {showCheckMark && <IconCheck />}
            </span>
            {(sliderItems[Object.keys(sliderItems)[0]].groupMinQty === "" || sliderItems[Object.keys(sliderItems)[0]].groupMinQty === undefined) && <span className="visually-hidden">. {appLabels["order"]["select-item-to-continue"]} </span>}
          </h2>
          {isQuantityCombo && !!sliderItems && (
            <p className="quantity-combo-details">
              <span
                className={`${subGroupQuantityReached(currentSubgroupNumber) ? "is-complete" : ""}`}
              >
                {deviceWidth >= 660 && "("}

                {appLabels["order"]["select-n-items"].replace(
                  "[min-quantity]",
                  sliderItems[Object.keys(sliderItems)[0]].groupMinQty
                )}

                {deviceWidth >= 660 && ")"}
              </span>{" "}
              <span>{getSelectedItemsNames(sliderItems)}</span>
            </p>
          )}
        </div>
        {/*<div className="item-detail__option-text-container">
      {confirmedOptionsText ? (
        <div className="item-detail__option-note">{confirmedOptionsText}</div>
      ) : (
        <div className={`item-detail__option-note${mandatoryOptionClass}`}>
          {instructionsText}
        </div>
      )}
      </div>*/}
      </div>

      <div
        className={`menu-combo-slider-container menu-container desktop-container mobile-container`}
        style={isModal && isSafariBrowser() ? { position: "fixed", height: "100%" } : {}}
      >
        {/*
      <p>{appLabels["order"]["combo-choose-item"]}</p>
  */}

        {deviceWidth < 660 && <span className="menu-slider__item-spacing">&nbsp;</span>}
        <ul className="menu__category-cards-grid" style={isModal ? { display: "none" } : {}}>
          {!!sliderItems ? (
            <>
              {Object.keys(sliderItems).map((key, index) => (
                <MenuComboItem
                  key={sliderItems[key].id}
                  comboItem={sliderItems[key]}
                  comboCartItem={comboCartItem}
                  //mainComboItemName={mainComboItemName}
                  //displayType={displayType}
                  //cartItem={cartItem}
                  showSelectedItem={showSelectedItem}
                  //isModal={isModal}
                  setIsModal={setIsModal}
                  childImage={
                    comboChildImage && comboChildImage[index] ? comboChildImage[index] : ""
                  }
                  setSelectedComboChildItem={setSelectedComboChildItem}
                  setIsRelatedComboItem={setIsRelatedComboItem}
                  updateActiveItem={updateActiveItem}
                  isModifyDisplay={isModifyDisplay}
                  isComboChildItem={isComboChildItem}
                  isQuantityCombo={isQuantityCombo}
                  mainComboId={mainComboId}
                  subGroupQuantityReached={subGroupQuantityReached}
                  setValidateGoingToNextLevel={setValidateGoingToNextLevel}
                  setIsEditingComboItem={setIsEditingComboItem}
                />
              ))}
              <li className="menu-slider__item-spacing">&nbsp;</li>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </ul>

        {isModal && isRelatedComboItem && selectedComboChildItem && (
          <MenuComboItemDetail
            item={selectedComboChildItem}
            closeModal={() => {
              setIsModal(false);
              setIsRelatedComboItem(false);
              setSelectedComboChildItem(null);
            }}
            displayType={displayType}
            comboCartItem={comboCartItem}
            mainComboItemName={mainComboItemName}
            cartItem={cartItem}
            currentSubgroupNumber={currentSubgroupNumber}
          />
        )}

        {isAtLeastOneCalorie && appLabels["order"]["calories-disclaimer"] && (
          <CaloriesDisclaimer caloriesDisclaimer={appLabels["order"]["calories-disclaimer"]} />
        )}
        <AdditionalDisclaimer disclaimer={appLabels["order"]["menu-item-disclaimer"]} />
      </div>
    </>
  );
};

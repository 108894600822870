//Libraries
import React, { useEffect, useRef, useState } from "react";

//Hooks
import useWindowSize from "../_common/hooks/useWindowSize";

//Helpers
import { isTouchDevice } from "../_common/helpers/isTouchDevice";

//UI Elements
import { ReactComponent as IconArrowLeft } from "../_common/icons/IconArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../_common/icons/IconArrowRight.svg";

//CSS
import "../Dashboard/DashboardCardsSlider/DashboardCardsSlider.css";
import { GiftCardSlide } from "./GiftCardSlide";

const SLIDER_RIGHT_PADDING = 44;

const ARROW_BUTTON_WIDTH = 40;

export const GiftCardCarousel = ({ slidesData, selectedGiftCard = null, customClass = "", isCompactCarousel = false }) => {
  const listRef = useRef(null);
  const MARGIN_BETWEEN_SLIDES = isCompactCarousel ? 12 : 10;
  const currentTransformX = useRef(0);
  const [totalSlideGroups, setTotalSlideGroups] = useState(0);

  const [numberOfSlidesInView, setNumberOfSlidesInView] = useState(1);
  const [inViewSlidesGroupIndex, setInViewSlidesGroupIndex] = useState(0);

  const [isLeftScrollPossible, setIsLeftScrollPossible] = useState(false);
  const [isRightScrollPossible, setIsRightScrollPossible] = useState(false);

  useEffect(() => {

    if (listRef.current) {
      const list = listRef.current;
      const listWidth = list.getBoundingClientRect().width;

      if (list.scrollWidth - SLIDER_RIGHT_PADDING > listWidth) {
        setIsRightScrollPossible(true);
      }
    }
  }, []);

  const deviceWidth = useWindowSize().width;
  useEffect(() => {
    if (isCompactCarousel) {
      if (deviceWidth >= 660 && deviceWidth < 1350) setNumberOfSlidesInView(4);
      else if (deviceWidth >= 1350) setNumberOfSlidesInView(4);
      else if (deviceWidth < 660) setNumberOfSlidesInView(1);
    }
    else {
      if (deviceWidth >= 660 && deviceWidth < 850) setNumberOfSlidesInView(3);
      else if (deviceWidth >= 850) setNumberOfSlidesInView(4);
      else if (deviceWidth < 660) setNumberOfSlidesInView(1);
    }
  }, [deviceWidth]);

  useEffect(() => {
    if (listRef.current) {
      const list = listRef.current;
      const listWidth = list.getBoundingClientRect().width;
      const numberOfSlides = slidesData.length;
      const listItemWidth = list.firstChild.getBoundingClientRect().width;

      let totalSlideGroups = (listItemWidth * numberOfSlides) / (listWidth);

      totalSlideGroups = Math.ceil(totalSlideGroups)
      if (totalSlideGroups === 2 && numberOfSlides === 7) {
        totalSlideGroups = 3;
      }

      setTotalSlideGroups(totalSlideGroups);
    }
  }, [numberOfSlidesInView]);

  const scrollLeft = () => {
    const list = listRef.current;
    const allInViewSlidesWidth =
      (list.firstChild.getBoundingClientRect().width + MARGIN_BETWEEN_SLIDES) *
      numberOfSlidesInView;

    list.style.transform =
      inViewSlidesGroupIndex - 1 === 0
        ? "translateX(0)"
        : `translateX(${currentTransformX.current + allInViewSlidesWidth + 120}px)`;

    currentTransformX.current =
      inViewSlidesGroupIndex - 1 === 0
        ? 0
        : currentTransformX.current + allInViewSlidesWidth - ARROW_BUTTON_WIDTH;

    const prevSlidesGroupIndex = inViewSlidesGroupIndex - 1;
    if (prevSlidesGroupIndex === 0) {
      setIsLeftScrollPossible(false);
    }
    setIsRightScrollPossible(true);
    setInViewSlidesGroupIndex((prevIndex) => prevIndex - 1);
  };

  const scrollRight = () => {
    const list = listRef.current;
    const allInViewSlidesWidth =
      (list.firstChild.getBoundingClientRect().width + MARGIN_BETWEEN_SLIDES) *
      4;
    list.style.transform = `translateX(${currentTransformX.current -
      (inViewSlidesGroupIndex === 0
      ? allInViewSlidesWidth - ARROW_BUTTON_WIDTH
      : allInViewSlidesWidth)
      }px)`;

    currentTransformX.current =
      currentTransformX.current -
      (inViewSlidesGroupIndex === 0
        ? allInViewSlidesWidth - ARROW_BUTTON_WIDTH
        : allInViewSlidesWidth);

    if (!isLeftScrollPossible) setIsLeftScrollPossible(true);

    const nextSlidesGroupIndex = inViewSlidesGroupIndex + 1;
    if (nextSlidesGroupIndex === totalSlideGroups - 1) {
      setIsRightScrollPossible(false);
    }
    setInViewSlidesGroupIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <div>
      <div className={`dashboard__horizontal-slider-wrapper giftCardSliderWrapper ${isCompactCarousel ? "compactGiftCardSliderWrapper" : ""} `} >
        {!isTouchDevice() && isLeftScrollPossible && (
          <SliderScrollButton
            handleClick={scrollLeft}
            actionDescription="Scroll to the previous group of slides"
            icon={<IconArrowLeft aria-hidden="true" />}
            direction="previous"
            isCompactCarousel={isCompactCarousel}
          />
        )}
        <ul
          ref={listRef}
          className={`dashboard-horizontal-slider__list dashboard-horizontal-slider__list--${numberOfSlidesInView}-in-view  giftCardMobileSlider`}

        >
          {slidesData.map((giftCard, index) => {
            return (<React.Fragment key={giftCard.id}>
              <GiftCardSlide giftCard={giftCard} allGiftCards={slidesData} isQuickSelected={selectedGiftCard ? true : false} selectedGiftCard={selectedGiftCard || null} customClass={"carousel_giftCardListWrapper " + customClass} showTooltip={true} />

            </React.Fragment>)
          })}
          <li style={{ width: `${SLIDER_RIGHT_PADDING}px` }}>&nbsp;</li>
        </ul>
        {!isTouchDevice() &&
          listRef.current &&
          listRef.current.scrollWidth - SLIDER_RIGHT_PADDING >
          listRef.current.getBoundingClientRect().width &&
          isRightScrollPossible && (
            <SliderScrollButton
              handleClick={scrollRight}
              actionDescription="Scroll to the next group of slides"
              icon={<IconArrowRight aria-hidden="true" />}
              direction="next"
            isCompactCarousel={isCompactCarousel}
            />
          )}
      </div>

    </div>
  );
};

const SliderScrollButton = ({ handleClick, actionDescription, icon, direction, isCompactCarousel = false }) => {
  return (
    <button
      onClick={handleClick}
      className={`dashboard__horizontal-slider-button dashboard__horizontal-slider-${direction}-button ${isCompactCarousel ? "compactGiftCardSliderArrowButton" : ""}`}
      style={{ width: `${isCompactCarousel ? "30" : ARROW_BUTTON_WIDTH}px` }}
      type="button"
    >
      <span className="visually-hidden">{actionDescription}</span>
      {icon}
    </button>
  );
};

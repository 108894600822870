// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";

// Contexts
import LocationsContext from "../Locations/LocationsContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helper functions
import {
  getOrderItemDetails,
  getOrderStatusFromProgressBar,
  getOrderStoreAddress,
  getTotalPointsRedeemed,
  removeDuplicatesAndUpdateCount,
} from "./orderHistoryHelpers";
import { capitalizeWords } from "../../_common/stringHelpers";

// UI components
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { OrderStatusProgressBar } from "../OrderStatus/OrderStatusProgressBar";
import { RecentOrderOptionsList } from "./RecentOrderOptionsList";
import StarIcon from "@material-ui/icons/Star";
import { jsonCopy, toDollars } from "../../_common/helpers";
import { LoadingSpinner } from "../../_common/LoadingSpinner";

export const ActiveOrdersCard = (props) => {
  const { order, activeOrdersLength, appLabels, appLanguage, formatOrderDateAndTime } = props;

  const { skin } = useContext(MerchantConfigContext);
  const allStores = useContext(LocationsContext);
  const appSettings = useContext(AppSettingsContext);

  const deviceWidth = useWindowSize().width;

  const [showDetails, setShowDetails] = useState(() => {
    if (activeOrdersLength === 1) {
      return true;
    }
    return false;
  });

  const showDetailsButtonRef = useRef(null);

  const isAddonQuantityEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";

  const handleShowDetails = () => {
    const currentState = showDetails;
    setShowDetails(!currentState);
  };

  useEffect(() => {
    if (showDetails) {
      showDetailsButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showDetails]);

  // const orderStatus = order.orderStatus.status;

  const [uniqueOrderItems, setUniqueOrderItems] = useState(null);
  const [orderWithDetails, setOrderWithDetails] = useState(null);
  useEffect(() => {
    if (showDetails && !uniqueOrderItems) {
      setOrderWithDetails(null);
      getOrderItemDetails(skin, order.id, order.storeLocation.id, appLanguage).then(
        (itemDetails) => {
          let orderWithDetails = jsonCopy(order);
          orderWithDetails.discountTotal = itemDetails.discountTotal;
          orderWithDetails.items = itemDetails.items;
          orderWithDetails.paymentDetails = itemDetails.paymentDetails;
          orderWithDetails.rewardDetails = itemDetails.rewardDetails;
          orderWithDetails.orderAddress = itemDetails.orderAddress;
          setOrderWithDetails(orderWithDetails);
          const uniqueItems = removeDuplicatesAndUpdateCount(
            orderWithDetails.items,
            isAddonQuantityEnabled
          );
          setUniqueOrderItems(uniqueItems);
        }
      );
    }
  }, [showDetails]);

  let paymentRewardDetailsAvailable = false;

  if (order) {
    paymentRewardDetailsAvailable =
      (order.paymentDetails && order.paymentDetails.length > 0) ||
      (order.rewardDetails && order.rewardDetails.length > 0)
        ? true
        : false;
  }

  return (
    <div className="recent-order">
      <section className="recent-order__header">
        <span>{formatOrderDateAndTime(order.date)}</span>
        <span>#{order.id}</span>
      </section>
      <section className="recent-order__main">
        {((order.orderStatus.status !== "delivery_completed" && order.orderType !== "Pickup") ||
          (order.orderStatus.status !== "closed" && order.orderType === "Pickup")) &&
          order.orderStatus.isEnabled &&
          order.orderStatus.isFinished !== true &&
          !showDetails && (
            <h3 className="order-status__order-details-status-header">
              {deviceWidth >= 660 && <div className="recent-order__active-order-checkmark"></div>}
              {`${getOrderStatusFromProgressBar(order, appLabels)}`}
            </h3>
          )}
        <div ref={showDetailsButtonRef}>{getOrderStoreAddress(order, appLabels, true)}</div>
      </section>
      <section className="recent-order__footer-active-orders">
        {showDetails && uniqueOrderItems && orderWithDetails && (
          <div className="order-status__transition">
            <div className="order-status__steps-wrapper">
              <OrderStatusProgressBar order={order} />
            </div>

            {allStores &&
              !!allStores.find((store) => store.storeId === orderWithDetails.storeLocation.id) && (
                <p className="order-status__order-details-disclaimer">
                  {appLabels["account"]["order-status-make-changes-blurb"]
                    .replace(
                      "[phone-number]",
                      allStores.find((store) => store.storeId === orderWithDetails.storeLocation.id)
                        .phone
                    )
                    .split("+1")
                    .slice(0, -1)
                    .join(" ")}
                  <strong>
                    {appLabels["account"]["order-status-make-changes-blurb"]
                      .replace(
                        "[phone-number]",
                        allStores.find(
                          (store) => store.storeId === orderWithDetails.storeLocation.id
                        ).phone
                      )
                      .split("+1")
                      .pop()}
                  </strong>
                </p>
              )}

            {orderWithDetails.items && !!orderWithDetails.items.length && (
              <>
                <h3 className="order-status__order-details-header">
                  {`${capitalizeWords(
                    appLabels["account"]["order-details"].replaceAll("_", " ").trim()
                  )}`}
                </h3>
                <ul className="order-status__order-details-items-list">

                {uniqueOrderItems.map((item, index) => (
                  <React.Fragment key={item.name + "-" + index}>
                    <li className="recent-order__item">
                      <h2 className="recent-order__item-name-price">
                        <span className="recent-order__order-units">{item.quantity}</span>
                        <span className="recent-order__item-name">{item.name}</span>
                        <span className="recent-order__item-price">
                          {`
                          ${toDollars(
                            appSettings["currency-symbol"],
                            appSettings["currency-symbol-side"],
                            item.price,
                            appLanguage
                          )}
                          `}
                        </span>
                      </h2>
                      {item.modifiers && (
                        <RecentOrderOptionsList
                          type="modifier"
                          options={item.modifiers}
                          isAddonQuantityEnabled={isAddonQuantityEnabled}
                        />
                      )}
                      {item.addons && (
                        <RecentOrderOptionsList
                          type="addon"
                          options={item.addons}
                          isAddonQuantityEnabled={isAddonQuantityEnabled}
                        />
                      )}
                      {item.combo_child_items && (
                        <ul
                          className="recent-order__combo-child-items"
                          key={"combo-child-item-" + item.id + "-" + index}
                        >
                          {item.combo_child_items.map((childItem, childItemIndex) => (
                            <React.Fragment key={childItem.id + "-" + childItemIndex}>
                              <li>
                                <h2 className="recent-order__combo-child-item-name">
                                  {childItem.name}
                                </h2>
                                {!!childItem.modifierGroups.length && (
                                  <RecentOrderOptionsList
                                    type="modifier"
                                    options={childItem.modifierGroups}
                                    isAddonQuantityEnabled={isAddonQuantityEnabled}
                                  />
                                )}
                                {!!childItem.addonGroups.length && (
                                  <RecentOrderOptionsList
                                    type="addon"
                                    options={childItem.addonGroups}
                                    isAddonQuantityEnabled={isAddonQuantityEnabled}
                                  />
                                )}
                              </li>
                            </React.Fragment>
                          ))}
                        </ul>
                      )}
                    </li>
                  </React.Fragment>
                ))}
                  <li className="recent-order__tip-total-active-orders">
                  {orderWithDetails.discountTotal &&
                    parseFloat(orderWithDetails.discountTotal) > 0 && (
                      <p className="recent-order__tip-discount-active-orders">
                        <span>{appLabels["order"]["discount"]}</span>
                        <span>-${orderWithDetails.discountTotal}</span>
                      </p>
                    )}
                  {orderWithDetails.paymentDetails &&
                    orderWithDetails.paymentDetails.length > 0 &&
                    orderWithDetails.paymentDetails[0].paymentTip &&
                    parseFloat(orderWithDetails.paymentDetails[0].paymentTip) > 0 && (
                      <p className="recent-order__tip-discount-active-orders">
                        <span>{appLabels["order"]["tip"]}</span>
                        <span>{orderWithDetails.paymentDetails[0].paymentTip}</span>
                      </p>
                    )}
                  <p
                    className={`recent-order__total-active-orders ${
                      paymentRewardDetailsAvailable ? " recent-order__border-bottom" : ""
                    }`}
                  >
                    <span>
                      {appLabels["general"]["total"]}
                      {": "}
                    </span>
                    <span>{orderWithDetails.total}</span>
                  </p>
                  <section>
                    {orderWithDetails.paymentDetails &&
                      orderWithDetails.paymentDetails.length > 0 &&
                      orderWithDetails.paymentDetails[0].pointsEarned &&
                      orderWithDetails.paymentDetails[0].pointsEarned !== null &&
                      orderWithDetails.paymentDetails[0].pointsEarned !== "" &&
                      !isNaN(orderWithDetails.paymentDetails[0].pointsEarned) && (
                        <p className="recent-order__footer-current-order">
                          <span>{appLabels["order"]["points-earned"]}</span>
                          <span className="recent-order__footer-points">
                            +{parseInt(orderWithDetails.paymentDetails[0].pointsEarned)}
                            <StarIcon className="recent-order__footer-star-icon" />
                          </span>
                        </p>
                      )}
                    {orderWithDetails.rewardDetails &&
                      orderWithDetails.rewardDetails.length > 0 && (
                        <p className="recent-order__footer-current-order">
                          <span>{appLabels["order"]["points-redeemed"]}</span>
                          <span className="recent-order__footer-points">
                            -{getTotalPointsRedeemed(orderWithDetails.rewardDetails)}
                            <StarIcon className="recent-order__footer-star-icon" />
                          </span>
                        </p>
                      )}
                    {orderWithDetails.paymentDetails &&
                      orderWithDetails.paymentDetails.length > 0 && (
                        <p className="recent-order__footer-current-order">
                          <span>
                            {appLabels["order"]["charged-to"]}{" "}
                            {orderWithDetails.paymentDetails[0].paymentType}
                          </span>
                          {orderWithDetails.paymentDetails[0].paymentCard !== "None" && (
                            <span>{orderWithDetails.paymentDetails[0].paymentCard}</span>
                          )}
                        </p>
                      )}
                  </section>
                  </li>
              </ul>
              </>
            )}
          </div>
        )}
        <button className="recent-order__expand-more" aria-label={showDetails ? appLabels["order"]["collapse-order-details"] : appLabels["order"]["expand-order-details"]} onClick={handleShowDetails}>
          {!showDetails ? <ExpandMore /> : <ExpandLess />}
        </button>
      </section>
      {showDetails && !uniqueOrderItems && <LoadingSpinner />}
    </div>
  );
};

import React, { useContext, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

/** Custom Hookds */
import useWindowSize from "../_common/hooks/useWindowSize";

/** Contexts */
import { MenuContext } from "./Menu/MenuContext";
import CartContext from "./Cart/CartContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import AppSettingsContext from "../App/AppSettingsContext";

/** Helper Functions */
import { jsonCopy, objectToArray } from "../_common/helpers";
import {
  cleanUpItemsListBasedOnQuantityForCart,
  separateItemsList,
} from "./Menu/helpers/orderingHelpers";
import { onImpression, onProductClick } from "../_common/helpers/dataLayerHelpers";
import { onImpressionXtreme, onProductClickXtreme } from "../_common/helpers/xtremePushHelper";

/** UI Components */
import { MenuCategoryFilterButtonsList } from "./Menu/MenuCategoryFilterButtonsList/MenuCategoryFilterButtonsList";
import { MenuBreadcrumbs } from "./Menu/MenuBreadcrumbs";
import { MenuCategoryCard } from "./Menu/MenuCategoryCard/MenuCategoryCard";

export const MenuItemsLevel = () => {
  const orderMenu = useContext(MenuContext);
  const menuLevel = orderMenu.menuLevel;

  const cartContext = useContext(CartContext);
  const cart = cartContext.value || [];

  const { skin } = useContext(MerchantConfigContext);
  const history = useHistory();
  const { category, subcategory } = useParams();

  const appSettings = useContext(AppSettingsContext);
  const isAddonQuantityEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";

  const categoryData =
    menuLevel === "threeLevels" && category
      ? orderMenu.apiData[`category-${category}`].subcategories[`subcategory-${subcategory}`]
      : orderMenu.apiData[`subcategory-${subcategory}`];

  const items = categoryData && categoryData.items;

  useEffect(() => {
    if (orderMenu) {
      const tempMenuLevel = orderMenu.menuLevel;
      const temoCategoryData =
        tempMenuLevel === "threeLevels" && category
          ? orderMenu.apiData[`category-${category}`].subcategories[`subcategory-${subcategory}`]
          : orderMenu.apiData[`subcategory-${subcategory}`];

      const tempItems = temoCategoryData && temoCategoryData.items;
      if (!tempItems || Object.keys(tempItems).length === 0) {
        history.push("/online-ordering/menu");
      }
    }
  }, [orderMenu]);

  useEffect(() => {
    orderMenu.updateMenu({
      ...orderMenu,
      activeTopCategory: category,
      activeSubcategory: subcategory,
    });

    if (
      (history.location.state && history.location.state !== "from-item-page") ||
      !history.location.state
    ) {
      onImpression(objectToArray(items), category, subcategory);
      onImpressionXtreme(objectToArray(items), category, subcategory, skin);
    }
  }, [category, subcategory]);

  const goToItemDetails = (id, type, itemData) => {
    if (menuLevel === "threeLevels") {
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: category,
        activeSubcategory: subcategory,
        activeItem: id,
      });

      onProductClick(itemData, category, subcategory);
      onProductClickXtreme(itemData, category, subcategory, skin);

      if (type === "productItem") {
        history.push(
          `/online-ordering/menu/category/${category}/subcategory/${subcategory}/item/${id}`
        );
      } else if (
        type === "comboItem" &&
        (itemData["item_count"] === "None" || Number(itemData["item_count"]) > 0)
      ) {
        history.push(
          `/online-ordering/menu/category/${category}/subcategory/${subcategory}/combo/${id}`
        );
      }
      //
    } else if (menuLevel === "twoLevels") {
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: null,
        activeSubcategory: subcategory,
        activeItem: id,
      });

      onProductClickXtreme(itemData, category, subcategory, skin);

      if (type === "productItem") {
        history.push({
          pathname: `/online-ordering/menu/subcategory/${subcategory}/item/${id}`,
          state: { from: "item-card" },
        });
      } else if (type === "comboItem") {
        history.push({
          pathname: `/online-ordering/menu/subcategory/${subcategory}/combo/${id}`,
          state: { from: "item-card" },
        });
      }
    }
  };

  const deviceWidth = useWindowSize().width;

  useEffect(() => {
    /* Clean up and consolidate the cart items based on their quantity for the customization popup */
    cleanUpItemsListBasedOnQuantityForCart(
      separateItemsList(jsonCopy(cart)),
      cartContext,
      isAddonQuantityEnabled
    );
  }, []);

  const consolidateCart = useRef(true);
  useEffect(() => {
    const consolidate = async () => {
      if (consolidateCart.current) {
        consolidateCart.current = false;
        const data = await cleanUpItemsListBasedOnQuantityForCart(
          separateItemsList(jsonCopy(cart)),
          cartContext,
          isAddonQuantityEnabled
        );
        if (data) {
          setTimeout(() => {
            consolidateCart.current = true;
          }, 500);
        }
      }
    };
    consolidate();
  }, [cart]);

  return (
    <>
      {deviceWidth >= 660 && <MenuCategoryFilterButtonsList />}
      <MenuBreadcrumbs currentLevelCategory={categoryData} />
      <ul className="menu__category-cards-grid">
        {items &&
          Object.keys(items).map((key, index) => (
            <MenuCategoryCard
              key={`${items[key].id}-${index}`}
              handleClick={goToItemDetails}
              index={index}
              itemData={{
                ...items[key],
                category: category,
                subcategory: subcategory,
                onlineQuantityLimit:
                  !!items[key]["online_qty_limit"] && items[key]["online_qty_limit"] !== "None"
                    ? items[key]["online_qty_limit"]
                    : null,
              }}
            />
          ))}
      </ul>
    </>
  );
};

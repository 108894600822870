// Libraries
import React, { useEffect, useState } from "react";

// Helper functions
import { toDollars, get } from "../../_common/helpers";

import { LoadingSpinner } from "../../_common/LoadingSpinner";

// CSS
import "../../OnlineOrdering/Cart/CartSummary.css";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import { useContext } from 'react';
import "./giftCardCheckout.css"
import localforage from "localforage";

export const GCCartSummary = (props) => {
  const {
    setStoredPromoCode,
    coupon,
    orderSummaryData,
    isCollapsed,
    updateOrderSummary,
    storedPromoCode
  } = props;

  const merchantConfig = useContext(MerchantConfigContext)
  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const skin = merchantConfig.skin

  const [storedPromoDetails, setStoredPromoDetails] = useState()

  function removePromo() {
    localforage.removeItem(merchantConfig.skin + "__gcStoredPromoCode");
    localforage.removeItem(merchantConfig.skin + "__gcStoredPromoDetails");
    setStoredPromoCode(null)
    updateOrderSummary(null, "", "", "");
  }

  useEffect(() => {
    localforage.getItem(skin + "__gcStoredPromoDetails").then((promoDetails) => {
      if (promoDetails) {
        setStoredPromoDetails(promoDetails)
      } else {
        setStoredPromoDetails(null)
      }
    })
  }, [storedPromoCode])

  return (
    <>
      <div
        className={`cart-summary__container giftCardCartSummaryContainer ${isCollapsed
          ? " collapsable-section__collapsed-content"
          : " collapsable-section__expanded-content"
          }`}
      >

        <div className="cart-summary__totals-column fullRowWidth">

          {!!orderSummaryData && orderSummaryData !== "reset" && (
            <div className="cart-summary__total-wrapper">
              <div className="cart-summary__line">
                <span className="cart-summary__label">
                  {appLabels["general"]["subtotal"] + ":"}
                </span>
                <span className="cart-summary__output" aria-live="assertive">
                  {toDollars(
                    appSettings["currency-symbol"],
                    appSettings["currency-symbol-side"],
                    orderSummaryData.subTotal
                    ,
                    appLanguage
                  )}
                </span>
              </div>

              {orderSummaryData.discount && orderSummaryData.discount.length > 0 && storedPromoDetails !== undefined && (

                orderSummaryData.discount.map((discount, index) => {
                  return (
                    <div key={index} className="cart-summary__line giftCardDiscountLine">
                      <span className="cart-summary__label giftCardDiscountLabel">

                        {appLabels["gift-card"]["promo"] + ": " + discount.name}
                      </span>
                      {discount.discount === 0 && orderSummaryData.promoBonus[index] !== 0 ?
                        <span
                          className="cart-summary__output cart-summary__discount-value bonusPromoValue"
                          aria-live="assertive"
                        >
                          {`${toDollars(
                            appSettings["currency-symbol"],
                            appSettings["currency-symbol-side"],
                            orderSummaryData.promoBonus[index].promoBonus,
                            appLanguage
                          )}`}
                        </span>
                        :
                        <span
                          className="cart-summary__output cart-summary__discount-value"
                          aria-live="assertive"
                        >
                          {`- ${toDollars(
                            appSettings["currency-symbol"],
                            appSettings["currency-symbol-side"],
                            discount.discount,
                            appLanguage
                          )}`}
                        </span>
                      }
                    </div>
                  )
                }
                )

              )}
              {storedPromoDetails &&
                <div className="gcRemovePromoButton">
                  <button
                    className="link-wrapper--add-items "
                    onClick={() => removePromo()}
                    type="button"
                  >
                    {appLabels["gift-card"]["remove-applied-promo-codes"]}
                  </button>
                </div>
              }

              {!!get(props, "coupon.balance") && (
                <div className="cart-summary__line cart-summary__line--coupon">
                  <span className="cart-summary__label">{appLabels["order"]["coupon"] + ": "}</span>
                  <span
                    className="cart-summary__output cart-summary__discount-value"
                    aria-live="assertive"
                  >
                    {`- ${toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      coupon.balance,
                      appLanguage
                    )}`}
                  </span>
                </div>
              )}

              {!isNaN(get(props, "orderSummaryData.orderTotal")) && (
                <div className="cart-summary__line cart-summary__line--total">
                  <span className="cart-summary__label">{appLabels["general"]["total"] + ":"}</span>
                  <span className="cart-summary__output" aria-live="assertive">
                    {!!orderSummaryData.tipAmount
                      ? toDollars(
                        appSettings["currency-symbol"],
                        appSettings["currency-symbol-side"],
                        orderSummaryData.orderTotal + Number(orderSummaryData.tipAmount),
                        appLanguage
                      )
                      : toDollars(
                        appSettings["currency-symbol"],
                        appSettings["currency-symbol-side"],
                        orderSummaryData.orderTotal,
                        appLanguage
                      )}
                  </span>
                </div>
              )}

            </div>
          )}
          {(!orderSummaryData || orderSummaryData === "reset") && (
            <LoadingSpinner isAbsolute={true} />
          )}
        </div>
      </div>

    </>
  );
};

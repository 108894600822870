// Libraries
import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";

// Hooks
import { useLocation } from "react-router-dom";

// Contexts
import AppLabelsContext from "../App/AppLabelsContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";

// UI components
import { PageComponentTemplate } from "../_common/components/PageComponentTemplate";
import { GiftCard } from "./GiftCard";
import { AddGiftCardDetails } from "./GiftCardSelect/AddGiftCardDetails";
import { GCCartCheckout } from "./GiftCardCheckout/GCCartCheckout";
import { GCPayment } from "./GiftCardPayment/GCPayment";
import { GCOrderConfirmation } from "./GCOrderConfirmation";
import GCPaymentMethodPaypalHandlePayment from "./GiftCardPayment/GCPaymentMethodPaypalHandlePayment";

export const GiftCardRoutes = () => {
  const location = useLocation();
  const { state } = location;

  const appLabels = useContext(AppLabelsContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);

  /* 
  isGiftCardPurchasingEnabled variable is used to check for if gift card purchasing is activated for the given merchant
  If this value is true, the routes in this file will work as expected and load components related to gift cards.
  If this value is false, the routes in this file will instead redirect to Dashboard, thereby blocking the user
  to manually access gift card routes.
  */
  const isGiftCardPurchasingEnabled = cws5ModuleContext.isGiftCardEnabled;

  return (
    <>
      <Route
        exact
        path={["/gift-card/purchase", "/gift-card/category/:category"]}
        render={() =>
          isGiftCardPurchasingEnabled ? (
            <PageComponentTemplate
              pageHeading={appLabels["gift-card"]["send-a-gift-card"]}
              backButtonDestination={"/dashboard"}
              isBackButton={true}
              isHomeLink={true}
              isMapPin={false}
              component={<GiftCard />}
              isFooter={true}
              isOrderSettingsHidden={true}
              mainClassOverride="main-content menu-main-content"
            />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />

      <Route
        exact
        path={["/gift-card/recent", "/gift-card/recent/order-details/:id"]}
        render={() =>
          isGiftCardPurchasingEnabled ? (
            <PageComponentTemplate
              hasHeaderLogo={false}
              pageHeading={appLabels["gift-card"]["order-history"]}
              backButtonDestination={
                location.pathname.includes("recent/order-details/")
                  ? "/gift-card/recent"
                  : "/dashboard"
              }
              isBackButton={true}
              isHomeLink={true}
              isMapPin={false}
              component={<GiftCard />}
              isFooter={true}
              isOrderSettingsHidden={true}
              mainClassOverride="main-content menu-main-content"
            />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />

      <Route
        exact
        path="/gift-card/purchase/item/:giftCardId"
        render={() =>
          isGiftCardPurchasingEnabled ? (
            <PageComponentTemplate
              isBackButton={false}
              isHomeLink={false}
              hasHeaderLogo={false}
              pageHeading={appLabels["order"]["customize"]}
              mainClassOverride="menu-item_main-container"
              isEditing={state && state.isEditing ? true : false}
              editingItemKey={state && state.editingItemKey ? state.editingItemKey : null}
              component={<AddGiftCardDetails />}
              isFooter={false}
              isXButton={true}
            />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />

      <Route
        exact
        path={["/gift-card/checkout"]}
        render={() =>
          isGiftCardPurchasingEnabled ? (
            <PageComponentTemplate
              pageHeading={appLabels["order"]["checkout"]}
              isBackButton={true}
              backButtonDestination={"/gift-card/purchase"}
              isHomeLink={true}
              isMapPin={false}
              component={<GCCartCheckout />}
              isFooter={false}
              isOrderSettingsHidden={true}
              mainClassOverride="checkout-content cart-content"
            />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />

      <Route
        exact
        path={["/gift-card/payment"]}
        render={() =>
          isGiftCardPurchasingEnabled ? (
            <PageComponentTemplate
              hasHeaderLogo={false}
              pageHeading={appLabels["order"]["payment"]}
              backButtonDestination={"/gift-card/checkout"}
              isBackButton={true}
              isHomeLink={true}
              isMapPin={false}
              component={<GCPayment />}
              isFooter={false}
              isOrderSettingsHidden={true}
            />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />

      <Route
        exact
        path={["/gift-card/order-confirmation"]}
        render={() =>
          isGiftCardPurchasingEnabled ? (
            <PageComponentTemplate
              pageHeading={appLabels["order"]["order-confirmation"]}
              isHomeLink={true}
              component={<GCOrderConfirmation />}
            />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />

      <Route
        path="/gift-card/payment/paypal"
        render={() =>
          isGiftCardPurchasingEnabled ? (
            <PageComponentTemplate
              pageHeading={appLabels["order"]["payment"]}
              isBackButton={true}
              component={<GCPaymentMethodPaypalHandlePayment />}
              isFooter={false}
              backButtonDestination="/gift-card/checkout"
            />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
    </>
  );
};

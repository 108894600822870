export const loginRegisterLabels = {
  "hello-there": "Bonjour",
  "welcome-to": "Bienvenue à [merchant-name]",
  "all-in-one-solution": "Solution tout-en-un",
  register: "S'inscrire",
  "sign-in": "Ouvrir une session",
  "continue-as-guest": "Poursuivre en tant qu'invité",
  next: "Suivant",
  previous: "Précédent",
  "rewards-card-question": "Possédez-vous une carte-récompenses?",
  "rewards-card-question-blurb-2": "Entrer le numéro de la carte",
  "no-rewards-card-want-one": "Non, mais j'en voudrais une",
  "have-rewards-card": "Oui, et j'ai le numéro de la carte",
  "successful-registration-heading": "Enregistrée avec succès",
  "successful-registration-paragraph":
    "En tant que membre de notre famille, vous avez accès à des promotions et à des rabais exclusifs! Commencez à accumuler des points et à les échanger contre des boissons et des aliments gratuits. Faites que chaque dollar dépensé puisse compter.",
  "user-already-exists-heading": "Il existe déjà un compte ayant cette adresse courriel",
  "user-already-exists-paragraph":
    "Essayez d'ouvrir une session à l'aide de cette adresse courriel et rappelez-vous que vous pouvez toujours réinitialiser votre mot de passe si vous l'avez oublié.",
  "forgot-password": "Mot de passe oublié",
  "signing-in": "Ouverture de session en cours…",
  "forgot-password-heading": "Vous avez oublié votre mot de passe?",
  "forgot-password-paragraph":
    "Confirmez votre adresse courriel de connexion et nous vous enverrons vos directives tout de suite.",
  "login-email": "Courriel de connexion",
  "reset-password": "Réinitialiser le mot de passe",
  "sending-password": "Envoi du mot de passe en cours…",
  "temporary-login-paragraph":
    "Nous vous avons adressé un courriel. Servez-vous du mot de passe temporaire pour vous connecter.",
  "form-step-status": "Étape [current-step] de [total-steps]",
  "dont-have-account": "Vous n'avez pas de compte",
  "login-register-page-message": "Vous êtes sur la page de connection. Si vous ne souhaitez pas vous connecter, vous pouvez aussi poursuivre en tant qu'invité."
};

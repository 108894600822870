import React, { useEffect, useState, useContext } from "react";

/** Contexts */
import AppLabelsContext from "../../../../../App/AppLabelsContext";
import CartContext from "../../../../Cart/CartContext";

/** Helpers */
import { jsonCopy } from "../../../../../_common/helpers";

/** Assets */
import { ReactComponent as IconPlus } from "../../../../../_common/icons/IconPlus.svg";
import { ReactComponent as IconMinus } from "../../../../../_common/icons/IconMinus.svg";

/** CSS */
import "./AddonQuantityButtons.css";

const AddonQuantityButtons = (props) => {
  const {
    addon,
    displayType,
    updateSelectedOptions,
    updateUnselectedMultipleOptions,
    inputId,
    selectedOptions,
    unselectedMultipleOptions,
    disableAllButtons,
    maxNumberOfOptionsReached,
    isEditingComboItem,
    customStyle,
    modifierContainerId = null
  } = props;

  const cartData = useContext(CartContext);
  const appLabels = useContext(AppLabelsContext);

  const [addonQuantity, setAddonQuantity] = useState(addon.isDefault === "True" ? 1 : 0);
  const [addonMaxQuantity, setAddonMaxQuantity] = useState(null);

  const isMenuDisplay = displayType === "menu";
  const isModifyDisplay = displayType === "cart-modify";
  const cartItems = cartData.value || [];

  useEffect(() => {
    if (!addon.isSelected && addon.isDefault !== "True") {
      setAddonQuantity(0);
    }
  }, [addon]);

  const totalAddonUsageInCart = () => {
    let totalNumberOfAddonInstances = 0;

    cartItems.forEach((cartItem) => {
      const cartItemAddonGroups = cartItem.addonGroups;
      if (cartItemAddonGroups) {
        if (!!Object.keys(cartItemAddonGroups).length) {
          Object.keys(cartItemAddonGroups).forEach((groupKey) => {
            const addonGroup = cartItemAddonGroups[groupKey];
            const addonItems = addonGroup.items;

            Object.keys(addonItems).forEach((addonKey) => {
              const option = addonItems[addonKey];
              if (option.id === addon.id) {
                totalNumberOfAddonInstances += option.quantity || 1;
              }
            });
          });
        }
      }
    });

    return totalNumberOfAddonInstances;
  };

  useEffect(() => {
    if (isModifyDisplay) {
      setAddonQuantity(addon.quantity || 0);
    }
  }, []);

  useEffect(() => {
    if (isModifyDisplay && addon.isSelected) {
      setAddonQuantity(addon.quantity);
    }
  });

  useEffect(() => {
    if (addon && addonMaxQuantity === null) {
      setAddonMaxQuantity(
        addon.item_count === "None" ? "" : !!addon.item_count ? parseInt(addon.item_count) : ""
      );

      if (addon.item_count === "0") {
        setAddonQuantity(0);
      }

      if (!isEditingComboItem && isMenuDisplay && addon.isDefault !== "True") {
        updateAddonQuantity(false);
      }
    }
  }, [addon]);

  useEffect(() => {
    if (!isModifyDisplay) {
      if (addon.quantity && addon.isSelected) {
        setAddonQuantity(addon.quantity);
      } else {
        if (addon.isDefault !== "True") {
          setAddonQuantity(0);
        }
      }
    }
  });

  const updateAddonQuantity = async (isAdding) => {
    let currentQuantity = addonQuantity;
    if (isAdding) {
      currentQuantity++;
      if (addonMaxQuantity !== "" && currentQuantity >= addonMaxQuantity) {
        currentQuantity = addonMaxQuantity;
      }
    } else {
      currentQuantity--;
      if (currentQuantity <= 0) {
        currentQuantity = 0;
      }
    }

    //update the menu item
    const addonCopy = jsonCopy(addon);
    addonCopy.quantity = currentQuantity;

    if (!addon.isSelected && isAdding) {
      document.getElementById(inputId).click();
    } else if (addon.isSelected && !isAdding && addonQuantity === 1) {
      document.getElementById(inputId).click();
    } else {
      let tempSelectedOptions = { ...selectedOptions };
      let tempUnselectedOptions = { ...unselectedMultipleOptions };

      if (isAdding) {
        tempSelectedOptions[`addon-${addonCopy.id}`] = addonCopy;
      } else {
        if (currentQuantity <= 0) {
          tempUnselectedOptions[`addon-${addonCopy.id}`] = addonCopy;
        } else {
          tempSelectedOptions[`addon-${addonCopy.id}`] = addonCopy;
        }
      }

      await updateUnselectedMultipleOptions(tempUnselectedOptions);
      await updateSelectedOptions(tempSelectedOptions);
      await setAddonQuantity(currentQuantity);
    }
    if (isAdding) {
      if(modifierContainerId){
      let plusButton = document.getElementById(modifierContainerId)
        .getElementsByClassName("addon-quantity-buttons__main-wrapper")[0]
        .getElementsByClassName("addon-quantity-button-plus")[0];
      if (plusButton) {
        plusButton.focus();
      }}
    } else {
      if(modifierContainerId){
      let minusButton = document.getElementById(modifierContainerId)
        .getElementsByClassName("addon-quantity-buttons__main-wrapper")[0]
        .getElementsByClassName("addon-quantity-button-minus")[0];
      if (minusButton) {
        minusButton.focus();
        }
      }
    }
  };

  return (
    <div className="addon-quantity-buttons__main-wrapper" style={customStyle ? customStyle : {}}>
      <button
        data-quantity="minus"
        data-field="quantity"
        type="button"
        className="addon-quantity-button addon-quantity-button-minus"
        onClick={() => {
          updateAddonQuantity(false);
        }}
        disabled={
          addon.item_count === "0" || addonQuantity <= 0 || disableAllButtons || !addon.isSelected
        }
        aria-label={
          appLabels["order"]["decrease-quantity-by-one"] +
          " " +
          appLabels["general"]["for-text"] +
          " " +
          addon.name +
          ". " +
          appLabels["order"]["current-quantity"].replace("[item-quantity]", addonQuantity || 0)
        }
      >
        <IconMinus />
      </button>

      <input
        className="addon-quantity-input"
        readOnly
        type="number"
        name="quantity"
        value={addon.isSelected ? addonQuantity : 0}
        max={addonMaxQuantity}
        min="0"
      />
      <button
        data-quantity="plus"
        data-field="quantity"
        type="button"
        className="addon-quantity-button addon-quantity-button-plus"
        onClick={() => {
          updateAddonQuantity(true);
        }}
        disabled={
          (addon.item_count !== "None" &&
            (addon.item_count === "0" || addonQuantity >= addonMaxQuantity)) ||
          totalAddonUsageInCart() >= addon.item_count ||
          maxNumberOfOptionsReached ||
          disableAllButtons
        }
        aria-label={
          appLabels["order"]["increase-quantity-by-one"] +
          " " +
          appLabels["general"]["for-text"] +
          " " +
          addon.name +
          ". " +
          appLabels["order"]["current-quantity"].replace("[item-quantity]", addonQuantity || 0)
        }
      >
        <IconPlus />
      </button>
    </div>
  );
};
export default AddonQuantityButtons;

import React, { useRef, useState, useEffect, useContext } from "react";
import AppLabelsContext from "../../App/AppLabelsContext";
import { ReactComponent as IconArrowDown } from "../icons/IconArrowDown.svg";
import "./FormSelect.css";
import AppSettingsContext from "../../App/AppSettingsContext";

export const FormSelect = (props) => {
  const {
    onSelectChange,
    defaultValue,
    fieldData,
    isSmall,
    id,
    label,
    type,
    name,
    isRequired,
    isSkipEmptyOption,
    showAstr,
    customLabelClass,
    displayCurrencySymbol = false,
    hasStartIcon = false,
    customOptions = null,
    handleInputChangeFromParent = null,
    isEditingGiftCardAmount = false
  } = props;

  const [selectInFocusClass, setSelectInFocusClass] = useState("");
  const appSettings = useContext(AppSettingsContext);
  let currencySymbol = appSettings["currency-symbol"];
  const [displayCurrency, setDisplayCurrency] = useState()
  const [selectDisabled, setSelectDisabled] = useState(false)

  useEffect(() => {
    // If a non-empty option is pre-selected set the select style to be in-focus
    if (fieldData.value !== "") setSelectInFocusClass(" form__field-wrapper--in-focus");

  }, []);

  useEffect(() => {
    if (currencySymbol === "") {
      setDisplayCurrency("$")
    } else {
      setDisplayCurrency(currencySymbol)
    }
  }, [displayCurrencySymbol, currencySymbol])

  useEffect(() => {
    if (isEditingGiftCardAmount) {
      fieldData.value = defaultValue
    }
    else if (fieldData.name === "eGiftCardAmount") {
      fieldData.value = ""
    }
  }, [fieldData, defaultValue])

  const onChange = (event) => {
    onSelectChange(event.target);

    if (!!props.callBack) {
      props.callBack(event.target);
    }

    if (event.target.value !== "") {
      setSelectInFocusClass(" form__field-wrapper--in-focus");
    } else {
      setSelectInFocusClass("");
    }
    if (handleInputChangeFromParent) {
      handleInputChangeFromParent(event)
    }
  };

  // if Select has a default value - Set focused label class and perform its onSelectChange
  const selectRef = useRef();
  useEffect(() => {
    if (defaultValue && !selectInFocusClass && selectRef.current) {
      setSelectInFocusClass(" form__field-wrapper--in-focus");
      onSelectChange(selectRef.current);
    }
  }, [selectRef.current]);

  const appLabels = useContext(AppLabelsContext);
  useEffect(() => {
    if (customOptions && customOptions.length === 1 && customOptions[0] > 0) {
      setSelectDisabled(true)
    }
  }, [customOptions])
  return (
    !fieldData.isHidden && (
      <div
        style={!!props.isHidden ? { display: "none" } : {}}
        className={`form__field-wrapper${
          isSmall ? " form__field-wrapper--small" : ""
        }${selectInFocusClass}`}
      >
        <label htmlFor={id} className={`form__field-label ${customLabelClass}`}>
          {!!showAstr && <span aria-hidden="true">*</span>}
          {(fieldData.label || label) +
            (!fieldData.isRequired ? ` (${appLabels["form"]["optional-label"]})` : "")}
        </label>
        {displayCurrencySymbol && fieldData.value !== "" &&
          <p className="selectIconStart">{displayCurrency}</p>
        }
        <select
          onChange={onChange}
          className={`${hasStartIcon ? "form__field " : "form__field"} ${name === "eGiftCardAmount" && selectDisabled ? "gcAmountSelectDisabled" : ""} ${name === "eGiftCardAmount" ? fieldData.value === "" ? "" : "selectInputValuePaddingLeft" : ""}`}
          value={fieldData.value !== "" ? fieldData.value : isEditingGiftCardAmount ? "" : customOptions && customOptions.length === 1 && customOptions[0] > 0 ? customOptions[0] : ""}
          disabled={selectDisabled}
          type={type}
          id={id}
          name={name}
          aria-required={isRequired}
          ref={selectRef}
        >
          {!isSkipEmptyOption && <option value=""></option>}
          {fieldData.options &&
            fieldData.options.length > 0 &&
            fieldData.options.map((option) => {

              return (

                <option key={option.value} value={option.value}>
                  {option.displayValue}
                </option>
              )
            })}
          {customOptions &&
            customOptions.length > 0 &&
            <>
              <option value="">{appLabels["form"]["select-one"]}</option>
              {customOptions.map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              )
            })}
            </>
          }

        </select>
        {!selectDisabled &&
          < IconArrowDown />
        }
        {!fieldData.isValid && <div className="form__field-error">{fieldData.errorMessage}</div>}
      </div>
    )
  );
};

FormSelect.displayName = "FormSelect";

//Libraries
import React, { useContext } from "react";
import { useHistory } from "react-router";

//Contexts
import CartContext from "../OnlineOrdering/Cart/CartContext";
import OrderContext from "../App/OrderContext";
import StoreContext from "../OnlineOrdering/StoreContext";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";
import OrderTimeContext from "../OnlineOrdering/OrderTimeContext";
import AppLabelsContext from "../App/AppLabelsContext";

//UI Component
import CartIndicator from "../_common/CartIndicator/CartIndicator";

export const OrderSettingsCart = (props) => {
  const storeContext = useContext(StoreContext);
  const orderType = useContext(OrderTypeContext).value;
  const orderTimeContext = useContext(OrderTimeContext);
  const activeOrderStore = storeContext.activeOrderStore;
  const history = useHistory();
  const cartData = useContext(CartContext);
  //const branding = useContext(BrandingContext);
  //const cartIconColor = getContrastColor(branding["online-order-settings-background-colour"]);

  const orderContext = useContext(OrderContext);
  const appLabels = useContext(AppLabelsContext);

  const onCartButtonClick = (event) => {
    if (cartData.quantity > 0 && activeOrderStore) {
      if (orderType === "dinein") {
        history.push("/online-ordering/review-order");
      } else {
        history.push("/online-ordering/checkout");
      }
    } else {
      orderContext.checkOrderSettings(orderType, storeContext, orderTimeContext);
    }
  };
  return (
    <button
      type="button"
      className="order-settings__cart-button"
      aria-label={appLabels["general"]["go-to-cart"]}
      onClick={(e) => {
        onCartButtonClick(e);
      }}
    >
      <CartIndicator
        quantity={cartData.quantity}
        isAnimated={cartData.isAnimated}
        isFloating={true}
        activeOrderStore={activeOrderStore}
      />
    </button>
  );
};

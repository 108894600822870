import React, { useContext, useState, useEffect } from "react";
import { /*Redirect,*/ Link } from "react-router-dom";
import localforage from "localforage";
import { useHistory } from "react-router-dom";

import { formatOrderType, get, toDollars } from "../../../_common/helpers";
import { getOrderItemDetails } from "../../RecentOrders/orderHistoryHelpers";
import { onPurchaseComplete } from "../../../_common/helpers/dataLayerHelpers";
import {
  formatStoreTimeOverrides,
  getClosingTime,
  getDayOfWeekFromIndex,
  getFormattedStoreHoursWithMidnight,
  getIntermittentIncrementInterval,
  getMinInterval,
  getOpeningTime,
  isTimeSlotBlocked,
  isWithinBusinessHours,
} from "../../Locations/helpers/isStoreOpenOrClosed";

import {
  getTimeAmPm,
  getTrueBusinessDate,
  prettifyDate,
  roundTime,
  roundToNearestQuarter,
} from "../../../Dashboard/DashboardOrder/dateHelpers";
import { getStore1306Config } from "../../../App/apiHelpers/getStore1306Config";
import { calculateNextAvailableOrderTime, isValidOrderTime } from "../../../_common/PaymentHelpers";
import { setStoreHoursProperties } from "../../GoogleMaps/dateHelpers";
import {
  onOrderSubmittedXtreme,
  onRewardRedeemXtreme,
} from "../../../_common/helpers/xtremePushHelper";

import MerchantConfigContext from "../../../App/MerchantConfigContext";
import OrderTypeContext from "../../../OnlineOrdering/OrderTypeContext";
import StoreContext from "../../../OnlineOrdering/StoreContext";
import CartContext from "../../../OnlineOrdering/Cart/CartContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import UserRoleContext from "../../../App/UserRoleContext";
import AppSettingsContext from "../../../App/AppSettingsContext";
import ActiveOrdersContext from "../../../OnlineOrdering/OrderStatus/ActiveOrdersContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import OrderTimeContext from "../../OrderTimeContext";
import ManifestContext from "../../../App/ManifestContext";
import { MenuContext } from "../../Menu/MenuContext";

import useWindowSize from "../../../_common/hooks/useWindowSize";
import { getMarquiThankYouImage } from "./getMarquiThankYouImage";
import { ReactComponent as IconCheck } from "../../../_common/icons/IconCheck.svg";
import { ReactComponent as IconGoldStar } from "../../../_common/icons/IconGoldStar.svg";
import { ReactComponent as IconThumbsUp } from "../../../_common/icons/IconThumbsUp.svg";
import { ReactComponent as IconThumbsDown } from "../../../_common/icons/IconThumbsDown.svg";

import { AdditionalDisclaimer } from "../../../_common/AdditionalDisclaimer";

import "./OrderConfirmation.css";

export const OrderConfirmation = () => {
  const deviceWidth = useWindowSize().width;
  const history = useHistory();

  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appSettings = useContext(AppSettingsContext);

  const merchantConfig = useContext(MerchantConfigContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const manifestContext = useContext(ManifestContext);

  const menuContext = useContext(MenuContext);
  const vexilorConfig = merchantConfig.vexilor.I2;
  const isOrderStatusEnabled = vexilorConfig["order_status_access"] === "t";
  const skin = merchantConfig.skin;

  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;

  const storeContext = useContext(StoreContext);
  const activeStore = storeContext.activeOrderStore;

  const cart = useContext(CartContext);
  const activeTableNumber = storeContext.activeOrderStoreTable;

  const userStatus = useContext(UserRoleContext).status;
  const [customerInfo, setCustomerInfo] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  const [finalOrderTotal, setFinalOrderTotal] = useState(null);
  const [completedOrderId, setCompletedOrderId] = useState(null);
  const [pointsIssued, setPointsIssued] = useState(null);

  const relativePath = useContext(AppSettingsContext)["relative-path"];

  const activeOrdersContext = useContext(ActiveOrdersContext);

  const [thankYouImage, setThankYouImage] = useState(null);

  const isLocalhost = process.env.NODE_ENV === "development";
  const IS_GIVEX_HOST = !isLocalhost;
  const PATHNAME = window.location.pathname;
  const IS_DEFAULT_LANGUAGE = IS_GIVEX_HOST
    ? PATHNAME.split("/")[3] === ""
    : PATHNAME.split("/")[1] === "";

  const resetMenu = () => {
    localforage.removeItem(skin + "__storedMenu");
    menuContext.updateMenu({
      ...menuContext,
      apiData: null,
      activeTopCategory: null,
      activeSubcategory: null,
      activeItem: null,
      menuLevel: null,
    });
  };

  const storeOrderDateInStorage = (orderTime) => {
    const orderDate = new Date(orderTime);
    const orderDateToStore = {
      value: orderDate.getMonth() + "-" + orderDate.getDate(), // the day of a date as a number (1-31)
      displayValue:
        orderDate.getDate() === new Date().getDate()
          ? appLabels["order"]["today"]
          : prettifyDate(orderDate, appLanguage), // stringified Date object
      dateObject: orderDate,
    };

    localforage.setItem(skin + "__orderDate", orderDateToStore);
  };
  const updateOrderTime = async (foundStore, orderType) => {
    /* Set the order time as now + min interval */
    let foundStoreWithVexilorConfig = null;
    if (foundStore && !foundStore.vexilorConfig) {
      foundStoreWithVexilorConfig = await getStore1306Config(skin, appLanguage, foundStore);
    }

    foundStore = setStoreHoursProperties(foundStore);

    const storeConfig = foundStoreWithVexilorConfig
      ? foundStoreWithVexilorConfig.vexilorConfig
      : storeContext.activeOrderStore.vexilorConfig;

    const formattedStoreOverrides =
      orderType === "dinein"
        ? []
        : formatStoreTimeOverrides(
            orderType === "delivery"
              ? storeConfig.delivery_min_interval_override
              : storeConfig.pickup_min_interval_override,
            new Date()
          );
    const blockedTimeIntervals =
      Object.keys(formattedStoreOverrides).length === 0
        ? []
        : formattedStoreOverrides[getDayOfWeekFromIndex(new Date().getDay())].filter(
            (interval) => interval.isBlocked
          );

    if (storeConfig) {
      const minInterval = getMinInterval(storeConfig, orderType, skin);

      if (foundStore.isOpen && setStoreHoursProperties(foundStore).isOpen) {
        let orderTime = new Date();
        orderTime.setTime(orderTime.getTime() + minInterval * 60000);

        let newTime = calculateNextAvailableOrderTime(
          activeStore,
          activeOrderType,
          skin,
          orderTimeContext
        );
        let isAsapOption = false;
        let currDate = new Date();
        orderTime.setSeconds("00");
        newTime.setSeconds("00");
        if (orderTime < newTime) {
          currDate.setSeconds("00");
          let dateDiff = (newTime - currDate) / 60000;
          dateDiff = Math.round(dateDiff);
          if (dateDiff > minInterval) {
            isAsapOption = false;
          } else {
            isAsapOption = true;
          }
          orderTime = newTime;
        } else {
          isAsapOption = true;
        }
        if (
          !isTimeSlotBlocked(new Date(orderTime), blockedTimeIntervals) &&
          isValidOrderTime(foundStore, {
            value: {
              value: orderTime,
              trueBusinessDate: getTrueBusinessDate(orderTime, foundStore),
            },
          }) &&
          isWithinBusinessHours(foundStore, orderTime)
        ) {
          orderTimeContext.update({
            value: new Date(orderTime),
            displayValue: !isAsapOption
              ? `${getTimeAmPm(orderTime)}`
              : `${appLabels["order"]["asap"]} (~${minInterval} ${appLabels["order"]["minutes-short-hand"]})`,
            triggerMenu: true,
            trueBusinessDate: getTrueBusinessDate(orderTime, foundStore),
          });
          storeOrderDateInStorage(orderTime);
          localforage.setItem(
            skin + "__trueBusinessDate",
            getTrueBusinessDate(orderTime, foundStore)
          );
        } else {
          //if the order time falls in a blocked time interval
          //calculate the next available time slot after the block finishes

          let lookingForNextAvailableTime = true;

          let now = new Date();
          let iterationIndex = 0;
          const pickupIncrement = storeConfig.pickup_inc_interval;
          while (lookingForNextAvailableTime) {
            const roundedMinutes = roundToNearestQuarter(now.getMinutes());
            const roundedTime = roundTime(now, roundedMinutes, true);

            const increment = getIntermittentIncrementInterval(
              formattedStoreOverrides,
              roundedTime,
              pickupIncrement
            );

            // this code now compares nextTime to calulcated next valid time and changes nextTime if needed
            let nextTime = newTime(roundedTime, iterationIndex === 0 ? minInterval : increment); // "In {pickupMinInterval OR pickupIncrement} min"
            let nextValidTime = calculateNextAvailableOrderTime(activeStore, activeOrderType, skin);
            if (nextTime.toString() < nextValidTime.toString()) {
              nextTime = nextValidTime;
            }
            const nextTimeWithResetSeconds = new Date(nextTime);
            nextTimeWithResetSeconds.setSeconds(0, 0); // reset seconds to 0 to allow pre-select based on storage value
            if (!isTimeSlotBlocked(nextTimeWithResetSeconds, blockedTimeIntervals)) {
              orderTimeContext.update({
                value: new Date(nextTimeWithResetSeconds),
                displayValue: `${getTimeAmPm(nextTimeWithResetSeconds)}`,
                triggerMenu: true,
                trueBusinessDate: getTrueBusinessDate(nextTimeWithResetSeconds, foundStore),
              });
              lookingForNextAvailableTime = false;
              storeOrderDateInStorage(nextTimeWithResetSeconds);
              localforage.setItem(
                skin + "__trueBusinessDate",
                getTrueBusinessDate(nextTimeWithResetSeconds, foundStore)
              );
              break;
            } else {
              now = new Date(nextTimeWithResetSeconds);
            }
            iterationIndex++;

            // fail safe to break out of the loop
            if (iterationIndex > 1000) {
              break;
            }
          }
        }
      } else {
        // Store is closed and/or ordering for future date
        let dayInFutureIndex = 0;

        /** if ordering for a future date, use the default min pickup/delivery interval */
        let defaultMinInterval =
          orderType === "delivery"
            ? storeConfig.delivery_min_interval
            : storeConfig.pickup_min_interval;

        let today = new Date();
        let futureDate = new Date();
        let nextDay = new Date();
        nextDay.setDate(today.getDate() + dayInFutureIndex);
        futureDate = nextDay;
        let selectedDay = futureDate;

        let selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, selectedDay.getDay());
        let selectedDayApiClosingTime = getClosingTime(foundStore.hours, selectedDay.getDay());

        let realHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          futureDate,
          foundStore
        );

        let todayStoreHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          today,
          foundStore
        );

        let futureDatePlusMinInterval = new Date();
        futureDatePlusMinInterval.setTime(today.getTime() + minInterval * 60000);

        while (
          (isNaN(selectedDayApiOpeningTime.replace(":", "")) &&
            selectedDayApiOpeningTime !== "closed") ||
          (isNaN(selectedDayApiClosingTime.replace(":", "")) &&
            selectedDayApiClosingTime !== "closed") ||
          (realHours !== "closed" && isNaN(realHours.formattedClosingTime.getTime())) ||
          (realHours !== "closed" && isNaN(realHours.formattedOpeningTime.getTime())) ||
          realHours === "closed" ||
          foundStore.hours[getDayOfWeekFromIndex(today.getDay() + dayInFutureIndex)] === "closed" ||
          (todayStoreHours !== "closed" &&
            futureDate.getTime() >= todayStoreHours.formattedClosingTime.getTime() &&
            futureDate.getDate() === todayStoreHours.formattedClosingTime.getDate()) ||
          today.getTime() > futureDate.getTime() ||
          !isValidOrderTime(foundStore, {
            value: {
              value: futureDatePlusMinInterval,
              trueBusinessDate: getTrueBusinessDate(futureDatePlusMinInterval, foundStore),
            },
          })
        ) {
          dayInFutureIndex++;
          let nextDay = new Date();
          nextDay.setDate(today.getDate() + dayInFutureIndex);
          futureDate = nextDay;
          selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, futureDate.getDay());
          selectedDayApiClosingTime = getClosingTime(foundStore.hours, futureDate.getDay());

          realHours = getFormattedStoreHoursWithMidnight(
            selectedDayApiOpeningTime,
            selectedDayApiClosingTime,
            futureDate,
            foundStore
          );

          if (realHours === "closed") {
            futureDate.setTime(
              new Date(new Date(futureDate).setDate(futureDate.getDate() + 1)).getTime()
            );
          } else {
            futureDate.setTime(realHours.formattedOpeningTime.getTime());
          }

          futureDatePlusMinInterval.setTime(futureDate.getTime() + minInterval * 60000);

          if (dayInFutureIndex >= 7) {
            break;
          }
        }

        selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, futureDate.getDay());
        selectedDayApiClosingTime = getClosingTime(foundStore.hours, futureDate.getDay());

        const selectedDayStoreHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          futureDate,
          foundStore
        );

        let selectedDayStoreOpeningTime = selectedDayStoreHours.formattedOpeningTime;
        let selectedDayStoreOpeningTimeWithMinInterval = new Date(selectedDayStoreOpeningTime);
        selectedDayStoreOpeningTimeWithMinInterval.setTime(
          selectedDayStoreOpeningTimeWithMinInterval.getTime() + defaultMinInterval * 60000
        );

        /** Make sure when the blocked intervals are being checked, they have the same date as the calculated time
         * otherwise isTimeSlotBlocked could return incorrect results
         */
        let blockedTimeIntervalsForTheSelectedDay =
          Object.keys(formattedStoreOverrides).length === 0
            ? []
            : formattedStoreOverrides[
                getDayOfWeekFromIndex(new Date(selectedDayStoreOpeningTimeWithMinInterval).getDay())
              ].filter((interval) => interval.isBlocked);

        if (blockedTimeIntervalsForTheSelectedDay.length > 0) {
          blockedTimeIntervalsForTheSelectedDay.forEach((blockedInterval) => {
            blockedInterval.end.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
            blockedInterval.end.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
            blockedInterval.end.setFullYear(
              selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
            );

            blockedInterval.start.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
            blockedInterval.start.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
            blockedInterval.start.setFullYear(
              selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
            );
          });
        }

        while (
          isTimeSlotBlocked(
            new Date(selectedDayStoreOpeningTimeWithMinInterval),
            blockedTimeIntervalsForTheSelectedDay
          )
        ) {
          selectedDayStoreOpeningTimeWithMinInterval.setTime(
            selectedDayStoreOpeningTimeWithMinInterval.getTime() + defaultMinInterval * 60000
          );

          if (blockedTimeIntervalsForTheSelectedDay.length > 0) {
            blockedTimeIntervalsForTheSelectedDay.forEach((blockedInterval) => {
              blockedInterval.end.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
              blockedInterval.end.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
              blockedInterval.end.setFullYear(
                selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
              );

              blockedInterval.start.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
              blockedInterval.start.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
              blockedInterval.start.setFullYear(
                selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
              );
            });
          }
        }

        orderTimeContext.update({
          value: new Date(selectedDayStoreOpeningTimeWithMinInterval),
          displayValue: getTimeAmPm(selectedDayStoreOpeningTimeWithMinInterval),
          triggerMenu: true,
          trueBusinessDate: getTrueBusinessDate(
            selectedDayStoreOpeningTimeWithMinInterval,
            foundStore
          ),
        });
        storeOrderDateInStorage(selectedDayStoreOpeningTimeWithMinInterval);

        localforage.setItem(
          skin + "__trueBusinessDate",
          getTrueBusinessDate(selectedDayStoreOpeningTimeWithMinInterval, foundStore)
        );
      }

      resetMenu();
      await storeContext.updateActiveOrderStore(foundStore);
    }
  };

  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      setCustomerInfo(customerInfo);
    });
    localforage
      .getItem(skin + "__paymentDetails")
      .then((paymentDetails) => setPaymentDetails(paymentDetails));
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setThankYouImage(require("../../../_common/Images/order-confirmation-image.jpg")); // localhost fallback
    } else {
      getMarquiThankYouImage(relativePath).then((marquiImages) => {
        if (
          marquiImages &&
          marquiImages["order-confirmation"] &&
          marquiImages["order-confirmation"]["thank-you-image-src"] !==
            "files/Images/online-ordering/" &&
          !marquiImages["order-confirmation"]["thank-you-image-src"].includes("_blank.")
        ) {
          if (IS_DEFAULT_LANGUAGE) {
            setThankYouImage(marquiImages["order-confirmation"]["thank-you-image-src"]);
          } else {
            setThankYouImage("../" + marquiImages["order-confirmation"]["thank-you-image-src"]);
          }
        } else {
          setThankYouImage(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    localforage.getItem(skin + "__finalOrderTotal").then((storedFinalOrderTotal) => {
      if (storedFinalOrderTotal || storedFinalOrderTotal === 0) {
        setFinalOrderTotal(storedFinalOrderTotal);
      }
    });

    localforage.getItem(skin + "__completedOrderId").then((storedOrderId) => {
      if (storedOrderId) {
        setCompletedOrderId(storedOrderId);
      } else {
        history.push("/dashboard");
      }
    });

    localforage.getItem(skin + "__pointsIssued").then((points) => {
      if (points && points > 0) setPointsIssued(points);
    });
  }, []);

  useEffect(() => {
    if (completedOrderId && finalOrderTotal && userStatus === "logged-in") {
      if (userStatus === "logged-in") {
        /** Update the active orders context for logged in users */
        const tempActiveOrders = [...activeOrdersContext.activeOrders];
        localforage.getItem(skin + "__customerID").then((customerID) => {
          if (customerID) {
            //add the new entry if the same customer id and order id does not exist
            if (
              !tempActiveOrders.find(
                (order) => order.id === completedOrderId && order.customerId === customerID
              )
            ) {
              (async () => {
                const itemDetails = await getOrderItemDetails(
                  skin,
                  completedOrderId,
                  activeStore.storeId,
                  appLanguage
                );

                if (itemDetails) {
                  tempActiveOrders.push({
                    id: completedOrderId,
                    date: itemDetails.date,
                    dateObject: orderTimeContext.value.value,
                    customerId: customerID,
                    total: toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      finalOrderTotal,
                      appLanguage
                    ),
                    items: itemDetails.items,
                    orderStatus: {},
                    orderAddress: itemDetails.orderAddress,
                    orderType: itemDetails.orderType,
                    rewardDetails: itemDetails.rewardDetails,
                    paymentDetails: itemDetails.paymentDetails,
                    discountTotal: itemDetails.discountTotal,
                    storeLocation: { id: activeStore.storeId, storeName: activeStore.name },
                  });
                  //activeOrdersContext.updateActiveOrders(tempActiveOrders);
                }
              })();
            }
          }
        });
      }
    }
  }, [completedOrderId, finalOrderTotal]);

  // Clear stored order details
  const [orderQuantity, setOrderQuantity] = useState(null);
  useEffect(() => {
    if (orderQuantity == null) {
      if (get(cart, "value.length")) {
        setOrderQuantity(cart.quantity);

        localforage.removeItem(skin + "__finalOrderTotal");

        localforage.removeItem(skin + "__orderHistory");
        localforage.removeItem(skin + "__orderDate");
        localforage.removeItem(skin + "__orderTime");
        localforage.removeItem(skin + "__trueBusinessDate");
        localforage.removeItem(skin + "__orderTypeSpecialInstruction");

        localforage.removeItem(skin + "__activeOrderStore");
      } else {
        setOrderQuantity(0);
      }
    }
  }, [cart, orderQuantity]);

  useEffect(() => {
    return () => {
      cart.updateCart(null);
      localforage.removeItem(skin + "__cart");

      localforage.removeItem(skin + "__storedRewardsDetails");
      localforage.removeItem(skin + "__storedPromoCode");
      localforage.removeItem(skin + "__storedRewards");
      localforage.removeItem(skin + "__coupon");
      localforage.removeItem(skin + "__usedPaymentMethod");
    };
  }, []);

  /* const { state } = useLocation();
  useEffect(() => {
    if (state && state.from === "order-without-payment") {
      // clear cart
      cart.updateCart(null);
      localforage.removeItem(skin + "__cart");

      localforage.removeItem(skin + "__storedRewardsDetails");
      localforage.removeItem(skin + "__storedPromoCode");
      localforage.removeItem(skin + "__storedRewards");
      localforage.removeItem(skin + "__coupon");
    }
  }, [state]);*/

  useEffect(() => {
    localforage.removeItem(skin + "__orderDate");
    localforage.removeItem(skin + "__orderTime");
  }, []);
  // END OF Clear stored order details

  const [isRewardsOn, setIsRewardsOn] = useState(null);
  useEffect(() => {
    if (isRewardsOn == null) {
      const tempIsRewardsOn = merchantConfig.merchant.I22 === "t";
      setIsRewardsOn(tempIsRewardsOn);
    }
  }, []);

  const youCouldHaveEarnedPointsMessage = isRewardsOn
    ? appLabels["loyalty"]["you-could-have-earned-points-message"]
    : "";

  const [itemsForOrderTypeLabel, setItemsForOrderTypeLabel] = useState("");

  useEffect(() => {
    if (orderQuantity) {
      let label = `${orderQuantity}`;

      const displayOrderTypeLabel =
        activeOrderType === "dinein"
          ? appLabels["order"]["dine-in"].toLowerCase()
          : appLabels["order"][activeOrderType].toLowerCase();

      if (activeOrderType === "pickup") {
        if (orderQuantity > 1) {
          label +=
            appLabels["order"]["items-for-pickup-at"]
              .split("[quantity]")[1]
              .split("[order-type]")[0] +
            displayOrderTypeLabel +
            appLabels["order"]["items-for-pickup-at"]
              .split("[quantity]")[1]
              .split("[order-type]")[1];
        } else {
          label +=
            appLabels["order"]["item-for-pickup-at"]
              .split("[quantity]")[1]
              .split("[order-type]")[0] +
            displayOrderTypeLabel +
            appLabels["order"]["item-for-pickup-at"]
              .split("[quantity]")[1]
              .split("[order-type]")[1];
        }
      } else if (activeOrderType === "delivery") {
        if (orderQuantity > 1) {
          label +=
            appLabels["order"]["items-for-delivery-or-dinein-from"]
              .split("[quantity]")[1]
              .split("[order-type]")[0] +
            displayOrderTypeLabel +
            appLabels["order"]["items-for-delivery-or-dinein-from"]
              .split("[quantity]")[1]
              .split("[order-type]")[1];
        } else {
          label +=
            appLabels["order"]["item-for-delivery-or-dinein-from"]
              .split("[quantity]")[1]
              .split("[order-type]")[0] +
            displayOrderTypeLabel +
            appLabels["order"]["item-for-delivery-or-dinein-from"]
              .split("[quantity]")[1]
              .split("[order-type]")[1];
        }
      } else if (activeOrderType === "dinein") {
        if (orderQuantity > 1) {
          label +=
            appLabels["order"]["items-for-dine-in-at"]
              .split("[quantity]")[1]
              .split("[order-type]")[0] +
            displayOrderTypeLabel +
            appLabels["order"]["items-for-dine-in-at"]
              .split("[quantity]")[1]
              .split("[order-type]")[1];
        } else {
          label +=
            appLabels["order"]["item-for-dine-in-at"]
              .split("[quantity]")[1]
              .split("[order-type]")[0] +
            displayOrderTypeLabel +
            appLabels["order"]["item-for-dine-in-at"]
              .split("[quantity]")[1]
              .split("[order-type]")[1];
        }
      }

      setItemsForOrderTypeLabel(label);
    }
  }, [orderQuantity]);

  useEffect(() => {
    if (completedOrderId) {
      localforage.getItem(skin + "__orderSummary").then((orderSummary) => {
        if (orderSummary) {
          localforage.getItem(skin + "__cart").then((cartData) => {
            if (cartData) {
              const summary = orderSummary;
              const cartDataCopy = cartData;

              let purchaseAdditionalInfo = {};
              localforage.getItem(skin + "__storedPromoCode").then((promoCode) => {
                localforage.getItem(skin + "__storedRewardsDetails").then((rewards) => {
                  localforage.getItem(skin + "__coupon").then((coupon) => {
                    localforage.getItem(skin + "__pointsIssued").then((pointsIssued) => {
                      localforage.getItem(skin + "__storedTip").then((tip) => {
                        localforage.getItem(skin + "__activeOrderType").then((activeOrderType) => {
                          localforage
                            .getItem(skin + "__usedPaymentMethod")
                            .then((paymentMethod) => {
                              purchaseAdditionalInfo.coupon = coupon ? coupon : "";
                              purchaseAdditionalInfo.promoCode = promoCode ? promoCode : "";
                              purchaseAdditionalInfo.rewards = rewards ? rewards : [];
                              purchaseAdditionalInfo.pointsIssued = pointsIssued ? pointsIssued : 0;
                              purchaseAdditionalInfo.tip = tip ? tip.tipAmount : 0;
                              purchaseAdditionalInfo.orderType = formatOrderType(activeOrderType);

                              onPurchaseComplete(
                                cartDataCopy,
                                summary,
                                activeStore,
                                completedOrderId,
                                purchaseAdditionalInfo
                              );

                              onOrderSubmittedXtreme(
                                summary,
                                activeStore,
                                completedOrderId,
                                purchaseAdditionalInfo,
                                purchaseAdditionalInfo.orderType,
                                paymentMethod,
                                skin
                              );

                              if (!!rewards) {
                                onRewardRedeemXtreme(rewards, skin);
                              }

                              // clear cart
                              cart.updateCart(null);
                              localforage.removeItem(skin + "__cart");
                              localforage.removeItem(skin + "__orderSummary");
                              //localforage.removeItem(skin + "__activeOrderType");
                              localforage.removeItem(skin + "__storedRewardsDetails");
                              localforage.removeItem(skin + "__storedPromoCode");
                              localforage.removeItem(skin + "__storedRewards");
                              localforage.removeItem(skin + "__coupon");
                              localforage.removeItem(skin + "__pointsIssued");
                              localforage.removeItem(skin + "__paymentDetails");
                              localforage.removeItem(skin + "__storedTip");
                              localforage.removeItem(skin + "__usedPaymentMethod");
                              updateOrderTime(activeStore, activeOrderType);
                            });
                        });
                      });
                    });
                  });
                });
              });
            }
          });
        }
      });
    }
  }, [completedOrderId]);

  const [isIOSNativeApp, setIsIOSNativeApp] = useState(null);
  const [isAndroidNativeApp, setIsAndroidNativeApp] = useState(null);

  useEffect(() => {
    /** Detect if it is native ios */
    var standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    if (!standalone && !safari && ios && deviceWidth <= 660) {
      //show the ios Disclaimer
      setIsIOSNativeApp(true);
    } else {
      //for debugging set to true
      setIsIOSNativeApp(false);
    }
  }, [deviceWidth]);

  useEffect(() => {
    /** Detect if it is native Android app */
    if (
      document.referrer &&
      document.referrer.includes("android-app://com.givex.cws5") &&
      deviceWidth <= 660
    ) {
      setIsAndroidNativeApp(true);
    } else {
      setIsAndroidNativeApp(false);
    }
  }, [deviceWidth]);

  const getDeviceOSVersion = () => {
    if (isIOSNativeApp && navigator.userAgent.match(/iPhone OS \d+(_\d+)*(_\d+)*\s+/)) {
      return navigator.userAgent.match(/iPhone OS \d+(_\d+)*(_\d+)*\s+/)[0].replace(/_/g, ".");
    } else if (isAndroidNativeApp && navigator.userAgent.match(/Android (\d+(?:\.\d+)*)/)) {
      return navigator.userAgent.match(/Android (\d+(?:\.\d+)*)/)[0];
    } else {
      return "";
    }
  };

  useEffect(() => {
    sessionStorage.setItem(skin + "__requiresStoreAndTimeUpdate", true);
  }, []);

  return (
    <>
      {completedOrderId != null &&
        orderQuantity != null &&
        finalOrderTotal != null &&
        thankYouImage != null &&
        itemsForOrderTypeLabel && (
          <div className="order-confirmation-container bottom-padding">
            {thankYouImage && (
              <div
                className="order-confirmation__image"
                style={
                  (isIOSNativeApp && appSettings["ios-app-link"] !== "") ||
                  (isAndroidNativeApp && appSettings["android-app-link"] !== "")
                    ? { maxWidth: "190px", margin: "0 auto" }
                    : {}
                }
              >
                <img
                  src={thankYouImage}
                  alt=""
                  aria-hidden="true"
                  style={
                    (isIOSNativeApp && appSettings["ios-app-link"] !== "") ||
                    (isAndroidNativeApp && appSettings["android-app-link"] !== "")
                      ? { textAlign: "center", marginTop: "1.2em", marginBottom: "0.4em" }
                      : {}
                  }
                />
              </div>
            )}
            <div className="order-confirmation__thankyou-note">
              <div className="order-confirmation__checkmark">
                <IconCheck />
              </div>
              <h2 className="order-confirmation__heading">
                {appLabels["order"]["order-thank-you-message"]}
              </h2>
            </div>
            <div className="order-confirmation__order-id">
              {" "}
              {appLabels["order"]["your-order-number-is"]} <span>{completedOrderId}</span>
              {!paymentDetails && <>{". "}</>}
              {customerInfo && customerInfo.email && !paymentDetails && (
                <>{appLabels["order"]["we-sent-you-confirmation-email"]}</>
              )}
              {customerInfo && customerInfo.email && paymentDetails && (
                <>
                  {" "}
                  {appLabels["order"]["order-processed-using-digital-payment"]}{" "}
                  <span>{paymentDetails}</span>
                  {"."}
                </>
              )}
            </div>
            <div className="order-confirmation__order-summary">
              {activeOrderType === "dinein" ? (
                <>
                  {itemsForOrderTypeLabel.split(
                    appLabels["order"]["item-for-dine-in-at"].split("[order-type]")[1].trim()
                  )}
                  {" | "}
                  <span>
                    <strong>
                      {appLabels["order"]["table"]} <span>{activeTableNumber}</span>
                    </strong>
                  </span>{" "}
                  {itemsForOrderTypeLabel.split(" ").pop()}
                </>
              ) : (
                <>{itemsForOrderTypeLabel}</>
              )}{" "}
              <span>
                <strong>
                  {`${activeStore.name.trim()}, ${activeStore.address.trim()}, ${
                    activeStore.city
                  }, ${activeStore.province}, ${activeStore.postal}.`}
                </strong>
                <br></br>
                <strong>{activeStore.phone}</strong>
              </span>
            </div>
            {userStatus === "logged-in" ? (
              <>
                {pointsIssued && isRewardsOn && (
                  <div className="order-confirmation__points-diclaimer">
                    <span className="order-confirmation__points-issued">
                      {
                        appLabels["loyalty"]["order-confirmation-points-issued"].split(
                          "[points]"
                        )[0]
                      }
                      {pointsIssued} <IconGoldStar />
                      {appLabels["loyalty"]["order-confirmation-points-issued"].split("[icon]")[1]}
                    </span>
                  </div>
                )}

                {/** Only show order status if the service is enabled, othe */}
                {isOrderStatusEnabled ? (
                  <>
                    <div className="order-confirmation__rewards-bottom button-container--single-bottom order-confirmation__rewards-bottom__bottom-margin button-container--multiple-bottom">
                      {/** Show order status and go homo button */}
                      <Link
                        to="/online-ordering/recent-orders/active-orders"
                        className="button  button--primary"
                      >
                        <span>{appLabels["account"]["order-status"]}</span>
                      </Link>

                      <Link to="/dashboard#order-confirmation" className="button  button--primary">
                        <span>{appLabels["order"]["back-to-home"]}</span>
                      </Link>
                    </div>
                  </>
                ) : (
                  <div
                    className={`order-confirmation__rewards-bottom button-container--single-bottom order-confirmation__rewards-bottom__bottom-margin`}
                  >
                    {/** go home button */}

                    <Link to="/dashboard" className="button  button--primary">
                      <span>{appLabels["order"]["back-to-home"]}</span>
                    </Link>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="order-confirmation__points-diclaimer">
                  {pointsIssued && (
                    <span className="order-confirmation__points-issued">
                      {youCouldHaveEarnedPointsMessage.split("[points]")[0]}
                      {pointsIssued}
                      <IconGoldStar />
                      {youCouldHaveEarnedPointsMessage.split("[points]")[1]}
                    </span>
                  )}
                  {isRewardsOn && (
                    <span>
                      {appLabels["loyalty"]["register-today-and-start-collecting-points-message"]}
                    </span>
                  )}
                </div>

                <div
                  className={`order-confirmation__rewards-bottom button-container--single-bottom order-confirmation__rewards-bottom__bottom-margin `}
                >
                  <Link to="/dashboard" className="button  button--primary">
                    <span>{appLabels["order"]["back-to-home"]}</span>
                  </Link>
                </div>
              </>
            )}

            {((isIOSNativeApp && appSettings["ios-app-link"] !== "") ||
              (isAndroidNativeApp && appSettings["android-app-link"] !== "")) && (
              <div className="order-confirmation__ratings-main-container">
                <h4 className="order-confirmation__ratings-header">
                  {appLabels["order"]["app-rating-header"]}
                </h4>
                <div className="order-confirmation__ratings-button-container">
                  <a
                    href={
                      isIOSNativeApp ? appSettings["ios-app-link"] : appSettings["android-app-link"]
                    }
                    aria-label={appLabels["order"]["app-rating-thumbs-up"]}
                  >
                    <IconThumbsUp />
                  </a>
                  <a
                    href={`mailto:${appSettings["merchant-email"]}?subject=${encodeURIComponent(
                      appLabels["order"]["merchant-email-subject"].replace(
                        "[merchant-name]",
                        manifestContext.name
                      )
                    )}&cc=android@givex.com&body=${encodeURIComponent(
                      `${appLabels["order"]["merchant-email-body-template-line-1"]}\n\n${
                        appLabels["order"]["merchant-email-description-of-issue"]
                      }\n\n${appLabels["order"]["merchant-email-enter-details"]}\n\n\n${
                        appLabels["order"]["merchant-email-steps-taken"]
                      }\n\n${
                        appLabels["order"]["merchant-email-enter-details"]
                      }\n\n\n--------------------\n${
                        appLabels["order"]["merchant-email-do-not-remove"]
                      }\n${appLabels["order"]["merchant-email-information-used-to-help"]}\n${
                        appLabels["order"]["merchant-email-username"]
                      }: ${
                        userStatus === "logged-in"
                          ? customerInfo.email
                          : appLabels["order"]["merchant-email-guest-user"] +
                            "(" +
                            `${customerInfo && customerInfo.email ? customerInfo.email : ""}` +
                            ")"
                      }\n${appLabels["order"]["merchant-email-device-type"]}: ${
                        isIOSNativeApp ? "iPhone" : "Android"
                      }\n${
                        appLabels["order"]["merchant-email-os-version"]
                      }: ${getDeviceOSVersion()}\n--------------------\n
                    `
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={appLabels["order"]["app-rating-thumbs-down"]}
                  >
                    <IconThumbsDown />
                  </a>
                </div>
              </div>
            )}

            <AdditionalDisclaimer
              disclaimer={appLabels["order"]["order-confirmation-disclaimer"]}
            />
          </div>
        )}
      {/*orderQuantity != null && orderQuantity === 0 && <Redirect to="/dashboard" />*/}
    </>
  );
};

// Libraries
import React, { useState, useEffect, useContext } from "react";
import localforage from "localforage";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Contexts
import CartContext from "../../OnlineOrdering/Cart/CartContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import BrandingContext from "../../App/BrandingContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import { MenuContext } from "../Menu/MenuContext";
import OrderTypeContext from "../OrderTypeContext";
import StoreContext from "../StoreContext";

// UI components
import { FavoriteItem } from "./FavoriteItem";
import { MenuCategoryFilterButtonsList } from "../Menu/MenuCategoryFilterButtonsList/MenuCategoryFilterButtonsList";
import { MenuBreadcrumbs } from "../Menu/MenuBreadcrumbs";

// CSS
import "./Favorites.css";
import AppLanguageContext from "../../App/AppLanguageContext";
import UserRoleContext from "../../App/UserRoleContext";
import { updateCustomerFavorite } from "./favoriteHelpers";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import OrderTimeContext from "../OrderTimeContext";

export const Favorites = () => {
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  const activeOrderType = useContext(OrderTypeContext).value;
  const { activeOrderStore } = useContext(StoreContext);
  const orderTimeContext = useContext(OrderTimeContext);

  const [customerID, setCustomerID] = useState(null);
  //get the customer id from storage
  useEffect(() => {
    localforage.getItem(skin + "__customerID").then((storedCustomerID) => {
      if (storedCustomerID) setCustomerID(storedCustomerID);
    });
  }, []);

  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);

  const branding = useContext(BrandingContext);

  const menuContext = useContext(MenuContext);
  const favorites = menuContext.favorites;

  useEffect(() => {
    if (customerID && loginToken) {
      updateCustomerFavorite(skin, appLanguage, loginToken, customerID, "get", []).then(
        (favoriteItems) => {
          menuContext.updateFavorites(favoriteItems);
        }
      );
    }
  }, [customerID, loginToken]);

  const removeFromFavorite = (itemIndex) => {
    const updatedFavorites = [...favorites];
    updatedFavorites.splice(itemIndex, 1);

    updateCustomerFavorite(skin, appLanguage, loginToken, customerID, "update", updatedFavorites)
      .then((updatedFavourites) => {
        menuContext.updateFavorites(updatedFavorites);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //Get stored menu from localforage
  const [menu, setMenu] = useState(null);
  useEffect(() => {
    if (!menu) {
      localforage.getItem(skin + "__storedMenu").then((storedMenu) => {
        if (storedMenu) {
          setMenu(storedMenu);
        }
      });
    }
  }, [menu]);

  const cart = useContext(CartContext);

  const deviceWidth = useWindowSize().width;
  const [itemNotAvailable, setItemNotAvailable] = useState(false);
  const [isQuantityValid, setIsQuantityValid] = useState(null);
  return (
    <>
      {deviceWidth >= 660 && <MenuCategoryFilterButtonsList />}
      <MenuBreadcrumbs currentLevelCategory={"favorites"} />
      {favorites ? (
        <ul className="favorite-items-list">
          {favorites.map((item, index) => (
            <React.Fragment key={`${item.item_id}-${item.subcategory}-${index}`}>
              <FavoriteItem
                item={item}
                itemIndex={index}
                removeFavorite={removeFromFavorite}
                cart={cart}
                menu={menu}
                branding={branding}
                setItemNotAvailable={setItemNotAvailable}
                cartContext={cart}
                skin={skin}
                activeOrderStore={activeOrderStore}
                activeOrderType={activeOrderType}
                orderTimeContext={orderTimeContext}
                key={`${item.item_id}-${item.subcategory}-${index}`}
                setIsQuantityValid={setIsQuantityValid}
              />
            </React.Fragment>
          ))}
        </ul>
      ) : (
        <p>{appLabels["order"]["no-favorites-message"]}</p>
      )}

      {itemNotAvailable && (
        <DialogModal
          message={appLabels["order"]["item-not-available"]}
          isCancelConfirm={false}
          resetRemoveDialog={() => {
            setItemNotAvailable(false);
          }}
        />
      )}

      {!isQuantityValid && isQuantityValid !== null && (
        <DialogModal
          message={appLabels["order"]["invalid-item-quantity-error"]}
          isCancelConfirm={false}
          resetRemoveDialog={() => {
            setIsQuantityValid(null);
          }}
        />
      )}
    </>
  );
};

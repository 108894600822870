import React, { useContext } from "react";
import AppLabelsContext from "../../../App/AppLabelsContext";

export const TipButton = (props) => {
  const { label, isSelected, onClickTipButton, index } = props;
  const appLabels = useContext(AppLabelsContext);

  return (
    <button
      type="button"
      className={`cart__tip-button ${isSelected ? " cart__tip-button-selected" : ""} `}
      onClick={() => onClickTipButton(index)}
      aria-label={label === "No Tip" ? appLabels["account"]["add-money-submit-button"] + " " + label : isSelected ? appLabels["order"]["currently-selected-tip-option"] + label : appLabels["order"]["add-tip"] + " " + label}
    >
      {label}
    </button>
  );
};

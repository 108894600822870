import { callAPI } from "../../../../../_common/Api";
import { toDollars } from "../../../../../_common/helpers";

export const getAPICardBalance = async (
  skin,
  givexNumber,
  pin,
  psdIndicator,
  reCAPTCHA,
  formLabels,
  currencySymbol,
  currencySymbolSide,
  language
) => {
  const api994Data = await callAPI(skin, "dc_994", {
    params: [language, "994", "mqid", "mqpass", givexNumber, pin, psdIndicator],
    id: "994",
    "g-recaptcha-response": reCAPTCHA,
  });

  const cardBalance = api994Data.result.I2;

  if (cardBalance && parseFloat(cardBalance) > 0) {
    // Successfully retrieve gift card's balance

    const isoSerial = api994Data.result.I10;

    const iso = isoSerial.split("-")[0];
    const serial = isoSerial.split("-")[1];

    const formattedGiftCard = {
      iso: iso,
      serial: serial,
      maskedNumber: iso + "*****" + serial + "*",
      currency: api994Data.result.I5,
      balance: parseFloat(cardBalance),
      displayBalance:
        toDollars(currencySymbol, currencySymbolSide, cardBalance, language) +
        " " +
        api994Data.result.I5,
      image: api994Data.result.I16,
      pin:pin || "",
      points: api994Data.result.I3
    };

    return formattedGiftCard;
  } else {
    // Card balance is 0
    if (parseFloat(cardBalance) === 0) {
      return "Card is empty";
    }

    // Failed to retrieve gift card's balance
    if (api994Data.status === 2) {
      return "Card number is invalid"; // wxget: invalid card number
    } else if (api994Data.status === 27) {
      return "Card is on hold"; // wxget: card is on hold
    } else if (api994Data.status === 40) {
      return "Card is not active"; // wxget: card is not active
    } else {
      // wxget: generic fallback API error
      return formLabels["generic-fallback-api-error"];
    }
  }
};

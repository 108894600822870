import { formatOptionGroups } from "../OnlineOrdering/Menu/helpers/orderingHelpers";
import { getArray, objectToArray } from "./helpers";

const validateGroups = (groups, formLabels = null) => {
  let errors = 0;
  let errorDetails = []
  let idOfGroupWithErrors;
  let idOfParentOfGroupWithErrors;
  let idOfMainAddon;
  var BreakException = {};
  try {
    groups.forEach(([key, group]) => {
      const items = getArray(group, "items");
      const min = parseInt(group.minQuantity);

      let numberSelected = 0;

      items.forEach(([key, item]) => {
        if (item.isSelected) {
          if (item.quantity) {
            numberSelected += item.quantity;
          } else {
            numberSelected++;
          }

          //validate addon modifiers
          const modifierGroups = getArray(item, "modifierGroups");
          idOfMainAddon = item.parentId;
          if (modifierGroups && modifierGroups.length > 0) {
            const nestedValidationResult = validateGroups(modifierGroups, formLabels);
            errors += nestedValidationResult.errors;
            idOfGroupWithErrors = nestedValidationResult.idOfGroupWithErrors;
            idOfParentOfGroupWithErrors = nestedValidationResult.idOfParentOfGroupWithErrors;
            /** Once a nested modifier with a required selection was found, break out of the loop */
            if (nestedValidationResult.idOfGroupWithErrors && nestedValidationResult.errors > 0) {
              throw BreakException;
            }
          }
        }
      });

      if (numberSelected < min) {
        errors++;
        idOfGroupWithErrors = group.id;
        idOfParentOfGroupWithErrors = group.parentId;
        let errorMessage = formLabels["required-selection-for"] + " " + group.name + " " + formLabels["minimum"] + " " + min + " " + formLabels["selection-needed"]
        errorDetails.push(errorMessage)
        throw BreakException;
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }
  return { errors, idOfGroupWithErrors, idOfParentOfGroupWithErrors, idOfMainAddon, errorDetails };
};

export const validateOptions = (item, formLabels = null) => {
  let errors = 0;
  let idOfGroupWithErrors;
  let idOfParentOfGroupWithErrors;
  let idOfMainAddon;
  const addonGroups = getArray(item, "addonGroups");
  const modifierGroups = getArray(item, "modifierGroups");

  let validation;
  let errorDetails;
  if (addonGroups.length > 0) {
    validation = validateGroups(addonGroups, formLabels);
    errorDetails = validation.errorDetails
    errors += validation.errors;
    if (validation.errors > 0) {
      idOfGroupWithErrors = validation.idOfGroupWithErrors;
      idOfParentOfGroupWithErrors = validation.idOfParentOfGroupWithErrors;
      idOfMainAddon = validation.idOfMainAddon;
    }
  }
  if (modifierGroups.length > 0) {
    validation = validateGroups(modifierGroups, formLabels);
    errorDetails = validation.errorDetails
    errors += validation.errors;
    if (validation.errors > 0) {
      idOfGroupWithErrors = validation.idOfGroupWithErrors;
      idOfParentOfGroupWithErrors = validation.idOfParentOfGroupWithErrors;
      idOfMainAddon = validation.idOfMainAddon;
    }
  }
  return { errors, idOfGroupWithErrors, idOfParentOfGroupWithErrors, idOfMainAddon, errorDetails };
};

export const isMandatoryAddonGroup = (addonGroup) => {
  const minQuantity = parseInt(addonGroup.minQuantity);
  const maxQuantity = parseInt(addonGroup.maxQuantity);

  let isMandatory = false;

  if (minQuantity > 1 && !maxQuantity) {
    isMandatory = true;
  } else if ((minQuantity === 1 && !maxQuantity) || (minQuantity === 1 && maxQuantity === 1)) {
    isMandatory = true;
  } else if (minQuantity >= 1 && maxQuantity > 1) {
    isMandatory = true;
  }

  return isMandatory;
};

export const checkOutOfStockAddons = (menuItem, orderLabels) => {
  const addonGroups = menuItem.addonGroups;

  if (addonGroups && Object.keys(addonGroups).length > 0) {
    let outOfStockAddons = 0;
    let isAddonGroupOutOfStock = false;

    Object.keys(addonGroups).forEach((groupKey) => {
      if (isMandatoryAddonGroup(addonGroups[groupKey])) {
        // Only check mandatory (min qty >= 1) addon groups
        const addons = addonGroups[groupKey].items;

        if (addons && Object.keys(addons).length > 0) {
          Object.keys(addons).forEach((key) => {
            if (
              addons[key].item_count !== "None" &&
              Number(addons[key].item_count) <= 0 &&
              addons[key].isSelected
            ) {
              outOfStockAddons++;
            }
          });

          const minQuantity = parseInt(addonGroups[groupKey].minQuantity);
          const remainingInStockAddons = Object.keys(addons).length - outOfStockAddons;

          if (
            outOfStockAddons === Object.keys(addons).length ||
            (minQuantity > 0 && remainingInStockAddons < minQuantity)
          ) {
            isAddonGroupOutOfStock = true;
            addonGroups[groupKey].isOutOfStock = true;
          }
        }
      }
    });

    let errorMessage = "";

    if (outOfStockAddons > 0 && isAddonGroupOutOfStock) {
      if (outOfStockAddons > 1) {
        const label = orderLabels["item-detail-addons-out-of-stock-error"];
        errorMessage = label.split("[number]")[0] + outOfStockAddons + label.split("[number]")[1];
      }
    }

    return { isOutOfStock: isAddonGroupOutOfStock, errorMessage };
  } else {
    return false;
  }
};

/** Returns the item price plus any selected addons */
export const getTotalItemPriceBasedOnSelection = (item) => {
  let totalPrice = Number(item.price);

  if (item.addonGroups) {
    //add the price of any selected addons to the total price
    Object.keys(item.addonGroups).forEach((addonGroupKey) => {
      const addonGroup = item.addonGroups[addonGroupKey];
      const addonGroupItems = addonGroup.items;
      Object.keys(addonGroupItems).forEach((addonKey) => {
        const addon = addonGroupItems[addonKey];
        if (addon.isSelected) {
          totalPrice += Number(addon.price) * (addon.quantity || 1);
        }
      });
    });
  }

  return totalPrice * (item.quantity || 1);
};

/**
 *
 * @param {*} menuItem
 * @param {*} isStepByStepOrderingEnabled
 * @param {*} currentActiveOptionGroupIndex
 * @returns number of required selections, either for the the whole item or a particular options group, based on the isStepByStepOrdering enabled flag
 */
export const checkRequiredSelection = async (
  menuItem,
  isStepByStepOrderingEnabled,
  currentActiveOptionGroupIndex,
  numberOfOptionGroups,
  skin
) => {
  let numberOfRequiredChoices = 0;
  const modifierGroups = menuItem.modifierGroups;
  const addonGroups = menuItem.addonGroups;

  if (isStepByStepOrderingEnabled) {
    let tempFormattedGroups = {};
    /** Format Modifier groups */
    const formattedModifierGroups = await formatOptionGroups(modifierGroups, false, false, skin);

    Object.keys(formattedModifierGroups).forEach((key) => {
      tempFormattedGroups[key] = formattedModifierGroups[key];
    });

    /** Format Addon groups */
    const formattedAddonGroups = await formatOptionGroups(addonGroups, false, false, skin);

    Object.keys(formattedAddonGroups).forEach((key) => {
      tempFormattedGroups[key] = formattedAddonGroups[key];
    });

    tempFormattedGroups = objectToArray(tempFormattedGroups);

    const currentActiveGroup = tempFormattedGroups[currentActiveOptionGroupIndex];

    if (!currentActiveGroup && numberOfOptionGroups) {
      if (currentActiveOptionGroupIndex < 0) {
        return 0;
      } else {
        let totalRequiredSelections = 0;
        for (let i = numberOfOptionGroups - 1; i >= 0; i--) {
          totalRequiredSelections += await checkRequiredSelection(
            menuItem,
            isStepByStepOrderingEnabled,
            i,
            numberOfOptionGroups,
            skin
          );
        }
        return totalRequiredSelections;
      }
    } else if (currentActiveGroup) {
      const groupMinQuantity = Number(currentActiveGroup.minQuantity);
      let minRequirementForGroupIsMet = false;
      const options = currentActiveGroup.items;

      if (groupMinQuantity > 0) {
        if (
          objectToArray(options).filter((option) => option.isSelected).length >= groupMinQuantity
        ) {
          minRequirementForGroupIsMet = true;
        } else {
          minRequirementForGroupIsMet = false;
          numberOfRequiredChoices += groupMinQuantity;
        }

        if (!minRequirementForGroupIsMet) {
          Object.keys(options).forEach((optionKey) => {
            const option = options[optionKey];
            if (option.isSelected) {
              if (option.quantity) {
                numberOfRequiredChoices = numberOfRequiredChoices - option.quantity;
              } else {
                numberOfRequiredChoices--;
              }

              //check for required nested modifiers
              const nestedOptionsGroup = option.modifierGroups;
              if (nestedOptionsGroup && Object.keys(nestedOptionsGroup).length > 0) {
                Object.keys(nestedOptionsGroup).forEach((nestedOptionsGroupKey) => {
                  const nestedModifierGroup = nestedOptionsGroup[nestedOptionsGroupKey];
                  const nestedModifierGroupMinQuantity = parseInt(nestedModifierGroup.minQuantity);
                  let minRequirementForNestedModifierIsMet = false;
                  const nestedModifiers = nestedModifierGroup.items;

                  if (nestedModifierGroupMinQuantity > 0) {
                    if (
                      objectToArray(nestedModifiers).filter((nestedMod) => nestedMod.isSelected)
                        .length >= nestedModifierGroupMinQuantity
                    ) {
                      minRequirementForNestedModifierIsMet = true;
                    } else {
                      minRequirementForNestedModifierIsMet = false;
                      numberOfRequiredChoices += nestedModifierGroupMinQuantity;
                    }

                    if (!minRequirementForNestedModifierIsMet) {
                      Object.keys(nestedModifiers).forEach((nestedModifierKey) => {
                        const nestedModifier = nestedModifiers[nestedModifierKey];
                        if (nestedModifier.isSelected) {
                          numberOfRequiredChoices--;
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        }
      }
    } else {
      return 0;
    }
  } else {
    if (modifierGroups) {
      Object.keys(modifierGroups).forEach((modifierGroupKey) => {
        const modifierGroup = modifierGroups[modifierGroupKey];
        const modifierGroupMinQuantity = parseInt(modifierGroup.minQuantity);
        let minRequirementForModifierGroupIsMet = false;
        const modifiers = modifierGroup.items;
        if (modifierGroupMinQuantity > 0) {
          /** Check if the Modifier group is already satisfied. If so, any additional selection should not affect the total 'Required' selection
           */
          if (
            objectToArray(modifiers).filter((modifier) => modifier.isSelected).length >=
            modifierGroupMinQuantity
          ) {
            minRequirementForModifierGroupIsMet = true;
          } else {
            minRequirementForModifierGroupIsMet = false;
            numberOfRequiredChoices += modifierGroupMinQuantity;
          }

          if (!minRequirementForModifierGroupIsMet) {
            Object.keys(modifiers).forEach((modifierKey) => {
              const modifier = modifiers[modifierKey];
              if (modifier.isSelected) {
                numberOfRequiredChoices--;
              }
            });
          }
        }
      });
    }

    if (addonGroups) {
      Object.keys(addonGroups).forEach((addonGroupKey) => {
        const addonGroup = addonGroups[addonGroupKey];
        const addonGroupMinQuantity = parseInt(addonGroup.minQuantity);
        let minRequirementForAddonGroupIsMet = false;
        const addons = addonGroup.items;
        /** Check if the addon group is already satisfied. If so, any additional selection should not affect the total 'Required' selection
         */
        if (addonGroupMinQuantity > 0) {
          if (
            objectToArray(addons).filter((addon) => addon.isSelected).length >=
            addonGroupMinQuantity
          ) {
            minRequirementForAddonGroupIsMet = true;
          } else {
            minRequirementForAddonGroupIsMet = false;
            numberOfRequiredChoices += addonGroupMinQuantity;
          }

          if (!minRequirementForAddonGroupIsMet) {
            Object.keys(addons).forEach((addonKey) => {
              const addon = addons[addonKey];
              if (addon.isSelected) {
                if (addon.quantity) {
                  numberOfRequiredChoices = numberOfRequiredChoices - addon.quantity;
                } else {
                  numberOfRequiredChoices--;
                }

                //check for required nested modifiers
                const nestedModifierGroups = addon.modifierGroups;
                if (nestedModifierGroups && Object.keys(nestedModifierGroups).length > 0) {
                  Object.keys(nestedModifierGroups).forEach((nestedModifierGroupKey) => {
                    const nestedModifierGroup = nestedModifierGroups[nestedModifierGroupKey];
                    const nestedModifierGroupMinQuantity = parseInt(
                      nestedModifierGroup.minQuantity
                    );
                    let minRequirementForNestedModifierIsMet = false;
                    const nestedModifiers = nestedModifierGroup.items;

                    if (nestedModifierGroupMinQuantity > 0) {
                      if (
                        objectToArray(nestedModifiers).filter((nestedMod) => nestedMod.isSelected)
                          .length >= nestedModifierGroupMinQuantity
                      ) {
                        minRequirementForNestedModifierIsMet = true;
                      } else {
                        minRequirementForNestedModifierIsMet = false;
                        numberOfRequiredChoices += nestedModifierGroupMinQuantity;
                      }

                      if (!minRequirementForNestedModifierIsMet) {
                        Object.keys(nestedModifiers).forEach((nestedModifierKey) => {
                          const nestedModifier = nestedModifiers[nestedModifierKey];
                          if (nestedModifier.isSelected) {
                            numberOfRequiredChoices--;
                          }
                        });
                      }
                    }
                  });
                }
              }
            });
          }
        }
      });
    }
  }

  return numberOfRequiredChoices;
};

export const isValidItemQuantity = (cart, menuItem, isModifying = false) => {
  const itemCount = menuItem.item_count;
  const itemQuantity = menuItem.quantity || 1;

  /** Check the cart if there are other instances of the same item in the cart */
  //modifying an item
  if (isModifying) {
    //cart has at least one item
    let menuItemCartInstances = 0;

    cart.forEach((cartItem) => {
      if (cartItem.id === menuItem.id && cartItem.quantity !== menuItem.quantity) {
        menuItemCartInstances = menuItemCartInstances + menuItem.quantity;
      }
    });

    return itemCount === "None" ? true : menuItemCartInstances <= itemCount;
  }
  //adding an item
  else {
    //cart is empty
    if (cart.length === 0) {
      return itemCount === "None" ? true : itemQuantity <= itemCount;
    } else {
      //cart has at least one item
      let menuItemCartInstances = 0;
      cart.forEach((cartItem) => {
        if (cartItem.id === menuItem.id) {
          menuItemCartInstances = menuItemCartInstances + cartItem.quantity;
        }
      });

      return itemCount === "None" ? true : menuItemCartInstances + itemQuantity <= itemCount;
    }
  }
};

export const checkLimitedStockedItemToCart = (menuItem) => {
  const itemCount = menuItem.item_count;
  const itemQuantity = menuItem.quantity;

  return itemCount < itemQuantity && itemCount !== 0 && itemCount > 0;
};

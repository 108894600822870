import React, { useContext } from "react";

/** Context */
import BrandingContext from "../../../App/BrandingContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import OrderTypeContext from "../../OrderTypeContext";

/** Helpers */
import { hexToHSL } from "../../../_common/colorHelpers";

/** UI Components */
import { LocationSearchingTwoTone } from "@material-ui/icons";

/** CSS */
import "./Geolocation.css";

export const Geolocation = ({ getUserGeolocation, isActive }) => {
  const brandingContext = useContext(BrandingContext);
  const appLabels = useContext(AppLabelsContext);
  const orderTypeContext = useContext(OrderTypeContext);

  const primaryColor = brandingContext["primary-colour"];
  const secondaryColor = brandingContext["secondary-colour"];

  const primaryColorHSL = hexToHSL(primaryColor);
  const innerRingBorderColor =
    primaryColorHSL.split(",")[0] + ", " + primaryColorHSL.split(",")[1] + ", 70%)";

  const orderType = orderTypeContext.value;

  return (
    <>
      {/*<div className="button--geolocation-wrapper">*/}
      <button
        onClick={() => {
          getUserGeolocation(false);
        }}
        className="button order-stores__use-location-button"
        aria-label={appLabels["general"]["find-location-google-maps"]}
        type="button"
      >
        <LocationSearchingTwoTone className="icon--target" fontSize="small" />
        <span style={{ fontSize: "1.0rem", color: secondaryColor }}>
          {orderType === "delivery"
            ? appLabels["order"]["use-my-location-for-delivery"]
            : appLabels["order"]["use-my-location-for-pickup"]}
        </span>
        {isActive && (
          <>
            <span
              className="button--geolocation-wrapper__main-ring"
              style={{ borderColor: primaryColor }}
            />
            <span
              className="button--geolocation-wrapper__inner-ring"
              style={{ border: `6px solid ${innerRingBorderColor}` }}
            />
            <span
              className="button--geolocation-wrapper__large-pulse"
              style={{ backgroundColor: primaryColor }}
            />
          </>
        )}
      </button>
      {/*</div>*/}
    </>
  );
};

import React from "react";
import { ReactComponent as IconArrowDown } from "../icons/IconArrowDown.svg";
import "./FormSelect.css";

export const DummyFormSelect = props => {
  const { handleChange, isSmall, id, customClass, label, name, options } = props;

  return (
    <div
      className={`form__field-wrapper${isSmall ? " form__field-wrapper--small" : ""}${customClass ||
        ""}`}
    >
      <label htmlFor={id} className="form__field-label visually-hidden">
        {label}
      </label>
      <select onChange={handleChange} id={id} name={name} className="form__field">
        {options.map(option => (
          <option key={option.serial} value={option.serial}>
            {option.maskedNumber}
          </option>
        ))}
      </select>
      <IconArrowDown />
    </div>
  );
};

export const formLabels = {
  "card-details": "Card Details",
  "credit-card-number": "Credit Card Number",
  "name-on-card": "Name on card",
  "expiry-date": "Expiry Date",
  cvv: "CVV",
  "save-credit-card": "Save credit card",
  "billing-address": "Billing Address",
  "address-1": "Address 1",
  "address-2": "Address 2",
  country: "Country",
  province: "Province",
  city: "City",
  postal: "Postal Code",
  phone: "Phone",
  email: "Email",
  "confirm-email": "Confirm Email",
  password: "Password",
  "confirm-password": "Confirm Password",
  "keep-me-logged-in": "Remember me",
  "first-name": "First Name",
  "last-name": "Last Name",
  "special-instructions": "Special Instructions",
  "form-security-message": "This information will be saved and encrypted securely",
  "card-number": "Card Number",
  "card-description": "Card Description",
  required: "Required",
  "missing-required-fields-error": "Complete the fields above",
  "missing-required-addons-modifiers-error": "Mandatory choices not selected",
  "title-numbers-error": "Title cannot contain numbers",
  "first-name-numbers-error": "First name cannot contain numbers",
  "middle-name-numbers-error": "Middle name cannot contain numbers",
  "last-name-numbers-error": "Last name cannot contain numbers",
  "invalid-email-error": "Enter a valid email",
  "phone-only-numbers-error": "Phone number can only contain numbers",
  "invalid-card-number": "Enter a valid number",
  "credit-card-numbers-error": "Credit card name cannot contain numbers",
  "invalid-date-error": "Enter a valid date",
  "invalid-cvv-error": "Enter a valid CVV",
  "invalid-postal-error": "Enter a valid postal code",
  "invalid-birthday-error": "Enter a valid birthday",
  "password-char-length-error": "Must be at least 8 characters long",
  "passwords-dont-match": "Passwords do not match",
  "emails-dont-match": "Emails do not match",
  "city-numbers-error": "City cannot container numbers",
  "country-numbers-error": "Country cannot container numbers",
  "county-numbers-error": "County cannot container numbers",
  "select-one": "Select One",
  weekly: "Weekly",
  monthly: "Monthly",
  male: "Male",
  female: "Female",
  "other-gender": "Non-binary",
  "yes-to-promo-email": "Yes",
  "no-to-promo-email": "No",
  english: "English",
  french: "French",
  spanish: "Spanish",
  portuguese: "Portuguese",
  "simplified-chinese": "Chinese (Simplified)",
  "traditional-chinese": "Chinese (Traditional)",
  "default-submit-button": "Submit",
  "default-submit-button-success": "Submitted",
  "default-submit-button-processing": "Submitting...",
  "optional-label": "Optional",
  "enter-in-input-mask-pattern-format": "Enter in [mask-pattern] format",
  "generic-fallback-api-error": "Something went wrong with your submission, please try again",
  "invalid-address-error": "Enter a valid address",
  "accept-terms-text":
    "I have read, understood and agree to be bound by the [terms-and-conditions] of use.",
  "accept-terms-link-text": "terms and conditions",
  "add-new-gift-card-invalid-card-error": "Card number is invalid, please try again.",
  "special-delivery-instructions-field-label": "Special Delivery Instruction",
  "zip-code": "Zip Code",
  "unit-number": "Unit Number",
  "invalid-phone-number": "Invalid Phone Number",
  "incorrect-username-password": "Username/Password is incorrect",
  "save-credit-card-login-error":
    "Please sign in or create an account to save your credit card for a quick checkout next time.",
  "invalid-delivery-address-error": "Please enter a valid & complete address",
  "street-address": "Street Address",
  "apt-suit-unit": "Apt, Suite or Unit",
  "primary-address": "Primary Address",
  section: "Section",
  row: "Row",
  seat: "Seat",
  "do-not-include-country-code": "Please do not include country code",
  "eGiftCard-amount-incorrect": "The Amount entered is incorrect",
  "eGiftCard-amount-not-numeric": "Amount should only contain numbers",
  "eGiftCard-date-time-incorrect": "Date or time is incorrect",
  "eGiftCard-value-negative": "Value must me greater than 0",
  "invalid-amount": "Invalid amount",
  "please-correct-following-errors": "Please correct the following errors",
  "required-selection-for": "Required selection for",
  "selection-needed": "selection needed.",
  "minimum": "minimum"
};

/* eslint-disable no-useless-computed-key */
// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import localforage from "localforage";
import { Route, Switch, Link } from "react-router-dom";

// Helper functions
import { getOrderHistory } from "./orderHistoryHelpers";
import {
  getDayOfWeekFromIndexForOrderStatus,
  getMonthFromIndexForOrderStatus,
  getOrderStatusData,
} from "../OrderStatus/OrderStatusHelpers";
import { capitalizeWords } from "../../_common/stringHelpers";

// Contexts
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import { MenuContext } from "../Menu/MenuContext";
import CartContext from "../Cart/CartContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import ActiveOrdersContext from "../OrderStatus/ActiveOrdersContext";
import LocationsContext from "../Locations/LocationsContext";

// UI components
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { PastOrdersCard } from "./PastOrdersCard";
import { NoRecentOrders } from "./NoRecentOrders";
import { PastOrdersCalaendar } from "./PastOrdersCalendar";
import { LoadingSpinner } from "../../_common/LoadingSpinner";

// CSS
import "./RecentOrders.css";
import "../OrderStatus/OrderStatus.css";

//Tab Views
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { ActiveOrders } from "./ActiveOrders";
import { PastOrders } from "./PastOrders";

export const RecentOrders = () => {
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;

  const appLabels = useContext(AppLabelsContext);

  const foodMenuContext = useContext(MenuContext);
  const cart = useContext(CartContext);
  const activeOrdersContext = useContext(ActiveOrdersContext);

  const vexilorConfig = merchantConfigContext.vexilor.I2;
  const isOrderStatusEnabled = vexilorConfig["order_status_access"] === "t";

  const foodMenu = foodMenuContext.apiData;
  const menuLevel = foodMenuContext.menuLevel;
  const [customerID, setCustomerID] = useState();

  const allLocationsContext = useContext(LocationsContext);

  useEffect(() => {
    localforage.getItem(skin + "__customerID").then((storedCustomerID) => {
      if (storedCustomerID) setCustomerID(storedCustomerID);
    });
  }, []);

  const [recentOrders, setRecentOrders] = useState("idle");
  const [lastUpdateTimeStamp, setLastUpdateTimeStamp] = useState("");
  const [orderHistoryOffset, setOrderHistoryOffset] = useState(0);
  const [hideLoadMore, setHideLoadMore] = useState(false);

  useEffect(() => {
    setLastUpdateTimeStamp(new Date());
    setRecentOrders(null);
    /*localforage.getItem(skin + "__orderHistory").then((orderHistory) => {
      if (orderHistory) {
        setRecentOrders(orderHistory);
        setIsNotification(true);
      } else {
        setRecentOrders(null);
        setIsNotification(true);
      }
    });*/
  }, []);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  const [orderHistoryDates, setOrderHistoryDates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (orderHistoryDates && orderHistoryDates.length > 0) {
      setRecentOrders(null); //this will trigger the API call to 1304 via getOrdeHistory function
    }
  }, [orderHistoryDates]);

  const isCalling1304 = useRef(false);
  const orderHistoryOffsetRef = useRef(0);

  useEffect(() => {
    // Clean up the states
    return () => {
      setOrderHistoryDates(null);
      orderHistoryOffsetRef.current = 0;
    };
  }, []);

  function areArraysEqualById(arr1, arr2) {
    // Check if both arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Extract and sort the ids from both arrays
    let ids1 = arr1.map((obj) => obj.id).sort();
    let ids2 = arr2.map((obj) => obj.id).sort();

    // Compare the sorted arrays of ids
    for (let i = 0; i < ids1.length; i++) {
      if (ids1[i] !== ids2[i]) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    if (
      customerID &&
      ((recentOrders !== "idle" && !recentOrders) ||
        (recentOrders && recentOrders !== "idle" && orderHistoryOffset > 0)) &&
      !isCalling1304.current
    ) {
      const activeOrders = activeOrdersContext.activeOrders;
      isCalling1304.current = true;
      if (datePickerRef && datePickerRef.current) {
        datePickerRef.current.closeCalendar();
      }

      const startDate =
        !!orderHistoryDates && orderHistoryDates.length > 0
          ? new Date(` ${orderHistoryDates[0].format()} 00:00:00`)
          : "";
      const endDate =
        !!orderHistoryDates && orderHistoryDates.length > 0
          ? new Date(`${orderHistoryDates[1].format()} 23:59:59`)
          : "";

      const formattedStartDate =
        startDate !== ""
          ? `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")} 00:00:00`
          : "";
      const formattedEndDate =
        endDate !== ""
          ? `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")} 23:59:59`
          : "";

      getOrderHistory(
        skin,
        customerID,
        appSettings["currency-symbol"],
        appSettings["currency-symbol-side"],
        appLanguage,
        activeOrders.length,
        orderHistoryOffset,
        formattedStartDate,
        formattedEndDate,
        allLocationsContext,
        isOrderStatusEnabled
      ).then((orderHistory) => {
        setOrderHistoryOffset(0);
        isCalling1304.current = false;
        setIsLoading(false);
        if (orderHistory) {
          //before setting the state, check the activeOrderContext and see if there are any active orders there that might be missing still from recent orders
          orderHistory = orderHistory.filter((order) => {
            return order !== undefined;
          });
          let tempOrderHistory = [...orderHistory];

          setHideLoadMore(orderHistory.length < 10);

          if (activeOrders.length === 0) {
            if (recentOrders === null) {
              setRecentOrders(tempOrderHistory);
              localforage.setItem(skin + "__orderHistory", orderHistory);
              return;
            } else {
              if (recentOrders !== false) {
                setRecentOrders(recentOrders.concat(tempOrderHistory));
                localforage.setItem(skin + "__orderHistory", recentOrders.concat(tempOrderHistory));
                return;
              } else if (recentOrders === false && orderHistory && orderHistory.length > 0) {
                setRecentOrders(tempOrderHistory);
                localforage.setItem(skin + "__orderHistory", tempOrderHistory);
              }
            }
          }

          (async () => {
            await activeOrders.forEach((activeOrder, index) => {
              (async () => {
                if (!tempOrderHistory.find((order) => order.id === activeOrder.id)) {
                  tempOrderHistory.unshift({
                    ...activeOrder,
                    orderStatus: await getOrderStatusData(skin, appLanguage, activeOrder),
                  });
                } else {
                  //tempActiveOrders.splice(index, 1);
                }

                if (index === activeOrders.length - 1) {
                  if (recentOrders === null) {
                    if (orderHistory.length > 0) {
                      setRecentOrders(tempOrderHistory);
                      localforage.setItem(skin + "__orderHistory", orderHistory);
                    } else {
                      setRecentOrders([]);
                      localforage.setItem(skin + "__orderHistory", orderHistory);
                    }
                  } else {
                    if (recentOrders !== false) {
                      if (!areArraysEqualById(tempOrderHistory, recentOrders)) {
                        setRecentOrders(recentOrders.concat(tempOrderHistory));
                        localforage.setItem(
                          skin + "__orderHistory",
                          recentOrders.concat(tempOrderHistory)
                        );
                      }
                    }
                  }
                }
              })();
            });
          })();
        } else {
          setIsLoading(false);
          setRecentOrders(false);
        }
      });
    }
  }, [customerID, recentOrders, orderHistoryOffset]);

  const [isNotification, setIsNotification] = useState(false);
  const [isAlertNotification, setIsAlertNotification] = useState(false);

  const updateIsNotification = (isOpen) => {
    setIsNotification(isOpen);
    if (!isOpen && isAlertNotification) setIsAlertNotification(false); // reset alert
  };

  const formatOrderDateAndTime = (orderDateString) => {

    const crossPlatformDateObject = new Date(orderDateString.replace(" ", "T"));
    const orderDay = capitalizeWords(
      getDayOfWeekFromIndexForOrderStatus(crossPlatformDateObject.getDay(), appLabels).substr(0, 3)
    );
    const orderDate = crossPlatformDateObject.getDate();
    const orderMonth = capitalizeWords(
      getMonthFromIndexForOrderStatus(crossPlatformDateObject.getMonth(), appLabels).substr(0, 3)
    );
    const orderHour = crossPlatformDateObject.getHours().toString().padStart(2, "0");
    const orderMinute = crossPlatformDateObject.getMinutes().toString().padStart(2, "0");

    return `${orderDay.substr(0, 3)} ${orderMonth} ${orderDate} ${
      appLabels["account"]["order-timestamp-at"]
    } ${orderHour}:${orderMinute}`;
  };

  const formattedLastUpdateTimeStamp = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };

    const formattedDate = date.toLocaleString(`${appLanguage}`, options);
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let seconds = date.getSeconds().toString().padStart(2, "0");
    return `${capitalizeWords(formattedDate.replace(/,/g, "")).replace(
      /\bDe\b/g,
      "de"
    )} ${hours}:${minutes}:${seconds} `;
  };

  let activeOrdersLength = 0;

  if (recentOrders !== "idle" && recentOrders) {
    activeOrdersLength = recentOrders.filter(
      (order) =>
        order.orderStatus && order.orderStatus.isEnabled && order.orderStatus.isFinished !== true
    ).length;
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& .MuiBox-root": {
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    tabs: {
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
        height: 4,
        maxWidth: "600px",
      },
      "& .MuiTabs-root": {
        backgroundColor: "#f5f5f5",
        textTransform: "none",
      },
      "& .MuiTab-root": {
        color: "black",
        textTransform: "none",
      },
      "& .Mui-selected": {
        color: "black",
        fontWeight: "bold",
      },
      "& .MuiButtonBase-root": {
        maxWidth: "600px",
        display: "inline-flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      "& .MuiTabs-flexContainer": {
        display: "flex",
        justifyContent: "center",
      },
    },
    tab1: {
      "& .MuiTab-wrapper": {
        width: "100%",
        [theme.breakpoints.up(660)]: {
          width: "50%",
          display: "inline-flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-end",
        },
      },
    },
    tab2: {
      "& .MuiTab-wrapper": {
        width: "100%",
        maxWidth: "600px",
        [theme.breakpoints.up(660)]: {
          width: "50%",
          display: "inline-flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-start",
        },
      },
    },
  }));

  useEffect(() => {
    if (recentOrders !== "idle" && recentOrders != null && !recentOrders) {
      orderHistoryOffsetRef.current = 0;
    }
  }, [recentOrders]);

  const classes = useStyles();

  const datePickerRef = useRef(null);

  const noPastOrders = () => {
    if (recentOrders === "idle" || recentOrders === null || recentOrders.length === 0) {
      return true;
    } else if (
      recentOrders !== "idle" &&
      recentOrders !== null &&
      recentOrders.length > 0 &&
      !isOrderStatusEnabled
    ) {
      return false;
    } else {
      let atLeastOnePastOrder = false;
      recentOrders.forEach((order) => {
        if (order.orderStatus && order.orderStatus.isFinished === true) {
          atLeastOnePastOrder = true;
        }
      });
      return atLeastOnePastOrder ? false : true;
    }
  };

  return (
    <div className={classes.root}>
      {isOrderStatusEnabled && recentOrders !== "idle" && (
        <>
          <Route
            path="/online-ordering/recent-orders"
            render={(history) => (
              <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Tabs
                  value={history.location.pathname}
                  variant="fullWidth"
                  className={classes.tabs}
                >
                  <Tab
                    label={appLabels["order"]["active-orders"]}
                    value="/online-ordering/recent-orders/active-orders"
                    component={Link}
                    to="/online-ordering/recent-orders/active-orders"
                    className={classes.tab1}
                  />
                  <Tab
                    label={appLabels["order"]["past-orders"]}
                    value="/online-ordering/recent-orders/past-orders"
                    component={Link}
                    to="/online-ordering/recent-orders/past-orders"
                    className={classes.tab2}
                  />
                </Tabs>
              </AppBar>
            )}
          />

          <PastOrdersCalaendar
            setOrderHistoryDates={setOrderHistoryDates}
            orderHistoryDates={orderHistoryDates}
            datePickerRef={datePickerRef}
            hideCalendar={
              !(
                recentOrders !== "idle" &&
                recentOrders !== null &&
                window.location.href.includes("past-orders")
              )
            }
          />

          <Switch>
            <Route
              path="/online-ordering/recent-orders/active-orders"
              render={() => (
                <Box p={2}>
                  <Typography component={"section"}>
                    <ActiveOrders
                      recentOrders={recentOrders}
                      activeOrdersLength={activeOrdersLength}
                      formattedLastUpdateTimeStamp={formattedLastUpdateTimeStamp}
                      lastUpdateTimeStamp={lastUpdateTimeStamp}
                      appLabels={appLabels}
                      appLanguage={appLanguage}
                      formatOrderDateAndTime={formatOrderDateAndTime}
                      isOrderStatusEnabled={isOrderStatusEnabled}
                    />
                  </Typography>
                </Box>
              )}
            />
            <Route
              path="/online-ordering/recent-orders/past-orders"
              render={() => (
                <Box p={2}>
                  <Typography component={"section"}>
                    <PastOrders
                      recentOrders={recentOrders}
                      appLabels={appLabels}
                      formatOrderDateAndTime={formatOrderDateAndTime}
                      foodMenu={foodMenu}
                      cart={cart}
                      updateIsNotification={updateIsNotification}
                      setIsAlertNotification={setIsAlertNotification}
                      menuLevel={menuLevel}
                    />
                  </Typography>
                </Box>
              )}
            />
          </Switch>
        </>
      )}
      {!isOrderStatusEnabled && (
        <>
          <PastOrdersCalaendar
            setOrderHistoryDates={setOrderHistoryDates}
            orderHistoryDates={orderHistoryDates}
            datePickerRef={datePickerRef}
            hideCalendar={
              !(
                recentOrders !== "idle" &&
                recentOrders !== null &&
                window.location.href.includes("past-orders")
              )
            }
          />

          {recentOrders !== "idle" && recentOrders != null && recentOrders.length < 1 && (
            <NoRecentOrders />
          )}

          {recentOrders !== "idle" && recentOrders != null && recentOrders.length > 0 && (
            <div className="past-orders-container__no-active-orders">
              <p className="recent-order__section-header">
                {appLabels["account"]["recent-orders-disclaimer"]}
              </p>

              {recentOrders !== "idle" &&
                recentOrders &&
                recentOrders.map((order) => (
                  <React.Fragment key={order.id}>
                    <PastOrdersCard
                      order={order}
                      appLabels={appLabels}
                      formatOrderDateAndTime={formatOrderDateAndTime}
                      foodMenu={foodMenu}
                      cart={cart}
                      updateIsNotification={updateIsNotification}
                      setIsAlertNotification={setIsAlertNotification}
                      menuLevel={menuLevel}
                    />
                  </React.Fragment>
                ))}
            </div>
          )}
        </>
      )}

      {recentOrders !== "idle" &&
        recentOrders &&
        recentOrders.length > 0 &&
        !noPastOrders() &&
        !hideLoadMore &&
        window.location.href.includes("past-orders") && (
          <div className="load-more-button__container">
            <button
              type="button"
              aria-label={appLabels["order"]["load-more"]}
              className="button recent-order__reorder-button button--pill-shaped recent-order__reorder-button--filled recert-order__load-more-button"
              onClick={() => {
                orderHistoryOffsetRef.current = recentOrders.length;
                setOrderHistoryOffset(orderHistoryOffsetRef.current);
                setIsLoading(true);
              }}
              disabled={isLoading}
            >
              {appLabels["order"]["load-more"]}
            </button>
          </div>
        )}

      {isNotification && isAlertNotification && (
        <DialogModal
          message={appLabels["account"]["recent-orders-item-doesnt-exist-message"]}
          resetRemoveDialog={updateIsNotification}
        />
      )}

      {isLoading && <LoadingSpinner customClass={"recent-orders__loader"} />}
    </div>
  );
};

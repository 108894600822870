import { objectToArray } from "../../../_common/helpers";
import { checkRequiredSelection } from "../../../_common/MenuHelpers";

export const getActiveItemDetails = (cat, subCat, itemId, orderMenu) => {
  const menuItems = orderMenu.apiData;
  const menuLevel = orderMenu.menuLevel;

  if (menuLevel === "threeLevels" && cat) {
    return menuItems["category-" + cat].subcategories["subcategory-" + subCat].items[
      "item-" + itemId
    ];
  } else if (menuLevel === "twoLevels" && subCat) {
    return menuItems["subcategory-" + subCat].items["item-" + itemId];
  } else {
    return null;
  }
};

export const determineOrderingStateActiveButton = async (
  menuItem,
  currentActiveOptionGroupIndex,
  formattedOptionGroups,
  isStepByStepOrderingEnabled,
  numberOfOptionGroups,
  isDealingWithRegularCombo = false,
  skin
) => {
  let activeButton = "";
  if (
    isDealingWithRegularCombo &&
    currentActiveOptionGroupIndex === Object.keys(formattedOptionGroups).length - 1
  ) {
    activeButton = "add";
    return activeButton;
  } else if (currentActiveOptionGroupIndex >= Object.keys(formattedOptionGroups).length) {
    activeButton = "add";
    return activeButton;
  } else {
    for (const [index, key] of Object.keys(formattedOptionGroups).entries()) {
      if (index === currentActiveOptionGroupIndex) {
        const activeGroup = formattedOptionGroups[key];
        const minQuantity = parseInt(activeGroup.minQuantity) || 0;

        if (minQuantity === 0) {
          activeButton = "next";
        } else {
          let numberOfRequiredChoices = await checkRequiredSelection(
            menuItem,
            isStepByStepOrderingEnabled,
            currentActiveOptionGroupIndex,
            numberOfOptionGroups,
            skin
          );

          if (numberOfRequiredChoices === 0) {
            activeButton = "next";
          } else {
            activeButton = "add";
          }
        }
      }
    }
    return activeButton;
  }
};

export const determineNextOptionGroupButtonText = (
  formattedOptionGroups,
  activeGroupIndex,
  labels
) => {
  let buttonText = labels["login-register"]["next"];
  const activeGroup = objectToArray(formattedOptionGroups).find(
    (group) => group.positioningTemplateIndex === activeGroupIndex
  );

  if (activeGroup) {
    const activeGroupItems = objectToArray(activeGroup.items);

    if (
      Number(activeGroup.minQuantity) === 0 &&
      activeGroupItems.filter((item) => item.isSelected).length === 0
    ) {
      buttonText = labels["general"]["skip"];
    }
  }

  return buttonText;
};

export const hideCartIcon = () => {
  for (let i = 0; i < document.getElementsByClassName("order-settings__cart-button").length; i++) {
    document.getElementsByClassName("order-settings__cart-button")[i].style.display = "none";
  }
};

export const showCartIcon = () => {
  for (let i = 0; i < document.getElementsByClassName("order-settings__cart-button").length; i++) {
    document.getElementsByClassName("order-settings__cart-button")[i].style.display = "";
  }
};

export const reachedMaxNumberOfSelection = (options, maxQuantity, optionGroupId) => {
  let optionsArray = objectToArray(options);

  /** special case for standalone addons */
  if (optionsArray.length === 1 && optionsArray[0].isStandalone && optionsArray[0].isSelected) {
    return true;
  }

  let allSelectedOptions = optionsArray.filter(
    (option) => option.isSelected && option.parentId === optionGroupId
  );

  if (!!allSelectedOptions.length) {
    let totalQuantity = 0;
    allSelectedOptions.forEach((option) => {
      totalQuantity += option.quantity || 1;
    });

    return totalQuantity >= maxQuantity;
  } else {
    return false;
  }
};

export const reachedMinRequirements = (optionGroup) => {
  let items = optionGroup.items;
  let minQuantity = Number(optionGroup.minQuantity);

  let numberOfSelectedItems = 0;
  Object.keys(items).forEach((itemKey) => {
    if (items[itemKey].isSelected) {
      if (items[itemKey].quantity) {
        numberOfSelectedItems = numberOfSelectedItems + items[itemKey].quantity;
      } else {
        numberOfSelectedItems++;
      }
    }
  });

  return numberOfSelectedItems >= minQuantity;
};

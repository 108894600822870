import React, { useContext } from "react";
/** Contexts */
import AppLabelsContext from "../../App/AppLabelsContext";

/** Custom Hooks */
import { useHideOrderModule } from "../../App/useHideOrderModule";

/** CSS */
import "./RedeemRewards.css";

export const RedeemRewards = () => {
  const appLabels = useContext(AppLabelsContext);

  const { hideOrderModule } = useHideOrderModule();

  return (
    <section className="my-rewards__section my-rewards__redeem-rewards-section">
      <div className="redeem-rewards-section__text-container desktop-container">
        <div className="my-rewards-section__header">
          <h2 className="my-rewards-section__heading">{appLabels["loyalty"]["redeem-rewards"]}</h2>
        </div>
        {!hideOrderModule && (
          <>
            <h2 className="my-rewards-section__subheading">
              {appLabels["loyalty"]["online-order"]}
            </h2>
            <p className="my-rewards-section__text">
              {appLabels["loyalty"]["when-ordering-online"]}
            </p>
          </>
        )}
        <h2 className="my-rewards-section__subheading">{appLabels["loyalty"]["in-store"]}</h2>
        <p className="my-rewards-section__text">
          {appLabels["loyalty"]["click-on-any-available-reward"]}
        </p>
      </div>
    </section>
  );
};

import React, { useState, useRef, useEffect } from "react";

import { ReactComponent as IconArrowLeft } from "../../../_common/icons/IconArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../../../_common/icons/IconArrowRight.svg";

import { onCheckout } from "../../../_common/helpers/dataLayerHelpers";
import { onCheckoutXtreme } from "../../../_common/helpers/xtremePushHelper";

import { PaymentMethodCreditCard } from "./PaymentMethodCreditCard/PaymentMethodCreditCard";
import { PaymentMethodGiftCard } from "./PaymentMethodGiftCard/PaymentMethodGiftCard";
import { PayLater } from "./PayLater";
import { PaymentMethodPaypal } from "./PaymentMethodPaypal/PaymentMethodPaypal";

import "./PaymentMethod.css";
import { PaymentMethodGooglePay } from "./PaymentMethodGooglePay/PaymentMethodGooglePay";
import PaymentMethodApplePay from "./PaymentMethodApplePay/PaymentMethodApplePay";
//import { findPos } from "../../../_common/helpers";
import { GCPaymentMethodPaypal } from '../../../GiftCard/GiftCardPayment/GCPaymentMethodPaypal';
import { GCPaymentMethodApplePay } from "../../../GiftCard/GiftCardPayment/GCPaymentMethodApplePay";
import { GCPaymentMethodGooglePay } from "../../../GiftCard/GiftCardPayment/GCPaymentMethodGooglePay";
// import { GCPaymentMethodGooglePay } from '../../../GiftCard/GiftCardPayment/GCPaymentMethodGooglePay';
export const PaymentMethod = (props) => {
  const {
    toggleCollapse,
    method,
    creditCardTypes,
    mealVoucherCardTypes,
    debitCardTypes,
    orderTotal,
    rewards,
    promoCode,
    coupon,
    appLabels,
    cartData,
    skin,
    isGiftCardCheckout = false,
  } = props;

  const [isPaymentMethodExpanded, setIsPaymentMethodExpanded] = useState(false);
  const [activeClass, setActiveClass] = useState("");

  const toggleDetails = () => {
    if (!isPaymentMethodExpanded) {
      setActiveClass(" payment-method--active");
      setIsPaymentMethodExpanded(true);
      onCheckout(cartData.value, 3, method.displayName);
      onCheckoutXtreme(cartData.value, 5, method.displayName, skin);
    } else {
      setIsPaymentMethodExpanded(false);
      setActiveClass("");
    }
  };

  const expandButtonRef = useRef(null);

  const paymentMethodExpanded = () => {
    expandButtonRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  /*useEffect(() => {
    if (method.type === "pay-later" && expandButtonRef.current) {
      expandButtonRef.current.click();
      expandButtonRef.current.disabled = true;
    }
  }, [expandButtonRef]);*/

  useEffect(() => {
    if (method.type === "credit-card") {
      if (
        sessionStorage.getItem(skin + "__autoOpenCCForm") &&
        sessionStorage.getItem(skin + "__autoOpenCCForm") === "true"
      ) {
        sessionStorage.removeItem(skin + "__autoOpenCCForm");
        toggleCollapse();
        toggleDetails();
      }
    }

  }, []);

  // if it is a gift card checkout then gift card, meal voucher, pay on delivery & pay upon pickup are omitted 
  return (
    <li className={`payment-method${activeClass}`}>
      <button
        onClick={() => {
          toggleCollapse();
          toggleDetails();
        }}
        ref={expandButtonRef}
        className={`payment-method__button${
          method.type === "pay-later" ? " payment-method__only-button" : ""
        }
        ${method.type === "google-pay" ? " payment-method__gpay-button" : ""}
        ${method.type === "apple-pay" ? " payment-method__applepay-button" : ""}`}
        aria-label={!isPaymentMethodExpanded ? appLabels["order"]["select-payment-type"].replace(
          "[payment-type]",
          method.displayName
        ) : appLabels["order"]["back-to-payment-method-select"]}
        type="button"
      >
        {isPaymentMethodExpanded && method.type !== "pay-later" && <IconArrowLeft />}
        {method.type !== "pay-later" && method.icon}
        {method.displayName}
        {!isPaymentMethodExpanded && method.type !== "pay-later" && <IconArrowRight />}
      </button>
      {isPaymentMethodExpanded && (
        <div className="payment-method__details">
          {method.type === "credit-card" && (
            <PaymentMethodCreditCard
              creditCardTypes={creditCardTypes}
              mealVoucherCardTypes={mealVoucherCardTypes}
              paymentMethodExpanded={paymentMethodExpanded}
              expandButtonRef={expandButtonRef.current}
              orderTotal={orderTotal}
              rewards={rewards}
              promoCode={promoCode}
              coupon={coupon}
              isGCCheckout={isGiftCardCheckout}
            />
          )}

          {method.type === "meal-voucher" && !isGiftCardCheckout && (
            <PaymentMethodCreditCard
              creditCardTypes={mealVoucherCardTypes}
              paymentMethodExpanded={paymentMethodExpanded}
              expandButtonRef={expandButtonRef.current}
              orderTotal={orderTotal}
              rewards={rewards}
              promoCode={promoCode}
              coupon={coupon}
              isDebitForm={true}
            />
          )}

          {method.type === "debit" && (
            <PaymentMethodCreditCard
              creditCardTypes={debitCardTypes}
              paymentMethodExpanded={paymentMethodExpanded}
              expandButtonRef={expandButtonRef.current}
              orderTotal={orderTotal}
              rewards={rewards}
              promoCode={promoCode}
              coupon={coupon}
              isDebitForm={true}
            />
          )}

          {method.type === "gift-card" && !isGiftCardCheckout && (
            <PaymentMethodGiftCard
              creditCardTypes={creditCardTypes}
              paymentMethodExpanded={paymentMethodExpanded}
              orderTotal={parseFloat(orderTotal)}
              rewards={rewards}
              promoCode={promoCode}
              coupon={coupon}
              isGCCheckout={isGiftCardCheckout}
            />
          )}
          {(method.type === "pay-on-pickup" ||
            method.type === "pay-on-delivery" ||
            method.type === "pay-later") && !isGiftCardCheckout && (
            <PayLater
              paymentMethodExpanded={paymentMethodExpanded}
              expandButtonRef={expandButtonRef.current}
              rewards={rewards}
              promoCode={promoCode}
              coupon={coupon}
            />
          )}
          {method.type === "paypal" && (
            isGiftCardCheckout ? <GCPaymentMethodPaypal
              orderTotal={orderTotal}
              formattedCartItems={props.formattedCartItems}
              promoCode={promoCode}
              paymentMethodExpanded={paymentMethodExpanded}
              isTimePickerEnabled={props.isTimePickerEnabled}
            /> :
            <PaymentMethodPaypal
              orderTotal={orderTotal}
              rewards={rewards}
              promoCode={promoCode}
              paymentMethodExpanded={paymentMethodExpanded}
            />
          )}
          {method.type === "google-pay" && (
            isGiftCardCheckout ?
              <GCPaymentMethodGooglePay
                orderTotal={orderTotal}
                rewards={rewards}
                promoCode={promoCode}
                paymentMethodExpanded={paymentMethodExpanded}
                isGCCheckout={isGiftCardCheckout}
                formattedCartItems={props.formattedCartItems}
                isTimePickerEnabled={props.isTimePickerEnabled}
              />
              :
            <PaymentMethodGooglePay
              orderTotal={orderTotal}
              rewards={rewards}
              promoCode={promoCode}
              paymentMethodExpanded={paymentMethodExpanded}
                isGCCheckout={isGiftCardCheckout}
            />
          )}

          {method.type === "apple-pay" && (
            isGiftCardCheckout ?
              <GCPaymentMethodApplePay
                orderTotal={orderTotal}
                rewards={rewards}
                promoCode={promoCode}
                paymentMethodExpanded={paymentMethodExpanded}
                isGCCheckout={isGiftCardCheckout}
                formattedCartItems={props.formattedCartItems}
                isTimePickerEnabled={props.isTimePickerEnabled}
              />
              :
            <PaymentMethodApplePay
              orderTotal={orderTotal}
              rewards={rewards}
              promoCode={promoCode}
              paymentMethodExpanded={paymentMethodExpanded}
                isGCCheckout={isGiftCardCheckout}
            />
          )}
        </div>
      )}
    </li>
  );
};

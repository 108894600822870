import { callAPI } from "../_common/Api";

export const removeAPIAutoReload = async (skin, loginToken, rewardsCard, language) => {
  const api1022Data = await callAPI(skin, "dc_1022", {
    params: [
      language,
      "1022",
      "mqid",
      "mqpass",
      loginToken,
      "",
      rewardsCard.iso,
      rewardsCard.serial,
    ],
    id: "1022",
  });

  if (api1022Data) {
    if (api1022Data.status === 249 || api1022Data.status === 150) {
      return { status: "expiredLoginToken" };
    } else if (api1022Data.result.I1 === "0") {
      //successfully removed auto reload
      return { isRemoved: true };
    } else {
      console.log(api1022Data.error);
    }
  }
};

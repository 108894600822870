import React, { useContext } from "react";

import AppLabelsContext from "../../App/AppLabelsContext";
import OrderContext from "../../App/OrderContext";
import StoreContext from "../StoreContext";
import OrderTypeContext from "../OrderTypeContext";
import OrderTimeContext from "../OrderTimeContext";

export const ViewMenuButton = () => {
  const appLabels = useContext(AppLabelsContext);
  const orderContext = useContext(OrderContext);

  const orderTimeContext = useContext(OrderTimeContext);
  const orderType = useContext(OrderTypeContext).value;
  const storeContext = useContext(StoreContext);

  return (
    <button
      type="button"
      aria-label={appLabels["order"]["view-menu"]}
      className="button recent-order__reorder-button button--pill-shaped recent-order__view-menu"
      onClick={() => {
        orderContext.checkOrderSettings(orderType, storeContext, orderTimeContext);
      }}
    >
      {appLabels["order"]["view-menu"]}
    </button>
  );
};

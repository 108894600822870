// Libraries
import React, { useContext, useEffect, useRef } from "react";
import localforage from "localforage";

// Contexts
import AppLabelsContext from "./AppLabelsContext";
import MerchantConfigContext from "./MerchantConfigContext";

// UI components
import { DialogModal } from "../_common/DialogModal/DialogModal";

export const CookiesBanner = ({ closeBanner, appDefaultLabelsPath }) => {
  const appLabels = useContext(AppLabelsContext);

  const policyLinkRef = useRef();
  useEffect(() => {
    if (policyLinkRef.current) {
      policyLinkRef.current.focus();
    }
  }, [policyLinkRef.current]);

  const cookieMessageSplit = appLabels["general"]["cookie-message"].split("[link]");

  const getPrivacyPolicyUrl = (languagePath) => {
    switch (languagePath) {
      case "fr/":
        return "https://cws.givex.com/cws4.0/global-unix/cookies/privacy-fr.html";
      case "pt/":
        return "https://cws.givex.com/cws4.0/global-unix/cookies/privacy-pt.html";
      case "es/":
        return "https://cws.givex.com/cws4.0/global-unix/cookies/privacy-es.html";
      case "es-mx/":
        return "https://cws.givex.com/cws4.0/global-unix/cookies/privacy-es.html";
      default:
        return "https://cws.givex.com/cws4.0/global-unix/cookies/privacy-en.html";
    }
  };

  const cookieBannerContent = (
    <span id="cookieBannerMessage">
      {cookieMessageSplit[0]}
      <a
        ref={policyLinkRef}
        href={getPrivacyPolicyUrl(appDefaultLabelsPath)}
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontWeight: "600", textDecoration: "underline" }}
      >
        {appLabels["general"]["cookie-message-link-text"]}
      </a>
      {cookieMessageSplit[1]}
    </span>
  );

  const merchantSkinName = useContext(MerchantConfigContext).skin;
  const acceptCookieBanner = () => {
    localforage.setItem(merchantSkinName + "__cookieIsAcceptedTimeStamp", new Date());
    closeBanner();
  };

  return (
    <DialogModal
      description="Cookie banner"
      message={cookieBannerContent}
      resetRemoveDialog={acceptCookieBanner}
      customClass={"cookieBannerMessage"}
    />
  );
};

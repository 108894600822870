// Libraries
import React, { useState, useEffect, useContext, isValidElement } from "react";
import ReactHtmlParser from "react-html-parser";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helper functions
import { getMarquiStepsText } from "./getMarquiStepsText";
import { getMarquiImages } from "./getMarquiImages";

// Contexts
import AppLanguageContext from "../../App/AppLanguageContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import AppSettingsContext from "../../App/AppSettingsContext";

// CSS
import "./HowRewardsWork.css";

const isLocalhost = process.env.NODE_ENV === "development";
const IS_GIVEX_HOST = !isLocalhost;
const PATHNAME = window.location.pathname;
const IS_DEFAULT_LANGUAGE = IS_GIVEX_HOST
  ? PATHNAME.split("/")[3] === ""
  : PATHNAME.split("/")[1] === "";

const isPopulated = (step) => step["image-src"] !== "" && !step["image-src"].includes("_blank.");

export const HowRewardsWork = () => {
  const isLocalhost = process.env.NODE_ENV === "development";

  const [images, setImages] = useState(null);

  const relativePath = useContext(AppSettingsContext)["relative-path"];

  useEffect(() => {
    if (isLocalhost) {
      setImages(require("./fallbackImages").fallbackImages["how-rewards-work-steps"]);
    } else {
      getMarquiImages(relativePath).then((marquiImages) => {
        if (marquiImages && marquiImages["how-rewards-work-steps"]) {
          const marquiImagesObject = marquiImages["how-rewards-work-steps"];
          let emptyImages = 0;

          Object.keys(marquiImagesObject).forEach((key) => {
            if (!isPopulated(marquiImagesObject[key])) emptyImages++;
          });

          if (emptyImages < Object.keys(marquiImagesObject).length) {
            if (IS_DEFAULT_LANGUAGE) {
              setImages(marquiImages["how-rewards-work-steps"]);
            } else {
              const tempImages = marquiImages["how-rewards-work-steps"];
              Object.keys(tempImages).forEach((imageKey) => {
                tempImages[imageKey]["image-src"] =
                  "../" + marquiImages["how-rewards-work-steps"][imageKey]["image-src"];
              });
              setImages(tempImages);
            }
          } else {
            setImages(require("./fallbackImages").fallbackImages["how-rewards-work-steps"]);
          }
        }
      });
    }
  }, []);

  const appLanguage = useContext(AppLanguageContext);

  const [stepsContent, setStepsContent] = useState({
    "step-1": "",
    "step-2": "",
    "step-3": "",
    "step-4": "",
    "step-5": "",
    "step-6": "",
  });
  useEffect(() => {
    if (isLocalhost) {
      setStepsContent(
        require(`./paragraphs/${
          IS_DEFAULT_LANGUAGE ? "" : appLanguage + "/"
        }how-rewards-work-paragraphs.js`).paragraphs
      );
    } else {
      getMarquiStepsText().then((marquiSteps) => {
        if (marquiSteps) setStepsContent(marquiSteps);
      });
    }
  }, []);

  const deviceWidth = useWindowSize().width;

  const appLabels = useContext(AppLabelsContext);

  return (
    <>
      {deviceWidth >= 660 && (
        <section className="desktop-page-header-section">
          <header className="desktop-page-header-container">
            <h2 className="desktop-page-header">{appLabels["loyalty"]["how-rewards-work"]}</h2>
          </header>
        </section>
      )}
      {images && (
        <>
          {((stepsContent["step-1"] &&
            !!ReactHtmlParser(stepsContent["step-1"]).length &&
            !isLocalhost) ||
            (isLocalhost && isValidElement(stepsContent["step-1"]))) && (
            <section className="how-rewards-work__section">
              {isPopulated(images["step-1"]) && (
                <div className="how-rewards-work-section__icon-wrapper">
                  <img src={images["step-1"]["image-src"]} alt={images["step-1"]["image-alt"]} />
                </div>
              )}
              <div className="how-rewards-work-section__text-wrapper">
                {ReactHtmlParser(stepsContent["step-1"]) && !isLocalhost
                  ? ReactHtmlParser(stepsContent["step-1"])
                  : stepsContent["step-1"]}
              </div>
            </section>
          )}
          {((stepsContent["step-2"] &&
            !!ReactHtmlParser(stepsContent["step-2"]).length &&
            !isLocalhost) ||
            (isLocalhost && isValidElement(stepsContent["step-2"]))) && (
            <section className="how-rewards-work__section">
              {deviceWidth >= 660 ? (
                <>
                  <div className="how-rewards-work-section__text-wrapper">
                    {ReactHtmlParser(stepsContent["step-2"]) && !isLocalhost
                      ? ReactHtmlParser(stepsContent["step-2"])
                      : stepsContent["step-2"]}
                  </div>
                  {isPopulated(images["step-2"]) && (
                    <div className="how-rewards-work-section__icon-wrapper">
                      <img
                        src={images["step-2"]["image-src"]}
                        alt={images["step-2"]["image-alt"]}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isPopulated(images["step-2"]) && (
                    <div className="how-rewards-work-section__icon-wrapper">
                      <img
                        src={images["step-2"]["image-src"]}
                        alt={images["step-2"]["image-alt"]}
                      />
                    </div>
                  )}
                  <div className="how-rewards-work-section__text-wrapper">
                    {ReactHtmlParser(stepsContent["step-2"]) && !isLocalhost
                      ? ReactHtmlParser(stepsContent["step-2"])
                      : stepsContent["step-2"]}
                  </div>
                </>
              )}
            </section>
          )}
          {((stepsContent["step-3"] &&
            !!ReactHtmlParser(stepsContent["step-3"]).length &&
            !isLocalhost) ||
            (isLocalhost && isValidElement(stepsContent["step-3"]))) && (
            <section className="how-rewards-work__section">
              {isPopulated(images["step-3"]) && (
                <div className="how-rewards-work-section__icon-wrapper">
                  <img src={images["step-3"]["image-src"]} alt={images["step-3"]["image-alt"]} />
                </div>
              )}
              <div className="how-rewards-work-section__text-wrapper">
                {ReactHtmlParser(stepsContent["step-3"]) && !isLocalhost
                  ? ReactHtmlParser(stepsContent["step-3"])
                  : stepsContent["step-3"]}
              </div>
            </section>
          )}
          {((stepsContent["step-4"] &&
            !!ReactHtmlParser(stepsContent["step-4"]).length &&
            !isLocalhost) ||
            (isLocalhost && isValidElement(stepsContent["step-4"]))) && (
            <section className="how-rewards-work__section">
              {deviceWidth >= 660 ? (
                <>
                  <div className="how-rewards-work-section__text-wrapper">
                    {ReactHtmlParser(stepsContent["step-4"]) && !isLocalhost
                      ? ReactHtmlParser(stepsContent["step-4"])
                      : stepsContent["step-4"]}
                  </div>
                  {isPopulated(images["step-4"]) && (
                    <div className="how-rewards-work-section__icon-wrapper">
                      <img
                        src={images["step-4"]["image-src"]}
                        alt={images["step-4"]["image-alt"]}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isPopulated(images["step-4"]) && (
                    <div className="how-rewards-work-section__icon-wrapper">
                      <img
                        src={images["step-4"]["image-src"]}
                        alt={images["step-4"]["image-alt"]}
                      />
                    </div>
                  )}
                  <div className="how-rewards-work-section__text-wrapper">
                    {ReactHtmlParser(stepsContent["step-4"]) && !isLocalhost
                      ? ReactHtmlParser(stepsContent["step-4"])
                      : stepsContent["step-4"]}
                  </div>
                </>
              )}
            </section>
          )}
          {((stepsContent["step-5"] &&
            !!ReactHtmlParser(stepsContent["step-5"]).length &&
            !isLocalhost) ||
            (isLocalhost && isValidElement(stepsContent["step-5"]))) && (
            <section className="how-rewards-work__section">
              {isPopulated(images["step-5"]) && (
                <div className="how-rewards-work-section__icon-wrapper">
                  <img src={images["step-5"]["image-src"]} alt={images["step-5"]["image-alt"]} />
                </div>
              )}
              <div className="how-rewards-work-section__text-wrapper">
                {ReactHtmlParser(stepsContent["step-5"]) && !isLocalhost
                  ? ReactHtmlParser(stepsContent["step-5"])
                  : stepsContent["step-5"]}
              </div>
            </section>
          )}
          {((stepsContent["step-6"] &&
            !!ReactHtmlParser(stepsContent["step-6"]).length &&
            !isLocalhost) ||
            (isLocalhost && isValidElement(stepsContent["step-6"]))) && (
            <section className="how-rewards-work__section">
              <div className="how-rewards-work-section__text-wrapper">
                {ReactHtmlParser(stepsContent["step-6"]) && !isLocalhost
                  ? ReactHtmlParser(stepsContent["step-6"])
                  : stepsContent["step-6"]}
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

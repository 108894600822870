import { callAPI } from "../../../_common/Api";

export const getAPIFullCardNumber = async (skin, loginToken, isoSerial, language) => {
  const api959Data = await callAPI(skin, "dc_959", {
    params: [language, "959", "mqid", "mqpass", loginToken, "", isoSerial],
    id: "959",
  });

  if (api959Data.result.I2) {
    return api959Data.result.I2;
  } else if (api959Data.status === 249 || api959Data.status === 150) {
    return { status: "expiredLoginToken" };
  } else {
    // Failed to retrieve full card number (givex number)
    return api959Data.result;
  }
};

/*global google*/
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import localforage from "localforage";

// Hooks
import useWindowSize from "../_common/hooks/useWindowSize";

//Contexts
import AppLabelsContext from "../App/AppLabelsContext";
import UserRoleContext from "../App/UserRoleContext";
import AppLanguageContext from "../App/AppLanguageContext";
import MerchantConfigContext from "../App/MerchantConfigContext";

//UI Components
import { Form } from "../_common/Form/Form";
import { FormInput } from "../_common/Form/FormInput";
import { FormSelect } from "../_common/Form/FormSelect";
import { LoadingSpinner } from "../_common/LoadingSpinner";
import { MAP_KEY } from "../App/AppSetup";
import { GoogleMaps } from "../OnlineOrdering/GoogleMaps/GoogleMaps";
import { AddressValidationDialogModal } from "../OrderSettings/AddressValidationDialogModal";
import { FormCheckbox } from "../_common/Form/FormCheckbox";

//helper functions
import { callGoogleAddressValidationAPI } from "../_common/Api";
import {
  getAddressAlreadyExistsForDialogModal,
  getRecommendedUserAddressForDialogModal,
  getUnverifiedAddressForDialogModal,
  getValidatedUserAddressForDialogModal,
} from "../OrderSettings/helpers/orderSettingsHelpers";
import {
  addressAlreadyExist,
  apiAddressAndEnteredAddressAreSame,
  getAddressIndex,
  getAllStoredAddresses,
  getProvinceDisplayNameFromValue,
  handleNewAddressAddition,
  handleSetPrimaryAddress,
  updateAddress,
  getProvinceValueFromDisplayName,
  addressAlreadyExistWithoutPrimaryFlag,
} from "../Account/apiHelpers/userAddressHelpers";
import { jsonCopy } from "../_common/helpers";

const ConfirmDeliveryAddressAccount = (props) => {
  const appLabels = useContext(AppLabelsContext);
  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const appLanguage = useContext(AppLanguageContext);
  const isLoggedIn = userRoleContext.status === "logged-in";

  const [userAddress, setUserAddress] = useState(null);
  const history = useHistory();
  const deviceWidth = useWindowSize().width;

  const { state } = useLocation();

  const [showSetPrimaryDeliveryAddressModal, setShowSetPrimaryDeliveryAddressModal] =
    useState(false);
  const [showRecommendedAddressModal, setShowRecommendedAddressModal] = useState(false);
  const [showTryDifferentAddressModal, setShowTryDifferentAddressModal] = useState(false);
  const [showVerificationIssueModal, setShowVerificationIssueModal] = useState(false);
  const [showAddressMissingComponentModal, setShowAddressMissingComponentModal] = useState(false);
  const [missingAddressComponents, setMissingAddressComponents] = useState(null);
  const [apiError, setAPIerror] = useState(null);
  const [isAPIsubmitting, setIsAPIsubmitting] = useState(null);
  const [apiAddress, setApiAddress] = useState({});
  const [userCoordinates, setUserCoordinates] = useState({});
  const [userLocation, setUserLocation] = useState(null);
  const [recommendedAddress, setRecommendedAddress] = useState({});
  const [isGeolocatedAddress, setIsGeolocatedAddress] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [isAddressAlreadyExist, setIsAddressAlreadyExist] = useState(false);

  useEffect(() => {
    if (state) {
      if (state && state.from === "delivery-addresses-geocode") {
        setUserLocation(state.userCoordinates);
        setIsGeolocatedAddress(true);
      } else {
        setUserLocation(state.userCoordinates);
      }
      setUserAddress(state.userAddress);
    } else {
      history.push("/account/delivery-address");
    }
  }, []);

  useEffect(() => {
    if (userAddress && userAddress.addressId) {
      setAddressId(userAddress.addressId);
    }
  }, [userAddress]);

  const getAddressValidationObject = (formData) => {
    let tempFormData = Object.values(formData);

    let address;
    let city;
    let apt;
    let province;
    let country;
    let postal;

    tempFormData.forEach((field) => {
      if (field.name === "first-address") address = field.value;
      else if (field.name === "second-address") apt = field.value;
      else if (field.name === "city") city = field.value;
      else if (field.name === "province") province = field.value;
      else if (field.name === "country") country = field.value;
      else if (field.name === "postal") postal = field.value;
    });

    let userEnteredAddress = {
      "first-address": address,
      "second-address": apt,
      city: city,
      province: province,
      country: country,
      postal: postal,
      addressId: addressId,
    };

    if (state && state.from === "modify-delivery-address") {
      setUserAddress(userEnteredAddress);
    } else {
      let updateAddressObject = {
        addressId: state.userAddress.addressId,
        "first-address": state.userAddress["first-address"],
        "second-address": state.userAddress["second-address"]
          ? state.userAddress["second-address"]
          : apt,
        city: state.userAddress.city,
        province: state.userAddress.province,
        country: state.userAddress.country,
        postal: state.userAddress.postal,
        isDefaultAddress: state.userAddress.isDefaultAddress,
      };

      setUserAddress(updateAddressObject);
    }

    let payload = {
      address: {
        addressLines: [address],
        administrativeArea:
          country === "BR" ? getProvinceDisplayNameFromValue(country, province) : province,
        locality: city,
        regionCode: country,
        postalCode: postal,
      },
    };

    return payload;
  };

  const IsRequestObjectSameAsResponse = (requestObject, responseObject) => {
    let requestObjectCopy = jsonCopy(requestObject);
    let responseObjectCopy = jsonCopy(responseObject);

    Object.keys(requestObjectCopy).forEach((key) => {
      if (!requestObjectCopy[key]) {
        delete requestObjectCopy[key];
      }
    });

    Object.keys(responseObjectCopy).forEach((key) => {
      if (!responseObjectCopy[key]) {
        delete responseObjectCopy[key];
      }
    });

    const objKeys1 = Object.keys(requestObjectCopy);
    const objKeys2 = Object.keys(responseObjectCopy);

    if (objKeys1.length !== objKeys2.length) return false;

    for (var key of objKeys1) {
      const value1 = requestObjectCopy[key];
      const value2 = responseObjectCopy[key];

      const isObjects = isObject(value1) && isObject(value2);

      if (
        (isObjects && !IsRequestObjectSameAsResponse(value1, value2)) ||
        (!isObjects && value1 !== value2)
      ) {
        return false;
      }
    }
    return true;
  };

  const isObject = (object) => {
    return object != null && typeof object === "object";
  };

  const geocodePlaceId = async (placeId) => {
    const geocoder = new google.maps.Geocoder();
    const geoCodeInfo = await geocoder.geocode({ placeId: placeId });
    if (geoCodeInfo) {
      if (geoCodeInfo.results[0]) {
        return geoCodeInfo.results[0];
      } else {
        window.alert("No results found");
      }
    }
  };

  const reverseGeoCode = async (coordinates) => {
    //Reverse geocode
    const geocoder = new google.maps.Geocoder();
    const geoCodeInfo = await geocoder.geocode({ location: coordinates });
    if (geoCodeInfo) {
      if (geoCodeInfo.results[0]) {
        return geoCodeInfo.results[0];
      } else {
        window.alert("No results found");
      }
    }
  };

  const onConfirmAddress = async (formData) => {
    setIsAPIsubmitting(true);

    const addressValidationRequestObject = getAddressValidationObject(formData);

    let tempFormData = Object.values(formData);

    let address;
    let city;
    let apt;
    let province;
    let country;
    let postal;
    let setAsPrimary =
      !!document.getElementById("checkbox-set-as-primary") &&
      document.getElementById("checkbox-set-as-primary").checked;

    tempFormData.forEach((field) => {
      if (field.name === "first-address") address = field.value;
      else if (field.name === "second-address") apt = field.value;
      else if (field.name === "city") city = field.value;
      else if (field.name === "province") province = field.value;
      else if (field.name === "country") country = field.value;
      else if (field.name === "postal") postal = field.value;
    });

    let userEnteredAddress = {
      "first-address": address,
      "second-address": apt,
      city: city,
      province: province,
      country: country,
      postal: postal,
      isDefaultChecked: setAsPrimary ? "t" : "f",
      isDefaultAddress: setAsPrimary ? "t" : "f",
    };
    setUserAddress(userEnteredAddress);

    if (country === "HK") {
      const addressExists = await addressAlreadyExist(userEnteredAddress, skin);
      if (!addressExists) {
        saveDeliveryAddress(setAsPrimary, userEnteredAddress);
      } else {
        const addressList = await localforage.getItem(skin + "__userStoredAddresses");
        const parsedAddressList = JSON.parse(addressList);
        const addressIndex = getAddressIndex(userEnteredAddress, parsedAddressList);
        const tempId = parsedAddressList[addressIndex][0];
        const addressIsSetToPrimary = parsedAddressList[addressIndex][2] === "t";
        if ((addressIsSetToPrimary && setAsPrimary) || (!addressIsSetToPrimary && !setAsPrimary)) {
          setDeliveryAddressWithoutSaving();
        } else {
          const data = await handleSetPrimaryAddress(
            JSON.parse(addressList),
            { ...userEnteredAddress, addressId: tempId },
            skin,
            appLanguage,
            loginToken
          );
          if (data) {
            setDeliveryAddressWithoutSaving();
          }
        }
      }
    } else {
      callGoogleAddressValidationAPI(addressValidationRequestObject, MAP_KEY)
        .then(async (apiData) => {
          if (apiData.result) {
            setIsAPIsubmitting(false);
            setApiAddress(apiData.result);
            if (apiData.result && apiData.result.verdict) {
              let addressVerdict = apiData.result.verdict;
              let apiAddressComponent = apiData.result.address.postalAddress;
              let apiMissingComponents = apiData.result.address.missingComponentTypes;
              let unconfirmedComponentTypes = apiData.result.address.unconfirmedComponentTypes;

              //The language code in the address returned by API is reserved for future uses and is ignored today.
              //The API returns the address in the appropriate language for where the address is located.
              //Removing langauge code in order to compare the user enterred address and api returned address
              delete apiAddressComponent.languageCode;

              let lng = apiData.result.geocode.location.longitude;
              let lat = apiData.result.geocode.location.latitude;
              let placeId = apiData.result.geocode.placeId;

              let coordinates = { lat: lat, lng: lng, placeId: placeId };
              setUserLocation({
                lat: lat,
                lng: lng,
              });
              await geocodePlaceId(placeId).then(async (places) => {
                if (places && Object.keys(places).length > 0) {
                  const position = places.geometry.location;
                  const lat = position.lat();
                  const lng = position.lng();
                  const storeCoordsObject = new google.maps.LatLng(lat, lng);
                  const reverseGeoCodeResponse = await reverseGeoCode(storeCoordsObject);

                  if (reverseGeoCodeResponse) {
                    const reverseGeoCodeAddressComponents =
                      reverseGeoCodeResponse["address_components"];

                    const postalCodeComponent = reverseGeoCodeAddressComponents.filter(
                      (component) =>
                        component["types"].includes("postal_code") &&
                        !component["types"].includes("postal_code_prefix")
                    );

                    const postalCode =
                      postalCodeComponent.length > 0 ? postalCodeComponent[0]["long_name"] : "";

                    if (apiAddressComponent.postalCode !== postalCode) {
                      apiAddressComponent.postalCode = postalCode;
                    }
                  }
                }
              });

              let hasInvalidComponents =
                addressVerdict.hasUnconfirmedComponents || addressVerdict.hasReplacedComponents;

              let isInputAddressSameAsApiAddress = IsRequestObjectSameAsResponse(
                addressValidationRequestObject.address,
                apiData.result.address.postalAddress
              );

              setRecommendedAddress(apiAddressComponent);

              setUserCoordinates(coordinates);

              if (
                addressVerdict.addressComplete &&
                (hasInvalidComponents || !isInputAddressSameAsApiAddress)
              ) {
                if (apiAddressAndEnteredAddressAreSame(apiAddressComponent, userEnteredAddress)) {
                  /** if the street number could not be verified */
                  if (
                    unconfirmedComponentTypes &&
                    unconfirmedComponentTypes.length === 1 &&
                    unconfirmedComponentTypes.includes("street_number")
                  ) {
                    setShowVerificationIssueModal(true);
                  } else {
                    setShowTryDifferentAddressModal(true);
                  }
                } else {
                  setShowRecommendedAddressModal(true);
                }
              } else if (
                !addressVerdict.addressComplete &&
                apiAddressComponent.regionCode !== "CA" &&
                ((!!apiMissingComponents &&
                  apiMissingComponents.length === 1 &&
                  (apiMissingComponents.includes("subpremise") ||
                    apiMissingComponents.includes("sublocality_level_1"))) ||
                  (!!unconfirmedComponentTypes && unconfirmedComponentTypes.includes("country")))
              ) {
                if (!!unconfirmedComponentTypes) {
                  setShowRecommendedAddressModal(true);
                } else if (!!apiMissingComponents) {
                  const addressExists = await addressAlreadyExist(userEnteredAddress, skin);
                  if (!addressExists) {
                    setShowSetPrimaryDeliveryAddressModal(true);
                  } else {
                    setDeliveryAddressWithoutSaving(userEnteredAddress, {
                      lat: lat,
                      lng: lng,
                    });
                  }
                }
              } else if (
                addressVerdict.addressComplete &&
                !hasInvalidComponents &&
                isInputAddressSameAsApiAddress
              ) {
                const addressExists = await addressAlreadyExist(userEnteredAddress, skin);
                const addressAlreadyExistWithoutFlag = await addressAlreadyExistWithoutPrimaryFlag(
                  userEnteredAddress,
                  skin
                );
                if (!addressExists && !addressAlreadyExistWithoutFlag.matchFound) {
                  saveDeliveryAddress(setAsPrimary, userEnteredAddress);
                } else {
                  const addressList = await localforage.getItem(skin + "__userStoredAddresses");
                  const parsedAddressList = JSON.parse(addressList);
                  const addressIndex = getAddressIndex(userEnteredAddress, parsedAddressList);
                  const tempId = parsedAddressList[addressIndex][0];
                  const addressIsSetToPrimary = parsedAddressList[addressIndex][2] === "t";
                  const defaultFlagChanged = !(
                    (addressIsSetToPrimary && setAsPrimary) ||
                    (!addressIsSetToPrimary && !setAsPrimary)
                  );
                  if (!defaultFlagChanged) {
                    setIsAddressAlreadyExist(true);
                    //setDeliveryAddressWithoutSaving();
                  } else {
                    if (addressAlreadyExistWithoutFlag.matchFound && !defaultFlagChanged) {
                      setIsAddressAlreadyExist(true);
                    } else {
                      const data = await handleSetPrimaryAddress(
                        JSON.parse(addressList),
                        { ...userEnteredAddress, addressId: tempId },
                        skin,
                        appLanguage,
                        loginToken,
                        !defaultFlagChanged
                      );
                      if (data) {
                        setDeliveryAddressWithoutSaving();
                      }
                    }
                  }
                }
              } else if (apiMissingComponents && apiMissingComponents.includes("street_number")) {
                setMissingAddressComponents("street-number");
                setShowAddressMissingComponentModal(true);
              } else {
                setMissingAddressComponents("multiple");
                setShowVerificationIssueModal(true);
              }
            }
          }
        })
        .catch((error) => {
          setIsAPIsubmitting(false);
          setAPIerror(error);
        });
    }
  };

  const setDeliveryAddressWithoutSaving = (userAddressParam = null) => {
    let userDeliveryAddress = {};
    if (userAddressParam) {
      userDeliveryAddress = {
        "first-address": userAddressParam["first-address"]
          ? userAddressParam["first-address"]
          : userAddressParam.addressLines[0],
        "second-address": userAddressParam["second-address"]
          ? userAddressParam["second-address"]
          : "",
        city: userAddressParam.city ? userAddressParam.city : userAddressParam.locality,
        country: userAddressParam.country ? userAddressParam.country : userAddressParam.regionCode,
        postal: userAddressParam.postal ? userAddressParam.postal : userAddressParam.postalCode,
        province: userAddressParam.province
          ? userAddressParam.province
          : userAddressParam.administrativeArea,
      };
    } else {
      userDeliveryAddress = {
        "first-address": userAddress["first-address"]
          ? userAddress["first-address"]
          : userAddress.addressLines[0],
        "second-address": userAddress["second-address"] ? userAddress["second-address"] : "",
        city: userAddress.city ? userAddress.city : userAddress.locality,
        country: userAddress.country ? userAddress.country : userAddress.regionCode,
        postal: userAddress.postal ? userAddress.postal : userAddress.postalCode,
        province: userAddress.province ? userAddress.province : userAddress.administrativeArea,
      };
    }

    history.push({
      pathname: "/account/delivery-address",
      state: {
        from: "confirm-address",
        userAddress: userDeliveryAddress,
        userCoordinates: userCoordinates,
      },
    });
  };

  const saveDeliveryAddress = async (isDefaultChecked, userAddressParam = null) => {
    if (isLoggedIn) {
      let userDeliveryAddress = {};
      if (userAddressParam) {
        userDeliveryAddress = {
          "first-address": userAddressParam["first-address"]
            ? userAddressParam["first-address"]
            : userAddressParam.addressLines[0],
          "second-address": userAddressParam["second-address"]
            ? userAddressParam["second-address"]
            : "",
          city: userAddressParam.city ? userAddressParam.city : userAddressParam.locality,
          country: userAddressParam.country
            ? userAddressParam.country
            : userAddressParam.regionCode,
          postal: userAddressParam.postal ? userAddressParam.postal : userAddressParam.postalCode,
          province: userAddressParam.province
            ? userAddressParam.province
            : userAddressParam.administrativeArea,
          isDefaultChecked: isDefaultChecked,
          addressId: addressId,
        };
      } else {
        userDeliveryAddress = {
          "first-address": userAddress["first-address"]
            ? userAddress["first-address"]
            : userAddress.addressLines[0],
          "second-address": userAddress["second-address"] ? userAddress["second-address"] : "",
          city: userAddress.city ? userAddress.city : userAddress.locality,
          country: userAddress.country ? userAddress.country : userAddress.regionCode,
          postal: userAddress.postal ? userAddress.postal : userAddress.postalCode,
          province: userAddress.province ? userAddress.province : userAddress.administrativeArea,
          isDefaultChecked: isDefaultChecked,
          addressId: addressId,
        };
      }

      const addressAlreadyExistWithoutFlag = await addressAlreadyExistWithoutPrimaryFlag(
        userDeliveryAddress,
        skin
      );

      const addressExist = await addressAlreadyExist(userDeliveryAddress, skin);

      if (state.from === "modify-delivery-address") {
        if (isDefaultChecked) {
          //change the primary address and then update the address info
          const addressList = await localforage.getItem(skin + "__userStoredAddresses");
          const parsedAddressList = JSON.parse(addressList);
          const addressIndex = getAddressIndex(userDeliveryAddress, parsedAddressList);
          const addressIsSetToPrimary =
            addressIndex !== "" ? parsedAddressList[addressIndex][2] === "t" : false;
          const data = await handleSetPrimaryAddress(
            JSON.parse(addressList),
            userDeliveryAddress,
            skin,
            appLanguage,
            loginToken,
            !(
              (addressIsSetToPrimary && isDefaultChecked) ||
              (!addressIsSetToPrimary && !isDefaultChecked)
            )
          );

          if (data) {
            updateAddress(skin, appLanguage, loginToken, userDeliveryAddress).then((data1001) => {
              if (data1001) {
                if (data1001.addressUpdated) {
                  history.push("/account/delivery-address");
                } else if (data1001.status === "expiredLoginToken") {
                  userRoleContext.handleLoginTokenExpiration();
                } else {
                  setShowSetPrimaryDeliveryAddressModal(false);
                  setAPIerror(data1001.error);
                  setShowTryDifferentAddressModal(true);
                }
              }
            });
          }
        } else {
          updateAddress(skin, appLanguage, loginToken, userDeliveryAddress).then((data1001) => {
            if (data1001) {
              if (data1001.addressUpdated) {
                history.push("/account/delivery-address");
              } else if (data1001.status === "expiredLoginToken") {
                userRoleContext.handleLoginTokenExpiration();
              } else {
                setShowSetPrimaryDeliveryAddressModal(false);
                setAPIerror(data1001.error);
                setShowTryDifferentAddressModal(true);
              }
            }
          });
        }
      } else {
        if (addressAlreadyExistWithoutFlag.matchFound) {
          if (addressExist) {
            history.push({
              pathname: "/account/delivery-address",
              state: {
                from: "confirm-address",
                userAddress: userDeliveryAddress,
                userCoordinates: userCoordinates,
              },
            });
          } else {
            updateAddress(skin, appLanguage, loginToken, {
              ...userDeliveryAddress,
              addressId: addressAlreadyExistWithoutFlag.matchedId,
            }).then((data1001) => {
              if (data1001) {
                if (data1001.addressUpdated) {
                  history.push("/account/delivery-address");
                } else if (data1001.status === "expiredLoginToken") {
                  userRoleContext.handleLoginTokenExpiration();
                } else {
                  setShowSetPrimaryDeliveryAddressModal(false);
                  setAPIerror(data1001.error);
                  setShowTryDifferentAddressModal(true);
                }
              }
            });
          }
        } else {
          getAllStoredAddresses(skin, loginToken, appLanguage, "stored_shipping").then(
            async (data1002) => {
              if (data1002) {
                if (data1002.status === "expiredLoginToken") {
                  userRoleContext.handleLoginTokenExpiration();
                } else if (data1002.storedAddresses) {
                  const data = await handleNewAddressAddition(
                    userDeliveryAddress,
                    data1002.storedAddresses,
                    skin,
                    appLanguage,
                    loginToken
                  );
                  if (data) {
                    if (data.addressAdded) {
                      history.push({
                        pathname: "/account/delivery-address",
                        state: {
                          from: "confirm-address",
                          userAddress: userDeliveryAddress,
                          userCoordinates: userCoordinates,
                        },
                      });
                    } else if (data.status === "expiredLoginToken") {
                      userRoleContext.handleLoginTokenExpiration();
                    } else {
                      setShowSetPrimaryDeliveryAddressModal(false);
                      setAPIerror(data.error);
                      setShowTryDifferentAddressModal(true);
                    }
                  }
                }
              }
            }
          );
        }
      }
    } else {
      history.push("/login-register");
    }
  };

  const useRecommendedAddress = async (value) => {
    setShowRecommendedAddressModal(false);
    if (value === "recommended") {
      let addressValidationRequestObject = { address: recommendedAddress };
      addressValidationRequestObject.address.administrativeArea = getProvinceDisplayNameFromValue(
        recommendedAddress.regionCode,
        recommendedAddress.administrativeArea
      );

      callGoogleAddressValidationAPI({ address: recommendedAddress }, MAP_KEY)
        .then(async (apiData) => {
          const hasUnconfirmedComponents = apiData.result.verdict.hasUnconfirmedComponents;
          const unconfirmedComponentTypes = apiData.result.address.unconfirmedComponentTypes;

          if (
            hasUnconfirmedComponents &&
            !unconfirmedComponentTypes.includes("administrative_area_level_2")
          ) {
            /** if the street number could not be verified */
            if (
              unconfirmedComponentTypes &&
              unconfirmedComponentTypes.length === 1 &&
              unconfirmedComponentTypes.includes("street_number")
            ) {
              setShowVerificationIssueModal(true);
            } else if (unconfirmedComponentTypes && unconfirmedComponentTypes.length > 1) {
              setMissingAddressComponents("multiple");
              setShowVerificationIssueModal(true);
            } else if (
              unconfirmedComponentTypes &&
              unconfirmedComponentTypes.length === 1 &&
              unconfirmedComponentTypes.includes("sublocality_level_1")
            ) {
              const userSelectedAddress = {
                ...recommendedAddress,
                "second-address": userAddress["second-address"],
              };
              const formattedSelectedAddress = {
                "first-address": userSelectedAddress.addressLines[0],
                "second-address": userSelectedAddress["second-address"]
                  ? userSelectedAddress["second-address"]
                  : "",
                city: userSelectedAddress.locality,
                country: userSelectedAddress.regionCode,
                postal: userSelectedAddress.postalCode,
                province: userSelectedAddress.administrativeArea,
              };
              const addressExists = await addressAlreadyExist(formattedSelectedAddress, skin);
              if (!addressExists) {
                setUserAddress(userSelectedAddress);
                let setAsPrimary =
                  !!document.getElementById("checkbox-set-as-primary") &&
                  document.getElementById("checkbox-set-as-primary").checked;
                saveDeliveryAddress(setAsPrimary, userSelectedAddress);
              } else {
                setDeliveryAddressWithoutSaving(userSelectedAddress);
              }
            } else if (
              unconfirmedComponentTypes &&
              unconfirmedComponentTypes.length === 1 &&
              unconfirmedComponentTypes.includes("postal_code")
            ) {
              setMissingAddressComponents("multiple");
              setShowVerificationIssueModal(true);
            } else {
              setMissingAddressComponents("multiple");
              setShowVerificationIssueModal(true);
            }
          } else {
            const userSelectedAddress = {
              ...recommendedAddress,
              "second-address": userAddress["second-address"],
            };

            let setAsPrimary =
              !!document.getElementById("checkbox-set-as-primary") &&
              document.getElementById("checkbox-set-as-primary").checked;
            const formattedSelectedAddress = {
              "first-address": userSelectedAddress.addressLines[0],
              "second-address": userSelectedAddress["second-address"]
                ? userSelectedAddress["second-address"]
                : "",
              city: userSelectedAddress.locality,
              country: userSelectedAddress.regionCode,
              postal: userSelectedAddress.postalCode,
              province: getProvinceValueFromDisplayName(
                userSelectedAddress.regionCode,
                userSelectedAddress.administrativeArea
              ),
              isDefaultChecked: setAsPrimary ? "t" : "f",
              isDefaultAddress: setAsPrimary ? "t" : "f",
            };

            const addressExists = await addressAlreadyExist(formattedSelectedAddress, skin);
            const addressAlreadyExistWithoutFlag = await addressAlreadyExistWithoutPrimaryFlag(
              formattedSelectedAddress,
              skin
            );
            const addressList = await localforage.getItem(skin + "__userStoredAddresses");
            const parsedAddressList = JSON.parse(addressList);
            const addressIndex = getAddressIndex(formattedSelectedAddress, parsedAddressList);
            const addressIsSetToPrimary =
              addressIndex !== "" ? parsedAddressList[addressIndex][2] === "t" : false;
            const tempId = addressIndex !== "" ? parsedAddressList[addressIndex][0] : "";
            const defaultFlagChanged = !(
              (addressIsSetToPrimary && setAsPrimary) ||
              (!addressIsSetToPrimary && !setAsPrimary)
            );
            if (!addressExists && !addressAlreadyExistWithoutFlag.matchFound) {
              setUserAddress(userSelectedAddress);
              if (state && state.from === "modify-delivery-address") {
                saveDeliveryAddress(
                  formattedSelectedAddress.isDefaultChecked === "t",
                  formattedSelectedAddress
                );
              } else {
                saveDeliveryAddress(setAsPrimary, userSelectedAddress);
              }
            } else {
              if (addressAlreadyExistWithoutFlag.matchFound && !defaultFlagChanged) {
                setIsAddressAlreadyExist(true);
              } else {
                const data = await handleSetPrimaryAddress(
                  JSON.parse(addressList),
                  { ...formattedSelectedAddress, addressId: tempId },
                  skin,
                  appLanguage,
                  loginToken,
                  !defaultFlagChanged
                );
                if (data) {
                  setDeliveryAddressWithoutSaving();
                }
              }
              //setIsAddressAlreadyExist(true);
              //setDeliveryAddressWithoutSaving(userSelectedAddress);
            }
          }
        })
        .catch((error) => {
          setIsAPIsubmitting(false);
          setAPIerror(error);
        });
    } else {
      setShowTryDifferentAddressModal(true);
    }
  };

  const useEnteredAddress = async (address) => {
    setShowVerificationIssueModal(false);
    let setAsPrimary =
      !!document.getElementById("checkbox-set-as-primary") &&
      document.getElementById("checkbox-set-as-primary").checked;
    const addressExists = await addressAlreadyExist(address, skin);
    if (!addressExists) {
      saveDeliveryAddress(setAsPrimary, address);
    } else {
      const addressList = await localforage.getItem(skin + "__userStoredAddresses");
      const parsedAddressList = JSON.parse(addressList);
      const addressIndex = getAddressIndex(address, parsedAddressList);
      const tempId = parsedAddressList[addressIndex][0];
      const addressIsSetToPrimary = parsedAddressList[addressIndex][2] === "t";
      if ((addressIsSetToPrimary && setAsPrimary) || (!addressIsSetToPrimary && !setAsPrimary)) {
        setDeliveryAddressWithoutSaving();
      } else {
        const data = await handleSetPrimaryAddress(
          JSON.parse(addressList),
          { ...address, addressId: tempId },
          skin,
          appLanguage,
          loginToken
        );
        if (data) {
          setDeliveryAddressWithoutSaving();
        }
      }
    }
  };

  const makeChangesToTheAddress = () => {
    setShowVerificationIssueModal(false);
  };

  return (
    <>
      {userAddress && userAddress !== "null" ? (
        <>
          {deviceWidth >= 660 ? (
            <>
              <section className="account-info__change-password-page-title">
                <div className="desktop-container">
                  <h1>{appLabels["order"]["confirm-address"]}</h1>
                </div>
              </section>

              <div className="delivery-address__form-container desktop-container">
                <div className="delivery_address__confirm-form-and-map">
                  {userLocation && isGeolocatedAddress && (
                    <div aria-hidden="true" className="confirm-address__google-maps-container">
                      <GoogleMaps aria-hidden="true" userLocation={userLocation} />
                    </div>
                  )}
                  <Form
                    id="form--confirm-address"
                    className={`form--confirm-address desktop-container ${
                      !userLocation || !isGeolocatedAddress ? " full-width" : ""
                    }`}
                    submitForm={onConfirmAddress}
                    resetAPIError={() => setAPIerror("")}
                    isAPIsubmitting={isAPIsubmitting}
                    isDisableSubmit={isAPIsubmitting}
                    submitButtonText={appLabels["order"]["save-address"]}
                    isFAB={true}
                    isModalTrigger={true}
                    isModalFAB={true}
                    buttonWrapperClass={"update_account_info__floating-button"}
                  >
                    <FormInput
                      type="text"
                      label={appLabels["form"]["street-address"]}
                      id="input--address"
                      name="first-address"
                      isRequired={true}
                      defaultValue={userAddress["first-address"]}
                      isSmall={userLocation && isGeolocatedAddress ? false : true}
                    />
                    <FormInput
                      type="text"
                      label={appLabels["form"]["apt-suit-unit"]}
                      id="input--apt-suit"
                      name="second-address"
                      defaultValue={
                        userAddress["second-address"] ? userAddress["second-address"] : ""
                      }
                      isSmall={userLocation && isGeolocatedAddress ? false : true}
                    />
                    <FormSelect
                      id="select--country"
                      name="country"
                      label={appLabels["form"]["country"]}
                      isRequired={true}
                      defaultValue={userAddress.country}
                      isHidden={true}
                      isSmall={userLocation && isGeolocatedAddress ? false : true}
                    />

                    <FormInput
                      type="text"
                      label={appLabels["form"]["city"]}
                      id="input--city"
                      name="city"
                      isRequired={true}
                      defaultValue={userAddress.city}
                      isSmall={userLocation && isGeolocatedAddress ? false : true}
                    />
                    <FormSelect
                      id="select--province"
                      name="province"
                      label={appLabels["form"]["province"]}
                      isRequired={true}
                      defaultValue={userAddress.province}
                      isSmall={userLocation && isGeolocatedAddress ? false : true}
                    />

                    <FormInput
                      type="text"
                      label={appLabels["form"]["postal"]}
                      id="input--postal-code"
                      name="postal"
                      isRequired={true}
                      defaultValue={userAddress.postal}
                      isSmall={userLocation && isGeolocatedAddress ? false : true}
                    />

                    <FormCheckbox
                      label={appLabels["order"]["set-as-primary-delivery-address"]}
                      id="checkbox-set-as-primary"
                      name="checkbox-set-as-primary"
                      value="set-as-primary"
                      isDefaultChecked={userAddress.isDefaultAddress === "t"}
                      isSmall={userLocation && isGeolocatedAddress ? false : true}
                    />
                  </Form>
                </div>
              </div>
            </>
          ) : (
            <>
              {userLocation && isGeolocatedAddress && (
                  <div aria-hidden="true" className="confirm-address__google-maps-container">
                    <GoogleMaps aria-hidden="true" userLocation={userLocation} />
                </div>
              )}
              <Form
                id="form--confirm-address"
                className="form--confirm-address desktop-container"
                submitForm={onConfirmAddress}
                resetAPIError={() => setAPIerror("")}
                isAPIsubmitting={isAPIsubmitting}
                isDisableSubmit={isAPIsubmitting}
                submitButtonText={appLabels["order"]["save-address"]}
                isFAB={true}
                isModalTrigger={true}
                isModalFAB={true}
                buttonWrapperClass={"update_account_info__floating-button"}
              >
                <FormInput
                  type="text"
                  label={appLabels["form"]["street-address"]}
                  id="input--address"
                  name="first-address"
                  isRequired={true}
                  defaultValue={userAddress["first-address"]}
                />
                <FormInput
                  type="text"
                  label={appLabels["form"]["apt-suit-unit"]}
                  id="input--apt-suit"
                  name="second-address"
                  defaultValue={userAddress["second-address"] ? userAddress["second-address"] : ""}
                />
                <FormSelect
                  id="select--country"
                  name="country"
                  label={appLabels["form"]["country"]}
                  isRequired={true}
                  defaultValue={userAddress.country}
                  isHidden={true}
                />

                <FormInput
                  type="text"
                  label={appLabels["form"]["city"]}
                  id="input--city"
                  name="city"
                  isRequired={true}
                  defaultValue={userAddress.city}
                />

                <FormSelect
                  id="select--province"
                  name="province"
                  label={appLabels["form"]["province"]}
                  isRequired={true}
                  defaultValue={userAddress.province}
                />

                <FormInput
                  type="text"
                  label={appLabels["form"]["postal"]}
                  id="input--postal-code"
                  name="postal"
                  isRequired={true}
                  defaultValue={userAddress.postal}
                />
                <FormCheckbox
                  label={appLabels["order"]["set-as-primary-delivery-address"]}
                  id="checkbox-set-as-primary"
                  name="checkbox-set-as-primary"
                  value="set-as-primary"
                  isDefaultChecked={userAddress.isDefaultAddress === "t"}
                />
              </Form>
            </>
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}

      {apiAddress && showSetPrimaryDeliveryAddressModal && (
        <AddressValidationDialogModal
          message={getValidatedUserAddressForDialogModal(userAddress, appLabels)}
          closeModalAction={() => {
            setShowSetPrimaryDeliveryAddressModal(!showSetPrimaryDeliveryAddressModal);
          }}
          resetRemoveDialog={setDeliveryAddressWithoutSaving}
          confirmAction={saveDeliveryAddress}
          addressType="validated"
          recommendedAddress={recommendedAddress}
          enteredAddress={userAddress}
          isModifyText={appLabels["order"]["not-now"]}
          isConfirmText={appLabels["order"]["save-address"]}
          isCancelConfirm={true}
          userLocation={userLocation}
        />
      )}

      {apiAddress && showRecommendedAddressModal && (
        <AddressValidationDialogModal
          message={getRecommendedUserAddressForDialogModal(appLabels)}
          closeModalAction={() => {
            setShowRecommendedAddressModal(!showRecommendedAddressModal);
          }}
          confirmAction={useRecommendedAddress}
          addressType="recommended"
          recommendedAddress={recommendedAddress}
          enteredAddress={userAddress}
          isConfirmText={true}
          userLocation={userLocation}
        />
      )}

      {apiAddress && showTryDifferentAddressModal && (
        <AddressValidationDialogModal
          message={apiError ? apiError : getUnverifiedAddressForDialogModal(appLabels)}
          closeModalAction={() => {
            setShowTryDifferentAddressModal(!showTryDifferentAddressModal);
          }}
          addressType="unverified"
          isConfirmText={appLabels["order"]["try-different-address"]}
          recommendedAddress={recommendedAddress}
          enteredAddress={userAddress}
          userLocation={userLocation}
          customClass={"confirm-address__unverified-address "}
        />
      )}

      {apiAddress && showVerificationIssueModal && (
        <AddressValidationDialogModal
          message={apiError ? apiError : getUnverifiedAddressForDialogModal(appLabels)}
          closeModalAction={() => {
            setShowVerificationIssueModal(!showVerificationIssueModal);
          }}
          addressType="verificationIssue"
          recommendedAddress={recommendedAddress}
          enteredAddress={userAddress}
          customClass={"confirm-address__unverified-address "}
          missingComponents={missingAddressComponents}
          onMakeChanges={() => {
            makeChangesToTheAddress();
          }}
          onUseEntered={() => {
            useEnteredAddress(userAddress);
          }}
        />
      )}

      {apiAddress && showAddressMissingComponentModal && (
        <AddressValidationDialogModal
          message={apiError ? apiError : getUnverifiedAddressForDialogModal(appLabels)}
          closeModalAction={() => {
            setShowAddressMissingComponentModal(!showAddressMissingComponentModal);
          }}
          addressType="missingComponent"
          recommendedAddress={recommendedAddress}
          enteredAddress={userAddress}
          customClass={"address-confirmation__missing-component "}
          missingComponents={missingAddressComponents}
          isConfirmText={appLabels["order"]["try-different-address"]}
        />
      )}

      {apiAddress && isAddressAlreadyExist && (
        <AddressValidationDialogModal
          message={apiError ? apiError : getAddressAlreadyExistsForDialogModal(appLabels)}
          closeModalAction={() => {
            console.log("clicked");
            setIsAddressAlreadyExist(!isAddressAlreadyExist);
            history.push("/account/delivery-address");
          }}
          addressType="missingComponent"
          recommendedAddress={recommendedAddress}
          enteredAddress={userAddress}
          customClass={"address-confirmation__missing-component "}
          missingComponents={missingAddressComponents}
          isConfirmText={appLabels["general"]["dialog-modal-ok"]}
          hideCloseButton={true}
        />
      )}
    </>
  );
};

export default ConfirmDeliveryAddressAccount;

import React, { useRef, useEffect, useContext, useState } from "react";
import "./Form.css";
import "./FormDateTimeInput.css";
import AppLabelsContext from "../../App/AppLabelsContext";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import FormClockIcon from '../icons/FormClockIcon';
import { formatDateTimeObject, nextDeliveryDateTime } from "../../GiftCard/giftCardHelpers";
import useWindowSize from "../hooks/useWindowSize";
import "react-multi-date-picker/styles/layouts/mobile.css"

const buttonStyles = {
  position: "absolute",
  bottom: "7px",
  right: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "42px"
};

const iconStyles = {
  color: "#757575",
};

export const FormDateTimeInputCombo = (props) => {
  const {
    resetInvalidField,
    resetPatternTooltip,
    defaultValue,
    fieldData,
    isSmall,
    id,
    name,
    onInputChange,
    handleInputChangeFromParent,
    value,
    isRequired,
    label,
    placeholder,
    showAstr,
    onInputBlur,
    onFocusAction,
    customerWrapperClass,
    clearDateInput = false,
    setClearDateInput,
    isTimePickerEnabled = false,
    calendarOptions
  } = props;

  const [inputInFocusClass, setInputInFocusClass] = useState("");
  const appLabels = useContext(AppLabelsContext)
  const [dateValue, setDateValue] = useState(null)
  const deviceWidth = useWindowSize().width
  const [closeCalendar, setCloseCalendar] = useState(false)
  const datePickerRef = useRef()
  const [maxDate, setMaxDate] = useState()
  const [isCalendarDisabled, setIsCalendarDisabled] = useState(false)

  const handleClickOutside = (event) => {
    const target = event.target;
    if (target) {
      const isClickOutside = !datePickerRef.current.contains(target);
      setCloseCalendar(isClickOutside)
      if (isClickOutside) {
        try {
          datePickerRef.current.closeCalendar()
        } catch (e) {
          
        }
      }
    }
  };

  useEffect(() => {
    if (deviceWidth >= 660) {
      document.getElementById("top-hidden-item-name").addEventListener('click', handleClickOutside, true);

      return () => document.removeEventListener('click', handleClickOutside, true);
    }
  }, []);

  const onFocus = (field) => {
    resetInvalidField(field);
    setInputInFocusClass(" form__field-wrapper--in-focus");
    if (onFocusAction) {
      onFocusAction();
    }
  };

  // if a value exisits for a date, example in case of editing a cart item, show that date instead of default date of now
  useEffect(() => {
    setDateValue(value)
  }, [value])

  // used to manually clear date value when clear form button is pressed in recipient form
  useEffect(() => {
    if (clearDateInput) {
      setDateValue(nextDeliveryDateTime().timestamp)
      setClearDateInput(false)
    }
  }, [clearDateInput])

  const onClickAway = (field) => {
    if (onInputBlur) {
      onInputBlur(field);
    } else {
      /* If any value is present keep placeholder minimized, otherwise expand it */
      field.value === "" && setInputInFocusClass("");
      resetPatternTooltip(field);
    }
  };

  const onCalendarOpenRemoveFocus = () => {
    let element = document.getElementById(id)
    element.blur()

  }

  /* setting the min date a user can select from date time panel.
  This time is set to slightly before today time.
  This is done to fix the calendar not showing current date as valid
  */
  let minDate = new Date();
  minDate.setMinutes(0)
  minDate.setHours(0)
  minDate.setSeconds(0)
  const inputRef = useRef();
  useEffect(() => {
    if (calendarOptions && calendarOptions.length > 0) {
      const maxYearLength = Number(calendarOptions[0])
      const maxMonthsLength = Number(calendarOptions[1])
      const maxDaysLength = Number(calendarOptions[2])
      if ((maxYearLength === 0 && maxMonthsLength === 0 && maxDaysLength === 0) || (maxDaysLength === 1)) {
        if (isTimePickerEnabled) {
          let newMaxDate = new Date()
          newMaxDate.setSeconds(59)
          newMaxDate.setMinutes(59)
          newMaxDate.setHours(23)
          setMaxDate(newMaxDate)
          setIsCalendarDisabled(false)
        } else {
          setIsCalendarDisabled(true)
        }
      } else {
        setIsCalendarDisabled(false)
        let newMaxDate = new Date()
        let currentDate = new Date()
        const futureDate = new Date(
          currentDate.getFullYear() + maxYearLength,
          currentDate.getMonth() + maxMonthsLength,
          currentDate.getDate() + maxDaysLength
        );
        newMaxDate = futureDate
        newMaxDate.setHours(23)
        newMaxDate.setMinutes(59)
        newMaxDate.setTime(newMaxDate.getTime() - 1440 * 60000); // minus additional day to avoid hittin max limit
        newMaxDate.setSeconds(59)
        newMaxDate.setMinutes(59)
        newMaxDate.setHours(23)
        setMaxDate(newMaxDate)
      }
    }
  }, [calendarOptions]);

  useEffect(() => {
    if (defaultValue && !inputInFocusClass && inputRef.current) {
      setInputInFocusClass(" form__field-wrapper--in-focus");
      onFocus(inputRef.current);
    }
  }, [inputRef.current]);

  return (
    <div
      className={`form__field-wrapper${true ? "" : " form__field-wrapper--invalid"
        }${isSmall ? " form__field-wrapper--small" : ""}
    ${customerWrapperClass ? customerWrapperClass : ""}
    ${inputInFocusClass} dateTimeInputComboContainer`}
    >
      <label
        htmlFor={id}
        className="form__field-label"
      >
        {!showAstr && !isCalendarDisabled && <span aria-hidden="true">*</span>}
        {(fieldData.label || label) +
          (fieldData.isRequired || isRequired
            ? ""
            : ` (${appLabels["form"]["optional-label"]})`)}
      </label>
      <div>
        {isTimePickerEnabled ?
          <DatePicker
            ref={datePickerRef}
            className={deviceWidth < 660 ? "rmdp-mobile" : ""}
            range={false}
            onOpen={() => {
              onCalendarOpenRemoveFocus()
            }}

            onClose={() => deviceWidth < 660 ? true : closeCalendar}
            format="MM/DD/YYYY HH:mm"
            minDate={minDate}
            maxDate={maxDate}
            render={(value, openCalendar, onChange) => {
              return (
                <>
                  <input className="form__field formDatepickerInput" type="text"
                    ref={inputRef}
                    name={name}
                    readOnly

                    value={value ? formatDateTimeObject(value).displayValue : ""}
                    placeholder={placeholder}
                    onInput={onChange}
                    onFocus={(e) => onFocus(e.target)}
                    onBlur={(e) => onClickAway(e.target)}
                    id={id} aria-required="false" onClick={openCalendar} autoComplete="off" />

                  <button className="iconButtonEndAbsolute" style={buttonStyles} type="button" onClick={openCalendar}>
                    <FormClockIcon aria-hidden="true" style={iconStyles} />
                  </button>
                </>)
            }
            }

            editable={false}
            headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
            onChange={(dateValue, e) => {
              // get the date value and cast it into Date object
              let dateObject = new Date(dateValue)
              // manually get date value from input
              onInputChange(e.input.getElementsByTagName("input")[0], true, dateObject.toString());
              if (handleInputChangeFromParent) handleInputChangeFromParent(e.input.getElementsByTagName("input")[0], dateObject)
              setDateValue(dateValue)
            }}

            id="giftCardDeliveryDateTime"
            inputClass="past-orders__calendar-input"
            onOpenPickNewDate={true}
            plugins={[
              <TimePicker position="bottom" hStep={1} hideSeconds />
            ]}
            currentDate={null}
            value={dateValue ? new Date(dateValue) : value}
          />
          :
          <DatePicker
            ref={datePickerRef}
            className={deviceWidth < 660 ? "rmdp-mobile" : ""}
            range={false}
            onOpen={() => {
              onCalendarOpenRemoveFocus()
            }}
            onClose={() => deviceWidth < 660 ? true : closeCalendar}
            format="MM/DD/YYYY"
            minDate={minDate}
            maxDate={maxDate}
            disabled={isCalendarDisabled}
            render={(value, openCalendar, onChange) => {
              return (
                <>
                  <input className={`form__field formDatepickerInput ${isCalendarDisabled ? "disabledCalendarInput" : ""} `} type="text"
                    ref={inputRef}
                    disabled={isCalendarDisabled}
                    name={name}
                    readOnly
                    value={value ? formatDateTimeObject(value, true).displayValue : ""}
                    placeholder={placeholder}
                    onInput={onChange}
                    onFocus={(e) => onFocus(e.target)}
                    onBlur={(e) => onClickAway(e.target)}
                    id={id} aria-required="false" onClick={openCalendar} autoComplete="off" />
                  {!isCalendarDisabled &&
                    <button className="iconButtonEndAbsolute" style={buttonStyles} type="button" onClick={openCalendar}>
                      <FormClockIcon aria-hidden="true" style={iconStyles} />
                    </button>
                  }
                </>)
            }
            }

            editable={false}
            headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
            onChange={(dateValue, e) => {
              // get the date value and cast it into Date object
              let dateObject = new Date(dateValue)
              dateObject.setHours(23)
              dateObject.setMinutes(59)
              dateObject.setSeconds(59)
              // manually get date value from input
              onInputChange(e.input.getElementsByTagName("input")[0], true, dateObject.toString());
              if (handleInputChangeFromParent) handleInputChangeFromParent(e.input.getElementsByTagName("input")[0], dateObject)
              setDateValue(dateValue)
            }}

            id="giftCardDeliveryDateTime"
            inputClass="past-orders__calendar-input"
            onOpenPickNewDate={true}
            currentDate={null}
            value={dateValue ? new Date(dateValue) : value}
          />
        }
      </div>

      {!fieldData.isValid && fieldData.errorMessage !== "" && (
        <div className="form__field-error">{fieldData.errorMessage}</div>
      )}
    </div>
  );
};

FormDateTimeInputCombo.displayName = "FormDateTimeInputCombo";

import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import GooglePayButton from "@google-pay/button-react";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";

const GCPaymentMethodGooglePayButton = (props) => {
  const { orderTotal, appLanguage, submitGooglePayOrder, validateDeliveryDateTime } =
    props;

  const [preventDefaultBehaviour, setPreventDefaultBehaviour] = useState(true);

  const isLocalhost =
    process.env.NODE_ENV === "development" ||
    window.location.hostname.includes("alpha") ||
    window.location.hostname.includes("beta") ||
    window.location.hostname.includes("dilbert");
  const merchantConfig = useContext(MerchantConfigContext).merchant;

  const currencyCode = merchantConfig.I34
  const countryCode = merchantConfig.I58
  const GOOGLE_PAY_PRODUCTION_ID = "3336759522887621560";
  const GOOGLE_PAY_TEST_ID = "BCR2DN4TZLH43BBN";

  const googlePayConfigGateway = merchantConfig.I53[0] //googlePayConfigGateway
  const googlePayConfigGatewayMechantId =
    merchantConfig.I53[1] // google_pay_config.gateway_merchant_id;

  const appLabels = useContext(AppLabelsContext);

  return ReactDOM.createPortal(
    <div className="google-pay-checkout-button__container">
      <GooglePayButton
        aria-label={appLabels["order"]["pay-with-google-pay"]}
        environment={isLocalhost ? "TEST" : "PRODUCTION"}
        buttonSizeMode="fill"
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ["VISA", "AMEX", "DISCOVER", "MASTERCARD"]
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: googlePayConfigGateway,
                  gatewayMerchantId: googlePayConfigGatewayMechantId,
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: isLocalhost ? GOOGLE_PAY_TEST_ID : GOOGLE_PAY_PRODUCTION_ID,
            merchantName: "Givex Corp",
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: Number(orderTotal).toFixed(2).toString(),
            currencyCode: currencyCode,
            countryCode: countryCode,
          },
          shippingAddressRequired: false,
          callbackIntents: ["PAYMENT_AUTHORIZATION"],
          emailRequired: true,
        }}
        onClick={async (event) => {
          if (preventDefaultBehaviour) {
            event.preventDefault();
            setPreventDefaultBehaviour(false);
            let validTime = validateDeliveryDateTime()
            if (validTime) {
              document.getElementsByClassName("gpay-button")[0].click();
            }
            return true;
          }
        }}
        onCancel={(reason) => {
          setPreventDefaultBehaviour(true);
        }}
        onLoadPaymentData={(paymentRequest) => {
          submitGooglePayOrder(paymentRequest);
        }}
        onPaymentAuthorized={(paymentData) => {
          if (paymentData) {
            return { transactionState: "SUCCESS" };
          } else {
            return {
              transactionState: "ERROR",
              error: {
                reason: "PAYMENT_DATA_INVALID",
                message: "Cannot pay with payment credentials",
                intent: "PAYMENT_AUTHORIZATION",
              },
            };
          }
        }}
        buttonLocale={appLanguage}
        buttonColor="default"
        buttonType="checkout"
      />
    </div>,
    document.body
  );
};

export default GCPaymentMethodGooglePayButton;

// Libraries
import React, { useContext } from "react";
import { Route } from "react-router-dom";

// Contexts
import AppLabelsContext from "../App/AppLabelsContext";
import useWindowSize from "../_common/hooks/useWindowSize";

// UI components
import { Account } from "./Account";
import { AddCreditCard } from "./AddCreditCard";
import { PaymentMethods } from "./PaymentMethods";
import { PageComponentTemplate } from "../_common/components/PageComponentTemplate";
import AccountDeletion from "./AccountDeletion";
import AccountDeletionConfirmation from "./AccountDeletionConfirmation";
import AddDeliveryAddress from "./AddDeliveryAddress";
import ConfirmDeliveryAddressAccount from "./ConfirmDeliveryAddressAccount";

export const AccountRoutes = () => {
  const appLabels = useContext(AppLabelsContext);

  const deviceWidth = useWindowSize().width;
  return (
    <>
      <Route path="/account/profile" render={() => <Account />} />
      <Route path="/account/add-credit-card" render={() => <AddCreditCard />} />
      <Route
        path="/account/payment-methods"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["account"]["payment-methods"]}
            isHomeLink={true}
            isBackButton={true}
            mainClassOverride="payment-methods-main-content"
            component={<PaymentMethods />}
            isFooter={false}
          />
        )}
      />

      <Route
        path="/account/account-deletion"
        exact
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["account"]["delete-account"]}
            isHomeLink={true}
            isBackButton={true}
            mainClassOverride="account-deletion-main-content"
            component={<AccountDeletion />}
            isFooter={true}
          />
        )}
      />

      <Route
        path="/account/delivery-address"
        exact
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["delivery-addresses"]}
            isHomeLink={true}
            isBackButton={true}
            mainClassOverride="account-deletion-main-content"
            component={<AddDeliveryAddress />}
            isFooter={true}
          />
        )}
      />

      <Route
        path="/account/confirm-address"
        exact
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["order"]["confirm-address"]}
            isHomeLink={true}
            isBackButton={true}
            mainClassOverride="account-deletion-main-content confirm-order__form-container"
            component={<ConfirmDeliveryAddressAccount />}
            isFooter={true}
            backButtonDestination={deviceWidth < 660 ? "/account/delivery-address" : null}
          />
        )}
      />

      <Route
        path="/account/account-deletion-confirmation"
        exact
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["account"]["delete-account-confirmation-header"]}
            isHomeLink={true}
            isBackButton={false}
            mainClassOverride="account-deletion-confirmation-main-content"
            component={<AccountDeletionConfirmation />}
            isFooter={true}
          />
        )}
      />
    </>
  );
};

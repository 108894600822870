// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";

// Helpers and custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";
/** Hooks */
import { useHideOrderModule } from "../../App/useHideOrderModule";

// Contexts
import AppLabelsContext from "../../App/AppLabelsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import BrandingContext from "../../App/BrandingContext";
import StoreContext from "../StoreContext";
import { MenuContext } from "./MenuContext";
import OrderContext from "../../App/OrderContext";
import OrderTypeContext from "../OrderTypeContext";

// UI components
import { HeroSection } from "../../_common/DesktopLayout/HeroSection";
import { MenuTopCategoriesGrid } from "./MenuTopCategoriesGrid";
import { MenuSubcategoriesGrid } from "./MenuSubcategoriesGrid";
import { MenuItemsLevel } from "../MenuItemsLevel";
import { Favorites } from "../Favorites/Favorites";
//import { MenuItemDetail } from "./MenuItemDetail/MenuItemDetail";
//import { DesktopMenuItemDetails } from "./DesktopMenu/DesktopMenuItemDetails";
//import { DesktopMenuComboDetails } from "./DesktopMenu/DesktopMenuComboDetails";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import MenuBillToggle from "./MenuBillToggle";
import { CartOrderDetailsSummary } from "../Cart/CartOrderDetailsSummary";

// CSS
import "./Menu.css";
import localforage from "localforage";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { getOutOfStockOrUnavailableItems } from "../RecentOrders/orderHistoryHelpers";
import CartContext from "../Cart/CartContext";

export const Menu = () => {
  const appLabels = useContext(AppLabelsContext);
  const { skin } = useContext(MerchantConfigContext);
  const branding = useContext(BrandingContext);
  const genericBanner = branding["online-ordering-landing-page"];
  const itemLevelBanner = branding["online-ordering-item-page"];

  const orderContext = useContext(OrderContext);
  const storeContext = useContext(StoreContext);
  const activeOrderStore = storeContext.activeOrderStore;

  const orderMenu = useContext(MenuContext);
  const menuLevel = orderMenu.menuLevel;
  const isThreeLevelMenu = menuLevel === "threeLevels";
  const isTwoLevelMenu = menuLevel === "twoLevels";

  const { pathname, state } = useLocation();
  const history = useHistory();
  const deviceWidth = useWindowSize().width;

  const billPaymentIsEnabled =
    activeOrderStore && activeOrderStore["vexilorConfig"]["get_order_from_table"] === "t";

  const { hideOrderModule } = useHideOrderModule();

  useEffect(() => {
    if (activeOrderStore) orderContext.checkStoreAvailability(activeOrderStore);
  }, [activeOrderStore]);

  useEffect(() => {
    const pathName = window.location.pathname;
    sessionStorage.removeItem(pathName + "scannedQRCodeURLHash");
  }, []);

  const [isIncomingLinkValid, setIsIncomingLinkValid] = useState(false);

  useEffect(() => {
    if (orderMenu.apiData) {
      if (pathname.includes("undefined")) {
        orderMenu.updateMenu({
          ...orderMenu,
          activeTopCategory: null,
          activeSubcategory: null,
          activeItem: null,
        });
        history.push("/online-ordering/menu");
        //
      } else {
        const isTopCategoryLevel =
          pathname.includes("/category/") &&
          !pathname.includes("/subcategory/") &&
          !pathname.includes("/item/") &&
          !pathname.includes("/combo/");

        const isTwoLevelSubcategory =
          pathname.includes("/subcategory/") &&
          !pathname.includes("/category/") &&
          !pathname.includes("/item/") &&
          !pathname.includes("/combo/");

        const isSubcategoryLevel =
          pathname.includes("/category/") &&
          pathname.includes("/subcategory/") &&
          !pathname.includes("/item/") &&
          !pathname.includes("/combo/");

        const isItemLevel =
          (pathname.includes("/item/") || pathname.includes("/combo/")) &&
          (pathname.includes("/category/") || pathname.includes("/subcategory/"));

        let isValidLink = false;

        if (isTopCategoryLevel) {
          const topCategoryIdFromURL = pathname.split("/category/")[1];
          if (orderMenu.apiData[`category-${topCategoryIdFromURL}`]) isValidLink = true;
          //
        } else if (isTwoLevelSubcategory) {
          // 2-level menu on subcategory reload
          const subcategoryIdFromURL = pathname.split("/subcategory/")[1];
          if (orderMenu.apiData[`subcategory-${subcategoryIdFromURL}`]) isValidLink = true;
          //
        } else if (isSubcategoryLevel) {
          // 3-level menu on subcategory reload
          const topCategoryIdFromURL = pathname.split("/category/")[1].split("/")[0];
          const subcategoryIdFromURL = pathname.split("/subcategory/")[1];

          if (
            orderMenu.apiData[`category-${topCategoryIdFromURL}`] &&
            orderMenu.apiData[`category-${topCategoryIdFromURL}`].subcategories[
              `subcategory-${subcategoryIdFromURL}`
            ]
          ) {
            isValidLink = true;
          }
          //
        } else if (isItemLevel) {
          if (isThreeLevelMenu) {
            // 3-level menu on subcategory reload
            const topCategoryIdFromURL =
              pathname.split("/category/")[1] && pathname.split("/category/")[1].split("/")[0];
            const subcategoryIdFromURL =
              pathname.split("/subcategory/")[1] &&
              pathname.split("/subcategory/")[1].split("/")[0];

            let itemIdFromURL = "";

            if (pathname.includes("/item/") && !pathname.includes("/combo/")) {
              itemIdFromURL = pathname.split("/item/")[1];
            } else if (pathname.includes("/combo/") && !pathname.split("/item/")[1]) {
              itemIdFromURL = pathname.split("/combo/")[1];
            } else if (pathname.includes("/combo/") && pathname.split("/item/")[1]) {
              itemIdFromURL = pathname.split("/combo/")[1].split("/item/")[0];
            }

            if (
              orderMenu.apiData[`category-${topCategoryIdFromURL}`] &&
              orderMenu.apiData[`category-${topCategoryIdFromURL}`].subcategories[
                `subcategory-${subcategoryIdFromURL}`
              ] &&
              orderMenu.apiData[`category-${topCategoryIdFromURL}`].subcategories[
                `subcategory-${subcategoryIdFromURL}`
              ].items[`item-${itemIdFromURL}`]
            ) {
              isValidLink = true;
            }
            //
          } else if (isTwoLevelMenu) {
            const subcategoryIdFromURL = pathname.split("/subcategory/")[1].split("/")[0];

            let itemIdFromURL = "";

            if (pathname.includes("/item/") && !pathname.includes("/combo/")) {
              itemIdFromURL = pathname.split("/item/")[1];
            } else if (pathname.includes("/combo/") && !pathname.split("/item/")[1]) {
              itemIdFromURL = pathname.split("/combo/")[1];
            } else if (pathname.includes("/combo/") && pathname.split("/item/")[1]) {
              itemIdFromURL = pathname.split("/combo/")[1].split("/item/")[0];
            }

            if (
              orderMenu.apiData[`subcategory-${subcategoryIdFromURL}`] &&
              orderMenu.apiData[`subcategory-${subcategoryIdFromURL}`].items[
                `item-${itemIdFromURL}`
              ]
            ) {
              isValidLink = true;
            }
            //
          }
        } else {
          // URL was for Menu's landing page
          isValidLink = true;
        }

        if (isValidLink) setIsIncomingLinkValid(true);
        else {
          orderMenu.updateMenu({
            ...orderMenu,
            activeTopCategory: null,
            activeSubcategory: null,
            activeItem: null,
          });
          if (pathname !== "/online-ordering/menu") history.push("/online-ordering/menu");
        }
      }
    }
  }, [orderMenu.apiData, pathname]);

  const mainRef = useRef(null);
  useEffect(() => {
    // Reset scroll position on route change depending on the "scroll" container
    if (mainRef.current && deviceWidth < 660) {
      mainRef.current.scrollTo(0, 0);
    } else if (mainRef.current && deviceWidth >= 660) {
      document.querySelector(".App").scrollTo(0, 0);
    }
  }, [pathname, mainRef]);

  const url = window.location.href;
  const subcategoryUrl = url.match("subcategory");
  const favorites = url.match("favorites");

  const skipSubcatGridView =
    isTwoLevelMenu &&
    deviceWidth >= 660 &&
    (!state ||
      (state &&
        !state.isModifyingItem &&
        state.from !== "breadcrumbs" &&
        state.from !== "category-button" &&
        state.from !== "item-card" &&
        state.from !== "menu-home-breadcrumb" &&
        state.from !== "menu-slider" &&
        state.from !== "subcategory-card" &&
        state.from !== "combo-item" &&
        state.from !== "dashboard-card-slider" &&
        state.from !== "login-register" &&
        state.from !== "suggestedItems" &&
        state.from !== "item-page")) &&
    !subcategoryUrl &&
    !favorites;

  const getFirstValidSubcategoryId = (apiData) => {
    const subcategoryList = Object.keys(apiData).map(function (key) {
      return apiData[key];
    });
    return subcategoryList.filter((subcategory) => subcategory["noOfItems"] > 0)[0].id;
  };

  const orderTypeContext = useContext(OrderTypeContext);
  const orderType = orderTypeContext.value;

  /** validate active order store and active order time */
  useEffect(() => {
    if (orderTypeContext && storeContext && orderType) {
      if ((!storeContext.activeOrderStore || !orderTypeContext.value) && orderType !== "dinein") {
        history.push("/online-ordering/order-type");
      }
    }
  }, [orderTypeContext, storeContext, orderType]);

  const [outOfStockItemsList, setOutOfStockItemsList] = useState(null);
  const [isAlertNotification, setIsAlertNotification] = useState(false);

  useEffect(() => {
    localforage.getItem(skin + "__outOfStockItems").then((outOfStockItems) => {
      if (outOfStockItems) {
        setOutOfStockItemsList(outOfStockItems);
        setIsAlertNotification(true);
      } else {
        setIsAlertNotification(false);
      }
    });
  });

  const resetOutOfStockItemsList = () => {
    setOutOfStockItemsList(null);
    if (isAlertNotification) setIsAlertNotification(false);
    localforage.removeItem(skin + "__outOfStockItems");
  };
  const cartContext = useContext(CartContext)

  return (
    <>
      <div ref={mainRef}>
        {cartContext && cartContext.quantity > 0 && (
          <div className="visually-hidden" role="alert">
            {" "}
            <p>
              {" "}
              {appLabels["order"]["you-have-n-items-in-cart"].replace(
                "[item-quantity]",
                cartContext.quantity
              )}
            </p>
          </div>
        )}
        {cartContext && cartContext.quantity > 0 && (
          <div className="visually-hidden" aria-atomic="true" aria-live="polite">
            <p>
              {" "}
              {appLabels["order"]["you-have-n-items-in-cart"].replace(
                "[item-quantity]",
                cartContext.quantity
              )}
            </p>
          </div>
        )}
        {deviceWidth >= 660 && (
          <>
            {pathname.includes("/item/") && itemLevelBanner && (
              <HeroSection imageUrl={itemLevelBanner} />
            )}
            {!pathname.includes("/item/") && genericBanner && (
              <HeroSection imageUrl={genericBanner} />
            )}
            {/*orderMenu.apiData && <DesktopMenuOrderDetails />*/}
          </>
        )}

        {deviceWidth < 660 &&
          orderType === "dinein" &&
          billPaymentIsEnabled &&
          !hideOrderModule && <MenuBillToggle initialState={"menu"} />}

        <CartOrderDetailsSummary
          activeOrderStore={activeOrderStore}
          activeOrderType={orderType}
          numberOfItems={orderType === "delivery" ? 1 : null}
          onlyDeliveryInfo={orderType === "delivery"}
        />

        <div
          className={`menu-container desktop-container${
            deviceWidth < 660 ? " mobile-container" : ""
          }`}
        >
          {orderMenu.apiData && isIncomingLinkValid && (
            <>
              <Route exact path="/online-ordering/menu">
                {isThreeLevelMenu && <MenuTopCategoriesGrid />}
                {isTwoLevelMenu && <MenuSubcategoriesGrid />}
              </Route>
              {skipSubcatGridView && (
                <Redirect
                  to={`/online-ordering/menu/subcategory/${getFirstValidSubcategoryId(
                    orderMenu.apiData
                  )}`}
                />
              )}
              <Route exact path="/online-ordering/menu/favorites" render={() => <Favorites />} />
              <Route
                exact
                path="/online-ordering/menu/category/:category"
                render={() => <MenuSubcategoriesGrid />}
              />
              <Route
                exact
                path="/online-ordering/menu/category/:category/subcategory/:subcategory"
                render={() => <MenuItemsLevel />}
              />

              <Route
                exact
                path="/online-ordering/menu/subcategory/:subcategory"
                render={() => <MenuItemsLevel />}
              />
            </>
          )}
          {orderMenu.isEmpty && (
            <p className="menu__empty-message">
              {appLabels["order"]["no-store-menu-message"]}
              {"."}
            </p>
          )}
          {!orderMenu.apiData && !orderMenu.isEmpty && <LoadingSpinner />}
        </div>
      </div>

      {outOfStockItemsList && isAlertNotification && (
        <DialogModal
          message={getOutOfStockOrUnavailableItems(outOfStockItemsList, appLabels)}
          isReorderFlow={true}
          resetRemoveDialog={resetOutOfStockItemsList}
        />
      )}
    </>
  );
};

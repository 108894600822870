import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import useWindowSize from "../../_common/hooks/useWindowSize";
import { MenuContext } from "./MenuContext";
import UserRoleContext from "../../App/UserRoleContext";
import { MenuCategoryCard } from "./MenuCategoryCard/MenuCategoryCard";
import { MenuCategoryFilterButtonsList } from "./MenuCategoryFilterButtonsList/MenuCategoryFilterButtonsList";
import { MenuFavoritesCategoryCard } from "./MenuCategoryCard/MenuFavoritesCategoryCard";
import { MenuBreadcrumbs } from "./MenuBreadcrumbs";

export const MenuSubcategoriesGrid = () => {
  const orderMenu = useContext(MenuContext);
  const isTwoLevels = orderMenu.menuLevel === "twoLevels";
  const isThreeLevels = orderMenu.menuLevel === "threeLevels";

  const userRoleContext = useContext(UserRoleContext);
  const userStatus = userRoleContext.status;
  const isLoggedIn = userStatus === "logged-in";

  const history = useHistory();
  const { category } = useParams();

  useEffect(() => {
    //reset scroll to position zero
    if (!!document.getElementsByClassName("menu-main-content").length) {
      document.getElementsByClassName("menu-main-content")[0].scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (category) orderMenu.updateMenu({ ...orderMenu, activeTopCategory: category });
  }, [category]);

  const [subcategories, setSubcategories] = useState();

  useEffect(() => {
    if (orderMenu.apiData && orderMenu.apiData !== "reset") {
      if (isThreeLevels) {
        const topCategoryId = category || orderMenu.activeTopCategory;

        if (orderMenu.apiData["category-" + topCategoryId]) {
          setSubcategories(orderMenu.apiData["category-" + topCategoryId].subcategories);
        } else {
          // category doesn't exist (Menu updated or invalid URL)
          history.push("/dashboard");
        }
      } else if (isTwoLevels) {
        setSubcategories(orderMenu.apiData);
      }
    }
  }, [orderMenu.apiData, category]);

  const navigateToNextMenuLevel = (id) => {
    if (isThreeLevels) {
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: category,
        activeSubcategory: id,
        activeItem: null,
      });
      history.push(`/online-ordering/menu/category/${category}/subcategory/${id}`);
      //
    } else if (isTwoLevels) {
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: null,
        activeSubcategory: id,
        activeItem: null,
      });
      history.push({
        pathname: `/online-ordering/menu/subcategory/${id}`,
        state: { from: "subcategory-card" },
      });
    }

    //reset scroll to position zero
    if (!!document.getElementsByClassName("menu-main-content").length) {
      document.getElementsByClassName("menu-main-content")[0].scrollTo(0, 0);
    }
  };

  const currentLevelCategory =
    isThreeLevels && !!subcategories
      ? orderMenu.apiData[`category-${subcategories[Object.keys(subcategories)[0]].parentId}`]
      : null;

  const deviceWidth = useWindowSize().width;

  return (
    !!subcategories && (
      <>
        {deviceWidth >= 660 && isThreeLevels && <MenuCategoryFilterButtonsList />}
        {isThreeLevels && <MenuBreadcrumbs currentLevelCategory={currentLevelCategory} />}
        <ul className="menu__category-cards-grid">
          {isTwoLevels && isLoggedIn && <MenuFavoritesCategoryCard />}
          {Object.keys(subcategories).map((key, index) => (
            <React.Fragment key={`${subcategories[key].id}-${index}`}>
              {subcategories[key]["noOfItems"] > 0 && (
                <MenuCategoryCard
                  handleClick={navigateToNextMenuLevel}
                  index={index}
                  itemData={subcategories[key]}
                />
              )}
            </React.Fragment>
          ))}
        </ul>
      </>
    )
  );
};

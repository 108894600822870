import { useState } from "react";

export const useModal = () => {
  const [isModal, setIsModal] = useState(false);
  const [closeAnimation, setCloseAnimation] = useState("");

  const toggleModal = event => {
    if (isModal) {
      if (event) {
        const isFullscreen =
          event.target.closest(".modal") &&
          event.target.closest(".modal").classList.contains("modal--fullscreen");

        if (isFullscreen) setCloseAnimation("modal--slideOutToRight");
        else setCloseAnimation("modal--slideOutToBottomAndShrink");

        setTimeout(() => {
          setIsModal(false);
          setCloseAnimation("");
        }, 200);
      } else {
        setIsModal(false); // skip closing animations and close modal
      }
      document.querySelector("body").classList.remove("modal--open");
    } else {
      setIsModal(true);
      document.querySelector("body").classList.add("modal--open");
    }
  };
  return { isModal, toggleModal, closeAnimation, setIsModal };
};

import React, { useContext } from "react";

//Contexts
import BrandingContext from "../App/BrandingContext";

//CSS
import "./LoadingDots.css";

export const LoadingDots = () => {
  const NUMBER_OF_LOADING_DOTS = 3;
  const brandingContext = useContext(BrandingContext);
  const primaryColor = brandingContext["primary-colour"];

  return (
    <div className="loading-dots-container">
      <div className="loading-dots">
        {Array.from(Array(NUMBER_OF_LOADING_DOTS)).map((dot, index) => (
          <React.Fragment key={index}>
            <div className="loading-dot" style={{ backgroundColor: primaryColor }}></div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";

/** Libraries */
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

/** CSS */
import "./OrderAddressAutoComplete.css";

const OrderAddressAutoComplete = (props) => {
  const { appLabels, onSearchBoxPlacesChanged, inputRef, isAddressValid } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const CHAR_LIMIT_BEFORE_API_CALL = 3;
  const onInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const displaySuggestions = function (predictions, status) {
    if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
      console.error(status);
      return;
    }

    const container = document.getElementById("address-suggestion-list");
    container.innerHTML = "";
    container.classList.remove("visually-hidden");

    predictions.forEach((prediction) => {
      const li = document.createElement("li");
      li.appendChild(document.createTextNode(prediction.description));

      const liButton = document.createElement("button");
      liButton.className = "address-suggestion-list-item-button";
      liButton.addEventListener("click", () => {
        onSearchBoxPlacesChanged(prediction);
      });
      liButton.setAttribute('aria-label', appLabels["general"]["select"] + " " + prediction.description)
      li.appendChild(liButton);
      container.appendChild(li);
    });
  };

  function initService() {
    const service = new window.google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input: searchQuery }, displaySuggestions);
  }
  useEffect(() => {
    if (searchQuery && searchQuery.length > CHAR_LIMIT_BEFORE_API_CALL) {
      initService();
    } else {
      const container = document.getElementById("address-suggestion-list");
      container.innerHTML = "";
      container.classList.add("visually-hidden");
    }
  }, [searchQuery]);

  useEffect(() => {
    const container = document.getElementById("address-suggestion-list");
    container.innerHTML = "";
    container.classList.add("visually-hidden");
  }, [isAddressValid]);

  return (
    <>
      <TextField
        id="google-map-autocomplete-address"
        variant="standard"
        inputRef={inputRef}
        type="search"
        placeholder={appLabels["order"]["find-address"]}
        onChange={onInputChange}
        style={{
          border: "none",
          width: "100%",
          height: "40px",
          fontSize: "14px",
          textOverflow: "ellipses",
        }}
        InputProps={{
          autoComplete: "off",
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                className="icon--search"
                style={{
                  color: "#d4d4d4",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
export default OrderAddressAutoComplete;

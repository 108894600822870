import React, { useContext, useEffect } from "react";

// Context
import UserRoleContext from "../../App/UserRoleContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import AppSettingsContext from "../../App/AppSettingsContext";
import BrandingContext from "../../App/BrandingContext";

//Helpers
import { getAPICardList } from "../../OnlineOrdering/Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPICardList";

// UI Components
import { DashboardActionButton } from "./DashboardActionButton";

// Assets
import { ReactComponent as IconStar } from "../../_common/icons/IconStar.svg";
import { ReactComponent as IconArrowDown } from "../../_common/icons/IconArrowDown.svg";
import AppLanguageContext from "../../App/AppLanguageContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";

export const DashboardRewardButton = (props) => {
  const { customClass, setIsShowingRewardDetails, isShowingRewardDetails, setUserGiftCards } =
    props;
  const userRoleContext = useContext(UserRoleContext);
  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const branding = useContext(BrandingContext);

  const { skin } = useContext(MerchantConfigContext);

  const loginToken = userRoleContext.loginToken;

  const handleShowingRewardDetails = () => {
    setIsShowingRewardDetails(!isShowingRewardDetails);
  };

  useEffect(() => {
    if (loginToken && isShowingRewardDetails) {
      getAPICardList(
        skin,
        loginToken,
        appSettings["currency-symbol"],
        appSettings["currency-symbol-side"],
        appLanguage,
        branding
      ).then((fetchedData) => {
        if (fetchedData) {
          if (fetchedData.length > 0) {
            setUserGiftCards(fetchedData);
          } else if (fetchedData.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          }
        }
      });
    } else {
      setUserGiftCards(null);
    }
  }, [loginToken, isShowingRewardDetails]);

  return (
    <>
      <DashboardActionButton
        isLeftAligned={true}
        title={appLabels["loyalty"]["my-rewards"]}
        ariaLabel={appLabels["loyalty"]["my-rewards"]}
        icon={<IconStar aria-hidden="true" style={{ width: "30px", height: "30px" }} />}
        onClick={() => {
          handleShowingRewardDetails();
        }}
        iconBackGroundColour={"#eeeeee"}
        id="dashboard-rewards-button"
        hasArrow={true}
        collapsedArrow={
          <IconArrowDown aria-hidden="true" style={{ width: "25px", height: "25px" }} />
        }
        expandedArrow={
          <IconArrowDown
            aria-hidden="true"
            style={{ transform: "rotate(180deg)", width: "25px", height: "25px" }}
          />
        }
        isCollapsed={!isShowingRewardDetails}
        customClass={`${isShowingRewardDetails ? " expanded-rewards-details" : ""} ${
          customClass ? customClass : ""
        }`}
      />
    </>
  );
};

import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

import AppLabelsContext from "../../App/AppLabelsContext";

export const GCSeeDetailsButton = ({ recentOrders }) => {
  const deviceWidth = useWindowSize().width;
  const appLabels = useContext(AppLabelsContext);

  return (
    <Link
      className={`button recent-order__reorder-button button--pill-shaped ${deviceWidth < 660
        ? "recent-order-view-details-button"
        : "recent-order-view-details-button-desktop"
        }`}
      to={{
        pathname: `/gift-card/recent/order-details/` + recentOrders.id,
        state: {
          recentOrders: recentOrders,
        },
      }}
    >
      {appLabels["order"]["view-details"]}
    </Link>
  );
};

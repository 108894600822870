import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useWindowSize from "../../_common/hooks/useWindowSize";

// Context
import AppLabelsContext from "../../App/AppLabelsContext";
import OrderContext from "../../App/OrderContext";
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";
import StoreContext from "../../OnlineOrdering/StoreContext";
import OrderTimeContext from "../../OnlineOrdering/OrderTimeContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import { MenuContext } from "../../OnlineOrdering/Menu/MenuContext";

export const DashboardNewsFeedCard = (props) => {
  const { slideData, index } = props;
  const history = useHistory();
  const deviceWidth = useWindowSize().width;

  const appLabels = useContext(AppLabelsContext);
  const orderContext = useContext(OrderContext);
  const orderType = useContext(OrderTypeContext);
  const storeContext = useContext(StoreContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const merchantConfig = useContext(MerchantConfigContext);
  const orderMenu = useContext(MenuContext);
  const skin = merchantConfig.skin;

  const [isShowingFullBlurb, setIsShowingFullBlurb] = useState(false);
  const [charLimit, setCharLimit] = useState(45);
  const [hideReadMore, setHideReadMore] = useState(false);

  const textLength = !!slideData["blurb"]
    ? slideData["blurb"].length
    : 0 + (!!slideData["disclaimer"] ? slideData["disclaimer"].length : 0);

  const cotainerID = `dashboard-news-feed-blurb-container-${slideData.id}-${index}`;

  function calculatePercentageDifference(num1, num2) {
    let difference = Math.abs(num1 - num2);
    let average = (num1 + num2) / 2;
    let percentageDifference = (difference / average) * 100;
    return percentageDifference;
  }

  function getCharacterCount(divId) {
    const div = document.getElementById(divId);

    if (!div) {
      return 45;
    }

    // Create a temporary span element to measure text dimensions
    const span = document.createElement("span");
    span.style.visibility = "hidden";
    span.style.whiteSpace = "nowrap";
    span.textContent = "A"; // Use a common character for estimation
    span.id = "temp-span";
    div.appendChild(span);

    // Get the width of a single character
    const charWidth = span.offsetWidth;

    // Get the computed styles of the span element
    const computedStyles = window.getComputedStyle(span);
    const fontSize = parseFloat(computedStyles.fontSize);

    // Get the line height
    let lineHeight = computedStyles.lineHeight;

    // If line height is 'normal', estimate it
    if (lineHeight === "normal") {
      lineHeight = fontSize * 1.3; // Assuming a typical line height factor
    } else {
      lineHeight = parseFloat(lineHeight);
    }

    // Remove the temporary span element
    div.removeChild(span);

    // Get the dimensions of the div
    const divWidth = div.clientWidth;
    const divHeight = div.clientHeight;

    // Estimate the number of characters per line
    const charsPerLine = parseInt(divWidth / charWidth);

    // Estimate the number of lines
    let lines = Math.floor(divHeight / lineHeight);

    if (lines === 0) {
      lines = 1;
    }
    const p = div.querySelector("p");
    p.style["-webkit-line-clamp"] = lines;

    if (deviceWidth < 600) {
      p.style["-webkit-line-clamp"] = 3;
    }

    if (!slideData["image-src"]) {
      p.style["-webkit-line-clamp"] = "unset";
      setHideReadMore(true);
    } else {
      setHideReadMore(false);
    }

    // Calculate the total number of characters
    let totalChars = parseInt(charsPerLine * lines);

    if (textLength < totalChars) {
      setIsShowingFullBlurb(true);
    } else {
      setIsShowingFullBlurb(false);
    }

    if (textLength > totalChars && calculatePercentageDifference(textLength, totalChars) < 10) {
      return textLength;
    } else {
      return totalChars;
    }
  }

  useEffect(() => {
    setIsShowingFullBlurb(false);
    setHideReadMore(false);

    setTimeout(() => {
      setCharLimit(getCharacterCount(cotainerID));
    }, 250);
  }, [deviceWidth]);

  function goToMenuItem(link) {
    /** Check the order settings before trying to redirect to the menu  */
    if (!orderType || !orderType.value) {
      sessionStorage.setItem(skin + "__featuredItemLink", link);
      history.push("/online-ordering/order-type");
    }
    //if order store is not selected redirect to order stores screen
    else if (!storeContext.activeOrderStore) {
      sessionStorage.setItem(skin + "__featuredItemLink", link);
      history.push("/online-ordering/order-store");
    } else if (
      orderTimeContext.value === null ||
      (orderTimeContext.value && orderTimeContext.value.value === null)
    ) {
      //if order time is not selected
      orderContext.openOrderTimePopupWidget(orderTimeContext);
    } else {
      //else redirect to menu
      const slideMenuPath = link;

      const categoryFromPath =
        slideMenuPath.split("/category/")[1] &&
        slideMenuPath.split("/category/")[1].split("/item/")[0];
      const subcategoryFromPath =
        slideMenuPath.split("/subcategory/")[1] &&
        slideMenuPath.split("/subcategory/")[1].split("/item/")[0];

      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: categoryFromPath,
        activeSubcategory: subcategoryFromPath,
        activeItem: slideMenuPath.split("/item/")[1],
        activeComboSubItem: slideMenuPath.split("/combo/")[1],
      });

      history.push({
        pathname: link,
        state: { from: "dashboard-card-slider" },
      });
    }
  }

  const onNewsFeedButtonClick = (link) => {
    if (link.includes("/menu")) {
      goToMenuItem(link);
    } else {
      history.push({
        pathname: link,
        state: { from: "dashboard-card-slider" },
      });
    }
  };

  return (
    <>
      {/** Desktop */}
      {deviceWidth >= 600 && (
        <li className="dashboard-news-feed__card">
          {slideData["image-src"] && (
            <div className="dashboard-news-feed__column">
              <div
                className="dashboard-news-feed__image-container"
                id={`${slideData.id + index}-image-container`}
              >
                <img src={slideData["image-src"]} alt={slideData["image-alt"]}></img>
              </div>
            </div>
          )}
          <div
            className="dashboard-news-feed__column news-feed-content"
            style={{
              width: slideData["image-src"] ? "50%" : "100%",
              padding: slideData["image-src"] ? "1em 1em" : "1em 1em",
              maxHeight: isShowingFullBlurb
                ? "unset"
                : document.getElementById(`${slideData.id + index}-image-container`)
                ? document.getElementById(`${slideData.id + index}-image-container`).clientHeight +
                  "px"
                : "",
            }}
          >
            <h3 className="dashboard-news-feed__title">{slideData["title"]}</h3>
            <div
              className="dashboard-news-feed-blurb-container"
              id={`dashboard-news-feed-blurb-container-${slideData.id}-${index}`}
            >
              <p
                className={`dashboard-news-feed__blurb ${
                  isShowingFullBlurb ? "dashboard-news-feed__blurb-expanded " : ""
                }`}
              >
                {slideData["blurb"]}

                {slideData["disclaimer"] && (
                  <>
                    <br />
                    <br />
                    <span className="dashboard-news-feed__disclaimer">
                      {slideData["disclaimer"]}
                    </span>
                  </>
                )}
              </p>
            </div>

            <div
              className={`dashboard-news-feed__button-conatainer ${
                isShowingFullBlurb && !hideReadMore ? "expanded" : ""
              } ${!slideData["image-src"] ? " no-image" : " with-image"}
              ${textLength > charLimit && !hideReadMore ? "with-read-more" : "without-read-more"}`}
              id={`${slideData.id + index}-button-container`}
            >
              {textLength > charLimit && !hideReadMore && (
                <button
                  className="dashboard-news-feed__read-more-button blue-link-button "
                  onClick={() => setIsShowingFullBlurb(!isShowingFullBlurb)}
                >
                  {isShowingFullBlurb
                    ? appLabels["order"]["read-less"]
                    : appLabels["order"]["read-more"]}
                </button>
              )}
              {slideData.isExternalLink ? (
                <a
                  href={slideData["button-link"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dashboard-news-feed__button button button--primary button--primary-with-border button--primary-small"
                  aria-label={slideData["image-alt"]}
                  style={{
                    minWidth: slideData["image-src"] ? "30%" : "15%",
                  }}
                >
                  {slideData["button-text"]}
                </a>
              ) : (
                <button
                  onClick={() => onNewsFeedButtonClick(slideData["button-link"])}
                  className="dashboard-news-feed__button button button--primary button--primary-with-border button--primary-small"
                  style={{
                    minWidth: slideData["image-src"] ? "30%" : "15%",
                  }}
                >
                  <span>{slideData["button-text"]}</span>
                </button>
              )}
            </div>
          </div>
        </li>
      )}

      {/** Mobile */}
      {deviceWidth < 600 && (
        <li className="dashboard-news-feed__card">
          {slideData["image-src"] && (
            <div className="dashboard-news-feed__image-container">
              <img src={slideData["image-src"]} alt={slideData["image-alt"]}></img>
            </div>
          )}

          <h3 className="dashboard-news-feed__title">{slideData["title"]}</h3>
          {slideData["blurb"] && (
            <div
              className="dashboard-news-feed-blurb-container"
              id={`dashboard-news-feed-blurb-container-${slideData.id}-${index}`}
            >
              <p
                className={`dashboard-news-feed__blurb ${
                  isShowingFullBlurb ? "dashboard-news-feed__blurb-expanded " : ""
                }`}
              >
                {slideData["blurb"]}

                {slideData["disclaimer"] && (
                  <>
                    <br />
                    <br />
                    <span className="dashboard-news-feed__disclaimer">
                      {slideData["disclaimer"]}
                    </span>
                  </>
                )}
              </p>
            </div>
          )}

          <div
            className={`dashboard-news-feed__button-conatainer ${
              !slideData["blurb"] && !slideData["disclaimer"]
                ? " no-content-button-container"
                : " with-content-button-container"
            }
            ${isShowingFullBlurb && !hideReadMore ? "expanded" : ""}
            ${!slideData["image-src"] ? " no-image" : " with-image"}
              ${textLength > charLimit && !hideReadMore ? "with-read-more" : "without-read-more"}
            `}
          >
            {textLength > charLimit && !hideReadMore && (
              <button
                className="dashboard-news-feed__read-more-button blue-link-button "
                onClick={() => setIsShowingFullBlurb(!isShowingFullBlurb)}
              >
                {isShowingFullBlurb
                  ? appLabels["order"]["read-less"]
                  : appLabels["order"]["read-more"]}
              </button>
            )}
            {slideData.isExternalLink ? (
              <a
                href={slideData["button-link"]}
                target="_blank"
                rel="noopener noreferrer"
                className="dashboard-news-feed__button button button--primary button--primary-with-border button--primary-small"
                aria-label={slideData["image-alt"]}
              >
                {slideData["button-text"]}
              </a>
            ) : (
              <button
                onClick={() => onNewsFeedButtonClick(slideData["button-link"])}
                className="dashboard-news-feed__button button button--primary button--primary-with-border button--primary-small"
              >
                <span>{slideData["button-text"]}</span>
              </button>
            )}
          </div>
        </li>
      )}
    </>
  );
};

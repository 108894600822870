import React from "react";

//UI Elements
import { DashboardNewsFeedCard } from "./DashboardNewsFeedCard";

//CSS
import "./DashboardNewsFeed.css";

export const DashboardNewsFeed = (props) => {
  const { slidesData } = props;

  return (
    <>
      {slidesData && slidesData.length > 0 && (
        <section className="dashboard__module dashboard__module--horizontal-slider dashboard__new-order-items-module dashboard-news-feed-section">
          <ul className="dashboard-news-feed__list-container ">
            {slidesData.map((slideData, index) => (
              <React.Fragment key={slideData.id + index}>
                {slideData["title"] && slideData["button-text"] && slideData["button-link"] && (
                  <DashboardNewsFeedCard slideData={slideData} index={index} />
                )}
              </React.Fragment>
            ))}
          </ul>
        </section>
      )}
    </>
  );
};

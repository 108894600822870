import { useEffect, useState } from "react";

export const useOutsideClickDetection = (navToggleRef, navRef) => {
  const [isClickOutside, setIsClickOutside] = useState(false);

  useEffect(() => {
    if (navToggleRef.current && navRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [navToggleRef.current, navRef.current]);

  function handleClickOutside(event) {
    if (!navToggleRef.current.contains(event.target) && !navRef.current.contains(event.target)) {
      setIsClickOutside(true);
    } else {
      setIsClickOutside(false);
    }
  }

  return isClickOutside;
};

export const getById = (id) => document.getElementById(id);
export const getEl = (selector) => document.querySelector(selector);
export const createEl = (id) => document.createElement(id);

// Convert a numeric value into dollar output
export const toDollars = (currencySymbol, currencySymbolSide, price, language) => {
  if (!price || price === "None") price = 0;
  if (currencySymbol === "") {
    currencySymbol = "$";
  }

  if (currencySymbolSide === "") {
    currencySymbolSide = "left";
  }

  const displayPrice = parseFloat(price).toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const isLeftCurrencySymbol = currencySymbolSide === "left";

  return isLeftCurrencySymbol ? currencySymbol + displayPrice : displayPrice + currencySymbol;
};

export const jsonCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const get = (o, path) => {
  const p = String(path).split(".");
  return p.reduce((xs, x) => (xs && typeof xs[x] !== "undefined" ? xs[x] : null), o);
};

export const getArray = (o, path) => {
  let obj;
  if (path) {
    const p = String(path).split(".");
    obj = p.reduce((xs, x) => (xs && typeof xs[x] !== "undefined" ? xs[x] : null), o);
  } else {
    obj = { ...o };
  }
  return obj ? Object.entries(obj) : [];
};

export const isArrItems = (arr) => Array.isArray(arr) && arr.length > 0;

// Convert array into an object with keys based on the specified keyField and optional prefix
export const arrayToObject = (array, keyField, prefix) =>
  array.reduce((obj, item) => {
    /*
      For top level groups use group id. If id doesn't exist it's a standalone addonGroup, so instead use its only child's addon id
    */
    if (prefix) {
      obj[
        (prefix === "item" ? "subcat-" + item.parentId + "-" : "") +
          prefix +
          "-" +
          (item[keyField] ? item[keyField] : item.items[Object.keys(item.items)[0]].id)
      ] = item;
    } else {
      obj[item[keyField]] = item;
    }
    return obj;
  }, {});

export const matchMedia = (media) => window.matchMedia(`(display-mode: ${media})`).matches;

export const formatOrderType = (orderTypeValue) => {
  let orderTypeInApiFormat;

  switch (orderTypeValue) {
    case "pickup":
      orderTypeInApiFormat = "TAKE_OUT";
      break;
    case "delivery":
      orderTypeInApiFormat = "DELIVERY";
      break;
    case "dinein":
      orderTypeInApiFormat = "DINEIN";
      break;
    default:
      return;
  }

  return orderTypeInApiFormat;
};

export const getCurrentTimestamp = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return timestamp;
};

// Returns the timestamp in the YYY-MM-DD hh:mm:ss format
export const getTimestamp = (time) => {
  const date = new Date(time.value ? time.value : time);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return timestamp;
};

export const hasNumber = (inputStr) => {
  return /\d/.test(inputStr);
};

export const getDeviceTypeId = (orderType) => {
  let deviceTypeId = "";

  if (orderType === "pickup" || orderType === "TAKE_OUT") {
    deviceTypeId = "20";
  } else if (orderType === "delivery" || orderType === "DELIVERY") {
    deviceTypeId = "21";
  } else if (orderType === "dinein" || orderType === "DINEIN") {
    deviceTypeId = "22";
  }
  return deviceTypeId;
};

export const objectToArray = (obj) => {
  if (obj === [] || !obj) {
    return [];
  }
  var result = Object.keys(obj).map(function (key) {
    return obj[key];
  });
  return result;
};

export const sortByKey = (array, key, isReverse = false) => {
  if (isReverse) {
    return array.sort(function (b, a) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  } else {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
};

export const findPos = (obj) => {
  var curtop = 0;
  var offset = 55;
  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return [curtop - offset];
  }
};

/** this function will return false if the store is closed all days of the week */
export const storeIsClosedAllWeek = (store) => {
  if (
    store.monday_hours === "" &&
    store.tuesday_hours === "" &&
    store.wednesday_hours === "" &&
    store.thursday_hours === "" &&
    store.friday_hours === "" &&
    store.saturday_hours === "" &&
    store.sunday_hours === ""
  ) {
    return true;
  } else {
    return false;
  }
};

export const getOrderTypeInOptionFormat = (orderTypeValue, orderLabels, isStadiumSchema) => {
  const option = {};

  switch (orderTypeValue) {
    case "pickup":
      option.value = "pickup";
      option.displayValue = orderLabels["pickup"];
      break;
    case "delivery":
      option.value = "delivery";
      option.displayValue = isStadiumSchema
        ? orderLabels["in-seat-service"]
        : orderLabels["delivery"];
      break;
    case "dinein":
      option.value = "dinein";
      option.displayValue = orderLabels["dine-in"];
      break;
    default:
      return;
  }

  return option;
};

export const getObjectDiff = (obj1, obj2, compareRef = false) => {
  var _ = require("lodash");
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && obj1[key] !== obj2[key]) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
};

export const parseURLParams = (url) => {
  var queryStart = url.indexOf("?") + 1,
    queryEnd = url.indexOf("#") + 1 || url.length + 1,
    query = url.slice(queryStart, queryEnd - 1),
    pairs = query.replace(/\+/g, " ").split("&"),
    parms = {},
    i,
    n,
    v,
    nv;

  if (query === url || query === "") return;

  for (i = 0; i < pairs.length; i++) {
    nv = pairs[i].split("=", 2);
    n = decodeURIComponent(nv[0]);
    v = decodeURIComponent(nv[1]);

    if (!parms.hasOwnProperty(n)) parms[n] = [];
    parms[n].push(nv.length === 2 ? v : null);
  }
  return parms;
};

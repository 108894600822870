import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";

import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import UserRoleContext from "../../App/UserRoleContext";

import { Form } from "../../_common/Form/Form";
import { FormInput } from "../../_common/Form/FormInput";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { onCheckout } from "../../_common/helpers/dataLayerHelpers";
import useWindowSize from "../../_common/hooks/useWindowSize";
import { onCheckoutXtreme } from "../../_common/helpers/xtremePushHelper";
import GCCartContext from "../GCCartContext";
import IndividualLoginButton from "../../OnlineOrdering/Cart/IndividualLoginButton";

export const GCCustomerInfo = () => {
  const merchantConfig = useContext(MerchantConfigContext);
  const cartData = useContext(GCCartContext);
  const userRoleContext = useContext(UserRoleContext);
  const [customerInfo, setCustomerInfo] = useState(null);
  useEffect(() => {
    if (merchantConfig.skin) {
      localforage.getItem(merchantConfig.skin + "__gcCustomerInfo").then((storedCustomerInfo) => {
        if (storedCustomerInfo) setCustomerInfo(storedCustomerInfo);
        else setCustomerInfo(true);
      });
    }
  }, [merchantConfig]);

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [isAPISubmitting, setAPISubmitting] = useState(false);

  const collectCustomerInfo = (formData) => {
    setAPISubmitting(true);
    let email;
    let phone;
    formData.forEach((field) => {
      if (field.name === "email") email = field.value;
      else if (field.name === "phone") phone = field.value.replace(/[- )(]/g, "");
    });

    let tempCustomerInfo = { ...customerInfo, email, phone };

    setCustomerInfo(tempCustomerInfo);

    localforage.setItem(merchantConfig.skin + "__gcCustomerInfo", tempCustomerInfo);

    onCheckout(cartData.value, 2);
    setIsAPISubmitValid(true);
    setAPISubmitting(false);
  };

  useEffect(() => {
    onCheckoutXtreme(cartData.value, 3, "", merchantConfig.skin);
  }, []);

  const appLabels = useContext(AppLabelsContext);
  useEffect(() => {

  }, []);

  const deviceWidth = useWindowSize().width;
  return (
    <>
      {userRoleContext.status === "guest" && (
        <IndividualLoginButton
          floatRight={true}
          customStyle={deviceWidth >= 660 ? { width: "70%" } : {}}
          noRightPadding={true}
        />
      )}
      <div className="customer-info__form-wrapper">
        <h1 className="customer-info__form-heading">
          {appLabels["order"]["customer-info-form-heading"]}
        </h1>
        {customerInfo && customerInfo != null ? (
          <Form
            id="form--gc-customer-info"
            submitButtonText={appLabels["order"]["order-contact-confirm"]}
            submitButtonSuccessText={appLabels["order"]["order-contact-confirming"]}
            submitForm={collectCustomerInfo}
            isAllowEmptySubmit={true}
            isAPISubmitValid={isAPISubmitValid}
            isAPISubmitting={isAPISubmitting}
            submitNavigateURL="/gift-card/payment"
            submitButtonCustomClass="button--primary"
            isBufferBeforeNavigationDisabled={true}
            submitButtonWrapperClass="button-container--single-relative"
          >

            <FormInput
              type="text"
              label={appLabels["form"]["email"]}
              placeholder="email@example.com"
              id="input--email"
              name="email"
              isRequired={true}
              defaultValue={customerInfo.email}
            />
            <FormInput
              type="tel"
              label={appLabels["form"]["phone"]}
              id="input--phone"
              name="phone"
              isRequired={true}
              defaultValue={customerInfo.phone}
              isMaskedInput={true}
              autocomplete="tel"
            />
          </Form>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </>
  );
};

export const generalLabels = {
  welcome: "Bienvenue",
  "loading-your-setup": "Téléchargement de votre configuration en cours",
  "good-morning": "Bonjour",
  "good-afternoon": "Bonjour",
  "good-evening": "Bonsoir",
  account: "Compte",
  "scan-in-store": "Scanner",
  "my-account": "Mon compte",
  rewards: "Récompenses",
  "gift-cards": "Cartes Cadeaux",
  order: "Commander",
  "order-now": "Commander maintenant",
  "find-store": "Trouver un établissement",
  "terms-and-conditions": "Conditions générales",
  maintenance_message:
    "Le présent site fait actuellement l'objet d'une maintenance. Nous serons bientôt de retour…",
  "cookie-message":
    "Le présent site fait appel à des témoins (de connexion) pour faciliter votre expérience de magasinage, analyser le trafic du site Web et ainsi accélérer votre utilisation de notre site. [link] pour en apprendre davantage à ce sujet. Vous acceptez nos témoins si vous continuez à utiliser notre site Web.",
  "cookie-message-link-text": "Visualiser notre politique",
  "remove-all-cart-items-confirmation-message":
    "Êtes-vous bien certain de vouloir retirer tous les articles de votre panier?",
  "dialog-modal-confirm": "Confirmer",
  "dialog-modal-cancel": "Annuler",
  "dialog-modal-ok": "OK",
  "expires-on": "Expire le",
  remove: "Retirer",
  "credit-card": "Carte de crédit",
  "gift-card": "Carte-cadeau",
  optional: "Facultatif",
  subtotal: "Sous-total",
  total: "Total",
  barcode: "Code-barres",
  "something-went-wrong-error": "Quelque chose n'a pas fonctionné. Veuillez réessayer plus tard.",
  "login-expired-modal-message":
    "Votre session a expiré. Nous vous redirigeons vers la page de connexion…",
  "add-to-home-screen-prompt": "Ajouter à l'écran d'accueil",
  "logging-you-out": "Nous mettons fin à votre session…",
  "disabled-geolocation-alert":
    "Afin de nous permettre de chercher les succursales les plus proches, veuillez activer le positionnement dans les paramètres de votre appareil, et permettre à notre application d'y accéder.",
  "renew-expired-session-confirmation": "Votre session a expiré, aimeriez-vous la renouveler?",
  "expired-session-logout-countdown": "Il sera mis fin à votre session dans [number] secondes",
  "your-last-visit": "Votre dernière visite",
  "new-version-message": "Une mise à jour est disponible. Veuillez accepter le rafraîchissement.",
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
  "default-loyalty-card": "Carte par défaut",
  "app-version": "Version [number]",
  "error-code": "Code d'erreur",
  "main-website": "Site Principal",
  applied: "Effectué",
  "go-back": "Retour",
  "close-dialog-modal": "Fermer la fenêtre de dialogue",
  "close-modal": "Fermer la fenêtre",
  close: "Fermer",
  "close-disclaimer": "Fermer la notice",
  "close-install-prompt": "Fermer le demande d'installation",
  hide: "Cacher",
  show: "Afficher",
  open: "Ouvrir",
  "language-dropdown": "menu déroulant de langue",
  "show-info-active-store": "Afficher les infos de la succursale en cours",
  "show-info-all-stores": "Afficher les infos de toutes les ssuccursale dans le rayon",
  "find-location-google-maps": "Trouver ma position actuelle danss Google Maps",
  "search-address": "Chercher l'adresse",
  "close-store-info": "Fermer les informations sur la succursale",
  options: "options",
  "change-barcode-type-to": "Changer le type de code-barre en [barcode-type]",
  "show-app-header-order-summary":
    "Afficher l'heure de la commande et les détails de l'emplacement",
  "hide-app-header-order-summary": "Cacher l'heure de la commande et les détails de l'emplacement",
  "language-dropdown-display-name": "Langue",
  done: "Terminé",
  january: "Janvier",
  february: "Février",
  march: "Mars",
  april: "Avril",
  may: "Mai",
  june: "Juin",
  july: "Juillet",
  august: "Août",
  september: "Septembre",
  october: "Octobre",
  november: "Novembre",
  december: "Décembre",
  "client-gift-card-website-label": "Carte-cadeau",
  skip: "Sauter",
  "go-to-cart": "Aller au panier",
  "subscribe-to-notifications": "Abonnez-vous aux notifications push",
  "xp-subscribe-to-notifications-header": "Ne manquez rien!",
  "xp-subscribe-to-notifications-message":
    "Autorisez [merchant-name] à vous envoyer des offres et des réductions exclusives.",
  select: "Sélectionner",
  "for-text": "pour",
  "go-to-dashboard": "Aller au tableau de bord",
  modify: "Modifier",
  checking: "Vérification",
  home: "Maison",
  "hide-menu": "Masquer le menu",
  resources: "Ressources",
  "go-to": "Aller à",
  "selected": "Choisir",
  "scroll-to-top": "Faites défiler vers le haut de la page.",
  "breadcrumb": "fil d'Ariane",
  "select-language": "Choisir la langue [language-name].",
};

import React from "react";
import "./Form.css";

export const FormRadios = (props) => {
  const { legend, extraLabel, fields, fieldData, name, onRadioChange, initialValue } = props;
  return (
    <fieldset className="form__field-wrapper">
      <legend className="form-heading">{legend}</legend>
      {!!extraLabel && extraLabel}
      {fields.map((field) => (
        <div
          key={field.label}
          className="form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--radio"
        >
          <input
            type="radio"
            value={field.value}
            defaultChecked={fieldData.checkedValue === field.value || initialValue === field.value}
            className="form__field--checkbox-radio form__field--radio visually-hidden"
            id={field.id}
            name={name}
            onChange={onRadioChange}
          />
          <label
            htmlFor={field.id}
            className="form__field-label--checkbox-radio form__field-label--radio"
          >
            <span>{field.label}</span>
            {field.price && <span className="menu-item__addon-price">{field.price}</span>}
          </label>
        </div>
      ))}
    </fieldset>
  );
};

FormRadios.displayName = "FormRadios";

// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";

// Helper functions
import { jsonCopy, toDollars } from "../_common/helpers";

// API helper functions
import { callAPI } from "../_common/Api";
import { getAPICardList } from "../OnlineOrdering/Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPICardList";
import { removeAPIAutoReload } from "../PayInStore/removeAPIAutoReload";
import {
  callAPI1002ListStoredAddresses,
  callAPI1017RemoveStoredAddress,
} from "./apiHelpers/userAddressHelpers";
import { getAPIAccountInfo } from "./apiHelpers/getAPIAccountInfo";

// Contexts
import AppSettingsContext from "../App/AppSettingsContext";
import AppLanguageContext from "../App/AppLanguageContext";
import AppLabelsContext from "../App/AppLabelsContext";
import BrandingContext from "../App/BrandingContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import UserRoleContext from "../App/UserRoleContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";

// UI components
import { LoadingSpinner } from "../_common/LoadingSpinner";
import { AppFooter } from "../App/AppFooter/AppFooter";
import { AddToWalletLink } from "./AddToWalletLink";

// Assets
import { ReactComponent as IconCard } from "../_common/icons/IconCard.svg";
import { ReactComponent as IconGift } from "../_common/icons/IconGift.svg";
import { ReactComponent as IconCheck } from "../_common/icons/IconCheck.svg";
import { ReactComponent as IconPlusCircle } from "../_common/icons/IconPlusCircle.svg";
import { ReactComponent as IconGoldStar } from "../_common/icons/IconGoldStar.svg";
import { ReactComponent as IconXInCircle } from "../_common/icons/IconXInCircle.svg";

// CSS
import "./PaymentMethods.css";

export const PaymentMethods = () => {
  const history = useHistory();
  const isUpdatingGCList = useRef(false);
  const isRemovingAddress = useRef(false);

  const deviceWidth = useWindowSize().width;

  const merchantConfigContext = useContext(MerchantConfigContext);
  const branding = useContext(BrandingContext);
  const userRoleContext = useContext(UserRoleContext);
  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);

  const [customerInfo, setCustomerInfo] = useState(null);
  const [storedMaskedCCInfo, setStoredMaskedCCInfo] = useState(null);
  const [giftCards, setGiftCards] = useState(null);
  const [checkedStoredGC, setCheckedStoredGC] = useState(false);
  const [checkedStoredCC, setCheckedStoredCC] = useState(false);
  const [updateData, setUpdateData] = useState(true);
  const [updateGCList, setUpdateGCList] = useState(true);
  const [checkAutoReload, setCheckAutoReload] = useState(null);

  const skin = merchantConfigContext.skin;
  const merchantVexilorConfig = merchantConfigContext.vexilor.I2;
  const paymentMethods = merchantVexilorConfig.payment_methods;
  const isLoyaltyEnabled = cws5ModuleContext.isLoyaltyEnabled;
  const isBalanceTransferEnabled = cws5ModuleContext.isBalanceTransferEnabled;
  const isAutoReloadEnabled = cws5ModuleContext.isAutoReloadEnabled;
  const isManualReloadEnabled = cws5ModuleContext.isManualReloadEnabled;
  const showAddMoneyButton = isAutoReloadEnabled || isManualReloadEnabled;

  const isAddToWalletServiceEnabled =
    merchantConfigContext.vexilor.I2["wallet_download_url"] === "t";
  const isGiftCardAutoReloadServiceEnabled =
    merchantConfigContext.vexilor.I2["cws_get_reload"] === "t";
  const secondaryColor = branding["secondary-colour"];
  const loginToken = userRoleContext.loginToken;
  const apiMerchantRewards = merchantConfigContext.merchant.I14;

  let reformattedRewards = [];
  apiMerchantRewards.forEach((apiReward) => {
    const reformattedReward = {
      id: apiReward[0],
      name: apiReward[1],
      description: apiReward[2],
      price: parseInt(apiReward[3]),
      image: apiReward[4] || false,
      typeCode: apiReward[5],
      typeName: apiReward[6],
    };

    reformattedRewards.push(reformattedReward);
  });
  const showCardPoints =
    !!reformattedRewards.filter((reward) => reward.typeCode === "6" || reward.typeCode === "7")
      .length && isLoyaltyEnabled;

  const reformattedMethods = [];
  Object.keys(paymentMethods).forEach((paymentMethod) => {
    if (paymentMethod === "CREDIT") {
      reformattedMethods.push({
        type: "credit-card",
        displayName: appLabels["general"]["credit-card"],
      });
    } else if (paymentMethod === "GIFTCARD") {
      reformattedMethods.push({
        type: "gift-card",
        displayName: appLabels["general"]["gift-card"],
      });
    }
  });

  useEffect(() => {
    if (loginToken === null) {
      history.push("/login-register");
    }
  }, [loginToken]);

  useEffect(() => {
    if (loginToken && updateData) {
      localforage.removeItem(skin + "__customerInfo");
      getAPIAccountInfo(skin, loginToken, appLanguage).then(async (apiAccountInfo) => {
        if (apiAccountInfo) {
          if (apiAccountInfo.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else if (!apiAccountInfo.error) {
            const accountInfo = await userRoleContext.updateAccountInfoInStorage({
              result: apiAccountInfo,
            });
            setCustomerInfo(accountInfo);
            if (
              accountInfo.storedCreditCard &&
              accountInfo.storedCreditCard.id &&
              accountInfo.storedCreditCard.maskedNumber &&
              accountInfo.storedCreditCard.expiry
            ) {
              setStoredMaskedCCInfo(accountInfo.storedCreditCard);
            } else {
              setStoredMaskedCCInfo(false);
            }

            setCheckedStoredCC(true);
          }
        }
      });

      setUpdateData(false);
    }
  }, [updateData]);

  useEffect(() => {
    if (loginToken && updateGCList) {
      if (isUpdatingGCList.current === true) {
        return;
      }
      isUpdatingGCList.current = true;
      getAPICardList(
        skin,
        loginToken,
        appSettings["currency-symbol"],
        appSettings["currency-symbols-side"],
        appLanguage,
        branding
      ).then((apiCardList) => {
        if (apiCardList) {
          isUpdatingGCList.current = false;
          if (apiCardList.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else if (apiCardList.error) {
            setGiftCards(false);
          } else {
            setGiftCards(apiCardList);
          }

          setCheckedStoredGC(true);
        } else if (apiCardList === undefined) {
          setCheckedStoredGC(true);
        }
      });
      setUpdateGCList(false);
      setCheckAutoReload(null);
    }
  }, [loginToken, updateGCList]);

  const removeCard = (card) => {
    setCheckedStoredCC(false);

    callAPI(skin, "dc_952", {
      params: [appLanguage, "952", "mqid", "mqpass", loginToken, "", card.iso, card.serial],
      id: "dc_952",
    })
      .then((data952) => {
        if (data952.result.I1 === "0") {
          setUpdateData(true);
          setUpdateGCList(true);
          setGiftCards(null);
        } else {
          console.error(data952);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeCreditCard = () => {
    callAPI(skin, "dc_1019", {
      params: [appLanguage, "1019", "mqid", "mqpass", loginToken, "", "t"],
      id: "dc_1019",
    })
      .then((data1019) => {
        if (data1019.result.I1 === "0") {
          //setStoredMaskedCCInfo(null);
          let tempCustomerInfo = jsonCopy(customerInfo);
          delete tempCustomerInfo.storedCreditCard;
          localforage.setItem(skin + "__customerInfo", tempCustomerInfo);
          //once the credit card is removed, call API 1017 to removed the stored billing address
          callAPI1002ListStoredAddresses(skin, loginToken, appLanguage, "stored_billing").then(
            (addresses) => {
              if (addresses) {
                if (addresses.status === "expiredLoginToken") {
                  userRoleContext.handleLoginTokenExpiration();
                } else {
                  const storedAddresses = addresses.storedAddresses;
                  if (storedAddresses.length === 0) {
                    setUpdateData(true);
                  } else {
                    storedAddresses.forEach((address, index) => {
                      const addressId = address[0];
                      const addressType = address[1];
                      if (addressType === "stored_billing") {
                        callAPI1017RemoveStoredAddress(
                          skin,
                          appLanguage,
                          loginToken,
                          addressId
                        ).then((removedAddress) => {
                          if (removedAddress) {
                            if (
                              removedAddress.addressRemoved &&
                              index === storedAddresses.length - 1
                            ) {
                              setTimeout(() => {
                                setUpdateData(true);
                              }, 500);
                            }
                          }
                        });
                      }
                    });
                  }
                }
              }
            }
          );
        } else if (data1019.status === 249 || data1019.status === 150) {
          userRoleContext.handleLoginTokenExpiration();
        } else {
          console.error(data1019);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (
      giftCards &&
      checkAutoReload === null &&
      isAutoReloadEnabled &&
      isGiftCardAutoReloadServiceEnabled
    ) {
      (async () => {
        if (isRemovingAddress.current === true) {
          return;
        }
        isRemovingAddress.current = true;
        const tempGiftCard = jsonCopy(giftCards);
        for (let i = 0; i < tempGiftCard.length; i++) {
          const giftcard = tempGiftCard[i];
          //Check and see if the select card already has an auto reload set

          const response1023 = await callAPI(skin, "dc_1023", {
            params: [
              appLanguage,
              "1023",
              "mqid",
              "mqpass",
              loginToken,
              "",
              giftcard.iso,
              giftcard.serial,
            ],
            id: "1023",
          });

          if (response1023) {
            isRemovingAddress.current = false;
            if (response1023.result.I1 === "0") {
              if (response1023.result.I5 === "") {
                //has no auto reload set
                giftcard.hasAutoReload = false;
              } else {
                giftcard.hasAutoReload = true;
              }
            } else if (response1023.status === 249 || response1023.status === 150) {
              userRoleContext.handleLoginTokenExpiration();
            } else {
              console.error(response1023.error);
            }

            if (i === tempGiftCard.length - 1) {
              setCheckAutoReload(false);
              setGiftCards(tempGiftCard);
            }
          }
        }
      })();
    }
  }, [giftCards, checkAutoReload, isAutoReloadEnabled, isGiftCardAutoReloadServiceEnabled]);

  const onAddMoneyClick = (card) => {
    sessionStorage.setItem(skin + "__activeLoyaltyCard", JSON.stringify(card));
    history.push("/pay-in-store/add-money#paymentMethods");
  };

  const removeAutoReload = (card) => {
    removeAPIAutoReload(skin, loginToken, card, appLanguage).then((apiAutoReloadRemovalResult) => {
      if (apiAutoReloadRemovalResult) {
        if (apiAutoReloadRemovalResult.status === "expiredLoginToken") {
          userRoleContext.handleLoginTokenExpiration();
        } else if (apiAutoReloadRemovalResult.isRemoved) {
          setUpdateData(true);
          setUpdateGCList(true);
        }
      }
    });
  };

  return (
    <>
      {deviceWidth >= 660 && customerInfo && (
        <section className="payment-methods-desktop-title">
          <div className="desktop-container">
            <h2>{appLabels["account"]["payment-methods"]}</h2>
            <p>{customerInfo.email}</p>
          </div>
        </section>
      )}
      <div className={deviceWidth >= 660 ? "desktop-container" : ""}>
        {checkedStoredCC && checkedStoredGC ? (
          <div className="payment-methods-container">
            {reformattedMethods &&
              reformattedMethods.map((paymentMethod, index) => (
                <section className="payment-method-section" key={index}>
                  {paymentMethod.type === "credit-card" ? (
                    <>
                      <header className="payment-method-section__header">
                        <IconCard aria-hidden="true" />
                        <h2 className="payment-method-section__heading">
                          {paymentMethod.displayName}
                        </h2>
                      </header>
                      <div
                        className={`payment-method-section__main payment-methods__stored-cc  ${
                          storedMaskedCCInfo ? "" : " payment-methods__stored-cc-empty"
                        }`}
                      >
                        {storedMaskedCCInfo ? (
                          <>
                            {deviceWidth >= 660 ? (
                              <div className="payment-method-section__details">
                                <div>
                                  <div className="payment-method-section__icon-and-number-wrapper">
                                    <IconCheck aria-hidden="true" />
                                    <span className="payment-method-section__number">
                                      {storedMaskedCCInfo.maskedNumber}
                                    </span>
                                  </div>
                                  <div className="payment-method-section__name-and-expiry-wrapper">
                                    {storedMaskedCCInfo.expiry && (
                                      <div>
                                        {appLabels["general"]["expires-on"]}
                                        {": "}
                                        {storedMaskedCCInfo.expiry}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <button
                                    onClick={() => removeCreditCard(storedMaskedCCInfo)}
                                    className="payment-methods__remove-button"
                                    style={{ color: secondaryColor }}
                                    type="button"
                                    aria-label={
                                      appLabels["general"]["remove"] +
                                        " " +
                                        appLabels["account"]["card"] +
                                        " " +
                                        !!storedMaskedCCInfo && storedMaskedCCInfo.maskedNumber
                                        ? storedMaskedCCInfo.maskedNumber
                                        : ""
                                    }
                                  >
                                    {appLabels["general"]["remove"]}
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="payment-method-section__details">
                                  <div className="payment-method-section__icon-and-number-wrapper">
                                    <IconCheck aria-hidden="true" />
                                    <span className="payment-method-section__number">
                                      {storedMaskedCCInfo.maskedNumber}
                                    </span>
                                  </div>
                                  <div className="payment-method-section__name-and-expiry-wrapper">
                                    {storedMaskedCCInfo.expiry && (
                                      <div>
                                        {appLabels["general"]["expires-on"]}
                                        {": "}
                                        {storedMaskedCCInfo.expiry}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <button
                                    onClick={() => removeCreditCard(storedMaskedCCInfo)}
                                    className="payment-methods__remove-button"
                                    style={{ color: secondaryColor }}
                                    type="button"
                                    aria-label={
                                      appLabels["general"]["remove"] +
                                        " " +
                                        appLabels["account"]["card"] +
                                        " " +
                                        !!storedMaskedCCInfo && storedMaskedCCInfo.maskedNumber
                                        ? storedMaskedCCInfo.maskedNumber
                                        : ""
                                    }
                                  >
                                    {appLabels["general"]["remove"]}
                                  </button>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="payment-method__cc-related-buttons-container payment-method-section__row">
                              <Link
                                to="/account/add-credit-card#paymentMethods"
                                className="payment-method-section__add-new"
                              >
                                <IconPlusCircle />
                                <span>{appLabels["account"]["add-credit-card"]}</span>
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                      {!storedMaskedCCInfo && <hr />}
                    </>
                  ) : (
                    <>
                      <header className="payment-method-section__header payment-method-section__header-gift-card">
                        <IconGift aria-hidden="true" />
                        <h2 className="payment-method-section__heading">
                          {paymentMethod.displayName}
                        </h2>
                      </header>

                      <div
                        className={` payment-method__gc-related-buttons-container payment-method-section__column`}
                      >
                        {isBalanceTransferEnabled && (
                          <>
                            <p className="payment-method__gc-transfer-funds-note">
                              {appLabels["account"]["transfer-funds-blurb"]}
                            </p>
                            <Link
                              to="/pay-in-store/transfer-funds#paymentmethod"
                              className="payment-method-section__transfer-funds"
                            >
                              <IconPlusCircle />
                              <span>{appLabels["account"]["transfer-funds"]}</span>
                            </Link>
                          </>
                        )}
                      </div>

                      <div className="payment-method-section__main payment-method-section__main-giftcard">
                        {giftCards && Array.isArray(giftCards) && (
                          <ul className="payment-method-section__gift-cards">
                            {giftCards.map((card, index) => (
                              <React.Fragment key={index}>
                                {deviceWidth >= 660 ? (
                                  <li
                                    className="payment-method-section__row payment-methods__stored-gc "
                                    key={index}
                                  >
                                    <div className="payment-method-section__details-wrapper">
                                      <div className="payment-method-section__details">
                                        <div className="payment-method-section__icon-and-number-wrapper">
                                          <IconCheck aria-hidden="true" />
                                          <span className="payment-method-section__number">
                                            {card.maskedNumber}
                                          </span>
                                        </div>
                                        <div className="payment-method-section__name-and-expiry-wrapper">
                                          {card.name && <div>{card.name}</div>}
                                          {card.expiry && (
                                            <div>
                                              {appLabels["general"]["expires-on"]}
                                              {": "}
                                              {card.expiry}
                                            </div>
                                          )}
                                          <div className="payment-method-section__balance">
                                            <span>
                                              {toDollars(
                                                appSettings["currency-symbol"],
                                                appSettings["currency-symbol-side"],
                                                card.balance,
                                                appLanguage
                                              )}
                                            </span>
                                            {showCardPoints && (
                                              <span>
                                                {card.points}
                                                <IconGoldStar aria-hidden="true" />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {index > 0 && (
                                        <button
                                          onClick={() => removeCard(card)}
                                          className="payment-methods__remove-button"
                                          style={{ color: secondaryColor }}
                                          type="button"
                                          aria-label={
                                            appLabels["general"]["remove"] +
                                              " " +
                                              appLabels["account"]["card"] +
                                              " " +
                                              !!storedMaskedCCInfo &&
                                            storedMaskedCCInfo.maskedNumber
                                              ? storedMaskedCCInfo.maskedNumber
                                              : ""
                                          }
                                        >
                                          {appLabels["general"]["remove"]}
                                        </button>
                                      )}
                                    </div>
                                    <div className="payment-method-section__details-gc-buttons">
                                      {showAddMoneyButton && (
                                        <button
                                          type="button"
                                          className="button button--primary button--primary-small button--primary-with-border"
                                          onClick={() => onAddMoneyClick(card)}
                                        >
                                          <span>{appLabels["account"]["add-money"]}</span>
                                        </button>
                                      )}
                                      {card.hasAutoReload && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            removeAutoReload(card);
                                          }}
                                        >
                                          <IconXInCircle aria-hidden="true" />
                                          <span>
                                            {appLabels["account"]["button-remove-autoreload"]}
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                ) : (
                                  <li
                                    className="payment-method-section__row payment-methods__stored-gc"
                                    key={index}
                                  >
                                    <div className="payment-method-section__details-wrapper">
                                      <div className="payment-method-section__details">
                                        <div className="payment-method-section__icon-and-number-wrapper">
                                          <IconCheck aria-hidden="true" />
                                          <span className="payment-method-section__number">
                                            {card.maskedNumber}
                                          </span>
                                        </div>
                                        <div className="payment-method-section__name-and-expiry-wrapper">
                                          {card.name && <div>{card.name}</div>}
                                          {card.expiry && (
                                            <div>
                                              {appLabels["general"]["expires-on"]}
                                              {": "}
                                              {card.expiry}
                                            </div>
                                          )}
                                          <div className="payment-method-section__balance">
                                            <span>
                                              {toDollars(
                                                appSettings["currency-symbol"],
                                                appSettings["currency-symbol-side"],
                                                card.balance,
                                                appLanguage
                                              )}
                                            </span>
                                            {showCardPoints && (
                                              <span>
                                                {card.points}
                                                <IconGoldStar aria-hidden="true" />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {index > 0 && (
                                        <button
                                          onClick={() => removeCard(card)}
                                          className="payment-methods__remove-button"
                                          style={{ color: secondaryColor }}
                                          type="button"
                                          aria-label={
                                            appLabels["general"]["remove"] +
                                              " " +
                                              appLabels["account"]["card"] +
                                              " " +
                                              !!storedMaskedCCInfo &&
                                            storedMaskedCCInfo.maskedNumber
                                              ? storedMaskedCCInfo.maskedNumber
                                              : ""
                                          }
                                        >
                                          {appLabels["general"]["remove"]}
                                        </button>
                                      )}
                                    </div>
                                    <div className="payment-method-section__details-gc-buttons">
                                      {showAddMoneyButton && (
                                        <button
                                          type="button"
                                          className="button button--primary button--primary-small button--primary-with-border"
                                          onClick={() => onAddMoneyClick(card)}
                                        >
                                          <span>{appLabels["account"]["add-money"]}</span>
                                        </button>
                                      )}
                                      {card.hasAutoReload && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            removeAutoReload(card);
                                          }}
                                        >
                                          <IconXInCircle aria-hidden="true" />
                                          <span>
                                            {appLabels["account"]["button-remove-autoreload"]}
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                    {isAddToWalletServiceEnabled && (
                                      <div className="payment-method-section__details-wallet-link-container">
                                        <AddToWalletLink
                                          activeLoyaltyCard={card}
                                          loginToken={loginToken}
                                        />
                                      </div>
                                    )}
                                  </li>
                                )}
                              </React.Fragment>
                            ))}
                          </ul>
                        )}
                      </div>
                    </>
                  )}
                </section>
              ))}
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
      {deviceWidth < 660 && <AppFooter />}
    </>
  );
};

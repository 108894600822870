import React, { useContext } from "react";
import "./GiftCardCarousel.css"
//import MerchantConfigContext from "../../App/MerchantConfigContext";
//import StoreContext from "../../OnlineOrdering/StoreContext";
import { useHistory } from 'react-router-dom';

import AppLabelsContext from '../App/AppLabelsContext';


export const GiftCardSlide = (props) => {
  const {
    giftCard,
    allGiftCards = null,
    isQuickSelected = false,
    selectedGiftCard = null,
    customClass = "",
    compactCarouselSlide = false,
  } = props;

  const imagePlaceholder = require("../_common/Images/EGiftCardImageUnavailable.png")
  const HOSTNAME = window.location.hostname;
  const IS_LOCALHOST =
    HOSTNAME === "localhost" ||
    (HOSTNAME.split(".").length === 4 && isNaN(Number(HOSTNAME.split("."))));
  let baseURL = !IS_LOCALHOST ? window.origin : "https://alpha-wwws.givex.com"
  const appLabels = useContext(AppLabelsContext)
  const history = useHistory()
  const selectGiftCard = (giftCard) => {
    history.push({
      pathname: "/gift-card/purchase/item/" + giftCard.id,
      state: {
        giftCard: giftCard,
        allGiftCards: allGiftCards
      }
    });
  }

  return (
    <li
      className={`dashboard-horizontal-slider__card giftCard_list-wrapper giftCard_card-ratio ${isQuickSelected && selectedGiftCard.id === giftCard.id
        ? "selectedGiftCard carouselGiftCardSelector"
        : ""
        } ${customClass} ${compactCarouselSlide ? "compact-carousel-slide-item" : "regular-carousel-slide-item"
        }`}>
      <div className="gcImageContainer">
        <button
          onClick={() => selectGiftCard(giftCard)}
          className="dashboard__slide-button giftCard-button"
          type="button"
          aria-label={appLabels["gift-card"]["select-gift-card"] + " " + giftCard.title}>
          <img
            src={
              giftCard.giftCardImageUrl && giftCard.giftCardImageUrl !== ""
                ? baseURL + giftCard.giftCardImageUrl
                : imagePlaceholder
            }
            alt={giftCard.title}
            className="dashboard__featured-item-img giftCard_slide"
          />
          {!giftCard.giftCardImageUrl && giftCard.giftCardImageUrl === "" && (
            <div
              alt={giftCard.title}
              className={`dashboard__featured-item-img giftCard_slide giftCardInvalidImageSlide ${compactCarouselSlide ? "giftCardInvalidImageSlideCompact" : ""
                }`}>
              <p>{appLabels["gift-card"]["gift-card-image-unavailable"]}</p>
            </div>
          )}
        </button>
      </div>
      <p className="gcCardTitle">{giftCard.title.length > 22 ? giftCard.title.substring(0, 22) + "..." : giftCard.title}</p>
    </li>
  );
};

//Libraries
import React, { useContext, useState, useEffect, useRef } from "react";
import localforage from "localforage";

//Context
import AppLabelsContext from "../../../App/AppLabelsContext";
import UserRoleContext from "../../../App/UserRoleContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";
import { MenuContext } from "../../../OnlineOrdering/Menu/MenuContext";

//UI elements
import { ReactComponent as IconHeart } from "../../icons/IconHeart.svg";
import { LoadingSpinner } from "../../LoadingSpinner";

//Helper functions
import {
  filterOptionGroupBasedOnSelection,
  formatCartForFavoritesApi,
  isTheSameAddonSelection,
  isTheSameModifierSelection,
  updateCustomerFavorite,
} from "../../../OnlineOrdering/Favorites/favoriteHelpers";
import { validateOptions } from "../../MenuHelpers";
import { jsonCopy, objectToArray } from "../../helpers";

import "./ButtonAddToFavorites.css";

export const ButtonAddToFavorites = (props) => {
  const {
    item,
    category,
    subcategory,
    customClassName,
    setIsGuestFavoriteAttempt,
    showingComboSummary,
    comboCartItem,
    setIsFavAttemptBeforeSelection,
    customStyle,
    buttonId,
  } = props;

  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const menuContext = useContext(MenuContext);
  const userRoleContext = useContext(UserRoleContext);
  const skin = useContext(MerchantConfigContext).skin;
  const loginToken = userRoleContext.loginToken;
  const isLoggedIn = userRoleContext.status === "logged-in";

  const [isFavorited, setIsFavorited] = useState(null);
  const [favoritedItemIndex, setFavoritedItemIndex] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const isGetting1327Data = useRef();
  const [isWaitingForAPIResponse, setIsWaitingForAPIResponse] = useState(false);

  //get the customer id from storage
  useEffect(() => {
    localforage.getItem(skin + "__customerID").then((storedCustomerID) => {
      if (storedCustomerID) setCustomerID(storedCustomerID);
    });
  }, []);

  // Check if item is a favorite item
  const [favorites, setFavorites] = useState([]);

  /** When this component renders and use is logged in, get user's favourites */
  useEffect(() => {
    if (customerID && isLoggedIn) {
      if (
        (item.type === "comboItem" && !showingComboSummary) ||
        isGetting1327Data.current === true
      ) {
        return;
      }
      isGetting1327Data.current = true;
      setIsWaitingForAPIResponse(true);
      updateCustomerFavorite(skin, appLanguage, loginToken, customerID, "get", []).then(
        (favoriteItems) => {
          setIsWaitingForAPIResponse(false);
          if (favoriteItems) {
            if (item.type !== "comboItem") {
              menuContext.updateFavorites(favoriteItems);
            }
            if (favoriteItems.length === 0) {
              setIsFavorited(false);
            }

            setFavorites(favoriteItems);
          }
          isGetting1327Data.current = false;
        }
      );
    }
  }, [customerID, isLoggedIn]);

  /**
   * Call API 1327 to get the list of favourite items already existing
   */
  useEffect(() => {
    if (item && favorites) {
      let BreakException = {};
      try {
        favorites.forEach((favItem, index) => {
          if (favItem.item_id === item.id) {
            //compare the items based on their addonGroups
            const favItemAddonGroups = favItem.addon_list;
            const itemAddonGroups = filterOptionGroupBasedOnSelection(
              objectToArray(item.addonGroups)
            );

            const favItemModifierGroups = favItem.modifier_list;
            const itemModifierGroups = filterOptionGroupBasedOnSelection(
              objectToArray(item.modifierGroups)
            );

            if (
              isTheSameAddonSelection(favItemAddonGroups, itemAddonGroups) &&
              isTheSameModifierSelection(favItemModifierGroups, itemModifierGroups)
            ) {
              if (!item.combo_child_items || item.combo_child_items.length === 0) {
                //if it's a regular item, break out of the loop
                setFavoritedItemIndex(index);
                setIsFavorited(true);
                throw BreakException;
              } else {
                //it's a combo item
                //check the combo child items
                let comboChildrenMatch = true;
                for (let i = 0; i < item.combo_child_items.length; i++) {
                  const favChildItem = favItem.combo_child_items[i];
                  const childItem = item.combo_child_items[i];

                  if (favChildItem && childItem.id === favChildItem.item_id) {
                    const favChildAddonGroups = favChildItem.addon_list;
                    const childItemAddonGroups = filterOptionGroupBasedOnSelection(
                      objectToArray(childItem.addonGroups)
                    );

                    const favChildModifierGroups = favChildItem.modifier_list;
                    const childItemModifierGroups = filterOptionGroupBasedOnSelection(
                      objectToArray(childItem.modifierGroups)
                    );

                    if (
                      !isTheSameAddonSelection(favChildAddonGroups, childItemAddonGroups) ||
                      !isTheSameModifierSelection(favChildModifierGroups, childItemModifierGroups)
                    ) {
                      comboChildrenMatch = false;
                    }
                  } else {
                    //one child item odes not match
                    comboChildrenMatch = false;
                    break;
                  }
                }

                if (comboChildrenMatch) {
                  setFavoritedItemIndex(index);
                  setIsFavorited(true);
                  throw BreakException;
                } else {
                  setIsFavorited(false);
                }
              }
            } else {
              setIsFavorited(false);
            }
          } else {
            setIsFavorited(false);
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    }
  }, [item, favorites]);

  const [isHeartAnimated, setIsHeartAnimated] = useState(false);
  const handleHeartClick = () => {
    if (isFavorited) removeFromFavorites();
    else addToFavorites();

    setIsHeartAnimated(true);
    setTimeout(() => setIsHeartAnimated(false), 500); // wait for animation to end
  };

  const addToFavorites = () => {
    if (!customerID || !loginToken) {
      //use is not logged-in
      setIsGuestFavoriteAttempt(true);
      return;
    }

    const { errors } = validateOptions(item, appLabels["form"]);
    if (errors === 0) {
      const updatedFavorites = favorites ? [...favorites] : [];
      const newFavoriteItem = {
        ...item,
        favoriteKey: new Date().getTime(),
        category: category || null,
        subcategory: subcategory,
      };
      updatedFavorites.push(newFavoriteItem);

      const comboItem = jsonCopy(item);
      comboItem.combo_child_items = comboCartItem ? comboCartItem.combo_child_items : [];

      setIsFavorited(true);
      setIsWaitingForAPIResponse(true);
      updateCustomerFavorite(
        skin,
        appLanguage,
        loginToken,
        customerID,
        "add",
        formatCartForFavoritesApi(
          item.type === "comboItem" ? [comboItem] : [item],
          category || "",
          subcategory
        )
      )
        .then((updatedFavorites) => {
          setIsWaitingForAPIResponse(false);
          if (updatedFavorites) {
            if (updatedFavorites.favItemAdded) {
              menuContext.updateFavorites(updatedFavorites.favItems);
              setFavorites(updatedFavorites.favItems);
            } else {
              if (updatedFavorites.errorCode === 453 || updatedFavorites.errorCode === 452) {
                // mandatory selection missing
                if (document.getElementById("button--submit-form")) {
                  document.getElementById("button--submit-form").click();
                }

                if (document.getElementsByClassName("FAB--add-to-order").length > 0) {
                  document.getElementsByClassName("FAB--add-to-order")[0].click();
                }
              }

              setIsFavorited(false);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      if (setIsFavAttemptBeforeSelection) {
        setIsFavAttemptBeforeSelection(true);
      }

      /** 
      // mandatory selection missing
      if (document.getElementById("button--submit-form")) {
        document.getElementById("button--submit-form").click();
      }

      if (document.getElementsByClassName("FAB--add-to-order").length > 0) {
        document.getElementsByClassName("FAB--add-to-order")[0].click();
      }
      **/
    }
  };

  function removeFromFavorites() {
    const updatedFavorites = favorites ? [...favorites] : [];
    updatedFavorites.splice(favoritedItemIndex, 1); // remove item from favorites
    setIsWaitingForAPIResponse(true);
    updateCustomerFavorite(skin, appLanguage, loginToken, customerID, "update", updatedFavorites)
      .then((updatedFavourites) => {
        setIsWaitingForAPIResponse(false);
        if (updatedFavourites) {
          menuContext.updateFavorites(updatedFavorites);
          setFavorites(updatedFavorites);
          setIsFavorited(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const removeAriaLabel = appLabels["order"]["remove-item-from-favorites"].replace(
    "[item-name]",
    item.name
  );

  const addAriaLabel = appLabels["order"]["add-item-to-favorites"].replace(
    "[item-name]",
    item.name
  );

  return (
    <>
      {(isFavorited === null && isLoggedIn && item.type !== "comboItem") ||
      isWaitingForAPIResponse ? (
        <LoadingSpinner
          customClass={`fav-button__loading-spinner ${customClassName ? customClassName : ""}`}
        />
      ) : (
        <button
          onClick={handleHeartClick}
          aria-label={isFavorited ? removeAriaLabel : addAriaLabel}
          style={customStyle ? customStyle : {}}
          type="button"
          className={customClassName}
          id={
            buttonId
              ? buttonId
              : window.location.href.includes("item") && !window.location.href.includes("combo")
              ? "item-details-page__fav-button"
              : ""
          }
        >
          <IconHeart
            className={isHeartAnimated ? "heart-icon--flicker" : ""}
            stroke="#FC8181"
            fill={isFavorited ? "#FC8181" : "#fff"}
          />
        </button>
      )}
    </>
  );
};

import React, { useContext, useEffect, useState } from "react";
import localforage from "localforage";

/** Contexts */
import BrandingContext from "../../../App/BrandingContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

/** Helpers */
import { getContrastColor } from "../../../_common/colorHelpers";

/** CSS */
import "./MenuComboProgressBar.css";
import AppLabelsContext from '../../../App/AppLabelsContext';

const MenuComboProgressBar = (props) => {
  const {
    comboSubgroupNumber,
    updateCurrentComboSubgroupNumber,
    appLabels,
    groupIsComplete,
    comboCartItem,
    setShowSummary,
    showSummary,
    currentSubgroupNumber,
    isQuantityCombo,
  } = props;

  const [isSummaryDisabled, setIsSummaryDisabled] = useState(false);
  return (
    <>
      <div className="progress-bar-container" style={showSummary ? { position: "unset" } : {}}>
        <ul className="progress-bar">
          {Array(comboSubgroupNumber)
            .fill(null)
            .map((value, index) => (
              <React.Fragment key={`ComboGroup${index + 1}`}>
                <MenuComboProgressBarItem
                  groupNumber={index + 1}
                  groupIsComplete={groupIsComplete}
                  updateCurrentComboSubgroupNumber={updateCurrentComboSubgroupNumber}
                  comboCartItem={comboCartItem}
                  setIsSummaryDisabled={setIsSummaryDisabled}
                  comboSubgroupNumber={comboSubgroupNumber}
                  currentSubgroupNumber={currentSubgroupNumber}
                  showSummary={showSummary}
                  isQuantityCombo={isQuantityCombo}
                />
              </React.Fragment>
            ))}
          <li
            className={`${
              groupIsComplete(comboSubgroupNumber) || showSummary ? "summary is-active " : ""
            }is-summary  ${isSummaryDisabled ? "progress-bar-item-disabled" : ""}`}
            key={`Summary-${Date.now()}`}
            data-key={`Summary-${Date.now()}`}
          >
            <button
              type="button"
              onClick={
                comboCartItem.combo_child_items.length >= comboSubgroupNumber
                  ? () => setShowSummary(true)
                  : () => {
                      return false;
                    }
              }
              disabled={isSummaryDisabled}
              aria-label={appLabels["order"]["go-to-combo-summary-page"]}
            >
              {appLabels["order"]["combo-summary"]}
            </button>
          </li>
        </ul>
      </div>
      <hr className="progress-bar-container_bottom_border" />
    </>
  );
};

const MenuComboProgressBarItem = (props) => {
  const {
    groupNumber,
    groupIsComplete,
    updateCurrentComboSubgroupNumber,
    comboCartItem,
    setIsSummaryDisabled,
    comboSubgroupNumber,
    currentSubgroupNumber,
    showSummary,
    isQuantityCombo,
  } = props;

  const branding = useContext(BrandingContext);
  const primaryColor = branding["primary-colour"];
  const contrastColor = getContrastColor(groupIsComplete(groupNumber) ? primaryColor : "#fffff");
  const skin = useContext(MerchantConfigContext).skin;
  const appLabels = useContext(AppLabelsContext)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (comboCartItem && comboCartItem.combo_child_items && currentSubgroupNumber !== groupNumber) {
      if (!isQuantityCombo) {
        localforage.removeItem(skin + "__activeItemInCustomizationModel");
      }
      //first sub group
      if (groupNumber === 1) {
        setIsButtonDisabled(!groupIsComplete(groupNumber));
      }
      // last sub group
      else if (groupNumber === comboSubgroupNumber) {
        let incompleteGroup = false;
        for (let i = groupNumber - 1; i > 0; i--) {
          if (!groupIsComplete(i)) {
            incompleteGroup = true;
            break;
          }
        }
        setIsButtonDisabled(incompleteGroup);
      } else {
        let incompleteGroup = false;
        for (let i = 1; i <= currentSubgroupNumber; i++) {
          if (!groupIsComplete(i)) {
            incompleteGroup = true;
            break;
          }
        }
        setIsButtonDisabled(incompleteGroup);
      }
    }
  }, [comboCartItem]);

  useEffect(() => {
    let allGroupsAreCompleted = true;
    for (let i = 1; i <= comboSubgroupNumber; i++) {
      if (!groupIsComplete(i)) {
        allGroupsAreCompleted = false;
        break;
      }
    }

    if (
      (isButtonDisabled || !groupIsComplete(groupNumber) || !allGroupsAreCompleted) &&
      isQuantityCombo
    ) {
      setIsSummaryDisabled(true);
    } else if (
      !isQuantityCombo &&
      (!groupIsComplete(currentSubgroupNumber) || !groupIsComplete(groupNumber))
    ) {
      setIsSummaryDisabled(true);
      setIsSummaryDisabled(true);
    } else {
      setIsSummaryDisabled(false);
    }
  }, [isButtonDisabled, currentSubgroupNumber, showSummary, comboCartItem]);

  return (
    <li
      className={`${groupNumber === 1 ? "is-active" : ""}  ${
        groupIsComplete(groupNumber) ? "is-complete" : "is-not-complete"
      } ${groupIsComplete(groupNumber - 1) ? "is-active" : ""}
      ${isButtonDisabled ? "progress-bar-item-disabled" : ""}`}
      data-key={`ComboGroup${groupNumber}`}
    >
      <button
        type="button"
        onClick={(e) => updateCurrentComboSubgroupNumber(groupNumber, e)}
        aria-label={appLabels["order"]["go-to-choice"] + " " + groupNumber}
        style={{
          color: contrastColor,
        }}
        disabled={isButtonDisabled}
      >
        {groupNumber}
      </button>
    </li>
  );
};

export default MenuComboProgressBar;

import React, { useContext } from "react";
import ReactDOM from "react-dom";

/** Contexts */
import BrandingContext from "../../../App/BrandingContext";

/** Helpers */
import { getContrastColor } from "../../../_common/colorHelpers";

const OptionGroupNextButton = (props) => {
  const { onClick, customClass, buttonText, container } = props;
  const branding = useContext(BrandingContext);
  const contrastTextColor = getContrastColor(branding["online-order-settings-background-colour"]);
  return ReactDOM.createPortal(
    <div
      className={` ${
        customClass
          ? customClass
          : "FAB-wrapper FAB-wrapper__item-details-page centered wizard show-add-to-order"
      }`}
    >
      <div className="desktop-container">
        <button
          onClick={onClick}
          id="button--submit-form"
          className="FAB button button-primary  FAB--add-to-order"
          style={{
            color: contrastTextColor,
            backgroundColor: "transparent",
            border: `2px solid ${contrastTextColor}`,
          }}
        >
          {buttonText}
        </button>
      </div>
    </div>,
    container ? container : document.body
  );
};
export default OptionGroupNextButton;

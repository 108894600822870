import { callAPI } from "../_common/Api";
import { getDateFormat } from "../_common/Form/helpers/formFieldFormattingHelpers";

export const updateAPIAccount = async (
  skin,
  updatedFields,
  language,
  activeOrderStore,
  allStoresContext
) => {
  const birthdate = updatedFields.birthdate.replace(/-/g, "/").split("/");
  let reformattedBirthdate;
  if (birthdate.length > 1) {
    if (birthdate[0] === "undefined") {
      reformattedBirthdate = "";
    } else {
      const birthdayFormat = getDateFormat(activeOrderStore, allStoresContext);
      const birthdayPlaceholder = birthdayFormat.placeholder;
      if (birthdayPlaceholder === "mm/dd/yyyy") {
        reformattedBirthdate = birthdate[2] + "-" + birthdate[0] + "-" + birthdate[1];
      } else {
        reformattedBirthdate = birthdate[2] + "-" + birthdate[1] + "-" + birthdate[0];
      }
    }
  } else {
    reformattedBirthdate = birthdate[0];
  }

  const api947Data = await callAPI(skin, "dc_947", {
    params: [
      language,
      "947",
      "mqid",
      "mqpass",
      updatedFields.currentLogin, //Customer Login
      updatedFields.newLogin, //customerNewLogin (Optional)
      updatedFields.password, //customerPassword
      updatedFields.title, //customerTitle
      updatedFields.firstName, //customerFirstName
      updatedFields.middleName, //customerMiddleName
      updatedFields.lastName, //customerLastName
      updatedFields.gender, //customerGender
      reformattedBirthdate, //customerBirthdate
      updatedFields.address1, //customerAddress 1
      updatedFields.address2, //customerAddress 2
      updatedFields.city, //customerCity
      updatedFields.province, //customerProvince
      "", //customerCounty
      updatedFields.country, //customerCountry
      updatedFields.postal, //customerPostalCode
      updatedFields.email, //customerEmail
      updatedFields.phone, //customerPhone
      "0", //customerDiscount
      updatedFields.promoOptInEmail === "yes" ? "t" : "f", //promotionOptIn
      "", //customerMobile
      "", //customerCompany
      "", //Message Type
      "", //Message Delivery Method
      updatedFields.promoOptInEmail === "yes" ? "t" : "f", //promotionOptInMail
      "", //Company ABN
      "", //Position
      "", //Company Industry
      "", //customerWaitingActivation
      "", //customerSocialLoginList
      "", //customerUnfollow
      updatedFields.cpfNumber, //Government ID
      "", //redemptionOptIn
      "", //promotionOptInPhone
      "", //customerAlternativeName
      "", //customerNationality
      "", //customerLangPref
      "", //customFields
      "", //T&C accepted
      "", //Return URL for Email Validation
      "", //Member Type
      updatedFields.deleteAccount, //Delete Account
    ],
    id: "947",
  });

  if (api947Data.result.I0) {
    return api947Data;
  } else {
    return { error: api947Data.result.message };
  }
};

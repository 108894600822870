import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

import { callAPI } from "../../_common/Api";

import AppLanguageContext from "../../App/AppLanguageContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";

import { Form } from "../../_common/Form/Form";
import { FormInput } from "../../_common/Form/FormInput";
import UserRoleContext from "../../App/UserRoleContext";

var AES = require("crypto-js/aes");

export const ChangeTemporaryPasswordForm = () => {
  const history = useHistory();

  const merchantConfig = useContext(MerchantConfigContext);
  const userRoleContext = useContext(UserRoleContext);

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [apiError, setAPIError] = useState("");
  const [isAPISubmitting, setAPISubmitting] = useState(false);
  const [submitNavigateURL, setSubmitNavigateURL] = useState(false);

  const appLabels = useContext(AppLabelsContext);

  useEffect(() => {
    if (!history.location.state) history.push("/login-with-temporary-password");
  }, []);

  const appLanguage = useContext(AppLanguageContext);

  const changeTemporaryPassword = (data) => {
    setAPISubmitting(true);

    let currentEmail;
    let currentPassword;
    let newPassword;

    if (history.location.state) {
      currentEmail = history.location.state.currentEmail;
      currentPassword = history.location.state.currentPassword;
    }

    data.forEach((field) => {
      if (field.name === "new-password") newPassword = field.value;
    });

    callAPI(merchantConfig.skin, "dc_953", {
      params: [appLanguage, "953", "mqid", "mqpass", currentEmail, currentPassword, newPassword],
      id: "953",
    }).then((data) => {
      if (data.result.I0) {
        // User successfully changed the temporary password
        setIsAPISubmitValid(true);
        // props.updateCurrentScreen("login");

        // Auto-login user with email and new password
        callAPI(merchantConfig.skin, "dc_958", {
          params: [appLanguage, "958", "mqid", "mqpass", currentEmail, newPassword, "t"],
          id: "958",
        }).then((data) => {
          if (data.result.I2) {
            if (data.result.I2 === "0") {
              // User successfully logged in
              setAPISubmitting(false);

              const storedLogin = JSON.parse(localStorage.getItem(merchantConfig.skin + "__login"));

              if (storedLogin) {
                localStorage.setItem(
                  merchantConfig.skin + "__login",
                  JSON.stringify({
                    email: currentEmail,
                    password: AES.encrypt(newPassword, "blurb").toString(),
                  })
                );
              }

              // Get user's first name for Dashboard
              callAPI(merchantConfig.skin, "dc_950", {
                params: [appLanguage, "950", "mqid", "mqpass", data.result.I4, ""],
                id: "950",
              }).then((data) => {
                if (data.result.I0) {
                  // Successfully retrieve user's first name
                  localforage.setItem(merchantConfig.skin + "__userFirstName", data.result.I7);
                }
              });

              // Navigate to Dashboard and set user status flags
              setSubmitNavigateURL("/dashboard");
              userRoleContext.updateStatus("logged-in");
              userRoleContext.updateLoginToken(data.result.I4);
              setIsAPISubmitValid(true);
            } else {
              // wxget: generic fallback API error
              setAPIError(appLabels["form"]["generic-fallback-api-error"]);
              setIsAPISubmitValid(false);
            }
          }
        });
      } else if (data.status === 150) {
        // User's new password is invalid due to backend validation
        setAPIError(data.result.message); // "Invalid customer login/password"
        // wxget: change password's incorrect current password error
        console.error("Current Password is incorrect"); // TEMP - for debugging
        // setAPIError("Current Password is incorrect");
      } else if (data.status === 184) {
        // User's entered temporary password doesn't exist
        setAPIError(data.result.message); // "Password is invalid"
        // wxget: change password's incorrect new password error
        console.error("New Password is invalid"); // TEMP - for debugging
        // setAPIError("New Password is invalid");
      } else if (data.status === 407) {
        setAPIError(data.result.message); // "New password must be different than existing password"
        // wxget: change password's incorrect new password error
      } else {
        // wxget: generic fallback API error
        setAPIError(appLabels["form"]["generic-fallback-api-error"]);
      }
      setIsAPISubmitValid(false);
    });
  };

  const resetAPIError = () => setAPIError("");

  return (
    <div className="form-wrapper login-register__form-wrapper new-password__form-wrapper">
      <Form
        id="form--change-password"
        submitButtonText={appLabels["account"]["update"]}
        submitButtonSuccessText={appLabels["account"]["updating"]}
        submitForm={changeTemporaryPassword}
        submitAPIError={apiError}
        resetAPIError={resetAPIError}
        isAPISubmitValid={isAPISubmitValid}
        isAPISubmitting={isAPISubmitting}
        submitNavigateURL={submitNavigateURL || false}
        submitNavigateURLStateFrom="login-register"
      >
        <FormInput
          type="password"
          label={appLabels["account"]["new-password"]}
          id="input--new-password"
          name="new-password"
          isRequired={true}
        />
        <FormInput
          type="password"
          label={appLabels["account"]["confirm-new-password"]}
          id="input--confirm-new-password"
          name="confirm-new-password"
          isRequired={true}
        />
      </Form>
    </div>
  );
};

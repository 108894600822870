import React, { useContext } from "react";
import BrandingContext from "../../../App/BrandingContext";
import AppLabelsContext from "../../../App/AppLabelsContext";

export const MenuCategoryFilterButton = ({ handleClick, categoryData, isActive }) => {
  const { id, name, parentId } = categoryData;
  const secondaryColor = useContext(BrandingContext)["secondary-colour"];
  const appLabels = useContext(AppLabelsContext);
  return (
    <li className="menu__category-filter-button-wrapper">
      <button
        onClick={() => handleClick(id, parentId)}
        className="menu__category-filter-button"
        style={{
          color: isActive ? "#fff" : secondaryColor,
          backgroundColor: isActive ? secondaryColor : "#fff",
        }}
        aria-label={isActive ? name + " is currently selected category" : "Select Category" + name}
        type="button"
      >
        {name.toLowerCase() === "featured" ? appLabels["order"]["featured"] : name}
      </button>
    </li>
  );
};

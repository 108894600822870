// Libraries
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

// Contexts
import UserRoleContext from "../UserRoleContext";
import AppSettingsContext from "../AppSettingsContext";
import MerchantConfigContext from "../MerchantConfigContext";
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";
import OrderTimeContext from "../../OnlineOrdering/OrderTimeContext";
import AppLabelsContext from "../AppLabelsContext";
import OrderContext from "../OrderContext";
import StaticPagesDataContextContext from "../contexts/StaticPagesDataContext";
import StoreContext from "../../OnlineOrdering/StoreContext";
import CWS5ModuleContext from "../CWS5ModuleContext";

// Hooks
import { useHideOrderModule } from "../useHideOrderModule";

// UI components
import { AppFooterItem } from "./AppFooterItem";

// Assets
import { ReactComponent as IconQR } from "../../_common/icons/IconQR.svg";
import { ReactComponent as IconStar } from "../../_common/icons/IconStar.svg";
import { ReactComponent as IconUtensils } from "../../_common/icons/IconUtensils.svg";
import { ReactComponent as IconGift } from "../../_common/icons/IconEGiftCard.svg";
import { ReactComponent as IconHouse } from "../../_common/icons/IconHouse.svg";

// CSS
import "./AppFooter.css";

export const AppFooter = () => {
  const history = useHistory();
  const { hideOrderModule } = useHideOrderModule();

  const appSettings = useContext(AppSettingsContext);
  const appLabels = useContext(AppLabelsContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const userRoleContext = useContext(UserRoleContext);
  const staticPagesData = useContext(StaticPagesDataContextContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const orderContext = useContext(OrderContext);
  const orderType = useContext(OrderTypeContext).value;
  const storeContext = useContext(StoreContext);

  const vexilorConfig = merchantConfigContext.vexilor.I2;
  const isLoyaltyModuleEnabled = cws5ModuleContext.isLoyaltyEnabled;
  const isOrderingModuleEnabled = cws5ModuleContext.isOnlineOrderingEnabled;
  const isGiftCardModuleEnabled = cws5ModuleContext.isGiftCardEnabled;
  const isScanInStoreModuleEnabled = cws5ModuleContext.isScanEnabled;

  const userStatus = userRoleContext.status;

  const [hideOrderButtonOnLanding, setHideOrderButtonOnLanding] = useState(false);
  const [navData, setNavData] = useState(null);

  useEffect(() => {
    if (vexilorConfig && storeContext && appSettings) {
      const isPayOnly = appSettings["is-pay-only"] === "yes";
      if (isPayOnly) {
        if (
          storeContext.activeOrderStore === null &&
          vexilorConfig.active_order_types === "dinein"
        ) {
          setHideOrderButtonOnLanding(true);
        } else {
          setHideOrderButtonOnLanding(false);
        }
      } else {
        setHideOrderButtonOnLanding(false);
      }
    }
  }, [vexilorConfig, storeContext, appSettings]);

  useEffect(() => {
    const location = history.location.pathname;

    const tempNavData = [];

    /** Home Icon */
    tempNavData.push({
      name: "home",
      displayName: appLabels["general"]["home"],
      icon: <IconHouse aria-hidden={true} />,
      isActive: location.includes("/dashboard"),
      isLink: true,
      linkPathname: "/dashboard",
    });

    if (isGiftCardModuleEnabled) {
      const giftCardNavDate = {
        name: "gift-card",
        ariaLabel: appLabels["gift-card"]["gift"],
        displayName: appLabels["gift-card"]["gift"],
        icon: <IconGift aria-hidden="true" />,
        isActive: location.includes("/gift-card"),
        isLink: true,
        linkPathname: userStatus === "logged-in" ? "/gift-card/purchase" : "/gift-card/purchase",
      };
      tempNavData.push(giftCardNavDate);
    }

    if (isScanInStoreModuleEnabled && userStatus === "logged-in") {
      tempNavData.push({
        name: "scan-in-store",
        displayName: appLabels["general"]["scan-in-store"],
        icon: <IconQR aria-hidden="true" />,
        isActive: location.includes("/pay-in-store"),
        isLink: true,
        linkPathname: "/pay-in-store",
      });
    }

    if (isLoyaltyModuleEnabled) {
      tempNavData.push({
        name: "loyalty",
        displayName: appLabels["loyalty"]["rewards"],
        icon: <IconStar aria-hidden="true" />,
        isActive: location.includes("rewards"),
        isLink: true,
        linkPathname: userStatus === "logged-in" ? "/my-rewards" : "/rewards",
      });
    }

    if (isOrderingModuleEnabled && !hideOrderModule && !hideOrderButtonOnLanding) {
      const orderNavData = {
        name: "order",
        ariaLabel: "order options",
        displayName: appLabels["general"]["order"],
        icon: <IconUtensils aria-hidden="true" />,
        isActive: location.includes("/online-ordering"),
        action: () => {
          orderContext.checkOrderSettings(orderType, storeContext, orderTimeContext);
        },
        isAction: true,
      };
      tempNavData.push(orderNavData);
    }
    setNavData(tempNavData);
  }, [
    orderTimeContext,
    userRoleContext,
    userStatus,
    staticPagesData,
    orderType,
    storeContext,
    hideOrderModule,
    hideOrderButtonOnLanding,
  ]);

  return (
    <>
      <footer className="footer">
        <nav>
          <ul className="footer__nav-list">
            {navData &&
              navData.map((module, index) => <AppFooterItem module={module} key={index} />)}
          </ul>
        </nav>
      </footer>
    </>
  );
};

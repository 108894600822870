/** Libraries */
import React, { useState, useContext } from "react";
import localforage from "localforage";

/** Custom hooks */
import useWindowSize from "../../../_common/hooks/useWindowSize";

/** Helper functions */
import { validateOptions } from "../../../_common/MenuHelpers";
import { dynamicSort } from "../../../_common/CartHelpers";
import { findPos } from "../../../_common/helpers";
import { consolidateAndSumQuantities } from "../helpers/orderingHelpers";

/** Contexts */
import AppLabelsContext from "../../../App/AppLabelsContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";

/** UI components */
import { FAB } from "../../../_common/buttons/FAB/FAB";
import BrandingContext from "../../../App/BrandingContext";
import { getContrastColor } from "../../../_common/colorHelpers";

export const MenuAddComboChoice = (props) => {
  const {
    item, //combo child item
    category, //category id
    subcategory, //subcategory id
    isOutOfStock,
    comboCartItem, // combo item object which will be added to the cart
    setShowingComboChildItem,
    isMenuDisplay,
    //comboParentItem, //main combo item
    //isThreeLevels,
    addonsOutOfStock,
    outOfStockError,
    numberOfRequiredChoices,
    addToOrderButtonText,
    isStepByStepOrderingEnabled,
    animationClass,
    isQuantityCombo,
    isRelativePositioning,
    relativeContainerId,
    isEditingComboItem,
    setShowSummary,
    useAddToOrderButtonText,
    subGroupQuantityReached,
    comboSubgroupNumber,
    groupIsComplete,
  } = props;

  const [areOptionsValid, setAreOptionsValid] = useState(null);
  const deviceWidth = useWindowSize().width;

  const skin = useContext(MerchantConfigContext).skin;
  //Add combo child item to the combo_child_items of the main combo item
  const addToChoice = () => {
    const { errors, idOfGroupWithErrors, idOfParentOfGroupWithErrors, idOfMainAddon } =
      validateOptions(item, appLabels["form"]);
    if (errors === 0) {
      const cartItem = { ...item };

      cartItem.category = category;
      cartItem.subcategory = subcategory;
      cartItem.quantity = item.quantity || 1;
      cartItem.entryKey = Date.now();

      //** add the addonQuantity key to the selected addon, this is required for correctly consolidate items when addon quantity is enabled */
      if (cartItem.addonGroups && Object.keys(cartItem.addonGroups).length > 0) {
        const addonGroups = cartItem.addonGroups;
        Object.keys(addonGroups).forEach((addonGroupKey) => {
          const addonGroup = addonGroups[addonGroupKey];
          const addonGroupItems = addonGroup.items;
          Object.keys(addonGroupItems).forEach((addonItemKey) => {
            const addon = addonGroupItems[addonItemKey];
            if (addon.isSelected) {
              addon.addonQuantity = Number(addon.quantity || 1);
            } else {
              delete addon.addonQuantity;
            }
          });
        });
      }

      if (comboCartItem.combo_child_items.length === 0) {
        comboCartItem.combo_child_items.push(cartItem);
        comboCartItem.combo_child_items.sort(dynamicSort("group_id"));
        comboCartItem.updateComboCartItem({ ...comboCartItem });
      } else {
        let removabaleElement = null;

        if (!isQuantityCombo) {
          //if the current subgroup already has an item, remove it and add the new one.
          comboCartItem.combo_child_items.forEach((element, index) => {
            if (element.group_id === cartItem.group_id) {
              removabaleElement = index;
            }
          });
        }

        if (removabaleElement !== null) {
          comboCartItem.combo_child_items.splice(removabaleElement, 1);
        }
        comboCartItem.combo_child_items.push(cartItem);
        comboCartItem.combo_child_items.sort(dynamicSort("group_id"));

        const listOfKeysToIgnore = [
          "quantity",
          "entryKey",
          "isFirstInstanceDefault",
          "positioningTemplateIndex",
          "type",
        ];

        comboCartItem.combo_child_items = consolidateAndSumQuantities(
          comboCartItem.combo_child_items,
          listOfKeysToIgnore
        );
        comboCartItem.updateComboCartItem({ ...comboCartItem });
      }

      if (setShowingComboChildItem) setShowingComboChildItem(false);
    } else {
      setAreOptionsValid(false);
      //TODO: simplify the validation mechanism
      setTimeout(() => setAreOptionsValid(null), 2000);

      if (deviceWidth < 660) {
        /** Scroll to the addon/modifier group with the id idOfGroupWithErrors */
        const containerWithError =
          document.getElementById(`${idOfGroupWithErrors}-header`) ||
          document.getElementById(
            `${idOfGroupWithErrors}_${idOfParentOfGroupWithErrors}_${idOfMainAddon}`
          );
        const groupContainerButton = containerWithError.querySelectorAll("button");
        const optionItemContainer = containerWithError.nextSibling;
        /** if the options groups is collapsed, expand it */
        if (
          optionItemContainer &&
          optionItemContainer.classList.value.includes("visually-hidden")
        ) {
          groupContainerButton[0].click();
        }

        if (document.getElementsByClassName("item-details-main__Wrapper").length > 0) {
          setTimeout(() => {
            document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
              top: findPos(containerWithError) - (deviceWidth >= 660 ? 140 : 180),
              behavior: "smooth",
            });
          }, 500);
        }

        let elementLabels;
        /** if nested addon/modifier group */
        if (
          containerWithError &&
          containerWithError.classList.value.includes("nested-addons-fieldset-wrapper")
        ) {
          elementLabels = containerWithError.querySelectorAll(`span`);
          let elementButtons = containerWithError.querySelectorAll("button");
          elementButtons.forEach((button) => {
            button.classList.add("error");
          });
        } else {
          //if regular addon modifier group
          if ((elementLabels = containerWithError.nextSibling)) {
            elementLabels = containerWithError.nextSibling.querySelectorAll(
              `.addons-modifiers-form .form__field-label--checkbox-radio`
            );
          }
        }
        /** Add the error class to each label element */
        if (elementLabels) {
          elementLabels.forEach((label) => {
            label.classList.add("error");
          });
        }
      } else {
        /** Scroll to the addon/modifier group with the id idOfGroupWithErrors */
        const containerWithError =
          document.getElementById(`${idOfGroupWithErrors}-header`) ||
          document.getElementById(
            `${idOfGroupWithErrors}_${idOfParentOfGroupWithErrors}_${idOfMainAddon}`
          );

        if (document.getElementsByClassName("item-details-main__Wrapper").length > 0) {
          setTimeout(() => {
            document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
              top: findPos(containerWithError) - (deviceWidth >= 660 ? 140 : 180),
              behavior: "smooth",
            });
          }, 500);
        }

        let elementLabels;
        /** if nested addon/modifier group */
        if (
          containerWithError &&
          containerWithError.classList.value.includes("nested-addons-fieldset-wrapper")
        ) {
          elementLabels = containerWithError.querySelectorAll(`label`);
          let elementButtons = containerWithError.querySelectorAll("button");
          elementButtons.forEach((button) => {
            button.classList.add("error");
          });
        } else {
          //if regular addon modifier group
          if ((elementLabels = containerWithError.nextSibling)) {
            elementLabels = containerWithError.nextSibling.querySelectorAll(
              `.form__field-label--checkbox-radio`
            );
          }
        }
        /** Add the error class to each label element */
        if (elementLabels) {
          elementLabels.forEach((label) => {
            label.classList.add("error");
          });
        }
      }
    }
  };

  const saveChanges = async () => {
    const { errors, idOfGroupWithErrors, idOfParentOfGroupWithErrors, idOfMainAddon } =
      validateOptions(item, appLabels["form"]);
    if (errors === 0) {
      const cartItem = { ...item };
      cartItem.category = category;
      cartItem.subcategory = subcategory;
      cartItem.quantity = item.quantity && item.quantity > 0 ? item.quantity : 1;

      //** add the addonQuantity key to the selected addon, this is required for correctly consolidate items when addon quantity is enabled */
      if (cartItem.addonGroups && Object.keys(cartItem.addonGroups).length > 0) {
        const addonGroups = cartItem.addonGroups;
        Object.keys(addonGroups).forEach((addonGroupKey) => {
          const addonGroup = addonGroups[addonGroupKey];
          const addonGroupItems = addonGroup.items;
          Object.keys(addonGroupItems).forEach((addonItemKey) => {
            const addon = addonGroupItems[addonItemKey];
            if (addon.isSelected) {
              addon.addonQuantity = Number(addon.quantity || 1);
            } else {
              delete addon.addonQuantity;
            }
          });
        });
      }

      const comboCartItemCopy = { ...comboCartItem };
      let removableIndex = null;
      var BreakException = {};
      try {
        comboCartItemCopy.combo_child_items.forEach((item, index) => {
          if (
            isQuantityCombo &&
            item.group_id === cartItem.group_id &&
            item.id === cartItem.id &&
            item.entryKey === cartItem.entryKey
          ) {
            removableIndex = index;
            throw BreakException;
          } else if (!isQuantityCombo && item.group_id === cartItem.group_id) {
            removableIndex = index;
            throw BreakException;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      cartItem.entryKey = Date.now();

      if (removableIndex !== null) {
        comboCartItemCopy.combo_child_items.splice(removableIndex, 1);
      }
      comboCartItemCopy.combo_child_items.push(cartItem);
      comboCartItemCopy.combo_child_items.sort(dynamicSort("group_id"));

      const listOfKeysToIgnore = [
        "quantity",
        "entryKey",
        "isFirstInstanceDefault",
        "positioningTemplateIndex",
        "type",
      ];

      const consolidatedList = consolidateAndSumQuantities(
        comboCartItemCopy.combo_child_items,
        listOfKeysToIgnore
      );
      comboCartItemCopy.combo_child_items = consolidatedList;
      comboCartItem.updateComboCartItem({ ...comboCartItemCopy });

      if (window.location.href.includes("customization-modal")) {
        await localforage.setItem(skin + "__activeItemInCustomizationModel", item.id);
      }

      if (setShowingComboChildItem) {
        setShowingComboChildItem(false);
      }

      if (isEditingComboItem) {
        let allGroupsReachedTheirMax = true;
        let allGroupsAreCompleted = true;
        if (isQuantityCombo) {
          comboCartItemCopy.combo_child_items.forEach((item) => {
            if (!subGroupQuantityReached(item.group_id)) {
              allGroupsReachedTheirMax = false;
            }
          });

          for (let i = 0; i < comboSubgroupNumber; i++) {
            if (!groupIsComplete(i + 1)) {
              allGroupsAreCompleted = false;
              break;
            }
          }

          if (comboCartItemCopy.combo_child_items.length < comboSubgroupNumber) {
            allGroupsReachedTheirMax = false;
          }
        } else {
          for (let i = 0; i < comboSubgroupNumber; i++) {
            if (!groupIsComplete(i + 1)) {
              allGroupsAreCompleted = false;
              break;
            }
          }
        }

        if (isQuantityCombo && allGroupsReachedTheirMax && allGroupsAreCompleted) {
          setShowSummary(true);
        } else if (!isQuantityCombo && allGroupsAreCompleted) {
          setShowSummary(true);
        } else {
          setShowSummary(false);
        }
      }
    } else {
      setAreOptionsValid(false);
      //TODO: simplify the validation mechanism
      setTimeout(() => setAreOptionsValid(null), 2000);

      /** Scroll to the addon/modifier group with the id idOfGroupWithErrors */
      const containerWithError =
        document.getElementById(`${idOfGroupWithErrors}-header`) ||
        document.getElementById(
          `${idOfGroupWithErrors}_${idOfParentOfGroupWithErrors}_${idOfMainAddon}`
        );
      const groupContainerButton = containerWithError.querySelectorAll("button");
      const optionItemContainer = containerWithError.nextSibling;
      /** if the options groups is collapsed, expand it */
      if (optionItemContainer && optionItemContainer.classList.value.includes("visually-hidden")) {
        groupContainerButton[0].click();
      }

      if (document.getElementsByClassName("item-details-main__Wrapper")[0]) {
        setTimeout(() => {
          document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
            top: findPos(containerWithError) - (deviceWidth >= 660 ? 140 : 180),
            behavior: "smooth",
          });
        }, 500);

        let elementLabels;
        /** if nested addon/modifier group */
        if (
          containerWithError &&
          containerWithError.classList.value.includes("nested-addons-fieldset-wrapper")
        ) {
          elementLabels = containerWithError.querySelectorAll(`span`);
          let elementButtons = containerWithError.querySelectorAll("button");
          elementButtons.forEach((button) => {
            button.classList.add("error");
          });
        } else {
          //if regular addon modifier group
          if ((elementLabels = containerWithError.nextSibling)) {
            elementLabels = containerWithError.nextSibling.querySelectorAll(
              `.addons-modifiers-form .form__field-label--checkbox-radio`
            );
          }
        }
        /** Add the error class to each label element */
        if (elementLabels) {
          elementLabels.forEach((label) => {
            label.classList.add("error");
          });
        }
      }
    }
  };

  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);
  const textIconColor = getContrastColor(branding["online-order-settings-background-colour"]);
  return (
    <>
      <FAB
        isAddItemType={true}
        isFormValid={areOptionsValid}
        buttonText={
          isMenuDisplay && !isEditingComboItem
            ? isStepByStepOrderingEnabled && numberOfRequiredChoices > 0 && useAddToOrderButtonText
              ? addToOrderButtonText
              : numberOfRequiredChoices > 0
              ? appLabels["order"]["number-of-required-choices"].replace(
                  "[number-of-choices]",
                  numberOfRequiredChoices
                )
              : `${
                  appLabels["order"]["button-add-to-combo-group-choice"].split("[item-group-id]")[0]
                }${item.group_id}`
            : appLabels["order"]["button-save-combo-choices"]
        }
        buttonSuccessText={
          isMenuDisplay && !isEditingComboItem
            ? appLabels["order"]["button-add-to-combo-group-choice-success"]
            : appLabels["order"]["button-save-combo-choices-success"]
        }
        formError={outOfStockError || appLabels["form"]["missing-required-addons-modifiers-error"]}
        onSubmit={isMenuDisplay && !isEditingComboItem ? addToChoice : saveChanges}
        isLeftAligned={false}
        isBackgroundGradient={true}
        isDisabled={isOutOfStock || (isStepByStepOrderingEnabled && numberOfRequiredChoices > 0)}
        isResetEnabled={!addonsOutOfStock}
        FABCustomClass={`FAB-wrapper__item-details-page FAB-wrapper__combo-add-to-choice wizard ${
          !isMenuDisplay || isEditingComboItem ? " FAB-wrapper__combo-save-changes" : ""
        }  ${animationClass ? animationClass : ""}`}
        customStyle={{
          border: `2px solid ${textIconColor}`,
          backgroundColor: branding["online-order-settings-background-colour"],
          color: textIconColor,
          opacity:
            isOutOfStock || (isStepByStepOrderingEnabled && numberOfRequiredChoices > 0) ? 0.5 : 1,
        }}
        isRelativePositioning={isRelativePositioning}
        relativeContainerId={relativeContainerId}
      />
    </>
  );
};

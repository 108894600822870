// Libraries
import React, { useState, useEffect, useContext } from "react";
import localforage from "localforage";
import { useHistory } from "react-router-dom";


// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helper functions
import {
  toDollars,
} from "../../_common/helpers";
import { isChromeBrowser, isSafariBrowser } from "../../_common/isSafariBrowser";
import { getOSPlatform } from "../../Account/AddToWalletLinkHelpers";

// Contexts
import AppLanguageContext from "../../App/AppLanguageContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import AppSettingsContext from "../../App/AppSettingsContext";

// UI components
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { PaymentMethod } from "../../OnlineOrdering/Payment/PaymentMethod/PaymentMethod";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { AdditionalDisclaimer } from "../../_common/AdditionalDisclaimer";
import { ReactComponent as IconCard } from "../../_common/icons/IconCard.svg";
import { ReactComponent as IconPaypal } from "../../_common/icons/IconPaypal.svg";
import { ReactComponent as IconGooglePay } from "../../_common/icons/IconGooglePay.svg";
import { ReactComponent as IconApplePay } from "../../_common/icons/IconApplePay.svg";

// CSS
import "../../OnlineOrdering/Payment/Payment.css";
import GCCartContext from "../GCCartContext";
import { getGiftCardCartSubTotal } from "../giftCardHelpers";
import { castToApiObject } from "../giftCardHelpers";
import { CallApi1013 } from "../CallAPI1013";
import UserRoleContext from "../../App/UserRoleContext";

const iconCard = <IconCard />;
const iconPaypal = <IconPaypal />;

export const GCPayment = (props) => {
  const { isSectionCollapsed, isOnBillPaymentPage } = props;
  const [isPaymentInfoReady, setIsPaymentInfoReady] = useState(false);

  const appSettings = useContext(AppSettingsContext);

  const history = useHistory();

  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext)

  const merchantConfig = useContext(MerchantConfigContext);
  const skin = merchantConfig.skin;

  const cart = useContext(GCCartContext);
  const platform = getOSPlatform();
  const isTimePickerEnabled = merchantConfig.merchant.I57 !== "" ? true : false

  //Get stored promo/rewards/coupon and calculate order total
  const [orderTotal, setOrderTotal] = useState(null);

  const deviceWidth = useWindowSize().width
  const [orderSummaryData, setOrderSummaryData] = useState()
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [creditCardTypes, setCreditCardTypes] = useState([]);
  const [debitCardTypes, setDebitCardTypes] = useState([]);
  const [exceededTotalAllowed, setExceededTotalAllowed] = useState(false) // used as flag to show dialog when total amount of gift cards is greater than allowed max
  const [formattedCartItems, setFormattedCartItems] = useState()
  const [storedGCPromoCode, setStoredGCPromoCode] = useState()

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const collapsedClass = isCollapsed ? "payment-methods--collapsed" : "";
  const cartContext = useContext(GCCartContext)
  const merchantConfigContext = useContext(MerchantConfigContext)
  const userRoleContext = useContext(UserRoleContext)
  const loginToken = userRoleContext.loginToken;

  const isIOSDevice = platform === "ios";
  const isAndroidDevice = platform === "android";
  const isChrome = isChromeBrowser();
  const isSafari = isSafariBrowser();
  const iconGooglePay = <IconGooglePay />;
  const iconApplePay = <IconApplePay />;

  /** check if the url contains any parameters regarding 3d secure */
  const [is3dSecureRejected, setIs3dSecureRejected] = useState(null);
  useEffect(() => {
    const url = window.location.href;
    const parameters = url.split("?");
    if (parameters.length > 1) {
      const paymentRejected = parameters[1].split("=")[1] === "rejected";
      setIs3dSecureRejected(paymentRejected);
    }

  }, []);

  useEffect(() => {

    if (cartContext.value) {
      setIsPaymentInfoReady(false)
      setOrderTotal(null)

      let maxTotalAmount = merchantConfigContext.merchant.I4[5]

      let formattedItems = []
      cartContext.value.forEach(gc => {

        let castedGiftCard = castToApiObject(gc, isTimePickerEnabled)
        formattedItems.push(castedGiftCard)
      })
      setFormattedCartItems(formattedItems)
      localforage.getItem(skin + "__gcOrderSummary").then((orderSummary) => {
        if (!orderSummary) {
          let orderSubTotal = 0
          orderSubTotal = getGiftCardCartSubTotal(cartContext.value)

          localforage.getItem(skin + "__gcStoredPromoCode").then((storedPromoCode) => {
            if (storedPromoCode) {
              setStoredGCPromoCode(storedPromoCode)
            }
            CallApi1013(
              skin,
              formattedItems,
              appLanguage,
              storedPromoCode,
              loginToken
            ).then((data1013) => {
              // for any reason data1013 returns status other than 0, return back to checkout page
              if (data1013.status !== 0) {
                history.push("/gift-card/checkout")
              } else
                if (data1013.result.I2) {

                  data1013.result.orderSubTotal = orderSubTotal
                  localforage.setItem(skin + "__gcOrderSummary", data1013.result);
                  let tmpOrderSummary = { "orderTotal": Number(data1013.result.I2), "taxTotal": 0, "orderSubTotal": orderSubTotal }
                  setOrderSummaryData(tmpOrderSummary)
                  setOrderTotal(tmpOrderSummary.orderTotal)
                  if (maxTotalAmount !== "" && Number(tmpOrderSummary.orderSubTotal) > maxTotalAmount) {
                    setExceededTotalAllowed(true)
                  }
                }
              setIsPaymentInfoReady(true)
            }).catch((error) => {
              setIsPaymentInfoReady(true)
              console.error(error)
            });
          })

        } else {
          let tmpOrderSummary = { "orderTotal": Number(orderSummary.I2), "taxTotal": 0, orderSubTotal: orderSummary.orderSubTotal }
          localforage.getItem(skin + "__gcStoredPromoCode").then((storedPromoCode) => {
            if (storedPromoCode) {
              setStoredGCPromoCode(storedPromoCode)
            }
          })
          if (maxTotalAmount !== "" && Number(tmpOrderSummary.orderSubTotal) > maxTotalAmount) {
            setExceededTotalAllowed(true)
          }

          setOrderSummaryData(tmpOrderSummary)
          setOrderTotal(tmpOrderSummary.orderTotal)
          setIsPaymentInfoReady(true)
        }
      })

    } else {
      history.push("/gift-card/purchase")
    }
  }, []);

  /** Calculate the final order amount for when dealing with bill payment  */
  useEffect(() => {
    if (isOnBillPaymentPage && orderSummaryData) {
      //get the data from localforage
      localforage.setItem(skin + "__gcFinalOrderTotal", orderSummaryData.orderTotal);
    }
  }, [orderSummaryData]);


  useEffect(() => {
    if (orderSummaryData) {
      setIsPaymentInfoReady(true);
    }
  }, [orderSummaryData]);

  useEffect(() => {
    if (orderTotal != null) {
      let tempMethods = [];
      let apiMethods = merchantConfig.merchant.I5;
      let tempCreditCardTypes = [];
      let tempDebitCardTypes = [];

      if (apiMethods && apiMethods.length > 0) {

        apiMethods.forEach(paymentMethod => {
          const method = {};
          method.type = paymentMethod.toLowerCase();

          if (method.type === "cc") {
            method.displayName = appLabels["general"]["credit-card"];
            method.icon = iconCard;
            method.type = "credit-card";

            // Store accepted CC types for icon display
            let ccTypes = merchantConfig.merchant.I6
            ccTypes.forEach((ccType) => {
              if (ccType === "MC") {
                tempCreditCardTypes.push("mastercard");
              } else {
                tempCreditCardTypes.push(ccType.toLowerCase());
              }
            });

          } else if (method.type === "paypal") {
            method.displayName = "PayPal";
            method.icon = iconPaypal;
            method.type = "paypal";
          }
          //add back later when api is enabled for apple pay and gpay
          else if (method.type === "applepay" && (isIOSDevice || isSafari)) {
            method.displayName = appLabels["order"]["apple-pay"];
            method.icon = iconApplePay;
            method.type = "apple-pay";
          } else if (method.type === "googlpay" && (isAndroidDevice || isChrome)) {
            method.displayName = appLabels["order"]["google-pay"];
            method.icon = iconGooglePay;
            method.type = "google-pay";
          }

          if (method.displayName) {
            tempMethods.push(method);
          }

        })
        setDebitCardTypes(tempDebitCardTypes);
        setPaymentMethods(tempMethods);
        setCreditCardTypes(tempCreditCardTypes);
        setIsApiLoading(false);
      }

    }

  }, [orderTotal]);


  return (
    <>
      {isPaymentInfoReady ? (
        <>

          <div
            className={`payment-content ${isSectionCollapsed
              ? " collapsable-section__collapsed-content"
              : " collapsable-section__expanded-content"
              }`}>
            {orderTotal !== null &&
              <div className="payment-summary">
                <div className="payment-summary__row">
                  <span className="payment-summary__label">
                    {appLabels["general"]["total"] + ":"}
                  </span>
                  <span className="payment-summary__output">
                    {toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      orderTotal,
                      appLanguage
                    )}
                  </span>
                </div>
              </div>
            }

            <AdditionalDisclaimer
              disclaimer={appLabels["order"]["payment-disclaimer"]}
              styleObject={
                deviceWidth < 660 ? { padding: "25px 25px 0px 25px" } : { margin: "2em auto" }
              }
            />
            {paymentMethods.length > 0 && (
              <div className="payment-methods-container">
                {!isApiLoading &&
                  paymentMethods.length > 0 ? (
                  <ul className={`payment-methods ${collapsedClass}`}>
                    {paymentMethods.map((method) => (
                      <PaymentMethod
                        key={method.type}
                        method={method}
                        creditCardTypes={creditCardTypes}
                        mealVoucherCardTypes={null}
                        debitCardTypes={debitCardTypes}
                        toggleCollapse={toggleCollapse}
                        orderTotal={orderTotal}
                        rewards={null}
                        promoCode={storedGCPromoCode}
                        coupon={null}
                        appLabels={appLabels}
                        cartData={cart}
                        skin={skin}
                        isGiftCardCheckout={true}
                        formattedCartItems={formattedCartItems}
                        isTimePickerEnabled={isTimePickerEnabled}
                      />
                    ))}
                  </ul>
                ) : (
                  <LoadingSpinner />
                )}
              </div>
            )}
          </div>

          {exceededTotalAllowed && (
            <DialogModal
              message={
                <div>
                  {appLabels["gift-card"]["exceeded-gift-card-amount-message"] + " " + toDollars(appSettings["currency-symbol"],
                    appSettings["currency-symbol-side"], merchantConfigContext.merchant.I4[5], appLanguage)}.
                  <br /> <br /> <span><b>{appLabels["gift-card"]["exceeded-gift-card-amount-remove-message"]}</b></span>
                </div>
              }
              resetRemoveDialog={() => history.push("/gift-card/checkout")}
              // isConfirmText={appLabels["general"]["dialog-modal-ok"]}

              confirmAction={() => history.push("/gift-card/checkout")}
              isHTMLContent={true}

            />
          )
          }
          {is3dSecureRejected && (
            <DialogModal
              message={appLabels["form"]["generic-fallback-api-error"]}
              resetRemoveDialog={() => {
                window.location.hash = "#/gift-card/payment";
                setIs3dSecureRejected(null);
              }}
            />
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

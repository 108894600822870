import React, { useContext } from "react";
import { ReactComponent as IconGift } from "../../_common/icons/IconEGiftCard.svg";
import AppLabelsContext from "../../App/AppLabelsContext";
import "./gcOrderHistory.css"
import useWindowSize from "../../_common/hooks/useWindowSize";
import { Link } from "react-router-dom";

export const GCNotLoggedIn = ({ skin }) => {
  const appLabels = useContext(AppLabelsContext);
  const deviceWidth = useWindowSize().width;

  function goToLoginRegisterPage() {
    sessionStorage.setItem(skin + "__lastVisitedLink", "/gift-card/recent");
  }

  return (
    <section className="recent-order__no-orders-message giftCardNotLoggedInMessage">
      <IconGift style={{ fill: "#e8e8e8", height: "95px", width: "95px" }} />
      <h3 className="recent-orders__no-orders-yet-label">{appLabels["order"]["no-orders-yet"]}</h3>
      <p className="recent-orders__browse-menu-label">{appLabels["gift-card"]["gift-card-recent-order-signed-out-message"]}</p>
      <Link
        className={`button recent-order__reorder-button button--pill-shaped buttonFilled ${deviceWidth < 660
          ? "recent-order-view-details-button"
          : "recent-order-view-details-button-desktop"
          }`}
        to={{
          pathname: `/login-register`,
        }}
        onClick={goToLoginRegisterPage}
      >
        {appLabels["login-register"]["sign-in"]} / {appLabels["login-register"]["register"]}
      </Link>

    </section >
  );
};

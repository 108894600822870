import localforage from "localforage";
import { callAPI } from "../../_common/Api";

export const CallApi1307 = async (
  skin,
  formattedCart,
  promo = "",
  orderType,
  currentLocation,
  rewardsList,
  language,
  orderTimestamp,
  deviceTypeId,
  isStadiumSchema = false
) => {
  let orderPostal = currentLocation.postal;
  if (isStadiumSchema) {
    localforage.getItem(skin + "__stadium-schema").then((schema) => {
      if (schema) {
        let parsedSchema = JSON.parse(schema);
        orderPostal = `${parsedSchema.section}/${parsedSchema.row}/${parsedSchema.seat}`;
      }
    });
  }

  const orderAddress = currentLocation.address;
  const orderCity = currentLocation.city;
  const orderProvince = currentLocation.province;
  const orderCountry = currentLocation.country;

  let customerId = "";
  await localforage.getItem(skin + "__customerID").then((id) => {
    if (id) {
      customerId = id;
    } else {
      customerId = "";
    }
  });

  return callAPI(skin, !rewardsList || rewardsList.length === 0 ? "info_vxl_1307" : "vxl_1307", {
    params: [
      language,
      "1307",
      "mqid",
      "mqpass",
      currentLocation.storeId, // Outlet ID
      orderType, // Order Type
      orderTimestamp, // Order Pickup Timestamp (optional)
      {
        customer_id: customerId, // customer_id - Customer ID
        order_email: "", // order_email - Customer e-mail
        order_fname: "", // order_fname - Customer First Name
        order_lname: "", // order_lname - Customer Last Name
        order_phone: "", // order_phone - Customer phone
        order_mobile: "", // order_mobile - Customer mobile
        order_address: orderAddress, // order_address - Customer address
        order_postal: orderPostal, // order_postal - Customer Postal Code
        order_city: encodeURI(orderCity), // order_city - Customer City
        order_prov: orderProvince, // order_prov - Customer Province/State
        order_country: orderCountry, // order_country - Customer Country
        discount_for_the_whole_order: promo, // discount_for_the_whole_order - discount UPC code to apply to the order
        redeem_rewards: rewardsList || "",
      }, // CustomerInfo
      formattedCart, // ItemsList
      "", //Delivery Instructions (optional)
      "", //Special Instructions (optional)
      null, //Order Delivery Reference (optional)
      null, //Driver Pickup Timestamp (optional)
      deviceTypeId || "", //Device Type (Optional)
    ],
    id: "1307",
  });
};

import localforage from "localforage";
import { callAPI } from "../../_common/Api";
import canadianProvinces from "../../_common/Form/data/CanadianProvinces.json";
import unitedStates from "../../_common/Form/data/UnitedStates.json";
import australianProvinces from "../../_common/Form/data/AustralianProvinces.json";
import brazillianProvinces from "../../_common/Form/data/BrazilianProvinces.json";
import mexicanStates from "../../_common/Form/data/MexicanStates.json";
import chineseProvinces from "../../_common/Form/data/ChineseProvinces.json";
import japaneseRegions from "../../_common/Form/data/JapaneseRegions.json";

/**
 * Formats an address object from an array into a more readable and usable format.
 *
 * @export
 * @param {Array} address - The address array containing address details.
 * @returns {Object} formattedAddress - The formatted address object.
 *
 * The `address` parameter array is expected to have the following structure:
 * - address[0] {string} : addressId - Unique identifier for the address.
 * - address[2] {boolean}: isDefaultAddress - Flag indicating whether this address is the default.
 * - address[5] {string} : address - The main address line (street, number, etc).
 * - address[6] {string} : address2 - The secondary address line (apartment, suite, etc).
 * - address[7] {string} : city - The city of the address.
 * - address[9] {string} : province - The province or state of the address.
 * - address[10] {string}: country - The country of the address.
 * - address[11] {string}: postal - The postal or ZIP code of the address.
 */
export const formatDeliveryAddress = (address) => {
  let formattedAddress = {
    addressId: address[0],
    isDefaultAddress: address[2],
    "first-address": address[5],
    "second-address": address[6],
    city: address[7],
    province: address[9],
    country: address[10],
    postal: address[11],
  };

  return formattedAddress;
};

/**
 * Handles the addition of a new address. If the new address is set to be primary, it also handles removing the current primary address.
 *
 * @export
 * @param {Object} newAddress - An object containing details of the new address.
 * @param {Array} addressList - An array of addresses, where each address is an array of details.
 * @param {Object} skin - An object containing skin/theme settings.
 * @param {string} appLanguage - The current language of the app.
 * @param {string} loginToken - The login token for the current session.
 * @returns {Promise} - Returns a Promise that, when fulfilled, will either add the new address or replace the primary address with the new address.
 */
export const handleNewAddressAddition = async (
  newAddress,
  addressList,
  skin,
  appLanguage,
  loginToken
) => {
  //Check if there are any addresses that are currently set as default
  let currentPrimaryAddress = null;
  for (let i = 0; i < addressList.length; i++) {
    let userAddress = addressList[i];
    if (userAddress[2] === "t") {
      currentPrimaryAddress = userAddress;
      break;
    }
  }
  const isPrimaryAddress = newAddress.isDefaultChecked;
  // if there is currently a primary address set, and the new address is to be primary
  // remove the current primary address before adding the new address
  if (currentPrimaryAddress && isPrimaryAddress) {
    //remove the current primary address
    const dataRemovePrimaryAddress = await removeCurrentPrimaryAddress(
      formatDeliveryAddress(currentPrimaryAddress),
      skin,
      appLanguage,
      loginToken
    );
    if (dataRemovePrimaryAddress) {
      if (dataRemovePrimaryAddress.addressUpdated) {
        //then
        return await addAddress(newAddress, skin, appLanguage, loginToken);
      }
    }
  } else {
    //if new address is not set to be primary
    return await addAddress(newAddress, skin, appLanguage, loginToken);
  }
};

/**
 * Adds a new address to the user's stored addresses.
 *
 * @export
 * @param {Object} address - An object containing details of the new address.
 * @param {Object} skin - An object containing skin/theme settings.
 * @param {string} appLanguage - The current language of the app.
 * @param {string} loginToken - The login token for the current session.
 * @returns {Promise} - Returns a Promise that, when fulfilled, will add the new address to the stored addresses.
 */
export const addAddress = async (address, skin, appLanguage, loginToken) => {
  return await callAPI1000AddStoredAddress(
    skin,
    appLanguage,
    loginToken,
    "stored_shipping",
    address["first-address"],
    address["second-address"],
    address.city,
    address.province,
    address.country,
    address.postal,
    address.isDefaultChecked ? "t" : "f"
  );
};

/**
 * Removes a stored address from the user's account.
 *
 * @export
 * @param {Object} skin - An object containing skin/theme settings.
 * @param {string} appLanguage - The current language of the app.
 * @param {string} loginToken - The login token for the current session.
 * @param {string} addressId - The unique identifier of the address to be removed.
 * @returns {Promise} - Returns a Promise that, when fulfilled, will remove the address with the specified id.
 */
export const removeAddress = async (skin, appLanguage, loginToken, addressId) => {
  return await callAPI1017RemoveStoredAddress(skin, appLanguage, loginToken, addressId);
};

/** Updates a stored address for the user.
 *
 * @export
 * @param {Object} skin - An object containing skin/theme settings.
 * @param {string} appLanguage - The current language of the app.
 * @param {string} loginToken - The login token for the current session.
 * @param {Object} address - An object containing the updated address details.
 * @returns {Promise} - Returns a Promise that, when fulfilled, will update the specified address with the new details.
 */
export const updateAddress = async (skin, appLanguage, loginToken, address) => {
  return await callAPI1001UpdateStoredAddress(
    skin,
    appLanguage,
    loginToken,
    address.addressId,
    "stored_shipping",
    address["first-address"],
    address["second-address"],
    address.city,
    address.province,
    address.country,
    address.postal,
    address.isDefaultChecked ? "t" : "f"
  );
};

/**
 * Removes the 'primary' status from the current primary address.
 *
 * @export
 * @param {Object} currentPrimaryAddress - An object containing details of the current primary address.
 * @param {Object} skin - An object containing skin/theme settings.
 * @param {string} appLanguage - The current language of the app.
 * @param {string} loginToken - The login token for the current session.
 * @returns {Promise} - Returns a Promise that, when fulfilled, will update the primary status of the current primary address.
 */
export const removeCurrentPrimaryAddress = async (
  currentPrimaryAddress,
  skin,
  appLanguage,
  loginToken
) => {
  return await callAPI1001UpdateStoredAddress(
    skin,
    appLanguage,
    loginToken,
    currentPrimaryAddress.addressId,
    "stored_shipping",
    currentPrimaryAddress["first-address"],
    currentPrimaryAddress["second-address"],
    currentPrimaryAddress.city,
    currentPrimaryAddress.province,
    currentPrimaryAddress.country,
    currentPrimaryAddress.postal,
    "f"
  );
};
/**
 * Sets a stored address as the primary address.
 *
 * @export
 * @param {Object} address - An object containing details of the address to be set as primary.
 * @param {Object} skin - An object containing skin/theme settings.
 * @param {string} appLanguage - The current language of the app.
 * @param {string} loginToken - The login token for the current session.
 * @returns {Promise} - Returns a Promise that, when fulfilled, will set the address as the primary address.
 */
export const setPrimaryAddress = async (address, skin, appLanguage, loginToken) => {
  return await callAPI1001UpdateStoredAddress(
    skin,
    appLanguage,
    loginToken,
    address.addressId,
    "stored_shipping",
    address["first-address"],
    address["second-address"],
    address.city,
    address.province,
    address.country,
    address.postal,
    "t"
  );
};

/**
 * Fetches all stored addresses for the user.
 *
 * @export
 * @param {Object} skin - An object containing skin/theme settings.
 * @param {string} loginToken - The login token for the current session.
 * @param {string} language - The current language of the app.
 * @param {string} addressTypeId - The identifier for the type of addresses to retrieve.
 * @returns {Promise} - Returns a Promise that, when fulfilled, will return an array of all stored addresses.
 */
export const getAllStoredAddresses = async (skin, loginToken, langauge, addressTypeId) => {
  return await callAPI1002ListStoredAddresses(skin, loginToken, langauge, addressTypeId);
};

/**
 * Handles setting an address as the primary address, including managing previous primary addresses.
 *
 * @export
 * @param {Array} addressList - An array of user address data, where each entry is an array of address details.
 * @param {Array} selectedAddress - An array of address details for the selected address to be set as primary.
 * @param {Object} skin - An object containing skin/theme settings.
 * @param {string} appLanguage - The current language of the app.
 * @param {string} loginToken - The login token for the current session.
 * @returns {Promise} - Returns a Promise that, when fulfilled, will either set the selected address as primary, remove the primary status from the current primary address, or both.
 */
export const handleSetPrimaryAddress = async (
  addressList,
  selectedAddress,
  skin,
  appLanguage,
  loginToken,
  performRemoveIfRequired = true
) => {
  let currentPrimaryAddress = null;
  for (let i = 0; i < addressList.length; i++) {
    let userAddress = addressList[i];
    if (userAddress[2] === "t") {
      currentPrimaryAddress = userAddress;
      break;
    }
  }

  //if there are no primary addresses
  if (currentPrimaryAddress === null) {
    //check if the selected address already exist in the address list but without the default flag
    const addressAlreadyExist = await addressAlreadyExistWithoutPrimaryFlag(selectedAddress, skin);
    if (!addressAlreadyExist.matchFound || !performRemoveIfRequired) {
      const dataSetPrimaryAddress = await setPrimaryAddress(
        Array.isArray(selectedAddress) ? formatDeliveryAddress(selectedAddress) : selectedAddress,
        skin,
        appLanguage,
        loginToken
      );
      if (dataSetPrimaryAddress) {
        return dataSetPrimaryAddress;
      }
    } else {
      if (performRemoveIfRequired) {
        return await removeAddress(skin, appLanguage, loginToken, addressAlreadyExist.matchedId);
      } else {
        return { addressUpdated: true };
      }
    }
  }
  // if the current default address is the same as selected address, then remove the default flag
  else if (
    currentPrimaryAddress &&
    (currentPrimaryAddress[0] === selectedAddress[0] ||
      currentPrimaryAddress[0] === selectedAddress["addressId"])
  ) {
    const dataRemovePrimaryAddress = await removeCurrentPrimaryAddress(
      formatDeliveryAddress(currentPrimaryAddress),
      skin,
      appLanguage,
      loginToken
    );
    if (dataRemovePrimaryAddress) {
      return dataRemovePrimaryAddress;
    }
  }
  // if there is currently a default address and user want to set another address as default
  else {
    const dataRemovePrimaryAddress = await removeCurrentPrimaryAddress(
      formatDeliveryAddress(currentPrimaryAddress),
      skin,
      appLanguage,
      loginToken
    );
    if (dataRemovePrimaryAddress) {
      if (dataRemovePrimaryAddress.addressUpdated) {
        return await setPrimaryAddress(
          Array.isArray(selectedAddress) ? formatDeliveryAddress(selectedAddress) : selectedAddress,
          skin,
          appLanguage,
          loginToken
        );
      } else {
        return dataRemovePrimaryAddress;
      }
    }
  }
};

/**
 * This function makes an API call to add a stored address for a customer.
 *
 * @param {string} skin - merchant skin name.
 * @param {string} appLanguage - The language code for the application's current language.
 * @param {string} loginToken - The customer's login token.
 * @param {string} addressTypeId - The type of address to be added (e.g., "stored_shipping", "stored_billing").
 * @param {string} address - The customer's address (street information).
 * @param {string} address2 - Apartment/unit number or additional address information.
 * @param {string} city - The city of the customer's address.
 * @param {string} province - The province or state of the customer's address.
 * @param {string} country - The country of the customer's address.
 * @param {string} postalCode - The postal code of the customer's address.
 * @param {boolean} defaultAddress - Flag to indicate if the address being added should be set as the default address.
 *
 * @returns {Promise<Object>} If successful, returns an object with 'addressAdded' set to true and 'addedAddressId' containing the ID of the added address.
 *                            If the API call is unsuccessful, returns an object with 'error' and 'statusCode' containing the error message and error code respectively.
 *                            If there is an exception in executing the function, returns an object with 'error' containing the exception details.
 */
export const callAPI1000AddStoredAddress = async (
  skin,
  appLanguage,
  loginToken,
  addressTypeId,
  address,
  address2,
  city,
  province,
  country,
  postalCode,
  defaultAddress
) => {
  try {
    // Making the API call with the provided parameters
    const api1000Data = await callAPI(skin, "dc_1000", {
      params: [
        appLanguage,
        "1000",
        "mqid",
        "mqpass",
        "customer",
        loginToken,
        "", // customer password
        addressTypeId,
        address,
        address2,
        city,
        getProvinceValueFromDisplayName(country, province),
        "", //county
        country,
        postalCode,
        "", //phone number
        "Delivery Address", //address description
        defaultAddress,
        "", //address name
        skin,
      ],
      id: "1000",
    });

    // Check if the API response has a successful status
    if (api1000Data.result.I0) {
      return { addressAdded: true, addedAddressId: api1000Data.result.I2 };
    } else if (api1000Data.status === 249 || api1000Data.status === 150) {
      return { status: "expiredLoginToken" };
    } else {
      // Logging the error message and error code
      console.error(
        `API 1000 | Error code ${api1000Data.error.code} | ${api1000Data.error.message}`
      );
      // Return error message and error code
      return { error: api1000Data.error.message, statusCode: api1000Data.error.code };
    }
  } catch (error) {
    // Log and return any exception thrown during execution
    console.error(error);
    return { error };
  }
};

/**
 * This function makes an API call to update a stored address for a customer.
 *
 * @param {string} skin - merchant skin name.
 * @param {string} appLanguage - The language code for the application's current language.
 * @param {string} loginToken - The customer's login token.
 * @param {string} addressId - The unique identifier for the address to be updated.
 * @param {string} addressTypeId - The type of the address to be updated (e.g., "stored_shipping", "stored_billing").
 * @param {string} address - The updated address.
 * @param {string} address2 - The updated apartment/unit numebr (if applicable).
 * @param {string} city - The updated city name.
 * @param {string} province - The updated province or state name.
 * @param {string} country - The updated country name.
 * @param {string} postalCode - The updated postal code.
 * @param {boolean} defaultAddress - A boolean indicating if this address should be set as the default ('t'/'f').
 *
 * @returns {Promise<Object>} If successful, returns an object with 'addressUpdated' set to true and 'updatedAddressId' containing the ID of the updated address.
 *                            If the API call is unsuccessful, returns an object with 'error' and 'statusCode' containing the error message and error code respectively.
 *                            If there is an exception in executing the function, returns an object with 'error' containing the exception details.
 */
export const callAPI1001UpdateStoredAddress = async (
  skin,
  appLanguage,
  loginToken,
  addressId,
  addressTypeId,
  address,
  address2,
  city,
  province,
  country,
  postalCode,
  defaultAddress
) => {
  try {
    // Making the API call with the provided parameters
    const api1001Data = await callAPI(skin, "dc_1001", {
      params: [
        appLanguage,
        "1001",
        "mqid",
        "mqpass",
        "customer",
        loginToken,
        "",
        addressId,
        addressTypeId, //customerAddressTypeId:  "stored_shipping", "stored_billing" or "favorite_store".
        address, //address
        address2, //address 2 - using address2 field for apartment.
        city, //city
        getProvinceValueFromDisplayName(country, province), //province
        "", //county
        country, //country
        postalCode, //postal code
        "", //phone
        "Delivery Address", //address description
        defaultAddress, //default address
        "", //address name
        skin,
      ],
      id: "1001",
    });

    // Check if the API response has a successful status
    if (api1001Data.result.I0) {
      //successful call
      return { addressUpdated: true, updatedAddressId: api1001Data.result.I2 };
    } else if (api1001Data.status === 249 || api1001Data.status === 150) {
      return { status: "expiredLoginToken" };
    } else {
      // Logging the error message and error code
      console.error(
        `API 1001 | Error code ${api1001Data.error.code} | ${api1001Data.error.message}`
      );
      // Return error message and error code
      return { error: api1001Data.error.message, statusCode: api1001Data.error.code };
    }
  } catch (error) {
    // Log and return any exception thrown during execution
    console.error(error);
    return { error };
  }
};

/**
 * This function makes an API call to retrieve a list of stored addresses for a customer.
 *
 * @param {string} skin - merchant skin name.
 * @param {string} loginToken - The customer's login token.
 * @param {string} language - The language code for the application's current language.
 * @param {string} addressTypeId - The type of addresses to be retrieved (e.g., "stored_shipping", "stored_billing").
 *
 * @returns {Promise<Object>} If successful, returns an object with 'customerId' containing the ID of the customer and 'storedAddresses' containing an array of the stored addresses.
 *                            If the API call is unsuccessful, returns an object with 'error' and 'statusCode' containing the error message and error code respectively.
 *                            If there is an exception in executing the function, returns an object with 'error' containing the exception details.
 */
export const callAPI1002ListStoredAddresses = async (skin, loginToken, language, addressTypeId) => {
  try {
    // Making the API call with the provided parameters
    const api1002Data = await callAPI(skin, "dc_1002", {
      params: [
        language, //language code
        "1002", //transaction code
        "mqid",
        "mqpass",
        loginToken, //customer login
        "", //customer password
        addressTypeId, //customerAddressTypeId:  "stored_shipping", "stored_billing" or "favorite_store".
        skin, //CWS SKIN (optional)
      ],
      id: "1002",
    });

    // Check if the API response has a successful status
    if (api1002Data.result.I0) {
      return { customerId: api1002Data.result.I2, storedAddresses: api1002Data.result.I3 };
    } else if (api1002Data.status === 249 || api1002Data.status === 150) {
      return { status: "expiredLoginToken" };
    } else if (api1002Data.error) {
      // Logging the error message and error code
      console.error(
        `API 1002 | Error code ${api1002Data.error.code} | ${api1002Data.error.message}`
      );
      // Return error message and error code
      return { error: api1002Data.error.message, statusCode: api1002Data.error.code };
    }
  } catch (error) {
    // Log and return any exception thrown during execution
    console.error(error);
    return { error };
  }
};

/**
 * This function makes an API call to remove a stored address for a customer.
 *
 * @param {string} skin - merchant skin name.
 * @param {string} appLanguage - The language code for the application's current language.
 * @param {string} loginToken - The customer's login token.
 * @param {string} customerAddressId - The unique identifier for the address to be removed.
 *
 * @returns {Promise<Object>} If successful, returns an object with 'addressRemoved' set to true and 'removedAddressId' containing the ID of the removed address.
 *                            If the API call is unsuccessful, returns an object with 'error' and 'statusCode' containing the error message and error code respectively.
 *                            If there is an exception in executing the function, returns an object with 'error' containing the exception details.
 */
export const callAPI1017RemoveStoredAddress = async (
  skin,
  appLanguage,
  loginToken,
  customerAddressId
) => {
  try {
    // Making the API call with the provided parameters
    const api1017Data = await callAPI(skin, "dc_1017", {
      params: [appLanguage, "1017", "mqid", "mqpass", loginToken, "", customerAddressId],
      id: "1017",
    });

    // Check if the API response has a successful status
    if (api1017Data.result.I0) {
      //successful call
      return { addressRemoved: true, removedAddressId: api1017Data.result.I2 };
    } else if (api1017Data.status === 249 || api1017Data.status === 150) {
      return { status: "expiredLoginToken" };
    } else {
      // Logging the error message and error code
      console.error(
        `API 1017 | Error code ${api1017Data.error.code} | ${api1017Data.error.message}`
      );
      // Return error message and error code
      return { error: api1017Data.error.message, statusCode: api1017Data.error.code };
    }
  } catch (error) {
    // Log and return any exception thrown during execution
    console.error(error);
    return { error };
  }
};

export const addressAlreadyExist = async (userEnteredAddress, skin) => {
  const addressList = await localforage.getItem(skin + "__userStoredAddresses");
  if (addressList) {
    const formattedAddressList = JSON.parse(addressList);
    let matchFound = false;
    formattedAddressList.forEach((deliveryAddress) => {
      let address = formatDeliveryAddress(deliveryAddress);
      if (
        address.city === userEnteredAddress.city &&
        ((address.isDefaultChecked &&
          address.isDefaultChecked === userEnteredAddress.isDefaultChecked) ||
          (address.isDefaultAddress &&
            address.isDefaultAddress === userEnteredAddress.isDefaultAddress) ||
          !address.isDefaultAddress) &&
        address["first-address"] === userEnteredAddress["first-address"] &&
        ((!!userEnteredAddress["postal"] && address["postal"] === userEnteredAddress["postal"]) ||
          (!userEnteredAddress["postal"] && address["postal"] !== userEnteredAddress["postal"])) &&
        ((!!address["second-address"] &&
          address["second-address"] === userEnteredAddress["second-address"]) ||
          (!address["second-address"] && !userEnteredAddress["second-address"])) &&
        address["country"] === userEnteredAddress["country"] &&
        ((!!userEnteredAddress["province"] &&
          (address["province"] === userEnteredAddress["province"] ||
            userEnteredAddress["province"] ===
              getProvinceDisplayNameFromValue(address["country"], address["province"]))) ||
          (!userEnteredAddress["province"] &&
            address["province"] !== userEnteredAddress["province"]))
      ) {
        matchFound = true;
      }
    });
    return matchFound;
  } else {
    return false;
  }
};

export const addressAlreadyExistWithoutPrimaryFlag = async (userEnteredAddress, skin) => {
  const addressList = await localforage.getItem(skin + "__userStoredAddresses");
  if (addressList) {
    const formattedAddressList = JSON.parse(addressList);
    let matchFound = false;
    let matchedId = "";
    formattedAddressList.forEach((deliveryAddress) => {
      let address = formatDeliveryAddress(deliveryAddress);
      if (
        address.city === userEnteredAddress.city &&
        address["first-address"] === userEnteredAddress["first-address"] &&
        ((!!userEnteredAddress["postal"] && address["postal"] === userEnteredAddress["postal"]) ||
          (!userEnteredAddress["postal"] && address["postal"] !== userEnteredAddress["postal"])) &&
        ((!!address["second-address"] &&
          address["second-address"] === userEnteredAddress["second-address"]) ||
          (!address["second-address"] && !userEnteredAddress["second-address"])) &&
        address["country"] === userEnteredAddress["country"] &&
        ((!!userEnteredAddress["province"] &&
          (address["province"] === userEnteredAddress["province"] ||
            userEnteredAddress["province"] ===
              getProvinceDisplayNameFromValue(address["country"], address["province"]))) ||
          (!userEnteredAddress["province"] &&
            address["province"] !== userEnteredAddress["province"]))
      ) {
        matchFound = true;
        matchedId = address["addressId"];
      }
    });
    return { matchFound, matchedId };
  } else {
    return { matchFound: false, matchedId: null };
  }
};

/**
 * Retrieves the index of a user entered address from an address list.
 *
 * This function compares each delivery address in the list with the user entered address
 * to find a match based on city, first-address, postal, second-address, country, and province.
 * If no match is found, the function will return an empty string.
 *
 * @param {Object} userEnteredAddress - The address input by the user.
 * @param {Array<Object>} addressList - List of delivery addresses.
 * @returns {number|string} Returns the index of the matched address in the list or an empty string if not found.
 */
export const getAddressIndex = (userEnteredAddress, addressList) => {
  let addressIndex = "";
  addressList.forEach((deliveryAddress, index) => {
    let address = formatDeliveryAddress(deliveryAddress);
    if (
      address.city === userEnteredAddress.city &&
      address["first-address"] === userEnteredAddress["first-address"] &&
      ((!!userEnteredAddress["postal"] && address["postal"] === userEnteredAddress["postal"]) ||
        (!userEnteredAddress["postal"] && address["postal"] !== userEnteredAddress["postal"])) &&
      ((!!address["second-address"] &&
        address["second-address"] === userEnteredAddress["second-address"]) ||
        (!address["second-address"] && !userEnteredAddress["second-address"])) &&
      address["country"] === userEnteredAddress["country"] &&
      ((!!userEnteredAddress["province"] &&
        (address["province"] === userEnteredAddress["province"] ||
          userEnteredAddress["province"] ===
            getProvinceDisplayNameFromValue(address["country"], address["province"]))) ||
        (!userEnteredAddress["province"] && address["province"] !== userEnteredAddress["province"]))
    ) {
      addressIndex = index;
    }
  });
  return addressIndex;
};

/**
 * Determines if the address from an API matches the address entered by the user.
 * @param {Object} apiAddressComponent - The address components as returned from an API.
 * @param {Object} userEnteredAddress - The address components as entered by a user.
 * @returns {boolean} Returns `true` if all components of the API address match the respective components of the user-entered address, `false` otherwise.
 */

export const apiAddressAndEnteredAddressAreSame = (apiAddressComponent, userEnteredAddress) => {
  if (
    apiAddressComponent.addressLines[0] === userEnteredAddress["first-address"] &&
    apiAddressComponent.administrativeArea === userEnteredAddress.province &&
    apiAddressComponent.locality === userEnteredAddress.city &&
    apiAddressComponent.postalCode === userEnteredAddress.postal &&
    apiAddressComponent.regionCode === userEnteredAddress.country
  ) {
    return true;
  } else {
    return false;
  }
};

export const getProvinceValueFromDisplayName = (countryCode, provinceDisplayName) => {
  let provinceList;
  if (countryCode === "CA") {
    provinceList = canadianProvinces.provinces;
  } else if (countryCode === "US") {
    provinceList = unitedStates.states;
  } else if (countryCode === "AU") {
    provinceList = australianProvinces.provinces;
  } else if (countryCode === "BR") {
    provinceList = brazillianProvinces.provinces;
  } else if (countryCode === "MX") {
    provinceList = mexicanStates.states;
  } else if (countryCode === "JP") {
    provinceList = japaneseRegions.regions;
  } else if (countryCode === "CN") {
    provinceList = chineseProvinces.provinces;
  } else {
    return provinceDisplayName;
  }

  let foundProvince = provinceList.find((p) => p.displayValue === provinceDisplayName);
  if (foundProvince) {
    return foundProvince.value;
  } else {
    // if no match were found, return the same value
    return provinceDisplayName;
  }
};

export const getProvinceDisplayNameFromValue = (countryCode, value) => {
  let provinceList;
  if (countryCode === "CA") {
    provinceList = canadianProvinces.provinces;
  } else if (countryCode === "US") {
    provinceList = unitedStates.states;
  } else if (countryCode === "AU") {
    provinceList = australianProvinces.provinces;
  } else if (countryCode === "BR") {
    provinceList = brazillianProvinces.provinces;
  } else if (countryCode === "MX") {
    provinceList = mexicanStates.states;
  } else if (countryCode === "JP") {
    provinceList = japaneseRegions.regions;
  } else if (countryCode === "CN") {
    provinceList = chineseProvinces.provinces;
  } else {
    return value;
  }

  let foundProvince = provinceList.find((p) => p.value === value);
  if (foundProvince) {
    return foundProvince.displayValue;
  } else {
    // if no match were found, return the same value
    return value;
  }
};

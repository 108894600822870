import React, { useRef, useEffect, useContext } from "react";
import AppLabelsContext from "../App/AppLabelsContext";
import { ReactComponent as IconStar } from "../_common/icons/IconStar.svg";

export const RewardsPointsItem = (props) => {
  const { isLast, reward, selectedCard, isActive, updateActiveReward, isFirst } = props;

  const appLabels = useContext(AppLabelsContext);
  const isRedeemable = selectedCard.points >= reward.price;
  const redeemableClass = isRedeemable ? " rewards-module__step--redeemable" : "";

  const activeClass = isActive ? " rewards-module__toggle-reward-name-button--active" : "";

  const ref = useRef();

  const handleClick = () => {
    updateActiveReward();
    ref.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
  };

  useEffect(() => {
    if (isFirst && isRedeemable) {
      handleClick();
    }
  }, [isFirst, isRedeemable]);

  return (
    <li className={`rewards-module__step${redeemableClass}`}>
      <span className="rewards-module__step-line" />
      <button
        ref={ref}
        onClick={handleClick}
        className={`button rewards-module__toggle-reward-name-button${activeClass}`}
        aria-label={appLabels["loyalty"]["show-reward-item"].replace('[reward-name]',reward.name)}
        type="button"
      >
        <IconStar />
        <span className="rewards-module__step-value">{reward.price}</span>
      </button>
      {!isLast && <span className="rewards-module__step-line" />}
      {isActive && <span className="rewards-module__step-name">{reward.name}</span>}
    </li>
  );
};

import React from "react";

export const OrderTypeSpecialInstruction = (props) => {
  const {
    specialInstructions,
    updateSpecialInstructions,
    label,
    placeholder,
    additionalInformationText,
  } = props;

  return (
    <div className="special-instructions-wrapper order-level__special-instructions">
      <label htmlFor="special-instructions" className="form__field-label">
        {label}
      </label>
      {!!additionalInformationText && (
        <p className="order-type-special-instructions__additional-information">
          {additionalInformationText}
        </p>
      )}
      <textarea
        onChange={(e) => updateSpecialInstructions(e.target.value)}
        placeholder={placeholder}
        rows="5"
        value={specialInstructions}
        className="order-type-special-instructions__field"
        id="special-instructions"
      ></textarea>
    </div>
  );
};

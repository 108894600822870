// Libraries
import React, { useState, useContext, useRef, useEffect } from "react";
import he from "he";
// Custom hooks
import { useModal } from "../../_common/Modal/useModal";

// Helper functions
import { jsonCopy, objectToArray, sortByKey, toDollars } from "../../_common/helpers";
import { getItemModifiersTotal, getItemQuantityFromCart } from "../../_common/CartHelpers";
import { formatItems, listFavItemOptions, hasTheSameOptionSelections } from "./favoriteHelpers";
import {
  getfavItemInfoFromMenu,
  sortObjectKeysAlphabetically,
} from "../Menu/helpers/orderingHelpers";
import { onAddProductToCartXtreme } from "../../_common/helpers/xtremePushHelper";

// Contexts
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import { MenuContext } from "../Menu/MenuContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";

// UI components
import { Modal } from "../../_common/Modal/Modal";
import { isValidItemQuantity } from "../../_common/MenuHelpers";
import { LoadingSpinner } from "../../_common/LoadingSpinner";

export const FavoriteItem = (props) => {
  const {
    item,
    menu,
    removeFavorite,
    itemIndex,
    branding,
    setItemNotAvailable,
    cartContext,
    setIsQuantityValid,
    key,
  } = props;

  const { isModal, toggleModal, closeAnimation } = useModal();
  const optionsTotal = getItemModifiersTotal(item);
  const cart = cartContext.value || [];
  const appLabels = useContext(AppLabelsContext);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const orderMenu = useContext(MenuContext);

  const { skin } = useContext(MerchantConfigContext);

  const [addToOrderText, setAddToOrderText] = useState(appLabels["order"]["favorite-add-to-order"]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const imagePlaceholder = branding["no-image-available-placeholder"];

  const [isLoading, setIsLoading] = useState(false);

  const isAddonQuantityEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";
  const isCallingAPI = useRef(false);

  const findCatSubcatId = (allItemsList, itemInSearch, menuLevel) => {
    const itemInSearchParentId = itemInSearch.parentId;
    const itemInSearchId = itemInSearch.id;
    let catId = "";
    let subCatId = "";
    if (menuLevel === "threeLevels") {
      Object.keys(allItemsList).forEach((catKey) => {
        const category = allItemsList[catKey];
        const subcategories = category.subcategories;
        Object.keys(subcategories).forEach((subCatKey) => {
          const subcategory = subcategories[subCatKey];
          const items = subcategory.items;
          Object.keys(items).forEach((itemKey) => {
            const item = items[itemKey];
            if (item.id === itemInSearchId && item.parentId === itemInSearchParentId) {
              catId = category.id;
              subCatId = subcategory.id;
            }
          });
        });
      });
    } else {
      Object.keys(allItemsList).forEach((subCatKey) => {
        const subcategory = allItemsList[subCatKey];
        const items = subcategory.items;
        Object.keys(items).forEach((itemKey) => {
          const item = items[itemKey];
          if (item.id === itemInSearchId && item.parentId === itemInSearchParentId) {
            subCatId = subcategory.id;
          }
        });
      });
    }
    return { catId, subCatId };
  };

  const addComboItemToCart = async (favItem, comboItem, categoryId = "", subcategoryId = "") => {
    if (isCallingAPI.current === true) {
      return;
    }

    comboItem.combo_child_items = [];

    if (!isValidItemQuantity(cart, comboItem)) {
      setIsQuantityValid(false);
      return;
    }

    setIsLoading(true);
    isCallingAPI.current = true;

    const menuLevel = orderMenu.menuLevel;

    const catSubCatIds = findCatSubcatId(orderMenu.apiData, comboItem, menuLevel);
    let comboData = [];
    let catIdToUse = catSubCatIds.catId || item.category || categoryId;
    let subCatIdToUse = catSubCatIds.subCatId || item.subcategory || subcategoryId;

    if (menuLevel === "twoLevels") {
      comboData =
        orderMenu.apiData["subcategory-" + subCatIdToUse].items["item-" + comboItem.id]
          .combo_child_items;
    } else if (menuLevel === "threeLevels") {
      comboData =
        orderMenu.apiData["category-" + catIdToUse].subcategories["subcategory-" + subCatIdToUse]
          .items["item-" + comboItem.id].combo_child_items;
    }

    const comboChildItems = formatItems(comboData);
    //loop through the comboChild items and compare to combo_child items of favitem
    comboChildItems.forEach((childItem) => {
      const addonGroups = childItem.addonGroups;
      const modifierGroups = childItem.modifierGroups;
      const favItemComboChildren = favItem.combo_child_items.filter(
        (item) =>
          item.item_id === childItem.id && item.item_components_group_id === childItem.group_id
      );

      favItemComboChildren.forEach((favItemComboChild, index) => {
        if (favItemComboChild) {
          favItemComboChild.name = he.decode(favItemComboChild.name);
          //check the modifier selection
          if (Object.keys(modifierGroups).length > 0) {
            Object.keys(modifierGroups).forEach((modifierGroupKey) => {
              const modifierGroup = modifierGroups[modifierGroupKey];
              if (
                favItemComboChild &&
                favItemComboChild.modifier_list.filter(
                  (group) => group.modifier_group_id === modifierGroup.id
                ).length > 0
              ) {
                //there is a selection for the modifier group
                const favItemModifierGroup = favItemComboChild.modifier_list.filter(
                  (group) => group.modifier_group_id === modifierGroup.id
                );
                const modifiers = modifierGroup.items;
                Object.keys(modifiers).forEach((modifierKey) => {
                  const modifier = modifiers[modifierKey];
                  delete modifier.isSelected;
                  if (
                    favItemModifierGroup.filter((option) => option.item_id === modifier.id).length >
                    0
                  ) {
                    modifier.isSelected = true;
                  }
                });
              }
            });
          }

          //check the addon selection
          if (Object.keys(addonGroups).length > 0) {
            Object.keys(addonGroups).forEach((addonGroupKey) => {
              const addonGroup = addonGroups[addonGroupKey];
              if (
                favItemComboChild &&
                favItemComboChild.addon_list.filter((group) =>
                  group.addon_group_id !== ""
                    ? group.addon_group_id === addonGroup.id
                    : group.item_id === addonGroupKey.split("-")[1]
                ).length > 0
              ) {
                //there is a selection for this addon group
                const favItemAddonGroup = favItemComboChild.addon_list.filter((group) =>
                  group.addon_group_id !== ""
                    ? group.addon_group_id === addonGroup.id
                    : group.item_id === addonGroupKey.split("-")[1]
                );
                const addons = addonGroup.items;
                Object.keys(addons).forEach((addonKey) => {
                  const addon = addons[addonKey];
                  delete addon.addonQuantity;
                  delete addon.isSelected;
                  if (
                    favItemAddonGroup.filter((option) => option.item_id === addon.id).length > 0
                  ) {
                    // If combo item is not a quantity combo
                    if (!favItemComboChild.hasOwnProperty("groupMinQty")) {
                      favItemComboChild.addon_list.map((favItemAddon) => {
                        if (favItemAddon.item_id === addon.id) {
                          favItemAddon.addon_group_id = addon.addon_group_id;
                        }
                        return favItemAddon;
                      });
                    }

                    addon.isSelected = true;
                    let addonQuantity = 1;
                    if (
                      favItemAddonGroup.find(
                        (tempAddon) => tempAddon.item_id === addonKey.replace("addon-", "")
                      )
                    ) {
                      addonQuantity =
                        favItemAddonGroup.find(
                          (tempAddon) => tempAddon.item_id === addonKey.replace("addon-", "")
                        ).quantity || 1;
                    }
                    addon.quantity = addonQuantity;
                    addon.addonQuantity = addon.quantity;
                    //checked the nested modifiers
                    const nestedModifierGroups = addon.modifierGroups;
                    Object.keys(nestedModifierGroups).forEach((nestedModifierGroupKey) => {
                      const nestedModGroup = nestedModifierGroups[nestedModifierGroupKey];
                      favItemAddonGroup.forEach((favAddonGroup) => {
                        if (
                          favAddonGroup.modifier_list.filter(
                            (option) => option.modifier_group_id === nestedModGroup.id
                          ).length > 0
                        ) {
                          const favItemNestedModGroups = favAddonGroup.modifier_list.filter(
                            (option) => option.modifier_group_id === nestedModGroup.id
                          );
                          const nestedMods = nestedModGroup.items;
                          Object.keys(nestedMods).forEach((nestedModKey) => {
                            const nestedModifier = nestedMods[nestedModKey];
                            delete nestedModifier.isSelected;
                            if (
                              favItemNestedModGroups.filter(
                                (option) => option.item_id === nestedModifier.id
                              ).length > 0
                            ) {
                              nestedModifier.isSelected = true;
                            }
                          });
                        }
                      });
                    });
                  }
                });
              }
            });
          }

          const favItemComboChildCopy = jsonCopy(favItemComboChild);
          const lookUpItem = jsonCopy(favItemComboChild);
          const comboChildItemsCopy = jsonCopy(comboChildItems);

          if (
            !!comboChildItemsCopy.find(
              (item) =>
                item.id === favItemComboChild.item_id &&
                item.group_id === favItemComboChild.item_components_group_id &&
                hasTheSameOptionSelections(lookUpItem, item, "addon")
            )
          ) {
            let foundAddonGroups = jsonCopy(
              comboChildItemsCopy.find(
                (item) =>
                  item.id === favItemComboChild.item_id &&
                  item.group_id === favItemComboChild.item_components_group_id &&
                  hasTheSameOptionSelections(lookUpItem, item, "addon")
              ).addonGroups
            );
            let filteredAddonGroups = {};

            //foundAddonGroups comes from combo data
            Object.keys(foundAddonGroups).forEach((addonGroupKey) => {
              const addonGroup = foundAddonGroups[addonGroupKey];
              const addonItems = addonGroup.items;

              //favItemComboChildCopy comes from the fav item
              //we want to check and only add the addon groups that are in the fav item

              if (favItemComboChildCopy.addon_list && favItemComboChildCopy.addon_list.length > 0) {
                favItemComboChildCopy.addon_list.forEach((favAddon) => {
                  if (
                    ((favAddon.addon_group_id === addonGroup.id && !!addonGroup.id) ||
                      !addonGroup.id) &&
                    objectToArray(addonItems).filter((item) => item.id === favAddon.item_id)
                      .length > 0
                  ) {
                    filteredAddonGroups[addonGroupKey] = addonGroup;
                    //check the nested modifiers
                    if (favAddon.modifier_list && favAddon.modifier_list.length > 0) {
                      Object.keys(addonGroup.items).forEach((addonKey) => {
                        const addon = addonGroup.items[addonKey];
                        const nestedModifierGroups = addon.modifierGroups;
                        if (Object.keys(nestedModifierGroups).length === 0) {
                          filteredAddonGroups[addonGroupKey].items[addonKey].modifierGroups = {};
                        } else {
                          Object.keys(nestedModifierGroups).forEach((nestedModifierGroupKey) => {
                            const nestedModGroup = nestedModifierGroups[nestedModifierGroupKey];
                            const nestedModItems = nestedModGroup.items;
                            favAddon.modifier_list.forEach((favMod) => {
                              if (
                                favMod.modifier_group_id === nestedModGroup.parentId &&
                                objectToArray(nestedModItems).filter(
                                  (item) => item.id === favMod.item_id
                                ).length > 0
                              ) {
                                filteredAddonGroups[addonGroupKey].items[addonKey].modifierGroups[
                                  nestedModifierGroupKey
                                ] = nestedModGroup;
                              }
                            });
                          });
                        }
                      });
                    }
                  }
                });
              }
            });
            favItemComboChildCopy.addonGroups = filteredAddonGroups;
          } else {
            favItemComboChildCopy.addonGroups = {};
          }

          if (
            comboChildItemsCopy.find(
              (item) =>
                item.id === favItemComboChild.item_id &&
                item.group_id === favItemComboChild.item_components_group_id &&
                hasTheSameOptionSelections(lookUpItem, item, "modifier")
            )
          ) {
            favItemComboChildCopy.modifierGroups = comboChildItemsCopy.find(
              (item) =>
                item.id === favItemComboChild.item_id &&
                item.group_id === favItemComboChild.item_components_group_id &&
                hasTheSameOptionSelections(lookUpItem, item, "modifier")
            ).modifierGroups;
          } else {
            favItemComboChildCopy.modifierGroups = {};
          }

          favItemComboChildCopy.id = favItemComboChild.item_id;
          favItemComboChildCopy.group_id = favItemComboChild.item_components_group_id;

          delete favItemComboChildCopy.addon_list;
          delete favItemComboChildCopy.modifier_list;
          delete favItemComboChildCopy.item_id;
          delete favItemComboChildCopy.item_components_group_id;

          if (
            comboItem.combo_child_items.filter(
              (tempChild) =>
                tempChild.group_id === favItemComboChildCopy.group_id &&
                tempChild.id === favItemComboChildCopy.id &&
                JSON.stringify(tempChild) === JSON.stringify(favItemComboChild)
            ).length === 0
          ) {
            favItemComboChildCopy.entryKey = Date.now();
            comboItem.combo_child_items.push(favItemComboChildCopy);
          }
        }
      });
    });

    const quantity = 1;
    const cartItem = JSON.parse(JSON.stringify(comboItem));
    cartItem.specialInstructions = "";
    cartItem.category = categoryId;
    cartItem.subcategory = subcategoryId;
    cartItem.quantity = 1;
    cartItem.entryKey = Date.now();
    cartItem.combo_child_items = sortByKey(comboItem.combo_child_items, "group_id");

    /** Check if the same item with the same selection exist in the cart
     * by creating copies of the cart and the item being added
     * and removing unnecessary keys
     * and then comparing them as strings
     */

    const cartCopy = jsonCopy(cart);
    const cartItemCopy = jsonCopy(cartItem);

    delete cartItemCopy.entryKey;
    delete cartItemCopy.quantity;
    let matchFound = false;
    let matchIndex = 0;
    cartCopy.forEach((cartCopyItem, index) => {
      let storedQuantity = cartCopyItem.quantity;
      delete cartCopyItem.entryKey;
      delete cartCopyItem.quantity;
      delete cartCopyItem.total_addon_discount;

      if (JSON.stringify(cartCopyItem) === JSON.stringify(cartItemCopy)) {
        matchFound = true;
        matchIndex = index;
        cartItem.quantity = quantity + storedQuantity;
        cartItem.entryKey = Date.now();
      }
    });
    const newCart = cart ? [...cart] : [];
    if (matchFound) {
      newCart[matchIndex] = cartItem;
    } else {
      newCart.push(cartItem);
    }

    onAddProductToCartXtreme(cartItem, cartItem.category, cartItem.subcategory, skin);
    cartContext.updateCart(newCart);
    setIsLoading(false);
    isCallingAPI.current = false;
  };
  const addItemToCart = (favItem, menuItem, categoryId = "", subcategoryId = "") => {
    const addonGroups = menuItem.addonGroups;
    const modifierGroups = menuItem.modifierGroups;

    if (!isValidItemQuantity(cart, menuItem)) {
      setIsQuantityValid(false);
      return;
    }

    //check the modifier selection
    if (Object.keys(modifierGroups).length > 0) {
      Object.keys(modifierGroups).forEach((modifierGroupKey) => {
        const modifierGroup = modifierGroups[modifierGroupKey];

        if (
          favItem.modifier_list.filter((group) => group.modifier_group_id === modifierGroup.id)
            .length > 0
        ) {
          //there is a selection for the modifier group
          const favItemModifierGroup = favItem.modifier_list.filter(
            (group) => group.modifier_group_id === modifierGroup.id
          );
          const modifiers = modifierGroup.items;
          Object.keys(modifiers).forEach((modifierKey) => {
            const modifier = modifiers[modifierKey];
            if (
              favItemModifierGroup.filter((option) => option.item_id === modifier.id).length > 0
            ) {
              modifier.isSelected = true;
            }
          });
        }
      });
    }

    //check the addon selection
    if (Object.keys(addonGroups).length > 0) {
      Object.keys(addonGroups).forEach((addonGroupKey) => {
        const addonGroup = addonGroups[addonGroupKey];
        if (
          favItem.addon_list.filter((group) =>
            group.addon_group_id !== ""
              ? group.addon_group_id === addonGroup.id
              : group.item_id === addonGroupKey.split("-")[1]
          ).length > 0
        ) {
          //there is a selection for this addon group
          const favItemAddonGroup = favItem.addon_list.filter((group) =>
            group.addon_group_id !== ""
              ? group.addon_group_id === addonGroup.id
              : group.item_id === addonGroupKey.split("-")[1]
          );
          const addons = addonGroup.items;
          Object.keys(addons).forEach((addonKey) => {
            const addon = addons[addonKey];
            delete addon.addonQuantity;
            if (favItemAddonGroup.filter((option) => option.item_id === addon.id).length > 0) {
              addon.isSelected = true;
              let addonQuantity = 1;
              if (
                favItem.addon_list.find(
                  (tempAddon) => tempAddon.item_id === addonKey.replace("addon-", "")
                )
              ) {
                addonQuantity =
                  favItem.addon_list.find(
                    (tempAddon) => tempAddon.item_id === addonKey.replace("addon-", "")
                  ).quantity || 1;
              }
              addon.quantity = addonQuantity;
              addon.addonQuantity = addon.quantity;
              //checked the nested modifiers
              const nestedModifierGroups = addon.modifierGroups;
              Object.keys(nestedModifierGroups).forEach((nestedModifierGroupKey) => {
                const nestedModGroup = nestedModifierGroups[nestedModifierGroupKey];
                favItemAddonGroup.forEach((favAddonGroup) => {
                  if (
                    favAddonGroup.modifier_list.filter(
                      (option) => option.modifier_group_id === nestedModGroup.id
                    ).length > 0
                  ) {
                    const favItemNestedModGroups = favAddonGroup.modifier_list.filter(
                      (option) => option.modifier_group_id === nestedModGroup.id
                    );
                    const nestedMods = nestedModGroup.items;
                    Object.keys(nestedMods).forEach((nestedModKey) => {
                      const nestedModifier = nestedMods[nestedModKey];
                      if (
                        favItemNestedModGroups.filter(
                          (option) => option.item_id === nestedModifier.id
                        ).length > 0
                      ) {
                        nestedModifier.isSelected = true;
                      }
                    });
                  }
                });
              });
            } else {
              delete addon.isSelected;
            }
          });
        }
      });
    }

    const quantity = 1;
    const cartItem = JSON.parse(JSON.stringify(menuItem));
    cartItem.specialInstructions = "";
    cartItem.category = categoryId;
    cartItem.subcategory = subcategoryId;
    cartItem.quantity = 1;
    cartItem.entryKey = Date.now();

    /** Check if the same item with the same selection exist in the cart
     * by creating copies of the cart and the item being added
     * and removing unnecessary keys
     * and then comparing them as strings
     */

    const cartCopy = jsonCopy(cart);
    const cartItemCopy = jsonCopy(cartItem);

    delete cartItemCopy.entryKey;
    delete cartItemCopy.quantity;
    let matchFound = false;
    let matchIndex = 0;
    cartCopy.forEach((cartCopyItem, index) => {
      let storedQuantity = cartCopyItem.quantity;
      delete cartCopyItem.entryKey;
      delete cartCopyItem.quantity;
      delete cartCopyItem.total_addon_discount;

      if (
        JSON.stringify(sortObjectKeysAlphabetically(cartCopyItem)) ===
        JSON.stringify(sortObjectKeysAlphabetically(cartItemCopy))
      ) {
        matchFound = true;
        matchIndex = index;
        cartItem.quantity = quantity + storedQuantity;
        cartItem.entryKey = Date.now();
      }
    });
    const newCart = cart ? [...cart] : [];
    if (matchFound) {
      newCart[matchIndex] = cartItem;
    } else {
      newCart.push(cartItem);
    }

    onAddProductToCartXtreme(cartItem, cartItem.category, cartItem.subcategory, skin);
    cartContext.updateCart(newCart);
  };

  const addItemToOrder = async () => {
    const isThreeLevel = menu.menuLevel === "threeLevels";
    const menuData = menu.data;

    let itemIsAvailable = false;
    for (let key in menuData) {
      if (isThreeLevel) {
        const category = menuData[key];
        const subcategories = category.subcategories;
        for (let subCatKey in subcategories) {
          const subcategory = subcategories[subCatKey];
          const items = subcategory.items;
          for (let itemKey in items) {
            if (item.item_id === items[itemKey].id) {
              itemIsAvailable = true;
              if (items[itemKey].type === "comboItem") {
                addComboItemToCart(item, jsonCopy(items[itemKey]), category.id, subcategory.id);
                break;
              } else {
                addItemToCart(item, jsonCopy(items[itemKey]), category.id, subcategory.id);
                break;
              }
            }
          }
        }
      } else {
        const subcategory = menuData[key];
        const items = subcategory.items;
        for (let itemKey in items) {
          if (item.item_id === items[itemKey].id) {
            itemIsAvailable = true;
            if (items[itemKey].type === "comboItem") {
              addComboItemToCart(item, jsonCopy(items[itemKey]), "", subcategory.id);
              break;
            } else {
              addItemToCart(item, jsonCopy(items[itemKey]), "", subcategory.id);
              break;
            }
          }
        }
      }
    }

    if (!itemIsAvailable) {
      setItemNotAvailable(true);
      setAddToOrderText("Unavailable");
      setIsButtonDisabled(true);
    }
  };

  const [onlineOrderingLimit, setOnlineOrderingLimit] = useState(null);
  useEffect(() => {
    if (item && menu) {
      const fullItemInfo = getfavItemInfoFromMenu(item["item_id"], menu);
      if (
        fullItemInfo &&
        fullItemInfo["online_qty_limit"] &&
        fullItemInfo["online_qty_limit"] !== "None"
      ) {
        setOnlineOrderingLimit(fullItemInfo["online_qty_limit"]);
      }
    }
  }, [item, menu]);

  return (
    <React.Fragment key={key}>
      <li className="favorite-item">
        <div className="favorite-item-card__image-and-text-wrapper">
          <div className="favorite-item-card__image-wrapper">
            <img
              className="favorite-item__image"
              src={item.imageUrl || imagePlaceholder}
              alt={item.name}
            />
            {item.calorie_count && (
              <div className="favorite-item__calorie-container">
                <span>
                  {item.calorie_count} {appLabels["order"]["calories"]}
                </span>
              </div>
            )}
          </div>

          <div className="favorite-item__item-details-wrapper">
            <div className="favorite-item__item-details-inner-wrapper">
              <div className="favorite-item__text-wrapper">
                <h3 className="favorite-item__name">{he.decode(item.name)}</h3>
              </div>
              <div className="favorite-item__price-container">
                <div className="favorite-item__price">
                  {toDollars(
                    appSettings["currency-symbol"],
                    appSettings["currency-symbol-side"],
                    item.price,
                    appLanguage
                  )}
                </div>
                {!!optionsTotal &&
                  (!item.combo_child_items || item.combo_child_items.length === 0) && (
                    <div className="favorite-item__modifiers-total">{`+${toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      optionsTotal,
                      appLanguage
                    )}`}</div>
                  )}
              </div>
            </div>
            <div className="favorite-item__item-details-inner-wrapper">
              <div className="favorite-item__options-list">
                {listFavItemOptions(item.addon_list, "column", isAddonQuantityEnabled)}
                {listFavItemOptions(item.modifier_list, "column", isAddonQuantityEnabled)}
              </div>
            </div>

            {!!item.combo_child_items && !!item.combo_child_items.length && (
              <>
                {item.combo_child_items.map((childItem, index) => (
                  <React.Fragment key={index + "-" + childItem.id}>
                    <div className="favorite-item__item-details-inner-wrapper">
                      <div className="favorite-item__text-wrapper">
                        <h3 className="favorite-item__name">
                          {childItem.groupMinQty && (childItem.quantity || 1) + "x "}
                          {he.decode(childItem.name)}{" "}
                          {childItem.price > 0 && (
                            <div className="favorite-item__price-container">
                              <div className="favorite-item__price">
                                {toDollars(
                                  appSettings["currency-symbol"],
                                  appSettings["currency-symbol-side"],
                                  childItem.price,
                                  appLanguage
                                )}
                              </div>
                            </div>
                          )}
                        </h3>

                        <div className="favorite-item__options-list">
                          {listFavItemOptions(
                            childItem.addon_list,
                            "column",
                            isAddonQuantityEnabled
                          )}
                          {listFavItemOptions(
                            childItem.modifier_list,
                            "column",
                            isAddonQuantityEnabled
                          )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="favorite-item__buttons-container">
          <div className="favorite-item__button__gradient"></div>
          <div className="favorite-item__buttons">
            <button
              className="button button--primary button--primary-small button--primary-with-border"
              onClick={toggleModal}
              type="button"
              disabled={isLoading}
              aria-label={appLabels["general"]["remove"] + " " + item.name}
            >
              {appLabels["general"]["remove"]}
            </button>
            <button
              className="button button--primary button--primary-small"
              onClick={addItemToOrder}
              disabled={
                isButtonDisabled ||
                isLoading ||
                (!!onlineOrderingLimit &&
                  getItemQuantityFromCart(cart, item["item_id"]) >= onlineOrderingLimit)
              }
              type="button"
              aria-label={addToOrderText + " " + item.name}
            >
              {addToOrderText}
            </button>
          </div>
        </div>
        {onlineOrderingLimit && (
          <div className="favorite-item-card__image-and-text-wrapper">
            <span>
              {getItemQuantityFromCart(cart, item["item_id"]) >= onlineOrderingLimit ? (
                <>{appLabels["order"]["limit-reached"]}</>
              ) : (
                <>
                  {appLabels["order"]["limit-per-order"].replace(
                    "[qty-limit]",
                    onlineOrderingLimit
                  )}
                </>
              )}
            </span>
          </div>
        )}
        {isLoading && <LoadingSpinner />}
      </li>
      <Modal
        isModal={isModal}
        toggleModal={toggleModal}
        content={
          <div className="confirm-remove-favorite__container">
            <p>{appLabels["order"]["remove-favorite-confirmation"]}</p>
          </div>
        }
        type="default"
        description="Confirm favorite item removal"
        closeAnimation={closeAnimation}
        isCancelButton={true}
        isSubmitButton={true}
        onSubmit={() => removeFavorite(itemIndex)}
        fabButtonText={appLabels["general"]["remove"]}
        isClickAway={true}
        wrapperClass="confirm-remove-favorite__modal"
      />
    </React.Fragment>
  );
};

import { callAPI } from "../../../_common/Api";

export const getAPIRewards = async (skin, fullCardNumber, language) => {
  const api933Data = await callAPI(skin, "dc_933", {
    params: [language, "933", "mqid", "mqpass", fullCardNumber, "95", "", ""],
    id: "933",
  });

  const apiRewards = api933Data.result.I2;

  if (apiRewards) {
    const rewards = [];

    /**
     * apiReward
     * [0] id
     * [1] description
     * [2] price
     * [3] expiry
     * [4] reward balance (number of this reward available to the user)
     * [5] ?
     * [6] reward name
     * [7] reward image url
     * [8] reward type id
     * [9] reward type name
     */
    apiRewards.forEach((apiReward) => {
      const formattedReward = {
        id: apiReward[0],
        description: apiReward[1],
        price: parseFloat(apiReward[2]),
        expiry: apiReward[3] || false,
        rewardBalance: apiReward[4],
        name: apiReward[6],
        image: apiReward[7],
        rewardTypeId: apiReward[8],
        rewardTypeName: apiReward[9],
        isBirthday: apiReward[1].toLowerCase().includes("birthday"),
      };

      rewards.push(formattedReward);
    });

    const sortedByCost = rewards.sort(
      (item1, item2) => parseInt(item1.price) - parseInt(item2.price)
    );

    return sortedByCost;
  }
};

// Libraries
import React, { useState, useContext } from "react";

// API helper functions
import { updateAPICustomerPassword } from "./apiHelpers/updateAPICustomerPassword";

// Contexts
import AppLanguageContext from "../App/AppLanguageContext";
import AppLabelsContext from "../App/AppLabelsContext";

// UI components
import { Form } from "../_common/Form/Form";
import { FormInput } from "../_common/Form/FormInput";

export const ChangePasswordForm = (props) => {
  const { skin, login, setIsModal } = props;

  var AES = require("crypto-js/aes");

  const [APIerror, setAPIerror] = useState(null);
  const [isAPIsubmitting, setIsAPIsubmitting] = useState(null);
  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);

  const appLanguage = useContext(AppLanguageContext);

  const submitPassword = (formData) => {
    setIsAPIsubmitting(true);

    const loginEmail = login;
    let currentPassword = "";
    let newPassword = "";

    formData.forEach((field) => {
      if (field.name === "password") currentPassword = field.value;
      if (field.name === "new-password") newPassword = field.value;
    });

    updateAPICustomerPassword(skin, loginEmail, currentPassword, newPassword, appLanguage).then(
      (apiCustomerId) => {
        if (apiCustomerId) {
          setIsAPIsubmitting(false);

          if (apiCustomerId.error) {
            setAPIerror(apiCustomerId.error);
            setIsAPISubmitValid(false);
          } else {
            setIsAPISubmitValid(true);
            setIsModal(false);
            sessionStorage.setItem(
              skin + "__login",
              JSON.stringify({
                email: loginEmail,
                password: AES.encrypt(newPassword, "blurb").toString(),
              })
            );

            if (localStorage.getItem(skin + "__isRememberMeOn")) {
              localStorage.setItem(
                skin + "__login",
                JSON.stringify({
                  email: loginEmail,
                  password: AES.encrypt(newPassword, "blurb").toString(),
                })
              );
            }
          }
        }
      }
    );
  };

  const appLabels = useContext(AppLabelsContext);

  return (
    <div className="modal-content-container account-form-modal-container">
      <h1 className="modal__heading">{appLabels["account"]["update-your-password"]}</h1>
      <div className="form__field-wrapper">
        <div className="form__field-label">{appLabels["account"]["current-username"]}</div>
        <div className="form__field current-field-value">{login}</div>
      </div>
      <Form
        id="form--change-password"
        className="form--account-update"
        submitForm={submitPassword}
        submitAPIError={APIerror}
        resetAPIError={() => setAPIerror("")}
        isAPISubmitValid={isAPISubmitValid}
        isAPIsubmitting={isAPIsubmitting}
        isDisableSubmit={isAPIsubmitting}
        submitButtonText={appLabels["account"]["change-password"]}
        submitButtonLoadingText={appLabels["account"]["changing-password"]}
        isFAB={true}
        isModalFAB={true}
        buttonWrapperClass={"change-password__floating-button"}
      >
        <FormInput
          type="password"
          label={appLabels["account"]["current-password"]}
          placeholder="********"
          id="input--password"
          name="password"
          isRequired={true}
        />
        <FormInput
          type="password"
          label={appLabels["account"]["new-password"]}
          id="input--new-password"
          name="new-password"
          isRequired={true}
        />
        <FormInput
          type="password"
          label={appLabels["account"]["confirm-new-password"]}
          id="input--confirm-new-password"
          name="confirm-new-password"
          isRequired={true}
        />
      </Form>
    </div>
  );
};

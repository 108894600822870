import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/**Hooks */
import { useHideOrderModule } from "../../App/useHideOrderModule";

/**Contexts */
import AppLabelsContext from "../../App/AppLabelsContext";
import StoreContext from "../../OnlineOrdering/StoreContext";
import AppSettingsContext from "../../App/AppSettingsContext";

/** Helpers */
import { getViewBillImages } from "./getViewBillImages";
import { jsonCopy } from "../../_common/helpers";

/** UI Components */
import defaultViewBillImage from "../../_common/Images/pay-bill-generic-image.jpeg";

/** CSS */
import "./DashboardPayBill.css";

const DashboardPayBill = (props) => {
  const appLabels = useContext(AppLabelsContext);
  const storeContext = useContext(StoreContext);
  const history = useHistory();

  const relativePath = useContext(AppSettingsContext)["relative-path"];

  const isLocalhost = process.env.NODE_ENV === "development";

  const [viewBillImage, setViewBillImage] = useState(null);
  const setDefaultBillImage = () => {
    setViewBillImage(defaultViewBillImage);
  };

  const { hideOrderModule } = useHideOrderModule();

  useEffect(() => {
    getViewBillImages(relativePath).then((fetchedImagesData) => {
      if (fetchedImagesData) {
        if (fetchedImagesData === "no-images-set" || isLocalhost) {
          setDefaultBillImage();
        } else {
          const tempImage = jsonCopy(fetchedImagesData);

          const image = tempImage;
          const imageCopy = jsonCopy(image);
          if (
            imageCopy["image-src"] === "" ||
            imageCopy["image-src"].includes("_blank") ||
            imageCopy["image-src"].split(".").length === 1
          ) {
            imageCopy["image-src"] = defaultViewBillImage;
          }

          image["image-src"] = imageCopy["image-src"];

          setViewBillImage(tempImage);
        }
      } else {
        //if no order-types key was set in online-ordering/images.json
        setDefaultBillImage();
      }
    });
  }, []);

  const onViewBillClick = () => {
    //if there is a table number stored, redirect to bill page
    //else, redirect to scan page
    if (storeContext.activeOrderStoreTable) {
      history.push("/online-ordering/bill");
    } else {
      history.push("/online-ordering/dinein");
    }
  };

  return (
    <>
      {viewBillImage && (
        <section
          className={`dashboard__module dashboard-pay-bill__module ${
            hideOrderModule ? "is-pay-only" : ""
          }`}
        >
          <div className="desktop-container"></div>
          <div className="dashboard-pay-bill__module-body-wrapper">
            <span className="rewards-module__balance-label-header">
              {appLabels["order"]["ready-to-pay"]}
            </span>

            <div className="dashboard-pay-bill__module-column">
              <p>{appLabels["order"]["ready-to-pay-blurb"]}</p>
            </div>
            <div className="dashboard-pay-bill__module-column-button-container">
              <button
                type="button"
                className="button button--primary"
                aria-label={appLabels["order"]["view-bill"]}
                onClick={onViewBillClick}
                id="dashboard-pay-bill"
              >
                {appLabels["order"]["view-bill"]}
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default DashboardPayBill;

// Libraries
import React, { useContext, useState, useEffect } from "react";
import { GoogleMap } from "@react-google-maps/api";

// Contexts
import LocationsContext from "../Locations/LocationsContext";

// UI components
import { GoogleMapsMarkers } from "./GoogleMapsMarkers";
import { LoadingSpinner } from "../../_common/LoadingSpinner";

// JSON data
import mapStyles from "./mapStyles.json";

// CSS
import "./GoogleMaps.css";

const INITIAL_CENTER = {
  lat: 49.285011,
  lng: -123.116563,
};
const ZOOM_LEVEL = 15; // street level

export const GoogleMaps = (props) => {
  const {
    storesInRange,
    userLocation,
    setStoreOnImpression,
    storeOnImpression,
    favoriteLocations,
  } = props;

  const apiStores = useContext(LocationsContext);
  const [isInitialSearchPerformed, setIsInitialSearchPerformed] = useState(false);

  const [mapInstance, setMapInstance] = useState(null);
  useEffect(() => {
    if (mapInstance) {
      mapInstance.setCenter(userLocation);
    }
  }, [mapInstance, userLocation]);

  const onUserMarkerChange = () => {
    if (!isInitialSearchPerformed) {
      setTimeout(() => {
        setIsInitialSearchPerformed(true);
      }, 1900);
    }
  };
  const handleMarkerClick = (store) => {
    setStoreOnImpression(store);
  };

  return (
    <>
      {!apiStores && <LoadingSpinner />}

      <GoogleMap
        aria-hidden="true"
        id="google-map"
        onLoad={(map) => setMapInstance(map)}
        center={INITIAL_CENTER}
        zoom={ZOOM_LEVEL}
        options={{
          disableDefaultUI: true,
          styles: mapStyles,
          ariaHidden: true
        }}
      >
        <GoogleMapsMarkers
          onUserMarkerChange={onUserMarkerChange}
          userLocation={userLocation}
          storesInRange={storesInRange}
          handleMarkerClick={handleMarkerClick}
          storeOnImpression={storeOnImpression}
          favoriteLocations={favoriteLocations}
        />
      </GoogleMap>
    </>
  );
};

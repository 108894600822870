// shows all the gift cards available to order

// Libraries
import React, { useState, useEffect, useContext } from "react";
import "./GiftCard.css"
import "../_common/CartIndicator/CartIndicator.css"

import GiftCardCartIndicator from "../_common/GiftCardCartIndicator/GiftCardCartIndicator";
import { useHistory } from 'react-router-dom';
import GCCartContext from "./GCCartContext";
import AppLabelsContext from "../App/AppLabelsContext";
import { GCCarousel } from "./GCCarousel";

export const AvailableGiftCards = (props) => {
  let availableGiftCards = props.availableGiftCards

  const [giftCards, setGiftCards] = useState([])
  const appLabels = useContext(AppLabelsContext)
  const cartContext = useContext(GCCartContext)

  let categorizedGiftCardsList = null
  categorizedGiftCardsList = props.giftCardCategorizedList

  useEffect(() => {
    setGiftCards(availableGiftCards)
    let categoriesList = []
    for (const key in categorizedGiftCardsList) {
      categoriesList.push(key)
    }
    if (categoriesList.length === 1) {
      showGiftCardsInCategory(categoriesList[0])
    }
  }, [props.availableGiftCards]);

  let history = useHistory()
  const showGiftCardsInCategory = (category) => {
    history.push({
      pathname: "/gift-card/category/" + category,
      state: {
        category: category,
      }
    });
  }

  return (
    <div>
      {giftCards && giftCards.length > 0 &&
        <div className="availableGiftCardsContainer">
          {categorizedGiftCardsList &&
            Object.keys(categorizedGiftCardsList).length > 0 &&
            Object.keys(categorizedGiftCardsList).map((key, index) => {
              return (
                <React.Fragment key={`${key}-${index}`}>
                  <div className="carouselHeadingContainer">
                    <h1 className="dashboard__module-heading">{key}</h1>
                    {categorizedGiftCardsList[key].data.length > 4 &&
                      <button
                        type="button"
                        className="suggested_items__view-details"
                        onClick={() => showGiftCardsInCategory(key)}
                      >
                        {appLabels["gift-card"]["see-all"]}
                      </button>
                    }
                  </div>
                  <section className="dashboard__module dashboard__module--horizontal-slider dashboard__new-order-items-module giftCardCarouselSection">
                    <GCCarousel allGiftCards={categorizedGiftCardsList[key].data} compactCarousel={false} />

                  </section>
                </React.Fragment>
              )
            })}
        </div>

      }

      {
        cartContext.quantity > 0 &&
        <button
          className={"button button--cart button--cart-floating}"}
            onClick={() => {
              history.push({
                pathname: "/gift-card/checkout/",
              })
            }}
          >
            <GiftCardCartIndicator appLabels={appLabels} quantity={cartContext.quantity} isFloating={true} isCheckoutScreen={false} />
          </button>
      }

    </div>
  )
}
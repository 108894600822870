import React from "react";
import ReactDOM from "react-dom";
import { isIOS } from "./_common/isIOS";
import { generateAppUpdateModal } from "./generateAppUpdateModal";

import "./index.css";
import AppSetup from "./App/AppSetup";
import "./App/AppFooter/AppFooterPayInStore.css";
import "./App/NewVersionModal.css";
import { isTouchDevice } from "./_common/helpers/isTouchDevice";

const isLocalhost = process.env.NODE_ENV === "development";
const isGivexHost = !isLocalhost;
const pathnameSplit = window.location.pathname.split("/");
const isDefaultLanguage = isGivexHost ? pathnameSplit[3] === "" : pathnameSplit[1] === "";
const languageFromPathname = isGivexHost ? pathnameSplit[3] : pathnameSplit[1];

const RELATIVE_PATH = isDefaultLanguage ? "" : "../";

// Inject the manifest.json relative URL into index.html's link based on the current language route
document.getElementById("manifestLink").setAttribute("href", `${RELATIVE_PATH}manifest.json`);

// Inject relative URL favicon files into index.html's links based on the current language route
document.getElementById("favicon").setAttribute("href", `${RELATIVE_PATH}files/Images/favicon.ico`);
document
  .getElementById("appleTouchIcon")
  .setAttribute("href", `${RELATIVE_PATH}files/Images/pwa/ios/touch-icon-iphone-retina.png`);

if (isIOS()) document.body.classList.add("body--iOS");
if (isTouchDevice()) document.body.classList.add("body--touch-device");

// Render App in index.html
ReactDOM.render(
  <AppSetup relativePath={RELATIVE_PATH} languageFromPathname={languageFromPathname} />,
  document.getElementById("root")
);

/** check if url has outlet id and table id in it, to detect if user landed on the site from scanning a qr code from outside the app */
const urlHash = window.location.hash;
const hasOutletId = urlHash.includes("outlet_id");
const hasTableId = urlHash.includes("table_id");
const isNotDirectLink = !urlHash.includes("direct");
if (hasOutletId && hasTableId && isNotDirectLink) {
  const pathName = window.location.pathname;
  sessionStorage.setItem(pathName + "scannedQRCodeURLHash", urlHash.split("#")[1]);
}

/**
 * Below is the custom Service Worker file registration.
 *
 * NOTE: registered file is merchant-unique and imports another global service worker file via importScripts
 * which holds the service worker event listeners and the CACHE_NAME, which if changed triggers a new registration/install/activate.
 * */

// Check for browser support
if ("serviceWorker" in navigator) {
  // delay registration until after the load event fires on window for better performance for initial visit

  window.addEventListener("load", () => {
    // updateViaCache: "none" will make sure the HTTP cache is ignored when making the service-worker-global.js request via importScripts (from intermediary service worker file in each Marqui account)

    navigator.serviceWorker
      .register(`${RELATIVE_PATH}service-worker-custom.js`, {
        updateViaCache: "none",
        scope: ".",
      })
      .then(
        (registration) => {
          // Registration was successful
          if (registration.waiting) {
            //generateAppUpdateModal(registration);
            if (!sessionStorage.getItem("newServiceWorker")) {
              //if the script url contains app key, the service worker is coming from XP
              if (!registration.waiting.scriptURL.includes("app_key")) {
                generateAppUpdateModal(registration);
                //registration.waiting.postMessage("skipWaiting");
              } else {
                registration.waiting.postMessage("skipWaiting");
                // registration.waiting.skipWaiting();
              }
            } else {
              setTimeout(() => {
                sessionStorage.removeItem("newServiceWorker");
              }, 5000);
            }
          }

          registration.addEventListener("updatefound", () => {
            // An updated service worker has appeared in registration.installing

            if (registration.installing) {
              registration.installing.addEventListener("statechange", (event) => {
                // Check if service worker state changedx
                if (registration.waiting) {
                  if (navigator.serviceWorker.controller) {
                    if (
                      !sessionStorage.getItem("newServiceWorker") &&
                      !event.target.scriptURL.includes("app_key") &&
                      !registration.active.scriptURL.includes("app_key")
                    ) {
                      generateAppUpdateModal(registration);
                    } else {
                      setTimeout(() => {
                        sessionStorage.removeItem("newServiceWorker");
                      }, 5000);
                    }
                  } else {
                    // otherwise it's the first install, nothing to do
                    console.log("Service Worker initialized for the first time");
                  }
                }
              });
            }
          });

          // reload once when the new Service Worker starts activating
          /*let refreshing = false; // prevents an infinite loop

          navigator.serviceWorker.addEventListener("controllerchange", () => {
            if (!refreshing) {
              window.location.reload();
              refreshing = true;
            }
          });*/
        },
        (error) => {
          // registration failed
          console.error("ServiceWorker registration failed: ", error);
        }
      );
  });
}

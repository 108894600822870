import React from "react";
import { capitalizeWords } from "../../_common/stringHelpers";
import "./DashboardModuleHeader.css";

export const DashboardModuleHeader = ({ icon, name }) => (
  <header className="dashboard__module-header">
    {icon}
    <h2 className="dashboard__module-heading">{capitalizeWords(name)}</h2>
  </header>
);

import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";

import MerchantConfigContext from "../../App/MerchantConfigContext";
import StoreContext from "../StoreContext";
import OrderTypeContext from "../OrderTypeContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import UserRoleContext from "../../App/UserRoleContext";
import CartContext from "./CartContext";

import { Form } from "../../_common/Form/Form";
import { FormInput } from "../../_common/Form/FormInput";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { OrderTypeSpecialInstruction } from "./OrderTypeSpecialInstruction";
import { onCheckout } from "../../_common/helpers/dataLayerHelpers";
import IndividualLoginButton from "./IndividualLoginButton";
import useWindowSize from "../../_common/hooks/useWindowSize";
import { onCheckoutXtreme } from "../../_common/helpers/xtremePushHelper";

export const CustomerInfo = () => {
  const merchantConfig = useContext(MerchantConfigContext);
  const storeContext = useContext(StoreContext);
  const orderTypeContext = useContext(OrderTypeContext);
  const cartData = useContext(CartContext);
  const userRoleContext = useContext(UserRoleContext);
  const isDeliveryOrderType = orderTypeContext.value === "delivery";
  const isDineinOrderType = orderTypeContext.value === "dinein";

  const isPickupOrderType = orderTypeContext.value === "pickup";
  const isOrderLevelInstructionEnabled =
    storeContext.activeOrderStore.vexilorConfig.pickup_special_instructions === "t" ||
    storeContext.activeOrderStore.vexilorConfig.dinein_special_instructions === "t";

  const isDeliverySpecialInstructionEnabled =
    storeContext.activeOrderStore.vexilorConfig.delivery_special_instructions === "t";
  /* The pickup Special Instruction can be used in different use cases (driver's licence plate #, special Instruct, etc...)
      As a result the header, placeholder and additional information presented for the pickup special instruction has to be customizable and 
      should become new field in marqui


      TODO: Create marqui fields required for pickup special instruction and pull them on the FE
        const [pickupSpecialInstTitle, setPickupSpecialInstTitle] = useState("Pickup Information");
        const [pickupSpecialInstAdditionalInfo, setPickupSpecialInstAdditionalInfo] = useState("");
        const [pickupSpecialInstPlaceholder, setPickupSpecialInstPlaceholder] = useState(
          "Special Instruction"
        );
      
  */

  //set this state to true if the country of the selected store is BR
  const isCPFRequired = storeContext.activeOrderStore.country === "BR";

  const [customerInfo, setCustomerInfo] = useState(null);
  useEffect(() => {
    if (merchantConfig.skin) {
      localforage.getItem(merchantConfig.skin + "__customerInfo").then((storedCustomerInfo) => {
        if (storedCustomerInfo) setCustomerInfo(storedCustomerInfo);
        else setCustomerInfo(true);
      });
    }
  }, [merchantConfig]);

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [isAPISubmitting, setAPISubmitting] = useState(false);

  const collectCustomerInfo = (formData) => {
    setAPISubmitting(true);

    let firstName;
    let lastName;
    let email;
    let phone;
    let cpfNumber = "";

    formData.forEach((field) => {
      if (field.name === "first-name") firstName = field.value;
      else if (field.name === "last-name") lastName = field.value;
      else if (field.name === "email") email = field.value;
      else if (field.name === "phone") phone = field.value.replace(/[- )(]/g, "");
      else if (field.name === "cpf-number") cpfNumber = field.value;
    });

    let tempCustomerInfo = { ...customerInfo, firstName, lastName, email, phone, cpfNumber };

    setCustomerInfo(tempCustomerInfo);

    localforage.setItem(merchantConfig.skin + "__customerInfo", tempCustomerInfo);

    onCheckout(cartData.value, 2);
    setIsAPISubmitValid(true);
    setAPISubmitting(false);
  };

  useEffect(() => {
    onCheckoutXtreme(cartData.value, 3, "", merchantConfig.skin);
  }, []);

  const [orderTypeInstruction, setOrderTypeInstruction] = useState("");
  const updateOrderTypeSpecialInstructions = (value) => {
    setOrderTypeInstruction(value);
    localforage.setItem(merchantConfig.skin + "__orderTypeSpecialInstruction", value);
  };

  const appLabels = useContext(AppLabelsContext);
  const [userDeliveryAddress, setUserDeliveryAddress] = useState(null);
  useEffect(() => {
    localforage
      .getItem(merchantConfig.skin + "__userDeliveryAddress")
      .then((userDeliveryAddress) => {
        if (userDeliveryAddress) {
          setUserDeliveryAddress(userDeliveryAddress);
        } else {
          setUserDeliveryAddress(false);
        }
      });
  }, []);

  const deviceWidth = useWindowSize().width;
  return (
    <>
      {userRoleContext.status === "guest" && (
        <IndividualLoginButton
          floatRight={true}
          customStyle={deviceWidth >= 660 ? { width: "70%" } : {}}
          noRightPadding={true}
        />
      )}
      <div className="customer-info__form-wrapper" aria-label={appLabels["order"]["customer-info-dialog-message"]} role="alert">
        <h1 className="customer-info__form-heading">
          {appLabels["order"]["customer-info-form-heading"]}
        </h1>
        {customerInfo && customerInfo != null && userDeliveryAddress !== null ? (
          <Form
            id="form--customer-info"
            submitButtonText={appLabels["order"]["order-contact-confirm"]}
            submitButtonSuccessText={appLabels["order"]["order-contact-confirming"]}
            submitForm={collectCustomerInfo}
            isAllowEmptySubmit={true}
            isAPISubmitValid={isAPISubmitValid}
            isAPISubmitting={isAPISubmitting}
            submitNavigateURL="/online-ordering/payment"
            submitButtonCustomClass="button--primary"
            isBufferBeforeNavigationDisabled={true}
            submitButtonWrapperClass="button-container--single-relative"
          >
            <FormInput
              type="text"
              label={appLabels["form"]["first-name"]}
              id="input--first-name"
              name="first-name"
              isRequired={true}
              defaultValue={customerInfo.firstName}
            />
            <FormInput
              type="text"
              label={appLabels["form"]["last-name"]}
              id="input--last-name"
              name="last-name"
              isRequired={true}
              defaultValue={customerInfo.lastName}
            />
            <FormInput
              type="text"
              label={appLabels["form"]["email"]}
              placeholder="email@example.com"
              id="input--email"
              name="email"
              isRequired={true}
              defaultValue={customerInfo.email}
            />
            <FormInput
              type="tel"
              label={appLabels["form"]["phone"]}
              id="input--phone"
              name="phone"
              isRequired={true}
              defaultValue={customerInfo.phone}
              isMaskedInput={true}
              autocomplete="tel"
            />
            {isCPFRequired && (
              <FormInput
                type="text"
                label="Você gostaria de informar o CPF?"
                id="input--cpf"
                name="cpf-number"
                isRequired={false}
                defaultValue={customerInfo.cpfNumber}
              />
            )}
            {isDeliveryOrderType && isDeliverySpecialInstructionEnabled && (
              <OrderTypeSpecialInstruction
                label={appLabels["form"]["special-delivery-instructions-field-label"]}
                placeholder={appLabels["form"]["special-instructions"]}
                updateSpecialInstructions={updateOrderTypeSpecialInstructions}
                specialInstructions={orderTypeInstruction}
              />
            )}
            {(isPickupOrderType || isDineinOrderType) && isOrderLevelInstructionEnabled && (
              <OrderTypeSpecialInstruction
                label={
                  isPickupOrderType
                    ? appLabels["order"]["pickup-information"]
                    : appLabels["order"]["dinein-information-special-instructions"]
                }
                placeholder={
                  isPickupOrderType &&
                  !!appLabels["order"]["pickup-information-special-instruction-placeholder"]
                    ? appLabels["order"]["pickup-information-special-instruction-placeholder"]
                    : appLabels["form"]["special-instructions"]
                }
                additionalInformationText={
                  isPickupOrderType ? appLabels["order"]["pickup-information-subheading"] : ""
                }
                updateSpecialInstructions={updateOrderTypeSpecialInstructions}
                specialInstructions={orderTypeInstruction}
              />
            )}
          </Form>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </>
  );
};

export const getMarquiStepsText = async () => {
  try {
    const response1 = await fetch("loyalty/how-rewards-work-step-1.html");
    const data1 = await response1.text();
    const content1 = data1
      .replace(/<div>&nbsp;<\/div>/g, "")
      .replace(/<p>&nbsp;<\/p>/g, "")
      .replace(/&nbsp;/g, ""); // removes unwanted Marqui-autoinserted RTB spaces

    const response2 = await fetch("loyalty/how-rewards-work-step-2.html");
    const data2 = await response2.text();
    const content2 = data2
      .replace(/<div>&nbsp;<\/div>/g, "")
      .replace(/<p>&nbsp;<\/p>/g, "")
      .replace(/&nbsp;/g, ""); // removes unwanted Marqui-autoinserted RTB spaces

    const response3 = await fetch("loyalty/how-rewards-work-step-3.html");
    const data3 = await response3.text();
    const content3 = data3
      .replace(/<div>&nbsp;<\/div>/g, "")
      .replace(/<p>&nbsp;<\/p>/g, "")
      .replace(/&nbsp;/g, ""); // removes unwanted Marqui-autoinserted RTB spaces

    const response4 = await fetch("loyalty/how-rewards-work-step-4.html");
    const data4 = await response4.text();
    const content4 = data4
      .replace(/<div>&nbsp;<\/div>/g, "")
      .replace(/<p>&nbsp;<\/p>/g, "")
      .replace(/&nbsp;/g, ""); // removes unwanted Marqui-autoinserted RTB spaces

    const response5 = await fetch("loyalty/how-rewards-work-step-5.html");
    const data5 = await response5.text();
    const content5 = data5
      .replace(/<div>&nbsp;<\/div>/g, "")
      .replace(/<p>&nbsp;<\/p>/g, "")
      .replace(/&nbsp;/g, ""); // removes unwanted Marqui-autoinserted RTB spaces

    const response6 = await fetch("loyalty/how-rewards-work-step-6.html");
    const data6 = await response6.text();
    const content6 = data6
      .replace(/<div>&nbsp;<\/div>/g, "")
      .replace(/<p>&nbsp;<\/p>/g, "")
      .replace(/&nbsp;/g, ""); // removes unwanted Marqui-autoinserted RTB spaces

    return {
      "step-1": content1,
      "step-2": content2,
      "step-3": content3,
      "step-4": content4,
      "step-5": content5,
      "step-6": content6,
    };
  } catch (error) {
    console.error(error);
    return false;
  }
};

// Libraries
import React, { useState, useEffect } from "react";
import { HashRouter } from "react-router-dom";
import localforage from "localforage";
import { LoadScript } from "@react-google-maps/api";

// Helpers functions
import { fetchJSON } from "../_common/helpers/fetchJSON";
import { isValidImage } from "../_common/helpers/isValidImage";
import { fetchText } from "../_common/helpers/fetchText";
import { getContrastColor } from "../_common/colorHelpers";

// API helper functions
import { getMerchantConfigs } from "./apiHelpers/getMerchantConfigs";

// Contexts
import { ManifestProvider } from "./ManifestContext";
import { BrandingProvider } from "./BrandingContext";
import { AppSettingsProvider } from "./AppSettingsContext";
import { AppLanguageProvider } from "./AppLanguageContext";
import { MerchantConfigProvider } from "./MerchantConfigContext";
import { AppLabelsProvider } from "./AppLabelsContext";
import { StaticPagesDataContextProvider } from "./contexts/StaticPagesDataContext";
import { CWS5ModuleProvider } from "./CWS5ModuleContext";

// UI components
import { App } from "./App";
import { LoadingSpinner } from "../_common/LoadingSpinner";
import { GenericAppError } from "./GenericAppError";

// CSS
import "./App.css";

// Global constants for this component
const PRIMARY_COLOR_FALLBACK = "#7fad0e";
const SECONDARY_COLOR_FALLBACK = "#0086ff";
const BUTTON_TEXT_COLOR_FALLBACK = "#fff";
const HEADER_FOOTER_BACKGROUND_FALLBACK = "#fff";

const isLocalhost = process.env.NODE_ENV === "development";
const isTestingServer = () => {
  return (
    window.location.hostname.includes("alpha-wwws.givex") ||
    window.location.hostname.includes("beta-wwws.givex")
  );
};

export const MAP_KEY = isLocalhost
  ? "AIzaSyDq5bz2kVbqYwDoWFmb-S-KSM-gWOsxAfg"
  : isTestingServer()
  ? "AIzaSyAt7gbpZZw05XX6K2Q-xXaWSojgsIjS3cc"
  : "AIzaSyCc7FbF6kTX2q4Gdc9OH-oZFMNtZCGYxfc";
const MAP_LIBRARIES = ["places", "geometry"];

function AppSetup({ relativePath, languageFromPathname }) {
  const [isFailedJSONFetch, setIsFailedJSONFetch] = useState(false);

  const [merchantSkinName, setMerchantSkinName] = useState(null);
  const [merchantDefaultLanguage, setMerchantDefaultLanguage] = useState("");
  const [appLanguageContext, setAppLanguageContext] = useState(null);
  const [appDefaultLabelsPath, setAppDefaultLabelsPath] = useState(null);
  const [hasDefaultLanguage, setHasDefaultLanguage] = useState(null);
  const [isSingleLanguage, setIsSingleLanguage] = useState(null);

  useEffect(() => {
    if (hasDefaultLanguage !== null && isSingleLanguage !== null && appDefaultLabelsPath !== null) {
      if (hasDefaultLanguage && isSingleLanguage) {
        setAppLanguageContext(appDefaultLabelsPath.replace("/", ""));
      } else if (hasDefaultLanguage && !isSingleLanguage) {
        window.location = `${window.location.origin}${window.location.pathname}${appDefaultLabelsPath}${window.location.hash}`;
      }
    }
  }, [isSingleLanguage, hasDefaultLanguage, appDefaultLabelsPath]);
  // Fetch merchant's skin name and use it determine app language and relative language path
  useEffect(() => {
    fetchJSON(`${relativePath}skin.json`).then((fetchedData) => {
      if (fetchedData) {
        let tempAppLanguageContext;
        // TODO: replace the first if statement with an API call to get the default language for that merchant that way
        if (fetchedData.hasOwnProperty("default-language") && !!fetchedData["default-language"]) {
          tempAppLanguageContext = fetchedData["default-language"];
          setMerchantDefaultLanguage(fetchedData["default-language"]);
        } else if (languageFromPathname === "") {
          tempAppLanguageContext = "en";
        } else {
          tempAppLanguageContext = languageFromPathname;
        }

        setMerchantSkinName(fetchedData.skin);

        const defaultLabelsPath =
          tempAppLanguageContext && tempAppLanguageContext !== "en" && languageFromPathname !== "en"
            ? !!languageFromPathname
              ? languageFromPathname + "/"
              : tempAppLanguageContext + "/"
            : "";

        setAppDefaultLabelsPath(defaultLabelsPath);
        if (
          defaultLabelsPath !== "" &&
          tempAppLanguageContext !== "en" &&
          !window.location.href.includes(defaultLabelsPath) &&
          !window.location.href.includes("?lang") &&
          !sessionStorage.getItem(fetchedData.skin + "___selectedLanguage")
        ) {
          setHasDefaultLanguage(true);
          //window.location = `${window.location.origin}${window.location.pathname}${defaultLabelsPath}${window.location.hash}`;
        } else if (window.location.href.includes("?lang")) {
          const url = window.location.href;
          const querySplit = url.split("?");
          const urlParams = new URLSearchParams(querySplit[1]);
          const selectedLanguage = urlParams.get("lang");
          if (selectedLanguage === "en") {
            setAppDefaultLabelsPath("");
          } else {
            setAppDefaultLabelsPath(selectedLanguage + "/");
          }
          setAppLanguageContext(selectedLanguage);
          sessionStorage.setItem(fetchedData.skin + "___selectedLanguage", selectedLanguage);
        } else {
          let storedLanguage = sessionStorage.getItem(fetchedData.skin + "___selectedLanguage");
          if (storedLanguage) {
            setAppLanguageContext(storedLanguage);
            if (storedLanguage === "en") {
              setAppDefaultLabelsPath("");
            } else {
              setAppDefaultLabelsPath(storedLanguage + "/");
            }
          } else {
            setAppLanguageContext(tempAppLanguageContext);
          }
        }
      } else {
        setIsFailedJSONFetch(true);
      }
    });
  }, []);
  // END OF: Fetch merchant's skin name and use it determine app language and relative language path

  // Call and combine 1306 and 1003 APIs into 1 merchant config context object along with the skin name
  const [merchantConfigContext, setMerchantConfigContext] = useState("idle");
  useEffect(() => {
    if (merchantSkinName && appLanguageContext) {
      getMerchantConfigs(merchantSkinName, "MERCHANT", appLanguageContext).then((fetchedData) => {
        if (fetchedData.error) {
          setMerchantConfigContext(fetchedData);
        } else {
          setMerchantConfigContext({ ...fetchedData, "default-language": merchantDefaultLanguage });
          localforage.setItem(`${merchantSkinName}__merchantConfig`, fetchedData);
        }
      });
    }
  }, [merchantSkinName, appLanguageContext]);
  // END OF: Call and combine 1306 and 1003 APIs into 1 merchant config context object along with the skin name

  //Setup a context to keep track of which modules are enabled
  const [cws5Modules, setCws5Modules] = useState(null);
  useEffect(() => {
    if (merchantConfigContext !== "idle" && merchantConfigContext.merchant) {
      const tempModules = {
        isOnlineOrderingEnabled: merchantConfigContext.merchant.I36 === "t",
        isLoyaltyEnabled: merchantConfigContext.merchant.I22 === "t",
        isGiftCardEnabled: merchantConfigContext.merchant.I21 === "t",
        isScanEnabled: merchantConfigContext.merchant.I28 === "t",
        isRegistrationEnabled: merchantConfigContext.merchant.I24 === "t",
        isGuestEnabled: merchantConfigContext.merchant.I18 === "t",
        isBalanceTransferEnabled: merchantConfigContext.merchant.I30 === "t",
        isAutoReloadEnabled: merchantConfigContext.merchant.I12 === "t",
        isManualReloadEnabled: merchantConfigContext.merchant.I9 === "t",
      };
      setCws5Modules(tempModules);
    }
  }, [merchantConfigContext]);

  // Fetch, combine and set up labels for all enabled modules
  const [appLabelsContext, setAppLabelsContext] = useState(null);
  useEffect(() => {
    if (appDefaultLabelsPath != null) {
      const appLabels = {
        general: require(`./labels/${appDefaultLabelsPath}default-general-labels.js`).generalLabels,
        form: require(`../_common/Form/labels/${appDefaultLabelsPath}default-form-labels.js`)
          .formLabels,
      };

      fetchJSON(
        `${relativePath}${
          languageFromPathname !== "" ? languageFromPathname + "/" : ""
        }general-labels.json`
      ).then((fetchedData) => {
        if (fetchedData) {
          for (let key in fetchedData) {
            if (fetchedData[key]) {
              appLabels["general"][key] = fetchedData[key];
            }
          }
        }
      });

      if (merchantConfigContext.merchant) {
        appLabels["login-register"] =
          require(`../LoginRegister/labels/${appDefaultLabelsPath}default-login-register-labels.js`).loginRegisterLabels;

        appLabels["account"] =
          require(`../Account/labels/${appDefaultLabelsPath}default-account-labels.js`).accountLabels;

        fetchJSON(
          `${relativePath}${
            languageFromPathname !== "" ? languageFromPathname + "/" : ""
          }login-register/labels.json`
        ).then((fetchedData) => {
          if (fetchedData) {
            for (let key in fetchedData) {
              if (fetchedData[key]) {
                appLabels["login-register"][key] = fetchedData[key];
              }
            }
          }
        });

        fetchJSON(
          `${relativePath}${
            languageFromPathname !== "" ? languageFromPathname + "/" : ""
          }account/labels.json`
        ).then((fetchedData) => {
          if (fetchedData) {
            for (let key in fetchedData) {
              if (fetchedData[key]) {
                appLabels["account"][key] = fetchedData[key];
              }
            }
          }
        });

        appLabels["order"] =
          require(`../OnlineOrdering/labels/${appDefaultLabelsPath}default-order-labels.js`).orderLabels;

        fetchJSON(
          `${relativePath}${
            languageFromPathname !== "" ? languageFromPathname + "/" : ""
          }online-ordering/labels.json`
        ).then((fetchedData) => {
          if (fetchedData) {
            for (let key in fetchedData) {
              if (fetchedData[key]) {
                appLabels["order"][key] = fetchedData[key];
              }
            }
          }
        });

        appLabels["loyalty"] =
          require(`../MyRewards/labels/${appDefaultLabelsPath}default-loyalty-labels.js`).loyaltyLabels;

        fetchJSON(
          `${relativePath}${
            languageFromPathname !== "" ? languageFromPathname + "/" : ""
          }loyalty/labels.json`
        ).then((fetchedData) => {
          if (fetchedData) {
            for (let key in fetchedData) {
              if (fetchedData[key]) {
                appLabels["loyalty"][key] = fetchedData[key];
              }
            }
          }
        });

        appLabels["gift-card"] =
          require(`../GiftCard/labels/${appDefaultLabelsPath}default-gift-card-labels.js`).giftCardLabels;

        fetchJSON(
          `${relativePath}${
            languageFromPathname !== "" ? languageFromPathname + "/" : ""
          }gift-cards/labels.json`
        ).then((fetchedData) => {
          if (fetchedData) {
            for (let key in fetchedData) {
              if (fetchedData[key]) {
                appLabels["gift-card"][key] = fetchedData[key];
              }
            }
          }
        });
      }

      setAppLabelsContext(appLabels);
    }
  }, [appDefaultLabelsPath, merchantConfigContext]);
  // END OF: Fetch, combine and set up labels for all enabled modules

  /**
   * Fetch merchant's manifest file (even though it's been loaded from index.html already)
   * to use its values from context */
  const [pwaManifestContext, setPWAManifestContext] = useState(null);
  useEffect(() => {
    fetchJSON(`${relativePath}manifest.json`).then((fetchedData) => {
      if (fetchedData) {
        setPWAManifestContext(fetchedData);
      } else {
        setIsFailedJSONFetch(true);
      }
    });
  }, []);
  // END OF: Merchant's manifest JSON fetch

  /**
   * Fetch merchant's settings file to use its values from context.
   * Contains some app settings that are not controlled by AIVs */
  const [merchantAppSettingsContext, setMerchantAppSettingsContext] = useState(null);
  useEffect(() => {
    fetchJSON(
      isLocalhost
        ? `${relativePath}settings.json`
        : `${relativePath}${
            languageFromPathname !== "" ? languageFromPathname + "/" : ""
          }settings.json`
    ).then((fetchedData) => {
      if (fetchedData) {
        const fetchedDataCopy = { ...fetchedData };
        fetchedDataCopy["relative-path"] = relativePath;

        const languagesList = fetchedDataCopy["languages-list"].replace(/\s/g, "").toLowerCase();
        fetchedDataCopy["languages-list"] = languagesList ? languagesList.split(",") : "";
        setIsSingleLanguage(fetchedDataCopy["languages-list"] === "");
        setMerchantAppSettingsContext(fetchedDataCopy);
      } else {
        setIsFailedJSONFetch(true);
      }
    });
  }, []);
  // END OF: Merchant's settings JSON fetch

  // Fetch merchant's branding file to use its values from context
  const [merchantBrandingContext, setMerchantBrandingContext] = useState(null);
  useEffect(() => {
    const brandingUrl = isLocalhost
      ? `${relativePath}branding.json`
      : `${relativePath}${
          languageFromPathname !== "" ? languageFromPathname + "/" : ""
        }branding.json`;
    fetchJSON(brandingUrl).then((fetchedData) => {
      if (fetchedData) {
        const fetchedDataCopy = { ...fetchedData };

        // Modify relative image URLs based on the current language route
        Object.keys(fetchedDataCopy).forEach((key) => {
          const brandingItem = fetchedDataCopy[key];

          if (brandingItem.includes("files/")) {
            fetchedDataCopy[key] = relativePath + brandingItem;
          }
        });

        let merchantBranding = {
          ...fetchedDataCopy,
          "primary-colour": fetchedDataCopy["primary-colour"] || PRIMARY_COLOR_FALLBACK,
          "secondary-colour": fetchedDataCopy["secondary-colour"] || SECONDARY_COLOR_FALLBACK,
          "button-text-colour": fetchedDataCopy["button-text-colour"] || BUTTON_TEXT_COLOR_FALLBACK,
          "secondary-button-text-colour":
            fetchedDataCopy["secondary-button-text-colour"] || fetchedDataCopy["primary-colour"],
          "secondary-button-border-colour":
            fetchedDataCopy["secondary-button-border-colour"] || fetchedDataCopy["primary-colour"],
          "header-background-colour":
            fetchedDataCopy["header-background-colour"] || HEADER_FOOTER_BACKGROUND_FALLBACK,
          "footer-background-colour":
            fetchedDataCopy["footer-background-colour"] || HEADER_FOOTER_BACKGROUND_FALLBACK,
        };

        merchantBranding = {
          ...merchantBranding,
          "pay-in-store-button-colour":
            fetchedDataCopy["pay-in-store-button-colour"] || merchantBranding["primary-colour"],
          "pay-in-store-button-text-colour":
            fetchedDataCopy["pay-in-store-button-text-colour"] ||
            merchantBranding["button-text-colour"],
          "add-to-order-button-colour":
            fetchedDataCopy["add-to-order-button-colour"] || merchantBranding["primary-colour"],
          "add-to-order-button-text-colour":
            fetchedDataCopy["add-to-order-button-text-colour"] ||
            merchantBranding["button-text-colour"],
          "header-footer-active-text-colour":
            fetchedDataCopy["header-footer-active-text-colour"] ||
            merchantBranding["secondary-colour"],
          "header-active-text-colour":
            fetchedDataCopy["header-active-text-colour"] || merchantBranding["secondary-colour"],
          "header-text-and-icon-colour":
            fetchedDataCopy["header-text-and-icon-colour"] ||
            getContrastColor(merchantBranding["header-background-colour"]), //if there is no colour specified for the head text and icon colours get the contrast colour based on the background
          "footer-text-and-icon-colour":
            fetchedDataCopy["footer-text-and-icon-colour"] ||
            getContrastColor(merchantBranding["footer-background-colour"]), //if there is not colour specified for the footer text and icon colours get the contrast colour based on the background
          "online-order-settings-background-colour":
            fetchedDataCopy["online-order-settings-background-colour"] || "#fff",
          "online-order-settings-active-colour":
            fetchedDataCopy["online-order-settings-active-colour"] ||
            fetchedDataCopy["header-footer-active-text-colour"] ||
            merchantBranding["secondary-colour"],
          "menu-card-item-name-background":
            fetchedDataCopy["menu-card-item-name-background"] || "#fff",
        };

        merchantBranding = {
          ...merchantBranding,
          "gift-nav-link-indicator-colour":
            fetchedDataCopy["gift-nav-link-indicator-colour"] ||
            fetchedDataCopy["online-order-settings-active-colour"] ||
            fetchedDataCopy["header-footer-active-text-colour"] ||
            merchantBranding["secondary-colour"],
        };

        const isDevEnvironment = process.env.NODE_ENV === "development";

        const noImagePlaceholder = isDevEnvironment
          ? require("../_common/Images/no-image-available.jpg")
          : fetchedDataCopy["no-image-available-placeholder"];

        const noAddonThankyouImage =
          isDevEnvironment || !fetchedDataCopy["no-thank-you-addon-image"]
            ? require("../_common/Images/no-thank-you-addon-option.jpg")
            : fetchedDataCopy["no-thank-you-addon-image"];

        const onlineOrderingMenuBanner = fetchedDataCopy["online-ordering-landing-page"];

        const onlineOrderingMenuItemBanner = isDevEnvironment
          ? require("../_common/Images/cws5_banner_oloitem.jpg")
          : fetchedDataCopy["online-ordering-item-page"];

        const dashboardBanner = isDevEnvironment
          ? require("../_common/Images/cws5_banner_dashboard2.jpg")
          : fetchedDataCopy["dashboard-banner"];

        const dashboardBannerMobile = isDevEnvironment
          ? require("../_common/Images/acai-bowl-resize.png")
          : fetchedDataCopy["dashboard-banner-mobile"];

        const loyaltyHowItWorksBanner = isDevEnvironment
          ? require("../_common/Images/cws5_banner_extra1.jpg")
          : fetchedDataCopy["loyalty-how-it-works"];

        const loyaltyBanner = isDevEnvironment
          ? require("../_common/Images/cws5_banner_loyalty.jpg")
          : fetchedDataCopy["loyalty-main-tab"];

        const noGiftCardImagePlaceholder = isDevEnvironment
          ? require("../_common/Images/gift-card-placeholder-image.png")
          : fetchedDataCopy["no-image-available-giftcard-placeholder"];

        const outOfStockImagePlaceholder = isDevEnvironment
          ? require("../_common/Images/item-out-of-stock.png")
          : fetchedDataCopy["item-out-of-stock-image"];

        // add in gift card banner displayed on gift cards purchase page
        const giftCardsBannerImage = isDevEnvironment
          ? require("../_common/Images/sample_gift_card_banner.jpeg")
          : fetchedDataCopy["gift-cards-banner-image"];

        merchantBranding = {
          ...merchantBranding,
          "no-image-available-placeholder": isValidImage(noImagePlaceholder)
            ? noImagePlaceholder
            : require("../_common/Images/no-image-available.jpg"),
          "no-thank-you-addon-image": isValidImage(noAddonThankyouImage)
            ? noAddonThankyouImage
            : require("../_common/Images/no-thank-you-addon-option.jpg"),

          "online-ordering-landing-page": isValidImage(onlineOrderingMenuBanner)
            ? onlineOrderingMenuBanner
            : "",
          "online-ordering-item-page": isValidImage(onlineOrderingMenuItemBanner)
            ? onlineOrderingMenuItemBanner
            : "",
          "dashboard-banner": isValidImage(dashboardBanner) ? dashboardBanner : "",
          "dashboard-banner-mobile": isValidImage(dashboardBannerMobile)
            ? dashboardBannerMobile
            : "",
          "loyalty-how-it-works": isValidImage(loyaltyHowItWorksBanner)
            ? loyaltyHowItWorksBanner
            : "",
          "loyalty-main-tab": isValidImage(loyaltyBanner) ? loyaltyBanner : "",
          "no-image-available-giftcard-placeholder": isValidImage(noGiftCardImagePlaceholder)
            ? noGiftCardImagePlaceholder
            : require("../_common/Images/gift-card-placeholder-image.png"),
          "item-out-of-stock-image": isValidImage(outOfStockImagePlaceholder)
            ? outOfStockImagePlaceholder
            : require("../_common/Images/item-out-of-stock.png"), // TODO: instead of falling back to an image we should fallback to a component that renders the same with JSX + CSS
          "gift-cards-banner-image": isValidImage(giftCardsBannerImage) ? giftCardsBannerImage : "",
        };

        setMerchantBrandingContext(merchantBranding);
      } else {
        setIsFailedJSONFetch(true);
      }
    });
  }, []);
  // END OF: Merchant's branding JSON fetch

  const [staticPagesDataContext, setStaticPagesDataContext] = useState(null);
  useEffect(() => {
    fetchJSON("static-pages.json").then((fetchedStaticPagesData) => {
      if (fetchedStaticPagesData) {
        const fetchedStaticPages = { ...fetchedStaticPagesData }["static-pages"];

        fetchedStaticPages.forEach((pageData) => {
          pageData.path = `/${pageData.path}`;

          if (pageData.contentPath && pageData.contentPath !== "default.html") {
            pageData.contentPath = `static-pages/${pageData.contentPath}`;
          }

          if (pageData.iconFilename.includes(".svg")) {
            pageData.iconFilename = `${relativePath}files/Images/static-pages/icons/${pageData.iconFilename}`;
          } else {
            delete pageData.iconFilename;
          }
        });

        setStaticPagesDataContext(fetchedStaticPages);
      }
    });
  }, []);

  // Fetch script that contains merchant-specific snippets that'll be inserted into head via Helmet
  const [customHeadScriptContents, setCustomHeadScriptContents] = useState("");
  useEffect(() => {
    fetchText(`${relativePath}custom-head-script.js`).then((fetchedData) => {
      if (fetchedData) setCustomHeadScriptContents(fetchedData);
    });
  }, []);
  // END OF: Custom head script fetch

  // Fetch script that contains merchant-specific snippets that'll be inserted at the end of body
  const [customBodyScriptContents, setCustomBodyScriptContents] = useState("");
  useEffect(() => {
    fetchText(`${relativePath}custom-body-script.js`).then((fetchedData) => {
      if (fetchedData) setCustomBodyScriptContents(fetchedData);
    });
  }, []);
  // END OF: Custom body script fetch

  return (
    <>
      {appLanguageContext != null &&
      merchantConfigContext.merchant &&
      appLabelsContext &&
      pwaManifestContext &&
      merchantAppSettingsContext &&
      merchantBrandingContext &&
      cws5Modules ? (
        <ManifestProvider value={pwaManifestContext}>
          <AppLanguageProvider value={appLanguageContext}>
            <AppSettingsProvider value={merchantAppSettingsContext}>
              <BrandingProvider value={merchantBrandingContext}>
                <MerchantConfigProvider value={merchantConfigContext}>
                  <AppLabelsProvider value={appLabelsContext}>
                    <StaticPagesDataContextProvider value={staticPagesDataContext}>
                      <CWS5ModuleProvider value={cws5Modules}>
                        <HashRouter>
                          <LoadScript
                            id="script-loader"
                            googleMapsApiKey={MAP_KEY}
                            libraries={MAP_LIBRARIES}
                            loadingElement={<LoadingSpinner />}
                          >
                            <App
                              appSettingsContext={merchantAppSettingsContext}
                              appLanguage={appLanguageContext}
                              appDefaultLabelsPath={appDefaultLabelsPath}
                              merchantConfigContext={merchantConfigContext}
                              appLabels={appLabelsContext}
                              customHeadScriptContents={customHeadScriptContents}
                              customBodyScriptContents={customBodyScriptContents}
                            />
                          </LoadScript>
                        </HashRouter>
                      </CWS5ModuleProvider>
                    </StaticPagesDataContextProvider>
                  </AppLabelsProvider>
                </MerchantConfigProvider>
              </BrandingProvider>
            </AppSettingsProvider>
          </AppLanguageProvider>
        </ManifestProvider>
      ) : (
        !merchantConfigContext.error && <LoadingSpinner isDefault={true} />
      )}
      {/* 1003/1306 merchant config API error display */}
      {merchantConfigContext.error && merchantConfigContext.statusCode && appLabelsContext && (
        <GenericAppError
          text={`${appLabelsContext["general"]["error-code"]}: ${merchantConfigContext.statusCode}. ${appLabelsContext["general"]["something-went-wrong-error"]}`}
        />
      )}
      {/* 1003/1306 merchant config generic fallback error display */}
      {merchantConfigContext.error && !merchantConfigContext.statusCode && appLabelsContext && (
        <GenericAppError text={appLabelsContext["general"]["something-went-wrong-error"]} />
      )}
      {/* Generic fallback error if any of the JSON fetch requests fail */}
      {isFailedJSONFetch && (
        <GenericAppError
          text={
            (appLabelsContext && appLabelsContext["general"]["something-went-wrong-error"]) ||
            "Something went wrong. Please try again later."
          }
        />
      )}
    </>
  );
}

export default AppSetup;

export const accountLabels = {
  profile: "Perfil",
  "log-out": "Salir",
  "log-out-confirm-message": "Esta seguro que desea salir?",
  "personal-info": "Información Personal",
  "update-your-personal-info": "Actualizar su información personal",
  update: "Actualizar",
  updating: "Actualizando…",
  "change-password": "Cambie su contraseña",
  "update-your-password": "Actualizar su contraseña",
  "current-username": "Nombre de usuario actual",
  "current-password": "Contraseña actual",
  "new-password": "Nueva contraseña",
  "confirm-new-password": "Confirme su nueva contraseña",
  "changing-password": "Cambiando contraseña",
  "payment-methods": "Formas de Pago",
  "add-credit-card": "Agregar tarjeta de crédito",
  "save-card": "Guardar tarjeta",
  "saving-card": "Guardando tarjeta",
  "add-new-card": "Agregar una nueva tarjeta",
  "add-new-card-paragraph":
    "Para agregar una nueva tarjeta, digite el numero de la tarjeta o escanee el código de barras",
  "scan-barcode": "Escanear Código de Barras",
  "scan-barcode-modal-heading": "Escanee el código de barras de su tarjeta",
  "add-card": "Agregar una tarjeta",
  adding: "Agregando . . .",
  added: "Agregado",
  "transaction-history": "Histórico de Transacciones",
  "recent-orders": "Pedidos Recientes",
  "recent-online-orders": "Pedidos Recientes Online",
  "recent-orders-disclaimer": "",
  "recent-orders-item-doesnt-exist-message":
    "* El total del pedido previo puede variar ya que los precios están sujetos a cambios",
  "no-previous-orders": "Uno o mas artículos de este pedido ya no existen",
  "recent-loyalty-transactions": "No hay pedidos anteriores",
  "recent-card-transactions": "Transacciones Recientes de Lealtad",
  "pay-in-store": "Transacciones Recientes de Tarjetas",
  "add-money": "Escanear en tienda",
  "add-money-submit-button": "Agregar Dinero",
  "refresh-code": "Agregar",
  "my-wallet": "Actualizar Código",
  card: "Mi Cartera",
  "method-of-payment": "Tarjeta",
  "reload-amount": "Forma de Pago",
  "auto-reload": "Recargar Cantidad",
  "auto-reload-amount": "Recarga Automática",
  "when-balance-reaches": "Recarga Automática de Cantidad",
  "choose-how-often-to-reload": "Cuando el saldo llegue a",
  "set-auto-reload": "o seleccione la frecuencia de recarga",
  "button-remove-autoreload": "Configurar Recarga Automática",
  "update-auto-reload": "Eliminar recarga automática",
  "update-account-details": "Actualizar Recarga Automática",
  "enter-password-to-confirm-changes": "Actualizar Detalles de la Cuenta",
  "balance-transfer-to-card-number": "Ingrese su contraseña para confirmar los cambios",
  "balance-transfer-success-message": "A su tarjeta de lealtad",
  "balance-transfer-new-points-balance": "Saldo Transferido Satisfactoriamente",
  "transfer-funds": "Su nuevo saldo en puntos es",
  "funds-transferred": "Transferir fondos",
  "balance-transfer-disclaimer": "Fondos Transferidos",
  "transfer-funds-blurb":
    "Una vez transferidos los fondos a su cuenta, la Tarjeta de Regalo se desactivara",
  "balance-transfer-to-gift-card":
    "Tiene una Tarjeta de Regalo? Transfiera fondos a su cuenta aqui.",
  "open-barcode-scanner": "A su Tarjeta de Regalo",
  "auto-reload-schedule-with-threshold": "Abrir lector de codigo de barras",
  "auto-reload-schedule-weekly":
    "[auto-reload] esta configurado para agregar [reload-amount] a esta tarjeta cuando el saldo llegue a [threshold-amount] o menos",
  "auto-reload-schedule-monthly":
    "[auto-reload] esta configurado para agregar [reload-amount] a esta tarjeta [frequency].",
  "order-status": "Estado de la orden",
  "order-status-active-orders-blurb": "Ud tiene [number-of-orders] pedido(s) en progreso",
  "order-details": "Detalles del Pedido",
  "order-status-make-changes-blurb":
    "Si desea hacer algun cambio por favor contacte a la tienda al [phone-number]",
  "order-placed": "Colocada",
  "order-accepted": "Aceptada",
  "order-cancelled": "Cancelada",
  "order-preparing": "Preparando",
  "order-ready-for-pickup": "Lista para recoger",
  "order-on-its-way": "En camino",
  "order-complete": "Completada",
  "order-in-progress": "Ordene(s) en Progreso",
  "past-orders": "Pedidos anteriores",
  "ordered-items": "Articulos Ordenados",
  "order-received": "Recibido",
  "order-void": "Cancelado",
  "order-rejected": "Declinado",
  "order-prepared": "Preparado",
  "order-status-last-update-time": "Ultima actualizacion en [time-stamp]",
  "order-reviewing": "Revisando",
  "order-timestamp-at": "en",
  "my-orders": "Mis pedidos",
  "request-account-deletion": "Solicitar eliminación de cuenta",
  "account-deletion-email-subject": "Solicitud de eliminación de cuenta - [customer_id]",
  "account-deletion-email-body":
    " INSTRUCCIONES:\nEnvíe este correo electrónico para enviar una solicitud para eliminar la siguiente cuenta en nombre de [merchant_name]:\n\nCUSTOMER ID:\n[customer_id]\n\nCUSTOMER User Name\n[customer_email]\n\nAl enviar, reconoce que esta solicitud es irreversible y que su cuenta [nombre_comerciante] junto con sus datos asociados se eliminarán de forma permanente. Recibirá una confirmación final por correo electrónico una vez que su cuenta se haya eliminado con éxito.",
  "delete-account": "Borrar cuenta",
  "delete-my-account": "Borrar mi cuenta",
  deleting: "Borrando...",
  "enter-password": "Introducir la contraseña",
  "delete-account-confirmation-header": "Confirmación",
  "delete-account-sorry-to-see-you-go": "¡Siento verte ir!",
  "delete-account-permanently":
    "¿Estás seguro de que quieres eliminar tu cuenta de forma permanente?",
  "delete-account-confirm-deletion":
    "Para confirmar la eliminación de su cuenta, ingrese su contraseña a continuación.",
  "delete-account-success-message": "¡Tu cuenta ha sido desactivada con éxito!",
  "delete-account-blurb1":
    "Después de [number-of-days] días, eliminaremos todos los registros y datos personales asociados con su cuenta.",
  "delete-account-blurb2":
    "Le hemos enviado un correo electrónico de confirmación a la dirección de correo electrónico asociada con su cuenta",
  "remove-address-confirmation": "¿Está seguro de que desea eliminar esta dirección?",
  "add-delivery-address": "Agregar una dirección de entrega",
  "confirm-delivery-address": "Confirmar dirección de entrega",
  "select-delivery-address": "Seleccionar dirección de entrega"
};

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

/** Context */
import AppLabelsContext from "../App/AppLabelsContext";
import AppLanguageContext from "../App/AppLanguageContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import StoreContext from "../OnlineOrdering/StoreContext";
import CartContext from "../OnlineOrdering/Cart/CartContext";
import { MenuContext } from "../OnlineOrdering/Menu/MenuContext";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";

/** Helper Functions */
import { get1300Stores } from "../OnlineOrdering/GoogleMaps/get1300Stores";

/** UI Elements */
import { Form } from "../_common/Form/Form";
import { FormInput } from "../_common/Form/FormInput";
import { DialogModal } from "../_common/DialogModal/DialogModal";

/** Assets */
import { ReactComponent as IconAlert } from "../_common/icons/IconAlert.svg";

/** CSS */
import "./OrderSeatRowSection.css";
import useWindowSize from "../_common/hooks/useWindowSize";

const OrderSeatRowSection = () => {
  const history = useHistory();

  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const merchantConfig = useContext(MerchantConfigContext);
  const skin = merchantConfig.skin;
  const orderTypeContext = useContext(OrderTypeContext);
  const orderStore = useContext(StoreContext);
  const orderCart = useContext(CartContext);
  const orderMenu = useContext(MenuContext);

  const deviceWidth = useWindowSize().width;

  const [isAPISubmitting, setIsAPISubmitting] = useState(false);
  const [noAvailableStores, setNoAvailableStores] = useState(false);
  const [apiError, setAPIerror] = useState(null);
  const [sectionRowSeat, setSectionRowSeat] = useState("idle");

  const onConfirmSection = (formData) => {
    let section, row, seat;
    formData.forEach((field) => {
      if (field.name === "section") {
        section = field.value;
      } else if (field.name === "row") {
        row = field.value;
      } else if (field.name === "seat") {
        seat = field.value;
      }
    });

    const schema = `${section}/${row}/${seat}`;
    checkStoreAvailability(schema);
  };

  const checkStoreAvailability = (schema) => {
    setIsAPISubmitting(true);
    get1300Stores(skin, "delivery", appLanguage, "", "", schema)
      .then((data1300) => {
        setIsAPISubmitting(true);
        if (data1300) {
          if (data1300.error) {
            setAPIerror(data1300.error);
          } else if (data1300.length === 0) {
            setNoAvailableStores(true);
          } else {
            localforage.setItem(
              skin + "__stadium-schema",
              JSON.stringify({
                section: schema.split("/")[0],
                row: schema.split("/")[1],
                seat: schema.split("/")[2],
              })
            );
            history.push({
              pathname: "/online-ordering/order-store",
              state: { from: "stadium-section", userAddress: schema },
            });
          }
        }
      })
      .catch((error) => {
        setIsAPISubmitting(false);
        console.error(error);
      })
      .finally(() => {
        setIsAPISubmitting(false);
      });
  };

  const resetMenu = () => {
    localforage.removeItem(skin + "__storedMenu");
    orderMenu.updateMenu({
      ...orderMenu,
      apiData: null,
      activeTopCategory: null,
      activeSubcategory: null,
      activeItem: null,
      menuLevel: null,
    });
  };

  const clearOrderStorage = () => {
    localforage.removeItem(skin + "__activeOrderType");
    localforage.removeItem(skin + "__orderDate");
    localforage.removeItem(skin + "__orderTime");
    localforage.removeItem(skin + "__trueBusinessDate");

    localforage.removeItem(skin + "__storedMenu");

    localforage.removeItem(skin + "__storedPromo");
    localforage.removeItem(skin + "__storedRewards");
    localforage.removeItem(skin + "__storedRewardsDetails");
    localforage.removeItem(skin + "__activeOrderStoreTable");

    localforage.removeItem(skin + "__storedTip");

    localforage.removeItem(skin + "__storedPromoCode");
    orderCart.updateCart(null);
    localforage.removeItem(skin + "__userDeliveryAddress");
    localforage.removeItem(skin + "__cart");
    orderStore.updateActiveOrderStore(null);
    orderStore.updateActiveOrderStoreTable(null);
    resetMenu();
  };

  useEffect(() => {
    localforage.getItem(skin + "__stadium-schema").then((schema) => {
      if (schema) {
        let parsedSchema = JSON.parse(schema);
        setSectionRowSeat({
          section: parsedSchema.section,
          seat: parsedSchema.seat,
          row: parsedSchema.row,
        });
      } else {
        setSectionRowSeat(null);
      }
      localforage.removeItem(skin + "__stadium-schema");
    });
  }, []);

  return (
    <>
      {sectionRowSeat !== "idle" && (
        <div className="desktop-container">
          {deviceWidth >= 660 && (
            <div className="delivery-address__header-container">
              <h2>{appLabels["order"]["confirm-seating"]}</h2>
            </div>
          )}

          <Form
            id="form--seat-row-section"
            className={`form--seat-row-section`}
            submitForm={onConfirmSection}
            isAPIsubmitting={isAPISubmitting}
            isDisableSubmit={isAPISubmitting}
            submitAPIError={apiError}
            resetAPIError={() => setAPIerror("")}
            submitButtonText={appLabels["general"]["dialog-modal-confirm"]}
            isFAB={true}
            isModalTrigger={false}
            isModalFAB={false}
            buttonWrapperClass={"form--seat-row-section__floating-button"}
          >
            <FormInput
              type="text"
              label={appLabels["form"]["section"]}
              id="input--section"
              name="section"
              isRequired={true}
              defaultValue={sectionRowSeat ? sectionRowSeat.section : ""}
            />

            <FormInput
              type="text"
              label={appLabels["form"]["row"]}
              id="input--row"
              name="row"
              isRequired={true}
              defaultValue={sectionRowSeat ? sectionRowSeat.row : ""}
            />

            <FormInput
              type="text"
              label={appLabels["form"]["seat"]}
              id="input--seat"
              name="seat"
              isRequired={true}
              defaultValue={sectionRowSeat ? sectionRowSeat.seat : ""}
            />
          </Form>
        </div>
      )}

      {noAvailableStores && (
        <DialogModal
          isCancelConfirm={!!merchantConfig.vexilor.I2.active_order_types.includes("pickup")}
          isHTMLContent={true}
          message={
            <div className="seat-row-section__error-container">
              <p>
                {" "}
                <IconAlert aria-hidden="true" />
                {appLabels["order"]["stadium-service-not-available"]}
              </p>
            </div>
          }
          resetRemoveDialog={() => {
            setNoAvailableStores(false);
          }}
          confirmAction={async () => {
            clearOrderStorage();
            localforage.removeItem(skin + "__stadium-schema");
            await orderTypeContext.update("pickup");
            history.push("/online-ordering/order-store");
          }}
          isCancelConfirmText={appLabels["order"]["order-pickup"]}
          isModifyText={appLabels["order"]["edit-seating"]}
          isCustomConfirmText={appLabels["order"]["edit-seating"]}
        />
      )}
    </>
  );
};

export default OrderSeatRowSection;

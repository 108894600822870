import React, { useState, useEffect, useContext, useRef } from "react";

/** Helpers */
import {
  groupHasDefaultSelection,
  optionGroupHasAtLeastOneImage,
} from "../../helpers/orderingHelpers";
import { reachedMaxNumberOfSelection } from "../MenuItemDetailHelpers";
import { jsonCopy } from "../../../../_common/helpers";

/** UI Components */
import { AddonModifierLabel } from "./AddonModifierLabel";
import { NestedAddonModifierGroup } from "./NestedAddonModifierGroup";
import BrandingContext from "../../../../App/BrandingContext";

export const SingleChoiceAddonModifier = (props) => {
  const {
    topLevelOptionGroup,
    selectedOption,
    updateSelectedOption,
    instructionsText,
    mandatoryOptionClass,
    isInCart,
    isBeingSelected,
    //topLevelOptionGroupCopy,
    comboChildItem,
    isTheSameChildItem,
    updateOrderingState,
    setGoToNextGroup,
    isStepByStepOrderingEnabled,
    isEditingComboItem,
    displayType,
    cartItem,
    comboCartItem,
  } = props;

  const topLevelOptionGroupCopy = jsonCopy(topLevelOptionGroup);
  const brandingContext = useContext(BrandingContext);
  const isModifyDisplay = displayType === "cart-modify";
  const [openNestedGroup, setOpenNestedGroup] = useState(true);
  const [openNestedGroupParentId, setOpenNestedGroupParentId] = useState(null);
  const [nestedGroupIsOpen, setNestedGroupIsOpen] = useState(null);

  const isStandalone = !topLevelOptionGroup.id || topLevelOptionGroup.isStandalone;
  const defaultAddonImage = brandingContext["no-image-available-placeholder"];
  /*const topLevelOptions =
    isInCart && !!comboChildItem && !isTheSameChildItem
      ? topLevelOptionGroup.items
      : topLevelOptionGroupCopy.items;*/

  const [topLevelOptions, setTopLevelOptions] = useState(null);
  useEffect(() => {
    if (topLevelOptionGroup && topLevelOptionGroupCopy) {
      setTopLevelOptions(
        isInCart && !!comboChildItem && !isTheSameChildItem
          ? topLevelOptionGroup.items
          : topLevelOptionGroupCopy.items
      );
    }
  }, [isInCart, comboChildItem, isTheSameChildItem, topLevelOptionGroup]);

  const atLeastOneAddonImage = optionGroupHasAtLeastOneImage(topLevelOptionGroup);
  const showAddonGroupImages =
    atLeastOneAddonImage &&
    (topLevelOptionGroup.type === "addonGroup" ||
      (Object.keys(topLevelOptionGroup.items).length > 0 &&
        Object.keys(topLevelOptionGroup.items)[0].includes("addon")));

  /** This Ref is used to flag that the initial state of the nested groups were set to collapsed when modifying */
  const setInitialStateAsCollapsed = useRef([]);

  const allNestedGroupsAreCollapsed = () => {
    let allNestedGroupsAreCollapsed = true;

    Object.keys(topLevelOptions).forEach((groupKey) => {
      if (topLevelOptions[groupKey].isSelected) {
        const topLevelOption = topLevelOptions[groupKey];
        if (topLevelOption.modifierGroups) {
          Object.keys(topLevelOption.modifierGroups).forEach((key) => {
            const element = document.getElementById(
              `${topLevelOption.modifierGroups[key].id}_${topLevelOption.modifierGroups[key].parentId}_${topLevelOption.parentId}`
            );
            if (element && element.style.display !== "none") {
              allNestedGroupsAreCollapsed = false;
            }
          });
        }
      }
    });
    return allNestedGroupsAreCollapsed;
  };

  const onRadioChange = (event) => {
    const field = event.target;
    const mainFieldContainer = field.parentElement.parentElement.parentElement;
    const elementLabels = mainFieldContainer.querySelectorAll(`label`);
    elementLabels.forEach((label) => {
      label.classList.remove("error");
    });

    setOpenNestedGroup(false);
    Object.keys(topLevelOptions).forEach((key) => {
      delete topLevelOptions[key].isSelected;

      if (topLevelOptions[key].modifierGroups) {
        Object.keys(topLevelOptions[key].modifierGroups).forEach((nestedModKey) => {
          const nestedModGroup = topLevelOptions[key].modifierGroups[nestedModKey];
          delete nestedModGroup.isSelected;

          /** If there is a collapsed nested modifier group, remove it from the DOM, since only one radio can be chosen at any one time  */
          const parentId = isStandalone
            ? `${nestedModGroup.parentId}-standalone`
            : `${nestedModGroup.parentId}_${topLevelOptions[key].parentId}`;

          const element = document.getElementById(
            `${nestedModGroup.id}_${nestedModGroup.parentId}_${topLevelOptions[key].parentId}-${parentId}`
          );
          if (element) {
            element.remove();
          }
          const nestedModGroupItems = nestedModGroup.items;

          if (nestedModGroupItems) {
            Object.keys(nestedModGroupItems).forEach((nestedModItemKey) => {
              const nestedModGroupItem = nestedModGroupItems[nestedModItemKey];
              delete nestedModGroupItem.isSelected;
            });
          }
        });
      }
    });

    const newSelectedOption = topLevelOptions[field.dataset.keyName];
    newSelectedOption.isSelected = true;

    if (isStandalone) newSelectedOption.isStandalone = true;

    //check to see if the selected option has a nested modifier and if it has default modifiers
    const hasNestedModifiers =
      newSelectedOption.modifierGroups && Object.keys(newSelectedOption.modifierGroups).length > 0;

    if (hasNestedModifiers) {
      const nestedModifiers = newSelectedOption.modifierGroups;
      const nesterModifierKeys = Object.keys(nestedModifiers);

      for (let nestedModGroupKey in nesterModifierKeys) {
        const nestedItems =
          newSelectedOption.modifierGroups[nesterModifierKeys[nestedModGroupKey]].items;
        const nestedItemKeys = Object.keys(nestedItems);

        for (let nestedModItemKey in nestedItemKeys) {
          if (nestedItems[nestedItemKeys[nestedModItemKey]].isDefault === "True") {
            newSelectedOption.modifierGroups[
              `addonModifierGroup-${
                newSelectedOption.modifierGroups[nesterModifierKeys[nestedModGroupKey]].id
              }`
            ].items[`modifier-${nestedItems[nestedItemKeys[nestedModItemKey]].id}`] =
              nestedItems[nestedItemKeys[nestedModItemKey]];

            newSelectedOption.modifierGroups[
              `addonModifierGroup-${
                newSelectedOption.modifierGroups[nesterModifierKeys[nestedModGroupKey]].id
              }`
            ].items[
              `modifier-${nestedItems[nestedItemKeys[nestedModItemKey]].id}`
            ].isSelected = true;

            newSelectedOption.modifierGroups[
              `addonModifierGroup-${
                newSelectedOption.modifierGroups[nesterModifierKeys[nestedModGroupKey]].id
              }`
            ].isSelected = true;
          }
        }
      }
    }
    updateSelectedOption(newSelectedOption);
    /**if combo item, check if the maximum number of selection has reached
     * if regular item, the useEffect will take care of this
     */
    if (!!comboCartItem) {
      if (
        topLevelOptionGroup.maxQuantity !== "0" &&
        reachedMaxNumberOfSelection(
          jsonCopy({ ...topLevelOptions, newSelectedOption }),
          topLevelOptionGroup.maxQuantity,
          jsonCopy(topLevelOptionGroup).id
        ) &&
        !groupHasDefaultSelection(newSelectedOption)
      ) {
        if (allNestedGroupsAreCollapsed()) {
          setTimeout(() => {
            setGoToNextGroup(true);
          }, 90);
        }
      }
    }
  };

  /** this useEffect will remove the closed nested mod widget if it's parent option become unselected */
  useEffect(() => {
    if (topLevelOptions) {
      Object.keys(topLevelOptions).forEach((itemKey) => {
        const option = topLevelOptions[itemKey];
        if (
          !option.isSelected &&
          option.modifierGroups &&
          !!Object.keys(option.modifierGroups).length
        ) {
          Object.keys(option.modifierGroups).forEach((nestModGroupKey) => {
            if (
              document.getElementById(
                `${option.modifierGroups[nestModGroupKey].id}_${option.id}_${option.parentId}-${option.id}_${option.parentId}`
              )
            ) {
              document
                .getElementById(
                  `${option.modifierGroups[nestModGroupKey].id}_${option.id}_${option.parentId}-${option.id}_${option.parentId}`
                )
                .remove();
            }
          });
        }
      });
    }
  }, [topLevelOptions]);

  const scrolledToNestedGroup = useRef(false);
  useEffect(() => {
    if (nestedGroupIsOpen !== null && isStepByStepOrderingEnabled) {
      updateOrderingState({ hideAllButtons: nestedGroupIsOpen });
      if (!nestedGroupIsOpen) {
        scrolledToNestedGroup.current = false;
      }
    }
  }, [nestedGroupIsOpen]);

  /**check if the maximum number of selection has reached */
  useEffect(() => {
    if (topLevelOptions && !comboCartItem) {
      if (
        topLevelOptionGroup.maxQuantity !== "0" &&
        reachedMaxNumberOfSelection(
          jsonCopy(topLevelOptions),
          topLevelOptionGroup.maxQuantity,
          jsonCopy(topLevelOptionGroup).id
        ) &&
        !groupHasDefaultSelection(topLevelOptions)
      ) {
        if (allNestedGroupsAreCollapsed()) {
          setGoToNextGroup(true);
        }
      }
    }
  }, [topLevelOptions]);

  /** This state will serve as a flag to determine if the group was already selected at time of initial render,
   * This flag will serve as a condition to see  if the initial state of any nested modifier group of this group should have a collapsed or expanded view
   */
  const [isParentGroupAlreadySelected, setIsParentGroupAlreadySelected] = useState(false);
  useEffect(() => {
    Object.keys(topLevelOptionGroup.items).forEach((itemKey) => {
      if (topLevelOptionGroup.items[itemKey].isSelected) {
        setIsParentGroupAlreadySelected(true);
      }
    });
  }, []);

  return (
    <fieldset
      className={`form__fieldset form__fieldset--top-level ${
        showAddonGroupImages ? " form__fieldset-addonModifier with-image-group" : " no-image-group"
      }`}
    >
      <legend className="form__fieldset-legend">
        <span>{topLevelOptionGroup.name}</span>
        <span className={`item-detail__option-note${mandatoryOptionClass}`}>
          {instructionsText}
        </span>
      </legend>

      {topLevelOptions &&
        Object.keys(topLevelOptions).map((key, index) => (
          <React.Fragment key={key}>
            <div
              className={`form__field-wrapper--checkbox-radio__background ${
                topLevelOptions[key].isSelected ? " is-checked" : ""
              } ${!showAddonGroupImages ? " no-image" : " with-image"}`}
              id={
                topLevelOptions[key].isStandalone
                  ? `${topLevelOptions[key].id}-standalone`
                  : `${topLevelOptions[key].id}_${topLevelOptions[key].parentId}`
              }
              style={
                showAddonGroupImages
                  ? {
                      animation: "scaleExpand 0.3s forwards",
                      animationDelay: `${index * 150}ms`,
                    }
                  : {
                      animation: "scaleExpandY 0.5s forwards",
                      animationDelay: `${index * 150}ms`,
                    }
              }
            >
              <div
                className={`form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--radio ${
                  topLevelOptions[key].isSelected ? " is-checked" : ""
                }`}
              >
                {showAddonGroupImages && (
                  <img
                    src={topLevelOptions[key].imageUrl || defaultAddonImage}
                    alt={topLevelOptions[key].name}
                    className="addon-modifier_image"
                  />
                )}
                <input
                  type="radio"
                  name={topLevelOptionGroup.id ? topLevelOptionGroup.id : topLevelOptions[key].name}
                  value={topLevelOptions[key].id}
                  defaultChecked={
                    (selectedOption &&
                      selectedOption.id === topLevelOptions[key].id &&
                      selectedOption.isFirstInstanceDefault) ||
                    ((topLevelOptions[key].isSelected ||
                      (selectedOption &&
                        selectedOption.id === topLevelOptions[key].id &&
                        selectedOption.isSelected)) &&
                      (isInCart || isBeingSelected))
                  }
                  id={`option-${topLevelOptions[key].parentId}-${topLevelOptions[key].id}`}
                  data-key-name={key}
                  className="form__field--checkbox-radio form__field--radio visually-hidden"
                  onChange={onRadioChange}
                  disabled={
                    Number(topLevelOptions[key].item_count) <= 0 ||
                    (openNestedGroupParentId && openNestedGroupParentId !== topLevelOptions[key].id)
                  }
                />
                <AddonModifierLabel
                  option={topLevelOptions[key]}
                  isRadio={true}
                  customClass={` ${
                    showAddonGroupImages
                      ? topLevelOptions[key].isSelected
                        ? " is-checked"
                        : "is-not-checked"
                      : ""
                  }`}
                  id={`option-${topLevelOptions[key].parentId}-${topLevelOptions[key].id}-label`}
                />
                {showAddonGroupImages && (
                  <button
                    type="button"
                    data-key-name={key}
                    onClick={() => {
                      document
                        .getElementById(
                          `option-${topLevelOptions[key].parentId}-${topLevelOptions[key].id}-label`
                        )
                        .click();
                    }}
                    className="addon-modifier-choice__overlay-button"
                    aria-hidden={true}
                  ></button>
                )}
              </div>
              {showAddonGroupImages &&
                openNestedGroupParentId &&
                openNestedGroupParentId !== topLevelOptions[key].id && (
                  <div className="form__field-wrapper--checkbox-radio__gray-overlay"></div>
                )}
            </div>
            {topLevelOptions[key].isSelected && (
              <>
                {topLevelOptions[key].modifierGroups &&
                  Object.keys(topLevelOptions[key].modifierGroups).map((nestedGroupKey) => (
                    <NestedAddonModifierGroup
                      key={nestedGroupKey}
                      nestedOptionGroup={topLevelOptions[key].modifierGroups[nestedGroupKey]}
                      selectedOption={selectedOption}
                      updateSelectedOption={updateSelectedOption}
                      topLevelOptionKey={key}
                      type="modifier"
                      isInCart={isInCart}
                      isBeingSelected={isBeingSelected}
                      topLevelOption={topLevelOptions[key]}
                      openNestedGroup={openNestedGroup}
                      setOpenNestedGroup={setOpenNestedGroup}
                      showAddonGroupImages={showAddonGroupImages}
                      setOpenNestedGroupParentId={setOpenNestedGroupParentId}
                      setNestedGroupIsOpen={setNestedGroupIsOpen}
                      setGoToNextGroup={setGoToNextGroup}
                      parentAddonGroupIsSingleChoice={true}
                      labelId={`option-${topLevelOptions[key].parentId}-${topLevelOptions[key].id}-label`}
                      scrolledToNestedGroup={scrolledToNestedGroup}
                      isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
                      isStandaloneParent={topLevelOptions[key].isStandalone}
                      isEditingComboItem={isEditingComboItem}
                      isModifyDisplay={isModifyDisplay}
                      mainAddonGroupParentId={
                        isStandalone ? topLevelOptions[key].id : topLevelOptions[key].parentId
                      }
                      mainAddonGroupId={topLevelOptions[key].id}
                      topLevelOptionGroup={topLevelOptionGroup}
                      comboChildItem={comboChildItem}
                      cartItem={cartItem}
                      setInitialStateAsCollapsed={setInitialStateAsCollapsed}
                      comboCartItem={comboCartItem}
                      isParentGroupAlreadySelected={isParentGroupAlreadySelected}
                    />
                  ))}
              </>
            )}
          </React.Fragment>
        ))}
    </fieldset>
  );
};

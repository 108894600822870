import React, { useContext } from "react";

/** Contexts */
import AppLabelsContext from "../../../App/AppLabelsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import AppSettingsContext from "../../../App/AppSettingsContext";

/** Helpers */
import { toDollars } from "../../../_common/helpers";

/** UI Components */
import { ReactComponent as IconCheck } from "../../../_common/icons/IconCheck.svg";

const BillItem = (props) => {
  const {
    item,
    seatItem,
    updateSelectedBillItems,
    selectedBillItems,
    hasCheckbox,
    readOnlyItem,
    orderedTogetherCheckboxId,
    billItemsGroup,
    numberOfAllBillItems,
  } = props;

  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  const onInputClick = (e) => {
    const currentSelectedBillItems = selectedBillItems ? [...selectedBillItems] : [];
    const isChecked = e.target.checked;

    if (isChecked) {
      currentSelectedBillItems.push(item);

      //Check if all the items in the group are selected, if so, check the group checkbox (ordered together)
      if (
        currentSelectedBillItems.filter((item) => item.seat_num === billItemsGroup[0].seat_num)
          .length === billItemsGroup.filter((item) => item["pay_status"] === "unpaid").length
      ) {
        if (document.getElementById(orderedTogetherCheckboxId)) {
          document.getElementById(orderedTogetherCheckboxId).checked = true;
        }
      }

      //Check if all the items in the bill are selected, if so, check the group checkbox (pay all items)
      if (currentSelectedBillItems.length === numberOfAllBillItems) {
        if (document.getElementById("pay-one-bill-0")) {
          document.getElementById("pay-one-bill-0").checked = true;
        }
      }
    } else {
      const unselectedItemIndex = currentSelectedBillItems.indexOf(
        currentSelectedBillItems.find(
          (tempItem) =>
            tempItem["item_id"] === item["item_id"] &&
            tempItem["seat_num"] === item["seat_num"] &&
            tempItem["vxl_order_line_id"] === item["vxl_order_line_id"] &&
            tempItem["total_price"] === item["total_price"]
        )
      );
      currentSelectedBillItems.splice(unselectedItemIndex, 1);
      if (
        document.getElementById(orderedTogetherCheckboxId) &&
        document.getElementById(orderedTogetherCheckboxId).checked
      ) {
        document.getElementById(orderedTogetherCheckboxId).checked = false;
        //unchecked the ordered together (one bill) checkbox
        if (
          document.getElementById("pay-one-bill-0") &&
          document.getElementById("pay-one-bill-0").checked
        ) {
          document.getElementById("pay-one-bill-0").checked = false;
        }
      }
    }

    updateSelectedBillItems(currentSelectedBillItems);
  };

  let isDisabled =
    item["pay_status"] === "full" ||
    item["pay_status"] === "billed" ||
    item["pay_status"] === "partial" ||
    readOnlyItem;

  return (
    <li key={new Date().now}>
      <div className="cartItem__wrapper billItem__wrapper">
        {hasCheckbox && (
          <div className="form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--checkbox billItem__checkbox">
            {item["pay_status"] === "full" || item["pay_status"] === "paid" ? (
              <IconCheck />
            ) : (
              <>
                <input
                  type="checkbox"
                  className="form__field--checkbox-radio form__field--checkbox visually-hidden"
                  id={`${item["item_id"]}-${seatItem}-${item["vxl_order_line_id"]}-checkbox`}
                  onChange={(e) => {
                    onInputClick(e);
                  }}
                  disabled={
                    item["pay_status"] === "full" ||
                    item["pay_status"] === "billed" ||
                    item["pay_status"] === "partial" ||
                    readOnlyItem
                  }
                ></input>

                <label
                  htmlFor={`${item["item_id"]}-${seatItem}-${item["vxl_order_line_id"]}-checkbox`}
                  className={`form__field-label--checkbox-radio form__field-label--checkbox `}
                >
                  <span></span>
                </label>
              </>
            )}
          </div>
        )}
        <div
          className={`cartItem__infoWrapper billItem__infoWrapper ${
            isDisabled ? "form__field-label--checkbox-greyed" : ""
          } `}
          style={!hasCheckbox ? { marginLeft: 0 } : {}}
        >
          <div className="cart-item__name-price-wrapper">
            <h4 className="cartItem__heading">
              {window.location.href.includes("bill-payment") ? (
                <>
                  <span className="cartItem__quantity-span">{`${item.units}x`}</span>{" "}
                  {item["item_name"]}
                </>
              ) : (
                <>{item["item_name"]}</>
              )}
            </h4>
            <span className="cartItem__price">
              {!!item.calorie_count && item.calorie_count + ` ${appLabels["order"]["calories"]} | `}
              {toDollars(
                appSettings["currency-symbol"],
                appSettings["currency-symbol-side"],
                item["price_for_display"],
                appLanguage
              )}
            </span>
          </div>

          <ul className="cartItem__modifiers">
            {item.hasOwnProperty("modifier_list") &&
              item["modifier_list"].map((option, index) => (
                <li key={index} className="cart-item__option">
                  <span className="cart-item__option-name">{option["modifier_name"]}</span>
                </li>
              ))}
            {item.hasOwnProperty("addon_list") &&
              item["addon_list"].map((option, index) => (
                <React.Fragment key={index}>
                  <li className="cart-item__option">
                    <span className="cart-item__option-name">{option["item_name"]}</span>
                    {option["price_for_display"] && option["price_for_display"] > 0 && (
                      <span className="cart-item__option-price">
                        {!!option.calorie_count &&
                          option.calorie_count + ` ${appLabels["order"]["calories"]} | `}
                        {option["price_for_display"]}
                      </span>
                    )}
                  </li>
                  {option["modifier_list"] && option["modifier_list"].length > 0 && (
                    <>
                      {option["modifier_list"].map((nestedMod, i) => (
                        <li key={`${nestedMod}-${i}-${Date.now()}`} className="cart-item__option">
                          <span className="cart-item__option-name cart-combo-child-item__option-name cart-combo-child-item__nested-option-name">
                            {nestedMod["modifier_name"]}
                          </span>
                        </li>
                      ))}
                    </>
                  )}
                </React.Fragment>
              ))}
            {item.hasOwnProperty("item_list") &&
              item["item_list"].map((comboChildItem, index) => (
                <React.Fragment key={`${comboChildItem["item_id"]}-${index}`}>
                  <li className="cart-item__option cart-combo-child-item">
                    <span className="cart-item__option-name cart-combo-child-item__name">
                      {comboChildItem["item_name"]}
                    </span>
                    {comboChildItem["price_for_display"] &&
                      comboChildItem["price_for_display"] > 0 && (
                        <span className="cart-item__option-price cart-combo-child-item__price">
                          {!!comboChildItem.calorie_count &&
                            comboChildItem.calorie_count + ` ${appLabels["order"]["calories"]} | `}
                          {toDollars(
                            appSettings["currency-symbol"],
                            appSettings["currency-symbol-side"],
                            comboChildItem["price_for_display"],
                            appLanguage
                          )}
                        </span>
                      )}
                  </li>
                  {comboChildItem["modifier_list"].map((option, index) => (
                    <li key={index} className="cart-item__option">
                      <span className="cart-item__option-name cart-combo-child-item__option-name">
                        {option["item_name"]}
                      </span>
                      {option["price_for_display"] && option["price_for_display"] > 0 && (
                        <span className="cart-item__option-price cart-combo-child-item__option-price">
                          {!!option.calorie_count &&
                            option.calorie_count + ` ${appLabels["order"]["calories"]} | `}
                          {option["price_for_display"]}
                        </span>
                      )}
                    </li>
                  ))}
                  {comboChildItem["addon_list"].map((option, index) => (
                    <React.Fragment key={index}>
                      <li className="cart-item__option">
                        <span className="cart-item__option-name cart-combo-child-item__option-name">
                          {option["item_name"]}
                        </span>
                        {option["price_for_display"] && option["price_for_display"] > 0 && (
                          <span className="cart-item__option-price cart-combo-child-item__option-price">
                            {!!option.calorie_count &&
                              option.calorie_count + ` ${appLabels["order"]["calories"]} | `}
                            {option["price_for_display"]}
                          </span>
                        )}
                      </li>

                      {option["modifier_list"] && option["modifier_list"].length > 0 && (
                        <>
                          {option["modifier_list"].map((nestedMod, i) => (
                            <li
                              key={`${nestedMod}-${i}-${Date.now()}`}
                              className="cart-item__option"
                            >
                              <span className="cart-item__option-name cart-combo-child-item__option-name cart-combo-child-item__nested-option-name">
                                {nestedMod["modifier_name"]}
                              </span>
                            </li>
                          ))}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
          </ul>
        </div>
      </div>
    </li>
  );
};
export default BillItem;

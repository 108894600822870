import localforage from "localforage";

export const checkIfExpiredOrNewCookieBanner = async (merchantSkinName) => {
  const expiredOrNewCookieBanner = await localforage
    .getItem(merchantSkinName + "__cookieIsAcceptedTimeStamp")
    .then((timestamp) => {
      if (timestamp) {
        const acceptedTimestamp = new Date(timestamp);
        const currentTime = new Date();

        const diffInSeconds = (currentTime.getTime() - acceptedTimestamp.getTime()) / 1000;

        const cookieBannerTimeLimit = 31536000; // seconds in a year

        if (diffInSeconds >= cookieBannerTimeLimit) {
          // Cookie banner was accepted more than a year ago
          localforage.removeItem(merchantSkinName + "__cookieIsAcceptedTimeStamp");

          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

  return expiredOrNewCookieBanner;
};

import localforage from "localforage";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

/** Contexts */
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import UserRoleContext from "../App/UserRoleContext";

/** Hooks */
import useWindowSize from "../_common/hooks/useWindowSize";

const AccountDeletionConfirmation = () => {
  const deviceWidth = useWindowSize().width;
  const appLabels = useContext(AppLabelsContext);
  const skin = useContext(MerchantConfigContext).skin;
  const userStatusContext = useContext(UserRoleContext);

  const [daysToDeleteAccount, setDaysToDeleteAccount] = useState(null);
  useEffect(() => {
    localforage.getItem(skin + "___DaysToDeleteAccount").then((numberOfDays) => {
      if (numberOfDays) {
        setDaysToDeleteAccount(numberOfDays);
      } else {
        setDaysToDeleteAccount(30);
      }
      localforage.removeItem(skin + "___DaysToDeleteAccount");
      sessionStorage.removeItem(skin + "__login");
      sessionStorage.removeItem(skin + "__activeLoyaltyCard");

      localforage.removeItem(skin + "__customerInfo");
      localforage.removeItem(skin + "__customerID");
      localforage.removeItem(skin + "__userFirstName");
      localforage.removeItem(skin + "__isSessionLogin");
      localforage.removeItem(skin + "__lastLoginTimeStampDisplayed");
      localforage.setItem(skin + "__isUserGreeted", true);
      localforage.removeItem(skin + "__storedRewards");
      localforage.removeItem(skin + "__orderHistory");

      userStatusContext.updateStatus("guest");
      userStatusContext.updateLoginToken();
    });
  }, []);

  return (
    <>
      {daysToDeleteAccount && (
        <>
          {deviceWidth >= 660 && (
            <section className="delete-account-desktop-title">
              <div className="desktop-container">
                <h1 className="account-deletion-title">
                  {appLabels["account"]["delete-account-confirmation-header"]}
                </h1>
              </div>
            </section>
          )}
          <div className="desktop-container mobile-container">
            <h2 className="account-deletion-subtitle">
              {appLabels["account"]["delete-account-success-message"]}
            </h2>

            <p>
              {appLabels["account"]["delete-account-blurb1"].replace(
                "[number-of-days]",
                daysToDeleteAccount
              )}
            </p>

            <p>{appLabels["account"]["delete-account-blurb2"]}</p>

            <div className="account-deletion-back-to-home">
              <Link className="button button--primary" to="/dashboard">
                {appLabels["order"]["back-to-home"]}
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default AccountDeletionConfirmation;

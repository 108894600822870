// Libraries
import React, { useContext, useEffect, useState } from "react";

// Hooks
import { useHistory } from "react-router-dom";

// Contexts
import MerchantConfigContext from "../App/MerchantConfigContext";
import UserRoleContext from "../App/UserRoleContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";

// UI components
import { LoginRegisterLinks } from "./LoginRegisterLinks/LoginRegisterLinks";
import { Login } from "./Login/Login";
import AppLabelsContext from "../App/AppLabelsContext";

export const LoginRegister = () => {
  const history = useHistory();

  const merchantConfigContext = useContext(MerchantConfigContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const userRoleContext = useContext(UserRoleContext);
  const orderTypeContext = useContext(OrderTypeContext);

  const merchantSkinName = merchantConfigContext.skin;
  const isRegistrationEnabled = cws5ModuleContext.isRegistrationEnabled;
  const activeOrderType = orderTypeContext.value;
  const appLabels = useContext(AppLabelsContext)

  const [isFromCheckoutPage, setIsFromCheckoutPage] = useState(false)

  useEffect(() => {
    if (
      sessionStorage.getItem(merchantSkinName + "__redirectToCheckout") &&
      sessionStorage.getItem(merchantSkinName + "__redirectToCheckout") === "true"
    ) {
      setIsFromCheckoutPage(true)
    } else {
      setIsFromCheckoutPage(false)
    }
  }, [])

  const proceedToDashboard = () => {
    userRoleContext.updateStatus("guest");

    if (
      sessionStorage.getItem(merchantSkinName + "__redirectToCheckout") &&
      sessionStorage.getItem(merchantSkinName + "__redirectToCheckout") === "true"
    ) {
      history.push({ pathname: "/online-ordering/checkout", state: { from: "login-register" } });
    } else if (
      sessionStorage.getItem(merchantSkinName + "__gcRedirectToGCCheckout") &&
      sessionStorage.getItem(merchantSkinName + "__gcRedirectToGCCheckout") === "true"
    ) {
      history.push({ pathname: "/gift-card/checkout", state: { from: "login-register" } });
    } else if (
      sessionStorage.getItem(merchantSkinName + "__lastVisitedLink") &&
      !sessionStorage.getItem(merchantSkinName + "__lastVisitedLink").includes("register") &&
      !sessionStorage.getItem(merchantSkinName + "__lastVisitedLink").includes("login-register") &&
      !sessionStorage.getItem(merchantSkinName + "__lastVisitedLink").includes("past-orders") &&
      !sessionStorage.getItem(merchantSkinName + "__lastVisitedLink").includes("favorites")
    ) {
      let pathLink = sessionStorage.getItem(merchantSkinName + "__lastVisitedLink");
      sessionStorage.removeItem(merchantSkinName + "__lastVisitedLink");

      // Redirect to checkout page if user came from the payment page,
      // because they need to enter their customer info for the order
      if (pathLink === "/online-ordering/payment") {
        if (activeOrderType !== "dinein") {
          pathLink = "/online-ordering/checkout";
        } else {
          pathLink = "/online-ordering/review-order";
        }
      } else if (pathLink === "/gift-card/payment") {
        pathLink = "/gift-card/payment";
      } else if (pathLink.includes("/account") || pathLink.includes("/pay-in-store")) {
        pathLink = "/dashboard";
      }

      history.push({
        pathname: pathLink,
        state: { from: "login-register" },
      });
    } else {
      history.push("/dashboard");
    }
  };

  useEffect(() => {
    if (!isRegistrationEnabled) proceedToDashboard();
  }, []);

  return (
    <div className="login-register__main-wrapper">
      {isRegistrationEnabled && (
        <div>
          {isFromCheckoutPage && <p className="visually-hidden" role="alert">{appLabels["login-register"]["login-register-page-message"]}</p>}
          <Login />
          <LoginRegisterLinks proceedToDashboard={proceedToDashboard} />
        </div>
      )}
    </div>
  );
};

import React, { useRef, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import localforage from "localforage";

//Custom Hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

//Contexts
import BrandingContext from "../../App/BrandingContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import OrderTimeContext from "../OrderTimeContext";
import AppLabelsContext from "../../App/AppLabelsContext.js";
import AppLanguageContext from "../../App/AppLanguageContext.js";
import AppSettingsContext from "../../App/AppSettingsContext";
import OrderContext from "../../App/OrderContext";
import LocationsContext from "../../OnlineOrdering/Locations/LocationsContext";
import StoreContext from "../StoreContext";

//Helpers
import { jsonCopy } from "../../_common/helpers";
import { getOrderTypeImages } from "../../OrderSettings/helpers/getOrderTypeImages";
import { getTranslatedOrderType } from "../../OrderSettings/helpers/orderSettingsHelpers";
import { capitalizeWords } from "../../_common/stringHelpers";
import { prettifyDate } from "../../Dashboard/DashboardOrder/dateHelpers";

//UI Components
import { IconEdit } from "../../_common/icons";
import pickupIcon from "../../OrderSettings/assets/pickupIcon.png";
import deliveryIcon from "../../OrderSettings/assets/deliveryIcon.png";
import dineinIcon from "../../OrderSettings/assets/dineinIcon.png";
import inSeatServiceIcon from "../../OrderSettings/assets/inSeatServiceIcon.png";

//CSS
import "./CartOrderDetailsSummary.css";

export const CartOrderDetailsSummary = (props) => {
  const { activeOrderStore, activeOrderType, numberOfItems, onlyDeliveryInfo = false } = props;

  const deviceWidth = useWindowSize().width;
  const appLabels = useContext(AppLabelsContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const orderContext = useContext(OrderContext);
  const locationsContext = useContext(LocationsContext);
  const storeContext = useContext(StoreContext);

  const activeTableNumber = storeContext.activeOrderStoreTable;

  const merchantConfig = useContext(MerchantConfigContext);
  const merchantOrderTypes = merchantConfig.vexilor.I2.active_order_types.split(",");
  const onlyOneOrderType = merchantOrderTypes.length === 1;
  const skin = merchantConfig.skin;

  const brandingContext = useContext(BrandingContext);
  const secondaryColor = brandingContext["secondary-colour"];

  const isStadiumSchema = merchantConfig.merchant.I55 === "stadium";

  const [orderDate, setOrderDate] = useState(null);

  useEffect(() => {
    localforage.getItem(skin + "__orderDate").then((storedOrderDate) => {
      if (storedOrderDate) {
        setOrderDate(storedOrderDate);
      } else {
        if (orderTimeContext) {
          const tempOrderDate = new Date(orderTimeContext.value.value);
          const orderDateToStore = {
            value: tempOrderDate.getMonth() + "-" + tempOrderDate.getDate(), // the day of a date as a number (1-31)
            displayValue:
              tempOrderDate.getDate() === new Date().getDate()
                ? appLabels["order"]["today"]
                : prettifyDate(tempOrderDate, appLanguage), // stringified Date object
            dateObject: tempOrderDate,
          };

          localforage.setItem(skin + "__orderDate", orderDateToStore);
          setOrderDate(orderDateToStore);
        }
      }
    });
  }, [orderTimeContext]);

  const appLanguage = useContext(AppLanguageContext);

  const [orderDateNewFormat, setOrderDateNewFormat] = useState("");
  useEffect(() => {
    if (orderDate) {
      const date = new Date();
      date.setMonth(orderDate.value.split("-")[0], orderDate.value.split("-")[1]);

      const today = new Date();
      const isToday =
        today.getDate() === date.getDate() &&
        today.getMonth() === date.getMonth() &&
        today.getFullYear() === date.getFullYear();

      if (isToday) {
        setOrderDateNewFormat(appLabels["order"]["today"]);
      } else {
        // convert date number back to date in ms
        const orderDateValue = new Date();
        orderDateValue.setMonth(orderDate.value.split("-")[0], 1); // 1 is a dummy value to prevent JS from skipping months without the current date
        orderDateValue.setDate(orderDate.value.split("-")[1]);

        const newDateFromOrderDate = new Date(orderDateValue);
        formatDate(newDateFromOrderDate);
      }
    }
  }, [orderDate]);

  const formatDate = (date) => {
    const displayDate = date.toLocaleDateString(appLanguage, {
      weekday: "short",
      month: "short",
      day: "numeric",
    });

    setOrderDateNewFormat(capitalizeWords(displayDate.replace(/,/g, "")).replace(/De/g, "de"));
  };

  const homeLinkRef = useRef(null);

  const [orderTimeDetails, setOrderTimeDetails] = useState(false);
  useEffect(() => {
    if (orderDateNewFormat && orderTimeContext.value && !orderTimeContext.openOrderTimePopup) {
      if (activeOrderType === "dinein") {
        setOrderTimeDetails(`${orderDateNewFormat} ${appLabels["order"]["asap"]}`);
      } else {
        const orderTimeDisplayValue = orderTimeContext.value.displayValue;
        if (orderTimeDisplayValue) {
          const isInMinutesLabel = isNaN(Number(orderTimeDisplayValue.split(":")[0]));

          if (isInMinutesLabel) {
            setOrderTimeDetails(`${orderDateNewFormat} ${orderTimeDisplayValue}`);
          } else {
            setOrderTimeDetails(`
          ${orderDateNewFormat}
          ${
            appLabels["order"]["order-type-order-date-at-order-time"]
              .split("[order-date]")[1]
              .split("[order-time]")[0]
          }${orderTimeDisplayValue}`);
          }
        }
      }
    }
  }, [orderDateNewFormat, orderTimeContext]);

  const [userDeliveryAddress, setUserDeliveryAddress] = useState(null);
  useEffect(() => {
    if (activeOrderType === "delivery") {
      localforage.getItem(skin + "__userDeliveryAddress").then((userDeliveryAddress) => {
        if (userDeliveryAddress) {
          setUserDeliveryAddress(userDeliveryAddress);
        }
      });
    }
  }, []);

  const relativePath = useContext(AppSettingsContext)["relative-path"];

  const isLocalhost = process.env.NODE_ENV === "development";

  const [orderTypeImages, setOrderTypeImages] = useState(null);
  const setDefaultOrderTypeImages = () => {
    setOrderTypeImages({
      "pickup-icon": { "image-src": pickupIcon, "image-alt": "" },
      "delivery-icon": {
        "image-src": isStadiumSchema ? inSeatServiceIcon : deliveryIcon,
        "image-alt": "",
      },
      "dinein-icon": { "image-src": dineinIcon, "image-alt": "" },
    });
  };

  useEffect(() => {
    getOrderTypeImages(relativePath).then((fetchedImagesData) => {
      if (fetchedImagesData) {
        if (fetchedImagesData === "no-images-set" || isLocalhost) {
          setDefaultOrderTypeImages();
        } else {
          const tempImages = jsonCopy(fetchedImagesData);
          Object.keys(tempImages).forEach((imageKey) => {
            const image = tempImages[imageKey];
            const imageCopy = jsonCopy(image);
            if (
              imageCopy["image-src"] === "" ||
              imageCopy["image-src"].includes("_blank") ||
              imageCopy["image-src"].split(".").length === 1
            ) {
              if (imageKey === "pickup-icon") {
                imageCopy["image-src"] = pickupIcon;
              } else if (imageKey === "delivery-icon") {
                imageCopy["image-src"] = deliveryIcon;
              } else if (imageKey === "dinein-icon") {
                imageCopy["image-src"] = dineinIcon;
              }
            }

            image["image-src"] = imageCopy["image-src"];
          });
          setOrderTypeImages(tempImages);
        }
      } else {
        //if no order-types key was set in online-ordering/images.json
        setDefaultOrderTypeImages();
      }
    });
  }, []);

  const showOrderTimePopup = () => {
    orderContext.openOrderTimePopupWidget(orderTimeContext);
  };

  const showEditButtons = !window.location.href.includes("payment");

  const isAsapOrder = isNaN(Number(orderTimeContext.value.displayValue.split(":")[0]));

  return (
    <>
      {(!!numberOfItems || numberOfItems === 0) && orderTypeImages && activeOrderStore && (
        <>
          {deviceWidth < 660 ? (
            <div className="orderDetailsSummary__container">
              {!onlyDeliveryInfo && (
                <div className="orderDetailsSummary__location-details">
                  <div className="orderDetailsSummary__location-details-columns">
                    <p>
                      <span>
                        {activeOrderType === "dinein" ? (
                          <>
                            {getTranslatedOrderType(activeOrderType, appLabels)} {"|"}{" "}
                            {capitalizeWords(appLabels["order"]["table"])} {activeTableNumber}
                          </>
                        ) : isStadiumSchema && activeOrderType === "delivery" ? (
                          appLabels["order"]["in-seat-service"]
                        ) : (
                          getTranslatedOrderType(activeOrderType, appLabels)
                        )}
                      </span>
                    </p>

                    {showEditButtons && !onlyOneOrderType && (
                      <Link
                        ref={homeLinkRef}
                        to="/online-ordering/order-type"
                        className="orderDetailsSummary__order-time-details"
                        aria-label={appLabels["order"]["edit-order-type"]}
                        style={{ color: secondaryColor }}
                      >
                        <IconEdit />
                      </Link>
                    )}
                  </div>

                  <div className="orderDetailsSummary__location-details-columns">
                    <p>
                      {appLanguage !== "en" && !showEditButtons && isAsapOrder ? (
                        <span>
                          {orderTimeDetails
                            .toString()
                            .substring(0, orderTimeDetails.toString().indexOf("("))}
                          <br />
                          {orderTimeDetails
                            .toString()
                            .substring(orderTimeDetails.toString().indexOf("(") + 0)}
                        </span>
                      ) : (
                        <span>{orderTimeDetails}</span>
                      )}
                    </p>
                    {activeOrderType !== "dinein" && showEditButtons && (
                      <button
                        className="orderDetailsSummary__order-time-details"
                        aria-label={appLabels["order"]["edit-order-time"]}
                        onClick={showOrderTimePopup}
                        style={{ color: secondaryColor }}
                      >
                        <IconEdit />
                      </button>
                    )}
                  </div>
                </div>
              )}

              {activeOrderType === "delivery" && userDeliveryAddress && (
                <div
                  className="orderDetailsSummary__location-details"
                  style={onlyDeliveryInfo ? { border: "none" } : {}}
                >
                  {isStadiumSchema ? (
                    <p>
                      <span>{appLabels["order"]["delivering-to-address"]}:</span>
                      <br />
                      <span className="stadium-delivery-address">
                        {appLabels["form"]["section"]}{" "}
                        {userDeliveryAddress.split("/")[0].toUpperCase()},{" "}
                        {appLabels["form"]["row"]} {userDeliveryAddress.split("/")[1].toUpperCase()}
                        , {appLabels["form"]["seat"]}{" "}
                        {userDeliveryAddress.split("/")[2].toUpperCase()}
                      </span>
                    </p>
                  ) : (
                    <div className="delivery-address-detail-container flex__column">
                      <p>
                        <span>{appLabels["order"]["delivering-to-address"]}:</span>
                      </p>
                      <p>
                        {userDeliveryAddress["first-address"]}, {userDeliveryAddress.city},{" "}
                        {userDeliveryAddress.province &&
                        userDeliveryAddress.province.split(":").length === 1
                          ? userDeliveryAddress.province + ","
                          : ""}{" "}
                        {userDeliveryAddress.postal}
                        <br />
                        {userDeliveryAddress["second-address"] && (
                          <span className="address-validation__apt-suite-unit-container">
                            <span className="address-validation__apt-suite-unit-header">
                              {appLabels["form"]["apt-suit-unit"]}
                            </span>
                            <span className="address-validation__apt-suite-unit">{` ${userDeliveryAddress["second-address"]}`}</span>
                          </span>
                        )}
                      </p>
                    </div>
                  )}

                  {showEditButtons && (
                    <Link
                      ref={homeLinkRef}
                      to={
                        isStadiumSchema
                          ? "/online-ordering/seat-section"
                          : "/online-ordering/delivery-address"
                      }
                      style={{ color: secondaryColor }}
                      aria-label={appLabels["order"]["edit-delivery-address"]}
                    >
                      <IconEdit />
                    </Link>
                  )}
                </div>
              )}

              {!onlyDeliveryInfo && (
                <div className="orderDetailsSummary__location-details">
                  <p>
                    <span>
                      {appLabels["order"]["ordering-from"]} {activeOrderStore.name}
                    </span>
                    <br />
                    {activeOrderStore.address}, {activeOrderStore.city},{" "}
                    {activeOrderStore.province && activeOrderStore.province.split(":").length === 1
                      ? activeOrderStore.province + ","
                      : ""}
                    {activeOrderStore.postal}
                  </p>
                  {activeOrderType !== "delivery" &&
                    activeOrderType !== "dinein" &&
                    showEditButtons &&
                    locationsContext.length > 1 && (
                      <Link
                        ref={homeLinkRef}
                        to="/online-ordering/order-store"
                        className="orderDetailsSummary__order-time-details"
                      aria-label={appLabels["order"]["edit-ordering-locaiton"]}
                        style={{ color: secondaryColor }}
                      >
                        <IconEdit />
                      </Link>
                    )}
                </div>
              )}
            </div>
          ) : (
            <div className="orderDetailsSummary__container">
              <div className="desktop-container">
                <div
                  className="orderDetailsSummary__location-details"
                  style={onlyDeliveryInfo ? { display: "none" } : {}}
                >
                  <div className="orderDetailsSummary__location-details-time-and-type">
                    <span>
                      {activeOrderType === "pickup" && (
                        <img
                          src={orderTypeImages["pickup-icon"]["image-src"]}
                          alt={orderTypeImages["pickup-icon"]["image-alt"]}
                        />
                      )}
                      {activeOrderType === "delivery" && (
                        <img
                          src={orderTypeImages["delivery-icon"]["image-src"]}
                          alt={orderTypeImages["delivery-icon"]["image-alt"]}
                        />
                      )}
                      {activeOrderType === "dinein" && (
                        <img
                          src={orderTypeImages["dinein-icon"]["image-src"]}
                          alt={orderTypeImages["dinein-icon"]["image-alt"]}
                        />
                      )}
                      {activeOrderType === "dinein" ? (
                        <>
                          {getTranslatedOrderType(activeOrderType, appLabels)} {"|"}{" "}
                          {capitalizeWords(appLabels["order"]["table"])} {activeTableNumber}
                        </>
                      ) : isStadiumSchema && activeOrderType === "delivery" ? (
                        appLabels["order"]["in-seat-service"]
                      ) : (
                        getTranslatedOrderType(activeOrderType, appLabels)
                      )}
                      {showEditButtons && !onlyOneOrderType && (
                        <Link
                          ref={homeLinkRef}
                          to="/online-ordering/order-type"
                          className="orderDetailsSummary__order-time-details"
                            aria-label={appLabels["order"]["edit-order-type"]}
                          style={{ color: secondaryColor }}
                        >
                          <IconEdit />
                        </Link>
                      )}
                    </span>
                    <span>
                      {orderTimeDetails}
                      {activeOrderType !== "dinein" && showEditButtons && (
                        <button
                          className="orderDetailsSummary__order-time-details"
                            aria-label={appLabels["order"]["edit-order-time"]}
                          onClick={showOrderTimePopup}
                          style={{ color: secondaryColor }}
                        >
                          <IconEdit />
                        </button>
                      )}
                    </span>
                  </div>
                </div>

                <div
                  className="orderDetailsSummary__location-details"
                  style={onlyDeliveryInfo ? { justifyContent: "flex-start" } : {}}
                >
                  <div className="orderDetailsSummary__location-details">
                    <p className="">
                      {activeOrderType === "delivery" && userDeliveryAddress && (
                        <>
                          {isStadiumSchema ? (
                            <>
                              {" "}
                              <span>{appLabels["order"]["delivering-to-address"]}:</span>
                              <br />
                              <span className="stadium-delivery-address">
                                {appLabels["form"]["section"]}{" "}
                                {userDeliveryAddress.split("/")[0].toUpperCase()},{" "}
                                {appLabels["form"]["row"]}{" "}
                                {userDeliveryAddress.split("/")[1].toUpperCase()},{" "}
                                {appLabels["form"]["seat"]}{" "}
                                {userDeliveryAddress.split("/")[2].toUpperCase()}
                              </span>
                              <br />
                            </>
                          ) : (
                            <>
                              <span>{appLabels["order"]["delivering-to-address"]}:</span>
                              <br />
                              {userDeliveryAddress["first-address"]}, {userDeliveryAddress.city},{" "}
                              {userDeliveryAddress.province &&
                              userDeliveryAddress.province.split(":").length === 1
                                ? userDeliveryAddress.province + ","
                                : ""}{" "}
                              {userDeliveryAddress.postal}
                              <br />
                              {userDeliveryAddress["second-address"] && (
                                <p className="address-validation__apt-suite-unit-container">
                                  <span className="address-validation__apt-suite-unit-header">
                                    {appLabels["form"]["apt-suit-unit"]}
                                  </span>
                                  <span className="address-validation__apt-suite-unit">{` ${userDeliveryAddress["second-address"]}`}</span>
                                </p>
                              )}
                              {!onlyDeliveryInfo && (
                                <>
                                  <br />
                                  <br />
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {!onlyDeliveryInfo && (
                        <>
                          <span>
                            {appLabels["order"]["ordering-from"]} {activeOrderStore.name}
                          </span>
                          <br />
                          {activeOrderStore.address}, {activeOrderStore.city},{" "}
                          {activeOrderStore.province &&
                          activeOrderStore.province.split(":").length === 1
                            ? activeOrderStore.province + ","
                            : ""}
                          {activeOrderStore.postal}
                        </>
                      )}
                    </p>
                    {showEditButtons && activeOrderType === "delivery" && (
                      <Link
                        ref={homeLinkRef}
                        to={
                          isStadiumSchema
                            ? "/online-ordering/seat-section"
                            : "/online-ordering/delivery-address"
                        }
                        style={{ color: secondaryColor }}
                        aria-label={appLabels["order"]["edit-delivery-address"]}
                      >
                        <IconEdit customStyle={{ marginTop: "11px" }} />
                      </Link>
                    )}
                  </div>

                  {activeOrderType !== "delivery" &&
                    activeOrderType !== "dinein" &&
                    showEditButtons &&
                    locationsContext.length > 1 && (
                      <Link
                        ref={homeLinkRef}
                        to="/online-ordering/order-store"
                        className="orderDetailsSummary__order-time-details"
                        aria-label={appLabels["order"]["edit-ordering-locaiton"]}
                        style={{ color: secondaryColor }}
                      >
                        <IconEdit />
                      </Link>
                    )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

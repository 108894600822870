// Libraries
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";

// Helper functions
import { getContrastColor, getColorLuminance } from "../_common/colorHelpers";

// API helper functions
import { getAPIAccountInfo } from "../Account/apiHelpers/getAPIAccountInfo";
import { addAPIGiftCard } from "./addAPIGiftCard";

// Contexts
import AppLanguageContext from "../App/AppLanguageContext";
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import BrandingContext from "../App/BrandingContext";
import UserRoleContext from "../App/UserRoleContext";

// UI components
import { Modal } from "../_common/Modal/Modal";
import { useModal } from "../_common/Modal/useModal";
import { BarcodeScanner } from "./BarcodeScanner";
import { FormReCAPTCHA } from "../_common/Form/FormReCAPTCHA";

// Assets
import { ReactComponent as IconCheck } from "../_common/icons/IconCheck.svg";
import { ReactComponent as IconX } from "../_common/icons/IconX.svg";
import { ReactComponent as IconBarcodeScan } from "../_common/icons/IconBarcodeScan.svg";

// CSS
import "../_common/Form/Form.css";
import "../_common/Form/FormInput.css";
import "../_common/buttons/FAB/FAB.css";
import "./AddNewCard.css";

export const AddNewCard = () => {
  const history = useHistory();

  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const isReCaptcha = merchantConfigContext.merchant.I26 === "t";

  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  const branding = useContext(BrandingContext);
  const primaryColor = branding["primary-colour"];

  const appLabels = useContext(AppLabelsContext);

  const appLanguage = useContext(AppLanguageContext);

  const deviceWidth = useWindowSize().width;

  /** on load check if user's login token has expired */
  useEffect(() => {
    if (loginToken) {
      getAPIAccountInfo(skin, loginToken, appLanguage).then((apiAccountInfo) => {
        if (apiAccountInfo) {
          if (apiAccountInfo.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          }
        }
      });
    }
  }, []);

  const [cardNumber, setCardNumber] = useState("");
  const onCardNumberChange = (event) => {
    const input = event.target;
    setCardNumber(input.value);
  };

  const [cardDescription, setCardDescription] = useState("");
  const onCardDescriptionChange = (event) => {
    const input = event.target;
    let value = input.value;

    // prevent inject of "<" and ">" characters
    if (input.value.includes(">") || input.value.includes("<")) {
      value = value.replace(/>|</g, "");
    }

    setCardDescription(value);
  };

  const [submitStatus, setSubmitStatus] = useState("idle");
  const [formError, setFormError] = useState("");

  const addCard = () => {
    setSubmitStatus("submitting");

    setTimeout(() => {
      addAPIGiftCard(
        skin,
        loginToken,
        cardNumber,
        cardDescription.trim(),
        captchaData.value,
        appLanguage,
        appLabels["form"]
      ).then((addedAPIGiftCard) => {
        if (addedAPIGiftCard) {
          if (addedAPIGiftCard.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else if (addedAPIGiftCard.error) {
            setSubmitStatus("failed");
            setFormError(addedAPIGiftCard.error);
          } else {
            setSubmitStatus("success");

            // Navigate away after 2 seconds
            setTimeout(() => {
              if (history.location.hash === "#paymentmethod") history.goBack();
              else history.push("/pay-in-store#refresh");
            }, 2000);
          }
        }
      });
    }, 1000); // buffer for user to register the submitting state
  };

  const [formErrorHideClass, setFormErrorHidingClass] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (cardNumber === "" || (isReCaptcha && !captchaData.value)) {
      setSubmitStatus("failed");
      setFormError(appLabels["form"]["missing-required-fields-error"]);

      setCaptchaData({ ...captchaData, isValid: false });
    } else {
      addCard();
    }
  };

  useEffect(() => {
    if (submitStatus === "failed") setTimeout(() => resetSubmit(), 2000);
  }, [submitStatus]);

  const resetSubmit = () => {
    setSubmitStatus("idle");
    setFormErrorHidingClass(" FAB__error--closing");

    setCaptchaData({
      ...captchaData,
      value: null,
      isValid: "idle",
      errorMessage: appLabels["form"]["required"],
    });

    // reset error and its hiding animation after animation is complete
    setTimeout(() => {
      setFormError("");
      setFormErrorHidingClass("");
    }, 300);
  };

  // start of: Input focus and blur events
  const [cardNumberFocusClass, setCardNumberFocusClass] = useState("");
  const [cardDescriptionFocusClass, setCardDescriptionFocusClass] = useState("");

  const onInputFocus = (event) => {
    const input = event.target;

    if (input.name === "Card Number") {
      setCardNumberFocusClass(" form__field-wrapper--in-focus");
    } else {
      setCardDescriptionFocusClass(" form__field-wrapper--in-focus");
    }
  };

  const onClickAwayFromInput = (event) => {
    const input = event.target;

    // if any value is present keep placeholder minimized, otherwise expand it
    if (input.value === "") {
      if (input.name === "Card Number") setCardNumberFocusClass("");
      else setCardDescriptionFocusClass("");
    }
  };
  // end of: Input focus and blur events

  // start of: fullscreen modal with barcode scanner
  const { isModal, toggleModal, closeAnimation } = useModal();

  const handleBarcodeScan = (result) => {
    if (result) {
      setCardNumber(result.codeResult.code);
      setCardNumberFocusClass(" form__field-wrapper--in-focus");

      toggleModal(); // closes the fullscreen modal with barcode scanner
    }
  };
  // end of: fullscreen modal with barcode scanner

  const [captchaData, setCaptchaData] = useState({
    value: null,
    isValid: "idle",
    errorMessage: appLabels["form"]["required"],
  });

  const onRecaptchaChange = (value) => {
    const tempCaptchaData = { ...captchaData };

    if (value == null) tempCaptchaData.isValid = false;
    else tempCaptchaData.isValid = true;

    tempCaptchaData.value = value;

    setCaptchaData(tempCaptchaData);
  };

  const [videoDevices, setVideodDevices] = useState("idle");
  useEffect(() => {
    if (videoDevices === "idle") {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
        setVideodDevices(null);
      } else {
        navigator.mediaDevices
          .enumerateDevices()
          .then(function (devices) {
            if (devices) {
              const videoDevices = devices.filter(
                (device) => device.kind === "videoinput" && !device.label.includes("front")
              );

              setVideodDevices(videoDevices);
            } else {
              setVideodDevices(null);
            }
          })
          .catch(function (err) {
            console.log(err.name + ": " + err.message);
          });
      }
    }
  }, [videoDevices]);

  const [streamError, setStreamError] = useState("idle");
  return (
    <>
      {deviceWidth < 660 && (
        <button
          onClick={() => history.goBack()}
          className="button add-new-card__button--close"
          aria-label={appLabels["general"]["go-back"]}
          type="button"
        >
          <IconX />
        </button>
      )}
      <div className="add-new-card__content-wrapper">
        <h1 className="add-new-card__heading">{appLabels["account"]["add-new-card-paragraph"]}</h1>
        <div className="add-new-card__form-card">
          <form id="add-new-card-form" className="add-new-card-form">
            <div className={`form__field-wrapper${cardNumberFocusClass}`}>
              <label htmlFor="add-new-card__card-number-input" className="form__field-label">
                {appLabels["form"]["card-number"]}
              </label>
              <input
                onChange={onCardNumberChange}
                type="number"
                value={cardNumber}
                name={appLabels["form"]["card-number"]}
                aria-required="true"
                className="form__field"
                id="add-new-card__card-number-input"
                onFocus={onInputFocus}
                onBlur={onClickAwayFromInput}
              />
              {submitStatus === "failed" && cardNumber === "" && (
                <div className="form__field-error">{appLabels["form"]["required"]}</div>
              )}
            </div>
            <div className={`form__field-wrapper${cardDescriptionFocusClass}`}>
              <label htmlFor="add-new-card__card-description-input" className="form__field-label">
                {appLabels["form"]["card-description"]}
                {" ("}
                {appLabels["general"]["optional"]}
                {")"}
              </label>
              <input
                onChange={onCardDescriptionChange}
                type="text"
                value={cardDescription}
                name={appLabels["form"]["card-description"]}
                className="form__field"
                id="add-new-card__card-description-input"
                onFocus={onInputFocus}
                onBlur={onClickAwayFromInput}
              />
            </div>
            {isReCaptcha && (
              <FormReCAPTCHA
                fieldData={captchaData}
                onRecaptchaChange={onRecaptchaChange}
                name="reCAPTCHA"
                isRequired={true}
              />
            )}
            <div className="FAB-wrapper">
              <div
                className={`FAB-wrapper__gradient${
                  formError ? " FAB-wrapper__gradient--expanded" : ""
                }`}
              />
              {formError && <div className={`FAB__error${formErrorHideClass}`}>{formError}</div>}
              <button
                onClick={handleSubmit}
                type="submit"
                form="add-new-card-form"
                disabled={submitStatus === "failed" || submitStatus === "submitting"}
                id="submit-add-new-card-form"
                className="FAB button button--primary"
              >
                {submitStatus === "success" && (
                  <div
                    className="FAB__success"
                    style={{
                      color: getContrastColor(primaryColor),
                      background: `linear-gradient(${getColorLuminance(
                        primaryColor,
                        0.075
                      )}, ${primaryColor} 60%) no-repeat`,
                    }}
                  >
                    <span>{appLabels["account"]["added"]}</span>
                    <IconCheck aria-hidden="true" />
                  </div>
                )}
                <span>
                  {submitStatus === "submitting"
                    ? appLabels["account"]["adding"]
                    : appLabels["account"]["add-card"]}
                </span>
              </button>
            </div>
          </form>
          <button
            onClick={toggleModal}
            className="button add-new-card__scanner-button"
            aria-label={appLabels["account"]["open-barcode-scanner"]}
            type="button"
          >
            <IconBarcodeScan aria-hidden="true" />
            <span>{appLabels["account"]["scan-barcode"]}</span>
          </button>
        </div>
      </div>
      <Modal
        heading={appLabels["account"]["scan-barcode-modal-heading"]}
        content={
          <>
            {streamError !== "idle" ? (
              <p style={{ padding: "1em" }}>{streamError}</p>
            ) : (
              <BarcodeScanner
                handleScan={handleBarcodeScan}
                videoDevices={videoDevices}
                setStreamError={setStreamError}
              />
            )}
          </>
        }
        type="fullscreen"
        customModalClass="modal--add-new-card-barcode-scanner"
        isTransparent={true}
        isCloseButton={true}
        isModal={isModal}
        toggleModal={toggleModal}
        closeAnimation={closeAnimation}
      />
    </>
  );
};

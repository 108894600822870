// Libraries
import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

// Helper functions
import { clearTemporaryPasswordOnExpiry } from "../clearTemporaryPasswordOnExpiry";

// Contexts
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";

// UI components
import { LoginRegisterBackButton } from "../LoginRegisterBackButton/LoginRegisterBackButton";
import { ChangeTemporaryPasswordForm } from "./ChangeTemporaryPasswordForm";
import useWindowSize from "../../_common/hooks/useWindowSize";

export const ChangeTemporaryPassword = () => {
  const skin = useContext(MerchantConfigContext).skin;

  const appLabels = useContext(AppLabelsContext);
  const history = useHistory();
  const deviceWidth = useWindowSize().width;

  useEffect(() => {
    clearTemporaryPasswordOnExpiry(skin).then((isExpired) => {
      if (isExpired) {
        history.push({
          pathname: "/",
        });
      }
    });
  }, []);

  return (
    <div className="change-temp-password__wrapper">
      {deviceWidth < 660 && <LoginRegisterBackButton isCloseButton={true} goToDashboard={true} />}
      <h1 className="change-temp-password__heading">
        {appLabels["login-register"]["reset-password"]}
      </h1>
      <div>
        <ChangeTemporaryPasswordForm />
      </div>
    </div>
  );
};

export const giftCardLabels = {
  gift: "Regalo",
  "gift-cards": "Tarjeta de Regalo",
  "order-history": "Historial de Pedidos",
  "see-all": "Ver Todo",
  amount: "Monto",
  "recipient-email": "Correo del Destinatario",
  "confirm-recipient-email": "Confirmar Correo del Destinatario",
  "recipient-name": "Nombre del Destinatario",
  "delivery-date-time": "Fecha y Hora de Entrega",
  "greeting-message": "Mensaje de Saludo",
  "sender-name": "Nombre del Remitente",
  "add-more-people": "Añadir más personas",
  "clear-form": "Limpiar formulario",
  save: "Guardar",
  "select-gift-card": "Seleccione Tarjeta de Regalo",
  "exit-gift-card-dialog-message":
    "Al abandonar esta página, se perderá su pedido de tarjeta de regalo",
  "sure-text": "¿Está seguro?",
  "gift-card-recent-order-signed-out-message":
    "Explore tarjetas de regalo para iniciar un pedido o inicie sesión para ver su historial de transacciones",
  "add-discount-coupon": "Añadir Descuento/Cupón",
  minimum: "Mínimo",
  maximum: "Máximo",
  "invalid-delivery-date-time-gift-card-title-message": "Oops..! Fecha/Hora de entrega invalida",
  "invalid-delivery-date-time-gift-card-message":
    "Una de sus tarjetas de regalo tiene una fecha o hora de entrega inválida. La próxima fecha y hora de entrega disponible sería",
  "exceeded-gift-card-amount-message":
    "El monto total de la(s) tarjeta(s) de regalo en el carrito supera el monto permitido de",
  "exceeded-gift-card-amount-remove-message":
    "Por favor, elimine una tarjeta de regalo o cambie el monto de la tarjeta de regalo.",
  "atleast-one-recipient-button": "Por favor agregue al menos un destinatario",
  "submit-recipient-error": "Los detalles del (los) destinatario(s) faltan o son inválidos",
  "one-valid-recipient-missing-error":
    "Necesita al menos un destinatario valido para la tarjeta de regalo",
  "gift-card-image-unavailable": "Imagen de la Tarjeta de Regalo no disponible",
  "view-gift-cards-button": "Ver Tarjetas de Regalo",
  "send-to-more-people": "Enviar a mas personas",
  "for-delivery-on": "para entrega en",
  to: "a",
  at: "en",
  continue: "Continuar",
  "confirmation-email-sent": "Le hemos enviado un email de confirmación",
  "gift-card-delivery-on": "Tarjeta de Regalo Electrónica para entrega en",
  one: "Uno",
  "go-to-gc-cart": "Ir al carro de compras de la tarjeta de regalo",
  "no-gift-cards-available": "No hay tarjetas de regalo disponibles en este momento",
  "gift-card-nav-label": "Historial de pedidos de tarjetas de regalo",
  "and-text": "y",
  "custom-amount": "Monto Personalizado",
  "minimum-one-recipient": "Se requiere mínimo un destinatario",
  "promo-code": "Código de Promoción",
  "promo-apply": "Aplicar",
  "send-a-gift-card": "Enviar una tarjeta de regalo",
  "denomination-error-heading": "Oops... Parece que algo salió mal",
  "denomination-error-text": "Estamos procesando tu pedido, por favor intenta nuevamente.",
  "recipient": "Destinatario",
  "delivery": "Entrega",
  "message": "Mensaje",
  "invalid-promo-code": "Código de oferta promocional inválido",
  "remove-applied-promo-codes": "Elimine códigos promocionales aplicados",
  "order-limit-reached": "Su pedido excede el limite",
  "item-order-limit": "Limite de pedido de",
  "reached": "Alcanzado",
  "promo": "Promoción"
};

import { callAPI } from "../../_common/Api";

export const isStoreAvailable = async (skin, language, store) => {
  let isAvailable = true;

  try {
    const api1306Data = await callAPI(skin, "info_vxl_1306", {
      params: [language, "1306", "mqid", "mqpass", "OUTLET", store.storeId, "t"],
      id: "1306",
    });

    // Uncomment to test failed store-level 1306 requests
    // const api1306Data = await callAPI(null);

    if (api1306Data.error) {
      // 1306 API (Store-level Vexilor config) error
      console.error(
        `API 1306 | Outlet ID ${store.storeId} | Error ${api1306Data.error.code} | ${api1306Data.error.message}`
      );
      isAvailable = false;
    }
    //
  } catch (error) {
    // Generic fallback for the failed 1306 API call
    console.error(`API 1306 | Store ${store.storeId}, ${store.name} | Error`);
    isAvailable = false;
  }

  return isAvailable;
};

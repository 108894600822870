import React from "react";

export const FormFieldset = (props) => {
  const {
    onInputChange,
    onSelectChange,
    onCheckboxChange,
    onRadioChange,
    resetInvalidField,
    resetPatternTooltip,
    onRecaptchaChange,
    id,
    legend,
    extraLabel,
    additionalClassnames = null
  } = props;

  const fields = React.Children.map(props.children, (fieldsetChild) => {
    /* check if a non-component element e.g. p, div, span e.t.c. */
    if (typeof fieldsetChild.type !== "string") {
      return React.cloneElement(fieldsetChild, {
        fieldData: props.fieldData[fieldsetChild.props.name],
        onInputChange,
        onSelectChange,
        onCheckboxChange,
        onRadioChange,
        resetInvalidField,
        resetPatternTooltip,
        onRecaptchaChange,
      });
    } else {
      return fieldsetChild; /* if a non-component element just render it as is */
    }
  });

  return (
    <fieldset id={id} className={additionalClassnames ? additionalClassnames + " form__fieldset" : "form__fieldset"}>
      <legend className="form-heading">{legend}</legend>
      {!!extraLabel && extraLabel}
      {fields}
    </fieldset>
  );
};
FormFieldset.displayName = "FormFieldset";

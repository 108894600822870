// Libraries
import React, { useState, useEffect, useContext } from "react";
import localforage from "localforage";
import { useHistory } from "react-router-dom";

// API helper functions
import { callAPI, callMarquiPaymentAPI } from "../../../../_common/Api";

// Contexts
import StoreContext from "../../../StoreContext";
import MerchantConfigContext from "../../../../App/MerchantConfigContext";

// UI components
import { LoadingSpinner } from "../../../../_common/LoadingSpinner";
import { parseURLParams } from "../../../../_common/helpers";

function PaymentMethodPaypalHandlePayment() {
  const history = useHistory();
  const storeContext = useContext(StoreContext);
  const merchantConfig = useContext(MerchantConfigContext);

  const skin = merchantConfig.skin;

  const [finalOrderTotal, setFinalOrderTotal] = useState(null);
  const [completedOrderId, setCompletedOrderId] = useState(null);

  const activeOrderStore = storeContext.activeOrderStore;

  useEffect(() => {
    localforage.getItem(skin + "__finalOrderTotal").then((storedFinalOrderTotal) => {
      if (storedFinalOrderTotal || storedFinalOrderTotal === 0) {
        setFinalOrderTotal(storedFinalOrderTotal);
      }
    });
    localforage.getItem(skin + "__completedOrderId").then((storedOrderId) => {
      if (storedOrderId) setCompletedOrderId(storedOrderId);
    });
  }, []);

  useEffect(() => {
    if (!window.location.href.includes("PayerID")) {
      history.push("/online-ordering/payment");
    }
  }, []);
  useEffect(() => {
    if (!!window.location.href.includes("PayerID") && !!completedOrderId && !!finalOrderTotal) {
      const urlParams = parseURLParams(window.location.href.replace("#/", ""));
      const payerId = urlParams.PayerID[0];
      const token = urlParams.token[0];

      if (!!urlParams.cancel) {
        cancelOrder(completedOrderId);
      } else {
        paypalConfirm(token, payerId);
      }
    }
  }, [completedOrderId, finalOrderTotal]);

  const paypalConfirm = (token, payerId) => {
    callMarquiPaymentAPI(skin, "paypal_confirm", {
      params: [
        skin,
        "consumer",
        "en",
        token, //token returned from paypal
        payerId, //payer id returned from paypal
        "", //customer_order_id
        "", //certrans_id
        "", //customer_id
        "", //loyalty_enroll_id
        completedOrderId, //online_order_id
        "", //scene_cardnum
        "", //cc_email
      ],
      id: "paypal_confirm",
    }).then((confirmPaypalData) => {
      if (confirmPaypalData.status === 0) {
        setTimeout(() => {
          history.push({
            pathname: "/online-ordering/order-confirmation",
          });
        }, 500);
      } else {
        cancelOrder(completedOrderId);
      }
    });
  };

  const cancelOrder = (orderID) => {
    //cancel The order using API 1303
    callAPI(skin, "vxl_1303", {
      params: ["en", "1303", "mqid", "mqpass", activeOrderStore.storeId, orderID],
      id: "1303",
    }).then((data1303) => {
      localforage.removeItem(skin + "__paypalPaymentStarted")
      history.push("/online-ordering/payment");
    });
  };

  return (
    <div style={{ height: "100vh" }}>
      <LoadingSpinner />
    </div>
  );
}

export default PaymentMethodPaypalHandlePayment;

import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

import { callAPI } from "../../_common/Api";

import AppLanguageContext from "../../App/AppLanguageContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";

import { Form } from "../../_common/Form/Form";
import { FormInput } from "../../_common/Form/FormInput";

export const ForgotPasswordForm = () => {
  const skin = useContext(MerchantConfigContext).skin;
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [apiError, setAPIError] = useState("");
  const [isAPISubmitting, setAPISubmitting] = useState(false);

  const history = useHistory();

  const sendTemporaryPassword = (data) => {
    // Retrieve form values
    let username;
    let email;

    data.forEach((field) => {
      if (field.name === "email") {
        email = field.value;
        username = email;
      }
    });

    setAPISubmitting(true); // Initiate submit button animations

    // Call API for sending user a temporary password
    callAPI(skin, "dc_954", {
      params: [appLanguage, "954", "mqid", "mqpass", username, email],
      id: "954",
    }).then((data) => {
      setAPISubmitting(false);

      if (data.result.I0) {
        // Successfully sent temporary password to user's email
        setIsAPISubmitValid(true);
        localforage.setItem(skin + "__tempPasswordTimestamp", new Date().getTime().toString());

        history.push("/login-with-temporary-password");
      } else {
        // User entered a wrong email or API timed out

        if (data.status === 322) {
          // wxget: forgot password's incorrect email error
          setAPIError(data.result.message); // "Invalid customer login"
          console.log("The user with this email doesn't exist"); // TEMP - for debugging
          // setAPIError("The user with this email doesn't exist");
        } else {
          // wxget: generic fallback API error
          setAPIError(appLabels["form"]["generic-fallback-api-error"]);
        }
        setIsAPISubmitValid(false);
      }
    });
  };

  const resetAPIError = () => {
    setAPIError("");
  };

  return (
    <div className="form-wrapper forgot-password__form-wrapper">
      <Form
        id="form--forgot-password"
        submitButtonText={appLabels["login-register"]["reset-password"]}
        submitButtonSuccessText={appLabels["login-register"]["sending-password"]}
        submitForm={sendTemporaryPassword}
        submitAPIError={apiError}
        resetAPIError={resetAPIError}
        isAPISubmitValid={isAPISubmitValid}
        isAPISubmitting={isAPISubmitting}
      >
        <FormInput
          type="text"
          label={appLabels["login-register"]["login-email"]}
          id="input--email"
          name="email"
          isRequired={true}
          autocomplete="on"
        />
      </Form>
    </div>
  );
};

import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/** UI Component */
import { ReactComponent as IconBill } from "../../_common/icons/IconBill.svg";
import { ReactComponent as IconMenu } from "../../_common/icons/IconMenu.svg";

/** Contexts */
import AppLabelsContext from "../../App/AppLabelsContext";

/** CSS */
import "./MenuBillToggle.css";

const MenuBillToggle = (props) => {
  const { initialState } = props;
  const appLabels = useContext(AppLabelsContext);
  const history = useHistory();

  const [currentToggleState, setCurrentToggleState] = useState(initialState);

  const toggleMenuBill = (state) => {
    setCurrentToggleState(state);

    if (state === "menu") {
      history.push("/online-ordering/menu");
    } else {
      history.push("/online-ordering/bill");
    }
  };

  const [activeToggleClass, setActiveToggleClass] = useState("");
  useEffect(() => {
    setActiveToggleClass(" is-active");
  }, [currentToggleState]);

  return (
    <div className="menu-bill-toggle__container">
      <button
        className={`menu-bill-toggle__button ${
          currentToggleState === "menu" ? `${activeToggleClass}` : ""
        }`}
        onClick={() => {
          toggleMenuBill("menu");
        }}
      >
        <span>
          <IconMenu />
          {appLabels["order"]["menu"]}
        </span>
      </button>
      <button
        className={`menu-bill-toggle__button ${
          currentToggleState === "bill" ? `${activeToggleClass}` : ""
        }`}
        onClick={() => {
          toggleMenuBill("bill");
        }}
      >
        <span>
          <IconBill />
          {appLabels["order"]["bill"]}
        </span>
      </button>
    </div>
  );
};
export default MenuBillToggle;

import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import he from "he";

import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import StoreContext from "../../OnlineOrdering/StoreContext";

import canadianProvinces from "./data/CanadianProvinces.json";
import unitedStates from "./data/UnitedStates.json";
import australianProvinces from "./data/AustralianProvinces.json";
import brazillianProvinces from "./data/BrazilianProvinces.json";
import mexicanStates from "./data/MexicanStates.json";
import chineseProvinces from "./data/ChineseProvinces.json";
import japaneseRegions from "./data/JapaneseRegions.json";

import { postalMask } from "./helpers/postalMask";
import { removeAllSpaces, validateForm } from "./helpers/formValidation";
import { FormSubmit } from "./FormSubmit.js";
import { getPhoneNumberFormat, getDateFormat } from "./helpers/formFieldFormattingHelpers";

import { FAB } from "../buttons/FAB/FAB";

import "./Form.css";
import LocationsContext from "../../OnlineOrdering/Locations/LocationsContext";

export const Form = forwardRef((props, ref) => {
  const [isFormValid, setIsFormValid] = useState(null);
  const [formData, setFormData] = useState({});

  const merchantConfig = useContext(MerchantConfigContext);
  const allStoresContext = useContext(LocationsContext);
  const activeOrderStore = useContext(StoreContext).activeOrderStore;
  const rawCountriesList = merchantConfig.merchant.I16;
  const countriesList = rawCountriesList.map((country) => {
    return { value: country[0], displayValue: he.decode(country[1]) };
  });


  useImperativeHandle(ref, () => ({

    handleQuickSubmit() {
      let validateData = props.eGiftCardAmountLimits ? validateForm(formData, appLabels["form"], false, props.eGiftCardAmountLimits) : validateForm(formData, appLabels["form"])
      setIsFormValid(validateData.isValid)
      setFormErrorsAriaTexts(validateData.fieldErrors)

      let data = getAllValuesToSubmit()
      props.submitForm(data, validateData.isValid)
    },
    handleFormAllClear() {
      let tempFormData = { ...formData };
      for (const key in tempFormData) {
        if (key !== "deliveryDateAndTime") {
          if (key === "eGiftCardAmount") {
            tempFormData[key].value = props.isGiftCardSoloAmount ? tempFormData[key].value : "";
          }
          else {
            tempFormData[key].value = ""
          }
        }
      }
      setFormData(tempFormData);
    },
    clearSelectValue() {
      let tempData = formData
      if (tempData.eGiftCardAmount) {
        tempData.eGiftCardAmount.value = ""
        setFormData(tempData)
      }
    }

  }));

  useEffect(() => {
    let tempFormData = { ...formData };

    const setUpInitialFormData = (formChild) => {
      if (!props) {
        return
      }

      if (formChild !== "" && formChild != null) {
        let fieldName = formChild.props.name;
        let fieldData = tempFormData[fieldName];
        let isMaskedField = formChild.props.isMaskedInput;
        if (formChild.type.displayName === "FormRadios") {
          fieldData = {
            checkedValue: formChild.props.initialValue,
          };
        } else if (formChild.type.displayName === "FormCheckbox") {
          fieldData = {
            isChecked: formChild.props.isDefaultChecked || false,
            isValid: true,
            errorMessage: "",
            isRequired: formChild.props.isRequired || false,
            type: "checkbox",
          };
          if (formChild.props.value) fieldData.value = formChild.props.value;
        } else {
          fieldData = {
            value: formChild.props.defaultValue || "",
            isValid: true,
            errorMessage: "",
            isRequired: formChild.props.isRequired || false,
          };
        }

        if (formChild.type.displayName === "FormSelect") {
          if (fieldName === "country") {
            //if there are specific countries defined for the country <FormSelect>
            if (formChild.props.children) {
              let customCountryList = [];
              formChild.props.children.forEach((child) => {
                customCountryList.push({
                  value: child.props.value,
                  displayValue: child.props.text,
                });
              });
              fieldData.options = customCountryList;
            } else {
              fieldData.options = countriesList;
            }
          } else if (fieldName === "province" && tempFormData.country) {
            fieldData.isHidden = true; // hide province dropdown by default if country dropdown exists
          }
          else {
            fieldData.options = React.Children.toArray(formChild.props.children).map((option) => {
              return { value: option.props.value, displayValue: option.props.text };
            });
            if (formChild.props.defaultValue) fieldData.value = formChild.props.defaultValue;
          }

          // Pre-select the option if it's the only option available
          if (fieldData.options && fieldData.options.length === 1) {
            fieldData.value = fieldData.options[0].value;
          }

          // Generate and show the provinces/states dropdown when country select option is pre-selected
          if (
            fieldName === "province" &&
            tempFormData.country &&
            tempFormData.country.value !== ""
          ) {
            fieldData.isHidden = false; // render the provinces/states select options
            updateProvincesDropdown(tempFormData.country.value, fieldData); // generate the provinces/states select options
          }
        }


        else if (formChild.type.displayName === "FormDateTimeInputCombo") {
          fieldData = {
            value: formChild.props.value ? formChild.props.value : formChild.props.defaultValue,
            isValid: true,
            errorMessage: "",
            isRequired: formChild.props.isRequired || false,
          };

        }
        else if (
          formChild.type.displayName === "FormInput" &&
          formChild.props.name.includes("password")
        ) {
          fieldData.minLength = 8;
        } else if (isMaskedField) {
          /* Set up masked input's attributes where some fields will have them as dynamic that will change either on other value change or another field interaction */
          if (fieldName === "credit-card-number") {
            fieldData.minLength = 12; // for validation when # of chars < pattern requirement
            fieldData.maxLength = 16; // for setting maximum digits for credit card number
            fieldData.title = "credit card number";
          } else if (fieldName === "credit-card-expiry") {
            fieldData.placeholder = "mm/yy";
            fieldData.maskPattern = "99/99";
            fieldData.displayMaskPattern = "99/99";
            fieldData.minLength = 5; // for validation when # of chars < pattern requirement
            fieldData.title = "4-digit numeric expiry date in the format of MM/YY";
          } else if (fieldName === "credit-card-cvv") {
            if (props.isSavedCCForm) {
              fieldData.placeholder = appLabels["form"]["cvv"];
              fieldData.maskPattern = "9999";
              fieldData.displayMaskPattern = "9999";
              fieldData.maxLength = 4;
              fieldData.minLength = 3; // for validation when # of chars < pattern requirement
              fieldData.title = "4-digit number";
            } else {
              fieldData.placeholder = "xxx";
              fieldData.maskPattern = "999";
              fieldData.displayMaskPattern = "999";
              fieldData.minLength = 3; // for validation when # of chars < pattern requirement
              fieldData.title = "3-digit number";
            }
          } else if (fieldName === "birthday") {
            const birthdayFormat = getDateFormat(activeOrderStore, allStoresContext);
            fieldData.placeholder = birthdayFormat.placeholder;
            fieldData.maskPattern = birthdayFormat.maskPattern;
            fieldData.displayMaskPattern = birthdayFormat.displayMaskPattern;
            fieldData.minLength = birthdayFormat.minLength;
            fieldData.title = birthdayFormat.title;
          } else if (
            fieldName === "phone" ||
            fieldName === "phone-number" ||
            fieldName === "cell-number"
          ) {
            const phonNumberFormat = getPhoneNumberFormat(activeOrderStore, allStoresContext);
            if (phonNumberFormat) {
              fieldData.placeholder = phonNumberFormat.placeholder;
              fieldData.maskPattern = phonNumberFormat.maskPattern;
              fieldData.displayMaskPattern = phonNumberFormat.displayMaskPattern;
              fieldData.minLength = phonNumberFormat.minLength; // for validation when # of chars < pattern requirement
              fieldData.title = phonNumberFormat.title;
            }
          }
        }
        tempFormData[fieldName] = fieldData;
      }
    };

    React.Children.toArray(props.children).forEach((formChild) => {
      /* check if a non-component element e.g. p, div, span e.t.c. */
      if (formChild !== "" && formChild != null && typeof formChild.type !== "string") {
        if (formChild.type.displayName === "FormFieldset") {
          React.Children.toArray(formChild.props.children).forEach((fieldsetChild) => {
            setUpInitialFormData(fieldsetChild);
          });
        } else {
          React.Children.toArray(props.children).forEach((formChild) => {
            setUpInitialFormData(formChild);
          });
        }
      }
    });
    setFormData(tempFormData);

  }, []);

  // wathces for showInputSelectCombo flag to indicate usafe of FormSelectInput combo field
  useEffect(() => {
    if (props.showInputSelectCombo) {
      let tempFormData = formData
      tempFormData.eGiftCardAmount.value = props.inputSelectComboValue ? props.inputSelectComboValue : ""
      setFormData(tempFormData)
    }
  }, [props.showInputSelectCombo])

  // used to set default value for gift card amount when FormSelect or FormSelectInputCombo is being used
  useEffect(() => {
    if (props.gcDenominationList) {
      if (props.gcDenominationList[0] && props.gcDenominationList[0] !== "-1" && props.gcDenominationList[0].length === 1) {
        let tmpFormData = formData
        tmpFormData.eGiftCardAmount.value = props.gcDenominationList[0]
        setFormData(tmpFormData)
      }
    }
  }, [props.gcDenominationList])

  //used to set default value for gift card greetings message in FormTextArea

  useEffect(() => {

    if (props.hasGreetingsMessage) {
      if (props.greetingsMessage && props.greetingsMessage !== "" && formData.greetingsMessage) {
        let tmpFormData = formData
        tmpFormData.greetingsMessage.value = props.greetingsMessage
        setFormData(tmpFormData)
        props.setHasGreetingsMessage(false)
      }
    }
  }, [props.hasGreetingsMessage])

  useEffect(() => {
    if (props.defaultDateValue) {
      let tmpFormData = formData
      tmpFormData.deliveryDateAndTime.value = props.defaultDateValue.toString()
      setFormData(tmpFormData)
      props.setHasGreetingsMessage(false)

    }
  }, [props.defaultDateValue])

  // wathces for clearFormGiftCardAmount flag to clear eGiftCardAmount value
  useEffect(() => {
    if (props.clearFormGiftCardAmount) {
      clearEGiftCardAmountValue()
      props.setClearFormGiftCardAmount(false)
    }
  }, [props.clearFormGiftCardAmount])

  // used to manually clear eGiftCardAmount value when a card changes to one that has denomination list
  function clearEGiftCardAmountValue() {
    let formDataCopy = formData
    if (formDataCopy.eGiftCardAmount && formDataCopy.eGiftCardAmount.value) {
      formDataCopy.eGiftCardAmount.value = ""
      setFormData(formDataCopy)
    }
  }

  /* Certain AMEX credit cards expect a 4-digit CVV number */
  const updateCVVFormat = (length) => {
    let tempFormData = { ...formData };
    let cvvField = tempFormData["credit-card-cvv"];
    cvvField.minLength = length;
    cvvField.placeholder = length === 3 ? "xxx" : "xxxx";
    cvvField.maskPattern = length === 3 ? "999" : "9999";
    cvvField.displayMaskPattern = length === 3 ? "999" : "9999";
    cvvField.title = length + "-digit number";
    setFormData(tempFormData);
  };

  // function to update maximum number of credit card digits
  const updateCreditCardFormat = (length) => {
    let tempFormData = { ...formData };
    let creditCardNumberField = tempFormData["credit-card-number"];
    creditCardNumberField.maxLength = length;
    setFormData(tempFormData);
  };

  const identifyCreditCardType = (number) => {
    let creditCardType = "";
    const firstNumber = number.charAt(0);

    /** American Express */
    if (firstNumber === "3") {
      creditCardType = "AMEX";
      updateCreditCardFormat(15);
      updateCVVFormat(4);
    } else if (firstNumber === "4") {
      /** Visa and Visa Electron (Visa Debit) */
      const visaElectronPrefixes = ["4844", "4508", "4913", "4917", "4427", "417500"];
      let isVisaElectron = false;
      for (let prefix of visaElectronPrefixes) {
        if (number.startsWith(prefix)) {
          creditCardType = "VISADEBIT";
          updateCreditCardFormat(prefix === "4427" ? 19 : 16);
          updateCVVFormat(3);
          isVisaElectron = true;
          break;
        }
      }

      if (!isVisaElectron) {
        creditCardType = "VISA";
        updateCreditCardFormat(16);
        updateCVVFormat(3);
      }
    } else if (firstNumber === "") {
      creditCardType = "";
      updateCreditCardFormat(16);
      updateCVVFormat(3);
    } else if (
      /** Master Card */
      firstNumber === "5" &&
      !number.match(
        /^(?:401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636368|655000|655001|651652|651653|651654|650485|650486|650487|650488|506699|5067[0-6][0-9]|50677[0-8]|509\d{3})\d{10}$/
      )
    ) {
      creditCardType = "MC";
      updateCreditCardFormat(16);
      updateCVVFormat(3);
    } else if (number.match(/^(606282\d{10}(\d{3})?)|(3841(0|4|6)0\d{13})$/)) {
      /** Hipercard Credit */
      creditCardType = "HIPERCARD";
      updateCreditCardFormat(16);
      updateCVVFormat(3);
    } else if (
      /** Elo Credit or Elo Debit */
      number.match(
        /^(?:401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636368|655000|655001|651652|651653|651654|650485|650486|650487|650488|506699|5067[0-6][0-9]|50677[0-8]|509\d{3})\d{10}$/
      )
    ) {
      creditCardType = "ELO";
      updateCreditCardFormat(16);
      updateCVVFormat(3);
    } else {
      /** No type found */
      creditCardType = "invalid";
    }

    //TODO: validate SIDECARD, FININVEST

    if (props.updateActiveCreditCardType) props.updateActiveCreditCardType(creditCardType);
  };

  const [prevInputValue, setPrevInputValue] = useState("");

  const toggleMaskedInputTooltip = (field) => {
    let tempFormData = { ...formData };
    setPrevInputValue(field.value);

    if (
      prevInputValue === field.value &&
      removeAllSpaces(field.value).length !== tempFormData[field.name].minLength
    ) {
      tempFormData[field.name].isPatternTooltip = true;
    } else {
      tempFormData[field.name].isPatternTooltip = false;
    }
    setFormData(tempFormData);
  };

  const onInputChange = (event, isElement = false, customValue = null) => {
    const field = isElement ? event : event.target;
    let tempFormData = { ...formData };

    if (field.name === "credit-card-number") {
      identifyCreditCardType(field.value);
    }

    if (field.getAttribute("ismaskedinput")) {
      toggleMaskedInputTooltip(field);
    }

    if (field.name.indexOf("email") >= 0 || field.name.indexOf("e-mail") >= 0) {
      tempFormData[field.name].value = field.value.toLowerCase();
    } else {
      tempFormData[field.name].value = field.value;
    }

    if (field.name === "phone-number" || field.name === "cell-number" ||
      field.name === "phone") {
      tempFormData[field.name].value = field.value.substr(0, 14);
    }

    if (field.name === "deliveryDateAndTime") {
      tempFormData[field.name].value = customValue.toString();
    }

    setFormData(tempFormData);
  };

  const onCheckboxChange = (event) => {
    const field = event.target;
    let tempFormData = { ...formData };
    tempFormData[field.name].isChecked = event.target.checked;

    //Required checkboxes
    if (tempFormData[field.name].isRequired && !tempFormData[field.name].isChecked) {
      tempFormData[field.name].isValid = false;
    } else {
      tempFormData[field.name].isValid = true;
    }

    const newData = { ...formData, ...tempFormData };
    setFormData(newData);
    updateParentState(newData);
  };

  const onRadioChange = (event) => {
    const field = event.target;

    let tempFormData = { ...formData };
    tempFormData[field.name].checkedValue = field.value;

    const newData = { ...formData, ...tempFormData };
    setFormData(newData);
    updateParentState(newData);
  };

  const appLabels = useContext(AppLabelsContext);

  const updatePostalFormat = (country, province) => {
    let tempFormData = { ...formData };
    let postalField = tempFormData.postal;

    postalField.label = appLabels["form"]["postal"]; // label for most countries

    if (country === "CA") {
      postalField.placeholder = "xxxxxx";
      postalField.minLength = 6;
      postalField.maskPattern = postalMask;
      postalField.displayMaskPattern = "X1X1X1";
      postalField.title = "6-character alphanumeric zip code in the format of X1X 1X1";
      postalField.ariaPlaceholder = "6-character alphanumeric zip code in the format of X1X 1X1";
      if (province && province !== "") {
        let firstLetter = "";
        let displayFirstLetter = "";
        const letter = /[A-Z]/i;
        const digit = /[0-9]/;

        switch (province) {
          case "AB":
            firstLetter = /t|T/;
            displayFirstLetter = "T";
            break;
          case "BC":
            firstLetter = /v|V/;
            displayFirstLetter = "V";
            break;
          case "MB":
            firstLetter = /r|R/;
            displayFirstLetter = "R";
            break;
          case "NB":
            firstLetter = /e|E/;
            displayFirstLetter = "E";
            break;
          case "NL":
            firstLetter = /a|A/;
            displayFirstLetter = "A";
            break;
          case "NT":
            firstLetter = /x|X/;
            displayFirstLetter = "X";
            break;
          case "NS":
            firstLetter = /b|B/;
            displayFirstLetter = "B";
            break;
          case "NU":
            firstLetter = /x|X/;
            displayFirstLetter = "X";
            break;
          case "ON":
            firstLetter = /[k-p]|[K-P]/;
            displayFirstLetter = "(K-P)";
            break;
          case "PE":
            firstLetter = /c|C/;
            displayFirstLetter = "C";
            break;
          case "QC":
            firstLetter = /[g-k]|[G-K]/;
            displayFirstLetter = "(G-K)";
            break;
          case "SK":
            firstLetter = /s|S/;
            displayFirstLetter = "T";
            break;
          case "YT":
            firstLetter = /y|Y/;
            displayFirstLetter = "Y";
            break;
          default:
            firstLetter = "";
            displayFirstLetter = "";
        }

        const mask = [firstLetter, digit, letter, digit, letter, digit];
        postalField.maskPattern = mask;
        postalField.displayMaskPattern = displayFirstLetter + "1X1X1";
        postalField.isHidden = false;
      }
    } else if (country === "US") {
      postalField.label = "Zip Code";
      postalField.placeholder = "xxxxx";
      postalField.minLength = 5;
      postalField.maskPattern = "99999";
      postalField.displayMaskPattern = "99999";
      postalField.title = "5-digit number";
      postalField.isHidden = false;
      postalField.ariaPlacholder = "5-digit number"
    } else if (country === "HK") {
      postalField.isRequired = false;
      postalField.isHidden = true;
    } else {
      // Remove the masked attributes for all other countries
      delete postalField.placeholder;
      delete postalField.minLength;
      delete postalField.maskPattern;
      delete postalField.displayMaskPattern;
      delete postalField.title;
      postalField.isHidden = false;
    }

    setFormData(tempFormData);
  };

  const sortByKey = (array, key) => {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  const updateProvincesDropdown = (country, fieldData) => {
    const tempFormData = { ...formData };
    const provinceSelect = fieldData || tempFormData.province;

    /** The province drop down should be mandatory if it is related to adding a credit card to the account or paying with credit card
     * If a certain country does not require province then it will get setup in the fallback "else" below based on selected country
     * Otherwise the requirement for this drop down should be datadriven based on the API 1024
     */
    if (
      window.location.href.includes("payment") ||
      window.location.href.includes("add-credit-card") ||
      window.location.href.includes("add-money") ||
      window.location.href.includes("delivery")
    ) {
      provinceSelect.isRequired = true;
    }
    if (country === "CA") {
      provinceSelect.options = sortByKey(canadianProvinces.provinces, "displayValue");
      provinceSelect.label = appLabels["form"]["province"];
    } else if (country === "US") {
      provinceSelect.options = sortByKey(unitedStates.states, "displayValue");
      provinceSelect.label = "State";
    } else if (country === "AU") {
      provinceSelect.options = sortByKey(australianProvinces.provinces, "displayValue");
      provinceSelect.label = appLabels["form"]["province"];
    } else if (country === "BR") {
      provinceSelect.options = sortByKey(brazillianProvinces.provinces, "displayValue");
      provinceSelect.label = appLabels["form"]["province"];
    } else if (country === "MX") {
      provinceSelect.options = sortByKey(mexicanStates.states, "displayValue");
      provinceSelect.label = "States";
    } else if (country === "JP") {
      provinceSelect.options = sortByKey(japaneseRegions.regions, "displayValue");
      provinceSelect.label = "Region";
    } else if (country === "CN") {
      provinceSelect.options = sortByKey(chineseProvinces.provinces, "displayValue");
      provinceSelect.label = appLabels["form"]["province"];
    } else {
      provinceSelect.options = [];
      provinceSelect.label = "";
      provinceSelect.isHidden = true;
      provinceSelect.isRequired = false;
    }
    setFormData(tempFormData);
  };

  const onSelectChange = (select) => {
    let tempFormData = { ...formData };
    let selectField = tempFormData[select.name];
    let countrySelect = tempFormData.country;
    let provinceSelect = tempFormData.province;
    let postalField = tempFormData.postal;
    selectField.value = select.value;

    if (select.value !== "") selectField.isValid = true;

    if (select.name === "country") {
      if (provinceSelect) {
        provinceSelect.isHidden = false;
        provinceSelect.value = "";
        updateProvincesDropdown(select.value);
      }
      if (postalField) {
        postalField.isHidden = true;
        updatePostalFormat(select.value);
      }
    } else if (select.name === "province" && postalField) {
      updatePostalFormat(countrySelect.value, select.value);
    } else if (select.name === "giftcard-request-question") {
      /** if the gift card request question select on registraton form was changed
       * check the "aria-required" attrobute of the gift card number element
       * if it is true update the formData accordingly, else set isRequired for gift card number to false
       * Note: This is required due to the property changes of the gift card number field based on the request card number drop down, and the form data not getting updated with each change
       * Note: the setTimeout is required to make sure the attribute value of the gift card input element has changed before trying to read it.
       */
      setTimeout(() => {
        if (
          document.getElementById("input--giftcard-number").getAttribute("aria-required") === "true"
        ) {
          tempFormData["giftcard-number"].isRequired = true;
        } else {
          tempFormData["giftcard-number"].isRequired = false;
        }
      }, 250);
    }

    setFormData(tempFormData);
    updateParentState(tempFormData);
  };

  const onRecaptchaChange = (value) => {
    let tempFormData = { ...formData };

    if (value == null) tempFormData.reCAPTCHA.isValid = false;
    tempFormData.reCAPTCHA.value = value;

    setFormData(tempFormData);
  };

  const resetInvalidField = (field) => {
    let tempFormData = { ...formData };
    if (field.name === "confirm-new-password" || field.name === "new-password") {
      tempFormData["confirm-new-password"].isValid = true;
      tempFormData["new-password"].isValid = true;
    } else {
      tempFormData[field.name].isValid = true;
    }
    setFormData(tempFormData);
  };

  const resetPatternTooltip = (field) => {
    let tempFormData = { ...formData };
    tempFormData[field.name].isPatternTooltip = false;
    setFormData(tempFormData);
  };

  const getAllValuesToSubmit = () => {
    let valuesToSubmit = [];
    for (let key in formData) {
      const field = formData[key];
      let newField = {};
      newField.name = key;
      newField.value = field.value
      valuesToSubmit.push(newField);
    }
    return valuesToSubmit
  }

  const getFormValuesToSubmit = () => {
    let valuesToSubmit = [];

    for (let key in formData) {
      const field = formData[key];

      if (
        (field.type === "checkbox" && field.isChecked) ||
        (field.type !== "checkbox" &&
          field.isValid &&
          field.value !== "" &&
          typeof field.value !== "undefined") ||
        field.checkedValue
      ) {
        let newField = {};
        newField.name = key;
        newField.value = field.value ? field.value : field.checkedValue;
        valuesToSubmit.push(newField);
      }
    }
    return valuesToSubmit;
  };

  let [formErrorsAriaText, setFormErrorsAriaTexts] = useState([])
  const handleSubmit = (event) => {
    event.preventDefault();
    let validateFormFields = validateForm(formData, appLabels["form"]);
    const isValid = props.isFABFormValid
      ? props.isFABFormValid
      : validateForm(formData, appLabels["form"], props.isSavedCCForm).isValid;

    setIsFormValid(isValid);
    if (!isValid) {
      setFormErrorsAriaTexts(validateFormFields.fieldErrors)
    }
    else {
      setFormErrorsAriaTexts([])
    }
    const submitData = getFormValuesToSubmit();

    if (props.submitForm && isValid) {
      //If form doesn't allow empty submit - prevent from submitting
      if (!props.isAllowEmptySubmit && (!submitData || !submitData.length)) return;
      props.submitForm(submitData);
    }
  };

  useEffect(() => {
    if (props.submitAPIError && props.submitAPIError !== "") {
      setIsFormValid(false);
    }
  }, [props.submitAPIError]);

  const resetInvalidForm = () => {
    setIsFormValid(null);
    if (props.resetAPIError) props.resetAPIError();
  };

  /* Clone each prop child (field object) and while doing so insert new props (updated field data and functions) */
  const fields = React.Children.map(props.children, (child) => {
    /* check if a non-component element e.g. p, div, span e.t.c. */
    if (child !== "" && child != null && typeof child.type !== "string") {
      const element = {
        onInputChange,
        onSelectChange,
        onCheckboxChange,
        onRadioChange,
        resetInvalidField,
        resetPatternTooltip,
        onRecaptchaChange,
      };
      if (child.type.displayName === "FormFieldset") {
        element.fieldData = Object.entries(formData).length > 0 && formData;
      } else {
        element.fieldData = Object.entries(formData).length > 0 && formData[child.props.name];
      }
      return React.cloneElement(child, element);
    } else {
      return child; /* if a non-component element just render it as is */
    }
  });

  //Optional passing of current data to parent component
  const updateParentState = (data) => {
    if (props.setCurrentData) props.setCurrentData(data);
  };

  return (
    <>

      <form
        id={props.id}
        className={`form ${props.className || ""}`}
        onSubmit={(e) => e.preventDefault()}

      >

        {formData && Object.entries(formData).length > 0 && fields}
        <div className="form-submit__wrapper">
          {!!props.extraButtons && props.extraButtons}
          {(!props.hideSubmitButton && !props.isQuickSubmit) &&
            (props.isFAB ? (
              <FAB
                formId={props.id}
                isFormValid={isFormValid}
                isAPISubmitValid={props.isAPISubmitValid}
                buttonText={props.submitButtonText || appLabels["form"]["default-submit-button"]}
                buttonSuccessText={
                  props.submitButtonSuccessText || appLabels["form"]["default-submit-button-success"]
                }
                buttonLoadingText={
                  props.submitButtonLoadingText ||
                  appLabels["form"]["default-submit-button-processing"]
                }
                isAPIsubmitting={props.isAPIsubmitting}
                formError={props.submitAPIError || appLabels["form"]["missing-required-fields-error"]}
                errorDetails={formErrorsAriaText}
                onSubmit={handleSubmit}
                resetInvalidForm={resetInvalidForm}
                navigateURL={props.submitNavigateURL}
                navigateURLStateFrom={props.submitNavigateURLStateFrom}
                isDisabled={!!props.isDisableSubmit}
                isBackgroundGradient={true}
                isModalFAB={props.isModalFAB}
                FABCustomClass={props.buttonWrapperClass}
                isBufferBeforeNavigationDisabled={props.isBufferBeforeNavigationDisabled}
              customStyle={props.customStyle}
              attachToBottomOfMainContent = {window.location.href.includes("confirm-address")}
              />
            ) : (
              <FormSubmit
                formId={props.id}
                isFormValid={isFormValid}
                isAPISubmitValid={props.isAPISubmitValid}
                buttonText={props.submitButtonText || appLabels["form"]["default-submit-button"]}
                buttonSuccessText={
                  props.submitButtonSuccessText || appLabels["form"]["default-submit-button-success"]
                }
                buttonLoadingText={props.showLoadingText === false ? props.submitButtonText || appLabels["form"]["default-submit-button"] :
                  props.submitButtonLoadingText ||
                  appLabels["form"]["default-submit-button-processing"]
                }
                isSubmitResettable={props.isSubmitResettable}
                isAPIsubmitting={props.isAPIsubmitting}
                formError={props.submitAPIError || appLabels["form"]["missing-required-fields-error"]}
                errorDetails={formErrorsAriaText}
                onSubmit={handleSubmit}
                resetInvalidForm={resetInvalidForm}
                navigateURL={props.submitNavigateURL}
                navigateURLStateFrom={props.submitNavigateURLStateFrom}
                isDisabled={!!props.isDisableSubmit}
                buttonWrapperClass={props.submitButtonWrapperClass}
                submitButtonCustomClass={props.submitButtonCustomClass}
                isBufferBeforeNavigationDisabled={props.isBufferBeforeNavigationDisabled}
              />
            ))}
        </div>


      </form>

    </>
  );
});

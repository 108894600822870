import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppLabelsContext from "../../../App/AppLabelsContext";
import BrandingContext from "../../../App/BrandingContext";
import { getContrastColor } from "../../../_common/colorHelpers";
import { ReactComponent as IconHeart } from "../../../_common/icons/IconHeart.svg";
import "./MenuCategoryCard.css";

export const MenuFavoritesCategoryCard = () => {
  const appLabels = useContext(AppLabelsContext);
  const brandingContext = useContext(BrandingContext);
  const itemNameBackgroundColor = brandingContext["menu-card-item-name-background"];

  return (
    <li className="menu__category-card">
      <div className="menu-category-card__content-wrapper">
        <div className="menu-category-card__image-wrapper">
          <IconHeart fill="#FC8181" stroke="#FC8181" width="12px" height="12px" />
        </div>
        <div
          className="menu-category-card___text-wrapper"
          style={{ backgroundColor: itemNameBackgroundColor || "#fff" }}
        >
          <h2
            className="menu-category-card__name"
            style={{
              color: itemNameBackgroundColor
                ? getContrastColor(itemNameBackgroundColor)
                : "#2a2a2a",
            }}
          >
            {appLabels["order"]["favorites"]}
          </h2>
        </div>
        <Link
          to="/online-ordering/menu/favorites"
          aria-label={appLabels["order"]["favorites"]}
          className="menu-category-card__button"
        />
      </div>
    </li>
  );
};

import localforage from "localforage";

/**
 * Get Device OS version
 * @param {Boolean} isIOSNativeApp
 * @param {Boolean} isAndroidNativeApp
 * @returns
 */
function getDeviceOSVersion(isIOSNativeApp, isAndroidNativeApp) {
  if (isIOSNativeApp && navigator.userAgent.match(/iPhone OS \d+(_\d+)*(_\d+)*\s+/)) {
    return navigator.userAgent.match(/iPhone OS \d+(_\d+)*(_\d+)*\s+/)[0].replace(/_/g, ".");
  } else if (isAndroidNativeApp && navigator.userAgent.match(/Android (\d+(?:\.\d+)*)/)) {
    return navigator.userAgent.match(/Android (\d+(?:\.\d+)*)/)[0];
  } else {
    return "";
  }
}

/**
 * get user's email
 * @param {string} skin
 */
async function getUserEmail(skin, preserveCustomerInfo = false) {
  const customerInfo = await localforage.getItem(skin + "__customerInfo");
  if (customerInfo) {
    if (window.xtremepush && !preserveCustomerInfo) {
      if ("login" in customerInfo) {
        window.xtremepush("set", "user_id", customerInfo.login);
        iOSXPSetHandler("user_id", customerInfo.login);
      }
      else {
        window.xtremepush("set", "user_id", customerInfo.email);
        iOSXPSetHandler("user_id", customerInfo.email);
      }
    }
    return preserveCustomerInfo && "login" in customerInfo ? customerInfo.login : customerInfo.email;
  } else {
    return null;
  }
}

function getDeviceInfo() {
  if (window.xtremepush) {
    return window.xtremepush("get", "device_info");
  } else {
    return "";
  }
}

function iOSXPEventHandler(eventName, eventData) {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.xpEventHandler
  ) {
    let message = {};
    message.eventName = eventName;
    message.eventData = eventData;

    window.webkit.messageHandlers.xpEventHandler.postMessage(JSON.stringify(message));
  }
}

function iOSXPSetHandler(setParameter, setData) {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.xpSetHandler
  ) {
    let message = {};
    message.setMethod = "set";
    message.setParameter = setParameter;
    message.setData = setData;

    window.webkit.messageHandlers.xpSetHandler.postMessage(JSON.stringify(message));
  }
}

function iOSXPTagHandler(tagParameter, tagData) {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.xpTagHandler
  ) {
    let message = {};
    message.tagMethod = "tag";
    message.tagParameter = tagParameter;
    message.tagData = tagData;

    window.webkit.messageHandlers.xpTagHandler.postMessage(JSON.stringify(message));
  }
}

/**
 * Sends login information to XP
 * Triggered when user logs in
 * @param {object} userInfo
 * @param {Number} deviceWidth
 * @returns
 */
export const onLoginXtreme = (userInfo, deviceWidth) => {
  if (window.xtremepush) {
    window.xtremepush("set", "user_id", userInfo.email);
  }
  iOSXPSetHandler("user_id", userInfo.email);

  /** Detect if it is native Android */
  let isAndroidNativeApp = null;
  if (
    document.referrer &&
    document.referrer.includes("android-app://com.givex.cws5") &&
    deviceWidth <= 660
  ) {
    isAndroidNativeApp = true;
  } else {
    isAndroidNativeApp = false;
  }

  /** Detect if it is native ios */
  let isIOSNativeApp = null;
  var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (!standalone && !safari && ios && deviceWidth <= 660) {
    isIOSNativeApp = true;
  } else {
    isIOSNativeApp = false;
  }

  const userInformation = {
    userInfo: userInfo,
    userAgent: window.navigator.userAgent,
    deviceOS: getDeviceOSVersion(isIOSNativeApp, isAndroidNativeApp),
    deviceType: !!isIOSNativeApp ? "iOS" : getDeviceOSVersion() !== "" ? "Android" : "Web",
    user_id: userInfo.email,
    deviceInfo: getDeviceInfo(),
  };

  if (window.xtremepush) {
    window.xtremepush("event", "login", userInformation);
  }
  iOSXPEventHandler("login", userInformation);
};

/**
 * Send Reward Click information to XP
 * Triggered when user clicks on a reward
 * @param {Object} reward
 * @param {String} skin
 * @returns
 */
export const onRewardClickXtreme = async (reward, skin) => {
  await getUserEmail(skin).then((userEmail) => {
    const rewardClickData = {
      reward: reward,
      deviceInfo: getDeviceInfo(),
      user_id: userEmail,
    };

    if (window.xtremepush) {
      window.xtremepush("event", "rewardClick", rewardClickData);
    }
    iOSXPEventHandler("rewardClick", rewardClickData);
  })
};

/**
 * Send Reward Redeem information to XP
 * Triggered when user redeems a reward
 * @param {Array} rewards
 * @param {String} skin
 * @returns
 */
export const onRewardRedeemXtreme = async (rewards, skin) => {
  if (!window.xtremepush) {
    return;
  }
  await getUserEmail(skin, true).then((userEmail) => {
    const rewardRedeemData = {
      rewards: rewards,
      deviceInfo: getDeviceInfo(),
      user_id: userEmail,
    };

    if (window.xtremepush) {
      window.xtremepush("event", "rewardRedeem", rewardRedeemData);
    }
    iOSXPEventHandler("rewardRedeem", rewardRedeemData);
  })
};

/**
 * Sends suggested item data to XP
 * Triggered when user clicks on a suggested item and adds it to the cart
 * @param {Object} item
 * @param {String} category
 * @param {String} subcategory
 * @param {String} skin
 * @returns
 */
export const onAddSuggestedItemXtreme = async (item, category, subcategory, skin) => {
  if (item === null || !item) {
    return;
  }
  await getUserEmail(skin).then((userEmail) => {
    const addSuggestedItemData = {
      items: [
        {
          name: item.name,
          id: item.id,
          price: item.price,
          category: category,
          subcategory: subcategory,
        },
      ],
      deviceInfo: getDeviceInfo(),
      user_id: userEmail,
    };

    if (window.xtremepush && item !== null) {
      window.xtremepush("tag", "lastItemAdded", item.name);
      //** On suggested item click
      window.xtremepush("event", "addSuggestedItem", addSuggestedItemData);
    }
    iOSXPTagHandler("lastItemAdded", item.name);
    iOSXPEventHandler("addSuggestedItem", addSuggestedItemData);
  })
};

/**
 * Sends impression data to XP
 * Triggered when user views a list of items
 * @param {Array} listOfItems list of item being viewed
 * @param {String} category A string representing the category id of the items.
 * @param {String} subcategory A string representing a the subcategory id of the items.
 */
export const onImpressionXtreme = async (listOfItems, category, subcategory, skin) => {
  let impressions = [];
  listOfItems.forEach((item, index) => {
    impressions.push({
      name: item.name,
      id: item.id,
      price: item.price,
      position: index + 1,
      category: category,
      subcategory: subcategory,
    });
  });
  await getUserEmail(skin).then((userEmail) => {
    const impressionData = {
      items: impressions,
      deviceInfo: getDeviceInfo(),
      user_id: userEmail,
    };

    if (window.xtremepush && impressions.length > 0) {
      window.xtremepush("event", "impression", impressionData);
    }
    iOSXPEventHandler("impression", impressionData);
  })
};

/**
 * Sends product click data to XP
 * Triggered when user clicks on a product
 * @param {Object} item An object representing a product.
 * @param {String} category A string representing the category id of the item.
 * @param {String} subcategory A string representing a the subcategory id of the item.
 */
export const onProductClickXtreme = async (item, category, subcategory, skin) => {
  await getUserEmail(skin).then((userEmail) => {
    const productClickData = {
      items: [
        {
          name: item.name,
          id: item.id,
          price: item.price,
          category: category,
          subcategory: subcategory,
        },
      ],
      deviceInfo: getDeviceInfo(),
      user_id: userEmail,
    };

    //** On Product click
    if (window.xtremepush) {
      window.xtremepush("event", "productClick", productClickData);
    }
    iOSXPEventHandler("productClick", productClickData);
  })
};

/**
 * Sends add to cart data to XP
 * Triggered when user adds an item to the cart
 * @param {Object} item
 * @param {String} category
 * @param {String} subcategory
 * @param {String} skin
 * @returns
 */
export const onAddProductToCartXtreme = async (item, category, subcategory, skin) => {
  if (item === null || !item) {
    return;
  }

  let products = [];

  let formattedItem = {
    name: item.name,
    id: item.id,
    price: item.price,
    category: item.category || category,
    subcategory: item.subcategory || subcategory,
    quantity: item.quantity,
  };

  if (!!item.combo_child_items && item.combo_child_items.length > 0) {
    formattedItem.combo_child_items = [];
    item.combo_child_items.forEach((childItem) => {
      formattedItem.combo_child_items.push({
        name: childItem.name,
        id: childItem.id,
        price: childItem.price,
        category: childItem.category,
        subcategory: childItem.subcategory,
        quantity: childItem.quantity,
      });
    });
  }

  products.push(formattedItem);
  await getUserEmail(skin).then((userEmail) => {
    const addToCartData = {
      items: products,
      deviceInfo: getDeviceInfo(),
      user_id: userEmail,
    };

    if (window.xtremepush) {
      window.xtremepush("tag", "lastItemAdded", item.name);
      window.xtremepush("event", "addToCart", addToCartData);
    }
    iOSXPTagHandler("lastItemAdded", item.name);
    iOSXPEventHandler("addToCart", addToCartData);
  })

};

/**
 * Sends remove item data from cart to XP
 * Triggered when user removes an item from the cart
 * @param {Object} item
 * @param {String} category
 * @param {String} subcategory
 * @param {Boolean} isMultipleItems
 * @param {String} skin
 * @returns
 */
export const onRemoveProductFromCartXtreme = async (
  item,
  category,
  subcategory,
  isMultipleItems,
  skin
) => {
  let productList = [];

  if (item) {
    if (isMultipleItems) {
      item.forEach((tempItem) => {
        productList.push({
          name: tempItem.name,
          id: tempItem.id,
          price: tempItem.price,
          category: tempItem.category,
          subcategory: tempItem.subcategory,
          quantity: tempItem.quantity || 1,
        });
      });
    } else {
      productList.push({
        name: item.name,
        id: item.id,
        price: item.price,
        category: category,
        subcategory: subcategory,
        quantity: item.quantity || 1,
      });
    }

    await getUserEmail(skin).then((userEmail) => {
      const removeFromCartData = {
        items: productList,
        deviceInfo: getDeviceInfo(),
        user_id: userEmail,
      };

      if (window.xtremepush) {
        window.xtremepush("event", "removeFromCart", removeFromCartData);
      }
      iOSXPEventHandler("removeFromCart", removeFromCartData);
    })
  }
};

/**
 * Sends Checkout Information to XP
 * Triggered during stages of the checkout process
 * @param {Arrays} items
 * @param {Number} stepNumber
 * @param {String} paymentType
 * @param {String} skin
 * @returns
 */
export const onCheckoutXtreme = async (items, stepNumber, paymentType = "", skin) => {
  if (items === null || !items) {
    return;
  }
  let products = [];

  items.forEach((item) => {
    let formattedItem = {
      name: item.name,
      id: item.id,
      price: item.price,
      category: item.category,
      subcategory: item.subcategory,
      quantity: item.quantity,
    };

    if (!!item.combo_child_items && item.combo_child_items.length > 0) {
      formattedItem.combo_child_items = [];
      item.combo_child_items.forEach((childItem) => {
        formattedItem.combo_child_items.push({
          name: childItem.name,
          id: childItem.id,
          price: childItem.price,
          category: childItem.category,
          subcategory: childItem.subcategory,
          quantity: childItem.quantity,
        });
      });
    }

    products.push(formattedItem);
  });

  let eventName = "";
  let checkoutNote = "";
  let preserveCustomerInfo = false
  switch (stepNumber) {
    case 1:
      eventName = "cartReviewOrder";
      checkoutNote = "user landed on review order screen";
      break;
    case 2:
      eventName = "cartCheckout";
      checkoutNote = "user clicked on checkout button";
      break;
    case 3:
      eventName = "cartCustomerInformation";
      checkoutNote = "user landed on customer information form";
      break;
    case 4:
      eventName = "cartPaymentMethodScreen";
      checkoutNote = "user landed on payment methods screen";
      preserveCustomerInfo = true;
      break;
    case 5:
      eventName = "cartPaymentMethodSelected";
      checkoutNote = "user selected payment method";
      preserveCustomerInfo = true;
      break;
    default:
      eventName = "";
      break;
  }

  if (eventName !== "") {
    await getUserEmail(skin, preserveCustomerInfo).then((userEmail) => {
      const eventData = {
        checkout: {
          checkoutStep: stepNumber,
          checkoutNote: checkoutNote,
          paymentMethod: paymentType,
        },
        items: products,
        deviceInfo: getDeviceInfo(),
        user_id: userEmail,
      };

      if (window.xtremepush) {
        window.xtremepush("event", eventName, eventData);
      }
      iOSXPEventHandler(eventName, eventData);
    })

  }
};

/**
 * Send transaction data to XP
 * Triggered when the purchase is completed and user lands on order confirmation page
 * @param {Array} cartItems list of purchased items
 * @param {Object} orderSummary details of the order (total, tax etc)
 * @param {Object} orderStore details of the selected store
 */
export const onOrderSubmittedXtreme = async (
  orderSummary,
  orderStore,
  onlineOrderId,
  purchaseAdditionalInfo,
  orderType,
  paymentMethod = "",
  skin
) => {
  await getUserEmail(skin, true).then((userEmail) => {
    const orderSubmittedData = {
      onlineOrderId: onlineOrderId, // Transaction ID. Required for purchases and refunds.
      storeName: orderStore.name,
      orderType: orderType,
      storeId: orderStore.storeId,
      paymentMethod: paymentMethod,
      orderTotal: orderSummary.I3, // Total transaction value (incl. tax and shipping)
      tax: orderSummary.I4,
      tip: purchaseAdditionalInfo.tip,
      coupon: purchaseAdditionalInfo.coupon,
      discountCode: purchaseAdditionalInfo.promoCode,
      deviceInfo: getDeviceInfo(),
      user_id: userEmail
    };

    if (window.xtremepush) {
      window.xtremepush("event", "orderSubmitted", orderSubmittedData);
    }
    iOSXPEventHandler("orderSubmitted", orderSubmittedData);
  })

};

import React from "react";

class IconNavigation extends React.Component {
  render() {
    return (
      <svg
        className="icon--navigation"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="3 11 22 2 13 21 11 13 3 11" />
      </svg>
    );
  }
}

export default IconNavigation;

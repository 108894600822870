// Libraries
import React, { useEffect, useContext, useState } from "react";
import localforage from "localforage";
import AppLabelsContext from "../App/AppLabelsContext";
import AppSettingsContext from "../App/AppSettingsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";

import GCCartContext from "./GCCartContext";
import { getMarquiGiftCardThankYouImage } from "../OnlineOrdering/Payment/OrderConfirmation/getMarquiThankYouImage";
import { IconCheck } from "../_common/icons";
import { AdditionalDisclaimer } from "../_common/AdditionalDisclaimer";
import { Link } from "react-router-dom";
import { formatDateObject, formatTimeForConfirmationPage } from "./giftCardHelpers";
import useWindowSize from "../_common/hooks/useWindowSize";
import IconThumbsUp from '../_common/icons/IconThumbsUp';
import ManifestContext from '../App/ManifestContext';
import UserRoleContext from "../App/UserRoleContext";
import IconThumbsDown from '../_common/icons/IconThumbsDown';
import { Redirect } from 'react-router-dom';
import "../OnlineOrdering/Payment/OrderConfirmation/OrderConfirmation.css";

export const GCOrderConfirmation = () => {

  const isLocalhost = process.env.NODE_ENV === "development";
  const IS_GIVEX_HOST = !isLocalhost;
  const PATHNAME = window.location.pathname;
  const IS_DEFAULT_LANGUAGE = IS_GIVEX_HOST
    ? PATHNAME.split("/")[3] === ""
    : PATHNAME.split("/")[1] === "";

  const appLabels = useContext(AppLabelsContext);
  const merchantConfig = useContext(MerchantConfigContext);
  const skin = merchantConfig.skin;
  const cartContext = useContext(GCCartContext)
  const deviceWidth = useWindowSize().width
  const appSettings = useContext(AppSettingsContext)
  const manifestContext = useContext(ManifestContext)
  const userStatus = useContext(UserRoleContext).status;
  const isTimePickerEnabled = merchantConfig.merchant.I57 !== "" ? true : false

  const [customerInfo, setCustomerInfo] = useState(null);
  const [cartDataCopy, setCartDataCopy] = useState()

  const [completedOrderId, setCompletedOrderId] = useState(null);
  const [orderQuantity, setOrderQuantity] = useState(null);

  const relativePath = useContext(AppSettingsContext)["relative-path"];
  const [thankYouImage, setThankYouImage] = useState(null);
  const [isIOSNativeApp, setIsIOSNativeApp] = useState(null);
  const [isAndroidNativeApp, setIsAndroidNativeApp] = useState(null);
  const [showConfirmationPage, setShowConfirmationPage] = useState(null)

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setThankYouImage(require("../_common/Images/order-confirmation-image.jpg")); // localhost fallback
    } else {
      getMarquiGiftCardThankYouImage(relativePath).then((marquiImages) => {
        if (
          marquiImages &&
          marquiImages["order-confirmation"] &&
          marquiImages["order-confirmation"]["gift-card-thank-you-image"] !==
          "files/Images/gift-cards/" &&
          !marquiImages["order-confirmation"]["gift-card-thank-you-image"].includes("_blank.")
        ) {
          if (IS_DEFAULT_LANGUAGE) {
            setThankYouImage(marquiImages["order-confirmation"]["gift-card-thank-you-image"]);
          } else {
            setThankYouImage("../" + marquiImages["order-confirmation"]["gift-card-thank-you-image"]);
          }
        } else {
          setThankYouImage(false)
        }
      });
    }
  }, []);

  useEffect(() => {
    if (cartContext.value && cartContext.value.length > 0) {
      setOrderQuantity(cartContext.value.length);


      setCartDataCopy(cartContext)
      localforage.getItem(skin + "__gcFinalOrderTotal").then((storedFinalOrderTotal) => {
        if (storedFinalOrderTotal) {
          localforage.removeItem(skin + "__gcFinalOrderTotal")
        }
      });
      localforage.getItem(skin + "__gcCompletedOrderId").then((storedOrderId) => {
        if (storedOrderId) {
          setCompletedOrderId(storedOrderId);
          localforage.removeItem(skin + "__gcOrderSummary")
          localforage.removeItem(skin + "__gcStoredPromoCode")
          localforage.removeItem(skin + "__gcStoredPromoDetails")
          cartContext.updateGCCart(null)
          if (storedOrderId !== "") {
            setShowConfirmationPage(true)
          } else {
            setShowConfirmationPage(false)
          }
          localforage.removeItem(skin + "__gcCompletedOrderId")
        }
        else {
          setShowConfirmationPage(false)

        }
      });

    } else {
      setShowConfirmationPage(false)
    }

  }, []);



  useEffect(() => {
    localforage.getItem(skin + "__gcCustomerInfo").then((customerInfo) => {
      setCustomerInfo(customerInfo);
    });

  }, []);

  useEffect(() => {
    /** Detect if it is native ios */
    var standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    if (!standalone && !safari && ios && deviceWidth <= 660) {
      //show the ios Disclaimer
      setIsIOSNativeApp(true);
    } else {
      //for debugging set to true
      setIsIOSNativeApp(false);
    }
  }, [deviceWidth]);

  useEffect(() => {
    /** Detect if it is native Android app */
    if (
      document.referrer &&
      document.referrer.includes("android-app://com.givex.cws5") &&
      deviceWidth <= 660
    ) {
      setIsAndroidNativeApp(true);
    } else {
      setIsAndroidNativeApp(false);
    }
  }, [deviceWidth]);

  const getDeviceOSVersion = () => {
    if (isIOSNativeApp && navigator.userAgent.match(/iPhone OS \d+(_\d+)*(_\d+)*\s+/)) {
      return navigator.userAgent.match(/iPhone OS \d+(_\d+)*(_\d+)*\s+/)[0].replace(/_/g, ".");
    } else if (isAndroidNativeApp && navigator.userAgent.match(/Android (\d+(?:\.\d+)*)/)) {
      return navigator.userAgent.match(/Android (\d+(?:\.\d+)*)/)[0];
    } else {
      return "";
    }
  };

  return (
    <>
      {completedOrderId != null &&
        orderQuantity != null &&
        thankYouImage != null
        && (
        <div className={`order-confirmation-container bottom-padding ${!thankYouImage ? "gcOrderConfirmPadding" : ""} `}>
            {thankYouImage && (
              <div
                className="order-confirmation__image giftCardOrderConfirmationImage"
                style={
                  {}
                }
              >
                <img
                  src={thankYouImage}
                  alt=""
                aria-hidden="true"
                />
              </div>
            )}
            <div className="order-confirmation__thankyou-note">
            <div className="order-confirmation__checkmark gcOrderConfirmationCheckmark">
                <IconCheck />
              </div>
              <h2 className="order-confirmation__heading">
                {appLabels["order"]["order-thank-you-message"]}
              </h2>
            </div>
            <div className="order-confirmation__order-id">
              {" "}
              {appLabels["order"]["your-order-number-is"]} <span>{completedOrderId}. {appLabels["order"]["confirmation-email-sent"]}</span>


          </div>
          <div className="giftCardDetailsConfirmedMessageListContainer">
            <ul className="giftCardDetailsConfirmedMessageList">
            {cartDataCopy.value.map((i) =>
            (<li>
              {isTimePickerEnabled ?
                <p key={i.id}>${i.amount} {appLabels["gift-card"]["for-delivery-on"]} <b>{formatDateObject(i.deliveryDateTime, true).date}</b> {appLabels["gift-card"]["at"]} <b>{formatTimeForConfirmationPage(i.deliveryDateTime, true).time}</b> {appLabels["gift-card"]["to"]} {i.recipientName}  </p>
                :
                <p key={i.id}>${i.amount} {appLabels["gift-card"]["for-delivery-on"]} <b>{formatDateObject(i.deliveryDateTime, true).date}</b> {appLabels["gift-card"]["to"]} {i.recipientName}  </p>
              }
            </li>
            )
            )}
            </ul>
          </div>
            <div
              className={`order-confirmation__rewards-bottom button-container--single-bottom order-confirmation__rewards-bottom__bottom-margin `}
            >
              <Link to="/dashboard" className="button  button--primary">
                <span>{appLabels["order"]["back-to-home"]}</span>
              </Link>
            </div>

            {((isIOSNativeApp && appSettings["ios-app-link"] !== "") ||
              (isAndroidNativeApp && appSettings["android-app-link"] !== "")) && (
                <div className="order-confirmation__ratings-main-container">
                  <h4 className="order-confirmation__ratings-header">
                    {appLabels["order"]["app-rating-header"]}
                  </h4>
                  <div className="order-confirmation__ratings-button-container">
                    <a
                      href={
                        isIOSNativeApp ? appSettings["ios-app-link"] : appSettings["android-app-link"]
                      }
                      aria-label={appLabels["order"]["app-rating-thumbs-up"]}
                    >
                      <IconThumbsUp />
                    </a>
                    <a
                      href={`mailto:${appSettings["merchant-email"]}?subject=${encodeURIComponent(
                        appLabels["order"]["merchant-email-subject"].replace(
                          "[merchant-name]",
                          manifestContext.name
                        )
                      )}&cc=android@givex.com&body=${encodeURIComponent(
                        `${appLabels["order"]["merchant-email-body-template-line-1"]}\n\n${appLabels["order"]["merchant-email-description-of-issue"]
                        }\n\n${appLabels["order"]["merchant-email-enter-details"]}\n\n\n${appLabels["order"]["merchant-email-steps-taken"]
                        }\n\n${appLabels["order"]["merchant-email-enter-details"]
                        }\n\n\n--------------------\n${appLabels["order"]["merchant-email-do-not-remove"]
                        }\n${appLabels["order"]["merchant-email-information-used-to-help"]}\n${appLabels["order"]["merchant-email-username"]
                        }: ${userStatus === "logged-in"
                          ? customerInfo.email
                          : appLabels["order"]["merchant-email-guest-user"] +
                          "(" +
                          `${customerInfo && customerInfo.email ? customerInfo.email : ""}` +
                          ")"
                        }\n${appLabels["order"]["merchant-email-device-type"]}: ${isIOSNativeApp ? "iPhone" : "Android"
                        }\n${appLabels["order"]["merchant-email-os-version"]
                        }: ${getDeviceOSVersion()}\n--------------------\n
                    `
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={appLabels["order"]["app-rating-thumbs-down"]}
                    >
                      <IconThumbsDown />
                    </a>
                  </div>
                </div>
              )}

            <AdditionalDisclaimer
              disclaimer={appLabels["order"]["order-confirmation-disclaimer"]}
            />
          </div>
        )}
      {(orderQuantity != null && orderQuantity === 0) && <Redirect to="/dashboard" />}
      {showConfirmationPage === false && <Redirect to="/dashboard" />}

    </>
  )
}
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
/** Contexts */
import AppLabelsContext from "../../App/AppLabelsContext";
import BrandingContext from "../../App/BrandingContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";

/** Helper functions */
import { getContrastColor } from "../../_common/colorHelpers";

/** UI Component */
import { ReactComponent as IconUser } from "../../_common/icons/IconUser.svg";

/** CSS */
import "./IndividualLoginButton.css";

const IndividualLoginButton = (props) => {
  const { floatRight, noRightPadding, customStyle } = props;
  const history = useHistory();
  const appLabels = useContext(AppLabelsContext);
  const branding = useContext(BrandingContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;

  const redirectToLoginPage = () => {
    if (document.getElementsByClassName("customer-info__modal").length > 0) {
      sessionStorage.setItem(skin + "__openCustomerInfo", true);
    }
    sessionStorage.setItem(skin + "__lastVisitedLink", window.location.hash.split("#")[1]);
    history.push("/login-register");
  };

  const redirectToRegisterPage = () => {
    if (document.getElementsByClassName("customer-info__modal").length > 0) {
      sessionStorage.setItem(skin + "__openCustomerInfo", true);
    }
    sessionStorage.setItem(skin + "__lastVisitedLink", window.location.hash.split("#")[1]);
    history.push("/register");
  };

  return (
    <div
      className={`individual-login-button-container ${floatRight ? " float-right" : ""} ${
        noRightPadding ? " no-right-padding" : ""
      }`}
      style={customStyle ? customStyle : {}}
    >
      <button
        className="individual-login-button"
        aria-label={appLabels["login-register"]["sign-in"]}
        onClick={redirectToLoginPage}
      >
        <IconUser
          aria-hidden="true"
          style={{ color: getContrastColor(branding["secondary-colour"]) }}
        />
        {appLabels["login-register"]["sign-in"]}
      </button>

      <button
        className="individual-register-button"
        aria-label={appLabels["login-register"]["register"]}
        onClick={redirectToRegisterPage}
      >
        {appLabels["login-register"]["register"]}
      </button>
    </div>
  );
};

export default IndividualLoginButton;

import { useContext, useEffect, useState } from "react";
import localforage from "localforage";
import MerchantConfigContext from "./MerchantConfigContext";

export const useOrderType = () => {
  const { skin, vexilor } = useContext(MerchantConfigContext);
  const merchantLevelOrderTypes = vexilor.I2.active_order_types.split(",");

  const [orderType, setOrderType] = useState({ value: "", update: updateOrderType });

  // Set the order type based on its stored value or the first merchant-level order type
  useEffect(() => {
    const setInitialOrderType = async () => {
      const storedOrderType = await localforage.getItem(skin + "__activeOrderType");

      if (
        storedOrderType &&
        merchantLevelOrderTypes.find(
          (merchantLevelOrderType) => merchantLevelOrderType === storedOrderType
        )
      ) {
        updateOrderType(storedOrderType);
      } else {
        updateOrderType("");
      }
    };

    setInitialOrderType();
  }, []);

  function updateOrderType(newOrderType) {
    setOrderType({ ...orderType, value: newOrderType });

    // if new Order type is null or empty, remove the stored value
    if (!newOrderType) {
      localforage.removeItem(skin + "__activeOrderType", newOrderType);
    } else {
      localforage.setItem(skin + "__activeOrderType", newOrderType);
    }
  }

  return { orderType: orderType.value, updateOrderType: orderType.update };
};

import React, { useContext, useState } from "react";

/** Contexts */
import AppLabelsContext from "../../App/AppLabelsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import AppSettingsContext from "../../App/AppSettingsContext";

/** UI Components */
import CollapsableSection from "../../_common/components/CollapsableSection";
import { Payment } from "./Payment";

/** Helpers */
import { toDollars } from "../../_common/helpers";

const PaymentCollapsable = (props) => {
  const { orderTotal, orderSummaryData, isOnBillPaymentPage } = props;
  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <CollapsableSection
        headerText={appLabels["order"]["select-payment"]}
        subHeaderText={` ${appLabels["general"]["total"] + ":"} ${toDollars(
          appSettings["currency-symbol"],
          appSettings["currency-symbol-side"],
          orderTotal,
          appLanguage
        )}`}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        id={"paymentCollapsable"}
      />
      <Payment
        isSectionCollapsed={isCollapsed}
        orderSummaryData={orderSummaryData}
        isOnBillPaymentPage={isOnBillPaymentPage}
      />
    </>
  );
};
export default PaymentCollapsable;

import React from "react";

export default (props) => (
  <svg
    className={`icon--cart ${props.className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.1 46.5"
  >
    <path d="M16.4 17c.1 1-1.4 1.2-1.5.2l-.9-6.6c-.9-6.7 9.3-7 10.2-.2l.9 6.5c.1 1-1.4 1.2-1.5.2l-.9-6.5c-.7-4.6-7.8-4.8-7.2-.2l.9 6.6zm11.9-4.5l1.8 23.2-18.4 5.4L4 36.4l1.9-24 5.9-.7 1.8.1.2 1.6-1.2-.1v25.9l15.8-4.6L26.8 14l-1.6-.1-.2-1.6 3.3.2zm-12-.6l5.9.3.2 1.6-5.9-.3-.2-1.6zm1.9-.5l.1-1c.2-1.2-.2-2.1-.8-2.7.7-.2 1.3-.2 2 0 .4.8.6 1.7.4 2.9l-.1.8h-1.6zM14 7.3c-1.3.5-2.5 1.6-2.7 3.4l-.1.5-1.6.2.1-.9c.5-3.6 3.5-5.2 6.2-4.9-.8.3-1.5.9-1.9 1.7zm-3 31.5V13.3l-3.7.4-1.7 21.9 5.4 3.2z" />
  </svg>
);

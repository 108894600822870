import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as IconPlus } from "../../_common/icons/IconPlus.svg";
import { ReactComponent as IconMinus } from "../../_common/icons/IconMinus.svg";
import { getItemQuantityFromCart } from "../../_common/CartHelpers";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import {
  onAddProductToCartXtreme,
  onRemoveProductFromCartXtreme,
} from "../../_common/helpers/xtremePushHelper";

export const CartItemQuantityButtons = (props) => {
  const {
    cartItem,
    updateItemQuantity,
    isQuantityButtonsDisabled,
    appLabels,
    cartData,
    comboCartItem,
    subGroupQuantityReached,
    isPerformingAction,
    setIsPerformingAction,
    setButtonTypePressed = null,
    buttonTypePressed = null,
  } = props;

  const { skin } = useContext(MerchantConfigContext);
  const cartItems = cartData.value || [];
  const [itemMaxQuantity, setItemMaxQuantity] = useState(null);
  // set the item quantity to the current quantity set on the cart item
  const itemQuantity = cartItem.item_count === "0" ? 0 : cartItem.quantity;
  const onlineQuantityLimit =
    !!cartItem["online_qty_limit"] && cartItem["online_qty_limit"] !== "None"
      ? cartItem["online_qty_limit"]
      : null;

  useEffect(() => {
    if (cartItem && itemMaxQuantity === null) {
      //set the max quantity value that can be selected based on the item_count
      if (cartItem.combo_child_items && !!cartItem.combo_child_items.length) {
        let maxQuantityPossible = null;
        cartItem.combo_child_items.forEach((comboChildItem) => {
          if (maxQuantityPossible === null) {
            if (comboChildItem.item_count !== "None") {
              maxQuantityPossible = parseInt(comboChildItem.item_count);
            }
          } else {
            if (
              comboChildItem.item_count !== "None" &&
              parseInt(comboChildItem.item_count) < maxQuantityPossible
            ) {
              maxQuantityPossible = parseInt(comboChildItem.item_count);
            }
          }
        });

        setItemMaxQuantity(!!maxQuantityPossible ? maxQuantityPossible : "");
      } else {
        if (onlineQuantityLimit) {
          setItemMaxQuantity(onlineQuantityLimit);
        }

        if (!onlineQuantityLimit) {
          setItemMaxQuantity(
            cartItem.item_count === "None"
              ? ""
              : !!cartItem.item_count
              ? parseInt(cartItem.item_count)
              : ""
          );
        } else {
          if (cartItem.item_count !== "None") {
            setItemMaxQuantity(!!cartItem.item_count ? parseInt(cartItem.item_count) : "");
          }
        }
      }
    }
  }, [cartItem, onlineQuantityLimit]);

  useEffect(() => {
    if (buttonTypePressed) {
      document.activeElement.blur();
      if(document.getElementById(buttonTypePressed)){
        document.getElementById(buttonTypePressed).focus();
      }
    }
  }, [buttonTypePressed]);

  return (
    <div className="cartItem__quantity-container">
      <div className="cartItem__quantity-buttons-container">
        <button
          id="buttonMinus_customization_dialog"
          data-quantity="minus"
          data-field="quantity"
          type="button"
          className="cartItem__quantity-button cartItem__quantity-button-minus"
          onClick={() => {
            if(setButtonTypePressed){
              setButtonTypePressed("buttonMinus_customization_dialog");
            }
            if (setIsPerformingAction) {
              setIsPerformingAction(true);
            }
            if (!!cartItem.group_id) {
              onRemoveProductFromCartXtreme(
                { ...comboCartItem, quantity: 1 },
                comboCartItem.category,
                comboCartItem.subcategory,
                false,
                skin
              );
              updateItemQuantity(
                false,
                cartItem,
                itemMaxQuantity,
                comboCartItem,
                false,
                !!cartItem["groupMinQty"] ? true : false //isQuantityCombo
              );
            } else {
              onRemoveProductFromCartXtreme(
                { ...cartItem, quantity: 1 },
                cartItem.category,
                cartItem.subcategory,
                false,
                skin
              );
              updateItemQuantity(false, cartItem, itemMaxQuantity, cartData);
            }
          }}
          disabled={
            cartItem.item_count === "0" ||
            itemQuantity <= 1 ||
            isQuantityButtonsDisabled ||
            isPerformingAction
          }
          aria-label={
            appLabels["order"]["decrease-quantity-by-one"] +
            " " +
            appLabels["general"]["for-text"] +
            " " +
            cartItem.name
          }
        >
          <IconMinus />
        </button>
        <input
          className="cartItem__quantity-input"
          readOnly
          type="number"
          name="quantity"
          value={itemQuantity}
          max={itemMaxQuantity}
          role="none"
          //aria-label={appLabels["order"]["item-quantity"] + " for " + cartItem.name + " is " + itemQuantity}
        />
        
        <button
          id="buttonPlus_customization_dialog"
          data-quantity="plus"
          data-field="quantity"
          type="button"
          className="cartItem__quantity-button cartItem__quantity-button-plus"
          onClick={() => {
            if (setButtonTypePressed) {
              setButtonTypePressed("buttonPlus_customization_dialog");
            }
            if (setIsPerformingAction) {
              setIsPerformingAction(true);
            }
            if (!!cartItem.group_id) {
              onAddProductToCartXtreme(
                { ...comboCartItem, quantity: 1 },
                comboCartItem.category,
                comboCartItem.subcategory,
                skin
              );
              updateItemQuantity(
                true,
                cartItem,
                itemMaxQuantity,
                comboCartItem,
                false,
                !!cartItem["groupMinQty"] ? true : false //isQuantityCombo
              );
            } else {
              onAddProductToCartXtreme(
                { ...cartItem, quantity: 1 },
                cartItem.category,
                cartItem.subcategory,
                skin
              );
              updateItemQuantity(true, cartItem, itemMaxQuantity, cartData);
            }
          }}
          disabled={
            isPerformingAction ||
            cartItem.item_count === "0" ||
            isQuantityButtonsDisabled ||
            cartItem.quantity >= cartItem.item_count ||
            (!!itemMaxQuantity && cartItem.quantity >= itemMaxQuantity) ||
            cartItems.filter((item) => item.id === cartItem.id).length >= cartItem.item_count ||
            (!!subGroupQuantityReached && subGroupQuantityReached(cartItem.group_id)) ||
            (!!onlineQuantityLimit &&
              getItemQuantityFromCart(cartItems, cartItem.id) >= onlineQuantityLimit)
          }
          aria-label={
            appLabels["order"]["increase-quantity-by-one"] +
            " " +
            appLabels["general"]["for-text"] +
            " " +
            cartItem.name
          }
        >
          <IconPlus />
        </button>
      </div>
      {onlineQuantityLimit && (
        <div
          className="cartItem__button--edit"
          role="contentinfo"
          aria-live={ window.location.href.includes("checkout") ? "off" : "polite"}
          aria-atomic="false"
        >
          <span className="visually-hidden" aria-atomic="true" aria-live={ window.location.href.includes("checkout") ? "off" : "polite"}>
            {cartItem.name} {appLabels["order"]["quantity-in-cart-is"]} {cartItem.quantity}.
          </span>
          <span className=" cartItem__button-text">
            {getItemQuantityFromCart(cartItems, cartItem.id) >= onlineQuantityLimit ? (
              <>{appLabels["order"]["limit-reached"]}</>
            ) : (
              <>
                {appLabels["order"]["limit-per-order"].replace("[qty-limit]", onlineQuantityLimit)}
              </>
            )}
          </span>
          <span className="visually-hidden" aria-atomic="true" aria-live={ window.location.href.includes("checkout") ? "off" : "polite"}>
            {appLabels["order"]["you-have-n-items-in-cart"].replace("[item-quantity]", cartData.quantity)}
          </span>
        </div>
      )}
    </div>
  );
};

import React, { useContext } from "react";

/** Contexts */
import AppLabelsContext from "../../../App/AppLabelsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import AppSettingsContext from "../../../App/AppSettingsContext";
import StoreContext from "../../StoreContext";
import UserRoleContext from "../../../App/UserRoleContext";

/** Helpers */
import { toDollars } from "../../../_common/helpers";

/** UI Components */
import IndividualLoginButton from "../../Cart/IndividualLoginButton";

const BillHeaderDetails = (props) => {
  const { orderSummary1322, onlyShowTableNumber, billOrderSummary } = props;

  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const storeContext = useContext(StoreContext);
  const userRoleContext = useContext(UserRoleContext);

  const activeTableNumber = storeContext.activeOrderStoreTable;

  return (
    <div className="bill__header-details">
      {userRoleContext.status === "guest" && (
        <IndividualLoginButton
          customStyle={{ padding: 0, justifyContent: "left", marginBottom: "20px" }}
          floatRight={false}
        />
      )}
      <div>
        <span className="bill__header-subtotal">
          {appLabels["order"]["table-number-field-label"]} {activeTableNumber}
          {!onlyShowTableNumber && (
            <>
              {" | "} {appLabels["general"]["subtotal"]}{" "}
              {toDollars(
                appSettings["currency-symbol"],
                appSettings["currency-symbol-side"],
                billOrderSummary
                  ? billOrderSummary["orderSubtotal"]
                  : orderSummary1322["order_subtotal"],
                appLanguage
              )}{" "}
            </>
          )}
        </span>
      </div>

      {orderSummary1322["vxl_order_id"] && orderSummary1322["vxl_order_id"] !== "None" && (
        <div>
          <span className="bill__header-order-number">
            {appLabels["general"]["order"]}# {orderSummary1322["vxl_order_id"]}
          </span>
        </div>
      )}
    </div>
  );
};
export default BillHeaderDetails;

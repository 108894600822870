import { useContext, useEffect, useState } from "react";

//Context
import CWS5ModuleContext from "../../App/CWS5ModuleContext";

export const useLoyaltySetup = () => {
  const cws5ModuleContext = useContext(CWS5ModuleContext);

  const [loyaltyContext, setLoyaltyContext] = useState("idle");

  useEffect(() => {
    const isLoyaltyEnabled = cws5ModuleContext.isLoyaltyEnabled;

    if (isLoyaltyEnabled) {
      const initialLoyaltyContext = { isEnabled: true };
      setLoyaltyContext(initialLoyaltyContext);
    } else {
      // loyalty module is disabled
      setLoyaltyContext(false);
    }
  }, []);

  return loyaltyContext;
};

import React, { useState, useEffect, useContext } from "react";
import localforage from "localforage";
import he from "he";

import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import UserRoleContext from "../../App/UserRoleContext";

import { ReactComponent as IconPaypal2 } from "../../_common/icons/IconPaypal2.svg";

import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { Form } from "../../_common/Form/Form";
import { callMarquiPaymentAPI } from "../../_common/Api";
import GCCartContext from '../GCCartContext';
import { checkDeliveryDateTime, nextDeliveryDateTime } from "../giftCardHelpers";

export const GCPaymentMethodPaypal = (props) => {
  const { paymentMethodExpanded, formattedCartItems, promoCode } = props;

  const merchantConfig = useContext(MerchantConfigContext);
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const userRoleContext = useContext(UserRoleContext);

  const skin = merchantConfig.skin;
  const loginToken = userRoleContext.loginToken;

  const [apiError, setAPIError] = useState("");
  const [isAPISubmitting, setIsAPISubmitting] = useState(null);
  const [isAPISubmitValid, setIsApiSubmitValid] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({});
  const [isPassedDeliveryDateTime, setIsPassedDeliveryDateTime] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [invalidDeliveryDateGiftCardIndexes, setInvalidDeliveryDateGiftCardIndexes] = useState()

  const cartContext = useContext(GCCartContext)

  useEffect(() => {
    if (appliedCoupon == null) {
      localforage.getItem(skin + "__gcCoupon").then((coupon) => {
        if (coupon) setAppliedCoupon(coupon);
      });
    }
  }, [appliedCoupon]);

  useEffect(() => {
    localforage.getItem(skin + "__gcCustomerInfo").then((customerInfo) => {
      if (customerInfo) setCustomerInfo(customerInfo);
      //else history.goBack();
    });

  }, []);

  // Scroll to the top of the expand/collapse toggle button after details panel is expanded
  useEffect(() => {
    const timer = setTimeout(() => paymentMethodExpanded(), 300);
    return () => clearTimeout(timer);
  }, []);

  const getCurrentTimestamp = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return timestamp;
  };

  const submitPaypalOrder = async () => {
    setIsAPISubmitting(true);

    const timestamp = getCurrentTimestamp();
    const placeOrderData = await callMarquiPaymentAPI(skin, "placeorder", {
      params: [
        skin, // merchant name
        "consumer",
        appLanguage, // language
        props.orderTotal, //amount
        timestamp, // tm session id
        "ECERT", // order type
        formattedCartItems, // shipping_order_ref or cart items formatted
        loginToken, //customer login
        "", // customer password
        promoCode || "", // promo offer code
        "", // scene card number
        "PAYPAL", // payment type
        "", // cc_id
        "", // cc_type, double check this with reular order placement
        "", // cc_number
        "", // cc_name
        "", // cc_security
        "", // cc_issue
        "", // cc_expiry_month
        "", // cc_expiry_year
        "", // cc_start_month
        "", // cc_start_year
        "", // cc_address1
        "", // cc_address2
        "", // cc_city
        "", // cc_province
        "", // cc_country
        "", // cc_postal
        customerInfo.email, // cc_email
        customerInfo.phone || "", // cc_phone
        "", // pa_res
        window.location.origin + window.location.pathname + "#/gift-card/payment/paypal", // return_url or success_url
        window.location.origin +
        window.location.pathname +
        "#/gift-card/payment/paypal?cancel=true", // cancel_url
        "f", // bulk ecert
        "", // pay_dolloar_success_url
        "", // pay_dolloar_error_url
        "", //pay_dollar_fail url
        "", // unique_pay_id
        "", // order_payment_ref
        "", // third_party_discount_val
        "", // customer_order_ref
        "", // wechat_oauth_id
        "", // use_message_for_all_cards
        "", // require_confirmation
        "", // pickup_template
        "", // payment_gift_card
        "", // url_3ds_success , this is one of the urls seen in the end of params in cws4 call
        "", // url_3ds_failure, this is one of the urls seen in the end of params in cws4 call
        "", //excel_formatted_file
        "", // add_card_to_customer _account
        "", // uptix_order
      ],
      id: "dc_placeorder",
    });

    if (placeOrderData) {
      //if no errors:
      if (placeOrderData.status === 0) {
        setIsApiSubmitValid(true);
        const completedOrderId = placeOrderData.result.I0;
        localforage.setItem(skin + "__gcCompletedOrderId", completedOrderId);
        localforage.setItem(skin + "__gcUsedPaymentMethod", "PAYPAL");
        window.location = placeOrderData.result.I3;
      } else {
        setIsApiSubmitValid(false);
        setIsAPISubmitting(null);

        if (
          placeOrderData.error &&
          placeOrderData.error.message.includes(
            "Customer ID can only be used when a customer is logged in"
          )
        ) {
          userRoleContext.handleLoginTokenExpiration();
        } else {
          setAPIError(
            `${appLabels["general"]["error-code"]}: ${placeOrderData.status}. ${he.decode(
              placeOrderData.result.message
            )}`
          );
        }
      }
    }
  };

  const onConfirmClick = () => {
    let { invalidItemIndexes, pastDeliveryDateTime } = checkDeliveryDateTime(cartContext.value, !props.isTimePickerEnabled)
    if (pastDeliveryDateTime) {
      setIsPassedDeliveryDateTime(true)
      setInvalidDeliveryDateGiftCardIndexes(invalidItemIndexes)
    } else {
      submitPaypalOrder();
    }
  };

  const declineTimeUpdate = () => {
    setIsPassedDeliveryDateTime(null);
    window.location.reload()
  };

  const updateDeliveryDateTime = () => {
    let updatedCartItems = []
    let cartItemsCopy = cartContext.value
    let nextAvailableTime = nextDeliveryDateTime(false, props.isTimePickerEnabled).timestamp

    for (let i = 0; i < invalidDeliveryDateGiftCardIndexes.length; i++) {
      let index = invalidDeliveryDateGiftCardIndexes[i]
      let itemCopy = cartContext.value[index]
      itemCopy.deliveryDateTime = nextAvailableTime.toString()
      updatedCartItems.push(itemCopy)
      cartItemsCopy[index] = itemCopy
    }

    cartContext.updateGCCart(cartItemsCopy)
    setIsPassedDeliveryDateTime(false)
    window.location.reload()
  }

  return (
    <>
      <div className="paypal-button__container">
        <p>{appLabels["order"]["complete-order-pay-with-paypal"]}</p>
        <IconPaypal2 className="icon icon--paypal2" />
      </div>

      <Form
        id="form--pay-with-paypal"
        isFAB={true}
        submitButtonText={appLabels["order"]["submit-order-button"]}
        submitButtonSuccessText={appLabels["order"]["submit-order-button-processing"]}
        submitForm={onConfirmClick}
        submitAPIError={apiError}
        resetAPIError={() => setAPIError("")}
        isAPISubmitValid={isAPISubmitValid}
        isAPISubmitting={isAPISubmitting}
        isAllowEmptySubmit={true}
        buttonWrapperClass={"form--credit-card__submit-button"}
      ></Form>


      {!!apiError && <DialogModal message={apiError} resetRemoveDialog={() => setAPIError(null)} />}

      {isAPISubmitting && <LoadingSpinner />}
      {isPassedDeliveryDateTime && (
        <DialogModal
          message={
            <div>
              <span><b>{appLabels["gift-card"]["invalid-delivery-date-time-gift-card-title-message"]}</b></span>
              <br /> <br />
              {appLabels["gift-card"]["invalid-delivery-date-time-gift-card-message"]}

              <br /> <br /> <span><b>{nextDeliveryDateTime(true, props.isTimePickerEnabled, appLabels["gift-card"]["at"]).displayValue}</b> </span>

            </div>
          }
          resetRemoveDialog={declineTimeUpdate}
          isCancelConfirm={true}
          confirmAction={() => updateDeliveryDateTime()}
          isHTMLContent={true}
        />
      )}
    </>
  );
};

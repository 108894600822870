import { callAPI } from "../../../../../_common/Api";

/** the login token parameter can also be user's email, however if email is provided password the user has to be provided as well
 * if login token is provided, password is not required
 */
export const getAPIFullCardNumber = async (skin, loginToken, giftCard, language, password = "") => {
  const api959Data = await callAPI(skin, "dc_959", {
    params: [
      language,
      "959",
      "mqid",
      "mqpass",
      loginToken,
      password,
      giftCard.iso + "-" + giftCard.serial,
      "",
    ],
    id: "959",
  });

  if (api959Data.result.I0) {
    if (api959Data.status === 0) {
      // Successfully retrieved full card number (givex number)
      const givexNumber = api959Data.result.I2;
      return givexNumber;
    } else if (api959Data.status === 249 || api959Data.status === 150) {
      return { status: "expiredLoginToken" };
    }
  } else {
    if (api959Data.status === 249 || api959Data.status === 150) {
      return { status: "expiredLoginToken" };
    } else {
      // Failed to retrieve full card number (givex number)
      return api959Data.result;
    }
  }
};

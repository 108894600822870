import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as IconArrowBack } from "../_common/icons/IconArrowBack.svg";
import MerchantConfigContext from "../App/MerchantConfigContext";

export const BackButton = (props) => {
  const { destination, appLabels } = props;
  const history = useHistory();
  const { skin } = useContext(MerchantConfigContext);

  const handleGoBack = () => {
    if (destination) {
      history.push({ pathname: destination, state: { from: "app-header-back-button" } });
    } else {
      if (window.location.href.includes("/rewards")) {
        sessionStorage.setItem(skin + "_isComingFromRewards", true);
      }
      history.goBack();
    }
  };

  return (
    <button
      className="button header__link--back"
      onClick={handleGoBack}
      aria-label={appLabels["general"]["go-back"]}
      type="button"
    >
      <IconArrowBack />
    </button>
  );
};

/*global google*/
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { OrderStoresSearchComponent } from "./OrderStoresSearchComponent";
import DeliveryAddressesComponent from "./DeliveryAddressesComponent";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";
import localforage from "localforage";
import MerchantConfigContext from "../App/MerchantConfigContext";
import useWindowSize from "../_common/hooks/useWindowSize";

// This component is responsible for handling and processing delivery addresses
const OrderDeliveryAddress = (props) => {
  // Destructuring properties from props
  const { isShowingGoogleMap, appLabels, searchboxInputRef } = props;

  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;

  // Getting the active order type from context
  const activeOrderType = useContext(OrderTypeContext).value;

  // State to manage if geolocation button is active
  const [geoLocationButtonIsActive, setGeoLocationButtonIsActive] = useState(null);

  // Reference to Google Maps autocomplete
  const autocompleteRef = useRef(null);

  // useHistory hook to access the history instance to navigate
  const history = useHistory();

  // Function to get the user's current geolocation
  const getUserGeolocation = () => {
    // Setting the geolocation button as active
    setGeoLocationButtonIsActive(true);

    // Checking if geolocation is available
    if (navigator && navigator.geolocation) {
      // Unfocus the active element (useful for buttons etc.)
      document.activeElement.blur();

      // Get the current position of the user
      navigator.geolocation.getCurrentPosition(
        // Success callback
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          // Set the geolocation button as inactive after retrieving coordinates
          setGeoLocationButtonIsActive(false);

          // Converting the coordinates into a LatLng object
          const storeCoordsObject = new google.maps.LatLng(lat, lng);

          // Reverse geocode the coordinates to get the address
          reverseGeoCode(storeCoordsObject).then((reverseGeoCodeResponse) => {
            if (reverseGeoCodeResponse) {
              const reverseGeoCodeComponents = reverseGeoCodeResponse["address_components"];
              const country = getAddressComponent(
                "country",
                reverseGeoCodeComponents,
                "short_name"
              );
              const postalCodeComponent = reverseGeoCodeComponents.filter(
                (component) =>
                  component["types"].includes("postal_code") &&
                  !component["types"].includes("postal_code_prefix")
              );
              const postalCode =
                postalCodeComponent.length > 0 ? postalCodeComponent[0]["long_name"] : "";

              // Get the formatted delivery address
              const userAddress = getDeliveryAddress(reverseGeoCodeComponents, country, postalCode);

              const userCoordinates = {
                lat: lat,
                lng: lng,
              };

              // Navigate to confirm address page with the obtained details
              history.push({
                pathname: "/online-ordering/confirm-address",
                state: {
                  from: "delivery-address-geocode",
                  userAddress: userAddress,
                  userCoordinates: userCoordinates,
                },
              });
            }
          });
        },

        // Error callback for geolocation
        function error() {
          setGeoLocationButtonIsActive(false);
          alert(appLabels["general"]["disabled-geolocation-alert"]);
          console.log(
            "GPS position feature was disabled. To enable again - click on the location icon in the top right corner of the browser within a search field, then clear these settings for future visits and reload."
          );
        },

        // Use high accuracy for geolocation
        { enableHighAccuracy: true }
      );
    }
  };

  // Function to format the delivery address
  const getDeliveryAddress = (addressComponent, country, postalCode) => {
    let hasLocalityComponent =
      addressComponent.filter((comp) => comp.types.includes("locality")).length > 0;

    const provinceComponent = getAddressComponent(
      "administrative_area_level_1",
      addressComponent,
      "short_name"
    );
    return {
      "first-address": `${getAddressComponent(
        "street_number",
        addressComponent,
        "long_name"
      )} ${getAddressComponent("route", addressComponent, "short_name")}`,
      city: getAddressComponent(
        country === "HK"
          ? "neighborhood"
          : country === "BR"
          ? "administrative_area_level_1"
          : hasLocalityComponent
          ? "locality"
          : "sublocality",
        addressComponent,
        "long_name"
      ),
      country: country,
      postal: postalCode,
      province:
        country === "BR"
          ? `${provinceComponent}:BR`
          : country === "MX"
          ? `${provinceComponent}:MX`
          : country === "CN"
          ? `${provinceComponent}:CN`
          : provinceComponent,
    };
  };

  // Function to reverse geocode using coordinates to get address details
  const reverseGeoCode = async (coordinates) => {
    // Initializing geocoder
    const geocoder = new google.maps.Geocoder();
    // Making the reverse geocoding request
    const geoCodeInfo = await geocoder.geocode({ location: coordinates });
    // Return the result if it exists, alert if not
    if (geoCodeInfo) {
      if (geoCodeInfo.results[0]) {
        return geoCodeInfo.results[0];
      } else {
        window.alert("No results found");
      }
    }
  };

  // Function to geocode a place ID to get the corresponding address details
  const geocodePlaceId = async (placeId) => {
    const geocoder = new google.maps.Geocoder();
    const geoCodeInfo = await geocoder.geocode({ placeId: placeId });
    if (geoCodeInfo) {
      if (geoCodeInfo.results[0]) {
        return geoCodeInfo.results[0];
      } else {
        window.alert("No results found");
      }
    }
  };

  // Function to get address components from user input
  const getUserInputAddressComponent = (input) => {
    return input.address_components;
  };

  // Function to get specific address components (e.g. country, street) from a list of components
  const getAddressComponent = (addressKey, components, nameFormat) => {
    const info = components.filter((component) => component["types"].includes(addressKey));
    return info.length > 0 ? info[0][nameFormat] : "";
  };

  // Function that handles when the user makes a selection from the search box
  const onSearchBoxPlacesChanged = async (prediction) => {
    await geocodePlaceId(prediction.place_id).then(async (places) => {
      if (places && Object.keys(places).length > 0) {
        const position = places.geometry.location;
        const lat = position.lat();
        const lng = position.lng();
        const storeCoordsObject = new google.maps.LatLng(lat, lng);
        const userInputAddressComponent = getUserInputAddressComponent(places);
        const reverseGeoCodeResponse = userInputAddressComponent
          ? userInputAddressComponent
          : await reverseGeoCode(storeCoordsObject);

        // Check if address components exist
        if (!userInputAddressComponent) {
          return;
        }

        if (reverseGeoCodeResponse) {
          const country = getAddressComponent("country", reverseGeoCodeResponse, "short_name");
          const postalCodeComponent = reverseGeoCodeResponse.filter(
            (component) =>
              component["types"].includes("postal_code") &&
              !component["types"].includes("postal_code_prefix")
          );
          const postalCode =
            postalCodeComponent.length > 0 ? postalCodeComponent[0]["long_name"] : "";

          const userAddress = getDeliveryAddress(userInputAddressComponent, country, postalCode);
          const userCoordinates = {
            lat: lat,
            lng: lng,
          };
          localforage.setItem(skin + "__deliveryAddressPrediction", prediction);
          history.push({
            pathname: "/online-ordering/confirm-address",
            state: {
              from: "delivery-address",
              userAddress: userAddress,
              userCoordinates: userCoordinates,
            },
          });
        }
      }
    });
  };

  useEffect(() => {
    localforage.getItem(skin + "__deliveryAddressPrediction").then((deliveryAddress) => {
      if (deliveryAddress) {
        onSearchBoxPlacesChanged(deliveryAddress);
      }
    });
  }, []);

  const deviceWidth = useWindowSize().width;

  // Rendering the OrderStoresSearchComponent and DeliveryAddressesComponent
  return (
    <>
      <OrderStoresSearchComponent
        onSearchBoxPlacesChanged={onSearchBoxPlacesChanged}
        searchboxInputRef={searchboxInputRef}
        autocompleteRef={autocompleteRef}
        getUserGeolocation={getUserGeolocation}
        isShowingGoogleMap={isShowingGoogleMap}
        geoLocationButtonIsActive={geoLocationButtonIsActive}
        orderType={activeOrderType}
      />
      {deviceWidth >= 660 && (
        <div className="desktop-container">
          <hr
            style={{
              backgroundColor: "var(--color-gray-light)",
              border: "none",
              height: "1px",
              marginBottom: "20px",
              marginTop: "0px",
            }}
          />
        </div>
      )}

      <DeliveryAddressesComponent />
    </>
  );
};

export default OrderDeliveryAddress;

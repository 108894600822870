import React, { useContext } from "react";
import { Route } from "react-router-dom";

import AppLabelsContext from "./AppLabelsContext";
import StaticPagesDataContextContext from "./contexts/StaticPagesDataContext";

import { PageComponentTemplate } from "../_common/components/PageComponentTemplate";
import { Terms } from "../Terms/Terms";
import { StaticPage } from "../_common/components/StaticPage";

export const StaticPageRoutes = () => {
  const appLabels = useContext(AppLabelsContext);
  const staticPagesData = useContext(StaticPagesDataContextContext);

  return (
    <>
      <Route
        path="/terms-and-conditions"
        render={() => (
          <PageComponentTemplate
            pageHeading={appLabels["general"]["terms-and-conditions"]}
            isBackButton={true}
            isHomeLink={true}
            mainClassOverride="terms-and-conditions-main-content"
            component={<Terms />}
          />
        )}
      />
      {staticPagesData &&
        staticPagesData.length > 0 &&
        staticPagesData.map((staticPageData, index) => (
          <React.Fragment key={index}>
            {staticPageData.isEnabled === "yes" && (
              <Route
                path={staticPageData.path}
                render={() => (
                  <PageComponentTemplate
                    pageHeading={staticPageData.pageHeading}
                    isBackButton={true}
                    isHomeLink={true}
                    component={
                      <StaticPage
                        pageHeading={staticPageData.pageHeading}
                        contentPath={staticPageData.contentPath}
                      />
                    }
                  />
                )}
              />
            )}
          </React.Fragment>
        ))}
    </>
  );
};

import React, { useContext, useState } from "react";

/** Contexts */
import AppLabelsContext from "../../App/AppLabelsContext";

/** UI Components */
import CollapsableSection from "../../_common/components/CollapsableSection";
import { CartSummary } from "./CartSummary";
import { Tip } from "./Tip/Tip";

const CartSummaryCollapsableSection = (props) => {
  const appLabels = useContext(AppLabelsContext);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const {
    items,
    isAnimateIcon,
    orderSummaryData,
    updateOrderSummary,
    currentLocation,
    storedPromoCode,
    setStoredPromoCode,
    coupon,
    setCoupon,
    storedRewards,
    setStoredRewards,
    setShowCheckoutButton,
    isTaxIncluded,
    setStoredRewardsDetails,
    storedRewardsDetails,
    setOrderSummaryData,
    skin,
    updateStoredTipAmount,
    isCalling1307,
    activeOrderType,
  } = props;

  const isOnBillPaymentPage = window.location.href.includes("bill-payment");

  const activeStoreConfig = currentLocation.vexilorConfig;
  const isTipEnabled =
    activeStoreConfig["tips_config"] &&
    activeStoreConfig["tips_config"]["tips_enabled"] === "t" &&
    activeStoreConfig["tips_config"]["tip_order_types"].includes(activeOrderType);

  return (
    <>
      <CollapsableSection
        headerText={appLabels["order"]["combo-summary"]}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        id={"cartSummaryCollapsable"}
      />

      <div
        className={`${
          isCollapsed
            ? " collapsable-section__collapsed-content"
            : " collapsable-section__expanded-content"
        }`}
      >
        <CartSummary
          items={items}
          isAnimateIcon={isAnimateIcon}
          orderSummaryData={orderSummaryData}
          updateOrderSummary={updateOrderSummary}
          currentLocation={currentLocation}
          storedPromoCode={storedPromoCode}
          setStoredPromoCode={setStoredPromoCode}
          coupon={coupon}
          setCoupon={setCoupon}
          storedRewards={storedRewards}
          setStoredRewards={setStoredRewards}
          setShowCheckoutButton={setShowCheckoutButton}
          isTaxIncluded={isTaxIncluded}
          setStoredRewardsDetails={setStoredRewardsDetails}
          storedRewardsDetails={storedRewardsDetails}
          setOrderSummaryData={setOrderSummaryData}
          activeOrderType={activeOrderType}
        />

        {isTipEnabled && (!isCalling1307 || isOnBillPaymentPage) && (
          <Tip
            orderSummaryData={orderSummaryData}
            skin={skin}
            updateStoredTipAmount={updateStoredTipAmount}
          />
        )}
      </div>
    </>
  );
};
export const CartSummaryCollapsable = React.memo(CartSummaryCollapsableSection);

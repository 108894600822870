// Libraries
import React, { useEffect, useContext, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useWindowSize from "../../../_common/hooks/useWindowSize";
import localforage from "localforage";

// Helper functions
import { arrayToObject, jsonCopy, isArrItems, getArray } from "../../../_common/helpers";
import { dynamicSort, getItemQuantityFromCart } from "../../../_common/CartHelpers";
import { getContrastColor } from "../../../_common/colorHelpers";
import { isValidItemQuantity } from "../../../_common/MenuHelpers";
import { isFavouriteItem } from "../../Favorites/favoriteHelpers";
import {
  onAddProductToCartXtreme,
  onProductClickXtreme,
  onRemoveProductFromCartXtreme,
} from "../../../_common/helpers/xtremePushHelper";

// Contexts
import { MenuContext } from "../MenuContext";
import MerchantConfigContext from "../../../App/MerchantConfigContext";
import StoreContext from "../../StoreContext";
import CartContext from "../../Cart/CartContext";
import BrandingContext from "../../../App/BrandingContext";
import AppLabelsContext from "../../../App/AppLabelsContext";
import OrderTypeContext from "../../OrderTypeContext";
import UserRoleContext from "../../../App/UserRoleContext";

/** UI components */
import { LoadingSpinner } from "../../../_common/LoadingSpinner";
import { MenuComboSummary } from "./MenuComboSummary";
import { SpecialInstructions } from "../MenuItemDetail/SpecialInstructions";
import { CaloriesDisclaimer } from "../CaloriesDisclaimer";
import { FAB } from "../../../_common/buttons/FAB/FAB";
import { MenuComboItems } from "./MenuComboItems";
import { QuantityButtons } from "../MenuItemDetail/QuantityButtons";
import { DialogModal } from "../../../_common/DialogModal/DialogModal";
import { OrderSettingsCart } from "../../../OrderSettings/OrderSettingsCart";
import XButton from "../../../_common/buttons/XButton";
import MenuComboProgressBar from "./MenuComboProgressBar";
import { MenuComboItemDetail } from "./MenuComboItemDetail";
import ComboCategoryFilterButtons from "../ComboCategoryFilterButtons";
import { HeroSection } from "../../../_common/DesktopLayout/HeroSection";
import { ButtonAddToFavorites } from "../../../_common/buttons/ButtonAddToFavorites/ButtonAddToFavorites";

// Assets
//import { ReactComponent as IconX } from "../../../_common/icons/IconX.svg";

/** CSS */
import "../MenuSlider/MenuSlider.css";
import "../MenuSlider/MenuSliderItem.css";
import "./MenuComboSliderItem.css";
import "./MenuComboDetail.css";

export const MenuComboDetail = (props) => {
  const {
    displayType,
    cartItem,
    comboChildImage,
    backButtonDestination,
    updateActiveItem,
    currentComboChildItem,
    showingComboChildItem,
    setShowingComboChildItem,
    isEditingComboItem,
    setIsEditingComboItem,
  } = props;

  const history = useHistory();
  const { category, subcategory, combo, item } = useParams();
  const { pathname, state } = useLocation();
  const deviceWidth = useWindowSize().width;

  const { skin } = useContext(MerchantConfigContext);
  const branding = useContext(BrandingContext);
  const activeOrderType = useContext(OrderTypeContext).value;
  const { activeOrderStore } = useContext(StoreContext);
  const orderMenu = useContext(MenuContext);
  const cart = useContext(CartContext);
  const userRoleContext = useContext(UserRoleContext);
  const isLoggedIn = userRoleContext.status === "logged-in";

  const [menuItem, setMenuItem] = useState(null);
  const [isAtLeastOneCalorie, setIsAtLeastOneCalorie] = useState(false);
  const [comboSubitems, setComboSubitems] = useState(null);
  const [comboSubgroupNumber, setComboSubgroupNumber] = useState(0);
  const [currentSubgroupNumber, setCurrentSubgroupNumber] = useState(null);
  const [currentSubgroupItems, setCurrentSubgroupItems] = useState([]);
  //const [closeModalClass, setCloseModalClass] = useState("");
  const [callingApi, setCallingApi] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [areOptionsValid, setAreOptionsValid] = useState(null);
  const [isQuantityValid, setIsQuantityValid] = useState(null);
  const [addedToOrder, setAddedToOrder] = useState(false);
  const [isGuestFavoriteAttempt, setIsGuestFavoriteAttempt] = useState(null);
  const [comboCartItem, setComboCartItem] = useState({
    id: orderMenu.activeItem,
    item_sku_code: "",
    addon_list: [],
    modifier_list: [],
    combo_child_items: [],
    updateComboCartItem: (newComboItem) => {
      setComboCartItem(newComboItem);
    },
  });
  const [isQuantityCombo, setIsQuantityCombo] = useState(false);
  const [onlineQuantityLimit, setOnlineQuantityLimit] = useState(null);

  const isSpecialInstructionEnabled = activeOrderStore.vexilorConfig.cooking_instruction === "t";
  const isThreeLevelMenu = orderMenu.menuLevel === "threeLevels";
  const isTwoLevelMenu = orderMenu.menuLevel === "twoLevels";
  const isItemOutOfStock = menuItem ? Number(menuItem.item_count) <= 0 : false;
  const isMenuDisplay = displayType === "menu";
  const isModifyDisplay = displayType === "cart-modify";
  const textIconColor = getContrastColor(branding["online-order-settings-background-colour"]);
  const itemLevelBanner = branding["online-ordering-item-page"];

  const [formattedComboCartItem, setFormattedComboCartItem] = useState(null);

  useEffect(() => {
    if (isMenuDisplay) {
      orderMenu.updateMenu({
        ...orderMenu,
        activeTopCategory: category,
        activeSubcategory: subcategory,
        activeItem: combo,
      });
    }
  }, [category, subcategory, combo]);

  useEffect(() => {
    return () => {
      if (isMenuDisplay) {
        localforage.removeItem(skin + "__activeItemInCustomizationModel");
        window.location.href = window.location.href.replace("?customization-modal", "");
      }
    };
  }, []);

  const subGroupQuantityReached = (groupId) => {
    if (
      groupId === null ||
      comboCartItem.combo_child_items.length === 0 ||
      comboCartItem.combo_child_items.filter((item) => Number(item.group_id) === Number(groupId))
        .length === 0
    ) {
      return false;
    }

    let numberOfSelectedItems = 0;
    comboCartItem.combo_child_items.forEach((childItem) => {
      if (Number(childItem.group_id) === Number(groupId)) {
        if (childItem.quantity) {
          numberOfSelectedItems = numberOfSelectedItems + childItem.quantity;
        } else {
          numberOfSelectedItems++;
        }
      }
    });

    if (isQuantityCombo) {
      return !!comboCartItem.combo_child_items.filter(
        (item) => Number(item.group_id) === Number(groupId)
      ).length
        ? Number(numberOfSelectedItems) >=
            Number(
              comboCartItem.combo_child_items.filter(
                (item) => Number(item.group_id) === Number(groupId)
              )[0]["groupMinQty"]
            )
        : false;
    } else {
      return numberOfSelectedItems >= 1;
    }
  };

  const [validateGoingToNextLevel, setValidateGoingToNextLevel] = useState(false);
  useEffect(() => {
    if (comboSubgroupNumber > 0 || validateGoingToNextLevel) {
      let largestGroupId = 0;

      comboCartItem.combo_child_items.forEach((item) => {
        if (item.group_id > largestGroupId) {
          largestGroupId = parseInt(item.group_id);
        }
      });

      if (largestGroupId > 0) {
        if (largestGroupId === comboSubgroupNumber && !isEditingComboItem) {
          if (isQuantityCombo) {
            if (subGroupQuantityReached(currentSubgroupNumber)) {
              let allGroupsAreCompleted = true;
              let inCompleteGroupNumber = 0;
              for (let i = 1; i <= comboSubgroupNumber; i++) {
                if (!subGroupQuantityReached(i)) {
                  allGroupsAreCompleted = false;
                  inCompleteGroupNumber = i;
                  break;
                }
              }

              /** Show the combo summary only if all subgroups are satisfied, otherwise go to the next incomplete subgroup */
              if (allGroupsAreCompleted) {
                setShowSummary(true);
              } else {
                setCurrentSubgroupNumber(inCompleteGroupNumber);
              }
            }
          } else {
            let allGroupsAreCompleted = true;
            let inCompleteGroupNumber = 0;
            for (let i = 0; i < comboSubgroupNumber; i++) {
              if (!groupIsComplete(i + 1)) {
                allGroupsAreCompleted = false;
                inCompleteGroupNumber = i + 1;
                break;
              }
            }
            //if all sub groups have a selected item, show the summary
            if (allGroupsAreCompleted) {
              setShowSummary(true);
            } else {
              setCurrentSubgroupNumber(inCompleteGroupNumber);
            }
          }
        } else {
          let inCompleteGroupNumber = 0;
          for (let i = 1; i <= comboSubgroupNumber; i++) {
            if (!subGroupQuantityReached(i)) {
              inCompleteGroupNumber = i;
              break;
            }
          }
          if (isQuantityCombo) {
            if (subGroupQuantityReached(currentSubgroupNumber)) {
              localforage.getItem(skin + "__activeItemInCustomizationModel").then((response) => {
                if (!response) {
                  setCurrentSubgroupNumber(inCompleteGroupNumber);
                }
                //remove the key from localforage which auto triggers the customization popup
                setTimeout(() => {
                  localforage.removeItem(skin + "__activeItemInCustomizationModel");
                  window.location.href = window.location.href.replace("?customization-modal", "");
                }, 1000);
              });
            }
          } else {
            // if there is an incomplete group, go to that group
            if (inCompleteGroupNumber !== 0) {
              setCurrentSubgroupNumber(inCompleteGroupNumber);
            } else if (groupIsComplete(currentSubgroupNumber)) {
              setCurrentSubgroupNumber(
                largestGroupId + 1 > comboSubgroupNumber ? largestGroupId : largestGroupId + 1
              );
            }
          }
        }
      }

      const activeElement = document.getElementsByClassName("is-active is-not-complete")[0];
      const summaryIsActive = document.getElementsByClassName("is-active is-summary")[0];

      if (activeElement) {
        const bounding = activeElement.getBoundingClientRect();
        //if element is not in the view port, scroll to it
        if (
          !(
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          )
        ) {
          setTimeout(() => {
            document.getElementsByClassName("progress-bar")[0].scrollLeft =
              activeElement.offsetLeft;
          }, 5);
        }
      } else if (summaryIsActive) {
        //if summary is active, scrool to summary
        //summaryIsActive.scrollIntoView({ behavior: "smooth", block: "end" });
      }
      setValidateGoingToNextLevel(false);
    }
  }, [comboCartItem, validateGoingToNextLevel]);

  useEffect(() => {
    if (orderMenu && isMenuDisplay) {
      /** Update the food menu object and set its activeItem */
      if (orderMenu.activeItem !== combo) {
        orderMenu.updateMenu({
          ...orderMenu,
          activeTopCategory: category,
          activeSubcategory: subcategory,
          activeItem: combo,
        });
      }

      if (orderMenu.activeComboSubItem && orderMenu.activeComboSubItem.id !== item) {
        orderMenu.updateMenu({
          ...orderMenu,
          activeTopCategory: category,
          activeSubcategory: subcategory,
          activeItem: combo,
          activeComboSubItem: getComboSubgroupItem(item),
        });
      }

      /** Get the item information from orderMenu Object */
      if (orderMenu.apiData && (orderMenu.activeSubcategory || orderMenu.activeTopCategory)) {
        const menu = orderMenu.apiData;
        let subcategoryData;

        if (isThreeLevelMenu) {
          const categoryData = menu["category-" + orderMenu.activeTopCategory];
          if (!categoryData) {
            //no category data
            return;
          }
          subcategoryData =
            categoryData.subcategories["subcategory-" + orderMenu.activeSubcategory];
        } else if (isTwoLevelMenu) {
          if (!orderMenu.activeSubcategory) {
            //no subcategory data
            return;
          }
          subcategoryData = orderMenu.apiData["subcategory-" + orderMenu.activeSubcategory];
        }

        const menuItemData = subcategoryData.items["item-" + combo];
        const addonGroupsData = getArray(menuItemData, "addonGroups");

        if (isArrItems(addonGroupsData)) {
          addonGroupsData.forEach(([key]) => {
            menuItemData.addonGroups[key].type = "addonGroup";
          });
        }

        const modifierGroupsData = getArray(menuItemData, "modifierGroups");

        if (isArrItems(modifierGroupsData)) {
          modifierGroupsData.forEach(([key]) => {
            menuItemData.modifierGroups[key].type = "modifierGroup";
          });
        }

        setMenuItem(menuItemData);
      }
    }
  }, [orderMenu]);

  useEffect(() => {
    if (menuItem && !callingApi && currentSubgroupNumber === null) {
      setCallingApi(true);
      const menuLevel = orderMenu.menuLevel;
      const topCategoryIdFromURL =
        pathname.split("/category/")[1] && pathname.split("/category/")[1].split("/")[0];
      const subcategoryIdFromURL =
        pathname.split("/subcategory/")[1] && pathname.split("/subcategory/")[1].split("/")[0];

      let comboData = [];
      if (menuLevel === "twoLevels") {
        comboData =
          orderMenu.apiData["subcategory-" + subcategoryIdFromURL].items["item-" + menuItem.id]
            .combo_child_items;
      } else if (menuLevel === "threeLevels") {
        comboData =
          orderMenu.apiData["category-" + topCategoryIdFromURL].subcategories[
            "subcategory-" + subcategoryIdFromURL
          ].items["item-" + menuItem.id].combo_child_items;
      }
      const items = comboData;

      setIsQuantityCombo(!!items[0].groupMinQty);
      const formattedItems = formatItems(items);
      setComboSubitems(formattedItems);

      let tempIsAtLeastOneCalorie = false;

      for (let key in formattedItems) {
        if (formattedItems[key].calorie_count !== "") {
          tempIsAtLeastOneCalorie = true;
          break;
        }
      }
      setIsAtLeastOneCalorie(tempIsAtLeastOneCalorie);

      const numberOfSubgroups = parseInt(items[items.length - 1].group_id);
      setComboSubgroupNumber(numberOfSubgroups);

      setCurrentSubgroupNumber(1);

      setCallingApi(false);
    }
  }, [menuItem]);

  useEffect(() => {
    if (currentSubgroupNumber) {
      /** When setting subgroup items , reset the items first with an empty object
       * to prevent issues for combos which have the same subitems in the subgroups
       */
      let tempSubgroupItems = getSubgroupItems(currentSubgroupNumber);
      setCurrentSubgroupItems({});
      setTimeout(() => {
        setCurrentSubgroupItems(tempSubgroupItems);
      }, 200);
    }
  }, [currentSubgroupNumber]);

  // Make sure to grab the current quantity from cart's item instance if it's being modified
  const usedOriginalCartItemQuantity = useRef(false);
  useEffect(() => {
    if (
      cartItem &&
      menuItem &&
      cartItem.id === menuItem.id &&
      usedOriginalCartItemQuantity.current === false
    ) {
      usedOriginalCartItemQuantity.current = true;
      setComboQuantity(cartItem.quantity);
    }
  }, [cartItem, menuItem]);

  useEffect(() => {
    if (!isQuantityValid && isQuantityValid != null) {
      setTimeout(() => {
        setIsQuantityValid(null);
      }, 2000);
    }
  }, [isQuantityValid]);

  /*
  const closeItemDetail = () => {
    if (isMenuDisplay) {
      setCloseModalClass(" menu-combo-detail--closing");

      setTimeout(() => {
        let closeDestination = "";

        if (state && state.from === "suggestedItems") {
          history.push({
            pathname: `/online-ordering/${
              activeOrderType === "dinein" ? "review-order" : "checkout"
            }`,
            state: "from-item-page",
          });
        } else if (isThreeLevelMenu) {
          closeDestination = `/online-ordering/menu/category/${orderMenu.activeTopCategory}/subcategory/${orderMenu.activeSubcategory}`;
        } else {
          closeDestination = `/online-ordering/menu/subcategory/${orderMenu.activeSubcategory}`;
        }
        history.push({
          pathname: closeDestination,
          state: "from-item-page",
        });
      }, 300);
    } else if (isModifyDisplay) {
      setShowEditItemModal(false);
    }
  };
  */

  const getSubgroupItems = (groupNumber) => {
    let subgroupItems = [];

    if (comboSubitems) {
      comboSubitems.forEach((item) => {
        if (item.group_id === groupNumber.toString()) {
          subgroupItems.push(item);
        }
      });

      const itemsObject = arrayToObject(subgroupItems, "id", "item");

      /**If comboCartItem contains a combo_child_items object for the same group number
       * Replace that item inside the itemsObject
       */
      if (
        comboCartItem.combo_child_items.length > 0 &&
        !!comboCartItem.combo_child_items[groupNumber - 1]
      ) {
        Object.keys(itemsObject).forEach((itemKey) => {
          if (
            itemsObject[itemKey].id === comboCartItem.combo_child_items[groupNumber - 1].id &&
            comboCartItem.combo_child_items[groupNumber - 1].group_id === groupNumber
          ) {
            itemsObject[itemKey] = comboCartItem.combo_child_items[groupNumber - 1];
            itemsObject[itemKey].imageUrl = comboSubitems.find(
              (item) =>
                item.id === itemsObject[itemKey].id &&
                item.group_id === itemsObject[itemKey].group_id
            ).imageUrl;
          }
        });
      }
      return itemsObject;
    }
  };

  const updateCurrentComboSubgroupNumber = (groupNumber, element) => {
    if (
      element === null ||
      element.target.parentElement.className.includes("is-complete") ||
      element.target.parentElement.className.includes("is-active")
    ) {
      setShowSummary(false);
      setCurrentSubgroupNumber(groupNumber);
    }
  };

  const getComboSubgroupItem = (itemId) => {
    let subItem = null;

    if (Object.entries(currentSubgroupItems).length > 0) {
      Object.entries(currentSubgroupItems).forEach((item) => {
        if (item[1].id === itemId) {
          subItem = item[1];
        }
      });
    }
    return subItem;
  };

  // Format Food Menu items into objects
  const formatItems = (apiItems) => {
    const formattedItemsArray = [...apiItems];

    formattedItemsArray.map((apiItem) => {
      // Convert addons into objects
      if (!Array.isArray(apiItem.addonGroups)) {
        apiItem.addonGroups = Object.values(apiItem.addonGroups);
        apiItem.addonGroups.forEach((addonGroup) => {
          addonGroup.items = Object.values(addonGroup.items);
        });
      }
      apiItem.addonGroups.map((addonGroup) => {
        addonGroup.items.map((addon) => {
          //convert addon modifiers to objects
          if (addon.modifierGroups && addon.modifierGroups.length > 0) {
            addon.modifierGroups.map((addonMod) => {
              addonMod.items = arrayToObject(addonMod.items, "id", "modifier");
              return addonMod;
            });
            addon.modifierGroups = arrayToObject(addon.modifierGroups, "id", "addonModifierGroup");
          }
          return addon;
        });

        addonGroup.items = arrayToObject(addonGroup.items, "id", "addon");
        return addonGroup;
      });

      apiItem.addonGroups = arrayToObject(apiItem.addonGroups, "id", "addonGroup");

      // Convert modifiers into objects
      if (!Array.isArray(apiItem.modifierGroups)) {
        apiItem.modifierGroups = Object.values(apiItem.modifierGroups);
        apiItem.modifierGroups.forEach((modifierGroup) => {
          modifierGroup.items = Object.values(modifierGroup.items);
        });
      }
      apiItem.modifierGroups.map((modifierGroup) => {
        modifierGroup.items = arrayToObject(modifierGroup.items, "id", "modifier");
        return modifierGroup;
      });

      apiItem.modifierGroups = arrayToObject(apiItem.modifierGroups, "id", "modifierGroup");
      return apiItem;
    });

    return formattedItemsArray;
  };
  // END OF Format Food Menu items into objects

  const groupIsComplete = (index) => {
    let groupIsComplete = false;

    if (isQuantityCombo) {
      if (subGroupQuantityReached(index)) {
        groupIsComplete = true;
      }
    } else {
      if (comboCartItem.combo_child_items.length > 0) {
        comboCartItem.combo_child_items.forEach((item) => {
          if (item.group_id === index.toString()) {
            groupIsComplete = true;
          }
        });
      }
    }

    return groupIsComplete;
  };

  const updateSpecialInstructions = (instructions) => {
    setSpecialInstructions(instructions);
  };

  const addToOrder = () => {
    if (!isValidItemQuantity(cart.value || [], { ...menuItem, quantity: comboQuantity })) {
      setIsQuantityValid(false);
      return;
    }

    //const cartItem = { ...comboCartItem };
    const cartItem = { ...formattedComboCartItem };
    cartItem.id = orderMenu.activeItem;
    cartItem.specialInstructions = specialInstructions;
    cartItem.category = orderMenu.activeTopCategory;
    cartItem.subcategory = orderMenu.activeSubcategory;
    cartItem.quantity = comboQuantity;
    cartItem.entryKey = Date.now();
    cartItem.price = menuItem.price;
    cartItem.name = menuItem.name;
    cartItem.imageUrl = menuItem.imageUrl;
    cartItem.item_count = menuItem.item_count;
    cartItem.online_qty_limit = menuItem.online_qty_limit;
    delete cartItem.updateComboCartItem;

    /** Check if the same item with the same selection exist in the cart
     * by creating copies of the cart and the item being added
     * and removing unnecessary keys
     * and then comparing them as strings
     */

    const cartCopy = jsonCopy(cart.value || []);
    const cartItemCopy = jsonCopy(cartItem);

    delete cartItemCopy.entryKey;
    delete cartItemCopy.quantity;

    cartItemCopy.combo_child_items.forEach((comboChildItem) => {
      delete comboChildItem.entryKey;
    });

    let matchFound = false;
    let matchIndex = 0;

    cartCopy.forEach((cartCopyItem, index) => {
      let storedQuantity = cartCopyItem.quantity;
      delete cartCopyItem.entryKey;
      delete cartCopyItem.quantity;
      delete cartCopyItem.total_addon_discount;

      if (cartCopyItem.combo_child_items) {
        cartCopyItem.combo_child_items.forEach((comboChildItem) => {
          delete comboChildItem.entryKey;
        });
      }

      if (JSON.stringify(cartCopyItem) === JSON.stringify(cartItemCopy)) {
        matchFound = true;
        matchIndex = index;
        cartItem.quantity = comboQuantity + storedQuantity;
        cartItem.entryKey = Date.now();
      }
    });

    const newCart = cart.value ? [...cart.value] : [];

    if (matchFound) {
      newCart[matchIndex] = cartItem;
    } else {
      newCart.push(cartItem);
    }

    onAddProductToCartXtreme(cartItem, category, subcategory, skin);
    cart.updateCart(newCart);

    setAddedToOrder(true);
    setAreOptionsValid(true);

    localforage.removeItem(skin + "__itemSelectionInProgress");
    sessionStorage.removeItem(skin + "__orderStoreState");
    if (state && state.from === "suggestedItems") {
      setTimeout(() => {
        history.push({
          pathname: `/online-ordering/${
            activeOrderType === "dinein" ? "review-order" : "checkout"
          }`,
          state: "from-item-page",
        });
      }, 1000);
    } else {
      setTimeout(() => {
        setAreOptionsValid(null);

        let closeDestination = "";
        if (orderMenu.menuLevel === "threeLevels") {
          closeDestination = `/online-ordering/menu/category/${orderMenu.activeTopCategory}/subcategory/${orderMenu.activeSubcategory}`;
        } else {
          closeDestination = `/online-ordering/menu/subcategory/${orderMenu.activeSubcategory}`;
        }

        history.push({
          pathname: closeDestination,
          state: "from-item-page",
        });
      }, 1000);
    }
  };

  const confirmChanges = async () => {
    const newCart = cart.value ? [...cart.value] : [];
    let removableIndex = null;

    let tempComboCartItem = jsonCopy(comboCartItem);
    tempComboCartItem.entryKey = cartItem.entryKey;
    delete tempComboCartItem.updateComboCartItem;

    if (!isValidItemQuantity(newCart, { ...tempComboCartItem, quantity: comboQuantity }, true)) {
      setIsQuantityValid(false);
      return;
    }

    const cartItemCopy = jsonCopy(tempComboCartItem);

    removableIndex = newCart.findIndex((element) => element.entryKey === cartItemCopy.entryKey);

    let originalQuntity = 1;
    if (removableIndex !== null) {
      if (removableIndex > -1 && newCart[removableIndex]) {
        originalQuntity = newCart[removableIndex].quantity || 1;
      }
      newCart.splice(removableIndex, 1);
    }

    cartItemCopy.quantity = comboQuantity;
    cartItemCopy.specialInstructions = specialInstructions;
    cartItemCopy.combo_child_items.sort(dynamicSort("group_id"));
    cartItemCopy.entryKey = Date.now();
    cartItemCopy.id = menuItem.id;
    newCart.push(cartItemCopy);

    const newQuantity = cartItemCopy.quantity;
    // If the quantity of the item has changed, call the xtremePush functions
    if (originalQuntity < newQuantity) {
      onAddProductToCartXtreme(
        { ...cartItemCopy, quantity: newQuantity - originalQuntity },
        cartItemCopy.category,
        cartItemCopy.subcategory,
        skin
      );
    } else if (originalQuntity > newQuantity) {
      onRemoveProductFromCartXtreme(
        { ...cartItemCopy, quantity: originalQuntity - newQuantity },
        cartItemCopy.category,
        cartItemCopy.subcategory,
        false,
        skin
      );
    }

    cart.updateCart(newCart);
    if (!!state && !!state.from && state.from === "customization-modal") {
      await localforage.setItem(skin + "__activeItemInCustomizationModel", menuItem.id);
    }

    localforage.removeItem(skin + "__itemSelectionInProgress");
    sessionStorage.removeItem(skin + "__orderStoreState");
    if (!!state && !!state.from && state.from === "customization-modal") {
      history.goBack();
    } else {
      if (activeOrderType === "dinein") {
        history.push("/online-ordering/review-order");
      } else {
        history.push("/online-ordering/checkout");
      }
    }
  };

  if (isModifyDisplay && !menuItem && cartItem) {
    setMenuItem(jsonCopy(cartItem));

    setSpecialInstructions(cartItem.specialInstructions);
    const newCartItem = { ...cartItem };
    newCartItem["updateComboCartItem"] = (newComboItem) => setComboCartItem(newComboItem);
    comboCartItem.updateComboCartItem(newCartItem);

    setShowSummary(true);
  }

  const appLabels = useContext(AppLabelsContext);

  const [comboQuantity, setComboQuantity] = useState(1);

  const updateComboQuantity = (item) => {
    setComboQuantity(item.quantity);
  };

  const formatComboForFavorites = () => {
    const cartItem = { ...comboCartItem };
    cartItem.id = menuItem.id;
    cartItem.specialInstructions = specialInstructions;
    cartItem.category = orderMenu.activeTopCategory;
    cartItem.subcategory = orderMenu.activeSubcategory;
    cartItem.quantity = comboQuantity;
    cartItem.entryKey = Date.now();
    cartItem.price = menuItem.price;
    cartItem.name = menuItem.name;
    cartItem.imageUrl = menuItem.imageUrl;
    cartItem.type = "comboItem";
    delete cartItem.updateComboCartItem;
    return cartItem;
  };

  const redirectToItemPageFromSummary = (comboItem) => {
    setShowSummary(false);
    setCurrentSubgroupNumber(comboItem.group_id);
    updateActiveItem(
      comboItem.id,
      comboItem.parentId,
      comboItem.type,
      true,
      comboItem,
      isModifyDisplay,
      !isModifyDisplay ? true : false
    );
  };

  /**
   * Store the item selection in progress in localforage
   */
  const storeItemSelectionInProgress = () => {
    const comboCartItemCopy = jsonCopy(comboCartItem);
    comboCartItemCopy.id = menuItem ? menuItem.id : "";
    comboCartItemCopy.quantity = !!document.getElementsByClassName("item-detail__quantity-input")
      .length
      ? parseInt(document.getElementsByClassName("item-detail__quantity-input").quantity.value)
      : 1;
    delete comboCartItemCopy.updateComboCartItem;
    localforage.setItem(skin + "__itemSelectionInProgress", JSON.stringify(comboCartItemCopy));
  };

  /** This useEffect will check the localforage when user lands here for any previously combo selection that was not still added to cart,
   * if so it will update the comboCartItem object from the data in the localforage
   */
  useEffect(() => {
    if (comboSubgroupNumber) {
      localforage.getItem(skin + "__itemSelectionInProgress").then((comboInMemory) => {
        if (comboInMemory) {
          const comboInMemoryObject = jsonCopy(JSON.parse(comboInMemory));
          /** only consider the combo selection from localforage if its id matches the current active item
           * otherwise remove the data from localforage
           */
          if (comboInMemoryObject.id === menuItem.id) {
            comboInMemoryObject.id = "";
            comboInMemoryObject.updateComboCartItem = (newComboItem) => {
              setComboCartItem(newComboItem);
            };
            comboCartItem.updateComboCartItem(comboInMemoryObject);
            setComboQuantity(comboInMemoryObject.quantity);
            setShowSummary(true);
            if (isLoggedIn) {
              setTimeout(() => {
                const favButton = document.getElementById("combo-favorite-button");
                if (!!favButton) {
                  //Trigger the click for the fav button only if the item is not favourited
                  const allFavItems = orderMenu.favorites;
                  const itemIsAlreadyAFavorite = isFavouriteItem(allFavItems, comboInMemoryObject);
                  if (!itemIsAlreadyAFavorite) {
                    favButton.click();
                  }
                }
              }, 1000);
            }
          } else {
            localforage.removeItem(skin + "__itemSelectionInProgress");
            sessionStorage.removeItem(skin + "__orderStoreState");
          }
        }
      });
    }
  }, [comboSubgroupNumber]);

  useEffect(() => {
    if (menuItem) {
      setOnlineQuantityLimit(
        !!menuItem["online_qty_limit"] && menuItem["online_qty_limit"] !== "None"
          ? menuItem["online_qty_limit"]
          : null
      );
    }
  }, [menuItem]);

  // if user lands on this page by clicking the slides from dashabord, trigger the onProductClickXtreme to XP
  const triggeredXPEvent = useRef(false);
  useEffect(() => {
    if (
      menuItem &&
      history.location.state &&
      history.location.state.from === "dashboard-card-slider" &&
      triggeredXPEvent.current === false
    ) {
      triggeredXPEvent.current = true;
      onProductClickXtreme(menuItem, category, subcategory, skin);
    }
  }, [menuItem]);

  return (
    <>
      {deviceWidth >= 660 && !showingComboChildItem && (
        <div>
          <HeroSection imageUrl={itemLevelBanner} />
        </div>
      )}
      <div
        className={`menu-combo-detail ${showingComboChildItem ? " menu-item-detail-wizard" : ""}`}
      >
        {!!menuItem && (
          <>
            {showingComboChildItem ? (
              <MenuComboItemDetail
                comboChildImage={currentComboChildItem}
                displayType={displayType}
                menuLevel={orderMenu.menuLevel}
                isInCart={displayType === "cart-modify" ? true : false}
                cartItem={cartItem}
                comboParentItem={menuItem}
                setShowingComboChildItem={setShowingComboChildItem}
                comboCartItem={comboCartItem}
                item={currentComboChildItem}
                currentSubgroupNumber={currentComboChildItem["group_id"]}
                setCurrentSubgroupNumber={setCurrentSubgroupNumber}
                isQuantityCombo={isQuantityCombo}
                isEditingComboItem={isEditingComboItem}
                setShowSummary={setShowSummary}
                state={state}
                subGroupQuantityReached={subGroupQuantityReached}
                comboSubgroupNumber={comboSubgroupNumber}
                groupIsComplete={groupIsComplete}
              />
            ) : (
              <>
                {deviceWidth < 660 && (
                  <header className="header curved-shadow-container main-combo-header">
                    <div className="curved-shadow-overlay-hack" />
                    <div className={`header-primary`} style={{ zIndex: "unset" }}>
                      <XButton
                        destination={backButtonDestination}
                        appLabels={appLabels}
                        skin={skin}
                      />
                      <h1 className="page-title">
                        {showSummary ? appLabels["order"]["combo-summary-header"] : menuItem.name}
                      </h1>
                      {showSummary ? (
                        <ButtonAddToFavorites
                          item={formatComboForFavorites()}
                          category={orderMenu.activeTopCategory}
                          subcategory={orderMenu.activeSubcategory}
                          customClassName={
                            showSummary ? "" : "item-details__transition-container__fav-button"
                          }
                          setIsGuestFavoriteAttempt={setIsGuestFavoriteAttempt}
                          showingComboSummary={showSummary}
                          comboCartItem={comboCartItem}
                          buttonId={"combo-favorite-button"}
                        />
                      ) : (
                        <div style={{ width: "40px", height: "40px" }}></div>
                      )}
                    </div>
                  </header>
                )}

                {deviceWidth >= 660 && !showingComboChildItem && (
                  <div className="combo-category-filters__wrapper">
                    <ComboCategoryFilterButtons />
                  </div>
                )}

                {!!comboSubgroupNumber && comboSubgroupNumber > 0 && !!currentSubgroupNumber ? (
                  <MenuComboProgressBar
                    comboSubgroupNumber={comboSubgroupNumber}
                    updateCurrentComboSubgroupNumber={updateCurrentComboSubgroupNumber}
                    appLabels={appLabels}
                    setShowSummary={setShowSummary}
                    comboCartItem={comboCartItem}
                    groupIsComplete={groupIsComplete}
                    showSummary={showSummary}
                    currentSubgroupNumber={currentSubgroupNumber}
                    isQuantityCombo={isQuantityCombo}
                  />
                ) : (
                  <>
                    <div className="menu-combo-loader-overlay"></div>
                    <LoadingSpinner />
                  </>
                )}

                {showSummary ? (
                    <div className="menu-combo-summary-wrapper"  >
                      <span className="visually-hidden" aria-live="assertive" aria-atomic="false">{appLabels["order"]["on-combo-summary-page"]}</span>
                      <div className="menu-combo-summary">
                      <MenuComboSummary
                        menuItem={menuItem}
                        comboCartItem={comboCartItem}
                        isQuantityCombo={isQuantityCombo}
                        foodMenu={orderMenu}
                        redirectToItemPageFromSummary={redirectToItemPageFromSummary}
                        favItem={formatComboForFavorites()}
                        orderMenu={orderMenu}
                        setIsGuestFavoriteAttempt={setIsGuestFavoriteAttempt}
                        updateCurrentComboSubgroupNumber={updateCurrentComboSubgroupNumber}
                        comboSubgroupNumber={comboSubgroupNumber}
                        isModifyDisplay={isModifyDisplay}
                        formattedComboCartItem={formattedComboCartItem}
                        setFormattedComboCartItem={setFormattedComboCartItem}
                      />
                    </div>

                    <QuantityButtons
                      menuItem={menuItem}
                      cartItem={comboCartItem}
                      isMenuDisplay={isMenuDisplay}
                      isModifyDisplay={isModifyDisplay}
                      updateMenuItem={updateComboQuantity}
                      cartData={cart}
                      isEditingComboItem={isEditingComboItem}
                      isComboSummary={true}
                      comboQuantity={comboQuantity}
                      comboCartItem={comboCartItem}
                    />
                    {isSpecialInstructionEnabled && (
                      <SpecialInstructions
                        specialInstructions={specialInstructions}
                        updateSpecialInstructions={updateSpecialInstructions}
                      />
                    )}
                    {isAtLeastOneCalorie && appLabels["order"]["calories-disclaimer"] && (
                      <CaloriesDisclaimer
                        caloriesDisclaimer={appLabels["order"]["calories-disclaimer"]}
                      />
                    )}
                    {isMenuDisplay && !cartItem && (
                      <>
                        <FAB
                          isAddItemType={true}
                          isFormValid={areOptionsValid || isQuantityValid}
                          buttonText={
                            addedToOrder
                              ? appLabels["order"]["add-another"]
                              : appLabels["order"]["add-to-order"].replace(
                                  "[item-quantity]",
                                  comboQuantity
                                )
                          }
                          buttonSuccessText={appLabels["order"]["added"]}
                          formError={
                            !areOptionsValid && areOptionsValid != null
                              ? appLabels["form"]["missing-required-addons-modifiers-error"]
                              : !isQuantityValid && isQuantityValid != null
                              ? appLabels["order"]["invalid-item-quantity-error"]
                              : ""
                          }
                          onSubmit={addToOrder}
                          //resetInvalidForm={resetInvalidForm}
                          isLeftAligned={true}
                          isBackgroundGradient={true}
                          isDisabled={
                            isItemOutOfStock ||
                            (onlineQuantityLimit !== null &&
                              getItemQuantityFromCart(cart.value || [], menuItem.id) >=
                                onlineQuantityLimit)
                          }
                          isAddToOrder={true}
                          customClass={`${
                            cart.quantity > 0 ? "FAB--add-to-order__with-cart" : ""
                          } ${isItemOutOfStock ? " disabled_order_button" : ""}`}
                          FABCustomClass={
                            "FAB-wrapper__item-details-page FAB-wrapper__combo-add-to-choice wizard"
                          }
                          customStyle={{
                            border: `2px solid ${textIconColor}`,
                            backgroundColor: branding["online-order-settings-background-colour"],
                            color: textIconColor,
                            margin:
                              cart.value && cart.value.length > 0 && deviceWidth < 660
                                ? "0 auto 0 8%"
                                : "0 auto",
                            opacity:
                              isItemOutOfStock ||
                              (onlineQuantityLimit !== null &&
                                getItemQuantityFromCart(cart.value || [], menuItem.id) >=
                                  onlineQuantityLimit)
                                ? "0.3"
                                : "1",
                          }}
                          checkIconColor={textIconColor}
                          cartButton={
                            cart.quantity > 0 && deviceWidth >= 660 ? <OrderSettingsCart /> : null
                          }
                        />
                        {cart.quantity > 0 &&
                          deviceWidth < 660 &&
                          ReactDOM.createPortal(<OrderSettingsCart />, document.body)}
                      </>
                    )}
                    {(isModifyDisplay || cartItem) && (
                      <FAB
                        isAddItemType={true}
                        isFormValid={areOptionsValid || isQuantityValid}
                        buttonText={appLabels["order"]["confirm-modified-combo-changes-button"]}
                        buttonSuccessText="Confirmed"
                        formError={
                          !areOptionsValid && areOptionsValid != null
                            ? appLabels["form"]["missing-required-addons-modifiers-error"]
                            : !isQuantityValid && isQuantityValid != null
                            ? appLabels["order"]["invalid-item-quantity-error"]
                            : ""
                        }
                        onSubmit={confirmChanges}
                        //resetInvalidForm={resetInvalidForm}
                        isLeftAligned={false}
                        isBackgroundGradient={true}
                        isDisabled={false}
                        FABCustomClass={
                          "FAB-wrapper__combo-add-to-choice FAB-wrapper__item-details-page FAB--add-to-order__with-cart-wrapper FAB--combo-confirm-choices-wrapper"
                        }
                        customStyle={{
                          border: `2px solid ${textIconColor}`,
                          backgroundColor: branding["online-order-settings-background-colour"],
                          color: textIconColor,
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    {Object.entries(currentSubgroupItems).length > 0 && (
                      <MenuComboItems
                        sliderItems={currentSubgroupItems}
                        comboCartItem={comboCartItem}
                        isAtLeastOneCalorie={isAtLeastOneCalorie}
                        mainComboItemName={menuItem.name}
                        displayType={displayType}
                        cartItem={cartItem}
                        comboChildImage={comboChildImage}
                        currentSubgroupNumber={currentSubgroupNumber}
                        updateActiveItem={updateActiveItem}
                        isModifyDisplay={isModifyDisplay}
                        isComboChildItem={true}
                        isQuantityCombo={isQuantityCombo}
                        subGroupQuantityReached={subGroupQuantityReached}
                        groupIsComplete={groupIsComplete}
                        mainComboId={menuItem.id}
                        setShowingComboChildItem={setShowingComboChildItem}
                        comboSubgroupNumber={comboSubgroupNumber}
                        setValidateGoingToNextLevel={setValidateGoingToNextLevel}
                        setIsEditingComboItem={setIsEditingComboItem}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        {isGuestFavoriteAttempt && (
          <DialogModal
            isConfirmText={false}
            hideConfirmButton={true}
            description="Guest User attempting to set item as favourite"
            message={
              <div className="menu-item__favourite-dialog-modal">
                <div>
                  <p>{appLabels["order"]["guest-attempt-favorite-item"]}</p>
                </div>
                <div className="menu-item__favourite-dialog-modal-buttons-wrapper">
                  <button
                    onClick={() => {
                      sessionStorage.setItem(
                        skin + "__lastVisitedLink",
                        window.location.hash.split("#")[1]
                      );
                      storeItemSelectionInProgress();
                      history.push("/login-register");
                    }}
                    className="button dialog-modal__button  button--primary button--primary-small"
                    type="button"
                  >
                    {appLabels["login-register"]["sign-in"]}
                  </button>

                  <button
                    onClick={() => {
                      sessionStorage.setItem(
                        skin + "__lastVisitedLink",
                        window.location.hash.split("#")[1]
                      );
                      storeItemSelectionInProgress();
                      history.push("/register");
                    }}
                    className="button dialog-modal__button  button--primary button--primary-small"
                    type="button"
                  >
                    {appLabels["login-register"]["register"]}
                  </button>
                </div>
              </div>
            }
            resetRemoveDialog={() => {
              setIsGuestFavoriteAttempt(false);
            }}
            isHTMLContent={true}
          />
        )}
      </div>
    </>
  );
};

import React, { useContext } from "react";
import { useParams } from "react-router-dom";

/** Contexts */
import { MenuContext } from "./MenuContext";

/** UI Components */
import { MenuBreadcrumbs } from "./MenuBreadcrumbs";
import { MenuCategoryFilterButtonsList } from "./MenuCategoryFilterButtonsList/MenuCategoryFilterButtonsList";

const ComboCategoryFilterButtons = () => {
  const orderMenu = useContext(MenuContext);
  const menuLevel = orderMenu.menuLevel;

  const { category, subcategory } = useParams();

  const categoryData =
    menuLevel === "threeLevels" && category
      ? orderMenu.apiData[`category-${category}`].subcategories[`subcategory-${subcategory}`]
      : orderMenu.apiData[`subcategory-${subcategory}`];

  return (
    <>
      <MenuCategoryFilterButtonsList />
      <MenuBreadcrumbs currentLevelCategory={categoryData} />
    </>
  );
};
export default ComboCategoryFilterButtons;

// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import localforage from "localforage";
import { useHistory } from "react-router-dom";
import he from "he";

// Custom hooks
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helper functions
import {
  //toDollars,
  get,
  formatOrderType,
  getTimestamp,
  getDeviceTypeId,
  toDollars,
} from "../../_common/helpers";
import { formatCartForApi } from "../../_common/CartHelpers";
import { getDayOfWeekFromIndex, getMonthFromIndex } from "../Locations/helpers/isStoreOpenOrClosed";
import { getTimeAmPm, getTrueBusinessDate } from "../../Dashboard/DashboardOrder/dateHelpers";
import {
  updateLocationData,
  ensureOrderTimeMinInterval,
  isOrderTimePassed,
  isOrderTimeBeforeOpeningTime,
  isOrderTimeBlocked,
  isLateOrderAttempted,
  getOrderStoreAddressForDialogModal,
  isStoreClosedToday,
} from "../../_common/PaymentHelpers";
import {
  checkBillRequestStatus,
  formatBillItemsForApi,
  generateBillsForItems,
} from "../Menu/Bill/BillHelpers";
import { isChromeBrowser, isSafariBrowser } from "../../_common/isSafariBrowser";
import { onCheckoutXtreme } from "../../_common/helpers/xtremePushHelper";

// API helper functions
import { callAPI, callPaymentAPI } from "../../_common/Api";
import { CallApi1307 } from "../Cart/CallApi1307";

// Contexts
import UserRoleContext from "../../App/UserRoleContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import OrderTypeContext from "../OrderTypeContext";
import StoreContext from "../StoreContext";
import CartContext from "../Cart/CartContext";
import OrderTimeContext from "../OrderTimeContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import BillContext from "../Menu/Bill/BillContext";
import AppSettingsContext from "../../App/AppSettingsContext";

// UI components
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { PaymentMethod } from "./PaymentMethod/PaymentMethod";
import { Form } from "../../_common/Form/Form";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { AdditionalDisclaimer } from "../../_common/AdditionalDisclaimer";
import { CartOrderDetailsSummary } from "../Cart/CartOrderDetailsSummary";
import { ReactComponent as IconCard } from "../../_common/icons/IconCard.svg";
import { ReactComponent as IconPaypal } from "../../_common/icons/IconPaypal.svg";
import { ReactComponent as IconGift } from "../../_common/icons/IconGift.svg";
import { ReactComponent as IconPaperBag } from "../../_common/icons/IconPaperBag.svg";
import { ReactComponent as IconGooglePay } from "../../_common/icons/IconGooglePay.svg";
import { ReactComponent as IconApplePay } from "../../_common/icons/IconApplePay.svg";
import PaymentOverlay from "./PaymentOverlay";

// CSS
import "./Payment.css";
import { getOSPlatform } from "../../Account/AddToWalletLinkHelpers";

const iconCard = <IconCard />;
const iconPaypal = <IconPaypal />;
const iconGift = <IconGift />;
const iconPaperBag = <IconPaperBag />;
const iconGooglePay = <IconGooglePay />;
const iconApplePay = <IconApplePay />;

export const Payment = (props) => {
  const { isSectionCollapsed, orderSummaryData, isOnBillPaymentPage } = props;
  const userRoleContext = useContext(UserRoleContext);
  const userStatus = userRoleContext.status;
  const billContext = useContext(BillContext);

  const appSettings = useContext(AppSettingsContext);

  const billItems = billContext.value;
  const billDetails = billContext.details;

  const history = useHistory();

  const appLabels = useContext(AppLabelsContext);

  const merchantConfig = useContext(MerchantConfigContext);
  const skin = merchantConfig.skin;
  const orderTypeContext = useContext(OrderTypeContext);
  const storeContext = useContext(StoreContext);
  const cart = useContext(CartContext);
  const loginToken = userRoleContext.loginToken;
  const isLoggedIn = userRoleContext.status === "logged-in"; 

  const isStadiumSchema = merchantConfig.merchant.I55 === "stadium";

  useEffect(() => {
    if (
      cart &&
      cart.quantity === 0 &&
      window.location.href.includes("payment") &&
      !window.location.href.includes("bill-payment")
    ) {
      // Redirect user to Cart with Add More Items button if cart is empty
      history.push("/online-ordering/review-order");
    }
  }, [cart]);

  /** check if the url contains any parameters regarding 3d secure */
  const [is3dSecureRejected, setIs3dSecureRejected] = useState(null);
  useEffect(() => {
    const url = window.location.href;
    const parameters = url.split("?");
    if (parameters.length > 1) {
      const paymentRejected = parameters[1].split("=")[1] === "rejected";
      setIs3dSecureRejected(paymentRejected);
    }

  }, []);

  const activeOrderType = orderTypeContext.value;
  const activeOrderStore = storeContext.activeOrderStore;

  const [isApiLoading, setIsApiLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [creditCardTypes, setCreditCardTypes] = useState([]);
  const [mealVoucherCardTypes, setMealVoucherCardTypes] = useState([]);
  const [debitCardTypes, setDebitCardTypes] = useState([]);

  const [promoCode, setPromoCode] = useState("");
  const [rewards, setRewards] = useState("");
  const [coupon, setCoupon] = useState("");
  const platform = getOSPlatform();

  const isIOSDevice = platform === "ios";
  const isAndroidDevice = platform === "android";
  const isChrome = isChromeBrowser();
  const isSafari = isSafariBrowser();

  const orderTimeContext = useContext(OrderTimeContext);

  //Get stored promo/rewards/coupon and calculate order total
  const [orderTotal, setOrderTotal] = useState(null);
  const isCalling1307 = useRef(false);
  useEffect(() => {
    if (
      (get(cart, "value") || billItems) &&
      activeOrderType &&
      activeOrderStore &&
      !isOnBillPaymentPage &&
      isCalling1307.current === false
    ) {
      if (
        window.location.href.includes("order-confirmation") ||
        window.location.href.includes("payment-confirmation")
      ) {
        return;
      }
      isCalling1307.current = true;

      const formattedCart = window.location.href.includes("bill-payment")
        ? formatBillItemsForApi(billItems)
        : formatCartForApi(cart.value);
      const formattedOrderType = formatOrderType(activeOrderType);

      localforage.getItem(skin + "__storedPromoCode").then((storedPromoCode) => {
        localforage.getItem(skin + "__storedRewards").then((storedRewards) => {
          localforage.getItem(skin + "__coupon").then((storedCoupon) => {
            if (storedPromoCode && !window.location.href.includes("bill-payment")) {
              setPromoCode(storedPromoCode);
            }
            if (storedRewards && !window.location.href.includes("bill-payment")) {
              setRewards(storedRewards);
            }
            if (storedCoupon && !window.location.href.includes("bill-payment")) {
              setCoupon(storedCoupon);
            }
            CallApi1307(
              skin,
              formattedCart,
              window.location.href.includes("bill-payment") ? "" : storedPromoCode || "",
              formattedOrderType,
              activeOrderStore,
              window.location.href.includes("bill-payment") ? "" : storedRewards || "",
              appLanguage,
              orderTimeContext.value && orderTimeContext.value.value !== "Select Time"
                ? getTimestamp(orderTimeContext.value.value)
                : "",
              getDeviceTypeId(activeOrderType),
              isStadiumSchema
            ).then((data1307) => {
              if (data1307.result.I0) {
                localforage.setItem(skin + "__orderSummary", data1307.result);
                const totalPreCoupon = data1307.result.I3;
                let finalOrderTotal = "";
                if (storedCoupon) {
                  callAPI(skin, "dc_994", {
                    params: [appLanguage, "994", "mqid", "mqpass", storedCoupon.code, "", ""],
                    id: "994",
                  }).then((data994) => {
                    if (data994.result.I0) {
                      const couponBalance = parseFloat(data994.result.I2);
                      finalOrderTotal = totalPreCoupon - couponBalance;

                      if (finalOrderTotal <= 0) {
                        finalOrderTotal = 0;
                      }

                      //check for tip amount
                      localforage.getItem(skin + "__storedTip").then((storedTip) => {
                        if (storedTip) {
                          finalOrderTotal =
                            parseFloat(finalOrderTotal) + parseFloat(storedTip.tipAmount);
                          setOrderTotal(finalOrderTotal);
                          localforage.setItem(skin + "__finalOrderTotal", finalOrderTotal);
                        } else {
                          setOrderTotal(finalOrderTotal);
                          localforage.setItem(skin + "__finalOrderTotal", finalOrderTotal);
                        }
                      });
                    }
                  });
                } else {
                  finalOrderTotal = totalPreCoupon;
                  localforage.getItem(skin + "__storedTip").then((storedTip) => {
                    if (storedTip) {
                      finalOrderTotal =
                        parseFloat(finalOrderTotal) + parseFloat(storedTip.tipAmount);
                      setOrderTotal(finalOrderTotal);
                      localforage.setItem(skin + "__finalOrderTotal", finalOrderTotal);
                    } else {
                      setOrderTotal(finalOrderTotal);
                      localforage.setItem(skin + "__finalOrderTotal", finalOrderTotal);
                    }
                  });
                }
                setTimeout(() => {
                  isCalling1307.current = false;
                }, 1000);
              }
            });
          });
        });
      });
    }
  }, [cart, orderTypeContext, storeContext, orderSummaryData, isOnBillPaymentPage]);
  //END OF Get stored promo/rewards/coupon and calculate order total

  /** Calculate the final order amount for when dealing with bill payment  */
  useEffect(() => {
    if (isOnBillPaymentPage && orderSummaryData) {
      //get the data from localforage

      localforage.getItem(skin + "__orderSummary").then((storedOrderSummary) => {
        if (storedOrderSummary) {
          const orderSummary = JSON.parse(storedOrderSummary);
          let finalOrderTotal = orderSummary.orderTotal;

          localforage.getItem(skin + "__storedTip").then((storedTip) => {
            if (storedTip) {
              finalOrderTotal = parseFloat(finalOrderTotal) + parseFloat(storedTip.tipAmount);
              setOrderTotal(finalOrderTotal);
              localforage.setItem(skin + "__finalOrderTotal", finalOrderTotal);
            } else {
              setOrderTotal(finalOrderTotal);
              localforage.setItem(skin + "__finalOrderTotal", finalOrderTotal);
            }
          });
        }
      });
    }
  }, [orderSummaryData]);

  const [isPaymentMethods, setIsPaymentMethods] = useState(null);
  useEffect(() => {
    if (orderTotal && orderTotal > 0) setIsPaymentMethods(true);
    else setIsPaymentMethods(false);
  }, [orderTotal]);

  useEffect(() => {
    if (orderTotal != null) {
      let tempMethods = [];
      let apiMethods = merchantConfig.vexilor.I2.payment_methods;
      let storePaymentIMethods = activeOrderStore.vexilorConfig.payment_methods;
      let tempCreditCardTypes = [];
      let tempMealVoucherTypes = [];
      let tempDebitCardTypes = [];

      if (apiMethods && Object.keys(apiMethods).length > 0) {
        /** For Google Pay and Apple Pay, look at store level settings */
        if (
          storePaymentIMethods &&
          Object.keys(storePaymentIMethods).length > 0 &&
          storePaymentIMethods["OTHERS"] &&
          storePaymentIMethods["OTHERS"].length > 0
        ) {
          storePaymentIMethods["OTHERS"].forEach((digitalPayments) => {
            const method = {};

            method.type = digitalPayments[0].toLowerCase();

            /** Temp: disable apple and google pay for bill payment only until the BE supports it */
            if (method.type === "applepay" && (isIOSDevice || isSafari)) {
              method.displayName = appLabels["order"]["apple-pay"];
              method.icon = iconApplePay;
              method.type = "apple-pay";
            } else if (method.type === "googlpay" && (isAndroidDevice || isChrome)) {
              method.displayName = appLabels["order"]["google-pay"];
              method.icon = iconGooglePay;
              method.type = "google-pay";
            }

            if (method.displayName) {
              tempMethods.push(method);
            }
          });
        }

        for (let paymentMethod in apiMethods) {
          const method = {};

          method.type = paymentMethod.toLowerCase();

          if (method.type === "credit") {
            method.displayName = appLabels["general"]["credit-card"];
            method.icon = iconCard;
            method.type = "credit-card";

            // Store accepted CC types for icon display
            apiMethods[paymentMethod].forEach((ccType) => {
              tempCreditCardTypes.push(ccType[0].toLowerCase());
            });
          } else if (method.type === "debit") {
            method.displayName = "Debit";
            method.icon = iconCard;
            method.type = "debit";

            //store accepted debit card types for icon display
            apiMethods[paymentMethod].forEach((ccType) => {
              const type = ccType[0].toLowerCase();
              if (type !== "sodexo" && type !== "ticket_debit" && type !== "alelo") {
                tempDebitCardTypes.push(ccType[0].toLowerCase());
              }
            });
          } else if (method.type === "giftcard") {
            method.displayName = appLabels["general"]["gift-card"];
            method.icon = iconGift;
            method.type = "gift-card";
          } else if (method.type === "others") {
            apiMethods[method.type.toUpperCase()].forEach((otherType) => {
              if (otherType[0] === "PAYPAL") {
                method.displayName = "PayPal";
                method.icon = iconPaypal;
                method.type = "paypal";
              }
            });
          } else if (method.type === "coupon") {
            continue;
          } else if (method.type === "cash") {
            continue;
          } else if (method.type === "loyalty" || method.type === "others") {
            continue;
          }

          //filter out any payment method that has a no display name
          if (method.displayName) {
            tempMethods.push(method);
          }

          // check if any of the debit cards are meal voucher
          if (method.type === "debit") {
            const debitCards = apiMethods[paymentMethod];
            for (let i = 0; i < debitCards.length; i++) {
              const debitCard = debitCards[i];

              if (
                debitCard[0] === "SODEXO" ||
                debitCard[0] === "TICKET_DEBIT" ||
                debitCard[0] === "ALELO"
              ) {
                tempMethods.push({
                  displayName: appLabels["order"]["meal-voucher"],
                  icon: iconCard,
                  type: "meal-voucher",
                });

                // Store accepted Meal Voucher types for icon display
                apiMethods[paymentMethod].forEach((ccType) => {
                  const type = ccType[0].toLowerCase();
                  if (type === "sodexo" || type === "ticket_debit" || type === "alelo")
                    tempMealVoucherTypes.push(ccType[0].toLowerCase());
                });

                setMealVoucherCardTypes(tempMealVoucherTypes);
                break;
              }
            }
          }
        }

        /** This is a special case for Private Label Credit card used in BR */
        if (apiMethods && Object.keys(apiMethods).length > 0) {
          if (apiMethods["OTHERS"] && apiMethods["OTHERS"].length > 0) {
            apiMethods["OTHERS"].forEach((digitalPayments) => {
              const method = {};

              method.type = digitalPayments[0].toLowerCase();
              if (method.type === "private") {
                tempCreditCardTypes.push(method.type);
              }
            });
          }
        }
        const merchantVexilorConfig = merchantConfig.vexilor.I2;

        //check the req_pay_all_orders on the store level
        const isPaymentForAllOrdersRequired =
          storeContext.activeOrderStore.vexilorConfig.req_pay_all_orders === "on";
        const isOnlyGuestPaymentRequired = merchantVexilorConfig.req_pay_unreg_cust === "on";
        const maxTotalOrderWithoutPayment = Number(
          activeOrderStore.vexilorConfig.max_amt_unpaid_ord
        );
        if (
          activeOrderType !== "dinein" &&
          !isPaymentForAllOrdersRequired &&
          ((userStatus === "guest" && !isOnlyGuestPaymentRequired) || userStatus === "logged-in") &&
          orderTotal < maxTotalOrderWithoutPayment
        ) {
          const method = {
            icon: iconPaperBag,
          };

          if (activeOrderType === "pickup") {
            method.displayName = appLabels["order"]["pay-on-pickup"];
            method.type = "pay-on-pickup";
          } else if (activeOrderType === "delivery") {
            method.displayName = appLabels["order"]["pay-on-delivery"];
            method.type = "pay-on-delivery";
          }

          tempMethods.push(method);
        }

        setDebitCardTypes(tempDebitCardTypes);
        setPaymentMethods(tempMethods);
        setCreditCardTypes(tempCreditCardTypes);
        setIsApiLoading(false);
      }
    }
  }, [orderTotal]);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const collapsedClass = isCollapsed ? "payment-methods--collapsed" : "";

  const [apiError, setAPIError] = useState("");
  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [isAPISubmitting, setAPISubmitting] = useState(false);

  const getCurrentTimestamp = () => {
    const date = new Date(
      ensureOrderTimeMinInterval(orderTimeContext.value.value, activeOrderStore, activeOrderType)
    );
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return timestamp;
  };

  const [customerInfo, setCustomerInfo] = useState(null);

  const [orderDate, setOrderDate] = useState(null);
  const [orderTime, setOrderTime] = useState(null);

  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      if (customerInfo) setCustomerInfo(customerInfo);
      //else history.goBack();
    });

    localforage.getItem(skin + "__orderDate").then((orderDate) => {
      if (orderDate) {
        setOrderDate(orderDate.displayValue);
      }
    });

    localforage.getItem(skin + "__orderTime").then((orderTime) => {
      if (orderTime) {
        setOrderTime(orderTime.displayValue);
      }
    });
  }, []);

  const [deliveryAddress, setDeliveryAddress] = useState(null);
  useEffect(() => {
    if (activeOrderType === "delivery") {
      localforage.getItem(skin + "__userDeliveryAddress").then((storedDeliveryAddress) => {
        if (storedDeliveryAddress) setDeliveryAddress(storedDeliveryAddress);
        else setDeliveryAddress(false);
      });
    }
  }, [activeOrderType]);

  const [orderTypeInstruction, setOrderTypeInstructions] = useState("None");
  const [tipAmount, setTipAmount] = useState(0);
  useEffect(() => {
    localforage.getItem(skin + "__orderTypeSpecialInstruction").then((orderInstruction) => {
      if (orderInstruction) {
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let orderInstructionCopy = orderInstruction;
            orderInstructionCopy += `, ${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(orderInstructionCopy);
          } else {
            setOrderTypeInstructions(orderInstruction);
          }
        });
      } else {
        //there is no order instruction set
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let sectionRowSeatInfo = `${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(sectionRowSeatInfo);
          }
        });
      }
    });

    localforage.getItem(skin + "__storedTip").then((storedTip) => {
      if (storedTip) {
        setTipAmount(storedTip.tipAmount);
      }
    });
  }, []);

  const [showPaymentOverlay, setShowPaymentOverlay] = useState(false);
  const [headerLabel, setHeaderLabel] = useState(null);

  const payZeroTotal = async () => {
    setAPISubmitting(true);
    setHeaderLabel(appLabels["order"]["connecting"]);

    setTimeout(() => {
      setHeaderLabel(appLabels["order"]["hang-tight-processing-payment"]);
    }, 2000);

    setTimeout(() => {
      setHeaderLabel(appLabels["order"]["almost-done-passing-order-to-store"]);
    }, 4000);

    const formattedOrderType = formatOrderType(activeOrderType);
    const timestamp = getCurrentTimestamp();

    const formattedCartItems = window.location.href.includes("bill-payment")
      ? formatBillItemsForApi(billItems)
      : formatCartForApi(cart.value);

    let order_address = activeOrderStore.address;
    let order_postal = activeOrderStore.postal;
    let order_city = activeOrderStore.city;

    if (activeOrderType === "delivery") {
      if (isStadiumSchema) {
        order_postal = deliveryAddress;
      } else {
        const isSecondAddress = deliveryAddress["second-address"];
        order_address =
          deliveryAddress["first-address"] +
          (isSecondAddress ? ", #" + deliveryAddress["second-address"] : "");
        order_postal = deliveryAddress.postal;
        order_city = deliveryAddress.city;
      }
    }

    const coupons = [];
    if (coupon) coupons.push(coupon.code);

    if (window.location.href.includes("bill-payment")) {
      /** If paying a bill */

      let orderLineList = [];

      billItems.forEach((billItem) => {
        orderLineList.push(billItem["vxl_order_line_id"]);
      });

      const billAPIData = await generateBillsForItems(
        appLanguage,
        skin,
        billDetails["vxl_order_id"], // vexilor order id
        orderLineList, //order line id list
        "", //online order id
        "", //loyalty card
        activeOrderStore.storeId
      );

      if (billAPIData && billAPIData.status === 0) {
        const hostTransactionId = billAPIData.result.I2;

        const billStatusData = await checkBillRequestStatus(
          appLanguage,
          skin,
          hostTransactionId,
          activeOrderStore.storeId
        );

        if (billStatusData && billStatusData.status === 0) {
          setTimeout(() => {
            callPaymentAPI(skin, "dc_vxl_pay_bill", {
              params: [
                skin,
                "consumer",
                appLanguage,
                activeOrderStore.storeId, //outlet id
                hostTransactionId, //Host transaction ID
                "", //workstation ID (optional)
                {
                  customer_id: customerInfo.id || "",
                  order_email: customerInfo.email || "",
                  order_fname: customerInfo.firstName || "",
                  order_lname: customerInfo.lastName || "",
                  order_phone: customerInfo.phone || "",
                  order_mobile: customerInfo.mobile || "",
                  order_address,
                  order_postal,
                  order_city,
                  order_prov: activeOrderStore.province,
                  order_country: activeOrderStore.country,
                  discount_for_the_whole_order: promoCode,
                  redeem_rewards: rewards,
                }, // CustomerInfo
                coupons, // gc_number_list
                "f", // save_credit_card
                "", // tm_session_id
                "", // scene_cardnum
                "CREDITCARD", // payment_type
                "", // cc_id
                "", // cc_type
                "", // cc_number
                "", // cc_name
                "", // cc_security
                "", // cc_issue
                "", // cc_expiry_month
                "", // cc_expiry_year
                "", // cc_start_month
                "", // cc_start_year
                "", // cc_address1
                "", // cc_address2
                "", // cc_city
                "", // cc_province
                "", // cc_country
                "", // cc_postal
                customerInfo.email, // cc_email
                customerInfo.phone || "", // cc_phone
                "", // pa_res
                "", // return_url
                "", // cancel_url
                "", // success_url
                "", // error_url
                "", // fail_url
                "", // unique_pay_id
                "", // wechat_oauth_id
                "", // url_3ds_success
                "", // url_3ds_failure
                activeOrderType === "pickup" || activeOrderType === "dinein"
                  ? orderTypeInstruction
                  : "None", //Special Instruction (pickup / dinein)
                tipAmount, //tip
                customerInfo.email || "", //Receipt Email
              ],
              id: "dc_vxl_pay_bill",
            }).then((billPaymentAPIData) => {
              setAPISubmitting(false);

              if (billPaymentAPIData.result.I0) {
                // successfully placed an order
                localforage.setItem(skin + "__completedOrderId", billPaymentAPIData.result.I0);
                setIsAPISubmitValid(true);
              } else {
                // failed to place an order
                setShowPaymentOverlay(false);
                setIsAPISubmitValid(false);
                setAPIError(
                  `${appLabels["general"]["error-code"]}: ${
                    billPaymentAPIData.error.code
                  }. ${he.decode(billPaymentAPIData.result.message)}`
                );
              }
            });
          }, 5000);
        }
      } else {
        setAPIError(appLabels["order"]["bill-api-error"]);
      }
    } else {
      /** If submitting and paying an order */

      callPaymentAPI(skin, "dc_cws_vxl_online_order", {
        params: [
          skin,
          "consumer",
          appLanguage,
          activeOrderStore.storeId,
          formattedOrderType,
          timestamp || "", // Order Pickup/Delivery Timestamp (optional)
          {
            customer_id: customerInfo.id || "",
            order_email: customerInfo.email,
            order_fname: customerInfo.firstName,
            order_lname: customerInfo.lastName,
            order_phone: customerInfo.phone,
            order_mobile: customerInfo.mobile,
            order_address,
            order_postal,
            order_city,
            order_prov: activeOrderStore.province,
            order_country: activeOrderStore.country,
            discount_for_the_whole_order: promoCode,
            redeem_rewards: rewards,
            customer_token: isLoggedIn ? loginToken : ""
          }, // CustomerInfo
          formattedCartItems, // ItemsList
          activeOrderType === "delivery" ? orderTypeInstruction : "None", // Delivery Instructions
          activeOrderType === "dinein" ? storeContext.activeOrderStoreTable : "", // Table Number (optional)
          customerInfo.cpfNumber, // government_id
          coupons, // gc_number_list
          "", // save_credit_card
          "", // tm_session_id
          "", // scene_cardnum
          "CREDITCARD", // payment_type
          "", // cc_id
          "", // cc_type
          "", // cc_number
          "", // cc_name
          "", // cc_security
          "", // cc_issue
          "", // cc_expiry_month
          "", // cc_expiry_year
          "", // cc_start_month
          "", // cc_start_year
          "", // cc_address1
          "", // cc_address2
          "", // cc_city
          "", // cc_province
          "", //  cc_country
          "", // cc_postal
          customerInfo.email, // cc_email
          customerInfo.phone, // cc_phone
          "", // pa_res
          "", // return_url
          "", // cancel_url
          "", // success_url
          "", // error_url
          "", // fail_url
          "", // unique_pay_id
          "", // wechat_oauth_id
          "", // url_3ds_success
          "", // url_3ds_failure
          activeOrderType === "pickup" || activeOrderType === "dinein"
            ? orderTypeInstruction
            : "None", //Special Instruction (pickup / dinein)
          tipAmount, //tip
          getDeviceTypeId(activeOrderType), // device type id
        ],
        id: "dc_cws_vxl_online_order",
      }).then((dataOnlineOrder) => {
        setAPISubmitting(false);

        if (dataOnlineOrder.result.I0) {
          // successfully placed an order
          localforage.setItem(skin + "__completedOrderId", dataOnlineOrder.result.I0);
          localforage.setItem(skin + "__usedPaymentMethod", "");
          setIsAPISubmitValid(true);
        } else {
          // failed to place an order
          setShowPaymentOverlay(false);
          if (
            dataOnlineOrder.error &&
            dataOnlineOrder.error.message.includes(
              "Customer ID can only be used when a customer is logged in"
            )
          ) {
            userRoleContext.handleLoginTokenExpiration();
          } else {
            setIsAPISubmitValid(false);
            setAPIError(
              `${appLabels["general"]["error-code"]}: ${dataOnlineOrder.error.code}. ${he.decode(
                dataOnlineOrder.result.message
              )}`
            );
          }
        }
      });
    }
  };

  const resetAPIError = () => setAPIError("");

  const [isPaymentInfoReady, setIsPaymentInfoReady] = useState(false);
  useEffect(() => {
    if (activeOrderStore && (orderTotal || orderTotal === 0)) {
      setIsPaymentInfoReady(true);
    }
  }, [activeOrderType, storeContext, orderTotal]);

  const [isLateOrderAttempt, setIsLateOrderAttempt] = useState(false);
  const [isPassedOrderTime, setIsPassedOrderTime] = useState(false);
  const [isOrderTimeBeforeOpening, setIsOrderTimeBeforeOpening] = useState(false);
  const [nextAvailableOrderTime, setNextAvailableOrderTime] = useState(null);
  const [orderTimeIsBlocked, setOrderTimeIsBlocked] = useState(false);
  const [isStoreClosed, setIsStoreClosed] = useState(false);

  const formatOrderTime = (orderTime) => {
    const date = new Date(orderTime);
    const month = date.getMonth();
    const calDate = date.getDate();
    const day = date.getDay();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;

    const nameOfDay = getDayOfWeekFromIndex(day);
    const timestamp = `${
      nameOfDay.substr(0, 3).charAt(0).toUpperCase() + nameOfDay.substr(1, 2)
    } ${getMonthFromIndex(month)} ${calDate} at ${strTime}`;

    return (
      <>
        <br /> <br /> <span style={{ fontWeight: 700 }}>{timestamp} </span>
        <br /> <br />{" "}
      </>
    );
  };

  const updateOrderTime = () => {
    orderTimeContext.update({
      value: nextAvailableOrderTime,
      displayValue: getTimeAmPm(nextAvailableOrderTime),
      trueBusinessDate: getTrueBusinessDate(nextAvailableOrderTime, activeOrderStore),
      timeAtOrdering: new Date()
    });
    setIsPassedOrderTime(false);
  };

  const [showLoadingSpinnerWhenCallingAPIs, setShowLoadingSpinnerWhenCallingAPIs] = useState(false);
  const [showDoubleConfirmation, setShowDoubleConfirmation] = useState(false);

  const [formDataReference, setFormDataReference] = useState(null);
  const onCompleteOrderClick = async (formData) => {
    setShowLoadingSpinnerWhenCallingAPIs(true);
    //update the location with the latest data, in case the store hours have been changed
    const updatedLocation = await updateLocationData(
      activeOrderStore,
      activeOrderType,
      skin,
      appLanguage
    );
    if (updatedLocation) {
      setShowLoadingSpinnerWhenCallingAPIs(false);

      let isStoreClosedForTheDay = isStoreClosedToday(updatedLocation, orderTimeContext)

      const orderTimeIsPassed = isOrderTimePassed(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        setNextAvailableOrderTime
      );
      const orderTimeIsBeforeOpening = isOrderTimeBeforeOpeningTime(
        updatedLocation,
        orderTimeContext
      );
      const orderTimeIsBlocked = isOrderTimeBlocked(
        updatedLocation,
        orderTimeContext,
        activeOrderType
      );
      const lateOrderAttempt = isLateOrderAttempted(
        updatedLocation,
        activeOrderType,
        orderTimeContext,
        skin,
        setNextAvailableOrderTime
      );
      if (lateOrderAttempt) {
        isStoreClosedForTheDay = true
      }

      if (isStoreClosedForTheDay) {
        setIsStoreClosed(true)
        return;
      }
      else if (orderTimeIsPassed) {
        setIsPassedOrderTime(true);

        setAPISubmitting(false);
        setIsAPISubmitValid(false);
        setAPIError(<>&nbsp;</>); // reset the disabled state on submit FAB
      } else if (orderTimeIsBeforeOpening) {
        setIsOrderTimeBeforeOpening(true);

        setAPISubmitting(false);
        setIsAPISubmitValid(false);
        setAPIError(<>&nbsp;</>); // reset the disabled state on submit FAB
      } else if (orderTimeIsBlocked) {
        setOrderTimeIsBlocked(true);
        return;
      } else {
        if (activeOrderType === "pickup") {
          setShowDoubleConfirmation(true);
          setFormDataReference(formData);
        } else {
          setShowPaymentOverlay(true);
          payZeroTotal(formData); // call cws_vxl_online_order
        }
      }
    } else {
      // if updatedLocation returns null, it means the selected location does not exist anymore
      history.push("/dashboard");
    }
  };

  const onConfirmClick = () => {
    setShowDoubleConfirmation(false);
    setShowPaymentOverlay(true);
    payZeroTotal(formDataReference); // call cws_vxl_online_order
  };

  const deviceWidth = useWindowSize().width;

  const appLanguage = useContext(AppLanguageContext);

  useEffect(() => {
    onCheckoutXtreme(cart.value, 4, "", skin);
  }, []);

  useEffect(() => {
    localforage.getItem(skin + "__paypalPaymentStarted").then((paypalPaymentStarted) => {
      if (paypalPaymentStarted) {
        localforage.getItem(skin + "__completedOrderId").then((completedOrderId) => {
          if (completedOrderId) {
            cancelOrder(completedOrderId);
          }
        });
      }
    });
  }, []);

  const cancelOrder = (orderID) => {
    //cancel The order using API 1303
    callAPI(skin, "vxl_1303", {
      params: ["en", "1303", "mqid", "mqpass", activeOrderStore.storeId, orderID],
      id: "1303",
    }).then((data1303) => {
      localforage.removeItem(skin + "__paypalPaymentStarted")
      localforage.removeItem(skin + "__completedOrderId")
    });
  };
  return (
    <>
      {isPaymentInfoReady ? (
        <>
          {(activeOrderType !== "dinein" || deviceWidth > 660) && (
            <CartOrderDetailsSummary
              activeOrderStore={activeOrderStore}
              activeOrderType={activeOrderType}
              numberOfItems={get(cart, "value")}
            />
          )}

          <div
            className={`payment-content ${
              isSectionCollapsed
                ? " collapsable-section__collapsed-content"
                : " collapsable-section__expanded-content"
            }`}>
            {(activeOrderType !== "dinein" || deviceWidth > 660) && (
              <div className="payment-summary">
                <div role="alert">
                  <span className="visually-hidden">
                    {appLabels["order"]["your-total-is"]} {toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      orderTotal,
                      appLanguage)}. {appLabels["order"]["please-select-payment-method"]}
                  </span>
                </div>
                <div className="payment-summary__row">
                  <span className="payment-summary__label">
                    {appLabels["general"]["total"] + ":"}
                  </span>
                  <span className="payment-summary__output">
                    {toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      orderTotal,
                      appLanguage
                    )}
                  </span>
                </div>
              </div>
            )}

            <AdditionalDisclaimer
              disclaimer={appLabels["order"]["payment-disclaimer"]}
              styleObject={
                deviceWidth < 660 ? { padding: "25px 25px 0px 25px" } : { margin: "2em auto" }
              }
            />
            {isPaymentMethods ? (
              <div className="payment-methods-container">
                {!isApiLoading &&
                rewards != null &&
                promoCode != null &&
                paymentMethods.length > 0 ? (
                  <ul className={`payment-methods ${collapsedClass}`}>
                    {paymentMethods.map((method) => (
                      <PaymentMethod
                        key={method.type}
                        method={method}
                        creditCardTypes={creditCardTypes}
                        mealVoucherCardTypes={mealVoucherCardTypes}
                        debitCardTypes={debitCardTypes}
                        toggleCollapse={toggleCollapse}
                        orderTotal={orderTotal}
                        rewards={rewards}
                        promoCode={promoCode}
                        coupon={coupon}
                        appLabels={appLabels}
                        cartData={cart}
                        skin={skin}
                      />
                    ))}
                  </ul>
                ) : (
                  <LoadingSpinner />
                )}
              </div>
            ) : (
              <>
                <Form
                  id="form--pay-on-pickup"
                  isFAB={true}
                  submitButtonText={appLabels["order"]["submit-order-button"]}
                  submitButtonSuccessText={appLabels["order"]["submit-order-button-processing"]}
                  submitForm={onCompleteOrderClick}
                  submitAPIError={apiError}
                  resetAPIError={resetAPIError}
                  submitNavigateURL={
                    window.location.href.includes("bill-payment")
                      ? "/online-ordering/payment-confirmation"
                      : "/online-ordering/order-confirmation"
                  }
                  isAPISubmitValid={isAPISubmitValid}
                  isAPISubmitting={isAPISubmitting}
                  isAllowEmptySubmit={true}></Form>
                {showDoubleConfirmation && (
                  <DialogModal
                    message={getOrderStoreAddressForDialogModal(
                      activeOrderStore,
                      appLabels,
                      orderDate,
                      orderTime,
                      activeOrderType,
                      orderTimeContext,
                      skin
                    )}
                    isConfirmText={true}
                    isModifyText={appLabels["order"]["modify-location"]}
                    isCancelConfirm={true}
                    confirmAction={onConfirmClick}
                    resetRemoveDialog={() => {
                      history.push("/online-ordering/order-store");
                    }}
                    closeModalAction={() => {
                      setShowDoubleConfirmation(!showDoubleConfirmation);
                      window.location.reload(); // This will have to change when the BE adds support for validating store hours.
                    }}
                  />
                )}
                {isStoreClosed && (
                  <DialogModal
                      hideCloseButton={true}
                    closeModalAction={() => {
                      setIsStoreClosed(false);
                    }}
                    isCancelConfirm={false}
                    hideConfirmButton={false}
                    isCustomConfirmText={appLabels["order"]["pick-new-time"]}
                      resetRemoveDialog={() => {
                      history.push({
                        pathname: "/dashboard",
                        state: {
                          showTimePanel: true,
                          timeAtOrdering: orderTimeContext.value.timeAtOrdering,
                          originalOrderTimeContext: orderTimeContext
                        },
                      });
                    }}
                    message={
                      <div>
                        <h2>{appLabels["order"]["sorry-it-is-closing-time"]}</h2>
                        <p>{appLabels["order"]["store-closed-message"]}</p>
                      </div>
                    }
                  />
                )}
                {isLateOrderAttempt && (
                  <DialogModal
                    message={appLabels["order"]["order-too-close-to-closing-time-error"]}
                    resetRemoveDialog={() => {
                      setIsLateOrderAttempt(false);
                      orderTimeContext.update({
                        value: nextAvailableOrderTime,
                        displayValue: getTimeAmPm(nextAvailableOrderTime),
                        trueBusinessDate: getTrueBusinessDate(
                          nextAvailableOrderTime,
                          activeOrderStore
                        ),
                      });
                      history.push("/dashboard");
                      window.location.reload(); // This will have to change when the BE adds support for validating store hours.
                    }}
                  />
                )}
                {isPassedOrderTime && (
                  <DialogModal
                    message={
                      <div>
                        {appLabels["order"]["select-order-time-passed-message"] + ": "}
                        {formatOrderTime(nextAvailableOrderTime)}
                        {appLabels["order"]["confirm-updated-order-time-message"]}
                      </div>
                    }
                    resetRemoveDialog={() => setIsPassedOrderTime(false)}
                    isCancelConfirm={true}
                    confirmAction={updateOrderTime}
                    isHTMLContent={true}
                  />
                )}
                {isOrderTimeBeforeOpening && (
                  <DialogModal
                    message={appLabels["order"]["order-before-store-opening-time-error"]}
                    resetRemoveDialog={() => {
                      setIsOrderTimeBeforeOpening(false);
                      history.push("/dashboard");
                      window.location.reload(); // This will have to change when the BE adds support for validating store hours.
                    }}
                  />
                )}

                {orderTimeIsBlocked && (
                  <DialogModal
                    message={appLabels["order"]["order-time-is-blocked"]}
                    resetRemoveDialog={() => {
                      setOrderTimeIsBlocked(false);
                      history.push("/dashboard");
                      window.location.reload(); // This will have to change when the BE adds support for validating store hours.
                    }}
                  />
                )}

                {showLoadingSpinnerWhenCallingAPIs && <LoadingSpinner />}

                {showPaymentOverlay && <PaymentOverlay headerLabels={headerLabel} />}
              </>
            )}
          </div>
          {is3dSecureRejected && (
            <DialogModal
              message={"Something went wrong with your submission, please try again."}
              resetRemoveDialog={() => {
                window.location.hash = "#/online-ordering/payment";
                setIs3dSecureRejected(null);
              }}
            />
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

// Libraries
import React, { useContext } from "react";
import localforage from "localforage";
import { useHistory } from "react-router-dom";

// Custom hooks
import useWindowSize from "../hooks/useWindowSize";

// Contexts
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";
import StoreContext from "../../OnlineOrdering/StoreContext";
import OrderTimeContext from "../../OnlineOrdering/OrderTimeContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";

// UI components
import { DialogModal } from "../DialogModal/DialogModal";
import { OrderTypeAndStoreDropdowns } from "../../Dashboard/DashboardOrder/OrderTypeAndStoreDropdowns";
import { OrderDateAndTimeDropdowns } from "../../Dashboard/DashboardOrder/OrderDateAndTimeDropdowns";

export const OrderTimeModal = ({ setIsOrderTimeModal }) => {
  const activeOrderType = useContext(OrderTypeContext).value;
  const orderTimeContext = useContext(OrderTimeContext);
  const appLabels = useContext(AppLabelsContext);

  const skin = useContext(MerchantConfigContext).skin;

  const deviceWidth = useWindowSize().width;
  const history = useHistory();

  const updateOrderDateTime = () => {
    setIsOrderTimeModal(false);

    if (
      ((orderTimeContext.value.value == null || orderTimeContext.value.value === "Select Time") &&
        activeOrderType !== "dinein") ||
      (activeOrderType === "dinein" &&
        (!storeContext.activeOrderStore ||
          !storeContext.activeOrderStore.vexilorConfig ||
          storeContext.activeOrderStore["__isUnavailable"]))
    ) {
      setIsOrderTimeModal(true);
    } else {
      const isRecentOrders = window.location.href.includes("/online-ordering/recent-orders");
      const isCartButtonClick = sessionStorage.getItem(skin + "__isCartButtonClick");

      if (isCartButtonClick) {
        sessionStorage.removeItem(skin + "__isCartButtonClick");

        // before redirecting to review page, make sure that there is delivery address in the storage for delivery orders,
        //and there is table number in the storage for dinein orders
        if (activeOrderType === "delivery") {
          localforage.getItem(skin + "__userDeliveryAddress").then((deliveryAddress) => {
            if (deliveryAddress) {
              history.push("/online-ordering/review-order");
            } else {
              history.push("/online-ordering/delivery");
            }
          });
        } else if (activeOrderType === "dinein") {
          localforage.getItem(skin + "__activeOrderStoreTable").then((tableNumber) => {
            if (tableNumber) {
              history.push("/online-ordering/review-order");
            } else {
              history.push("/online-ordering/dinein");
            }
          });
        } else {
          // Active order time exists
          history.push("/online-ordering/review-order");
        }

        history.push("/online-ordering/review-order");
      } else {
        if (isRecentOrders) {
          if (activeOrderType === "delivery") {
            history.push("/online-ordering/delivery");
          } else if (activeOrderType === "dinein") {
            history.push("/online-ordering/dinein");
          } else {
            history.push("/online-ordering/review-order");
          }
        } else {
          // if use is on 'find a store' page, there is no redirection required
          if (
            !window.location.hash.includes("online-ordering/pickup") &&
            !window.location.hash.includes("online-ordering/delivery")
          ) {
            const hash = window.location.hash;
            if (hash.includes("/category") || hash.includes("/subcategory")) {
              history.push(hash.split("#")[1]);
            } else {
              if (activeOrderType === "delivery") {
                history.push("/online-ordering/delivery");
              } else if (activeOrderType === "pickup") {
                history.push({
                  pathname: "/online-ordering/menu",
                  state: { from: "order-settings-modal" },
                });
              } else if (activeOrderType === "dinein") {
                history.push("/online-ordering/dinein");
                if (deviceWidth >= 660) {
                  history.push({
                    pathname: "/online-ordering/menu",
                    state: { from: "order-settings-modal" },
                  });
                } else {
                  history.push("/online-ordering/dinein");
                }
              }
            }
          }
        }
      }
    }
  };

  const storeContext = useContext(StoreContext);

  return (
    <DialogModal
      isOrderSettingsModal={true}
      isHTMLContent={true}
      isConfirmText={true}
      closeModalAction={() => {
        setIsOrderTimeModal(false);
        sessionStorage.removeItem(skin + "__isCartButtonClick");
        if (window.location.href.includes("/online-ordering/")) {
          history.push("/dashboard");
        } else {
          orderTimeContext.update({
            value: null,
            displayValue: "",
          });
        }
      }}
      message={
        <div className="orderTimeDatePopupContent">
          {storeContext.activeOrderStore &&
            !storeContext.activeOrderStore["__isUnavailable"] &&
            activeOrderType !== "dinein" && (
              <h2>{appLabels["order"]["select-order-time-for-menu-modal-message"]}</h2>
            )}
          {storeContext.activeOrderStore && storeContext.activeOrderStore["__isUnavailable"] && (
            <h2>{appLabels["order"]["select-another-store-prompt"]}</h2>
          )}
          {!storeContext.activeOrderStore && <h2>{appLabels["order"]["select-a-store-prompt"]}</h2>}
          <div className="dashboard__selects-container">
            {(!storeContext.activeOrderStore ||
              activeOrderType === "dinein" ||
              storeContext.activeOrderStore["__isUnavailable"]) && <OrderTypeAndStoreDropdowns />}
            {storeContext.activeOrderStore &&
              !storeContext.activeOrderStore["__isUnavailable"] &&
              activeOrderType !== "dinein" && (
                <OrderDateAndTimeDropdowns activeOrderType={activeOrderType} />
              )}
          </div>
        </div>
      }
      resetRemoveDialog={updateOrderDateTime}
    />
  );
};

import { callAPI } from "../_common/Api";

export const getOSPlatform = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return "android";
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  } else {
    return false;
  }
};

export const getAPIWalletDownload = async (skin, language, fullGiftCardNumber, platform) => {
  const api959Data = await callAPI(skin, "dc_1035", {
    params: [
      language,
      "1035",
      "mqid",
      "mqpass",
      fullGiftCardNumber,
      platform === "ios" ? "APPL" : "GOGL",
      "",
      "",
    ],
    id: "1035",
  });

  if (api959Data.result.I2) {
    return api959Data.result.I2;
  }
};

import React, { useContext } from "react";

// UI Components
import { ReactComponent as IconCart } from "../../_common/icons/IconCart.svg";

//Contexts
import AppLabelsContext from "../../App/AppLabelsContext";
import { ViewMenuButton } from "./ViewMenuButton";

export const NoRecentOrders = () => {
  const appLabels = useContext(AppLabelsContext);

  return (
    <section className="recent-order__no-orders-message">
      <IconCart style={{ fill: "#e8e8e8", height: "200px" }} />
      <h3 className="recent-orders__no-orders-yet-label">{appLabels["order"]["no-orders-yet"]}</h3>
      <p className="recent-orders__browse-menu-label">{appLabels["order"]["browse-menu"]}</p>
      <ViewMenuButton />
    </section>
  );
};

import { useEffect } from "react";
import { createEl } from "../_common/helpers";

export const useCustomBodyScript = (scriptContents) => {
  useEffect(() => {
    if (scriptContents) {
      const script = createEl("script");
      script.id = "custom-body-snippets";
      script.innerHTML = scriptContents;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [scriptContents]);
};

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AppLabelsContext from "../../../App/AppLabelsContext";
import { ForgotPasswordLink } from "../../ForgotPassword/ForgotPasswordLink";
import "./UserAlreadyExists.css";

function UserAlreadyExists() {
  const history = useHistory();

  const appLabels = useContext(AppLabelsContext);

  return (
    <div className="register-user-exists">
      <div className="register-user-exists__content">
        <div className="">
          <p className="register-user-exists__text">
            {appLabels["login-register"]["user-already-exists-paragraph"]}
          </p>
          <button
            onClick={() => history.push("/login-register")}
            className="button button--primary register-user-exists__singin-button"
            type="button"
          >
            {appLabels["login-register"]["sign-in"]}
          </button>
          <ForgotPasswordLink />
        </div>
      </div>
    </div>
  );
}

export default UserAlreadyExists;

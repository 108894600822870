export const giftCardLabels = {
  gift: "Cadeau",
  "gift-cards": "Carte-cadeaux",
  "order-history": "Historique des commandes",
  "see-all": "Voir tout",
  amount: "Montant",
  "recipient-email": "Courriel du destinataire",
  "confirm-recipient-email": "Confirmer le courriel du destinataire",
  "recipient-name": "Nom du destinataire",
  "delivery-date-time": "Date et heure d'envoi",
  "greeting-message": "Message d'accueil",
  "sender-name": "Nom de l'expéditeur",
  "add-more-people": "Ajouter plus de personnes",
  "clear-form": "Vider le formulaire",
  save: "Sauvegarder",
  "select-gift-card": "Veuillez choisir une carte-cadeau",
  "exit-gift-card-dialog-message":
    "Quittez cette page vous fera perdre votre commande de carte-cadeaux",
  "sure-text": "Êtes-vous sûr?",
  "gift-card-recent-order-signed-out-message":
    "Parcourez les cartes-cadeaux pour passer une commande ou connectez-vous pour voir l'historique de vos transactions",
  "add-discount-coupon": "Ajouter un rabais / bon",
  minimum: "Minimum",
  maximum: "Maximum",
  "invalid-delivery-date-time-gift-card-title-message": "Oups..! Les date et heure de livraison sont incorrectes",
  "invalid-delivery-date-time-gift-card-message":
    "L’une de vos cartes-cadeaux a une date ou une heure de livraison invalide. La prochaine date et heure de livraison disponible serait",
  "exceeded-gift-card-amount-message":
    "Le nombre total de carte-cadeau(x) dans le panier est supérieur au montant a",
  "exceeded-gift-card-amount-remove-message":
    "Veuillez supprimer une carte-cadeau ou modifier le montant de la carte-cadeau.",
  "atleast-one-recipient-button": "Veuillez ajouter au moins un destinataire",
  "submit-recipient-error":
    "Les détails d'un ou plusieurs destinataires sont incorrects ou n'ont pas été fournis",
  "one-valid-recipient-missing-error":
    "Il faut au moins un destinataire valide pour une carte-cadeau",
  "gift-card-image-unavailable": "Image de la carte-cadeau indisponible",
  "view-gift-cards-button": "Afficher les cartes-cadeaux",
  "send-to-more-people": "Envoyer à d'autres personnes",
  "for-delivery-on": "pour livraison le",
  to: "à",
  at: "à",
  continue: "Continuer",
  "confirmation-email-sent": "Nous vous avons envoyé un courriel de confirmation",
  "gift-card-delivery-on": "Carte-cadeau électronique pour livraison le",
  one: "un",
  "go-to-gc-cart": "Afficher le panier de cartes-cadeaux",
  "no-gift-cards-available": "Aucune carte-cadeau n'est disponible pour l'instant",
  "gift-card-value-text": "Valeur de la carte-cadeau",
  "gift-card-nav-label": "Historique des commandes de cartes-cadeaux",
  "and-text": "et",
  "custom-amount": "Montant personnalisé",
  "minimum-one-recipient": "Il faut au moins un destinatire",
  "promo-code": "Code promo",
  "promo-apply": "Appliquer",
  "send-a-gift-card": "Envoyer une carte cadeau",
  "denomination-error-heading": "Oups... Une erreur s'est produite",
  "denomination-error-text": "Nous effaçons votre commande, veuillez réessayer.",
  "recipient": "Destinataire",
  "delivery": "Livraison",
  "message": "Message",
  "invalid-promo-code": "Code d'offre promotionnelle incorrect",
  "remove-applied-promo-codes": "Supprimer les codes promo appliqués",
  "order-limit-reached": "Votre commande dépasse la limite autorisée",
  "item-order-limit": "Limite de commande de",
  "reached": "Atteinte",
  "promo": "Promo"
};

import React, { useContext, useState, useEffect } from "react";
import localforage from "localforage";
import { useHistory } from "react-router-dom";

import he from "he";

/** Contexts */
import AppLabelsContext from "../../App/AppLabelsContext";
import OrderTypeContext from "../OrderTypeContext";
import StoreContext from "../StoreContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import OrderTimeContext from "../OrderTimeContext";
import CartContext from "./CartContext";

/** Helpers */
import {
  ensureOrderTimeMinInterval,
  isLateOrderAttempted,
  isOrderTimeBeforeOpeningTime,
  isOrderTimeBlocked,
  isOrderTimePassed,
  updateLocationData,
  isStoreClosedToday
} from "../../_common/PaymentHelpers";
import { formatOrderType, getDeviceTypeId } from "../../_common/helpers";
import { formatCartForApi } from "../../_common/CartHelpers";
import { callAPI } from "../../_common/Api";

const SubmitOrderWithoutPayment = (props) => {
  const {
    isMinDeliverySpendingMet,
    skin,
    setNextAvailableOrderTime,
    setIsPassedOrderTime,
    setIsOrderTimeBeforeOpening,
    setOrderTimeIsBlocked,
    setSubmitOrderApiErrorHideClass,
    setSubmitOrderApiError,
    setIsStoreClosed,
  } = props;

  const appLabels = useContext(AppLabelsContext);
  const orderTypeContext = useContext(OrderTypeContext);
  const storeContext = useContext(StoreContext);
  const appLanguage = useContext(AppLanguageContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const cart = useContext(CartContext);

  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({});
  const [orderTypeInstructions, setOrderTypeInstructions] = useState("None");

  const activeOrderType = orderTypeContext.value;
  const activeOrderStore = storeContext.activeOrderStore;
  const history = useHistory();

  const [isSubmittingOrder, setIsSubmittingOrder] = useState(null);

  useEffect(() => {
    if (activeOrderType === "delivery") {
      localforage.getItem(skin + "__userDeliveryAddress").then((storedDeliveryAddress) => {
        if (storedDeliveryAddress) setDeliveryAddress(storedDeliveryAddress);
        else setDeliveryAddress(false);
      });
    }
  }, []);

  useEffect(() => {
    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      if (customerInfo) setCustomerInfo(customerInfo);
    });

    localforage.getItem(skin + "__orderTypeSpecialInstruction").then((orderInstruction) => {
      if (orderInstruction) {
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let orderInstructionCopy = orderInstruction;
            orderInstructionCopy += `, ${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(he.encode(orderInstructionCopy, { decimal: true }));
          } else {
            setOrderTypeInstructions(he.encode(orderInstruction, { decimal: true }));
          }
        });
      } else {
        //there is no order instruction set
        localforage.getItem(skin + "__stadium-schema").then((schema) => {
          if (schema) {
            let parsedSchema = JSON.parse(schema);
            let sectionRowSeatInfo = `${
              appLabels["form"]["section"]
            }:${parsedSchema.section.toUpperCase()}/${
              appLabels["form"]["row"]
            }:${parsedSchema.row.toUpperCase()}/${
              appLabels["form"]["seat"]
            }:${parsedSchema.seat.toUpperCase()}`;

            setOrderTypeInstructions(he.encode(sectionRowSeatInfo, { decimal: true }));
          }
        });
      }
    });
  }, []);

  const getCurrentTimestamp = () => {
    const date = new Date(
      ensureOrderTimeMinInterval(orderTimeContext.value.value, activeOrderStore, activeOrderType)
    );
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return timestamp;
  };

  const confirmOrder = (orderID) => {
    callAPI(skin, "vxl_1308", {
      params: [
        appLanguage,
        "1308",
        "mqid",
        "mqpass",
        activeOrderStore.storeId, // Outlet ID
        orderID, // Online Order ID
        [], // PaymentList
      ],
      id: "1308",
    }).then((data1308) => {
      if (data1308.result.I1 === "0") {
        // Order was successfully confirmed

        setTimeout(() => {
          history.push({
            pathname: "/online-ordering/order-confirmation",
            state: { from: "order-without-payment" },
          });
        }, 2000);
      } else {
        setIsSubmittingOrder(false);
        // Failed to cancel order
        //setAPIError(data1308.result.message);
        setSubmitOrderApiError(data1308.result.message);
        setTimeout(() => {
          setSubmitOrderApiErrorHideClass("FAB__error--closing");
          setSubmitOrderApiError(null);
          setTimeout(() => {
            setSubmitOrderApiErrorHideClass("");
          }, 250);
        }, 3000);
        console.error(data1308.result.message);
      }
    });
  };

  const [promoCode, setPromoCode] = useState("");
  const [rewards, setRewards] = useState("");

  useEffect(() => {
    localforage.getItem(skin + "__storedPromoCode").then((storedPromoCode) => {
      localforage.getItem(skin + "__storedRewards").then((storedRewards) => {
        if (storedPromoCode) setPromoCode(storedPromoCode);
        if (storedRewards) setRewards(storedRewards);
      });
    });
  }, []);

  const placeOrder = () => {
    const formattedOrderType = formatOrderType(activeOrderType);
    const timestamp = activeOrderType === "dinein" ? "" : getCurrentTimestamp();
    const formattedCartItems = formatCartForApi(cart.value);

    let order_address = activeOrderStore.address;
    let order_postal = activeOrderStore.postal;
    let order_city = activeOrderStore.city;

    if (activeOrderType === "delivery") {
      const isSecondAddress = deliveryAddress["second-address"];

      order_address =
        deliveryAddress["first-address"] +
        (isSecondAddress ? ", #" + deliveryAddress["second-address"] : "");

      order_postal = deliveryAddress.postal;
      order_city = deliveryAddress.city;
    }

    // Call 1302
    callAPI(skin, "vxl_1302", {
      params: [
        appLanguage,
        "1302",
        "mqid",
        "mqpass",
        activeOrderStore.storeId,
        formattedOrderType,
        timestamp || "", // Order Pickup/Delivery Timestamp (optional)
        [], // PaymentList
        {
          customer_id: customerInfo.id || "",
          order_email: customerInfo.email,
          order_fname: customerInfo.firstName,
          order_lname: customerInfo.lastName,
          order_phone: customerInfo.phone,
          order_mobile: customerInfo.mobile,
          order_address: order_address,
          order_postal,
          order_city: order_city,
          order_prov: activeOrderStore.province,
          order_country: activeOrderStore.country,
          discount_for_the_whole_order: promoCode,
          redeem_rewards: rewards,
        }, // CustomerInfo
        formattedCartItems, // ItemsList
        activeOrderType === "delivery" ? orderTypeInstructions : "None", // Delivery Instructions
        activeOrderType === "dinein" ? storeContext.activeOrderStoreTable : "", // Table Number (optional)
        customerInfo.cpfNumber, //Government ID
        activeOrderType === "pickup" || activeOrderType === "dinein"
          ? orderTypeInstructions
          : "None", //Special Instruction (pickup / dinein)
        null, //Order Delivery Reference (optional)
        null, //Driver Pickup Timestamp (optional)
        getDeviceTypeId(activeOrderType), //Device Type (optional)
      ],
      id: "1302",
    }).then((data1302) => {
      if (data1302.result.I2) {
        // Order was successfully placed
        const orderID = data1302.result.I2;
        const finalOrderTotal = data1302.result.I3;
        localforage.setItem(skin + "__completedOrderId", orderID);
        localforage.setItem(skin + "__usedPaymentMethod", appLabels["order"]["pay-later"]);
        localforage.setItem(skin + "__finalOrderTotal", finalOrderTotal);
        //setAPIError("");
        //setIsAPISubmitValid(true);
        //setAPISubmitting(false);

        confirmOrder(orderID); // 1308 API
      } else {
        setIsSubmittingOrder(false);
        // Failed to place an order
        //setAPIError(data1302.result.message);
        setSubmitOrderApiError(data1302.result.message);
        setTimeout(() => {
          setSubmitOrderApiErrorHideClass("FAB__error--closing");
          setSubmitOrderApiError(null);
          setTimeout(() => {
            setSubmitOrderApiErrorHideClass("");
          }, 250);
        }, 3000);

        console.error(data1302.result.message);
      }
    });
  };

  const submitOrder = async () => {
    setIsSubmittingOrder(true);
    if (activeOrderType === "dinein") {
      placeOrder(); // call 1302
    } else {
      //update the location with the latest data, in case the store hours have been changed
      const updatedLocation = await updateLocationData(
        activeOrderStore,
        activeOrderType,
        skin,
        appLanguage
      );
      if (updatedLocation) {
        let isStoreClosedForTheDay = isStoreClosedToday(updatedLocation, orderTimeContext)

        const orderTimeIsPassed = isOrderTimePassed(
          updatedLocation,
          activeOrderType,
          orderTimeContext,
          setNextAvailableOrderTime
        );
        const orderTimeIsBeforeOpening = isOrderTimeBeforeOpeningTime(
          updatedLocation,
          orderTimeContext
        );
        const orderTimeIsBlocked = isOrderTimeBlocked(
          updatedLocation,
          orderTimeContext,
          activeOrderType
        );
        const lateOrderAttempt = isLateOrderAttempted(
          updatedLocation,
          activeOrderType,
          orderTimeContext,
          skin,
          setNextAvailableOrderTime
        );
        if (lateOrderAttempt) {
          isStoreClosedForTheDay = true
        }

        if (isStoreClosedForTheDay) {
          setIsStoreClosed(true)
          return;
        } 
        else if (orderTimeIsPassed) {
          setIsPassedOrderTime(true);

          //setAPISubmitting(false);
          //setIsAPISubmitValid(false);
          //setAPIError(<>&nbsp;</>); // reset the disabled state on submit FAB
        } else if (orderTimeIsBeforeOpening) {
          setIsOrderTimeBeforeOpening(true);

          //setAPISubmitting(false);
          //setIsAPISubmitValid(false);
          //setAPIError(<>&nbsp;</>); // reset the disabled state on submit FAB
        } else if (orderTimeIsBlocked) {
          setOrderTimeIsBlocked(true);
          return;
        } else {
          placeOrder(); // call 1302
        }
      } else {
        // if updatedLocation returns null, it means the selected location does not exist anymore
        history.push("/dashboard");
      }
    }
  };

  return (
    <button
      className="button button--primary"
      type="button"
      disabled={isMinDeliverySpendingMet === false || isSubmittingOrder}
      onClick={submitOrder}
    >
      {isSubmittingOrder
        ? appLabels["order"]["submitting-order"]
        : appLabels["order"]["submit-order"]}
    </button>
  );
};
export default SubmitOrderWithoutPayment;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppLabelsContext from "../../App/AppLabelsContext";

export const ForgotPasswordLink = () => {
  const appLabels = useContext(AppLabelsContext);

  return (
    <div className="login-register__bottom-link-wrapper link--forgot-password-wrapper">
      <Link to="/forgot-password" className="login-register__bottom-link link--forgot-password">
        {appLabels["login-register"]["forgot-password"]}
      </Link>
    </div>
  );
};

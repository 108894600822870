import React, { useRef, useEffect, useContext, useState } from "react";
import "./Form.css";
import "./FormTextarea.css";
import AppLabelsContext from "../../App/AppLabelsContext";
import { Button } from "@material-ui/core";
import { IconFormErase } from "../icons/IconFormErase";
import BrandingContext from "../../App/BrandingContext";

export const FormTextarea = (props) => {
  const {
    resetInvalidField,
    defaultValue,
    fieldData,
    isSmall,
    id,
    name,
    handleInputChangeFromParent,
    onInputChange,
    value,
    isRequired,
    label,
    showAstr,
    onFocusAction,
    customerWrapperClass,
    hasActionButtons = null,
    clearForm = null,
    isDisabled,
    clearFormActive = false,
    recipientIndex,
  } = props;

  const [inputInFocusClass, setInputInFocusClass] = useState("");
  const appLabels = useContext(AppLabelsContext)
  const brandingContext = useContext(BrandingContext)

  const onFocus = (field) => {
    resetInvalidField(field);
    setInputInFocusClass(" form__field-wrapper--in-focus");
    if (onFocusAction) {
      onFocusAction();
    }
  };

  const inputRef = useRef();
  useEffect(() => {
    if (defaultValue && !inputInFocusClass && inputRef.current) {
      setInputInFocusClass(" form__field-wrapper--in-focus");
      onFocus(inputRef.current);
    }
  }, [inputRef.current]);

  const [showValue, setShowValue] = useState()

  useEffect(() => {
    setShowValue(defaultValue)
  }, [defaultValue])

  const onClearFormClick = () => {
    setShowValue("")
    clearForm()
  }

  return (
    <div
      className={`form__field-wrapper${true ? "" : " form__field-wrapper--invalid"
        }${isSmall ? " form__field-wrapper--small textAreaWrapperSmall" : ""}
    ${customerWrapperClass ? customerWrapperClass : ""}
    ${inputInFocusClass}`}
    >
      <label
        htmlFor={id}
        className="form__field-label"
      >
        {!!showAstr && <span aria-hidden="true">*</span>}
        {(fieldData.label || label) +
          (fieldData.isRequired || isRequired
            ? ""
            : ` (${appLabels["form"]["optional-label"]})`)}
      </label>
      <textarea
        type="textarea"
        rows={5}
        height={80}
        className="form__field textareaInput"
        maxLength={250}
        id={id}
        name={name}
        defaultValue={fieldData.value !== "" ? fieldData.value : showValue}
        onInput={(e) => {
          if (handleInputChangeFromParent) handleInputChangeFromParent(e);
          onInputChange(e)
        }}
        value={value}
        disabled={isDisabled}
      />

      {hasActionButtons &&
        <div className="form_button-action-row">

          <div className="form_button-action-clear">
            <Button id={recipientIndex + "-clearRecipientButton"} className={clearFormActive ? "activeText" : ""} startIcon={<IconFormErase color={clearFormActive ? brandingContext["primary-colour"] : null} />} onClick={() => onClearFormClick()} disabled={!clearFormActive}>
              {appLabels["gift-card"]["clear-form"]}
            </Button>
          </div>
        </div>
      }

      {!fieldData.isValid && fieldData.errorMessage !== "" && (
        <div className="form__field-error">{fieldData.errorMessage}</div>
      )}
    </div>
  );
};

FormTextarea.displayName = "FormTextarea";

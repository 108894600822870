import React, { useContext } from "react";

/** Context */
import AppLabelsContext from "../App/AppLabelsContext";

const AddressInfoCard = (props) => {
  const { address, recommendedAddress } = props;
  const appLabels = useContext(AppLabelsContext);

  const correctionStyleObject = {
    color: "red",
    fontWeight: "600",
  };

  const userStreetAddress = address["first-address"]
    ? address["first-address"]
    : address.addressLines[0];
  const userCity = address.city ? address.city : address.locality;
  const userProvince = address.province ? address.province : address.administrativeArea;
  const userPostal = address.postal ? address.postal : address.postalCode;
  const userCountry = address.country ? address.country : address.regionCode;

  const recommendedStreetAddress =
    recommendedAddress && recommendedAddress.addressLines
      ? recommendedAddress.addressLines[0]
      : recommendedAddress && recommendedAddress["first-address"]
      ? recommendedAddress["first-address"]
      : null;
  const recommendedCity = recommendedAddress
    ? recommendedAddress.locality
      ? recommendedAddress.locality
      : recommendedAddress.city
    : null;
  const recommendedProvince = recommendedAddress
    ? recommendedAddress.administrativeArea
      ? recommendedAddress.administrativeArea
      : recommendedAddress.province
    : null;
  const recommendedPostal = recommendedAddress
    ? recommendedAddress.postalCode
      ? recommendedAddress.postalCode
      : recommendedAddress.postal
    : null;

  const isStreetAddressRecommended =
    recommendedAddress && userStreetAddress !== recommendedStreetAddress;
  const isCityAddressRecommended = recommendedAddress && userCity !== recommendedCity;
  const isProvinceRecommended = recommendedAddress && userProvince !== recommendedProvince;
  const isPostalRecommended = recommendedAddress && userPostal !== recommendedPostal;

  return (
    <div className="address-info-card__container">
      <p
        className="address-validation__street-address"
        style={isStreetAddressRecommended ? correctionStyleObject : {}}
      >
        {userStreetAddress}
      </p>
      <p className="address-validation__city-province-postal">
        {!!userCity && (
          <span style={isCityAddressRecommended ? correctionStyleObject : {}}>{userCity}, </span>
        )}

        {!!userProvince && !!userProvince.split(":")[0] && (
          <span style={isProvinceRecommended ? correctionStyleObject : {}}>
            {userProvince.split(":")[0]},{" "}
          </span>
        )}
        {!!userPostal && (
          <span style={isPostalRecommended ? correctionStyleObject : {}}>
            {userPostal}
            {!recommendedAddress && ", "}
          </span>
        )}
        {!recommendedAddress && <span>{userCountry}</span>}
      </p>
      {address["second-address"] && (
        <p className="address-validation__apt-suite-unit-container">
          <span className="address-validation__apt-suite-unit-header">
            {appLabels["form"]["apt-suit-unit"]}
          </span>
          <span className="address-validation__apt-suite-unit">{` ${address["second-address"]}`}</span>
        </p>
      )}
    </div>
  );
};
export default AddressInfoCard;

import { callAPI } from "../_common/Api";

export const get1023AutomatedReloadSchedule = async (skin, loginToken, loyaltyCard, language) => {
  try {
    const apiData = await callAPI(skin, "dc_1023", {
      params: [
        language,
        "1023",
        "mqid",
        "mqpass",
        loginToken,
        "", // customerPassword
        loyaltyCard.iso,
        loyaltyCard.serial,
      ],
      id: "1023",
    });

    if (apiData.status === 249 || apiData.status === 150) {
      return { status: "expiredLoginToken" };
      //
    } else if (apiData.result.I1 === "0") {
      const result = apiData.result;

      const automatedReloadSchedule = {
        isActive: result.I5 !== "",
        amount: result.I7,
      };

      if (result.I8) automatedReloadSchedule.threshold = result.I8;

      if (result.I6 !== "th") {
        // "th" - threshold

        const frequency = result.I6;
        if (frequency === "wk") automatedReloadSchedule.frequency = "weekly";
        else if (frequency === "mn") automatedReloadSchedule.frequency = "monthly";
      }

      return { data: automatedReloadSchedule };
      //
    } else {
      // API error
      console.error(apiData.error);
      return { error: apiData.error };
    }
  } catch (error) {
    // Fetch error
    console.error(error);
    return { error };
  }
};
